import React, { Component, forwardRef } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { signIn, resetSignIn } from "./actions";
import { retrieveSignInData } from "./selectors";
import { Input, Button, withStyles, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import AuthLayout from "./AuthLayout";
import DocumentTitle from "react-document-title";
import { Link as RouterLink } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Redirect } from "react-router-dom";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const requiredFieldText = "This field is required";

const signInSchema = Yup.object().shape({
  username: Yup.string().required(requiredFieldText),
  password: Yup.string().required(requiredFieldText)
});

const mapState = state => ({
  signInData: retrieveSignInData(state)
});

const mapDispatch = dispatch => ({
  signIn: (username, password) => dispatch(signIn(username, password)),
  resetSignIn: () => dispatch(resetSignIn())
});

const styles = {
  field: {
    marginTop: "5px",
    width: "100%"
  },
  validationMessage: {
    height: "25px",
    padding: "3px 0"
  },
  alignTextCenter: {
    textAlign: "center"
  },
  bottom: {
    marginTop: "20px"
  }
};

const AdapterLink = forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

/**
 * A component to allow the users to login. When the login
 * is complete, the user should be able to access the application
 */
export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      username: ""
    };
  }

  componentWillUnmount = () => {
    this.props.resetSignIn();
  };

  togglePasswordVisibility = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleClose = () => {
    this.props.resetSignIn();
  };

  render = () => {
    const { signIn, signInData, classes } = this.props,
      { showPassword, username } = this.state,
      { error, data } = signInData || {},
      { detail } = (data && data.data) || {},
      userIsNotActive = detail === "User is not active",
      userLicenseIsExpired = detail === "User license is expired";
    const splitDetail = (detail && detail.split(" - ")) || [];
    return signInData && signInData.success ? (
      <Redirect to="/" />
    ) : (
      <DocumentTitle title="Login">
        <AuthLayout title="Login">
          <div className={`${classes.alignTextCenter}`}>
            {error && !userIsNotActive && splitDetail.length === 1 && detail}
            {error && !userIsNotActive && splitDetail.length === 2 && (
              <>
                <span>{splitDetail[0]}</span>
                <div style={{ width: 100, margin: "auto" }}>
                  <CountdownCircleTimer
                    isPlaying
                    duration={parseInt(splitDetail[1])}
                    size={100}
                    strokeWidth={6}
                    colors={[["#3f51b5"]]}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </div>
              </>
            )}
          </div>

          <UnselfishDialog
            onClose={this.handleClose}
            open={error && userIsNotActive}
          >
            <DialogContent>
              <DialogContentText>
                The user <b>{username}</b> is not activated yet. The account
                owner shall receive a notification once the account has been
                activated. If your account was not activated within 5 working
                days please contact
                <Link href="mailto:info@planopsim.com">
                  {" "}
                  info@planopsim.com
                </Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </UnselfishDialog>

          <UnselfishDialog
            onClose={this.handleClose}
            open={error && userLicenseIsExpired}
            test-data="expiredUserDialog"
          >
            <DialogContent>
              <DialogContentText>
                Dear <b>{username}</b>, it appears that your license is expired,
                please contact support for renewal or for more information.
                <Link href="mailto:info@planopsim.com">
                  {" "}
                  info@planopsim.com
                </Link>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </UnselfishDialog>

          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={signInSchema}
            onSubmit={values => {
              const { username, password } = values;
              this.setState({ username });
              signIn(username, password);
            }}
          >
            {({ errors, values, handleChange, handleBlur, touched }) => (
              <Form>
                <Input
                  className={classes.field}
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  placeholder="Username *"
                />
                <div className={classes.validationMessage}>
                  {touched.username && errors.username}
                </div>

                <Input
                  className={classes.field}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Password *"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.validationMessage}>
                  {touched.password && errors.password}
                </div>

                <Button
                  name="submitLogin"
                  test-data="submitLogin"
                  className={classes.field}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={signInData && signInData.loading}
                >
                  Submit
                </Button>

                <div className={`${classes.bottom} ${classes.alignTextCenter}`}>
                  <Link component={AdapterLink} to="/register">
                    <Typography test-data="createAccountLink">
                      Create an account
                    </Typography>
                  </Link>
                </div>
                <div className={`${classes.bottom} ${classes.alignTextCenter}`}>
                  <Link component={AdapterLink} to="/reset-password-request">
                    <Typography>Reset password</Typography>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </AuthLayout>
      </DocumentTitle>
    );
  };
}

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(Login)));
