import React, { PureComponent } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import GenericJobOverview from "./GenericJobOverview";
import AnalysisApi from "MetaComponent/api/Analysis";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DesignApi from "MetaComponent/api/Design";
import AnalysisAction from "MetaComponent/actions/Analysis";
import AnalysisSelector from "MetaComponent/selectors/Analysis";
import GenericApi from "Api";

export class AnalysisJobOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      data: null
    };
    this.handleFetchJobsComplete = this.handleFetchJobsComplete.bind(this);
    this.handleFetchJobsError = this.handleFetchJobsError.bind(this);
  }

  /**
   * when the component mounts it only gets the design jobs if the mc groups are loaded
   * else it fetches the groups and the meta componetns
   */
  componentDidMount() {
    const { fetchMCGroupsAndMetaComponentsAction, mcGroups } = this.props;
    if (mcGroups.loaded) {
      this.fetchJobs();
    } else {
      fetchMCGroupsAndMetaComponentsAction();
    }
  }

  /**
   * it fetches the jobs when the groups are loaded
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.mcGroups.loaded && this.props.mcGroups.loaded) {
      this.fetchJobs();
    }
  }

  /**
   * it makes a request to get specified jobs
   * @param {String} - the name of the endpoint
   */
  async fetchSpecificJobs(endpointName) {
    try {
      const response = await Axios.get(
        `${GenericApi.getBaseUrl()}/${endpointName}/`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        this.handleFetchJobsError();
      }
    } catch {
      this.handleFetchJobsError();
    }
    return null;
  }

  /**
   * it fetches design and analysis jobs and updates the state used in the table
   */
  async fetchJobs() {
    const designJobs = await this.fetchSpecificJobs("designjobs");
    const analysisJobs = await this.fetchSpecificJobs("analysisjobs");
    if (designJobs && analysisJobs) {
      this.handleFetchJobsComplete(designJobs, analysisJobs);
    }
  }

  /**
   * it sets the state that is used for the table data
   * @param {Object[]} designJobs - the design job objects
   * @param {Object[]} analysisJobs - the analysis job objects
   */
  handleFetchJobsComplete(designJobs, analysisJobs) {
    this.setState({
      data: analysisJobs.map(analysisJob => {
        const designJob = designJobs.find(
          job => job.id === analysisJob.design_job
        );
        return {
          itemId: analysisJob.id,
          mcGroup: this.getMCGroupName(designJob.meta_component),
          metaComponent: this.getMetaComponentName(designJob.meta_component),
          designJob: `${this.getMetaComponentName(
            designJob.meta_component
          )} - ${(designJob.start && designJob.start.slice(0, 10)) || "-"}`,
          startDate:
            (analysisJob.creationDate &&
              analysisJob.creationDate.slice(0, 10)) ||
            "-",
          ...analysisJob
        };
      }),
      recipientData: designJobs.map(designJob => ({
        restorationRecipientId: designJob.id,
        mcGroup: this.getMCGroupName(designJob.meta_component),
        metaComponent: this.getMetaComponentName(designJob.meta_component),
        designJob: `${this.getMetaComponentName(
          designJob.meta_component
        )} - ${(designJob.start && designJob.start.slice(0, 10)) || "-"}`
      }))
    });
  }

  handleFetchJobsError() {
    this.setState({ error: true });
  }

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} the name of the project to which the component belongs
   */
  getMCGroupName = metaComponentId => {
    const { mcGroups, metaComponents } = this.props;
    let mcGroupName = "";
    const metaComponent = metaComponents.byId[metaComponentId];
    if (metaComponent) {
      const mcGroup = mcGroups.byId[metaComponent.mcGroup];
      if (mcGroup) {
        mcGroupName = mcGroup.name;
      }
    }
    return mcGroupName;
  };

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} - the name of the meta component
   */
  getMetaComponentName = metaComponentId => {
    const { metaComponents } = this.props;
    const metaComponent = metaComponents.byId[metaComponentId];
    return metaComponent ? metaComponent.name : "";
  };

  /**
   * @param {Number} jobId - the design job id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  stopAnalysisJob = jobId => {
    return AnalysisApi.stopAnalysisJob(jobId).then(() => Promise.resolve(true));
  };

  /**
   * @param {Number} jobId - the design job id
   * @returns {Object} errors and warnings extracted from the design job
   */
  getAnalysisJobErrors = async jobId => {
    const job = this.state.data.find(job => job.itemId === jobId);
    const { errors } = job;
    return { errors };
  };

  /**
   *
   * @param {*} jobId
   */
  getAnalysisJobConfiguration = async jobId => {
    const job = this.state.data.find(job => job.itemId === jobId);
    const {
      width,
      height,
      columns_count,
      rows_count,
      incident_light,
      diffractive_order,
      center_coord,
      euler_angles,
      direction_out,
      ff_target_unit,
      metrics_definition,
      order_out,
      polarization_out,
      selected_wave_front,
      set_point_unit,
      swept_variables
    } = job;
    return {
      width,
      height,
      columns_count,
      rows_count,
      incident_light,
      diffractive_order,
      center_coord,
      euler_angles,
      direction_out,
      ff_target_unit,
      metrics_definition,
      order_out,
      polarization_out,
      selected_wave_front,
      set_point_unit,
      swept_variables
    };
  };

  /**
   * it deletes an analysis job
   * @param {Number[]} jobIds - the job ids
   * @callback
   */
  deleteAnalysisJob = (jobIds, onConfirmCallback) => {
    return this.props.showConfirmDialog(
      "Delete analysis job",
      "Are you sure you want to delete the selected analysis jobs?",
      () => {
        this.props.deleteAnalysisJob(jobIds);
        onConfirmCallback(jobIds);
      },
      undefined
    );
  };

  /**
   * @param {Number} analysisJobId - the analysis job from which the analysis will be restored
   * @param {Number}  designJobId - the design job to which the analysis will be restored
   */
  restoreAnalysis = (analysisJobId, designJobId) => {
    this.props.restoreAnalysis(designJobId, analysisJobId);
  };

  /**
   * @callback
   * @param {Object} newData
   */
  updateData = newData => {
    this.setState({ data: newData });
  };

  /**
   * sends the new filters to the redux state
   * @param {Object} filter - the new filter
   */
  handleFilterChange = filter => {
    this.props.updateOverviewFilters(filter);
  };

  render = () => {
    const { data, recipientData, error } = this.state;
    return (
      // data &&
      // recipientData && (
      <GenericJobOverview
        data={data}
        updateData={this.updateData}
        recipientData={recipientData}
        error={error}
        getErrorsAndWarnings={this.getAnalysisJobErrors}
        getConfiguration={this.getAnalysisJobConfiguration}
        getJobProgress={AnalysisApi.getSimpleAnalysisJobProgress}
        deleteJob={this.deleteAnalysisJob}
        stopJob={this.stopAnalysisJob}
        grouperColumns={[
          { title: "Group", field: "mcGroup", defaultGroupOrder: 0 },
          {
            title: "Meta Component",
            field: "metaComponent",
            defaultGroupOrder: 1
          },
          {
            title: "Design Job",
            field: "designJob",
            defaultGroupOrder: 2
          }
        ]}
        onRestoreConfirm={this.restoreAnalysis}
        restorationRecipient={"designJob"}
        handleFilterChange={this.handleFilterChange}
        filters={this.props.filters}
      />
      // )
    );
  };
}

const mapStateToProps = state => ({
  filters: AnalysisSelector.getOverviewFilters(state),
  mcGroups: DirectoryExplorerSelector.getMCGroups(state),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateOverviewFilters: filter =>
      dispatch(AnalysisAction.updateOverviewFilters(filter)),
    fetchMCGroupsAndMetaComponentsAction: () =>
      dispatch(DirectoryExplorerApi.fetch()),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    deleteAnalysisJob: jobIds =>
      dispatch(AnalysisApi.deleteAnalysisJobs(jobIds)),
    restoreAnalysis: (designJobId, analysisJobId) =>
      dispatch(DesignApi.restoreAnalysis(designJobId, analysisJobId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalysisJobOverview);
