import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import FileCopy from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: "3px 12px"
  }
}));

const GenericActions = ({
  onEdit,
  onDelete,
  onCopy,
  onShiftUp,
  onShiftDown
}) => {
  const classes = useStyles();

  return (
    <div name="StackToolBar" style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }} />
      {onEdit !== undefined ? (
        <IconButton
          name="EditButton"
          test-data="editBtn"
          classes={{ root: classes.iconButton }}
          onClick={onEdit}
        >
          <EditIcon />
        </IconButton>
      ) : null}
      {onDelete !== undefined ? (
        <IconButton
          name="DeleteButton"
          classes={{ root: classes.iconButton }}
          onClick={onDelete}
          test-data="deleteBtn"
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
      {onCopy !== undefined ? (
        <IconButton
          name="CopyButton"
          classes={{ root: classes.iconButton }}
          onClick={onCopy}
          test-data="copyBtn"
        >
          <FileCopy />
        </IconButton>
      ) : null}
      {onShiftUp != undefined ? (
        <IconButton
          name="ShiftUpButton"
          classes={{ root: classes.iconButton }}
          onClick={onShiftUp}
          test-data="shiftUpBtn"
        >
          <ArrowUpward />
        </IconButton>
      ) : null}
      {onShiftDown !== undefined ? (
        <IconButton
          name="ShiftDownButton"
          classes={{ root: classes.iconButton }}
          onClick={onShiftDown}
          test-data="shiftDownBtn"
        >
          <ArrowDownward />
        </IconButton>
      ) : null}
    </div>
  );
};

export default GenericActions;
