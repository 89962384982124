/**
 * A helper class to help components deal with material data.
 * @author Akira Kotsugai
 */
export default class MaterialHelper {
  /**
   * it finds a material by its name in the given materials list
   * @param {String} name - the material's name
   * @param {Object[]} materials - the list of materials
   * @return the material
   */
  static getMaterialByName = (name, materials) => {
    let material = null;
    material = materials.find(material => material.name === name);
    return material;
  };

  /**
   * it generates a comment for the material csv with the given values
   * @param {String} materialName - the name of the material
   * @param {String} username - the logged in user
   * @param {String} dateAndTime - the date and time that the csv was generated
   * @returns {String[]} the comment in a list
   */
  static getMaterialCsvComment = (materialName, username, dateAndTime) => [
    `## Line plot for ${materialName} material generated by ${username} on ${dateAndTime}`
  ];

  /**
   * it generates the material csv data
   * @param {Number[]} wavelength - the material's wavelength
   * @param {Number[]} refractiveIndex - the material's refractive index
   * @param {Number[]} absorptionCoeff - the material's absorption coefficient
   * @returns {Number[][]} - a list of lists where each list is a row
   */
  static getMaterialCsvData = (
    wavelength,
    refractiveIndex,
    absorptionCoeff
  ) => {
    let csvData = [];
    for (var i = 0; i < wavelength.length; i++) {
      csvData.push([wavelength[i], refractiveIndex[i], absorptionCoeff[i]]);
    }
    return csvData;
  };

  /**
   * it generates the data to plot a material containing lines for refractive index
   * and absorption coefficient. dots for wavelength higher than 4 will be ignored
   * because we consider it to be irrelevant information.
   * @param {Number[]} wavelength - the material's wavelength
   * @param {Number[]} refractiveIndex - the material's refractive index
   * @param {Number[]} absorptionCoeff - the material's absorption coefficient
   */
  static buildMaterialGraphData = (
    wavelength,
    refractiveIndex,
    absorptionCoeff
  ) => {
    let refrIdxLine = [];
    let absCoeffLine = [];
    for (var i = 0; i < wavelength.length; i++) {
      const x = wavelength[i];
      this.generateLineDot(x, refractiveIndex, i, refrIdxLine);
      this.generateLineDot(x, absorptionCoeff, i, absCoeffLine);
    }
    let materialGraphData = [];
    if (refractiveIndex.length !== 0)
      materialGraphData.push({
        id: "refractive index",
        data: refrIdxLine,
        dimensionsPercentages: this.getGraphDimensionsPercentages(refrIdxLine)
      });
    if (absorptionCoeff.length !== 0)
      materialGraphData.push({
        id: "absorption coefficient",
        data: absCoeffLine,
        dimensionsPercentages: this.getGraphDimensionsPercentages(absCoeffLine)
      });
    return materialGraphData;
  };

  /**
   * it gets the calculated graph dimensions to be used for rendering the graph correctly
   * @param {Object} lineGraph - the line data
   */
  static getGraphDimensionsPercentages = lineGraph => {
    const maxX = Math.max(...lineGraph.map(dot => dot.x));
    const maxY = Math.max(...lineGraph.map(dot => dot.y));
    let widthPercentage;
    let heightPercentage;
    let marginTopPercentage;
    let marginLeftPercentage;
    if (maxX > maxY) {
      widthPercentage = 100;
      heightPercentage = this.getProportionalPercentage(maxX, maxY);
      marginLeftPercentage = 0;
      marginTopPercentage = this.getMarginPercentage(heightPercentage);
    } else {
      heightPercentage = 100;
      widthPercentage = this.getProportionalPercentage(maxY, maxX);
      marginTopPercentage = 0;
      marginLeftPercentage = this.getMarginPercentage(widthPercentage);
    }
    return {
      width: widthPercentage + "%",
      height: heightPercentage + "%",
      marginTop: marginTopPercentage + "%",
      marginLeft: marginLeftPercentage + "%"
    };
  };

  /**
   * it gets the margin percentage of a material graph given the
   * dimension percentage (height or width percentage).
   * @param {Number} dimensionPercentage - the height or width percentage
   * @returns {Number} the margin percentage
   */
  static getMarginPercentage = dimensionPercentage => {
    return (100 - dimensionPercentage) / 2;
  };

  /**
   * it gets the proportional percentage of a graph dimension based on a value that corresponds
   * to 100%. The aspect ratio is limited to 4x3 though, so percentages that are lower than
   * 75% will be round up to 75%.
   * @param {Number} hundredPercentValue - the value that corresponds to 100%
   * @param {Number} valueToFindPercentage - the value of which we want to find the percentage.
   */
  static getProportionalPercentage = (
    hundredPercentValue,
    valueToFindPercentage
  ) => {
    const proportionalPercentage =
      (100 * valueToFindPercentage) / hundredPercentValue;
    return proportionalPercentage > 75 ? proportionalPercentage : 75;
  };

  /**
   * it gets the values in the given array and generates a dot: (an object containing
   * x and y) and insert in the line array.
   * in case the given yValues has only one value, we get this one value so it will be a straight line
   * @param {Number[]} x - the x value
   * @param {Number[]} yValues - the array containing y values
   * @param {Number} index - the position in the array to be checked
   * @param {Object[]} targetLine - the line array where the dot will be inserted
   */
  static generateLineDot = (x, yValues, index, targetLine) => {
    if (yValues instanceof Array) {
      const y = yValues.length === 1 ? yValues[0] : yValues[index];
      const dot = {
        x,
        y
      };
      targetLine.push(dot);
    }
  };

  /**
   * it reads the material file and returns either the read file or an error message
   * @param {Object} file - the file
   * @returns {Object} the material object
   */
  static readMaterialFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (function(theFile) {
        return function(e) {
          try {
            const data = e.target.result;
            const fileName = file.name;
            let jsonObj;
            if (fileName.endsWith(".json")) {
              jsonObj = MaterialHelper.turnMaterialJsonIntoObject(data);
            } else if (fileName.endsWith(".csv") || fileName.endsWith(".txt")) {
              jsonObj = MaterialHelper.turnMaterialTextIntoObject(data);
            }
            resolve(jsonObj);
          } catch (exception) {
            console.log(exception);
            resolve("This is not a valid material file.");
          }
        };
      })(file);
      reader.readAsText(file);
    });
  };

  /**
   * it reads a jsonText and returns a material object
   * @param {String} jsonText
   * @returns {Object} the material object
   */
  static turnMaterialJsonIntoObject = jsonText => {
    return JSON.parse(jsonText);
  };

  /**
   * it reads a text data and returns a material object
   * @param {String} textData
   * @returns {Object} the material object
   */
  static turnMaterialTextIntoObject = textData => {
    const allTextLines = textData.split(/\r\n|\n/);
    let wavelength = [],
      refractiveIndex = [],
      absorptionCoeff = [];
    for (let line of allTextLines) {
      const formattedLine = line.replace(/"/g, "");
      const firstCharacterIsNumber = !isNaN(Number(formattedLine[0]));
      if (firstCharacterIsNumber) {
        const curveValues = formattedLine.split(/,|;|\t/);
        wavelength.push(Number(curveValues[0]));
        refractiveIndex.push(Number(curveValues[1]));
        absorptionCoeff.push(
          curveValues[2] !== undefined ? Number(curveValues[2]) : 0
        );
      }
    }
    return {
      wavelength,
      refractiveIndex,
      absorptionCoeff
    };
  };

  /**
   * it checks whether a material has a valid curve or not
   * @param {Object} materialObj - the material
   * @returns {Boolean} whether the material curve is valid
   */
  static validateMaterialCurve = materialObj => {
    const { wavelength, refractiveIndex, absorptionCoeff } = materialObj;
    if (wavelength && refractiveIndex && absorptionCoeff) {
      if (
        wavelength instanceof Array &&
        refractiveIndex instanceof Array &&
        absorptionCoeff instanceof Array
      ) {
        if (
          wavelength.length === refractiveIndex.length &&
          wavelength.length === absorptionCoeff.length
        ) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * it gets the file name from the file and inserts into the material object
   * @param {File} file - the file
   * @param {Object} material - the material object
   */
  static setMaterialFileName = (file, material) => {
    const fileName = file.name;
    material.fileName = fileName;
  };

  /**
   * it finds which required properties are missing
   * @param {Object} material - the material
   * @param {String[]} requiredFields - the required properties
   */
  static getMissingRequiredProperties = (material, requiredFields) => {
    return requiredFields.filter(
      property =>
        material[property] === undefined ||
        material[property] === "" ||
        material[property] === null
    );
  };
}
