import React from 'react';
import { Button, FormControl, MenuItem, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import jet from 'assets/heatmap_color_preview/jet.jpg'
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import { colorScaleOptions } from "./ColorScales";
export default class CustomizeHeatmapColorscale extends React.Component {
    state = {
        customHeatmapColorScale: 1,
        previewImage: jet,
    };

    changeColorScale = (e) => {
        let colorScaleId = e.target.value;
        let selectedColorScale = colorScaleOptions.find(option => option.id == colorScaleId)
        this.setState({ previewImage: require(`assets/heatmap_color_preview/${selectedColorScale.previewImageName}`), customHeatmapColorScale: selectedColorScale.id });
    }

    updateColorscale = () => {
        let selectedColorScale = colorScaleOptions.find(option => option.id == this.state.customHeatmapColorScale)
        this.props.handleCustomHeatmapColorScale(selectedColorScale.value)
        this.props.handleClose();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" style={{ 'padding-bottom': '0px', }}>Change Heatmap color</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select a colorscheme from the dropdown for preview.
                        </DialogContentText>
                        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <FormControl style={{ minWidth: 200, marginBottom: "10px" }}>
                                    <InputLabel htmlFor="color-select">Colorscheme</InputLabel>
                                    <Select
                                        value={this.state.customHeatmapColorScale}
                                        onChange={this.changeColorScale}
                                        inputProps={{
                                            name: 'colorscale-select',
                                            id: 'colorscale-select',
                                        }}
                                        test-data="colorscale-select"
                                    >
                                        {colorScaleOptions.map(colorScale => (
                                            <MenuItem key={colorScale.id} value={colorScale.id}>
                                                <span>{colorScale.name}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Typography variant="h6" style={{ 'text-align': 'center' }}>Preview</Typography>
                        <img test-data="preview-image" width="400" src={this.state.previewImage} alt="Preview colorscale" />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="red">
                            Cancel
                        </Button>
                        <Button test-data="updateBtn" onClick={this.updateColorscale} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}