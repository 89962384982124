import React from "react";
import PropTypes from "prop-types";
import images from "../../incidentLightImages.json";

/**
 * a functional stateless component to show the direction of the electric field
 * @author Akira Kotsugai
 */
const ElectricFieldDirectionArrow = ({
  polarization,
  width,
  height,
  incidentPlaneAngle
}) => {
  const image =
    polarization === "RCP"
      ? images.arrowRight
      : polarization === "LCP"
      ? images.arrowLeft
      : images.arrow;
  return isNaN(incidentPlaneAngle) ? null : (
    <div
      test-data="arrowDiv"
      style={{
        position: "absolute",
        zIndex: 300,
        height,
        width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img
        test-data="arrowImage"
        src={`data:image/jpeg;base64,${image}`}
        alt=""
        style={{
          transform:
            polarization === "TE"
              ? `rotate(${(parseFloat(incidentPlaneAngle) + 90) * -1}deg)`
              : `rotate(${parseFloat(incidentPlaneAngle) * -1}deg)`,
          height:
            polarization === "RCP" || polarization === "LCP"
              ? height / 2
              : height
        }}
      />
    </div>
  );
};

ElectricFieldDirectionArrow.propTypes = {
  /**
   * the incident light polarization
   */
  polarization: PropTypes.string.isRequired,
  /**
   * the total width
   */
  width: PropTypes.number.isRequired,
  /**
   * the total height
   */
  height: PropTypes.number.isRequired,
  /**
   * the angle of the incident plane
   */
  incidentPlaneAngle: PropTypes.number.isRequired
};

export default ElectricFieldDirectionArrow;
