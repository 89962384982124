import React, { Component } from "react";
import {
  Select,
  withStyles,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import TopView from "../../StructureCanvas/components/TopView";
import ContainerDimensions from "react-container-dimensions";
import { connect } from "react-redux";
import StructureSelector from "MetaCell/selectors/Structure";
import PropTypes from "prop-types";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Grid from "@material-ui/core/Grid";
import ElectricFieldDirectionArrow from "./ElectricFieldDirectionArrow/ElectricFieldDirectionArrow";
import IncidentPlaneLine from "./IncidentPlaneLine/IncidentPlaneLine";
import IllustrationLegend from "./IllustrationLegend/IllustrationLegend";
import StructureHelper from "MetaCell/helper/Structure";

const styles = {
  root: {
    maxWidth: 300,
    padding: "0 20px",
    margin: "auto"
  },
  image: {
    width: 300
  },
  illustration: {
    position: "relative"
  }
};

class IncidentLightIllustration extends Component {
  height = null;
  constructor(props) {
    super(props);
    this.state = {
      layerId: null,
      angle: 0
    };
  }

  handleLayerChange = ({ target: { value: layerId } }) => {
    this.setState({ layerId });
  };

  getIncidentPlaneAngle = () => {
    const { incidentLight, sweptVariables } = this.props,
      { azimut } = incidentLight || {};
    let angle = 0;
    if (azimut) {
      if (azimut[0] === "=") {
        if (sweptVariables) {
          const sweptVariable = sweptVariables.find(
              ({ variableName }) => azimut === `=${variableName}`
            ),
            { values } = sweptVariable || {};
          if (values) {
            const valuesArray = JSON.parse(values);
            angle = (valuesArray && valuesArray[0]) || 0;
          }
        }
      } else {
        angle = Number.parseInt(azimut);
      }
    }
    return angle;
  };

  render() {
    const { classes, layers, incidentLight } = this.props,
      { polarization } = incidentLight || {},
      { layerId } = this.state;
    const layersList = StructureHelper.getExpandedStaircaseLayers(layers);
    const id = layerId || (layersList.length > 0 && layersList[0].id) || -1;

    return id && id !== -1 ? (
      <div className={classes.root}>
        <div className={classes.illustration}>
          <ContainerDimensions>
            {({ width, height }) => {
              const incidentPlaneAngle = this.getIncidentPlaneAngle();
              if (width > height && height > 0) {
                height = width;
                this.height = height;
              }
              return (
                <div
                  style={{
                    width,
                    ...(this.height && { height }),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <ElectricFieldDirectionArrow
                    polarization={polarization}
                    width={width}
                    height={height}
                    incidentPlaneAngle={this.getIncidentPlaneAngle()}
                  />

                  <IncidentPlaneLine
                    width={width}
                    height={height}
                    incidentPlaneAngle={incidentPlaneAngle}
                  />
                  <div style={{ padding: 50, width: "100%" }}>
                    <TopView
                      width={width - 100}
                      height={height - 100}
                      selectedLayerId={id}
                    />
                  </div>
                </div>
              );
            }}
          </ContainerDimensions>
        </div>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="space-around"
          style={{ width: 300 }}
        >
          <Grid item>
            <FormControl>
              <InputLabel htmlFor="layer">Shown Layer</InputLabel>
              <Select
                style={{ minWidth: 100, maxWidth: 140 }}
                value={id}
                onChange={this.handleLayerChange}
                inputProps={{
                  name: "layer",
                  id: "layer"
                }}
              >
                {layersList.map(({ id, name }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ width: 150, height: 100 }}>
            <IllustrationLegend
              polarization={polarization}
              incidentPlaneAngle={this.getIncidentPlaneAngle()}
            />
          </Grid>
        </Grid>
      </div>
    ) : null;
  }
}

IncidentLightIllustration.propTypes = {
  incidentLight: PropTypes.any.isRequired
};

const mapState = state => ({
  layers: StructureSelector.getLayers(state),
  sweptVariables: SimulationSettingsSelector.getSweptVariables(state)
});

export default connect(
  mapState,
  null
)(withErrorBoundary(withStyles(styles)(IncidentLightIllustration)));
