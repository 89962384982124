import { actionType } from "MetaCell/actions/SimulationSettings";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testMaxDiffractionOrders = {
  byId: {
    1: {
      id: 1,
      X: 1,
      Y: 1
    }
  },
  allIds: [1]
};

export const testProbes = [
  {
    id: 1,
    name: "probe 1",
    position: "=position",
    simulation: 1
  },
  {
    id: 2,
    name: "probe 2",
    position: "30",
    simulation: 2
  }
];

export const testSweptVariables = [
  {
    id: 1,
    variableName: "variable_1",
    values: "[2, 3, 4, 5]",
    simulation: 1
  },
  {
    id: 2,
    variableName: "variable_2",
    values: "[10, 20, 30]",
    simulation: 1
  },
  {
    id: 3,
    variableName: "variable_3",
    values: "[10, 20, 30]",
    simulation: 2
  },
  {
    id: 4,
    variableName: "position",
    simulation: 1,
    values: "[100, 200, 300]"
  },
  {
    id: 5,
    variableName: "variable 4",
    values: "[11, 22, 33]",
    simulation: 1
  }
];

/**
 * @constant
 * @typedef {Object} SimulationSettingsDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    maxDiffractionOrders: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    editingMaxDiffractionOrders: {
      id: null,
      X: "",
      Y: ""
    },
    editingSimulationDescription: null
  },
  fieldProbes: [],
  selectedFieldProbeId: -1,
  fieldProbeBeingEditedId: -1,
  sweptVariables: null
};

/**
 * Reducer function to manipulate the state of simulation settings
 * @author Ibtihel
 * @param {Object} [state=SimulationSettingsDefaultState] - simulation settings entities and ui management
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_MAX_DIFFRACTION_ORDERS: {
      let maxDiffractionOrders = cloneDeep(state.entities.maxDiffractionOrders);
      Utils.addOrUpdateEntities(payload, maxDiffractionOrders);
      return {
        ...state,
        entities: { ...state.entities, maxDiffractionOrders },
        ui: { ...state.ui, editingMaxDiffractionOrders: payload[0] }
      };
    }

    case actionType.CHANGE_EDITING_MDO_FIELD: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingMaxDiffractionOrders: {
            ...state.ui.editingMaxDiffractionOrders,
            ...payload
          }
        }
      };
    }

    case actionType.SET_EDITING_SIMULATION_DESCRIPTION: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingSimulationDescription: payload
        }
      };
    }

    case actionType.RESET_MAX_DIFFRACTION_ORDERS: {
      return defaultState;
    }

    case actionType.ADD_FIELD_PROBE: {
      return {
        ...state,
        fieldProbes: state.fieldProbes.concat(payload)
      };
    }

    case actionType.SELECT_FIELD_PROBE: {
      return {
        ...state,
        selectedFieldProbeId: payload,
        fieldProbeBeingEditedId:
          state.fieldProbeBeingEditedId !== payload
            ? -1
            : state.fieldProbeBeingEditedId
      };
    }

    case actionType.EDIT_FIELD_PROBE: {
      return { ...state, fieldProbeBeingEditedId: payload };
    }

    case actionType.CHANGE_FIELD_PROBE: {
      const fieldProbes = cloneDeep(state.fieldProbes);
      const probeIndex = fieldProbes.findIndex(
        probe => probe.id === payload.id
      );
      if (fieldProbes[probeIndex]) {
        fieldProbes[probeIndex] = {
          ...fieldProbes[probeIndex],
          ...payload.data
        };
      }
      return {
        ...state,
        fieldProbes: [...fieldProbes]
      };
    }

    case actionType.DELETE_FIELD_PROBE: {
      const { fieldProbes } = state;
      const index = fieldProbes.findIndex(val => val && val.id === payload);
      fieldProbes.splice(index, 1);
      return {
        ...state,
        fieldProbes: [...fieldProbes]
      };
    }

    case actionType.SET_SWEPT_VARIABLES: {
      return {
        ...state,
        sweptVariables: payload
      };
    }

    case actionType.ADD_SWEPT_VARIABLE: {
      const newSweptVariables = cloneDeep(state.sweptVariables);
      newSweptVariables.push(payload);
      return {
        ...state,
        sweptVariables: newSweptVariables
      };
    }

    case actionType.REMOVE_SWEPT_VARIABLE: {
      let sweptVariables = cloneDeep(state.sweptVariables);
      sweptVariables = sweptVariables.filter(
        variable => variable.id !== payload
      );
      return {
        ...state,
        sweptVariables
      };
    }

    case actionType.UPDATE_SWEPT_VARIABLE: {
      let sweptVariables = cloneDeep(state.sweptVariables);
      const index = sweptVariables.findIndex(
        val => val && val.id === payload.id
      );
      sweptVariables[index] = payload;
      return {
        ...state,
        sweptVariables
      };
    }

    case actionType.UPDATE_MAX_DIFFRACTION_ORDER: {
      const { maxDiffractionOrders } = state.entities;
      maxDiffractionOrders.byId[payload.id] = payload;
      return {
        ...state,
        entities: { ...state.entities, maxDiffractionOrders }
      };
    }

    case actionType.SET_PROBES: {
      return {
        ...state,
        fieldProbes: payload
      };
    }

    default:
      return state;
  }
}
