import { actionType } from "MetaCell/actions/SimulationTargets";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

export const defaultState = {
  entities: {
    simulationTargets: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

export const testSimulationTargets = {
  byId: {
    1: {
      id: 1,
      name: "test simulation target",
      optimizer: "none"
    },
    2: {
      id: 2,
      name: "AAAAA",
      optimizer: "none"
    }
  },
  allIds: ["1", "2"],
  loaded: true
};

export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_SIMULATION_TARGETS: {
      let simulationTargets = cloneDeep(
        defaultState.entities.simulationTargets
      );
      Utils.addOrUpdateEntities(payload, simulationTargets);
      return {
        ...state,
        entities: { ...state.entities, simulationTargets }
      };
    }

    case actionType.UPSERT_SIMULATION_TARGETS: {
      let simulationTargets = cloneDeep(state.entities.simulationTargets);
      Utils.addOrUpdateEntities(payload, simulationTargets);
      return {
        ...state,
        entities: { ...state.entities, simulationTargets }
      };
    }

    case actionType.DELETE_SIMULATION_TARGET: {
      let simulationTargets = cloneDeep(state.entities.simulationTargets);
      Utils.deleteEntities([payload], simulationTargets);
      return {
        ...state,
        entities: { ...state.entities, simulationTargets }
      };
    }

    default:
      return state;
  }
}
