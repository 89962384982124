import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import {
  withStyles,
  Stepper,
  Button,
  Step,
  StepLabel,
  StepContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import UserSelector from "BaseApp/selectors/User";
import { FamilySelector } from "MetaCell/selectors/Family";
import FamilyAction from "MetaCell/actions/Family";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MaterialTable from "material-table";
import FamilyApi from "MetaCell/api/Family";
import SimulationResult from "../../../../../../MetaCell/containers/SimulateCanvas/components/SimulationResult";
import "./FamilyMembers.css";
import SimulationSelector from "MetaCell/selectors/Simulation";
import Spinner from "components/Spinner/Spinner";
import SimulationAction from "MetaCell/actions/Simulation";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import PlotboxManager from "MetaCell/components/PlotboxManager/PlotboxManager";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import HelperUtils from "MetaCell/helper/HelperUtils";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import debounce from "lodash.debounce";
import Sync from "@material-ui/icons/Sync";
import { findLast } from "lodash";

const styles = {
  dialogTitleBar: {
    padding: 0
  },
  dialogActionBar: {
    justifyContent: "flex-start"
  },
  appBar: {
    position: "relative"
  },
  title: {
    flex: 1
  },
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

/**
 * the list of disabled action buttons on simulation view
 */
export const disabledActions = ["export", "newSimulation", "fileName"];

export class FamilyMemberWizard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      members: [],
      simulationJobs: null,
      loading: true,
      selectedJobs: [],
      saving: false,
      hide: false,
      fetchingJobs: false,
      snackbar: {
        visible: false,
        message: ""
      },
      isSaved: false
    };
  }

  confirmClose = () => {
    const { selectedJobs, activeStep } = this.state;
    if (selectedJobs?.length > 0) {
      const { showConfirmDialog } = this.props;
      const title = "Closing?";
      const steps = this.getSteps();
      const message =
        activeStep === steps.length
          ? "Metacells are being added in the background, a notification will be shown when this is finished. You can safely move away"
          : "The changes you made, including job selection and selected points will be lost.";
      showConfirmDialog(
        title,
        message,
        () => this.handleClose(true),
        undefined,
        false
      );
    } else {
      this.handleClose();
    }
  };

  /**
   *
   * @callback
   */
  handleClose = (cancel = false) => {
    const { wizardDialog, resetSelectedResultsAction } = this.props;
    this.handleReset();
    this.props.closeWizardDialogAction();
    this.props.wizardClose(wizardDialog.familyId, !cancel);
    if (cancel) {
      this.setState({ saving: false });
    } else {
      this.setState({ saving: false, simulationJobs: null });
    }
    // Reset the selected plot results
    resetSelectedResultsAction();
  };

  handleNext = async () => {
    let currentState = Object.assign({}, this.state);

    this.setState({ isSaved: true });

    if (currentState.activeStep === 0) {
      this.setState({
        activeStep: currentState.activeStep + 1
      });
    }

    if (currentState.activeStep === 1) {
      await this.generateTempMembers(currentState.activeStep + 1);
    }

    // save if the last step
    if (currentState.activeStep === 2) {
      await this.saveMembers(
        () => {
          this.handleClose();
          this.setState({ isSaved: false });
        },
        () =>
          this.setState({
            activeStep: currentState.activeStep + 1,
            saving: true
          }),
        message =>
          this.setState({
            snackbar: {
              visible: true,
              message
            },
            isSaved: false
          })
      );
    } else {
      this.setState({ isSaved: false });
    }
  };

  extractMBRNumber(alias) {
    var match = alias.match(/\bmbr (\d+)\b/);
    return match ? parseInt(match[1]) : null;
  }

  getTempMemberAutoNumbering(familyMembers) {
    const nrOfMembers = familyMembers.length;
    const lastAutoNumberedMember = findLast(familyMembers, m =>
      m.alias.includes("- mbr")
    );
    return lastAutoNumberedMember
      ? this.extractMBRNumber(lastAutoNumberedMember.alias) + 1
      : nrOfMembers;
  }

  /**
   * it creates temporary members with a predefined naming convention
   * @param {Number} newStep - to which step the wizard will change
   */
  async generateTempMembers(newStep) {
    const { selectedResults, wizardDialog, families } = this.props;
    const { familyId } = wizardDialog;
    const existingMembers = await FamilyApi.fetchMembersByFamily(familyId);
    const autoNumberingStart = this.getTempMemberAutoNumbering(existingMembers);
    const familyName = families.byId[familyId].name;
    const membersData = selectedResults.map((result, index) => {
      return {
        alias: `${familyName} - mbr ${autoNumberingStart + index}`,
        values: this.getSelectedPlotValues(result.plotValues)
      };
    });
    return this.setState({
      members: membersData,
      activeStep: newStep
    });
  }

  getCreateFamilyMembersJobStatusWithDelay = debounce(
    this.getCreateFamilyMemberJobStatus,
    5000
  );

  getCreateFamilyMemberJobStatus(familyId, onFinish) {
    return FamilyApi.createFamilyMembersJobProgress(familyId)
      .then(resp => resp.data)
      .then(data => {
        if (data.status === "QUEUED" || data.status === "RUNNING") {
          this.setState({
            progress: data.progress
          });
          return this.getCreateFamilyMembersJobStatusWithDelay(
            familyId,
            onFinish
          );
        } else if (data.status === "DONE" || data.status === "FAILED") {
          if (data.status === "DONE") {
            this.setState({
              progress: data.progress
            });
            onFinish();
          } else {
            if (data.errors && data.errors["error"]) {
              this.setState({
                snackbar: {
                  visible: true,
                  message: data.errors["error"]
                }
              });
            }
          }
        }
      });
  }

  saveMembers = async (onFinish, onSuccessJobStart, onError) => {
    const {
        wizardDialog,
        createFamilyMembersAction,
        selectedResults
      } = this.props,
      { members } = this.state,
      membersToBeCreated = members.map((member, index) => {
        const { sweepVariableValues } = selectedResults[index];
        const plotValues = selectedResults[index].plotValues
          ? Object.values(selectedResults[index].plotValues[0])[0].split("/")
          : false;
        // backward compatibility
        // TODO: make plot options key-based instead of position-based
        let incidentLight = false;
        if (plotValues && plotValues.length === 6) {
          incidentLight = plotValues[0];
        }
        return {
          alias: member.alias,
          simulation_job: this.state.selectedJobs[0].id,
          family: wizardDialog.familyId,
          swept_variable_values: sweepVariableValues,
          diff_summary: member.diff_summary,
          incident_light: incidentLight
        };
      });
    const onFinishJob = async () => {
      const createdFamilyMembersResponse = await FamilyApi.createFamilyMembersJobResults(
        wizardDialog.familyId
      );
      const nrMissed =
        membersToBeCreated.length -
        createdFamilyMembersResponse.data.created_family_members.length;
      const jobMessages = createdFamilyMembersResponse.data.messages;
      const message = `${nrMissed} metacells where skipped since the identical structure was already included (possibly with different input light settings) OR the configuration was not found in the simulation. Each unique metacell structure will collect all necessary input light calculations (if necessary for further metacomponent design, the output of new input light settings will be calculated on the fly and attached to this structure)`;
      if (nrMissed || jobMessages?.length) {
        const displayMessage = `${
          nrMissed ? message + ".\n\n" : ""
        }${jobMessages.join(".")}`;
        this.setState({
          snackbar: {
            visible: true,
            message: displayMessage
          }
        });
      }
      await FamilyAction.upsertFamilyMembers(createdFamilyMembersResponse.data);
      onFinish();
    };
    FamilyApi.startCreateFamilyMembersJob(membersToBeCreated)
      .then(() => {
        onSuccessJobStart();
        this.getCreateFamilyMembersJobStatusWithDelay(
          wizardDialog.familyId,
          onFinishJob
        );
      })
      .catch(e => {
        onError(e.response?.data || "Cannot Find jobs");
      });
  };

  getFindCompatibleJobsStatusWithDelay = debounce(
    this.getFindCompatibleJobsStatus,
    5000
  );

  getFindCompatibleJobsStatus(familyId, onFinish) {
    return FamilyApi.findCompatibleJobProgress(familyId)
      .then(resp => resp.data)
      .then(data => {
        if (data.status === "QUEUED" || data.status === "RUNNING") {
          return this.getFindCompatibleJobsStatusWithDelay(familyId, onFinish);
        } else if (data.status === "DONE" || data.status === "FAILED") {
          if (data.status === "DONE") {
            onFinish();
          } else {
            if (data.errors && data.errors["error"]) {
              this.setState({
                snackbar: {
                  visible: true,
                  message: data.errors["error"]
                }
              });
            }
          }
        }
      });
  }

  findCompatibleJobs = async (data, onFinish, onSuccessJobStart, onError) => {
    const { wizardDialog } = this.props;
    const { familyId } = wizardDialog;
    this.setState({
      snackbar: {
        visible: false,
        message: ""
      },
      fetchingJobs: true,
      simulationJobs: []
    });
    await FamilyApi.startFindCompatibleJob(familyId)
      .then(() => {
        onSuccessJobStart();
        this.getFindCompatibleJobsStatusWithDelay(familyId, onFinish);
      })
      .catch(e => {
        onError(e.response?.data || "Cannot Find jobs");
      });
  };

  componentDidMount() {
    this.setState({
      selectedJobs: [],
      simulationJobs: null
    });
  }

  handleJobSelection = rows => {
    this.setState({
      selectedJobs: rows
    });
  };

  getSteps = () => {
    return [
      "Select a Meta cell SimulationJob",
      "Chose specific result(s) from the selected SimulationJob",
      "Name the new Meta cells"
    ];
  };

  /**
   * @callback
   * @param {Object} newData - the new family member data
   * @param {Object} oldData - the old family member data
   * @returns {Promise} a promise that calls the update member method
   */
  onRowUpdate = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      // this.membersData[oldData.tableData.id].alias = newData.alias;
      const dataUpdate = [...this.state.members];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      this.setState({ members: [...dataUpdate] });
      resolve();
    });
  };

  forcePlotboxManagerRemount = () => {
    setTimeout(() => {
      this.setState({ hide: true });
      this.setState({ hide: false });
    }, 300);
  };

  getStepContent = step => {
    const { wizardDialog, user, classes } = this.props;
    const { fetchingJobs } = this.state;
    switch (step) {
      case 0:
        return (
          <div className="jobs-table">
            <MaterialTable
              title="Simulation jobs"
              columns={[
                { title: "Project", field: "project" },
                { title: "Simulation", field: "simulation" },
                {
                  title: "Simulation Date",
                  type: "date",
                  defaultSort: "desc",
                  field: "creationDate",
                  render: HelperUtils.getJobLabel
                },
                {
                  title: "Status",
                  field: "status"
                }
              ]}
              data={this.state.simulationJobs}
              actions={[
                {
                  icon: "search",
                  tooltip: "",
                  isFreeAction: true,
                  disabled: fetchingJobs,
                  onClick: () =>
                    this.findCompatibleJobs(
                      {},
                      this.fetchSimulationJobs,
                      () => this.setState({ fetchingJobs: true }),
                      message =>
                        this.setState({
                          snackbar: {
                            visible: true,
                            message
                          }
                        })
                    )
                }
              ]}
              components={{
                Action: props => (
                  <Button
                    onClick={event => props.action.onClick(event, props.data)}
                    color="primary"
                    variant="outlined"
                    style={{ textTransform: "none", marginRight: "10px" }}
                    size="small"
                    test-data="findCompatibleJobBtn"
                  >
                    Update compatible jobs
                    <Sync />
                  </Button>
                )
              }}
              options={{
                search: false,
                pageSize: 5,
                pageSizeOptions: [5, 10, 15, 20, 25],
                selection: true,
                selectionProps: rowData => ({
                  disabled:
                    this.state.selectedJobs.length === 1 &&
                    this.state.selectedJobs[0].tableData.id !==
                      rowData.tableData.id,
                  color: "primary"
                })
              }}
              onSelectionChange={this.handleJobSelection}
              localization={{
                header: {
                  actions: ""
                },
                body: {
                  emptyDataSourceMessage: fetchingJobs ? (
                    <Spinner
                      size={50}
                      timeout={60000}
                      test-data="job-fetch-spinner"
                    />
                  ) : (
                    <span test-data="empty-data-message">
                      {"No compatible simulation jobs found."}
                    </span>
                  )
                }
              }}
            />
          </div>
        );
      case 1:
        return (
          <div>
            {!this.state.hide && this.state.selectedJobs.length && (
              <PlotboxManager simulationJobId={this.state.selectedJobs[0].id}>
                <SimulationResult
                  forcePlotboxManagerRemount={this.forcePlotboxManagerRemount}
                  simulationId={this.state.selectedJobs[0].simulationId}
                  simulationJobId={this.state.selectedJobs[0].id}
                  projectName={""}
                  simulationName={""}
                  username={user ? user.username : ""}
                  classes={classes}
                  disabledActions={disabledActions}
                  inWizardView={true}
                  showConfirmPlotboxDeletion={this.showConfirmPlotboxDeletion}
                />
              </PlotboxManager>
            )}
          </div>
        );
      case 2: {
        const { members } = this.state;
        return (
          <div>
            {members && (
              <MaterialTable
                title="Meta cells"
                columns={[
                  {
                    title: "Alias",
                    field: "alias"
                  },
                  {
                    title: "Diff summary",
                    field: "diff_summary"
                  },
                  {
                    title: "Values",
                    field: "values",
                    editable: "never"
                  }
                ]}
                data={members}
                options={{
                  search: false,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 15, 20, 25]
                }}
                editable={{
                  onRowUpdate: this.onRowUpdate
                }}
                localization={{
                  body: {
                    editRow: {
                      deleteText: ""
                    }
                  },
                  header: {
                    actions: ""
                  }
                }}
              />
            )}
          </div>
        );
      }
      default:
        return "Unknown step";
    }
  };

  getSelectedPlotValues = plotValues => {
    let valuesList = "";
    plotValues.forEach((values, key) => {
      let value = "";
      Object.keys(values).forEach(key => {
        if (values[key].indexOf("/") !== -1) {
          const labelsArray = values[key].split("/");
          value += labelsArray[labelsArray.length - 1] + "=";
        } else {
          value += values[key] + "; ";
        }
      });
      valuesList += value;
    });
    return valuesList;
  };

  handleBack = () => {
    let currentState = Object.assign({}, this.state);
    this.setState({
      activeStep: currentState.activeStep - 1
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      selectedJobs: []
    });
  };

  fetchSimulationJobs = () => {
    const { wizardDialog } = this.props;
    const existingSimulationJobs = this.state.simulationJobs;
    if (wizardDialog.familyId) {
      this.setState({
        simulationJobs: [],
        fetchingJobs: true
      });
      FamilyApi.fetchSimulationJobs(wizardDialog.familyId).then(jobs => {
        if (!jobs?.length && existingSimulationJobs == null) {
          this.findCompatibleJobs(
            {},
            this.fetchSimulationJobs,
            () => this.setState({ fetchingJobs: true }),
            message =>
              this.setState({
                snackbar: {
                  visible: true,
                  message
                }
              })
          );
          return;
        } else {
          this.setState({
            simulationJobs: jobs?.map((job, index) => {
              return {
                id: job.id,
                startDate:
                  (job.startDate &&
                    `${new Date(job.startDate).toISOString().split("T")[0]} ${
                      new Date(job.startDate)
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0]
                    }`) ||
                  "-",
                endDate:
                  (job.endDate &&
                    `${new Date(job.endDate).toISOString().split("T")[0]} ${
                      new Date(job.endDate)
                        .toISOString()
                        .split("T")[1]
                        .split(".")[0]
                    }`) ||
                  "-",
                creationDate: job.creationDate,
                status: job.status,
                simulationId: job.simulation,
                simulation: job.simulation_name,
                project: job.project_name
              };
            }),
            loading: false,
            fetchingJobs: false
          });
        }
      });
    }
  };

  /**
   * it is supposed to be passed to the result component.
   * it opens the confirm dialog to delete a plot.
   * @param {Function} deleteFunction - what to do on confirm
   * @callback
   */
  showConfirmPlotboxDeletion = deleteFunction => {
    const { showConfirmDialog } = this.props;
    const title = "Delete plot";
    const message = "Are you sure you want to delete this plot?";
    showConfirmDialog(title, message, deleteFunction, undefined, false);
  };

  render = () => {
    const { wizardDialog, classes, selectedResults } = this.props,
      { saving, selectedJobs, activeStep, isSaved, progress } = this.state;
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="up" ref={ref} {...props} />;
    });
    const steps = this.getSteps();

    return (
      <div>
        <UnselfishDialog
          fullWidth
          open={wizardDialog.openStatus}
          onClose={this.confirmClose}
          maxWidth="lg"
          onEnter={this.fetchSimulationJobs}
          disableBackdropClick="true"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={this.confirmClose}
            disableTypography={true}
            className={classes.dialogTitleBar}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  test-data="closeBtn"
                  edge="start"
                  color="inherit"
                  onClick={this.confirmClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </DialogTitle>
          <DialogContent dividers style={{ padding: "24px 8px 8px 24px" }}>
            <Stepper
              activeStep={this.state.activeStep}
              orientation="vertical"
              style={{ padding: 0 }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {this.getStepContent(index)}
                    <div className={classes.actionsContainer}>
                      <div></div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </DialogContent>
          <DialogActions className={classes.dialogActionBar}>
            <Button
              test-data="cancelBtn"
              onClick={this.confirmClose}
              color="primary"
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              test-data="backBtn"
              disabled={
                this.state.activeStep === 0 ||
                saving ||
                (this.state.activeStep === 1 && selectedResults?.length > 0)
              }
              onClick={this.handleBack}
              className={classes.button}
            >
              Back
            </Button>
            <Button
              test-data="nextBtn"
              variant="contained"
              color="primary"
              onClick={this.handleNext}
              className={classes.button}
              disabled={
                saving ||
                isSaved ||
                selectedJobs?.length < 1 ||
                (activeStep == 2 && selectedResults?.length < 1)
              }
            >
              {this.state.activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
            {saving && (
              <>
                <Typography variant="caption">
                  the meta cell group members are being processed... this may
                  take a while.
                </Typography>
                <Spinner size={25} timeout={60000} progress={progress} />
              </>
            )}
          </DialogActions>
        </UnselfishDialog>
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  wizardDialog: FamilySelector.getWizardDialog(state),
  selectedResults: SimulationSelector.getSelectedResults(state),
  user: UserSelector.getUser(state),
  families: FamilySelector.getFamilies(state)
});

const mapDispatchToProps = dispatch => ({
  closeWizardDialogAction: () =>
    dispatch(
      FamilyAction.setMemberWizardOpen({
        openStatus: false,
        familyId: null
      })
    ),
  createFamilyMembersAction: (familyId, familyMembers, showWarningCallback) =>
    dispatch(
      FamilyApi.createFamilyMembers(
        familyId,
        familyMembers,
        showWarningCallback
      )
    ),
  resetSelectedResultsAction: () =>
    dispatch(SimulationAction.resetSelectedResults()),
  showConfirmDialog: (
    title,
    message,
    confirmAction,
    cancelAction,
    isReduxAction
  ) =>
    dispatch(
      ConfirmDialogAction.show(
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(FamilyMemberWizard)));
