import Axios from "axios";
import Action from "BaseApp/actions/User";
import DirectoryExplorerAction from "MetaCell/actions/DirectoryExplorer";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import GenericApi from "Api";

/**
 * A class created to perform http operations related to the user entity.
 * @author Akira Kotsugai
 */
export default class UserApi {
  /**
   * it generates a url to fetch the logged in user
   */
  static getCurrentUserUrl = () => `${GenericApi.getBaseUrl()}/user`;

  static getCurrentUserProfileUrl = () =>
    `${UserApi.getCurrentUserUrl()}/profile`;

  /**
   * it can fetch the logged in user.
   * @return a function that receives a dispatcher to redux
   */
  static getLoggedInUser = () => {
    return dispatch => {
      return Axios.get(UserApi.getCurrentUserUrl())
        .then(res => res.data)
        .then(user => {
          dispatch(Action.setUser(user));
          const userItem = {
            id: user.id,
            name: user.username,
            type: itemType.USER
          };
          dispatch(DirectoryExplorerAction.updateSelectedItem(userItem));
        })
        .catch(error => {
          console.log("error from User API:", error.message);
        });
    };
  };

  static getUserSettings = () => {
    return dispatch => {
      return Axios.get(`${GenericApi.getBaseUrl()}/user/settings`)
        .then(({ data }) => {
          dispatch(Action.setUserSettings(data));
        })
        .catch(error => {
          console.log("error from User API:", error.message);
        });
    };
  };

  static getVersion = () => {
    return dispatch => {
      return Axios.get(`${GenericApi.getBaseUrl()}/version`)
        .then(({ data }) => {
          dispatch(Action.setVersion(data));
        })
        .catch(error => {
          console.log("error from User API:", error.message);
        });
    };
  };

  static postUserSettings = params => {
    return dispatch => {
      return Axios.post(`${GenericApi.getBaseUrl()}/user/settings`, params)
        .then(({ data }) => {
          dispatch(Action.setUserSettings(data));
        })
        .catch(error => {
          console.log("error from User API:", error.message);
        });
    };
  };

  /**
   * it sends a request to the user profile endpoint to update a field
   * @param {String} field - the profile field
   * @param {String} value - the new field value
   */
  static updateProfile = (field, value) => {
    return dispatch => {
      let payload = {};
      payload[field] = value;
      return Axios.post(UserApi.getCurrentUserProfileUrl(), payload)
        .then(res => res.data)
        .then(user => dispatch(Action.setUser(user)));
    };
  };
}
