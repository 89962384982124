import React, { PureComponent } from "react";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-terminal";

/**
 * a component where the user can write python code
 * @author Akira Kotsugai
 */
export class CodeEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: ""
    };
  }

  componentDidMount() {
    this.setCode(this.props.script);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.script !== this.props.script) {
      this.setCode(this.props.script);
    }
  }

  /**
   * it updates the script in the state and calls the parent callback to also update
   * the script at a higher level
   * @param {String} code - the script
   */
  setCode = code => {
    this.setState({ code });
    this.props.updateCode(code);
  };

  render = () => {
    return (
      <AceEditor
        mode="python"
        theme="terminal"
        onChange={this.setCode}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={this.state.code}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2
        }}
      />
    );
  };
}

export default withErrorBoundary(CodeEditor);
