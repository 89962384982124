import Api from "Api";
import store from "store";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import * as mathjs from "mathjs";

/**
 * A helper class used to group auxiliary functions for helper classes
 * @author Akira Kotsugai
 */
export default class HelperUtils {
  /**
   * @param {Number} upper - max value
   * @param {Number} lower - min value
   * @param {Number} steps - how many steps between min and max
   * @returns {Number[]} - the step values
   */
  static getRange(upper, lower, steps) {
    const difference = upper - lower;
    const increment = difference / (steps - 1);
    return [
      lower,
      ...Array(steps - 2)
        .fill("")
        .map((_, index) => lower + increment * (index + 1)),
      upper
    ];
  }

  /**
   * @param {Number} start - the starting number
   * @param {Number} stop - the stopping number
   * @param {Number} step - how much will be added to each iteration
   * @returns {Number[]} an array of numbers that goes from the starting number until the stop number
   */
  static arange(start, stop, step) {
    step = step || 1;
    var arr = [];
    for (var i = start; i < stop; i += step) {
      arr.push(parseFloat(i.toFixed(6)));
    }
    return arr;
  }

  /**
   * @param {Number[]} arr - array
   * @param {Number} a - min value
   * @param {Number} b - max value
   * @returns {Number[]} filtered arr
   */
  static filterRange(arr, a, b) {
    // added brackets around the expression for better readability
    return arr.filter(item => a <= item && item <= b);
  }

  /**
   * @param {Number} min - min value
   * @param {Number} max - max value
   * @param {Number[]} arr - colors array
   * @returns {Number[]} arr of colors
   */
  static filterColorRange(min, max, arr, normalise = false) {
    const newColorArr = arr.map(val => {
      if (val >= min && val <= max) {
        return val;
      } else {
        if (normalise && val <= min) {
          return min;
        }
        if (normalise && val >= max) {
          return max;
        }
        return null;
      }
    });
    return newColorArr;
  }

  /**
   * @param {Number[]} matrix
   * @returns {Number[]} - how many items in X and Y directions there are
   */
  static getMatrixShape(matrix) {
    const nrOfElementsInYDirection = matrix.length,
      nrOfElementsInXDirection = matrix[0].length;
    return [nrOfElementsInXDirection, nrOfElementsInYDirection];
  }

  static readJsonFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (function(theFile) {
        return function(e) {
          try {
            const data = e.target.result;
            resolve(JSON.parse(data));
          } catch (exception) {
            reject(exception);
          }
        };
      })(file);
      reader.readAsText(file);
    });
  };

  /**
   * it finds the most recent entity record in a list of entities
   * @param {Object[]} entities - the entity records
   * @returns the most recent one
   */
  static getMostRecentRecord = entities => {
    const mostRecentDate = new Date(
      Math.max.apply(
        null,
        entities.map(record => new Date(record.creationDate))
      )
    );
    const mostRecentRecord = entities.find(
      record =>
        new Date(record.creationDate).getTime() == mostRecentDate.getTime()
    );
    return mostRecentRecord;
  };

  /**
   * opens the confirmation dialog asking if the user wants to report the bug
   * @param {Object} error
   */
  static offerUserToReportError(
    error,
    description,
    onReportSuccess,
    onReportFailure,
    errorDialogTitle = null
  ) {
    if (!process.env.REACT_APP_STANDALONE) {
      const dialogTitle = errorDialogTitle || "Error Encountered";
      const dialogMessage =
        "We are very sorry for the inconvenience. If you have not reported it yet, would you like to make us aware of this bug? You can help us reproduce the bug by describing how it was triggered.";
      const emailPlaceholder = "your@email.com";
      const bugDescriptionPlaceholder =
        "Describe the steps to reproduce the bug";
      const dialogConfirmAction = (formValues, uploadedFile) => {
        const userEmail = formValues[emailPlaceholder];
        const bugDescription = formValues[bugDescriptionPlaceholder];
        return this.report(
          error,
          onReportSuccess,
          onReportFailure,
          userEmail,
          bugDescription,
          uploadedFile
        );
      };
      store.dispatch(
        ConfirmDialogAction.show(
          dialogTitle,
          description ? description + "\n\n" + dialogMessage : dialogMessage,
          dialogConfirmAction,
          undefined,
          false,
          null,
          [emailPlaceholder, bugDescriptionPlaceholder],
          true
        )
      );
    }
  }

  /**
   * it makes an endpoint call to report the bug and calls a callback depending if it worked
   * @param {Object} error - the error to be reported
   * @param {Function} onReportSuccess - what to do when the report works
   * @param {Function} onReportFailure - what to do when the report does not work
   * @param {String} userEmail - the email adress of the user who reported the bug
   * @param {String} bugDescription - the description of the bug given by the user
   * @param {File} uploadedFile - a json file given by the user
   */
  static report(
    error,
    onReportSuccess,
    onReportFailure,
    userEmail,
    bugDescription,
    uploadedFile
  ) {
    return Api.reportBug(error, userEmail, bugDescription, uploadedFile)
      .then(onReportSuccess)
      .catch(onReportFailure);
  }

  /**
   * it generates a submittable form data with the given object
   * but undefined or null values are ignored
   * @param {Object} object - the object to be converted
   * @returns {FormData} the form data
   */
  static getFormData = object => {
    const formData = new FormData();
    for (var key in object) {
      const field = object[key];
      if (field !== null && field !== undefined) {
        if (Array.isArray(field) || typeof field === "boolean") {
          formData.append(key, JSON.stringify(object[key]));
        } else {
          formData.append(key, object[key]);
        }
      }
    }
    return formData;
  };

  /**
   * recursively limits the decimal places of a set of data
   * @param {Number | Number[]} data
   * @param {Number} decimalPlaces - the decimal count
   */
  static limitDecimals = (data, decimalPlaces) => {
    if (data === null) {
      return null;
    }
    if (typeof data !== "number" && !Array.isArray(data)) {
      throw Error("it is not a numerical dataset");
    }
    if (Array.isArray(data)) {
      let newArray = [];
      for (const item of data) {
        newArray.push(this.limitDecimals(item, decimalPlaces));
      }
      return newArray;
    }
    return data.toFixed(decimalPlaces);
  };

  /**
   * it joins the text with a comma, but if it is the last element is joins with "and" and ands a period in the end.
   * Examples would be:
   * "Air, Rectangle and Substrate."
   * "Air and Substrate."
   * "Air."
   * @param {String[]} words - the string elements
   * @param {Boolean} ommitPeriod - whether the joined words should end with a period
   * @returns {String} the joined elements
   */
  static joinWords = (words, ommitPeriod) => {
    const joinedWords = [
      words.slice(0, -1).join(", "),
      words.slice(-1)[0]
    ].join(words.length < 2 ? "" : " and ");
    return ommitPeriod ? joinedWords : joinedWords + ".";
  };

  /**
   * @param {Number} number - the number to be shown
   * @param {String} decimalSeparator
   * @param {Number} maxDecimalPlaces
   * @param {String[]} validCharaters
   * @param {Number} maxIntegerZeros
   * @returns {String} the number formatted with the given decimal separator
   */
  static getDisplayableNumber(
    number,
    decimalSeparator,
    validCharacters = [";", ":"],
    maxDecimalPlaces = 6,
    maxIntegerZeros = 10,
    expoNotationChars = ["e", "E", "+", "-"]
  ) {
    const numberString = number + "";
    const numbersToParse = validCharacters
      ? numberString.split(new RegExp(`[${validCharacters.join("")}]`, "g"))
      : [numberString];
    const parsedNumbers = numbersToParse.map(number => {
      const numberDecimals = number.split(".");
      const [integer, decimals] = numberDecimals;
      const decimalsExceeded = decimals && decimals.length > maxDecimalPlaces;
      const decimalsZerosExceeded =
        decimalsExceeded &&
        decimals.substring(0, maxDecimalPlaces) === "000000";
      const prohibitedIntegerEnding = Array.from(Array(maxIntegerZeros).keys())
        .map(() => "0")
        .join("");
      const integerZerosExceeded = integer.endsWith(prohibitedIntegerEnding);
      const containsExpoNotationChars = number
        .split("")
        .some(char => expoNotationChars.includes(char));
      const numberToReturn =
        decimalsZerosExceeded || integerZerosExceeded
          ? parseFloat(number).toExponential()
          : decimalsExceeded && !containsExpoNotationChars
          ? parseFloat(number).toFixed(6)
          : number;
      return numberToReturn.split(".").join(decimalSeparator);
    });
    return parsedNumbers.join(validCharacters ? validCharacters[0] : "");
  }

  /**
   * it downloads the data in the browser
   * @param {*} data - the data to be download
   * @param {String} fileName - the file name
   */
  static browserDownload = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  /**
   * @param {String} from - the unit to scale from
   * @param {String} to - the unit to scale to
   * @returns the multiplier value
   */
  static scaleUnit = (from, to) => {
    const scales = Object.freeze({
      mm: 1e3,
      μm: 1,
      nm: 1e-3,
      pm: 1e-6
    });
    return scales[from] / scales[to];
  };

  static getJobLabel(job, hideStatus = false) {
    const dt = new Date(job.creationDate);
    const formattedDate = `${dt
      .getDate()
      .toString()
      .padStart(2, "0")}/${(dt.getMonth() + 1).toString().padStart(2, "0")}/${dt
      .getFullYear()
      .toString()
      .padStart(4, "0")} ${dt
      .getHours()
      .toString()
      .padStart(2, "0")}:${dt
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${dt
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return hideStatus ? formattedDate : `${formattedDate} - ${job.status}`;
  }

  static getAxisName = (label, unit) =>
    !unit || unit === "" ? label : `${label} (${unit})`;

  static isJsonString = str => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  static getAllValuesFromNestedJson(obj) {
    let values = [];
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach(item => {
          if (typeof item === "object" || Array.isArray(item)) {
            values = values.concat(this.getAllValuesFromNestedJson(item));
          } else {
            values.push(item);
          }
        });
      } else if (typeof obj[key] === "object") {
        values = values.concat(this.getAllValuesFromNestedJson(obj[key]));
      } else if (
        typeof obj[key] === "string" &&
        this.isJsonString(obj[key]) &&
        typeof JSON.parse(obj[key]) === "object"
      ) {
        const jsonObj = JSON.parse(obj[key]);
        values = values.concat(this.getAllValuesFromNestedJson(jsonObj));
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  }

  static extractObjStrings(jsonString) {
    const result = [];
    const json = JSON.parse(jsonString);

    function recursiveExtract(obj) {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          recursiveExtract(obj[key]);
        } else if (typeof obj[key] === "string") {
          result.push(obj[key]);
        }
      }
    }

    recursiveExtract(json);
    return result;
  }

  static getUnusedSweepVariableNames(store, variables) {
    const parsedStoreValues = this.getAllValuesFromNestedJson(store);
    // const stringValuesWithFormula = parsedStoreValues.filter(item => typeof item === 'string' && item.startsWith('='));
    const stringValuesWithFormula = parsedStoreValues
      .flatMap(item => {
        if (typeof item === "string" && item.startsWith("{")) {
          return this.extractObjStrings(item);
        } else {
          return [item];
        }
      })
      .filter(item => typeof item === "string" && item.startsWith("="));
    const sweepVariableNames = variables.map(variable => variable.variableName);
    let independentVarsUsedInFormula = [];
    let unusedIndepdendentSweepVarNames = [];
    variables.forEach(variable => {
      const varUsed = stringValuesWithFormula.some(str =>
        // str.includes(variable.variableName)
        new RegExp("\\b" + variable.variableName + "\\b").test(str)
      );
      // check in formula vars that were used
      if (varUsed && variable.sweepType === "Formula") {
        const trimmedFormula = variable.formula;
        //   ?.replace(/\s+/g, "")
        //   .replace(/\[+/g, "")
        //   .replace(/\]+/g, "")
        //   .replace(/\(+/g, "")
        //   .replace(/\)+/g, "");

        const parsed = mathjs.parse(trimmedFormula);

        independentVarsUsedInFormula = parsed
          .filter(node => node.isSymbolNode)
          .map(node => node.name)
          .filter(varName => sweepVariableNames.includes(varName));

        // const unusedVariables = Object.keys(sweepVarScope).filter(variable => !variablesInFormula.includes(variable));

        // const sweptVarNamesInFormula = trimmedFormula.split(/([+-/*])/);
        // independentVarsUsedInFormula = independentVarsUsedInFormula.concat(
        //   sweepVariableNames.filter(varName =>
        //     sweptVarNamesInFormula.includes(varName)
        //   )
        // );
      }
      if (!varUsed) {
        if (!unusedIndepdendentSweepVarNames.includes(variable.variableName))
          unusedIndepdendentSweepVarNames.push(variable.variableName);
      }
    });
    return unusedIndepdendentSweepVarNames?.filter(
      unusedVar => !independentVarsUsedInFormula.includes(unusedVar)
    );
  }

  /**
   * @param {Number[][][]} matrix - an array of matrices
   * @returns {Number[]} the min and max values of all matrices
   */
  static getZDataRange(zData) {
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    for (const matrix of zData) {
      const matrixRange = this.getMatrixRange(matrix);
      const currentMin = matrixRange[0];
      const currentMax = matrixRange[1];
      minValue = currentMin < minValue ? currentMin : minValue;
      maxValue = currentMax > maxValue ? currentMax : maxValue;
    }
    // return this.normaliseGraphRange(minValue, maxValue);
    return [minValue, maxValue];
  }

  /**
   * @param {Number[][]} matrix - an array of arrays
   * @returns {Number[]} the min and max values of all arrays
   */
  static getMatrixRange = matrix => {
    // debugger;
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    for (const row of matrix) {
      const currentMin = Math.min(...row);
      const currentMax = Math.max(...row);
      minValue = currentMin < minValue ? currentMin : minValue;
      maxValue = currentMax > maxValue ? currentMax : maxValue;
    }
    return [minValue, maxValue];
    // return this.normaliseGraphRange(minValue, maxValue);
  };

  static normaliseGraphRange(minValue, maxValue) {
    // Adding 10% buffer
    return [minValue * 0.9, maxValue * 1.1];
  }

  /**
   * @param {String} value - string to be checked
   * @param {Boolean} allowNegative - whether negative values are allowed
   * @returns whether the string is a valid exponential notation
   */
  static isExpoNotation = (value, allowNegative = true) => {
    const regex = new RegExp(
      allowNegative
        ? /^-?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)$/
        : /^[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)$/
    );
    return regex.test(value);
  };

  static sortByName = objects => {
    let sortedArr = [...objects];
    sortedArr.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    );
    return sortedArr;
  };

  static deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }

  static isObject(object) {
    return object != null && typeof object === "object";
  }

  /**
   * @param {String} expression - text to be validated whether it is a math expression
   * @param {String[]} variables - texts that are actually variables in the expression
   * @returns
   */
  static validateMathExpression(expression, variables = []) {
    try {
      const variablelessExpression = variables.reduce(
        (accumulator, variable) => {
          return accumulator.replace(new RegExp(`\\b${variable}\\b`, "g"), "0");
        },
        expression
      );
      mathjs.evaluate(variablelessExpression);
      return true;
    } catch {
      return false;
    }
  }

  /**
   * @param {String} value - text to be extract a variable from
   * @param {String[]} validVarNames - allowed variable names
   * @returns
   */
  static extractVariableName(value, validVarNames) {
    if (validVarNames.includes(value)) {
      return value;
    }
    for (const varName of validVarNames) {
      if (value.includes(varName)) {
        const resolvedValue = this.resolveMathExpression(value, {
          [varName]: 999
        });
        if (resolvedValue !== null) {
          return varName;
        }
      }
    }
    return null;
  }

  /**
   * @param {String} expression - text to be validated whether it is a math expression
   * @param {Object} variableValues - variable and variable values
   * @returns {Number} expression resolved
   */
  static resolveMathExpression(expression, variableValues = null) {
    try {
      const value = mathjs.evaluate(
        expression,
        variableValues ? variableValues : {}
      );
      return value;
    } catch {
      return null;
    }
  }

  static import_sample_keys = {
    "Meta Cell": [
      "global_parameters",
      "probe_parameters",
      "structure",
      "materials",
      "incident_light",
      "diffractionOrders",
      "swept_variables"
    ],
    "Meta Component": [
      "family",
      "mc_group",
      "family_members",
      "design_targets",
      "set_points",
      "sp_results",
      "ffwf_targets",
      "meta_component",
      "selected_design_targets"
    ],
    "Near Field Wave Front Target": [
      "NFWaveFront",
      "NFWidth",
      "NFHeight",
      "nfwf_phase_unit",
      "nfwf_amplitude_unit",
      "design_script"
    ],
    Materials: [
      "absorptionCoeff",
      "chemicalComposition",
      "color",
      "comment",
      "fileName",
      "manufacturer",
      "name",
      "reference",
      "refractiveIndex",
      "wavelength"
    ],
    "Far Field Target": [
      "name",
      "description",
      "data_compression",
      "center_coord",
      "euler_Angles",
      "WFWidth",
      "WFHeight",
      "unit",
      "tolerance",
      "iteration_Stop",
      "wave_front",
      "wave_front_design_script"
    ]
  };

  static calculateSimilarity(arr1, arr2) {
    var count = 0;
    arr1.forEach(element => {
      if (arr2.includes(element)) {
        count++;
      }
    });
    const similarityPercentage = count / arr1.length;
    return similarityPercentage;
  }

  static get_possible_import_destination(json_dict) {
    const message = "Invalid file or destination for import file.";
    try {
      const json_keys = Object.keys(json_dict);
      if (json_keys.includes("import_destination")) {
        return `${message} Possible destination to import: ${json_dict["import_destination"]}`;
      }
      var destinationMessage = "";
      Object.entries(this.import_sample_keys).forEach(
        ([destination, sample_keys]) => {
          if (destinationMessage === "") {
            const similarityPercentage = this.calculateSimilarity(
              sample_keys,
              json_keys
            );
            if (similarityPercentage > 0.8) {
              destinationMessage = `${message} Possible destination to import: ${destination}`;
            }
          }
        }
      );
      if (destinationMessage !== "") return destinationMessage;
      return message;
    } catch (error) {
      return message;
    }
  }

  static toRadians = (degrees, precision = 2) => {
    return parseFloat(
      ((parseFloat(degrees) * Math.PI) / 180).toFixed(precision)
    );
  };

  static toDegrees = (radians, precision = 2) => {
    return Math.round(parseFloat(radians) * (180 / Math.PI).toFixed(precision));
  };

  static caseInsensitiveSort(a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  static mergeObjects(obj1, obj2) {
    const mergedObj = {};

    for (const key in obj1) {
      if (obj1[key] === null && obj2[key] !== null) {
        mergedObj[key] = obj2[key];
      } else if (obj2[key] === null && obj1[key] !== null) {
        mergedObj[key] = obj1[key];
      } else {
        mergedObj[key] = obj1[key] !== null ? obj1[key] : obj2[key];
      }
    }

    return mergedObj;
  }

  static mergeArrayOfObjects(array, keysToMergeOn) {
    const resultArray = [];

    for (let i = 0; i < array.length; i++) {
      let mergedObject = array[i];
      for (let j = i + 1; j < array.length; j++) {
        const currentObject = array[j];
        let keysMatch = true;
        for (const key of keysToMergeOn) {
          if (currentObject[key] !== mergedObject[key]) {
            keysMatch = false;
            break;
          }
        }
        if (keysMatch) {
          mergedObject = this.mergeObjects(mergedObject, currentObject);
          array.splice(j, 1);
          j--;
        }
      }
      resultArray.push(mergedObject);
    }

    return resultArray;
  }
}
