import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MainNavButton from "./components/MainNavButton/MainNavButton";
import { connect } from "react-redux";
import { getNavigationHistory } from "BaseApp/selectors/navigationHistory";
import { withStyles } from "@material-ui/core";
import { Feature } from "flagged";
import { PaidFeatures } from "App";

const styles = {
  root: {
    width: "100%"
  }
};

/** A component to display the main navigation menu of the application
 * so far there are 3 main screens in the app: MetaCell, MetaCellComponents and Library
 * @author Ibtihel
 */

export class MainMenu extends Component {
  render = () => {
    const { classes, navigationHistory } = this.props,
      { metacell, metacellComponents, library } = navigationHistory || {};
    return (
      <div className={classes.root} test-data="mainMenu">
        <AppBar position="static" color="primary">
          <Toolbar>
            <MainNavButton
              name="MainMenuMetaCellButton"
              text="Meta Cell"
              to={!metacell || metacell === "" ? "/meta-cell" : metacell}
            />
            <Feature name={PaidFeatures.META_COMPONENT}>
              <MainNavButton
                test-data="metaComponentBtn"
                name="MainMenuMetaComponentButton"
                text="Meta Component"
                to={
                  !metacellComponents || metacellComponents === ""
                    ? "/meta-component"
                    : metacellComponents
                }
              />
            </Feature>
            <MainNavButton
              name="MainMenuLibraryButton"
              text="Library"
              to={!library || library === "" ? "/library" : library}
            />
            <MainNavButton
              name="MainMenuJobOverviewButton"
              text="Job overview"
              to={"/job-overview"}
            />
            <div style={{ flexGrow: 1 }} />
            <MainNavButton name="WikiNavButton" text="Wiki" to={"/wiki"} />
          </Toolbar>
        </AppBar>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  navigationHistory: getNavigationHistory(state)
});

export default connect(mapStateToProps, null)(withStyles(styles)(MainMenu));
