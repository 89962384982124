import React, { PureComponent } from "react";
import { withStyles, FormLabel, Grid } from "@material-ui/core";
import Wavefront from "MetaComponent/containers/TargetCanvas/components/Wavefront/Wavefront";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import Helper from "MetaComponent/helper/FFWFTarget";
import NumberInput from "components/NumberInput/NumberInput";
import debounce from "lodash.debounce";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import HelperUtils from "MetaCell/helper/HelperUtils";
import GenericApi from "Api";
import Axios from "axios";
import { decode } from "@msgpack/msgpack";

const styles = theme => ({
  groupTwoNumberFields: {
    width: "80%",
    marginBottom: 5
  },
  groupLabel: {
    fontSize: 12,
    marginBottom: 5
  }
});

const requiredFieldText = "required";

const dimensionsFormSchema = Yup.object().shape({
  NFWidth: Yup.string().required(requiredFieldText),
  NFHeight: Yup.string().required(requiredFieldText)
});

/**
 * a component to allow a nfwf target to be edited
 * @author Akira Kotsugai
 */
export class NFWFTargetForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImportFailed: false,
      exportLoading: false,
      fileError: null,
      snackbar: {
        visible: false,
        message: ""
      }
    };
  }

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getNFWFImportStatusWithDelay = debounce(this.getNFWFImportStatus, 5000);
  getAsyncNFWFImportStatusWithDelay = debounce(
    this.getAsyncNFWFImportStatus,
    5000
  );

  componentWillUnmount() {
    this.getNFWFImportStatusWithDelay.cancel();
  }

  /**
   * it keeps checking the status of a nfwf import until it is finished
   * *
   */
  getNFWFImportStatus(designTargetId, onFinish) {
    return DesignTargetApi.getNFWFImportStatus(designTargetId)
      .then(resp => resp.data.status)
      .then(status => {
        if (status === "ERROR") {
          this.setState({
            snackbar: {
              visible: true,
              message: "Failed to upload and import the given wavefront."
            }
          });
          this.getNFWFImportStatusWithDelay.cancel();
        }
        if (status === "QUEUED" || status === "RUNNING") {
          return this.getNFWFImportStatusWithDelay(designTargetId, onFinish);
        } else if (status === "DONE" || status === "FAILED") {
          if (status === "DONE") {
            this.props.requestWavefront(true);
          } else {
            this.setState({ showImportFailed: false }, () =>
              this.setState({ showImportFailed: true })
            );
            this.getNFWFImportStatusWithDelay.cancel();
          }
          return onFinish();
        }
      })
      .catch(e => {
        this.setState({
          snackbar: {
            visible: true,
            message: "Failed to upload and import the given wavefront."
          }
        });
        return onFinish();
      })
      .catch(e => {
        this.setState({
          snackbar: {
            visible: true,
            message: "Failed to upload and import the given wavefront."
          }
        });
        return onFinish();
      });
  }

  getAsyncNFWFImportStatus(designTargetId, onFinish) {
    return DesignTargetApi.getAsyncNFWFImportStatus(designTargetId)
      .then(resp => resp.data)
      .then(data => {
        if (data.status === "QUEUED" || data.status === "RUNNING") {
          return this.getAsyncNFWFImportStatusWithDelay(
            designTargetId,
            onFinish
          );
        } else if (data.status === "DONE" || data.status === "FAILED") {
          if (data.status === "DONE") {
            this.props.requestWavefront(true);
          } else {
            this.setState({ showImportFailed: false }, () => {
              if (data.errors && data.errors["error"]) {
                this.setState({
                  snackbar: {
                    visible: true,
                    message: data.errors["error"]
                  }
                });
              } else {
                this.setState({ showImportFailed: true });
              }
            });
          }
          return onFinish();
        }
      });
  }

  /**
   * the initial form values will consist of the given wavefront properties
   * @returns {Object} the form values
   */
  getFormInitialValues() {
    const { NFWidth, NFHeight, NFWaveFront } = this.props.focusedDesignTarget;
    return {
      NFWidth,
      NFHeight,
      NFWaveFront
    };
  }

  /**
   * it only sends the wavefront File, not the
   * wavefront object, because the object is only used in the representation.
   * @param {Object} values - the form values to submit
   */
  handleSubmit = (values, onFinish) => {
    let preparedValues = { ...values };
    delete preparedValues.NFWaveFront;
    this.props.onSubmit({ ...preparedValues }, onFinish);
  };

  /**
   * it exports the wavefront json of the active design target.
   * supposed to be passed down to the wavefront component
   * @callback
   */
  exportWavefront = async () => {
    const { focusedDesignTarget } = this.props;
    try {
      this.setState({ exportLoading: true });
      const data = await DesignTargetApi.exportNFWFTargetWavefront(
        focusedDesignTarget.id
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${focusedDesignTarget.name}_nf_wavefront.json`
      );
      link.click();
      window.URL.revokeObjectURL(url);
      this.setState({ exportLoading: false });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * it reads the wavefront file and submits it with the dimensions in the file
   * @param {File} wavefrontFile - the dropped wavefront file
   */
  handleWavefrontFileDrop = async (wavefrontFile, onFinish, onError) => {
    const fileSizeInBytes = wavefrontFile.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    var resp_key = undefined;
    if (fileSizeInMB > 200) {
      resp_key = await this.handleWaveFrontFileDropToS3(wavefrontFile);
    }
    let fileData = undefined;
    if (resp_key) {
      const s3Response = await this.getS3WavefrontFileData(resp_key);
      fileData = s3Response.data;
    } else {
      fileData = await Helper.readWavefrontFile(wavefrontFile);
    }
    const width = fileData["NFWidth"],
      height = fileData["NFHeight"];
    if (!resp_key) {
      const fileError = HelperUtils.get_possible_import_destination(fileData);
      if (fileError?.split(":")[1] !== " Near Field Wave Front Target") {
        this.setState({ fileError });
        onError();
        return;
      }
    }
    const { id } = this.props.focusedDesignTarget;
    this.handleSubmit(
      {
        id,
        NFWidth: width,
        NFHeight: height,
        ...(resp_key
          ? { s3FileKey: resp_key }
          : { wavefrontFile: wavefrontFile })
      },
      () => this.getNFWFImportStatusWithDelay(id, onFinish)
    );
  };

  getS3WavefrontFileData = async s3FileKey => {
    const fileData = await DesignTargetApi.getS3WavefrontFileData(s3FileKey);
    return fileData;
  };

  handleWaveFrontFileDropToS3 = async wavefrontFile => {
    let s3UploadUrl;
    try {
      s3UploadUrl = await GenericApi.getObjectUploadUrl(wavefrontFile.name);
    } catch (e) {
      if (e.response?.status == 405) {
        console.log(e.response.data);
      }
      return;
    }
    let response_key = null;
    return Axios.put(s3UploadUrl.data, wavefrontFile, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        delete headers["Authorization"];
        return data;
      }
    })
      .then(() => {
        return wavefrontFile.name;
      })
      .catch(e => {
        if (e.response?.status == 405) {
          console.log("Not using direct file uploads");
        } else {
          console.log(e);
        }
      });
  };

  generateAsyncWavefront = async (
    data,
    onFinish,
    onSuccessJobStart,
    onError
  ) => {
    const { focusedDesignTarget } = this.props;
    this.setState({
      snackbar: false,
      message: ""
    });
    await DesignTargetApi.startAsyncNfwfJob(focusedDesignTarget.id, data)
      .then(() => {
        onSuccessJobStart();
        this.getAsyncNFWFImportStatusWithDelay(
          focusedDesignTarget.id,
          onFinish
        );
      })
      .catch(e => {
        onError(e.response?.data || "Invalid code");
      });
  };

  /**
   * it creates a json file out of wavefront object and submits it with the same dimensions
   * because the script is not supposed to change the dimensions
   * @param {Object} wavefront - the wavefront
   */
  handleWavefrontGeneratorConfirmation = (wavefront, onFinish) => {
    const wavefrontObject = {
        NFWaveFront: wavefront,
        nfwf_phase_unit: "radians",
        nfwf_amplitude_unit: "V/m"
      },
      wavefrontJson = JSON.stringify(wavefrontObject),
      blob = new Blob([wavefrontJson], { type: "application/json" }),
      wavefrontFile = new File([blob], "file.json");

    const { focusedDesignTarget, componentWidth, componentHeight } = this.props;
    this.handleSubmit(
      {
        id: focusedDesignTarget.id,
        NFWidth: componentWidth,
        NFHeight: componentHeight,
        wavefrontFile
      },
      () => this.getNFWFImportStatusWithDelay(focusedDesignTarget.id, onFinish)
    );
  };

  deleteWavefront = () => {
    this.handleSubmit({
      id: this.props.focusedDesignTarget
    });
  };

  render() {
    const { fileError } = this.state;
    const { classes, isEditing, rowLayout, focusedDesignTarget } = this.props,
      disabled = !isEditing;
    return (
      <>
        <>
          <Grid container spacing={2}>
            <Grid item xs={rowLayout ? 6 : 12}>
              <Grid container>
                <Grid item xs={5}>
                  <Formik
                    initialValues={this.getFormInitialValues()}
                    enableReinitialize
                    validationSchema={dimensionsFormSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                      touched,
                      submitForm,
                      resetForm
                    }) => {
                      // making it possible to call these formik functions from parent components
                      this.props.bindSubmitForm(submitForm);
                      this.props.bindResetForm(resetForm);
                      return (
                        <Form>
                          <div>
                            <FormLabel classes={{ root: classes.groupLabel }}>
                              Wavefront dimensions
                            </FormLabel>
                            <Grid container>
                              <Grid item xs={6}>
                                <NumberInput
                                  name={"NFWidth"}
                                  required
                                  error={touched.NFWidth && errors.NFWidth}
                                  helperText={touched.NFWidth && errors.NFWidth}
                                  className={classes.groupTwoNumberFields}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={"Width"}
                                  value={values.NFWidth}
                                  disabled={disabled}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <NumberInput
                                  name={"NFHeight"}
                                  required
                                  error={touched.NFHeight && errors.NFHeight}
                                  helperText={
                                    touched.NFHeight && errors.NFHeight
                                  }
                                  className={classes.groupTwoNumberFields}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={"Length"}
                                  value={values.NFHeight}
                                  disabled={disabled}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={rowLayout ? 6 : 12}>
              {focusedDesignTarget && (
                <Wavefront
                  wavefront={
                    this.props.wavefront || focusedDesignTarget.NFWaveFront
                  }
                  staticWavefront={this.props.staticWavefront}
                  showStaticRepresentation={this.props.showStaticRepresentation}
                  toggleInteraction={this.props.toggleInteraction}
                  wfWidth={focusedDesignTarget.NFWidth}
                  wfHeight={focusedDesignTarget.NFHeight}
                  unit={focusedDesignTarget.unit}
                  scriptId={focusedDesignTarget.wave_front_design_script}
                  componentWidth={this.props.componentWidth}
                  componentHeight={this.props.componentHeight}
                  componentUnit={this.props.componentUnit}
                  cellWidth={this.props.cellWidth}
                  cellHeight={this.props.cellHeight}
                  cellUnit={this.props.cellUnit}
                  onChange={
                    this.props.onSubmit &&
                    ((wavefrontFile, onFinish, onError) =>
                      this.handleWavefrontFileDrop(
                        wavefrontFile,
                        onFinish,
                        onError
                      ))
                  }
                  onExport={this.exportWavefront}
                  exportLoading={this.state.exportLoading}
                  onDelete={
                    (this.props.wavefront ||
                      focusedDesignTarget.NFWaveFront ||
                      (this.props.staticWavefront &&
                        this.props.staticWavefront.amplitude &&
                        this.props.staticWavefront.phase)) &&
                    this.props.onDeleteWavefront
                  }
                  isEditing={isEditing}
                  onGeneratorConfirm={
                    this.props.onSubmit &&
                    ((wavefront, onFinish) =>
                      this.handleWavefrontGeneratorConfirmation(
                        wavefront,
                        onFinish
                      ))
                  }
                  onGenerateAsyncWavefront={this.generateAsyncWavefront}
                  sampleScripts={this.props.sampleScripts}
                  showImportFailed={this.state.showImportFailed}
                  fileError={fileError}
                  onResetFileError={() =>
                    this.setState({
                      fileError: null
                    })
                  }
                  showLegend
                />
              )}
            </Grid>
          </Grid>
          {this.state.snackbar.visible && (
            <DirectionSnackbar message={this.state.snackbar.message} />
          )}
        </>
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </>
    );
  }
}

export default withStyles(styles)(NFWFTargetForm);
