import React, { Component } from "react";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaCell/api/DirectoryExplorer";
import JobOverview from "./JobOverview";
import DesignJobOverview from "./DesignJobOverview";
import AnalysisJobOverview from "./AnalysisJobOverview";
import SimulationPlotOverview from "./SimulationPlotOverview";
import MaskJobOverview from "./MaskJobOverview";
import ReportJobOverview from "./ReportJobOverview";
import AnalysisExportJobOverview from "./AnalysisExportJobOverview";
import NFWavefrontJobOverview from "./NFWavefrontJobOverview";
import { Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { getNavigationHistory } from "BaseApp/selectors/navigationHistory";
import { setJobOverviewPath } from "BaseApp/actions/navigationHistory";
import SimulationApi from "MetaCell/api/Simulation";
import ODAJobOverview from "./ODAJobOverview";

export const JobTypes = Object.freeze({
  SIMULATION: "Cell simulation",
  MODE_ANALYSIS: "Mode analysis simulation",
  SIMULATION_PLOT: "Plot of simulation result",
  DESIGN: "Component design",
  ANALYSIS: "Analysis of component design",
  MASK: "Design Mask",
  NFWAVEFRONT: "Near-field wavefront import",
  DESIGN_REPORT: "Design Report",
  ODA: "ODA",
  ANALYSIS_EXPORT: "Export of the analysis of component design"
});

/**
 * Component to allow selection and displayal of job overview types
 * @author Akira
 */
export class MainJobOverview extends Component {
  showJobOverview() {
    switch (this.props.navigationHistory.jobOverview) {
      case JobTypes.SIMULATION: {
        return (
          <JobOverview
            getJobs={SimulationApi.getAllSimulationJobs}
            restore={this.props.restoreSimulation}
          />
        );
      }
      case JobTypes.MODE_ANALYSIS: {
        return <JobOverview getJobs={SimulationApi.getAllModeAnalysisJobs} />;
      }
      case JobTypes.SIMULATION_PLOT: {
        return <SimulationPlotOverview />;
      }
      case JobTypes.DESIGN: {
        return <DesignJobOverview />;
      }
      case JobTypes.ANALYSIS: {
        return <AnalysisJobOverview />;
      }
      case JobTypes.MASK: {
        return <MaskJobOverview />;
      }
      case JobTypes.NFWAVEFRONT: {
        return <NFWavefrontJobOverview />;
      }
      case JobTypes.DESIGN_REPORT: {
        return <ReportJobOverview />;
      }
      case JobTypes.ODA: {
        return <ODAJobOverview />;
      }
      case JobTypes.ANALYSIS_EXPORT: {
        return <AnalysisExportJobOverview />;
      }
      default: {
        return null;
      }
    }
  }

  render = () => {
    return (
      <div style={{ paddingTop: 140 }}>
        <FormControl>
          <InputLabel style={{ marginLeft: 50 }}>JOB TYPE</InputLabel>
          <Select
            style={{ minWidth: 200, marginLeft: 50, marginBottom: 20 }}
            value={this.props.navigationHistory.jobOverview}
            onChange={event =>
              this.props.setJobOverviewPath(event.target.value)
            }
          >
            {Object.keys(JobTypes).map((jobType, index) => (
              <MenuItem value={JobTypes[jobType]} key={index}>
                {JobTypes[jobType]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.showJobOverview()}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  navigationHistory: getNavigationHistory(state),
  projects: DirectoryExplorerSelector.getProjects(state),
  simulations: DirectoryExplorerSelector.getSimulations(state)
});

const mapDispatchToProps = dispatch => {
  return {
    setJobOverviewPath: path => dispatch(setJobOverviewPath(path)),
    fetchProjectsAndSimulations: () => dispatch(DirectoryExplorerApi.fetch()),
    restoreSimulation: (projectId, simulationJobId) =>
      dispatch(
        DirectoryExplorerApi.restoreSimulation(projectId, simulationJobId)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainJobOverview);
