import React, { PureComponent } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { setMetacellComponentsPath } from "BaseApp/actions/navigationHistory";
import { connect } from "react-redux";
import ControlMenu from "components/ControlMenu/ControlMenu";
import MetaComponentMenu from "MetaComponent/containers/Menu/Menu";
import RootCanvas from "./containers/RootCanvas/RootCanvas";
import ComponentCanvas from "./containers/ComponentCanvas/ComponentCanvas";
import TargetCanvas from "./containers/TargetCanvas/TargetCanvas";
import DesignCanvas from "./containers/DesignCanvas/DesignCanvas";
import AnalysisCanvas from "./containers/AnalysisCanvas/AnalysisCanvas";
import RayTracingCanvas from "./containers/RayTracingCanvas/RayTracingCanvas";
import ODACanvas from "./containers/ODACanvas/ODACanvas";
import UserApi from "BaseApp/api/User";
import FamilyApi from "MetaCell/api/Family";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import SelectedDesignTargetApi from "MetaComponent/api/SelectedDesignTarget";
import SetPointApi from "MetaComponent/api/SetPoint";
import Script2DApi from "MetaComponent/api/Script2D";
import AnalysisApi from "MetaComponent/api/Analysis";
import DesignApi from "MetaComponent/api/Design";
import DesignSelector from "MetaComponent/selectors/Design";
import RayTracingApi from "MetaComponent/api/RayTracing";
import ODAApi from "./api/ODA";

/**
 * internal meta component paths
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} MetaComponentPaths
 * @property {String} ROOT - the path to the meta component section
 * @property {String} COMPONENT - the path to the component sub section
 * @property {String} TARGET - the path to the target sub section
 * @property {String} DESIGN - the path to the design sub section
 * @property {String} ANALYSIS - the path to the design analysis sub section
 * @global
 */
export const MetaComponentPaths = Object.freeze({
  ROOT: "/meta-component",
  COMPONENT: "/meta-component/component",
  TARGET: "/meta-component/target",
  DESIGN: "/meta-component/design",
  ODA: "/meta-component/oda",
  ANALYSIS: "/meta-component/analysis",
  RAY_TRACING_LINK: "/meta-component/raytracing"
});

export class MetaComponent extends PureComponent {
  constructor() {
    super();
    this.state = {
      currentPage: "",
      dataIsReady: false
    };
  }

  /**
   * if the component mounts but there is already a meta component open,
   * it means that the data is ready, because we always fetch data when we open a meta component.
   */
  componentDidMount() {
    if (this.props.metaComponentOpenId !== -1) {
      this.fetchAllData();
    }
  }

  /**
   * when a meta component is opened it fetches all necessary data to display the screen
   * @TODO go to root page when the open simulation is deleted and go to component screen
   * when a new simulation is open
   * @param {Object} prevProps - the previous props
   */
  componentDidUpdate(prevProps) {
    const { metaComponentOpenId } = this.props;
    if (metaComponentOpenId !== prevProps.metaComponentOpenId) {
      this.fetchAllData();
    }
  }

  setPage = currentPage => {
    this.setState({ currentPage });
    this.props.setMetacellComponentsPath(currentPage);
    this.props.postUserSettings({ lastView: currentPage });
  };

  /**
   * it fetches all data the meta components need and in the meanwhile it changes the
   * fetching state.
   */
  fetchAllData = () => {
    this.setState({ dataIsReady: false });
    const {
        fetchFamiliesAction,
        fetchFamilyMembersAction,
        fetchDesignTargetsAction,
        fetchSelectedDesignTargetsAction,
        fetchFFWFTargetsAction,
        fetchSetPointsAction,
        fetchScript2DsAction,
        fetchAnalysisJobsAction,
        fetchRayTracingJobsAction,
        fetchOdaJobsAction,
        fetchDesignJobs,
        metaComponentOpenId
      } = this.props,
      requests = [];
    requests.push(
      fetchFamiliesAction(),
      fetchFamilyMembersAction(),
      fetchDesignTargetsAction(),
      fetchFFWFTargetsAction(),
      fetchSetPointsAction(),
      fetchSelectedDesignTargetsAction(metaComponentOpenId),
      fetchScript2DsAction(),
      fetchAnalysisJobsAction(),
      fetchRayTracingJobsAction(),
      fetchOdaJobsAction(),
      fetchDesignJobs(metaComponentOpenId)
    );
    return Promise.all(requests).then(() =>
      this.setState({ dataIsReady: true })
    );
  };

  render() {
    const { metaComponentOpenId, metaComponents, designJobs } = this.props,
      { currentPage, dataIsReady } = this.state,
      ready = metaComponentOpenId !== -1 && dataIsReady,
      content = (
        <MetaComponentMenu
          activePage={currentPage}
          saveEditings={() => {}}
          metaComponentOpenId={metaComponentOpenId}
          loaded={metaComponents.loaded}
          thereAreDesignJobs={Object.values(designJobs.byId).length}
          // family_is_pdk={
          //   metaComponents.loaded &&
          //   metaComponentOpenId &&
          //   metaComponents.byId[metaComponentOpenId].family_is_pdk
          // }
        />
      );

    return (
      <div test-data="metaComponent">
        <Router>
          <ControlMenu content={content}>
            <Switch>
              <Route
                exact
                path={MetaComponentPaths.ROOT}
                render={props => (
                  <RootCanvas {...props} setPage={this.setPage} />
                )}
              />
              <Route
                path={MetaComponentPaths.COMPONENT}
                render={props => (
                  <ComponentCanvas
                    {...props}
                    setPage={this.setPage}
                    ready={ready}
                  />
                )}
              />
              <Route
                path={MetaComponentPaths.TARGET}
                render={props => (
                  <TargetCanvas
                    {...props}
                    setPage={this.setPage}
                    ready={ready}
                  />
                )}
              />
              <Route
                path={MetaComponentPaths.DESIGN}
                render={props => (
                  <DesignCanvas {...props} setPage={this.setPage} />
                )}
              />
              <Route
                path={MetaComponentPaths.ODA}
                render={props => (
                  <ODACanvas {...props} setPage={this.setPage} />
                )}
              />
              <Route
                path={MetaComponentPaths.ANALYSIS}
                render={props => (
                  <AnalysisCanvas {...props} setPage={this.setPage} />
                )}
              />
              <Route
                path={MetaComponentPaths.RAY_TRACING_LINK}
                render={props => (
                  <RayTracingCanvas {...props} setPage={this.setPage} />
                )}
              />
            </Switch>
          </ControlMenu>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  metaComponentOpenId: DirectoryExplorerSelector.getMetaComponentOpenId(state),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
  designJobs: DesignSelector.getDesignJobs(state)
});

const mapDispatchToProps = dispatch => ({
  setMetacellComponentsPath: path => dispatch(setMetacellComponentsPath(path)),
  postUserSettings: data => dispatch(UserApi.postUserSettings(data)),
  fetchFamiliesAction: () => dispatch(FamilyApi.fetchFamilies()),
  fetchFamilyMembersAction: () => dispatch(FamilyApi.fetchFamilyMembers()),
  fetchFFWFTargetsAction: () => dispatch(DesignTargetApi.fetchFFWFTargets()),
  fetchDesignTargetsAction: () =>
    dispatch(DesignTargetApi.fetchDesignTargets()),
  fetchSelectedDesignTargetsAction: metaComponentId =>
    dispatch(
      SelectedDesignTargetApi.fetchSelectedDesignTargets(metaComponentId)
    ),
  fetchSetPointsAction: () => dispatch(SetPointApi.fetchSetPoints()),
  fetchScript2DsAction: () => dispatch(Script2DApi.fetchScript2Ds()),
  fetchAnalysisJobsAction: () => dispatch(AnalysisApi.fetchAnalysisJobs()),
  fetchRayTracingJobsAction: () =>
    dispatch(RayTracingApi.fetchRayTracingJobs()),
  fetchOdaJobsAction: () => dispatch(ODAApi.fetchOdaJobs()),
  fetchDesignJobs: metaComponentId =>
    dispatch(DesignApi.getDesignJobs(metaComponentId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MetaComponent);
