import { actionType } from "MetaCell/actions/IncidentLight";
import Utils from "reducer/Utils";
import { cloneDeep } from "lodash";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testIncidentLight = {
  byId: {
    1: {
      id: 1,
      azimut: "15",
      zenit: "12",
      wavelength: "30",
      polarization: "TE",
      amplitude: "0",
      simulation: 1
    }
  },
  allIds: [1]
};

/**
 * @constant
 * @typedef {Object} IncidentLightDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    incidentLight: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    editingIncidentLight: {
      id: null,
      azimut: "",
      zenit: "",
      wavelength: "",
      polarization: "",
      amplitude: "",
      ref: ""
    },
    polarizationHistory: {
      stokes: null,
      jones: null
    }
  }
};

/**
 * Reducer function to manipulate the state of incident light
 * @author Ibtihel
 * @param {Object} [state=IncidentLightDefaultState] - incident light entities and ui management
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_INCIDENT_LIGHT_PARAMETERS: {
      let incidentLight = cloneDeep(state.entities.incidentLight);
      Utils.addOrUpdateEntities(payload, incidentLight);
      return {
        ...state,
        entities: { ...state.entities, incidentLight },
        ui: { ...state.ui, editingIncidentLight: payload[0] }
      };
    }

    case actionType.CHANGE_INCIDENT_LIGHT_EDITING_FIELD: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingIncidentLight: { ...state.ui.editingIncidentLight, ...payload }
        }
      };
    }

    case actionType.UPDATE_INCIDENT_LIGHT_PARAMETER: {
      const { incidentLight } = state.entities;
      incidentLight.byId[payload.id] = payload;
      return {
        ...state,
        entities: { ...state.entities, incidentLight }
      };
    }

    case actionType.RESET_INCIDENT_LIGHT: {
      return defaultState;
    }

    case actionType.UPDATE_POLARIZATION_HISTORY: {
      return {
        ...state,
        ui: {
          ...state.ui,
          polarizationHistory: { ...state.ui.polarizationHistory, ...payload }
        }
      };
    }

    case actionType.UPSERT_INCIDENT_LIGHT_SETTINGS: {
      let incidentLight = cloneDeep(state.entities.incidentLight);
      Utils.addOrUpdateEntities(payload, incidentLight);
      return {
        ...state,
        ui: {
          ...state.ui,
          polarizationHistory: { ...state.ui.polarizationHistory, ...payload }
        }
      };
    }

    case actionType.DELETE_INCIDENT_LIGHT_SETTINGS: {
      let incidentLight = cloneDeep(state.entities.incidentLight);
      Utils.deleteEntities([payload], incidentLight);
      return {
        ...state,
        entities: { ...state.entities, incidentLight }
      };
    }

    default:
      return state;
  }
}
