import SweepOverview from "components/SweepOverview/SweepOverview";
import SimulationSettingsApi from "MetaCell/api/SimulationSettings";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";

const mapState = state => {
  return {
    simulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
    // sweptVariables: [],
    hideExistingSweeps: true
  };
};

const mapDispatch = dispatch => ({
  addSweptVariable: data =>
    dispatch(SimulationSettingsApi.addSweptVariable(data))
});

export default connect(mapState, mapDispatch)(SweepOverview);
