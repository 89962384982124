import React, { PureComponent } from "react";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { connect } from "react-redux";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import NFWFTargetForm from "./components/NFWFTargetForm/NFWFTargetForm";
import { isEqual } from "lodash";
import { FamilySelector } from "MetaCell/selectors/Family";
import DesignTargetHelper from "MetaComponent/helper/DesignTarget";
import Script2DSelector from "MetaComponent/selectors/Script2D";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  title: {
    float: "left"
  }
});

/**
 * a component to allow the user to set the near field wavefront target of a design target
 * @author Akira Kotsugai
 */
export class NearFieldWavefront extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      showStaticRepresentation: true,
      staticWavefront: null
    };
  }

  componentDidMount() {
    const { focusedDesignTargetId } = this.props,
      focusedDesignTarget = this.getFocusedDesignTarget();
    if (focusedDesignTargetId !== 0 && focusedDesignTarget) {
      this.formResetter = () => {};
      this.initialize();
    }
  }

  toggleInteraction = () => {
    this.setState(
      { showStaticRepresentation: !this.state.showStaticRepresentation },
      this.ensureThatWavefrontDataIsLoaded
    );
  };

  /**
   * every time a different design target is focused or the currently focused design
   * target is updated we restart the component state
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const { focusedDesignTargetId } = this.props,
      focusedDesignTarget = this.getFocusedDesignTarget(),
      focusedDesignTargetChanged =
        focusedDesignTargetId !== prevProps.focusedDesignTargetId,
      currentDesignTargetWasUpdated = !isEqual(
        prevProps.designTargets.byId[focusedDesignTargetId],
        focusedDesignTarget
      );
    if (focusedDesignTarget) {
      if (focusedDesignTargetChanged || currentDesignTargetWasUpdated) {
        this.initialize();
      }
    }
  }

  /**
   * when initializing the component, we should make sure that it is not on edit mode
   * and the wavefront data is loaded
   */
  initialize() {
    this.cancelEditing();
    this.ensureThatWavefrontDataIsLoaded();
  }

  /**
   * it calls the action to load the wavefront of the focused design target
   */
  ensureThatWavefrontDataIsLoaded = (force = false) => {
    const focusedDesignTarget = this.getFocusedDesignTarget();
    if (this.state.showStaticRepresentation) {
      DesignTargetApi.requestNFWFTargetImagesWavefront(
        focusedDesignTarget.id
      ).then(staticWavefront => this.setState({ staticWavefront }));
    } else {
      if (this.props.useWavefrontInternally) {
        DesignTargetApi.requestNFWFTargetWavefront(
          focusedDesignTarget.id
        ).then(wavefront => this.setState({ wavefront }));
      } else if (force || !focusedDesignTarget.NFWaveFront) {
        this.props.getNFWFTargetWavefrontAction(focusedDesignTarget.id);
      }
    }
  };

  /**
   * it supposely takes the formik submitter and assign it to this component, so that
   * the formik submitter can be accessed from outside the formik component.
   * @param {*} submitFormFunction - the formik function that submits the form
   * @callback
   */
  bindSubmitForm = submitFormFunction => {
    this.formSubmitter = submitFormFunction;
  };

  /**
   * it supposely takes the formik resetter and assign it to this component, so that
   * the formik resetter can be accessed from outside the formik component.
   * @param {*} resetFormFunction - the formik function that submits the form
   * @callback
   */
  bindResetForm = resetFormFunction => {
    this.formResetter = resetFormFunction;
  };

  /**
   * @returns {Object} the focused design target object
   */
  getFocusedDesignTarget = () => {
    return this.props.designTargets.byId[this.props.focusedDesignTargetId];
  };

  /**
   * it changes the editing state to true
   */
  edit = () => {
    this.setState({
      editing: true
    });
  };

  /**
   * it changes the editing state to false and resets the form
   */
  cancelEditing = () => {
    this.setState({
      editing: false
    });
    this.formResetter();
  };

  /**
   * @param {Object} metaComponent
   * @returns {Object} the family that is linked to the component
   */
  getComponentFamily(metaComponent) {
    const { families } = this.props;
    return { ...families.byId[metaComponent.family] };
  }

  /**
   * it saves the editing near field wavefront information and leaves the edit mode.
   * @param {Object} formEditingValues - the form values.
   */
  save = async (formEditingValues, onFinish) => {
    const {
        focusedDesignTargetId,
        openMetaComponent,
        designTargets,
        showConfirmDialog,
        selectedDesignTargets
      } = this.props,
      designTarget = designTargets.byId[focusedDesignTargetId],
      family = this.getComponentFamily(openMetaComponent),
      { NFWidth, NFHeight, wavefrontFile, s3FileKey } = formEditingValues,
      { width, height, unit } = openMetaComponent,
      componentDimensions = { width, height, unit },
      editingDesignTarget = {
        ...designTarget,
        NFWidth,
        NFHeight,
        ...(s3FileKey
          ? { s3FileKey: s3FileKey }
          : {
              NFWaveFront: wavefrontFile
                ? wavefrontFile
                : designTarget.NFWaveFront
            })
      },
      sdtObjs = Object.values(selectedDesignTargets.byId);

    const messages = await DesignTargetHelper.getDiscrepantDesignTargetNFWFsMessages(
        [editingDesignTarget],
        family,
        componentDimensions
      ),
      confirmActions = await DesignTargetHelper.getDiscrepantDesignTargetNFWFsAnswers(
        [editingDesignTarget],
        family,
        componentDimensions,
        sdtObjs
      ),
      thereAreDiscrepanciesToHandle = messages.some(message => message !== ""),
      thereAreSimilaritiesToHandle = messages.some(message => message === "");
    if (thereAreDiscrepanciesToHandle) {
      showConfirmDialog(
        "Meta component size and near-field wavefront size",
        messages,
        confirmActions,
        onFinish,
        false,
        onFinish
      );
    } else {
      if (thereAreSimilaritiesToHandle) {
        // matching sizes and cell structure dont need user confirmation
        // but it still has actions to be executed (set the discrepancy handling to null)
        await DesignTargetHelper.runFirstActionOfEveryConfirmAction(
          confirmActions,
          onFinish
        );
      }
      onFinish();
    }
  };

  /**
   * @callback
   */
  deleteWavefront = onFinish => {
    return this.props
      .deleteNFWFWavefront(this.props.focusedDesignTargetId)
      .then(() => this.ensureThatWavefrontDataIsLoaded(true))
      .then(() => onFinish());
  };

  render() {
    const { classes, script2Ds, openMetaComponent } = this.props,
      { editing } = this.state,
      isEditing = editing !== false,
      focusedDesignTarget = this.getFocusedDesignTarget(),
      thereIsFarFieldSelected =
        focusedDesignTarget && focusedDesignTarget.FFWFTarget !== null;
    return focusedDesignTarget ? (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography className={classes.title} variant="h5" component="h3">
              {`${focusedDesignTarget.name} near-field wavefront`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {(!this.props.useWavefrontInternally ||
              (this.props.useWavefrontInternally &&
                this.state.wavefront !== undefined) ||
              (this.state.showStaticRepresentation &&
                this.state.staticWavefront &&
                this.state.staticWavefront.amplitude &&
                this.state.staticWavefront.phase)) && (
              <NFWFTargetForm
                isEditing={isEditing}
                focusedDesignTarget={focusedDesignTarget}
                bindSubmitForm={this.bindSubmitForm}
                bindResetForm={this.bindResetForm}
                onSubmit={openMetaComponent && this.save}
                onDeleteWavefront={this.deleteWavefront}
                sampleScripts={Object.values(script2Ds.byId).filter(
                  script => script.type === "NFWF_EXAMPLE"
                )}
                componentWidth={openMetaComponent && openMetaComponent.width}
                componentHeight={openMetaComponent && openMetaComponent.height}
                componentUnit={openMetaComponent && openMetaComponent.unit}
                cellWidth={
                  openMetaComponent &&
                  this.getComponentFamily(openMetaComponent).cell_width
                }
                cellHeight={
                  openMetaComponent &&
                  this.getComponentFamily(openMetaComponent).cell_height
                }
                cellUnit={
                  openMetaComponent &&
                  this.getComponentFamily(openMetaComponent).unit
                }
                wavefront={this.state.wavefront}
                staticWavefront={this.state.staticWavefront}
                requestWavefront={this.ensureThatWavefrontDataIsLoaded}
                showStaticRepresentation={this.state.showStaticRepresentation}
                toggleInteraction={this.toggleInteraction}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    ) : null;
  }
}

const mapStateToProps = state => ({
  designTargets: DesignTargetSelector.getDesignTargets(state),
  selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
    state
  ),
  families: FamilySelector.getFamilies(state),
  script2Ds: Script2DSelector.getScript2Ds(state)
});

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm
        )
      ),
    getNFWFTargetWavefrontAction: id =>
      dispatch(DesignTargetApi.getNFWFTargetWavefront(id)),
    deleteNFWFWavefront: designTargetId =>
      dispatch(DesignTargetApi.deleteNFWFTargetWavefront(designTargetId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(NearFieldWavefront)));
