/**
 * @constant
 * @typedef {Object} SimulationSettingsActionType
 * @property {String} SET_MAX_DIFFRACTION_ORDERS - tells the reducer to set maximum diffraction orders
 * @property {String} CHANGE_EDITING_MDO_FIELD - tells the reducer to change an editing MDO field
 * @property {String} RESET_MAX_DIFFRACTION_ORDERS - tells the reducer to reset maximum diffraction orders
 * @property {String} ADD_FIELD_PROBE - tells the reducer to add a probe
 * @property {String} DELETE_FIELD_PROBE - tells the reducer to delete a probe
 * @property {String} SELECT_FIELD_PROBE - tells the reducer to select a probe
 * @property {String} COPY_FIELD_PROBE - tells the reducer to copy a probe
 * @property {String} CHANGE_FIELD_PROBE - tells the reducer to change a probe
 * @property {String} EDIT_FIELD_PROBE - tells the reducer to edit a probe
 * @global
 * it defines simulation settings' type of actions.
 */
export const actionType = Object.freeze({
  SET_MAX_DIFFRACTION_ORDERS: "SET_MAX_DIFFRACTION_ORDERS",
  CHANGE_EDITING_MDO_FIELD: "CHANGE_EDITING_MDO_FIELD",
  RESET_MAX_DIFFRACTION_ORDERS: "RESET_MAX_DIFFRACTION_ORDERS",
  ADD_FIELD_PROBE: "ADD_FIELD_PROBE",
  DELETE_FIELD_PROBE: "DELETE_FIELD_PROBE",
  SELECT_FIELD_PROBE: "SELECT_FIELD_PROBE",
  CHANGE_FIELD_PROBE: "CHANGE_FIELD_PROBE",
  EDIT_FIELD_PROBE: "EDIT_FIELD_PROBE",
  SET_SWEPT_VARIABLES: "SET_SWEPT_VARIABLES",
  ADD_SWEPT_VARIABLE: "ADD_SWEPT_VARIABLE",
  REMOVE_SWEPT_VARIABLE: "REMOVE_SWEPT_VARIABLE",
  UPDATE_SWEPT_VARIABLE: "UPDATE_SWEPT_VARIABLE",
  UPDATE_MAX_DIFFRACTION_ORDER: "UPDATE_MAX_DIFFRACTION_ORDER",
  SET_PROBES: "SET_PROBES",
  SET_EDITING_SIMULATION_DESCRIPTION: "SET_EDITING_SIMULATION_DESCRIPTION"
});

/**
 * A class created to create simulation settings actions to be dispatched to redux.
 * It is already tested with simulation settings reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link SimulationSettingsActionType} and some data.
 * @author Akira Kotsugai
 */
export default class SimulationSettingsAction {

  static setMaxDiffractionOrders = value => ({
    type: actionType.SET_MAX_DIFFRACTION_ORDERS,
    payload: value
  });

  static setEditingSimulationDescription = value => ({
    type: actionType.SET_EDITING_SIMULATION_DESCRIPTION,
    payload: value
  })

  static changeEditingMDOField = value => ({
    type: actionType.CHANGE_EDITING_MDO_FIELD,
    payload: value
  });

  static resetMaxDiffractionOrders = () => ({
    type: actionType.RESET_MAX_DIFFRACTION_ORDERS,
    payload: {}
  });

  static addFieldProbe = probe => ({
    type: actionType.ADD_FIELD_PROBE,
    payload: probe
  });

  static selectFieldProbe = probeId => ({
    type: actionType.SELECT_FIELD_PROBE,
    payload: probeId
  });

  static editFieldProbe = probeId => ({
    type: actionType.EDIT_FIELD_PROBE,
    payload: probeId
  });

  static deleteFieldProbe = probeId => ({
    type: actionType.DELETE_FIELD_PROBE,
    payload: probeId
  });

  static changeFieldProbe = (id, data) => ({
    type: actionType.CHANGE_FIELD_PROBE,
    payload: { id, data }
  });

  static setSweptVariables = data => ({
    type: actionType.SET_SWEPT_VARIABLES,
    payload: data
  });

  static addSweptVariable = data => ({
    type: actionType.ADD_SWEPT_VARIABLE,
    payload: data
  });

  static removeSweptVariable = data => ({
    type: actionType.REMOVE_SWEPT_VARIABLE,
    payload: data
  });

  static updateSweptVariable = data => ({
    type: actionType.UPDATE_SWEPT_VARIABLE,
    payload: data
  });

  static updateMaxDiffractionOrder = obj => ({
    type: actionType.UPDATE_MAX_DIFFRACTION_ORDER,
    payload: obj
  });

  static setProbes = data => ({
    type: actionType.SET_PROBES,
    payload: data
  });
}
