import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Input, Button, withStyles } from "@material-ui/core";
import * as Yup from "yup";
import AuthLayout from "./AuthLayout";
import DocumentTitle from "react-document-title";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import * as qs from "query-string";
import GenericApi from "Api";

const requiredFieldText = "This field is required";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(requiredFieldText)
    .min(8, "Must have at least 8 characters")
    .max(100, "Must have at most 100 characters"),
  confirmationPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required(requiredFieldText)
});

const styles = {
  field: {
    marginTop: 5,
    width: "100%"
  },
  validationMessage: {
    height: 25,
    padding: "3px 0"
  },
  alignTextCenter: {
    textAlign: "center"
  },
  bottom: {
    marginTop: 20
  }
};

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      submitButtonDisabled: false,
      redirect: false,
      showPassword: false,
      showConfirmationPassword: false
    };
  }

  togglePasswordVisibility = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  toggleConfirmationPasswordVisibility = () => {
    const { showConfirmationPassword } = this.state;
    this.setState({ showConfirmationPassword: !showConfirmationPassword });
  };

  resetPassword = ({ password }) => {
    const parsed = qs.parse(this.props.location.search);
    Axios.put(`${GenericApi.getBaseUrl()}/user/password_reset`, {
      password,
      ...parsed
    })
      .then(this.resetPasswordSuccess)
      .catch(this.resetPasswordFailure);
  };

  resetPasswordSuccess = () => {
    this.setState({ redirect: true, submitButtonDisabled: false });
  };

  resetPasswordFailure = ({ response: { data } }) => {
    this.setState({
      error: true,
      errorMessage: data.detail || "Something went wrong",
      submitButtonDisabled: false
    });
  };

  onSubmit = form => {
    this.setState({
      submitButtonDisabled: true,
      error: false,
      errorMessage: ""
    });
    this.resetPassword(form);
  };

  render = () => {
    const { classes } = this.props,
      {
        error,
        errorMessage,
        submitButtonDisabled,
        redirect,
        showPassword,
        showConfirmationPassword
      } = this.state;
    if (redirect) {
      return <Redirect to="/reset-password-complete" />;
    }
    return (
      <DocumentTitle title="Reset password" test-data="resetPassword">
        <AuthLayout title="Reset password">
          <div
            className={`${classes.validationMessage} ${classes.alignTextCenter}`}
          >
            {error && errorMessage}
          </div>

          <Formik
            initialValues={{ password: "", confirmationPassword: "" }}
            validationSchema={resetPasswordSchema}
            onSubmit={this.onSubmit}
          >
            {({ errors, values, handleChange, handleBlur, touched }) => (
              <Form>
                <Input
                  className={classes.field}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="New password *"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordVisibility}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.validationMessage}>
                  {touched.password && errors.password}
                </div>

                <Input
                  className={classes.field}
                  type={showConfirmationPassword ? "text" : "password"}
                  name="confirmationPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmationPassword}
                  placeholder="Confirm New Password *"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.toggleConfirmationPasswordVisibility}
                      >
                        {showConfirmationPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.validationMessage}>
                  {touched.confirmationPassword && errors.confirmationPassword}
                </div>

                <Button
                  className={classes.field}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitButtonDisabled}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </AuthLayout>
      </DocumentTitle>
    );
  };
}

export default withStyles(styles)(ResetPassword);
