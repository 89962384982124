import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import HelperUtils from "MetaCell/helper/HelperUtils";
import Slider from "@material-ui/core/Slider";

class GenericSlider extends PureComponent {
  render() {
    const {
      rangeValue,
      sliderData,
      sliderUnit,
      sliderName,
      onRangeChange
    } = this.props;
    return (
      <div
        style={{
          backgroundColor: "white",
          paddingLeft: 100,
          paddingRight: 100
        }}
      >
        <Slider
          step={1}
          value={rangeValue}
          min={1}
          max={sliderData.length}
          onChange={(event, rangeValue) => onRangeChange(rangeValue)}
        />
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          {HelperUtils.getAxisName(
            `${sliderName} [${sliderData[rangeValue - 1]}] `,
            sliderUnit
          )}
        </div>
      </div>
    );
  }
}

GenericSlider.propTypes = {
  onRangeChange: PropTypes.func.isRequired,
  sliderData: PropTypes.arrayOf(PropTypes.number).isRequired,
  rangeValue: PropTypes.array.isRequired,
  sliderUnit: PropTypes.string.isRequired,
  sliderName: PropTypes.string.isRequired
};

export default GenericSlider;
