import React, { Component } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaCell/api/DirectoryExplorer";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import GenericJobOverview from "./GenericJobOverview";
import SimulationApi from "MetaCell/api/Simulation";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import SimulationAction from "MetaCell/actions/Simulation";
import SimulationSelector from "MetaCell/selectors/Simulation";
import HelperUtils from "MetaCell/helper/HelperUtils";

export class JobOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      data: null
    };
    this.handleFetchJobsComplete = this.handleFetchJobsComplete.bind(this);
    this.handleFetchJobsError = this.handleFetchJobsError.bind(this);
  }

  componentDidMount() {
    const { fetchProjectsAndSimulations, projects } = this.props;
    if (projects.loaded) {
      this.fetchJobs();
    } else {
      fetchProjectsAndSimulations();
    }
  }

  componentDidUpdate(prevProps) {
    const directoryExplorerHasBeenLoaded =
      !prevProps.projects.loaded && this.props.projects.loaded;
    const jobFetcherChanged = prevProps.getJobs !== this.props.getJobs;
    if (jobFetcherChanged || directoryExplorerHasBeenLoaded) {
      this.fetchJobs();
    }
  }

  async fetchJobs() {
    try {
      const response = await this.props.getJobs();
      this.handleFetchJobsComplete(response);
    } catch {
      this.handleFetchJobsError();
    }
  }

  handleFetchJobsComplete(data) {
    if (data && data.length > 0) {
      this.setState({
        data: data.map(({ simulation, name, status, startDate, id }) => ({
          itemId: id,
          project: this.getProjectName(simulation),
          simulation: name,
          startDate: (startDate && startDate.slice(0, 10)) || "-",
          status
        }))
      });
    } else {
      this.setState({ data: [] });
    }
  }

  getRecipientData() {
    const { projects } = this.props;
    return Object.values(projects.byId).map(project => ({
      restorationRecipientId: project.id,
      project: project.name
    }));
  }

  handleFetchJobsError() {
    this.setState({ error: true });
  }

  /**
   * @param {Number} simulationId - the simulation id
   * @returns {Number} the id of the project to which the simulation belongs
   */
  getProjectId = simulationId => {
    const { simulations } = this.props;
    const simulation = simulations.byId[simulationId];
    if (simulation) {
      return simulation.project;
    }
    return null;
  };

  getProjectName = simulationId => {
    const { projects, simulations } = this.props;
    let projectName = "";
    const simulation = simulations.byId[simulationId];
    if (simulation) {
      const project = projects.byId[simulation.project];
      if (project) {
        projectName = project.name;
      }
    }
    return projectName;
  };

  /**
   * @param {Number} jobId - the simulation job id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  stopSimulationJob = jobId => {
    return SimulationApi.stopSimulationJob(jobId).then(() =>
      Promise.resolve(true)
    );
  };

  /**
   * it deletes a simulation job
   * @param {Number} jobId - the job id
   * @callback
   */
  deleteSimulationJob = (jobIds, onConfirmCallback) => {
    return this.props.showConfirmDialog(
      "Delete simulation job",
      "Are you sure you want to delete the selected simulation jobs?",
      () => {
        this.props.deleteSimulationJob(jobIds);
        onConfirmCallback(jobIds);
      },
      undefined
    );
  };

  /**
   * @param {Number} jobId - the simulation job id
   * @returns {Object} errors and warnings extracted from the simulation job details
   */
  getSimulationJobErrorsAndWarnings = async jobId => {
    const { data } = await SimulationApi.getJobDetails(jobId);
    const { errors, warnings } = data;
    return { errors, warnings };
  };

  /**
   * @param {Number} jobId - the simulation job id
   * @returns {Object} the job configuration extracted from the simulation job details
   */
  getSimulationJobConfiguration = async jobId => {
    const { data } = await SimulationApi.getJobDetails(jobId);
    const { configuration } = data;
    return configuration;
  };

  /**
   * @callback
   * @param {Number} jobId - the job id to restore from
   * @param {Number} recipientId - the recipient to restore into
   */
  restore = (jobId, recipientId) => {
    this.props.restore(recipientId, jobId);
  };

  /**
   * @callback
   * @param {Object} newData
   */
  updateData = newData => {
    this.setState({ data: newData });
  };

  /**
   * sends the new filters to the redux state
   * @param {Object} filter - the new filter
   */
  handleFilterChange = filter => {
    this.props.updateOverviewFilters(filter);
  };

  render = () => {
    const { data, error } = this.state;
    return (
      // data && (
      <GenericJobOverview
        data={data}
        updateData={this.updateData}
        recipientData={this.getRecipientData()}
        error={error}
        getErrorsAndWarnings={this.getSimulationJobErrorsAndWarnings}
        getConfiguration={this.getSimulationJobConfiguration}
        getJobProgress={SimulationApi.getSimpleTaskResultStatus}
        stopJob={this.stopSimulationJob}
        deleteJob={this.deleteSimulationJob}
        grouperColumns={[
          {
            title: "Project",
            field: "project",
            defaultGroupOrder: 0,
            customSort: (a, b) => {
              if (typeof a == "object") a = a.project;
              if (typeof b == "object") b = b.project;
              return HelperUtils.caseInsensitiveSort(a, b);
            }
          },
          {
            title: "Simulation",
            field: "simulation",
            defaultGroupOrder: 1,
            customSort: (a, b) => {
              if (typeof a == "object") a = a.simulation;
              if (typeof b == "object") b = b.simulation;
              return HelperUtils.caseInsensitiveSort(a, b);
            }
          }
        ]}
        onRestoreConfirm={this.props.restore && this.restore}
        restorationRecipient={"project"}
        handleFilterChange={this.handleFilterChange}
        filters={this.props.filters}
      />
      // )
    );
  };
}

const mapStateToProps = state => ({
  filters: SimulationSelector.getOverviewFilters(state),
  projects: DirectoryExplorerSelector.getProjects(state),
  simulations: DirectoryExplorerSelector.getSimulations(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateOverviewFilters: filters =>
      dispatch(SimulationAction.updateOverviewFilters(filters)),
    fetchProjectsAndSimulations: () => dispatch(DirectoryExplorerApi.fetch()),
    deleteSimulationJob: jobIds =>
      dispatch(SimulationApi.deleteSimulationJobs(jobIds)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    restoreSimulation: (projectId, simulationJobId) =>
      dispatch(
        DirectoryExplorerApi.restoreSimulation(projectId, simulationJobId)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOverview);
