import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

const styles = theme => ({
    listItemSelected: {
        color: 'blue'
    }
});

/**
 * a generic menu item for side menus
 * @author Akira Kotsugai
 */
export class SideMenuItem extends PureComponent {

    /**
    * it checks whether the given path is the active path
    * @param {String} path - the given path
    */
    isSelected = (path) => {
        return this.props.activePath === path;
    }

    render() {
        const { classes, disabled, path } = this.props;
        return (
            <>
                <ListItem button
                    classes={{ selected: classes.listItemSelected }}
                    component={Link}
                    to={path}
                    disabled={disabled}
                    selected={this.isSelected(path)}
                >
                    {this.props.children}
                </ListItem>
            </>
        );
    }
}

SideMenuItem.propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired
};

export default withStyles(styles)(SideMenuItem);
