import React, { PureComponent } from "react";
import { Typography } from "@material-ui/core";
import HelperUtils from "MetaCell/helper/HelperUtils";
import IconTooltip from "components/IconTooltip/IconTooltip";

export class Efficiency extends PureComponent {
  render() {
    const { efficiency, type } = this.props;
    return efficiency !== null && efficiency !== undefined ? (
      <div>
        <Typography
          variant="subtitle1"
          component="h4"
          style={{ float: "left" }}
        >
          {`${type} efficiency: ${HelperUtils.limitDecimals(efficiency, 3)}`}
        </Typography>
        <IconTooltip
          text={`The ${type} efficiency indicates how much of the light
                      incident on the metasurface propagates to the ${type}`}
        />
      </div>
    ) : (
      <></>
    );
  }
}

export default Efficiency;
