import { actionType } from "MetaCell/actions/DirectoryExplorer";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testProjects = {
  byId: {
    1: {
      id: 1,
      name: "project 1",
      simulation_set: [1, 2],
      creationDate: "2018-10-05T18:49:06.765474Z"
    },
    2: {
      id: 2,
      name: "project 2",
      simulation_set: [3],
      creationDate: "2019-10-05T18:49:06.765474Z"
    }
  },
  allIds: ["1", "2"],
  loaded: true
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testSimulations = {
  byId: {
    1: {
      id: 1,
      name: "simulation 1",
      project: 1,
      creationDate: "2017-10-05T18:49:06.765474Z",
      description: "created because I wanted"
    },
    2: {
      id: 2,
      name: "simulation 2",
      project: 1,
      creationDate: "2018-10-05T18:49:06.765474Z",
      description: "created because I needed"
    },
    3: {
      id: 3,
      name: "simulation 1",
      project: 2,
      creationDate: "2019-10-05T18:49:06.765474Z",
      description: "created because I was told to"
    },
    4: {
      id: 4,
      name: "simulation 4",
      project: 2,
      creationDate: "2019-10-05T18:49:06.765474Z",
      description: "created because we added optimization",
      simulation_targets: [1]
    }
  },
  allIds: ["1", "2", "3", "4"],
  loaded: true
};

const getLastCreatedSimulationId = simulations => {
  if (simulations.length === 0) return -1;
  const mostRecentDate = new Date(
    Math.max.apply(
      null,
      simulations.map(simulation => new Date(simulation.creationDate))
    )
  );
  const mostRecentSimulation = simulations.find(
    simulation =>
      new Date(simulation.creationDate).getTime() == mostRecentDate.getTime()
  );
  return mostRecentSimulation.id;
};

/**
 * @constant
 * @typedef {Object} DirectoryExplorerDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    projects: {
      byId: {},
      allIds: [],
      loaded: false
    },
    simulations: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    selectedItem: null,
    form: "new project",
    simulationOpenId: -1,
    editingItem: null
  }
};

/**
 * Reducer function to manipulate the state of the directory explorer (the tree and the form)
 * @author Akira Kotsugai
 * @param {Object} [state=DirectoryExplorerDefaultState] - projects, simulations and ui management
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;

  const getFilteredSimulations = simulationsIdsToDelete => {
    let newSimulations = cloneDeep(state.entities.simulations);
    Utils.deleteEntities(simulationsIdsToDelete, newSimulations);
    return newSimulations;
  };

  const getFilteredProjects = projectIdsToDelete => {
    let newProjects = cloneDeep(state.entities.projects);
    Utils.deleteEntities(projectIdsToDelete, newProjects);
    return newProjects;
  };

  switch (action.type) {
    case actionType.UPDATE_SELECTED_ITEM: {
      let form = "";
      if (!Array.isArray(payload)) {
        if (payload.type === itemType.PROJECT) form = "new simulation";
        else if (payload.type === itemType.USER) form = "new project";
      }
      return {
        ...state,
        ui: { ...state.ui, selectedItem: payload, form }
      };
    }

    case actionType.OPEN_SIMULATION: {
      return {
        ...state,
        ui: { ...state.ui, simulationOpenId: payload }
      };
    }

    case actionType.UPDATE_FORM: {
      return {
        ...state,
        ui: { ...state.ui, form: payload }
      };
    }

    case actionType.UPSERT_PROJECTS: {
      let projects = cloneDeep(state.entities.projects);
      Utils.addOrUpdateEntities(payload, projects);
      return {
        ...state,
        entities: {
          ...state.entities,
          projects
        }
      };
    }

    case actionType.UPSERT_SIMULATIONS: {
      let entities = {
        ...state.entities
      };

      let newSimulations = cloneDeep(state.entities.simulations);
      Utils.addOrUpdateEntities(payload, newSimulations);
      entities["simulations"] = newSimulations;

      const projectsToUpdate = [];
      payload.forEach(simulation => {
        const project = state.entities.projects.byId[simulation.project];
        if (project !== undefined) {
          const inserting = !project.simulation_set.includes(simulation.id);
          if (inserting) {
            const simulation_set = project.simulation_set.concat(simulation.id);
            const updatedProject = { ...project, simulation_set };
            projectsToUpdate.push(updatedProject);
          }
        }
      });

      if (projectsToUpdate.length > 0) {
        let newProjects = cloneDeep(state.entities.projects);
        Utils.addOrUpdateEntities(projectsToUpdate, newProjects);
        entities["projects"] = newProjects;
      }

      const currentSimulationIds = state.entities.simulations.allIds;
      const isSingleInsertion =
        payload.length === 1 &&
        !currentSimulationIds.includes("" + payload[0].id);
      const simulationOpenId = isSingleInsertion
        ? payload[0].id
        : state.ui.simulationOpenId;
      return {
        ...state,
        entities,
        ui: {
          ...state.ui,
          simulationOpenId
        }
      };
    }

    case actionType.SET_DIRECTORY_EXPLORER: {
      let projects = cloneDeep(defaultState.entities.projects);
      let simulations = cloneDeep(defaultState.entities.simulations);
      Utils.addOrUpdateEntities(payload.projects, projects);
      Utils.addOrUpdateEntities(payload.simulations, simulations);
      const simulationOpenId =
        payload.simulationOpenId && simulations.byId[payload.simulationOpenId]
          ? payload.simulationOpenId
          : getLastCreatedSimulationId(payload.simulations);
      return {
        ...state,
        entities: {
          projects,
          simulations
        },
        ui: {
          ...state.ui,
          simulationOpenId
        }
      };
    }

    case actionType.DELETE_PROJECTS_AND_SIMULATIONS: {
      const projectIds = payload
        .filter(({ type }) => type === itemType.PROJECT)
        .map(({ id }) => id);
      let simulationIds = payload
        .filter(({ type }) => type === itemType.SIMULATION)
        .map(({ id }) => id);
      for (const projectId of projectIds) {
        const project = state.entities.projects.byId[projectId];
        simulationIds.push(...project.simulation_set);
      }
      simulationIds = [...new Set(simulationIds)]; // making them unique
      const newSimulations = getFilteredSimulations(simulationIds);
      const newProjects = getFilteredProjects(projectIds);
      for (const simulationId of simulationIds) {
        const simulation = state.entities.simulations.byId[simulationId];
        const projectId = simulation.project;
        const projectWontBeDeleted = !projectIds.includes(projectId);
        if (projectWontBeDeleted) {
          const newSimulationSet = newProjects.byId[
            projectId
          ].simulation_set.filter(id => id !== simulationId);
          newProjects.byId[projectId].simulation_set = newSimulationSet;
        }
      }
      const simulationOpenId = newSimulations.allIds.includes(
        "" + state.ui.simulationOpenId
      )
        ? state.ui.simulationOpenId
        : -1;
      return {
        ...state,
        entities: {
          ...state.entities,
          projects: newProjects,
          simulations: newSimulations
        },
        ui: {
          ...state.ui,
          simulationOpenId,
          selectedItem: null
        }
      };
    }

    case actionType.DELETE_PROJECT: {
      const projectId = payload;
      const project = state.entities.projects.byId[projectId];
      const newSimulations = getFilteredSimulations(project.simulation_set);
      const newProjects = getFilteredProjects([projectId]);
      const simulationOpenId = newSimulations.allIds.includes(
        "" + state.ui.simulationOpenId
      )
        ? state.ui.simulationOpenId
        : -1;
      return {
        ...state,
        entities: {
          ...state.entities,
          projects: newProjects,
          simulations: newSimulations
        },
        ui: {
          ...state.ui,
          simulationOpenId,
          selectedItem: null
        }
      };
    }

    case actionType.DELETE_SIMULATION: {
      const simulationId = payload;
      const simulation = state.entities.simulations.byId[simulationId];
      const projectId = simulation.project;
      const project = state.entities.projects.byId[projectId];
      const newProject = {
        ...project,
        simulation_set: project.simulation_set.filter(id => id != simulationId)
      };
      const newSimulations = cloneDeep(state.entities.simulations);
      Utils.deleteEntities([simulationId], newSimulations);
      const newProjects = cloneDeep(state.entities.projects);
      newProjects.byId[projectId] = newProject;
      const simulationOpenId = newSimulations.allIds.includes(
        "" + state.ui.simulationOpenId
      )
        ? state.ui.simulationOpenId
        : -1;
      return {
        ...state,
        entities: {
          ...state.entities,
          projects: newProjects,
          simulations: newSimulations
        },
        ui: {
          ...state.ui,
          simulationOpenId,
          selectedItem: null
        }
      };
    }

    case actionType.EDIT_ITEM: {
      return {
        ...state,
        ui: { ...state.ui, editingItem: payload }
      };
    }

    case actionType.UPDATE_ITEM_EDITING_VALUE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingItem: { ...state.ui.editingItem, name: payload }
        }
      };
    }

    case actionType.APPLY_EDITED_PROJECT: {
      let projects = cloneDeep(state.entities.projects);
      Utils.addOrUpdateEntities([payload], projects);
      return {
        ...state,
        entities: {
          ...state.entities,
          projects
        },
        ui: { ...state.ui, editingItem: null }
      };
    }

    case actionType.APPLY_EDITED_SIMULATION: {
      let simulations = cloneDeep(state.entities.simulations);
      Utils.addOrUpdateEntities([payload], simulations);
      return {
        ...state,
        entities: {
          ...state.entities,
          simulations
        },
        ui: { ...state.ui, editingItem: null }
      };
    }

    default: {
      return state;
    }
  }
}
