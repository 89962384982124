import React, { PureComponent } from "react";
import { Menu } from "@material-ui/core";

/**
 * a menu component that does not block the rest of the screen and the main scroll bar
 * is still visible
 * @author Akira
 */
class UnselfishMenu extends PureComponent {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Menu {...rest} disableScrollLock={true}>
        {children}
      </Menu>
    );
  }
}

export default UnselfishMenu;
