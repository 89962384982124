import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import InputBase from "@material-ui/core/InputBase";

const styles = theme => ({
  root: {
    padding: 0
  },
  inputBase: {
    fontSize: 13,
    height: 1,
    color: "white",
    labelWidth: 200,
    width: 60
  }
});

/**
 * A input component made to be used as a cell in table rows
 * @author Ibtihel
 */
class GenericEditableCell extends PureComponent {
  /**
   * It prevents an event from bubbling. The reason this method was created is
   * because double clicks on a input should not fire double click handlers in parents
   * @param {Object} event - the triggered event
   */
  stopEventPropagation = event => {
    event.stopPropagation();
  };

  render() {
    const {
      name,
      classes,
      inputType,
      lable,
      field,
      onInput,
      editValue,
      width,
      align,
      onKeyDown,
      autoFocus
    } = this.props;
    if (editValue !== null) {
      return (
        <TableCell style={{ width, padding: 0 }} align={align || "right"}>
          <InputBase
            name={`${name}Edit`}
            className={classes.inputBase}
            type={inputType}
            value={editValue}
            onChange={e => onInput(e, field, inputType)}
            onClick={e => this.stopEventPropagation(e)}
            onDoubleClick={e => this.stopEventPropagation(e)}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
          />
        </TableCell>
      );
    }
    return (
      <TableCell name={name} style={{ width, padding: 0 }} align="right">
        <span test-data="textValue">{lable}</span>
      </TableCell>
    );
  }
}

export default withStyles(styles)(GenericEditableCell);
