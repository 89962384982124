export const actionTypes = {
  SET_METACELL_PATH: "SET_METACELL_PATH",
  SET_METACELL_COMPONENTS_PATH: "SET_METACELL_COMPONENTS_PATH",
  SET_LIBRARY_PATH: "SET_LIBRARY_PATH",
  SET_JOB_OVERVIEW_PATH: "SET_JOB_OVERVIEW_PATH"
};

export function setMetacellPath(path) {
  return { type: actionTypes.SET_METACELL_PATH, payload: path };
}

export function setMetacellComponentsPath(path) {
  return { type: actionTypes.SET_METACELL_COMPONENTS_PATH, payload: path };
}

export function setLibraryPath(path) {
  return { type: actionTypes.SET_LIBRARY_PATH, payload: path };
}

export function setJobOverviewPath(path) {
  return { type: actionTypes.SET_JOB_OVERVIEW_PATH, payload: path };
}
