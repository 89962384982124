import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import { Grid, Paper, Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ComponentTargets from "./components/ComponentTargets/ComponentTargets";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import FarFieldWaveFront from "./components/FarFieldWavefront/FarFieldWavefront";
import NearFieldWaveFront from "./components/NearFieldWavefront/NearFieldWavefront";
import DesignTargetDetails from "./components/DesignTargetDetails/DesignTargetDetails";
import SetPointSelector from "MetaComponent/selectors/SetPoint";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import SelectedDesignTargetApi from "MetaComponent/api/SelectedDesignTarget";
import FFWTTargetSelector from "MetaComponent/selectors/FFWTTarget";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import Spinner from "components/Spinner/Spinner";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import SetPointApi from "MetaComponent/api/SetPoint";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  tab: {
    textTransform: "none",
    textAlign: "left"
  }
});

/**
 * A component created to be the content for Meta Cell component's target.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 */
export class TargetCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focusedDesignTargetId: 0,
      selectedTabIndex: 0
    };
  }

  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    const { fetchSetPointsAction } = this.props;
    this.props.setPage(MetaComponentPaths.TARGET);
    fetchSetPointsAction();
  }

  /**
   * whenever a different target is selected in the UI,
   * we decide on which wavefront to show
   */
  componentDidUpdate(prevProps, prevState) {
    const { ffwfTargets, designTargets } = this.props,
      { focusedDesignTargetId } = this.state,
      focusedDesignTargetChanged =
        prevState.focusedDesignTargetId !== focusedDesignTargetId;
    if (focusedDesignTargetChanged && focusedDesignTargetId) {
      const ffwfTarget = designTargets.byId[focusedDesignTargetId].FFWFTarget;
      this.setState({ selectedTabIndex: ffwfTarget ? 1 : 0 });
    }
  }

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  /**
   * it changes the focused design target state
   * @param {Number} id - the design target id to be focused
   * @callback
   */
  focusOnDesignTarget = id => {
    this.setState({
      focusedDesignTargetId: id
    });
  };

  /**
   * it gets the id of the available set points in the relationship between
   * the open meta component and its design targets.
   * @returns {Number[]} - the available set points' ids
   */
  getCompatibleSetPointsIds = () => {
    const { focusedDesignTargetId } = this.state;
    if (focusedDesignTargetId) {
      const { selectedDesignTargets } = this.props,
        selectedDesignTarget = Object.values(selectedDesignTargets.byId).find(
          sdt => sdt.design_target === focusedDesignTargetId
        );
      let compatibleSetPoints = [...selectedDesignTarget.list_of_set_points];
      // if for backend inconsistency the selected set point is not in the list of set points
      // we add it to the list.
      if (
        selectedDesignTarget.set_point &&
        !compatibleSetPoints.includes(selectedDesignTarget.set_point)
      ) {
        compatibleSetPoints.push(selectedDesignTarget.set_point);
      }
      return compatibleSetPoints;
    }
    return [];
  };

  /**
   * it updates the selected design target entity with the new properties
   * @param {Object} properties - the new properties
   */
  updateSelectedDesignTarget = properties => {
    const { selectedDesignTargets, updateSelectedDesignTarget } = this.props,
      selectedDesignTarget = Object.values(selectedDesignTargets.byId).find(
        sdt => sdt.design_target === this.state.focusedDesignTargetId
      );
    return updateSelectedDesignTarget(selectedDesignTarget.id, properties);
  };

  /**
   * Callback to handle the tab change event
   */
  handleTabChange = (event, newValue) => {
    this.setState({
      selectedTabIndex: newValue
    });
  };

  render() {
    const { ready, classes } = this.props;
    const { selectedTabIndex, focusedDesignTargetId } = this.state;
    if (!ready) {
      return (
        <div className={classes.progress}>
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ComponentTargets
                compatibleSetPointsIds={this.getCompatibleSetPointsIds()}
                focusedDesignTargetId={focusedDesignTargetId}
                focusOnDesignTarget={this.focusOnDesignTarget}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DesignTargetDetails
              compatibleSetPointsIds={this.getCompatibleSetPointsIds()}
              focusedDesignTargetId={focusedDesignTargetId}
              openMetaComponent={this.getOpenMetaComponent()}
              updateSelectedDesignTarget={this.updateSelectedDesignTarget}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {focusedDesignTargetId !== 0 && (
              <Paper className={classes.paper}>
                <Tabs
                  classes={{ root: classes.tabs }}
                  value={selectedTabIndex}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleTabChange}
                  aria-label="far-field and near-field wavefront targets"
                >
                  <Tab
                    classes={{ root: classes.tab }}
                    label="Near-field Wavefront"
                  />
                  <Tab
                    classes={{ root: classes.tab }}
                    label="Far-field Wavefront"
                  />
                </Tabs>
                {selectedTabIndex === 0 && (
                  <NearFieldWaveFront
                    focusedDesignTargetId={this.state.focusedDesignTargetId}
                    openMetaComponent={this.getOpenMetaComponent()}
                  />
                )}
                {selectedTabIndex === 1 && (
                  <FarFieldWaveFront
                    focusedDesignTargetId={this.state.focusedDesignTargetId}
                    ffwfTargets={this.props.ffwfTargets}
                    designTargets={this.props.designTargets}
                  />
                )}
              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
    setPoints: SetPointSelector.getSetPoints(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    ),
    ffwfTargets: FFWTTargetSelector.getFFWTTargets(state),
    designTargets: DesignTargetSelector.getDesignTargets(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetaComponent: (id, newProperties) =>
      dispatch(DirectoryExplorerApi.updateMetaComponent(id, newProperties)),
    updateSelectedDesignTarget: (id, newProperties) =>
      dispatch(
        SelectedDesignTargetApi.updateSelectedDesignTarget(id, newProperties)
      ),
    fetchSetPointsAction: () => dispatch(SetPointApi.fetchSetPoints())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(TargetCanvas)));
