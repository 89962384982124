import React, { PureComponent } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SweepInput from "components/SweepInput/SweepInput";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import IconTooltip from "components/IconTooltip/IconTooltip";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 360
  },
  container: {
    alignItems: "center",
    justifyContent: "center"
  },
  tip: {
    opacity: 0.7
  }
});

/**
 * A class component to allow the user to insert maximum diffraction orders parameters for the simulation
 * @typedef {Component} MaxDiffractionOrdersForm
 * @author Ibtihel
 * TODO: extract the similarities it has with IncidentLightForm and MetaCellGlobalParametersForm
 */
export class DiffractionOrdersForm extends PureComponent {
  /**
   * It handles a change in a field when it is being edited and calls an action
   * @param {Object} event - the change event
   * @param {String} field - the field being edited
   */
  handleUpdateField = (event, field) => {
    const newValue = {
      [field]: event.target.value
    };
    const { updateFieldCallback } = this.props;
    updateFieldCallback(newValue);
  };

  onXChange = x => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ X: x });
  };

  onYChange = y => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ Y: y });
  };

  render() {
    const {
      classes,
      maxDiffractionOrders: { X, Y }
    } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div test-data="titleWrapper">
              <Typography variant="h5" component="h3" style={{ float: "left" }}>
                Accuracy
              </Typography>
              <IconTooltip
                text="This setting defines the accuracy of the calculation. Increasing
              “x” and “y” increases the accuracy but also increases the
              calculation time. Above you can set the number of diffraction
              orders considered in x and y direction for this simulation."
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SweepInput
                    name="DiffOrderX"
                    test-data="X"
                    value={X}
                    onChange={this.onXChange}
                    label="X direction"
                    restrictFloat
                  />
                </Grid>

                <Grid item xs={6}>
                  <SweepInput
                    name="DiffOrderY"
                    test-data="Y"
                    value={Y}
                    onChange={this.onYChange}
                    label="Y direction"
                    restrictFloat
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(DiffractionOrdersForm));
