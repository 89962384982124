import React, { Component } from "react";
import PropTypes from "prop-types";
import Helper from "MetaCell/helper/SimulationResult";
import MaterialGraphs from "Library/containers/MaterialsCanvas/components/Materials/components/MaterialGraphs";
import MaterialHelper from "MetaCell/helper/Material";

export default class HiddenMaterialGraphs extends Component {
  /**
   * it gets the necessary data from the result's configuration for graphs of
   * used materials to be generated. This graphs should be rendered somewhere not visible by the user
   * because they only exist for pictures to be taken.
   * @returns {Object[]} a list of used material data containing the layer name, the used material name and
   * the plot data corresponding to the used material
   */
  getUsedMaterialsGraphsData = () => {
    const { configuration } = this.props;
    let usedMaterialsGraphsData = [];
    if (configuration !== null && configuration !== undefined) {
      const usedMaterialsByLayers = Helper.getUsedMaterialsByLayersFromConfiguration(
        configuration
      );
      usedMaterialsGraphsData = usedMaterialsByLayers.map(usedMaterial => {
        var {
          layersNames,
          name,
          wavelength,
          refractiveIndex,
          absorptionCoeff
        } = usedMaterial;
        return {
          layersNames,
          name,
          data: MaterialHelper.buildMaterialGraphData(
            wavelength,
            refractiveIndex,
            absorptionCoeff
          )
        };
      });
    }
    return usedMaterialsGraphsData;
  };

  render() {
    return (
      <div style={{ position: "absolute", left: -9999 }}>
        {this.getUsedMaterialsGraphsData().map(graphData => (
          <div
            className="usedMaterialGraph"
            layersNames={graphData.layersNames}
            usedMaterialName={graphData.name}
            style={{ height: 500, width: 1000 }}
          >
            <MaterialGraphs data={graphData.data} />
          </div>
        ))}
      </div>
    );
  }
}

HiddenMaterialGraphs.propTypes = {
  /**
   * the job configuration
   */
  configuration: PropTypes.object
};
