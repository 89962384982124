import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Autocomplete from "components/Autocomplete";

const styles = theme => ({
  root: {
    fontSize: 13,
    color: "white",
    height: 40
  }
});

/**
 * A select component made to be used as a cell in table rows
 * @author Akira Kotsugai
 *
 * @param {Object} classes - the style generated by material-UI
 * @param {String} value - the value that is going to be shown
 * @param {String} field - a field identifier that should match the store value
 * @param {Number} listIndex - a row identifier to update the correct record
 * @param {Function} onInput - a callback function to resolve value changes
 * @param {Boolean} setToEdit - defines if it should just display the value or show a select
 * @param {Array} options - a list of options to be selected
 */
const GenericEditableCellSelect = ({
  classes,
  name,
  value,
  field,
  onInput,
  editValue,
  options,
  width,
  onEnterClosed,
  autoFocus,
  autocomplete
}) => {
  const [open, setOpen] = React.useState(false);

  /**
   * it was create to override the on enter behaviour. instead of opening the options,
   * we want to save the editing.
   * @param {Object} event - the open event.
   */
  const handleOpen = event => {
    if (event.key === "Enter") {
      onEnterClosed();
    } else {
      setOpen(true);
    }
  };

  if (editValue !== null) {
    if (autocomplete) {
      const suggestions = options.map(option => ({
        label: option.name,
        value: option.id
      }));
      return (
        <Autocomplete
          id={field}
          name={field}
          suggestions={suggestions}
          onChange={suggestion => {
            onInput(suggestion, field, "select");
          }}
          value={suggestions.find(({ value }) => value === editValue)}
        />
      );
    }
    return (
      <Select
        autoFocus={autoFocus}
        open={open}
        style={{ float: "right" }}
        align="right"
        value={editValue}
        className={classes.root}
        input={<OutlinedInput name={field} />}
        onChange={event => onInput(event, field, "select")}
        onOpen={e => handleOpen(e)}
        onClose={e => setOpen(false)}
      >
        {options.map(option => (
          <MenuItem key={option.name} value={option.id}>
            <span test-data="optionText">{option.name}</span>
          </MenuItem>
        ))}
      </Select>
    );
  }
  return (
    <TableCell name={name} style={{ width, padding: 0 }} align="right">
      <span test-data="textValue">{value}</span>
    </TableCell>
  );
};

export default withStyles(styles)(GenericEditableCellSelect);
