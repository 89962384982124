import React, { PureComponent } from "react";
import { withStyles, Grid, Button, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import ItemOptions from "../ItemOptions/ItemOptions";

const styles = theme => ({
  iconButton: {
    padding: 5
  }
});

/**
 * A component to show operators in the structure combination
 * @author Akira Kotsugai
 */
export class OperatorItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false
    };
  }

  selectOperator = event => {
    this.setState({ editing: false });
    this.props.onEdit(this.props.itemIndex, event.target.value);
  };

  render = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={1}>
        {this.state.editing ? (
          <ItemOptions
            show={this.state.editing}
            handleSelect={this.selectOperator}
            options={this.props.options}
            handleClose={() => this.setState({ editing: false })}
          />
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }} />
              <IconButton
                className={classes.iconButton}
                onClick={() => this.setState({ editing: true })}
                test-data="editOperatorBtn"
                disabled={this.props.disabled}
              >
                <EditIcon />
              </IconButton>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  style={{ width: "100%", minWidth: 0 }}
                  color="inherit"
                  variant="outlined"
                  disabled={this.props.disabled}
                >
                  <span>
                    {
                      this.props.options.find(
                        ({ name }) => name === this.props.operator
                      ).label
                    }
                  </span>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
}

OperatorItem.propTypes = {
  /**
   * the operator type
   */
  operator: PropTypes.string.isRequired,
  /**
   * possible parameterized structures
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * callback for editing an item
   */
  onEdit: PropTypes.func.isRequired,
  /**
   * index of the item in the structure combination
   */
  itemIndex: PropTypes.number.isRequired
};

export default withStyles(styles)(OperatorItem);
