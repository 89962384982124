import React, { PureComponent } from "react";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import UserSelector from "BaseApp/selectors/User";
import UserAction from "BaseApp/actions/User";
import { DecimalSeparator } from "BaseApp/reducers/user";
import HelperUtils from "MetaCell/helper/HelperUtils";

/**
 * a component to allow a text field to be a number input with separators defined at runtime.
 * @author Akira Kotsugai
 */
export class NumberInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.validSeparators = Object.values(DecimalSeparator);
    this.DECIMAL_PLACES_LIMIT = 6;
    this.DISPLAY_DECIMAL_PLACES_LIMIT = 3;
    this.INTEGER_ZERO_LIMIT = 10;
    this.EXPO_NOTATION_CHARS = ["e", "E", "+", "-"];
  }

  isNumberOrSeparator(element) {
    return (
      !isNaN(element) ||
      this.EXPO_NOTATION_CHARS.includes(element) ||
      this.validSeparators.includes(element)
    );
  }

  multipleDecimalSeparatorOccurrences(value) {
    // ignore the valid characters and validate numbers amongst them.
    if (this.props.validCharacters) {
      const values = value.split(
        new RegExp(`[${this.props.validCharacters.join("")}]`)
      );
      for (const splittedValue of values) {
        if (this.hasMultipleDecimalSeparators(splittedValue)) {
          return true;
        }
      }
      return false;
    } else {
      return this.hasMultipleDecimalSeparators(value);
    }
  }

  /**
   * @param {String} value - the string number value
   * @returns
   */
  hasMultipleDecimalSeparators(value) {
    let separatorsOccurrences = 0;
    for (const separator of this.validSeparators) {
      const separatorOcurrence = value.split(separator).length - 1;
      separatorsOccurrences += separatorOcurrence;
    }
    return separatorsOccurrences > 1;
  }

  /**
   * @param {String} value - the number input value
   * @returns {String[]} all separator ocurrences
   */
  getSeparators(value) {
    let separators = [];
    for (const separator of this.validSeparators) {
      if (value.includes(separator)) {
        separators.push(separator);
      }
    }
    return separators;
  }

  /**
   * @param {String} value - the number input value
   * @returns {String} the first occurrence of a separator
   */
  getSeparator(value) {
    for (const separator of this.validSeparators) {
      if (value.includes(separator)) {
        return separator;
      }
    }
    return null;
  }

  isNumberOfDecimalPlacesValid(number) {
    const decimalPlaces = number.split(".")[1];
    if (decimalPlaces && decimalPlaces.length > this.DECIMAL_PLACES_LIMIT) {
      return false;
    }
    return true;
  }

  isNumberOfZeroIntegersValid(number) {
    const prohibitedIntegerEnding = Array.from(
      Array(this.INTEGER_ZERO_LIMIT).keys()
    )
      .map(() => "0")
      .join("");
    const integer = number.split(".")[0];
    if (integer.endsWith(prohibitedIntegerEnding)) {
      return false;
    }
    return true;
  }

  /**
   * @returns {Boolean} whether there are at most 3 decimal places (unlimited for scientific notation)
   */
  isInputValid(value) {
    const valueWithoutCustomSeparators = value
      .split(new RegExp(`[${this.validSeparators.join("")}]`, "g"))
      .join(".");
    const { validCharacters } = this.props;
    const numbersToValidate = validCharacters
      ? valueWithoutCustomSeparators.split(
          new RegExp(`[${validCharacters.join("")}]`, "g")
        )
      : [valueWithoutCustomSeparators];
    for (const number of numbersToValidate) {
      const numberContainsExpoNotationChars = number
        .split("")
        .some(char => this.EXPO_NOTATION_CHARS.includes(char));
      if (
        !HelperUtils.isExpoNotation(number) &&
        !numberContainsExpoNotationChars
      ) {
        if (
          !this.isNumberOfDecimalPlacesValid(number) ||
          !this.isNumberOfZeroIntegersValid(number)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * @returns {Boolean} whether there are at most 3 decimal places (unlimited for scientific notation)
   */
  validateNegative(value) {
    const valueWithoutCustomSeparators = value
      .split(new RegExp(`[${this.validSeparators.join("")}]`, "g"))
      .join(".");
    const { validCharacters } = this.props;
    const numbersToValidate = validCharacters
      ? valueWithoutCustomSeparators.split(
          new RegExp(`[${validCharacters.join("")}]`, "g")
        )
      : [valueWithoutCustomSeparators];

    if (Array.isArray(this.props.allowNegative)) {
      let index = 0;
      for (const number of numbersToValidate) {
        if (!this.props.allowNegative[index++] && number.startsWith("-")) {
          return false;
        }
      }
    } else if (!Array.isArray(this.props.allowNegative)) {
      if (!this.props.allowNegative && value.startsWith("-")) {
        return false;
      }
    }
    return true;
  }

  decimizeExpoNotations(value) {
    const { validCharacters } = this.props;
    const numbersToParse = validCharacters
      ? value.split(new RegExp(`[${validCharacters.join("")}]`, "g"))
      : [value];
    const parsedNumbers = numbersToParse.map(number => {
      return HelperUtils.isExpoNotation(number) &&
        parseFloat(number)
          .toString()
          .includes("e")
        ? parseFloat(number).toFixed(20)
        : number;
    });
    return parsedNumbers.join(validCharacters ? validCharacters[0] : "");
  }

  /**
   * it only calls the parent onChange if the typed are really numbers and have
   * at max 1 valid separator. except when it is being used by a sweep input, then, anything is allowed
   * @callback
   * @param {Object} event
   */
  handleChange = event => {
    const { noAutoDecimize } = this.props;
    const { value } = event.target;
    const inputValueHasSweepFormat =
      this.props.isSweep && value.startsWith("=");
    const characters = value.split("");
    const numberOfDecimalPlacesAreValid = this.isInputValid(value);
    const charactersAreValid =
      inputValueHasSweepFormat ||
      (this.validateNegative(value) &&
        (this.isNumberOrSeparator(value) ||
          characters.every(
            character =>
              this.isNumberOrSeparator(character) ||
              (this.props.validCharacters &&
                this.props.validCharacters.includes(character))
          )) &&
        numberOfDecimalPlacesAreValid);
    if (charactersAreValid) {
      if (
        inputValueHasSweepFormat ||
        !this.multipleDecimalSeparatorOccurrences(value)
      ) {
        const oldValue = event.target.defaultValue;
        const standardizedValue = this.standardizeDecimalSeparators(
          value,
          oldValue
        );
        const separator = this.getSeparator(standardizedValue);
        if (separator) {
          // the parent change handler will always receive a period as a separator because
          // instead of the typed separator because that's the only way to make floats
          event.target.value = standardizedValue.split(separator).join(".");
          if (separator !== this.props.decimalSeparator) {
            this.props.setDecimalSeparator(separator);
          }
        }
        if (!noAutoDecimize)
          event.target.value = this.decimizeExpoNotations(event.target.value);
        this.props.onChange(event);
      }
    }
  };

  /**
   * when there are multiple number values in the same value, multiple decimal separators
   * will be encountered, but we have to make sure they are all the same.
   * @param {String} value - the string containing the numbers to have their decimal places unique
   * @param {String} oldValue - the value before the change event, to check if the separator changed
   * @returns {String} the value with unique decimal separators
   */
  standardizeDecimalSeparators(value, oldValue) {
    // multiple decimal separators are only allowed when there are valid characters
    // because it means there can be multiple numbers in the string value
    if (this.props.validCharacters) {
      const oldSeparators = this.getSeparators(oldValue);
      const newSeparators = this.getSeparators(value);
      const newDifferentSeparator = newSeparators.find(
        separator => !oldSeparators.includes(separator)
      );
      if (newDifferentSeparator) {
        return value
          .split(new RegExp(`[${this.validSeparators.join("")}]`, "g"))
          .join(newDifferentSeparator);
      }
    }
    return value;
  }

  render() {
    const {
      isSweep,
      validCharacters,
      decimalSeparator,
      setDecimalSeparator,
      ...rest
    } = this.props;
    return (
      <TextField
        {...rest}
        value={HelperUtils.getDisplayableNumber(
          this.props.value,
          this.props.decimalSeparator,
          this.props.validCharacters,
          this.props.DISPLAY_DECIMAL_PLACES_LIMIT,
          this.INTEGER_ZERO_LIMIT,
          this.EXPO_NOTATION_CHARS
        )}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  decimalSeparator: UserSelector.getDecimalSeparator(state)
});

const mapDispatchToProps = dispatch => {
  return {
    setDecimalSeparator: separator =>
      dispatch(UserAction.setDecimalSeparator(separator))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberInput);
