export const actionType = Object.freeze({
  SET_SIMULATION_TARGETS: "SET_SIMULATION_TARGETS",
  DELETE_SIMULATION_TARGET: "DELETE_SIMULATION_TARGET",
  UPSERT_SIMULATION_TARGETS: "UPSERT_SIMULATION_TARGETS"
});

/**
 * A class created to create design target actions to be dispatched to redux. It is already tested with
 * Design Target reducer, so it does not need to be unit tested.
 */
export default class SimulationTargetAction {
  static set = value => ({
    type: actionType.SET_SIMULATION_TARGETS,
    payload: value
  });

  static delete = designTargetId => ({
    type: actionType.DELETE_SIMULATION_TARGET,
    payload: designTargetId
  });

  static upsert = value => ({
    type: actionType.UPSERT_SIMULATION_TARGETS,
    payload: value
  });
}
