import { actionType } from "MetaComponent/actions/Design";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testJobs = {
  byId: {
    127: {
      id: 127,
      status: "DONE",
      progress: "[33.33333333333333]",
      start: "2020-07-08T09:28:30.711682Z",
      end: null,
      errors: {
        exception:
          'Cannot assign "2": "FamilyMember.simulation_job" must be a "SimulationJob" instance.',
        type: "<class 'ValueError'>",
        traceback:
          '  File "C:\\ReactJS\\planopsim\\kernel\\kernel_worker\\meta_component_worker\\tasks.py", line 46, in run_design\n    errors = step(design_job)\n  File "C:\\ReactJS\\planopsim\\kernel\\kernel_worker\\meta_component_worker\\tasks.py", line 203, in create_sp_results\n    model_from_dict(FamilyMember, fm) for fm in design_job.used_family_members\n  File "C:\\ReactJS\\planopsim\\kernel\\kernel_worker\\meta_component_worker\\tasks.py", line 203, in <listcomp>\n    model_from_dict(FamilyMember, fm) for fm in design_job.used_family_members\n  File "C:\\ReactJS\\planopsim\\kernel\\backend\\web_platform\\web_platform\\models.py", line 569, in model_from_dict\n    return model_type(**data)\n  File "c:\\reactjs\\planopsim\\kernel\\stefanenv\\lib\\site-packages\\django\\db\\models\\base.py", line 467, in __init__\n    _setattr(self, field.name, rel_obj)\n  File "c:\\reactjs\\planopsim\\kernel\\stefanenv\\lib\\site-packages\\django\\db\\models\\fields\\related_descriptors.py", line 210, in __set__\n    self.field.remote_field.model._meta.object_name,\n'
      },
      meta_component: 1,
      creationDate: "2020-07-08T09:28:28.160771Z"
    },
    128: {
      id: 128,
      status: "ERROR",
      progress: "[0.0]",
      start: "2020-07-08T09:29:20.332349Z",
      end: null,
      errors: {
        message: "failed to converge",
        status: "failed",
        epsilon: 0.0002097500565984162,
        num_iterations: 10
      },
      meta_component: 1,
      creationDate: "2020-07-08T09:29:17.769639Z"
    },
    129: {
      id: 129,
      status: "FAILED",
      progress: "[0.0]",
      start: "2020-07-08T09:29:20.332349Z",
      end: null,
      errors: {},
      meta_component: 1,
      creationDate: "2020-07-08T09:29:17.769639Z"
    }
  },
  allIds: [127, 128, 129],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} DesignDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    designJobs: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    selectedJobId: null,
    overviewFilters: {}
  }
};

/**
 * Reducer function to manipulate the state of design
 * @param {Object} [state=DesignDefaultState] - design entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.UPDATE_OVERVIEW_FILTERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          overviewFilters: { ...state.ui.overviewFilters, ...payload }
        }
      };
    }

    case actionType.DELETE_DESIGN_JOBS: {
      let designJobs = cloneDeep(state.entities.designJobs);
      Utils.deleteEntities(payload, designJobs);
      return {
        ...state,
        entities: { ...state.entities, designJobs }
      };
    }
    case actionType.SET_DESIGN_JOBS: {
      let designJobs = cloneDeep(defaultState.entities.designJobs);
      Utils.addOrUpdateEntities(payload, designJobs);
      const lastJobId = payload.length
        ? Math.max.apply(
            Math,
            payload.map(job => job.id)
          )
        : null;
      const selectedJobId =
        state.ui.selectedJobId && designJobs.byId[state.ui.selectedJobId]
          ? state.ui.selectedJobId
          : lastJobId;
      return {
        ...state,
        entities: { ...state.entities, designJobs },
        ui: { ...state.ui, selectedJobId }
      };
    }
    case actionType.UPSERT_DESIGN_JOBS: {
      let designJobs = cloneDeep(state.entities.designJobs);
      Utils.addOrUpdateEntities(payload, designJobs);
      return {
        ...state,
        entities: { ...state.entities, designJobs }
      };
    }
    case actionType.SELECT_DESIGN_JOB: {
      return {
        ...state,
        ui: { ...state.ui, selectedJobId: payload }
      };
    }
    case actionType.RESET_JOBS: {
      return defaultState;
    }
    default:
      return state;
  }
}
