import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  typography: {
    textAlign: "center",
    marginBottom: "1.5rem"
  }
}));

const MaintenancePage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant="h3" component="h1" className={classes.typography}>
          We'll be back soon! 🚧
        </Typography>
        <div>
          <Typography variant="body1">
            Oops, it looks like we're giving our system a new pair of glasses.
            If you need to reach us, just squint really hard and send a message
            to{" "}
            <Link href="mailto:support@planopsim.com" color="primary">
              support@planopsim.com
            </Link>
            . We'll be back with better optics in no time! 🚀
          </Typography>
          <Typography variant="body1">
            —{" "}
            <Link
              href="https://planopsim.com/"
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              PlanOpSim
            </Link>{" "}
            Team
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default MaintenancePage;
