import React, { PureComponent } from "react";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Spinner from "components/Spinner/Spinner";

/**
 * a component to be used to overlay components while they are saving
 * @author Akira Kotsugai
 */
export default class LoadingOverlay extends PureComponent {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 11
        }}
      >
        <div
          style={{
            display: "table",
            width: "100%",
            height: "100%",
            backgroundColor: fade("#ffffff", 0.7)
          }}
        >
          <div
            style={{
              display: "table-cell",
              width: "100%",
              height: "100%",
              verticalAlign: "middle",
              textAlign: "center"
            }}
          >
            <Spinner />
          </div>
        </div>
      </div>
    );
  }
}
