import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import SimulationResult from "./components/SimulationResult";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import StructureSelector from "MetaCell/selectors/Structure";
import SimulationApi from "MetaCell/api/Simulation";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { metaCellPaths } from "MetaCell/MetaCell";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import UserSelector from "BaseApp/selectors/User";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import SimulationSelector from "MetaCell/selectors/Simulation";
import { isEqual } from "lodash";
import SimulationAction from "MetaCell/actions/Simulation";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import PlotboxManager from "MetaCell/components/PlotboxManager/PlotboxManager";
import HelperUtils from "MetaCell/helper/HelperUtils";
import JobStatusAndProgress from "components/JobStatusAndProgress/JobStatusAndProgress";

export const styles = {};

const mapStateToProps = state => {
  return {
    layers: StructureSelector.getLayers(state),
    simulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
    simulations: DirectoryExplorerSelector.getSimulations(state),
    projects: DirectoryExplorerSelector.getProjects(state),
    user: UserSelector.getUser(state),
    jobs: SimulationSelector.getJobs(state),
    store: state,
    sweptVariables: SimulationSettingsSelector.getSweptVariables(state),
    selectedJobId: SimulationSelector.getSelectedJobId(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm
        )
      ),
    fetchSimulationJobs: simulationId =>
      dispatch(SimulationApi.getSimulationJobs(simulationId)),
    selectJob: jobId => dispatch(SimulationAction.selectJob(jobId)),
    resetJobs: () => dispatch(SimulationAction.resetJobs()),
    runSimulation: (simulationId, errorHandler, keepPlots) =>
      dispatch(SimulationApi.run(simulationId, errorHandler, keepPlots)),
    getTaskResultStatus: jobId =>
      dispatch(SimulationApi.getTaskResultStatus(jobId))
  };
};

/**
 * @constant
 * @typedef {Object} SimulationMessages
 * @property {String} OUTER_LAYERS_CONSTRAINT - to be shown when the outer simulations are not homogeneous
 * @property {String} ONLY_ONE_LAYER_WARNING - to be shown when the simulation has only one layer
 * @property {String} ONE_LAYER_CONSTRAINT - to be shown when there is no layer in the stack
 * @property {String} UNUSED_SWEEP_VARIABLES - to be shown when there are sweep variables that were not used in the simulation
 * @global
 * it defines messages to be shown in the simulate page
 */
export const messages = Object.freeze({
  OUTER_LAYERS_CONSTRAINT:
    "You cannot start a simulation if the outer layers are not homogeneous.",
  ONLY_ONE_LAYER_WARNING: "The simulation was started with only one layer.",
  ONE_LAYER_CONSTRAINT: "There must be at least one layer in the structure.",
  UNUSED_SWEEP_VARIABLES:
    "The following variables were not used in the simulation:"
});

export class SimulateCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simulationJobStatus: null,
      message: "",
      polling: false,
      selectedSimulationJob: null,
      starting: false,
      showSpinner: true
    };
  }

  /**
   *
   * handle the returned api error
   */
  handleError(error) {
    if (error.status === 403) {
      this.setState({
        message: error.data.detail
      });
    }
  }

  /**
   * it sets the open screen and polls the job status immediately
   */
  componentDidMount() {
    const { setPage } = this.props;
    setPage(metaCellPaths.SIMULATE);
    this.setState({ polling: true }, this.getSimulationJobStatus);
  }

  /**
   * it polls the job status when the selected job changes
   */
  componentDidUpdate(prevProps) {
    const { selectedJobId } = this.props;
    if (!isEqual(prevProps.selectedJobId, selectedJobId)) {
      this.setState({ polling: true }, this.getSimulationJobStatus);
    }
  }

  componentWillUnmount() {
    this.getSimulationJobStatusWithDelay.cancel();
  }

  verifyParameters() {
    const { layers, store, sweptVariables, simulationId } = this.props,
      layersQuantity = layers.allIds.length;
    let firstLayerId,
      lastLayerId,
      firstLayer,
      lastLayer,
      valid = true,
      message = "";
    if (layersQuantity > 0) {
      firstLayerId = layers.allIds[0];
      lastLayerId = layers.allIds[layersQuantity - 1];
      firstLayer = layers.byId[firstLayerId];
      lastLayer = layers.byId[lastLayerId];
      valid = firstLayer.discretized === null && lastLayer.discretized === null;
      if (valid) {
        if (layersQuantity === 1) {
          message += messages.ONLY_ONE_LAYER_WARNING;
        }
        const simulationVariables = sweptVariables.filter(
          variable => variable.simulation === simulationId
        );
        const unusedSweepVariables = HelperUtils.getUnusedSweepVariableNames(
          store,
          simulationVariables
        );
        if (unusedSweepVariables.length > 0) {
          message += `${message == "" ? "" : "\n"}${messages.UNUSED_SWEEP_VARIABLES
            }`;
          unusedSweepVariables.forEach(
            variableName => (message += `\n\t-${variableName}`)
          );
        }
      } else {
        message += messages.OUTER_LAYERS_CONSTRAINT;
        valid = false;
      }
    } else {
      valid = false;
      message += messages.ONE_LAYER_CONSTRAINT;
    }
    this.setState({ message });
    return valid;
  }

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getSimulationJobStatusWithDelay = debounce(() => {
    this.getSimulationJobStatus();
  }, 5000);

  /**
   * it gets the simulation task status from the backend endpoint and depending on the response
   * it decides whether it should call itself again, but with delay for the next call.
   */
  getSimulationJobStatus = async () => {
    const { polling, simulationJobStatus } = this.state,
      { jobs, getTaskResultStatus, selectedJobId } = this.props;

    let simulationJob = selectedJobId ? jobs.byId[selectedJobId] : null;
    if (simulationJob) {
      // is progressed
      return getTaskResultStatus(simulationJob.id)
        .then(data => {
          if (simulationJobStatus && simulationJobStatus.progress) {
            if (simulationJobStatus.progress !== data.progress) {
              this.setState({
                showSpinner: false
              }, () => {
                this.setState({
                  showSpinner: true
                })
              })
            }
          }
          this.setState({
            simulationJobStatus: data,
            selectedSimulationJob: simulationJob
          });
          if (
            data.status === "ERROR" ||
            data.status === "DONE" ||
            data.status === "STOPPED" ||
            data.status === "FAILED"
          ) {
            this.setState({
              polling: false
            });
          } else if (polling) {
            this.getSimulationJobStatusWithDelay();
          }
        })
        .catch(() => {
          this.setState({
            polling: false
          });
        });
    } else {
      this.setState({ polling: false });
    }
  };

  /**
   * it verify the parameters, starts a simulation, sets the simulation task
   * in the state and start polling the status.
   * @param {Boolean} keepPlots - whether the new simulation show keep the same plots
   */
  runSimulation = (keepPlots = false) => {
    const { simulationId, runSimulation } = this.props,
      valid = this.verifyParameters();
    if (valid) {
      this.setState({ starting: true }, () => {
        return runSimulation(
          simulationId,
          this.handleError.bind(this),
          keepPlots
        )
          .then(() => this.getSimulationJobStatusWithDelay())
          .finally(() => this.setState({ starting: false }));
      });
    }
  };

  /**
   * it stops a running simulation job
   * @param {Number} jobId - the job id
   */
  stopSimulation = () => {
    const { selectedSimulationJob } = this.state;
    SimulationApi.stopSimulationJob(selectedSimulationJob.id)
      .then(response => {
        this.getSimulationJobStatus();
      })
      .catch(error => console.log("Simulation API: failed to stop simulation"));
  };

  /**
   * @param {Boolean} keepPlots - whether the new simulation show keep the same plots
   */
  runNewSimulation = keepPlots => {
    this.setState(
      {
        simulationJobStatus: null,
        polling: true,
        message: ""
      },
      () => this.runSimulation(keepPlots)
    );
  };

  /**
   * it redirects the user to the structure screen
   */
  redirectToStructure = () => {
    const { history } = this.props;
    history.push(metaCellPaths.STRUCTURE);
  };

  /**
   * it is supposed to be used by the result component when no configuration is found in the result.
   * it asks the user if he/she wants to try to run the simulation again, otherwise it redirects the user
   * back to the structure screen.
   * @callback
   */
  showMissingConfigurationDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "Simulation failed to start";
    const message =
      "The last run simulation did not start due to incorrect parameters. " +
      "Would you like to rerun the simulation?";
    showConfirmDialog(
      title,
      message,
      this.runNewSimulation,
      this.redirectToStructure,
      false
    );
  };

  /**
   * it is supposed to be used by the result component to double check with the user
   * if they really want to start a new simulation and keep the plots or not when there are plots
   * @param {Object[]} plotboxes - the plot boxes of the currently selected job.
   * @callback
   */
  showNewSimulationDialog = plotboxes => {
    const { showConfirmDialog } = this.props,
      title = "New simulation",
      message =
        "Running a new simulation will generate new results with the current configuration.",
      thereAreValidPlots =
        plotboxes && plotboxes.some(plotbox => plotbox.status !== "IDLE");
    if (thereAreValidPlots) {
      showConfirmDialog(
        title,
        [
          message +
          " But you can opt to use the existing plots to see the new results."
        ],
        [
          [
            {
              option: "Keep plots",
              actions: [
                (resolve, reject) => {
                  this.runNewSimulation(true);
                  resolve();
                }
              ]
            },
            {
              option: "Don't keep plots",
              actions: [
                (resolve, reject) => {
                  this.runNewSimulation(false);
                  resolve();
                }
              ]
            }
          ]
        ],
        undefined,
        false,
        () => { }
      );
    } else {
      showConfirmDialog(
        title,
        message,
        () => this.runNewSimulation(false),
        undefined,
        false
      );
    }
  };

  /**
   * it is supposed to be passed to the result component.
   * it opens the confirm dialog to delete a plot.
   * @param {Function} deleteFunction - what to do on confirm
   * @callback
   */
  showConfirmPlotboxDeletion = deleteFunction => {
    const { showConfirmDialog } = this.props;
    const title = "Delete plot";
    const message = "Are you sure you want to delete this plot?";
    showConfirmDialog(title, message, deleteFunction, undefined, false);
  };

  forcePlotboxManagerRemount = () => {
    setTimeout(() => {
      this.setState({ hide: true });
      this.setState({ hide: false });
    }, 500);
  };

  render() {
    const {
      polling,
      message,
      simulationJobStatus,
      selectedSimulationJob,
      hide,
      starting,
      showSpinner
    } = this.state,
      { classes, simulationId, simulations, projects, user, jobs } = this.props,
      simulationEntity =
        simulationId !== -1 ? simulations.byId[simulationId] : null,
      projectEntity =
        simulationEntity !== null
          ? projects.byId[simulationEntity.project]
          : null,
      { status, errors, progress } = simulationJobStatus || {},
      hasErrors = errors && errors.length > 0,
      isRunning = status === "RUNNING",
      isIdle = status === "IDLE",
      isQueued = status === "QUEUED",
      isError = status === "ERROR",
      isDone = status === "DONE",
      isStopped = status === "STOPPED",
      isFailed = status === "FAILED",
      simulationHasJob = jobs.allIds.length > 0,
      jobExistsButStatusNotLoaded =
        simulationHasJob && simulationJobStatus === null,
      showRunning = isRunning && !hasErrors,
      showError = isError || (isRunning && hasErrors);

    return hide ? null : (
      <div>
        <JobStatusAndProgress
          jobType={"Simulation"}
          jobStatus={status}
          showStatus={showRunning || showError || isFailed || isQueued}
          showBigSpinner={
            showSpinner && (!jobs.loaded || jobExistsButStatusNotLoaded || isRunning || isQueued)
          }
          progress={progress}
          stop={selectedSimulationJob && this.stopSimulation}
          showStartButton={isIdle || (!simulationHasJob && jobs.loaded)}
          polling={polling}
          starting={starting}
          run={this.runNewSimulation}
        />

        {message && (
          <>
            <DirectionSnackbar message={message} />
          </>
        )}

        {(isDone || isError || isStopped || isFailed) && (
          <PlotboxManager simulationJobId={selectedSimulationJob.id}>
            <SimulationResult
              forcePlotboxManagerRemount={this.forcePlotboxManagerRemount}
              status={status}
              simulationId={simulationId}
              simulationJobId={selectedSimulationJob.id}
              projectName={projectEntity !== null ? projectEntity.name : ""}
              simulationName={
                simulationEntity !== null ? simulationEntity.name : ""
              }
              username={user.username}
              simulations={simulations}
              runNewSimulation={this.runNewSimulation}
              showNewSimulation={this.showNewSimulationDialog}
              handleMissingConfiguration={this.showMissingConfigurationDialog}
              jobs={jobs}
              selectSimulationJob={jobId => {
                this.props.selectJob(jobId);
              }}
            />
          </PlotboxManager>
        )}
      </div>
    );
  }
}

SimulateCanvas.propTypes = {
  setPage: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SimulateCanvas));
