import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SweepInput from "components/SweepInput/SweepInput";
import {
  Grid,
  Paper,
  Typography,
  FormLabel,
  MenuItem,
  FormControl
} from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import IconTooltip from "components/IconTooltip/IconTooltip";
import Polarization from "components/Polarization/Polarization";
import PolarizationOut from "components/PolarizationOut/PolarizationOut";
import NumberInput from "components/NumberInput/NumberInput";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import HelperUtils from "MetaCell/helper/HelperUtils";
import Direction from "components/Direction/Direction";

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  container: {
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    color: "#0000008a",
    display: "flex",
    alignItems: "center"
  },
  labelIcon: {
    fontSize: 16,
    marginLeft: 3,
    color: "#3f51b5"
  }
});
export const unitOptions = Object.freeze(["μm", "nm"]);

export const xTooltip = `This setting defines the accuracy of the calculation. Increasing
“x” increases the accuracy but also increases the
calculation time. Set the number of diffraction orders considered in x direction for this simulation.`;

export const yTooltip = `This setting defines the accuracy of the calculation. Increasing
“y” increases the accuracy but also increases the
calculation time. Set the number of diffraction orders considered in y direction for this simulation.`;

const requiredFieldText = "required";

const odaFormSchema = Yup.object().shape({
  X: Yup.string().required(requiredFieldText),
  Y: Yup.string().required(requiredFieldText),
  zenit: Yup.string().required(requiredFieldText),
  azimut: Yup.string().required(requiredFieldText),
  wavelength: Yup.string().required(requiredFieldText),
  amplitude: Yup.string().required(requiredFieldText),
  polarization: Yup.string().required(requiredFieldText),
  order_x: Yup.string().required(requiredFieldText),
  order_y: Yup.string().required(requiredFieldText),
  direction_out: Yup.string().required(requiredFieldText),
  polarization_out: Yup.string().required(requiredFieldText)
});

export const azimutTooltip =
  "Angle between incident wave and x-axis (in-plane)";
export const zenitTooltip = "Angle of incident wave to surface normal";
export const unitTooltip = "Unit of the wavelength size.";
export const orderxTooltip =
  "Diffraction order of the light exiting the component. The absolute value of order X should not be larger than the accuracy X of the meta cell";
export const orderyTooltip =
  "Diffraction order of the light exiting the component. The absolute value of order Y should not be larger than the accuracy Y of the meta cell";

export const changeUnit = (wavelength, oldUnit, newUnit, fieldValueSetter) => {
  fieldValueSetter("unit", newUnit);
  const wavelengthIsNumber = !isNaN(wavelength);
  if (wavelengthIsNumber) {
    const multiplier = HelperUtils.scaleUnit(oldUnit, newUnit);
    const newWavelength = wavelength * multiplier;
    fieldValueSetter("wavelength", newWavelength);
  }
};

export class ODAForm extends Component {
  getFormInitialValues() {
    const { setPoint, defaultValues, outgoingConditions } = this.props,
      isCreateMode = setPoint === null;
    if (isCreateMode) {
      if (defaultValues) {
        return defaultValues;
      }
      return {
        X: "",
        Y: "",
        zenit: "",
        azimut: "",
        wavelength: "",
        amplitude: "",
        polarization: "TE",
        incident_light_type: "PW",
        order_x: 0,
        order_y: 0,
        direction_out: "Transmission",
        focal_spot_x: "",
        focal_spot_y: "",
        focal_spot_z: "",
        beam_divergence: "",
        unit: unitOptions[0],
        polarization_out: "TE"
      };
    }
    const { id, diffractive_order, incident_light, unit } = setPoint,
      { X, Y } = diffractive_order,
      { order_out, direction_out, polarization_out } = outgoingConditions,
      {
        zenit,
        azimut,
        wavelength,
        amplitude,
        incident_light_type,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence,
        polarization
      } = incident_light;
    return {
      id,
      X,
      Y,
      zenit,
      azimut,
      wavelength,
      amplitude,
      unit,
      incident_light_type,
      order_x: order_out[0],
      order_y: order_out[1],
      polarization,
      polarization_out,
      direction_out,
      focal_spot_x,
      focal_spot_y,
      focal_spot_z,
      beam_divergence
    };
  }

  handleSubmit = values => {
    const {
      id,
      X,
      Y,
      zenit,
      azimut,
      wavelength,
      amplitude,
      polarization,
      polarization_out,
      unit,
      incident_light_type,
      order_x,
      order_y,
      direction_out,
      focal_spot_x,
      focal_spot_y,
      focal_spot_z,
      beam_divergence
    } = values;
    const preparedValues = {
      id,
      incident_light: {
        zenit,
        azimut,
        wavelength,
        amplitude: 1,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence,
        incident_light_type,
        polarization
      },
      diffractive_order: {
        X,
        Y
      },
      unit,
      order_out: [order_x, order_y],
      direction_out,
      polarization_out
    };
    this.props.onSubmit(preparedValues);
  };

  updateField = (changedField, setFieldValue) => {
    setFieldValue(Object.keys(changedField)[0], Object.values(changedField)[0]);
  };

  render() {
    const { setPoint, disabled, classes } = this.props;
    const isSweep = true;
    return (
      <>
        <Formik
          initialValues={this.getFormInitialValues()}
          enableReinitialize
          validationSchema={odaFormSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            submitForm,
            resetForm,
            setFieldValue
          }) => {
            this.props.bindSubmitForm(submitForm);
            this.props.bindResetForm(resetForm);
            return (
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Typography variant="h5" component="h3">
                                Incident Light
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <form
                                className={classes.container}
                                noValidate
                                autoComplete="off"
                              >
                                <Grid container spacing={3}>
                                  <Grid item xs={4}>
                                    <div>
                                      <FormLabel
                                        style={{ fontSize: 12 }}
                                        disabled={disabled}
                                        htmlFor="Y"
                                      >
                                        Amplitude
                                      </FormLabel>
                                    </div>
                                    <NumberInput
                                      test-data="IncidentLightAmplitude"
                                      name="amplitude"
                                      disabled={disabled}
                                      value={1}
                                      onChange={event =>
                                        setFieldValue(
                                          "amplitude",
                                          event.target.value
                                        )
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </Grid>

                                  <Grid item xs={4}>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      disabled={disabled}
                                      htmlFor="Y"
                                    >
                                      Wavelength
                                    </FormLabel>
                                    <SweepInput
                                      name="IncidentLightWavelength"
                                      test-data="wavelength"
                                      value={values.wavelength}
                                      onChange={value =>
                                        setFieldValue("wavelength", value)
                                      }
                                      simulationId={-1}
                                      sweptVariables={this.props.sweptVariables}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <FormControl style={{ width: "100%" }}>
                                      <div>
                                        <FormLabel
                                          style={{ fontSize: 12 }}
                                          disabled={disabled}
                                          htmlFor="Y"
                                        >
                                          Unit
                                        </FormLabel>
                                        <IconTooltip text={unitTooltip} />
                                      </div>
                                      <UnselfishSelect
                                        name="unit"
                                        value={values.unit}
                                        onChange={event =>
                                          changeUnit(
                                            values.wavelength,
                                            values.unit,
                                            event.target.value,
                                            setFieldValue
                                          )
                                        }
                                        disabled={disabled}
                                      >
                                        {unitOptions.map(unit => (
                                          <MenuItem
                                            name="PolarizationOption"
                                            value={unit}
                                          >
                                            {unit}
                                          </MenuItem>
                                        ))}
                                      </UnselfishSelect>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <FormLabel
                                      disabled={disabled}
                                      style={{ fontSize: 12 }}
                                    >
                                      Polarization
                                    </FormLabel>
                                    <Polarization
                                      updateFieldCallback={pol_dict =>
                                        setFieldValue(
                                          "polarization",
                                          pol_dict["polarization"]
                                        )
                                      }
                                      polarization={values.polarization}
                                      gridColumnXs={12}
                                      isSweep={true}
                                      hideLabel
                                      simulationId={-1}
                                      sweptVariables={this.props.sweptVariables}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div>
                                      <FormLabel
                                        style={{ fontSize: 12 }}
                                        disabled={disabled}
                                        htmlFor="incident_light_type"
                                      >
                                        Type
                                      </FormLabel>
                                    </div>
                                    <UnselfishSelect
                                      style={{ marginTop: 4 }}
                                      name="incident_light_type"
                                      value={values.incident_light_type}
                                      onChange={({ target }) =>
                                        setFieldValue(
                                          "incident_light_type",
                                          target.value
                                        )
                                      }
                                    >
                                      <MenuItem
                                        name="incident_light_type_option"
                                        value="PW"
                                      >
                                        Plane wave
                                      </MenuItem>
                                      <MenuItem
                                        name="incident_light_type_option"
                                        value="GB"
                                      >
                                        Gaussian Beam
                                      </MenuItem>
                                    </UnselfishSelect>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div test-data={"azimutLabel"}>
                                      <FormLabel
                                        style={{ fontSize: 12 }}
                                        disabled={disabled}
                                        htmlFor="Y"
                                      >
                                        Azimuth
                                      </FormLabel>
                                      <IconTooltip text={azimutTooltip} />
                                    </div>
                                    <SweepInput
                                      name="IncidentLightAzimut"
                                      test-data="azimut"
                                      value={values.azimut}
                                      onChange={value =>
                                        setFieldValue("azimut", value)
                                      }
                                      simulationId={-1}
                                      sweptVariables={this.props.sweptVariables}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div test-data={"zenitLabel"}>
                                      <FormLabel
                                        style={{ fontSize: 12 }}
                                        disabled={disabled}
                                        htmlFor="Y"
                                      >
                                        Zenith
                                      </FormLabel>
                                      <IconTooltip text={zenitTooltip} />
                                    </div>
                                    <SweepInput
                                      name="IncidentLightZenit"
                                      test-data="zenit"
                                      value={values.zenit}
                                      onChange={value =>
                                        setFieldValue("zenit", value)
                                      }
                                      simulationId={-1}
                                      sweptVariables={this.props.sweptVariables}
                                    />
                                  </Grid>
                                  {values.incident_light_type === "GB" && (
                                    <Grid item xs={4}>
                                      <div test-data={"beam_divergence"}>
                                        <FormLabel
                                          style={{ fontSize: 12 }}
                                          disabled={disabled}
                                          htmlFor="beam_divergence"
                                        >
                                          Beam Divergence
                                        </FormLabel>
                                      </div>

                                      <SweepInput
                                        name="IncidentLightBeamDivergence"
                                        test-data="beam_divergence"
                                        value={values.beam_divergence}
                                        disabled={disabled && "DISABLE_INPUT"}
                                        onChange={value =>
                                          setFieldValue(
                                            "beam_divergence",
                                            value
                                          )
                                        }
                                        simulationId={this.props.simulationId}
                                        sweptVariables={
                                          this.props.sweptVariables
                                        }
                                      />
                                    </Grid>
                                  )}
                                  {values.incident_light_type === "GB" && (
                                    <Grid item xs={4}>
                                      <div test-data={"focal_spot_x"}>
                                        <FormLabel
                                          style={{ fontSize: 12 }}
                                          disabled={disabled}
                                          htmlFor="focal_spot_x"
                                        >
                                          Focal Spot X
                                        </FormLabel>
                                      </div>
                                      <SweepInput
                                        name="IncidentLightFocalSpotX"
                                        test-data="focal_spot_x"
                                        value={values.focal_spot_x}
                                        disabled={disabled && "DISABLE_INPUT"}
                                        onChange={value =>
                                          setFieldValue("focal_spot_x", value)
                                        }
                                        simulationId={this.props.simulationId}
                                        sweptVariables={
                                          this.props.sweptVariables
                                        }
                                        allowNegative
                                      />
                                    </Grid>
                                  )}
                                  {values.incident_light_type === "GB" && (
                                    <Grid item xs={4}>
                                      <div test-data={"focal_spot_y"}>
                                        <FormLabel
                                          style={{ fontSize: 12 }}
                                          disabled={disabled}
                                          htmlFor="focal_spot_y"
                                        >
                                          Focal Spot Y
                                        </FormLabel>
                                      </div>
                                      <SweepInput
                                        name="IncidentLightFocalSpotY"
                                        test-data="focal_spot_y"
                                        value={values.focal_spot_y}
                                        disabled={disabled && "DISABLE_INPUT"}
                                        onChange={value =>
                                          setFieldValue("focal_spot_y", value)
                                        }
                                        simulationId={this.props.simulationId}
                                        sweptVariables={
                                          this.props.sweptVariables
                                        }
                                        allowNegative
                                      />
                                    </Grid>
                                  )}
                                  {values.incident_light_type === "GB" && (
                                    <Grid item xs={4}>
                                      <div test-data={"focal_spot_z"}>
                                        <FormLabel
                                          style={{ fontSize: 12 }}
                                          disabled={disabled}
                                          htmlFor="focal_spot_z"
                                        >
                                          Focal Spot Z
                                        </FormLabel>
                                      </div>
                                      <SweepInput
                                        name="IncidentLightFocalSpotZ"
                                        test-data="focal_spot_z"
                                        value={values.focal_spot_z}
                                        disabled={disabled && "DISABLE_INPUT"}
                                        onChange={value =>
                                          setFieldValue("focal_spot_z", value)
                                        }
                                        simulationId={this.props.simulationId}
                                        sweptVariables={
                                          this.props.sweptVariables
                                        }
                                        allowNegative
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </form>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <Typography variant="h6" component="h3">
                            {"Meta Cell Accuracy"}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <div>
                                <FormLabel
                                  style={{ fontSize: 12 }}
                                  disabled={disabled}
                                  htmlFor="Y"
                                >
                                  Accuracy X
                                </FormLabel>
                                <IconTooltip text={xTooltip} />
                              </div>
                              <SweepInput
                                test-data="xInput"
                                value={values.X}
                                onChange={value => setFieldValue("X", value)}
                                simulationId={-1}
                                sweptVariables={this.props.sweptVariables}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                <FormLabel
                                  style={{ fontSize: 12 }}
                                  disabled={disabled}
                                  htmlFor="Y"
                                >
                                  Accuracy Y
                                </FormLabel>
                                <IconTooltip text={yTooltip} />
                              </div>
                              <SweepInput
                                test-data="yInput"
                                value={values.Y}
                                onChange={value => setFieldValue("Y", value)}
                                simulationId={-1}
                                sweptVariables={this.props.sweptVariables}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Typography variant="h5" component="h3">
                                Outgoing conditions
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <div>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      htmlFor="Y"
                                    >
                                      Order X
                                    </FormLabel>
                                    <IconTooltip text={orderxTooltip} />
                                  </div>
                                  <NumberInput
                                    test-data="outgoingOrderX"
                                    name="order_x"
                                    value={values.order_x}
                                    onChange={event =>
                                      setFieldValue(
                                        "order_x",
                                        event.target.value
                                      )
                                    }
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <div>
                                    <FormLabel
                                      style={{ fontSize: 12 }}
                                      htmlFor="Y"
                                    >
                                      Order Y
                                    </FormLabel>
                                    <IconTooltip text={orderyTooltip} />
                                  </div>
                                  <NumberInput
                                    test-data="outgoingOrderY"
                                    name="order_y"
                                    value={values.order_y}
                                    onChange={event =>
                                      setFieldValue(
                                        "order_y",
                                        event.target.value
                                      )
                                    }
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Direction
                                    disabled={disabled}
                                    value={values.direction_out}
                                    name={"direction_out"}
                                    onChange={handleChange}
                                    style={{ width: "100%" }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <PolarizationOut
                                    disabled={disabled}
                                    style={{ width: "100%" }}
                                    value={values.polarization_out}
                                    name={"polarization_out"}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(ODAForm));
