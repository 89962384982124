import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DrilldownInput from "components/DrilldownInput";
import { FormLabel } from "@material-ui/core";
import SimulationSelector from "MetaCell/selectors/Simulation";
import SimulationAction from "MetaCell/actions/Simulation";
import HelperUtils from "MetaCell/helper/HelperUtils";

export const styles = {
  drillDown: {
    marginBottom: 20,
    textAlign: "left"
  }
};

export class ModeAnalysisJobDropdown extends PureComponent {
  getJobsList = () => {
    return this.props.jobs.map(job => {
      const label = HelperUtils.getJobLabel(job);
      return {
        id: job.id,
        text: label,
        isSelected: job.id === this.props.selectedJobId
      };
    });
  };

  /**
   * it sets the selected job id in the state
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onJobSelect = selectedOptionIndex => {
    const { selectJob } = this.props;
    const selectedJobId = parseInt(this.props.jobs[selectedOptionIndex].id, 10);
    selectJob(selectedJobId);
  };

  render() {
    const { classes, selectedJobId, jobs } = this.props;
    return (
      <div className={classes.drillDown}>
        <FormLabel>Mode Analysis Job</FormLabel>
        <DrilldownInput
          name="AnalysisJob"
          marginTop={10}
          onClose={(value, data) => {}}
          options={[this.getJobsList()]}
          showCount={false}
          value={
            selectedJobId
              ? HelperUtils.getJobLabel(
                  jobs.filter(job => job.id === selectedJobId)[0]
                )
              : ""
          }
          onSelect={this.onJobSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    selectJob: jobId => dispatch(SimulationAction.selectModeAnalysisJob(jobId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ModeAnalysisJobDropdown));
