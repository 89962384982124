import React, { useState } from "react";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import GenericListItem from "components/GenericListItem/GenericListItem";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import { Input, Button } from "@material-ui/core";
import * as Yup from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Axios from "axios";
import GenericApi from "Api";

const requiredFieldText = "This field is required";

const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required(requiredFieldText),
  password: Yup.string()
    .required(requiredFieldText)
    .min(8, "Must have at least 8 characters")
    .max(100, "Must have at most 100 characters"),
  confirmationPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required(requiredFieldText)
});

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 24,
    marginLeft: 5
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0 16px 5px"
  },
  form: {
    padding: "0 16px 32px"
  },
  field: {
    marginTop: "5px",
    width: "100%"
  },
  validationMessage: {
    height: "25px",
    padding: "3px 0"
  }
}));

/**
 * a component created to be one of the contents of the AccountDialog. See {@link AccountDialogContent}.
 * It allows the management of account information details.
 * @author Akira Kotsugai
 */

export default function AccountForm({ account, onSaveEditing }) {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(
    false
  );
  const [accountFormErrors, setAccountFormErrors] = useState({
    username: false,
    email: false
  });

  const enableEditPassword = () => {
    setEditPassword(true);
  };

  const disableEditPassword = () => {
    setEditPassword(false);
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmationPasswordVisibility = () => {
    setShowConfirmationPassword(!showConfirmationPassword);
  };

  const onSubmit = ({ password, currentPassword }, { setErrors }) => {
    setSubmitDisabled(true);
    setError(false);
    setErrorMsg("");
    Axios.post(`${GenericApi.getBaseUrl()}/user/password`, {
      password,
      old_password: currentPassword
    })
      .then(() => {
        disableEditPassword();
      })
      .catch(({ response: { data } }) => {
        if (data === "Invalid password") {
          setErrors({ currentPassword: "Invalid password" });
        } else {
          setError(true);
          setErrorMsg(data.detail || "Something went wrong");
        }
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  return (
    <div>
      {editPassword && (
        <div>
          <div className={classes.header}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={disableEditPassword}
            >
              <ArrowBack />
            </IconButton>
            <Typography className={classes.title}>Change password</Typography>
          </div>

          <div className={classes.form}>
            <Formik
              initialValues={{
                currentPassword: "",
                password: "",
                confirmationPassword: ""
              }}
              validationSchema={changePasswordSchema}
              onSubmit={onSubmit}
            >
              {({ errors, values, handleChange, handleBlur, touched }) => (
                <Form>
                  <Input
                    className={classes.field}
                    type={showCurrentPassword ? "text" : "password"}
                    name="currentPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currentPassword}
                    placeholder="Current Password *"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleCurrentPasswordVisibility}
                        >
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className={classes.validationMessage}>
                    {touched.currentPassword && errors.currentPassword}
                  </div>

                  <Input
                    className={classes.field}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Password *"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className={classes.validationMessage}>
                    {touched.password && errors.password}
                  </div>

                  <Input
                    className={classes.field}
                    type={showConfirmationPassword ? "text" : "password"}
                    name="confirmationPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmationPassword}
                    placeholder="Confirmation Password *"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfirmationPasswordVisibility}
                        >
                          {showConfirmationPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <div className={classes.validationMessage}>
                    {touched.confirmationPassword &&
                      errors.confirmationPassword}
                  </div>

                  <Button
                    className={classes.field}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    Submit
                  </Button>

                  {error && (
                    <Typography style={{ textAlign: "center", marginTop: 10 }}>
                      {errorMsg}
                    </Typography>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {!editPassword && (
        <List>
          <GenericListItem
            test-data="username"
            field="username"
            label="Username"
            value={account.username}
            onEdit={() => {}}
            onSave={onSaveEditing}
            error={accountFormErrors.username}
            setFormErrors={fieldErrors => {
              setAccountFormErrors({ ...accountFormErrors, ...fieldErrors });
            }}
            rules={[
              value =>
                !/^[A-Za-z0-9]+$/.test(value) &&
                "Only letters and numbers are allowed in username"
            ]}
          />
          <Divider />
          <GenericListItem
            test-data="password"
            label="Password"
            value="********"
            onEdit={enableEditPassword}
            type="password"
          />
          <Divider />
          <GenericListItem
            test-data="email"
            field="email"
            label="Email"
            value={account.email}
            onEdit={() => {}}
            onSave={onSaveEditing}
            error={accountFormErrors.email}
          />
          <div test-data="license" style={{ display: "none" }}>
            <Divider />
            <GenericListItem
              test-data="cpuPower"
              Icon={OfflineBoltIcon}
              label="CPU Power"
              value="4 cores 3.0ghz 8mb cache"
            />
            <GenericListItem
              test-data="licenseType"
              Icon={CardMembershipIcon}
              label="License type"
              value="Bronze"
            />
            <GenericListItem
              test-data="expirationDate"
              Icon={TimelapseIcon}
              label="Expiration Date"
              value="24/10/2020"
            />
            <Divider />
            <ListItem test-data="renewLicense" button>
              <ListItemText primary="Renew license" align="center" />
            </ListItem>
          </div>
        </List>
      )}
    </div>
  );
}
