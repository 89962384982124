import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";
import BlurOn from "@material-ui/icons/BlurOn";
import BlurCircularIcon from "@material-ui/icons/BlurCircular";
import Adjust from "@material-ui/icons/Adjust";
import Divider from "@material-ui/core/Divider";
import { libraryPaths } from "Library/Library";
import SideMenuItem from "components/SideMenuItem/SideMenuItem";
import FilterTiltShift from "@material-ui/icons/FilterTiltShift";
import { Feature } from "flagged";
import { PaidFeatures } from "App";

/**
 * the content of library's control menu. Clicks on the menu will take the user to different pages.
 * See {@link LibraryPath}.
 * @author Akira Kotsugai
 */
export default class LibraryMenu extends PureComponent {
  render() {
    const { activePage } = this.props;
    return (
      <div test-data="libraryMenu">
        <div style={{ height: 90 }} />
        <Divider />
        <SideMenuItem
          test-data="materialsItem"
          activePath={activePage}
          path={libraryPaths.MATERIALS}
        >
          <BlurOn className="IconColor" />
          <ListItemText primary="Materials" />
        </SideMenuItem>
        <Divider />
        <Feature name={PaidFeatures.META_COMPONENT}>
          <SideMenuItem
            test-data="familiesItem"
            activePath={activePage}
            path={libraryPaths.FAMILIES}
          >
            <BlurCircularIcon className="IconColor" />
            <ListItemText primary="Meta cell groups" />
          </SideMenuItem>
          <Divider />
          <SideMenuItem
            test-data="designTargetsItem"
            activePath={activePage}
            path={libraryPaths.DESIGNTARGETS}
          >
            <FilterTiltShift className="IconColor" />
            <ListItemText primary="Design Targets" />
          </SideMenuItem>
          <Divider />
          <SideMenuItem
            test-data="ffwftargetsItem"
            activePath={activePage}
            path={libraryPaths.FFWFTARGETS}
          >
            <Adjust className="IconColor" />
            <ListItemText primary="Far-field targets" />
          </SideMenuItem>
          <Divider />
        </Feature>
      </div>
    );
  }
}

LibraryMenu.propTypes = {
  activePage: PropTypes.string.isRequired
};
