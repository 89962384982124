/**
 * a helper class created to be used in reducers. It does not need to be tested. it is already tested
 * inside unit tests of reducers.
 * @author Akira Kotsugai
 */
export default class ReducerUtils {
  /**
   * It adds or updates entities in the given state
   * @param {Object[]} entities - new entities
   * @param {Object} target - the target state
   * @param {String[]} fieldsToNormalize - fields that will replace the object by the id only
   */
  static addOrUpdateEntities = (entities, target, fieldsToNormalize = []) => {
    for (var entity of entities) {
      if (Array.isArray(entity)) {
        const normalizedEntities = entity.map(entity =>
          this.normalizeRelationships(entity, fieldsToNormalize)
        );
        target.byId[normalizedEntities[0].id] = normalizedEntities;
        continue;
      }
      const normalizedEntity = this.normalizeRelationships(
        entity,
        fieldsToNormalize
      );
      target.byId[entity.id] = {
        ...target.byId[entity.id],
        ...normalizedEntity
      };
    }
    target.allIds = Object.keys(target.byId);
    target.loaded = true;
  };

  /**
   * it changes potential nested objects into references if there are any.
   * @param {Object} entity - the given entity
   * @param {String[]} fieldsToNormalize - fields that will replace the object by the id only
   * @returns {Object} - the new entity with objects turned into references
   */
  static normalizeRelationships = (entity, fieldsToNormalize) => {
    const normalizedEntity = { ...entity };
    Object.keys(entity).forEach(property => {
      if (ReducerUtils.isAnDenormalizedSet(entity, property)) {
        normalizedEntity[property] = ReducerUtils.turnSetIntoReferences(
          entity[property]
        );
      } else if (fieldsToNormalize.includes(property)) {
        normalizedEntity[property] = entity[property].id;
      }
    });
    return normalizedEntity;
  };

  /**
   * it checks if the an entity's property is a denormalized set
   * @param {Object} entity - the entity that has the property
   * @param {String} property - the property name
   * @returns whether it is a denormalized set
   */
  static isAnDenormalizedSet = (entity, property) => {
    if (property.endsWith("_set")) {
      for (var element of entity[property])
        if (element instanceof Object) return true;
    }
    return false;
  };

  /**
   * it receives a data set and return only a list of ids
   * @param {Object[]} - the array of objects
   * @param {Number[]} - the array of ids
   */
  static turnSetIntoReferences = dataSet => {
    return dataSet.map(element => element.id);
  };

  /**
   * It deletes entities by ids from the given state
   * @param {Number[]} ids - the entities' ids
   * @param {Object} target - the target state
   */
  static deleteEntities = (ids, target) => {
    for (var id of ids) {
      delete target.byId[id];
    }
    target.allIds = target.allIds.filter(id => !ids.includes(parseInt(id)));
  };

  /**
   * It deletes entities by ids from the given state
   * @param {Number[]} ids - the entities' ids
   * @param {Object} target - the target state
   */
  static updateEntities = (ids, target) => {
    for (var id of ids) {
      delete target.byId[id];
    }
    target.allIds = target.allIds.filter(id => !ids.includes(parseInt(id)));
  };
}
