import React, { PureComponent } from "react";
import Plot from "react-plotly.js";
import CustomizePlotRangeDialog from "./CustomizePlotRangeDialog";
/**
 * generic plotly plot component
 * it is decoupled this much because it is not testable. it fails to import the Plot with jest
 * so avoid to add logic to this component
 * @author Akira Kotsugai
 */
export default class GenericPlot extends PureComponent {
  state = {
    openCustomizeDialog: false,
    wrapped: false
  };

  handleClickOpen = () => {
    this.setState({ openCustomizeDialog: true });
  };

  handleClose = () => {
    this.setState({ openCustomizeDialog: false });
  };

  handleWrapToggle = () => {
    const { wrapped } = this.state;
    this.props.onGetUnwrappedOutputData(!wrapped);
    this.setState({ wrapped: !wrapped });
  };

  getZDataRange = () => {
    let type = this.props.data[0].type;
    return type === "heatmap" || type === "surface" || type === "scatter"
      ? [this.props.data[0].zmin, this.props.data[0].zmax]
      : null;
  };

  render() {
    const customizeRangeIcon = {
        width: 80,
        height: 80,
        path:
          "M78,22V79H21V22H78m9-9H12V88H87V13ZM68,46.22H31V54H68ZM53,32H45.22V69H53Z",
        transform: "matrix(1 0 0 1 -10 -10)"
      },
      unwrapIcon = {
        width: 32,
        height: 32,
        path:
          "M31.875 4.063l-10.938 10.938-7-6.938-9.938 9.938v6l9.938-10 7 7 10.938-11zM4 27.938v-27.875h-4v31.875h32v-4z"
      };

    return (
      <div>
        <Plot
          data={this.props.data}
          layout={this.props.layout}
          onClick={this.props.onClick}
          config={{
            toImageButtonOptions: {
              format: "png",
              height: 500,
              width: 500
            },
            modeBarButtonsToAdd: [
              {
                name: "Customize Range",
                icon: customizeRangeIcon,
                click: this.handleClickOpen
              },
              ...(this.props?.morePlotlyModeBarButtonsToAdd
                ? this.props.morePlotlyModeBarButtonsToAdd
                : []),
              ...(this.props.onGetUnwrappedOutputData
                ? [
                    {
                      name: "Unwrap",
                      icon: unwrapIcon,
                      click: this.handleWrapToggle,
                      attr: false,
                      val: "off",
                      toggle: true
                    }
                  ]
                : [])
            ]
          }}
          onLegendClick={() => {
            return false;
          }}
          onLegendDoubleClick={() => {
            return false;
          }}
        />
        <CustomizePlotRangeDialog
          zDataRange={this.getZDataRange()}
          handleCustomRange={this.props.handleCustomRange}
          open={this.state.openCustomizeDialog}
          layout={this.props.layout}
          handleClose={this.handleClose}
        ></CustomizePlotRangeDialog>
      </div>
    );
  }
}
