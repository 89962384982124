import Axios from "axios";
import GlobalParametersAction from "MetaCell/actions/GlobalParameters";
import GenericApi from "Api";

/**
 * A class created to perform http operations related to the global parameters entity.
 * @author Akira Kotsugai
 * TODO: extract the similarities it has with SimulationSettingsApi and IncidentLightApi
 */
export default class MetaCellGlobalParametersApi {
  /**
   * it generates a url to fetch global parameters by a given simulation
   */
  static getBySimulationUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/globalparameters/?simulation=${simulationId}`;

  /**
   * it generates the url to the meta cell global parameters' api to manipulate
   * the given global parameters.
   *@param id - the global parameters' id
   */
  static getUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/globalparameters/${id}`;

  /**
   * it can fetch the simulation's global parameters. If it is a brand new simulation
   * it will create the record in the database.
   * @param {Number} simulationId - the open simulation's id
   * @return a function that receive a dispatcher to redux
   */
  static fetch = simulationId => {
    return dispatch => {
      const bySimulationUrl = MetaCellGlobalParametersApi.getBySimulationUrl(
        simulationId
      );
      return Axios.get(bySimulationUrl)
        .then(res => res.data)
        .then(metaCellGlobalParameters => {
          if (metaCellGlobalParameters.length > 0) {
            dispatch(GlobalParametersAction.set(metaCellGlobalParameters));
          } else {
            const url = MetaCellGlobalParametersApi.getUrl();
            const globalParameters = {
              id: null,
              cellWidth: "500",
              cellHeight: "500",
              unit: "nm",
              simulation: simulationId
            };
            return Axios.post(url, globalParameters)
              .then(res => res.data)
              .then(newGlobalParameters =>
                dispatch(GlobalParametersAction.set([newGlobalParameters]))
              );
          }
        })
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };

  /**
   * it can save existing meta cell global parameters.
   * @param {Object} metaCellGlobalParameters - the global parameters object
   * @return {Function} a function that is dispatchable to redux
   */
  static put = metaCellGlobalParameters => {
    return dispatch => {
      const url = MetaCellGlobalParametersApi.getUrl(
        metaCellGlobalParameters.id
      );
      return Axios.put(url, metaCellGlobalParameters)
        .then(({ data }) => {
          dispatch(GlobalParametersAction.set([data]));
        })
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };

  static patch = (id, obj) => {
    return dispatch => {
      const url = MetaCellGlobalParametersApi.getUrl(id);
      return Axios.patch(url, obj)
        .then(({ data }) => {
          dispatch(GlobalParametersAction.changeEditingField(data));
          dispatch(GlobalParametersAction.update(data));
        })
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };
}
