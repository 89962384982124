import Axios from "axios";
import GenericApi from "Api";
import OdaAction from "MetaComponent/actions/ODA";
import { decode } from "@msgpack/msgpack";

export default class ODAApi {
  static getODAJobsUrl = (jobId = "") =>
    `${GenericApi.getBaseUrl()}/odajobs/${jobId}`;

  static getOdaJobProgressUrl = odaJobId =>
    `${this.getODAJobsUrl(odaJobId)}/progress/`;

  static exportOdaUrl = id => `${this.getODAJobsUrl(id)}/data`;

  static getOdaJobResultUrl = (odaJobId, variableValues) => {
    const resultsUrl = `${this.getODAJobsUrl(odaJobId)}/results/`;
    const args = Object.keys(variableValues).map(
      key => `${key}=${variableValues[key]}`
    );
    if (!args.length) {
      return resultsUrl;
    }
    return `${resultsUrl}?${args.join("&")}`;
  };

  static start = odaJob => {
    return dispatch => {
      return GenericApi.runApiCall(
        Axios.post(ODAApi.getODAJobsUrl(), odaJob),
        "Failed to start ODA job"
      )
        .then(response => response.data)
        .then(odaJob => {
          dispatch(OdaAction.upsertJobs([odaJob]));
          return odaJob.id;
        });
    };
  };

  static restart = (odaJobId, properties) => {
    return dispatch => {
      return GenericApi.runApiCall(
        Axios.put(ODAApi.getODAJobsUrl(odaJobId) + "/", properties),
        "Failed to update ODA job"
      )
        .then(response => response.data)
        .then(odaJob => dispatch(OdaAction.upsertJobs([odaJob])));
    };
  };

  static getFinishedODAJobsByDesignId = designJobId => {
    const url =
      this.getODAJobsUrl() +
      `?design_job=${designJobId}&status=DONE&simple=true`;
    return GenericApi.runApiCall(
      Axios({
        url: url,
        method: "GET"
      }).then(res => res.data),
      "Failed to retrieve design ODA Jobs"
    );
  };

  static getODAJobDetails = odaJobId => {
    const url = this.getODAJobsUrl(odaJobId);
    return GenericApi.runApiCall(
      Axios({
        url: url,
        method: "GET"
      }).then(res => res.data),
      "Failed to retrieve calculated ODA Job details"
    );
  };

  static fetchOdaJobs = (jobId = "") => {
    return dispatch => {
      const url = ODAApi.getODAJobsUrl(jobId);
      return Axios.get(url)
        .then(response => response.data)
        .then(odaJobs => {
          dispatch(OdaAction.set(odaJobs));
        });
    };
  };

  static getOdaJobResult = (odaJobId, variableValues = {}) => {
    const url = ODAApi.getOdaJobResultUrl(odaJobId, variableValues),
      config = {
        responseType: "blob"
      };
    return Axios.get(url, config)
      .then(res => res.data.arrayBuffer())
      .then(arrayBuffer => decode(arrayBuffer))
      .then(wavefront => {
        return wavefront;
      });
  };

  static getSimpleOdaJobProgress = odaJobId => {
    const url = ODAApi.getOdaJobProgressUrl(odaJobId);
    return GenericApi.runApiCall(Axios.get(url), "Oda Job does not progress.");
  };

  static getOdaJobProgress = odaJobId => dispatch => {
    const url = ODAApi.getOdaJobProgressUrl(odaJobId);
    return GenericApi.runApiCall(Axios.get(url), "ODA Job does not progress.")
      .then(res => res.data)
      .then(jobProgress => {
        dispatch(OdaAction.upsertJobs([jobProgress]));
        return jobProgress;
      });
  };

  static exportOdaJob = id =>
    GenericApi.runApiCall(
      Axios({
        url: this.exportOdaUrl(id),
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream"
        }
      }).then(res => res.data),
      "Failed to export oda zip file."
    );

  static stopOdaJob = jobId => {
    const url = `${this.getODAJobsUrl(jobId)}/stop`;
    return Axios.post(url);
  };

  static deleteOdaJobs = jobIds => dispatch => {
    const url = this.getODAJobsUrl("");
    return Axios.delete(url, { data: jobIds }).then(() =>
      dispatch(OdaAction.deleteJobs(jobIds))
    );
  };
}
