import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import MetaCell, { metaCellPaths } from "MetaCell/MetaCell";
import MetaComponent, { MetaComponentPaths } from "MetaComponent/MetaComponent";
import Library from "Library/Library";
import CleanSeleniumLeftovers from "./CleanSeleniumLeftovers";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import RegisterComplete from "./Auth/RegisterComplete";
import { connect } from "react-redux";
import { retrieveTokensData } from "./Auth/selectors";
import { retrieveTokens, setupAuthInterceptor } from "./Auth/actions";
import PrivateRoute from "./PrivateRoute";
import ResetPasswordRequest from "./Auth/ResetPasswordRequest";
import ResetPasswordRequestComplete from "./Auth/ResetPasswordRequestComplete";
import ResetPassword from "./Auth/ResetPassword";
import ResetPasswordComplete from "./Auth/ResetPasswordComplete";
import MainJobOverview from "./JobOverview/MainJobOverview";
import WikiHome from "./Wiki/WikiHome";
import UserApi from "BaseApp/api/User";
import UserSelector from "BaseApp/selectors/User";
import { createPool, destroyPool } from "workerPool";
import { Helmet } from "react-helmet";
import { FlagsProvider } from "flagged";
import { removeTokens } from "Auth/actions";
import MaintenancePage from "MaintenancePage/MaintenancePage";
import Axios from "axios";
import GenericApi from "Api";

export const PaidFeatures = Object.freeze({
  META_COMPONENT: "meta_component"
});

export class App extends Component {
  constructor(props) {
    super(props);
    createPool();
    this.state = {
      isApiDown: false
    };
  }

  componentDidMount = () => {
    const {
      retrieveTokensAction,
      setupAuthInterceptorAction,
      removeTokensAction,
      fetchVersion
    } = this.props;
    if (window.location.pathname === "/login") {
      removeTokensAction();
    }
    setupAuthInterceptorAction();
    retrieveTokensAction();
    fetchVersion();
    this.checkApiStatus();
  };

  componentDidUpdate(prevProps) {
    const { access: oldAccessToken } = prevProps.tokens || {},
      { access: newAccessToken } = this.props.tokens || {};
    if (newAccessToken !== oldAccessToken) {
      this.props.fetchUserSetttings();
      this.props.fetchVersion();
    }
  }

  componentWillUnmount() {
    destroyPool();
  }

  checkApiStatus = async () => {
    try {
      const response = await Axios.get(`${GenericApi.getBaseUrl()}/api_health`);
      if (response.statusText === "OK" || response.data === "OK") {
        this.setState({ isApiDown: false });
      } else {
        this.setState({ isApiDown: true });
      }
    } catch (error) {
      console.error("Failed to check API status:", error);
      this.setState({ isApiDown: true });
    }
  };

  render = () => {
    const { tokens, userSettings } = this.props;
    const { isApiDown } = this.state;

    if (isApiDown) {
      return <MaintenancePage />;
    }

    return (
      <FlagsProvider
        features={{
          [PaidFeatures.META_COMPONENT]:
            userSettings &&
            userSettings.viewPermissions &&
            userSettings.viewPermissions.includes(PaidFeatures.META_COMPONENT)
        }}
      >
        <Helmet>
          <title>PlanOpSim</title>
          <link
            rel="icon"
            type="image/png"
            href={`${process.env.PUBLIC_URL}/static/favicon.ico`}
            sizes="16x16"
          />
        </Helmet>
        <Router test-data="app">
          {tokens && tokens.access && userSettings && (
            <Switch>
              <PrivateRoute
                path={"/clean-selenium-leftovers"}
                component={CleanSeleniumLeftovers}
              />
              <PrivateRoute path={metaCellPaths.ROOT} component={MetaCell} />
              <PrivateRoute
                path={MetaComponentPaths.ROOT}
                component={MetaComponent}
              />
              <PrivateRoute path="/library" component={Library} />
              <PrivateRoute path="/job-overview" component={MainJobOverview} />
              <PrivateRoute path="/wiki/:id" component={WikiHome} />
              <PrivateRoute path="/wiki" component={WikiHome} />
              {userSettings.lastView && <Redirect to={userSettings.lastView} />}
              <PrivateRoute exact path="/" component={MetaCell} />
              <Redirect to="/" />
            </Switch>
          )}
          {tokens && !tokens.access && (
            <Switch>
              <Route path="/register-complete" component={RegisterComplete} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route
                path="/reset-password-request"
                component={ResetPasswordRequest}
              />
              <Route
                path="/reset-password-request-complete"
                component={ResetPasswordRequestComplete}
              />
              <Route path="/reset-password" component={ResetPassword} />
              <Route
                path="/reset-password-complete"
                component={ResetPasswordComplete}
              />
              <Redirect to="/login" />
            </Switch>
          )}
        </Router>
      </FlagsProvider>
    );
  };
}

const mapStateToProps = state => ({
  tokens: retrieveTokensData(state),
  userSettings: UserSelector.getUserSettings(state)
});

const mapDispatchToProps = dispatch => {
  return {
    removeTokensAction: () => dispatch(removeTokens()),
    retrieveTokensAction: () => dispatch(retrieveTokens()),
    setupAuthInterceptorAction: () => dispatch(setupAuthInterceptor()),
    fetchUserSetttings: () => dispatch(UserApi.getUserSettings()),
    fetchVersion: () => dispatch(UserApi.getVersion())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
