import React, { PureComponent } from "react";
import { connect } from "react-redux";
import ProbeForm from "./Components/ProbeForm/ProbesForm";
import ProbesTable from "./Components/ProbesTable/ProbesTable";
import Selector from "MetaCell/selectors/SimulationSettings";
import SimulationSettingsAction from "MetaCell/actions/SimulationSettings";
import SimulationSettingsApi from "MetaCell/api/SimulationSettings";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";

export const fieldTypeOptions = [
  { label: "Ex" },
  { label: "Ey" },
  { label: "Ez" },
  { label: "normE" },
  { label: "Int" },
  { label: "Hx" },
  { label: "Hy" },
  { label: "Hz" },
  { label: "normH" }
];

class Probes extends PureComponent {
  handleAddNewProbe = newProbe => {
    const { simulationId } = this.props;
    newProbe.simulation = simulationId;
    this.props.addProbeAction(newProbe);
  };

  handleSelectProbe = probeId => {
    const { fieldProbeBeingEditedId } = this.props;
    if (probeId !== fieldProbeBeingEditedId) {
      this.checkProbeChanges();
    }
    this.props.selectProbeAction(probeId);
  };

  handleEditRow = probeId => {
    const { fieldProbeBeingEditedId, editProbeAction } = this.props;
    this.checkProbeChanges();
    if (probeId === fieldProbeBeingEditedId) {
      editProbeAction(-1);
    } else {
      editProbeAction(probeId);
    }
  };

  checkProbeChanges = () => {
    const {
      fieldProbeBeingEditedId,
      probeFields,
      updateProbeAction
    } = this.props;
    if (fieldProbeBeingEditedId !== -1) {
      const probe = probeFields.find(
        ({ id }) => id === fieldProbeBeingEditedId
      );
      if (probe) {
        let { id, ...data } = probe;
        updateProbeAction(id, data);
      }
    }
  };

  handleDeleteProbe = () => {
    const { selectedFieldProbeId, deleteProbeAction } = this.props;
    deleteProbeAction(selectedFieldProbeId);
  };

  handleCopyProbe = () => {
    const { addProbeAction, selectedFieldProbeId, probeFields } = this.props,
      probe = probeFields.find(({ id }) => id === selectedFieldProbeId);
    if (probe) {
      addProbeAction(probe);
    }
  };

  handleFieldChange = (probeId, data) => {
    this.props.changeFieldAction(probeId, data);
  };

  render() {
    const {
      probeFields,
      selectedFieldProbeId,
      fieldProbeBeingEditedId,
      simulationId
    } = this.props,
      probeFieldsFiltered = probeFields.filter(
        ({ simulation }) => simulation === simulationId
      );
    return (
      <div style={{ display: 'none' }}>
        <ProbesTable
          probeFields={probeFieldsFiltered}
          selectedFieldProbeId={selectedFieldProbeId}
          fieldProbeBeingEditedId={fieldProbeBeingEditedId}
          onSelectProbe={this.handleSelectProbe}
          onEditProbe={this.handleEditRow}
          onDeleteProbe={this.handleDeleteProbe}
          onCopyProbe={this.handleCopyProbe}
          onFieldChange={this.handleFieldChange}
        />
        <ProbeForm onSubmit={this.handleAddNewProbe} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    probeFields: Selector.getFieldProbes(state),
    selectedFieldProbeId: Selector.getSelectedFieldProbeId(state),
    fieldProbeBeingEditedId: Selector.getFieldProbeBeingEditedId(state),
    simulationId: DirectoryExplorerSelector.getSimulationOpenId(state)
  };
};

const mapdispatchToProps = dispatch => {
  return {
    addProbeAction: probe => dispatch(SimulationSettingsApi.postProbe(probe)),
    deleteProbeAction: probeId =>
      dispatch(SimulationSettingsApi.deleteProbe(probeId)),
    copyProbeAction: probeId =>
      dispatch(SimulationSettingsAction.copyFieldProbe(probeId)),
    selectProbeAction: probeId =>
      dispatch(SimulationSettingsAction.selectFieldProbe(probeId)),
    editProbeAction: probeId =>
      dispatch(SimulationSettingsAction.editFieldProbe(probeId)),
    changeFieldAction: (probeId, data) =>
      dispatch(SimulationSettingsAction.changeFieldProbe(probeId, data)),
    updateProbeAction: (probeId, data) =>
      dispatch(SimulationSettingsApi.patchProbe(probeId, data))
  };
};

export default connect(
  mapStateToProps,
  mapdispatchToProps
)(Probes);
