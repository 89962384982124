import React, { PureComponent } from "react";
import Select from "@material-ui/core/Select";
import { FormHelperText, FormControl } from "@material-ui/core";

/**
 * a select component that does not block the rest of the screen and the main scroll bar
 * is still visible
 * @author Akira
 */
class UnselfishSelect extends PureComponent {
  render() {
    return (
      <FormControl error={this.props.error}>
        <Select
          {...this.props}
          MenuProps={{
            disableScrollLock: true
          }}
        >
          {this.props.children}
        </Select>
        {this.props.error && (
          <FormHelperText>{this.props.error}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default UnselfishSelect;
