/**
 * A selector class for the incident light state
 * @author Akira Kotsugai
 */
export default class IncidentLightSelector {
  /**
   * it selects the incident light from the redux state
   * @param {Object} state => the redux state
   * @return {Object} the incident light
   */
  static get = state => state.incidentLight.entities.incidentLight;

  /**
   * it selects the editing incident light from the redux state
   * @param {Object} state => the redux state
   * @return {Object} the editing incident light
   */
  static getEditing = state => state.incidentLight.ui.editingIncidentLight;

  static getPolarizationHistory = state =>
    state.incidentLight.ui.polarizationHistory;
}
