import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Grid, withStyles } from "@material-ui/core";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import Description from "components/Description/Description";
import ComponentMembers from "./components/ComponentMembers/ComponentMembers";
import ComponentDimensions from "./components/ComponentDimensions/ComponentDimensions";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import Spinner from "components/Spinner/Spinner";

const styles = {
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

/**
 * A component created to be the content for Meta Cell component's component.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 * @TODO Make all canvas have the loading icon without copy and pastes
 */
export class ComponentCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingDescription: null
    };
  }
  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(MetaComponentPaths.COMPONENT);
  }

  /**
   * it updates the editing description with the new value
   * @param {Object} newDescription - new description
   * @callback
   */
  updateEditingDescription = newDescription => {
    this.setState({
      editingDescription: newDescription
    });
  };

  /**
   * it changes the editing state with the open meta component description
   */
  editDescription = () => {
    const openMetaComponent = this.getOpenMetaComponent(),
      { description } = openMetaComponent;
    this.setState({
      editingDescription: description ? description : ""
    });
  };

  /**
   * it changes the editing state to null
   */
  cancelEditingDescription = () => {
    this.setState({
      editingDescription: null
    });
  };

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  /**
   * it saves the description and leaves the editing mode
   */
  save = async () => {
    const { updateMetaComponent, openMetaComponentId } = this.props;
    await updateMetaComponent(openMetaComponentId, {
      description: this.state.editingDescription
    });
    this.cancelEditingDescription();
  };

  render() {
    const { ready, classes } = this.props;
    if (!ready) {
      return (
        <div className={classes.progress}>
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }

    const openMetaComponent = this.getOpenMetaComponent(),
      { description } = openMetaComponent,
      { editingDescription } = this.state;

    return (
      <div test-data="componentCanvas">
        <Grid container justify="space-around">
          <Grid container direction="column" xs={5}>
            <Grid container xs>
              <ComponentDimensions />
            </Grid>
            <Grid container xs>
              <div style={{ width: "100%", marginLeft: 30, marginRight: 30 }}>
                <Description
                  subject={"Meta Component"}
                  description={description}
                  updateField={this.updateEditingDescription}
                  editingDescription={editingDescription}
                  handleEdit={this.editDescription}
                  handleSave={this.save}
                />
              </div>
            </Grid>
            <Grid container xs></Grid>
          </Grid>
          <Grid container direction="column" xs={7}>
            <ComponentMembers />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetaComponent: (id, newProperties) =>
      dispatch(DirectoryExplorerApi.updateMetaComponent(id, newProperties))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ComponentCanvas));
