import React, { PureComponent } from "react";
import MaterialTable from "components/MaterialTable/src/index";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { withStyles } from "@material-ui/core";
import FamilyApi from "MetaCell/api/Family";
import UserSelector from "BaseApp/selectors/User";
import "./FamilyMembers.css";
import Spinner from "components/Spinner/Spinner";
import { Button } from "@material-ui/core";
import { FamilySimpleFieldEditing } from "./FamilyEditComponents";
import FamilyHelper from "MetaCell/helper/Family";
import FamilyAction from "../../../../../../MetaCell/actions/Family";
import Helper from "MetaCell/helper/FamilyMember";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import debounce from "lodash.debounce";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box"
  },
  loadingIndicator: {
    margin: "0 auto",
    display: "block"
  },
  table: {
    backgroundColor: "#f5f5f5"
  },
  exampleButton: {
    textTransform: "initial"
  }
};

const cellStyle = {
  backgroundColor: "#f5f5f5"
};
const headerStyle = {
  backgroundColor: "#f5f5f5"
};

/**
 * fields that must be filled in before submission
 */
export const requiredFields = ["alias"];

export class FamilyMembers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      loading: true,
      invalidFields: [],
      progress: null
    };
  }

  /**
   * @callback
   * @param {Object} newData - the new family member data
   * @param {Object} oldData - the old family member data
   * @returns {Promise} a promise that calls the update member method
   */
  onRowUpdate = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      const missingRequiredFields = FamilyHelper.getMissingRequiredProperties(
        newData,
        requiredFields
      );

      if (missingRequiredFields.length === 0) {
        FamilyApi.updateMember(oldData.id, {
          alias: newData.alias,
          diff_summary: newData.diff_summary
        })
          .then(() => {
            const dataUpdate = [...this.state.members];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            this.setState({ members: [...dataUpdate] });
            resolve();
          })
          .catch(() => reject());
      } else {
        this.setState({ invalidFields: missingRequiredFields });
        reject();
      }
    });
  };

  /**
   * @callback
   * @param {Object} e - the click event
   * @param {Object[]} rowsData - the selected family members
   * @returns {Promise} a promise that calls the delete family member action
   */
  onRowDelete = (e, rowsData) => {
    const { deleteFamilyMemberAction, showConfirmDialog } = this.props;
    const familyMemberIds = rowsData.map(({ id }) => id);
    showConfirmDialog(
      "Delete",
      "Are you sure you want to delete the selected meta cells?",
      () => {
        deleteFamilyMemberAction(rowsData[0].family, familyMemberIds);
        const dataDelete = [
          ...this.state.members.filter(
            member => !familyMemberIds.includes(member.id)
          )
        ];
        this.setState({ members: [...dataDelete] });
      },
      undefined,
      false
    );
  };

  getCreateFamilyMembersJobStatusWithDelay = debounce(
    this.getCreateFamilyMemberJobStatus,
    5000
  );

  async getCreateFamilyMemberJobStatus(familyId, onFinish) {
    return FamilyApi.createFamilyMembersJobProgress(familyId, true)
      .then(resp => resp.data)
      .then(async data => {
        if (data.status === "QUEUED" || data.status === "RUNNING") {
          this.setState({
            progress: data.progress
          });
          return this.getCreateFamilyMembersJobStatusWithDelay(
            familyId,
            onFinish
          );
        } else if (data.status === "DONE" || data.status === "FAILED") {
          if (data.status === "DONE") {
            this.setState({
              progress: null
            });
            return await onFinish();
          } else {
            if (data.errors && data.errors["error"]) {
              this.setState({
                snackbar: {
                  visible: true,
                  message: data.errors["error"]
                }
              });
            }
          }
        }
      })
      .catch(async err => await onFinish());
  }

  fetchMembersAndSetReadyState = async () => {
    let members = await FamilyApi.fetchMembersByFamily(this.props.familyId);
    members = members.map((member, index) => {
      return {
        ...member,
        diff_summary: Helper.formatDiffSummary(member.diff_summary)
      };
    });
    members = await FamilyHelper.loadFamilyMemberLazyFields(members);
    this.setState({
      members,
      loading: false
    });
  };

  async componentDidMount() {
    await this.getCreateFamilyMemberJobStatus(
      this.props.familyId,
      this.fetchMembersAndSetReadyState
    );
  }

  /**
   * it returns a style for the * sign in required fields
   * by checking their validation status
   * @param {String} field - the field to be checked
   */
  getRequiredStyle = field => {
    return {
      color: this.isFieldInvalid(field) ? "red" : "black"
    };
  };

  /**
   * it checks whether the given field exists in the state for invalid fields
   * @param {String} field - the family field
   * @returns {Boolean} whether it is invalid
   */
  isFieldInvalid = field => {
    const { invalidFields } = this.state;
    return invalidFields.indexOf(field) !== -1;
  };

  render = () => {
    const { classes, openMemberWizard } = this.props;
    const { loading, members, progress } = this.state;
    const showDiscrepantThicknessWarning = !Helper.membersHaveSameThickness(
      members
    );
    return (
      <div
        className={`${classes.main} members-wrapper`}
        style={{
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "10px"
        }}
      >
        {loading && (
          <Spinner
            className={classes.loadingIndicator}
            name="Waiting"
            timeout={30000}
            progress={progress}
          />
        )}
        {!loading && (
          <MaterialTable
            title="Meta cells"
            columns={[
              {
                title: "Alias",
                field: "alias",
                headerStyle,
                editComponent: props => {
                  return (
                    <FamilySimpleFieldEditing
                      field={"alias"}
                      value={props.value || ""}
                      getRequiredStyle={this.getRequiredStyle}
                      isFieldInvalid={this.isFieldInvalid}
                      onChange={(field, value) => {
                        props.onChange(value);
                      }}
                    />
                  );
                }
              },
              {
                title: "Number of layers",
                field: "number_of_layers",
                headerStyle,
                editable: "never"
              },
              {
                title: "total thickness",
                field: "total_thickness",
                headerStyle,
                editable: "never"
              },
              {
                title: "Diff summary",
                field: "diff_summary",
                headerStyle
              },
              {
                title: "",
                field: "example",
                editable: "never",
                render: rowData => (
                  <Button
                    className={classes.exampleButton}
                    variant="contained"
                    disabled
                  >
                    Example
                  </Button>
                )
              }
            ]}
            data={members}
            // options={{
            //   addRowPosition: "first",
            //   search: false,
            //   pageSize: 5,
            //   pageSizeOptions: [5, 10, 15, 20, 25],
            //   actionsCellStyle: {
            //     backgroundColor: "#f5f5f5"
            //   },
            //   headerStyle: { backgroundColor: "#f5f5f5" }
            // }}
            options={{
              selection: true,
              search: false,
              paging: false,
              sorting: false,
              draggable: false,
              maxBodyHeight: 350,
              headerStyle: { backgroundColor: "#f5f5f5" },
              cellStyle,
              actionsColumnIndex: -1
            }}
            editable={{
              isEditable: rowsData => rowsData.length === 1,
              onRowUpdate: this.onRowUpdate
            }}
            actions={[
              {
                icon: "add",
                tooltip: "Add Meta cell",
                isFreeAction: true,
                onClick: event => {
                  openMemberWizard(this.props.familyId);
                }
              },
              {
                icon: DeleteIcon,
                tooltip: "Delete",
                onClick: this.onRowDelete
              }
            ]}
            localization={{
              pagination: {
                labelRowsSelect: "members"
              },
              header: {
                actions: ""
              }
            }}
          />
        )}
        {showDiscrepantThicknessWarning && (
          <DirectionSnackbar message="Meta cells of different thicknesses may lead to infeasible meta component designs" />
        )}
      </div>
    );
  };
}

const mapState = state => ({
  user: UserSelector.getUser(state)
});

const mapDispatch = dispatch => ({
  openMemberWizard: familyId =>
    dispatch(
      FamilyAction.setMemberWizardOpen({
        openStatus: true,
        familyId: familyId
      })
    ),
  deleteFamilyMemberAction: (familyId, familyMemberIds) =>
    dispatch(FamilyApi.deleteMembers(familyId, familyMemberIds)),
  showConfirmDialog: (
    title,
    message,
    confirmAction,
    cancelAction,
    isReduxAction
  ) =>
    dispatch(
      ConfirmDialogAction.show(
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction
      )
    )
});

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(FamilyMembers)));
