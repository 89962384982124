import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import IconButton from "@material-ui/core/IconButton";
import GenericTextInput from "components/GenericTextInput/GenericTextInput";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(1)
  },
  textField: {
    marginTop: 0
  },
  addButton: {
    padding: 0
  }
}));

/**
 * A component for Tree Item inclusions.
 * @author Akira Kotsugai
 * @param {Object} props - the object is expected to have a handleUpdate and onSubmit functions,
 * the selectedItem in the tree and the inputValue to show in the form field.
 * @return {Component} a form field and a button for submissions.
 */
const ExplorerForm = ({ handleUpdate, onSubmit, selectedItem, inputValue }) => {
  const classes = useStyles();

  /**
   * it submits the form if enter is pressed
   * @param {Object} event - the key down event
   */
  const onKeyDown = event => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <div test-data="explorerForm" className={classes.root}>
      <GenericTextInput
        classes={classes}
        name="TreeInputField"
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        label={selectedItem.name}
        value={inputValue}
        handleUpdateField={e => handleUpdate(e.target.value)}
        onKeyDown={onKeyDown}
      />
      <IconButton
        aria-label="add"
        name="TreeInputSubmitButton"
        className={classes.addButton}
        color="primary"
        onClick={onSubmit}
      >
        <AddBox />
      </IconButton>
    </div>
  );
};

export default ExplorerForm;
