/**
 * A selector class for the design target state
 */
export default class DesignTargetSelector {
  /**
   * it selects the design targets from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the design targets
   */
  static getDesignTargets = state => state.designTarget.entities.designTargets;
}
