/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
/* eslint-enable no-unused-vars */

class MTableAction extends React.Component {
  render() {
    let action = this.props.action;
    if (typeof action === "function") {
      action = action(this.props.data);
      if (!action) {
        return null;
      }
    }

    if (action.hidden) {
      return null;
    }

    const handleOnClick = event => {
      if (action.onClick) {
        action.onClick(event, this.props.data);
        event.stopPropagation();
      }
    };

    const disabled =
      typeof action.disabled === "function"
        ? action.disabled(this.props.data)
        : action.disabled;
    const button = (
      <IconButton
        name={this.props.action.name}
        size={this.props.size}
        color="inherit"
        disabled={disabled}
        onClick={event => handleOnClick(event)}
        style={{ padding: 3 }}
      >
        {typeof action.icon === "string" ? (
          <Icon {...action.iconProps}>{action.icon}</Icon>
        ) : (
          <action.icon {...action.iconProps} disabled={disabled} />
        )}
      </IconButton>
    );

    if (action.tooltip) {
      return <Tooltip title={action.tooltip}>{button}</Tooltip>;
    } else {
      return button;
    }
  }
}

MTableAction.defaultProps = {
  action: {},
  data: {}
};

MTableAction.propTypes = {
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  size: PropTypes.string
};

export default MTableAction;
