import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import AnalysisAction from "MetaComponent/actions/Analysis";
import AnalysisSelector from "MetaComponent/selectors/Analysis";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import { isEqual, cloneDeep } from "lodash";
import {
  makeParametersColumn,
  components,
  validateVariableData
} from "components/SweepOverview/SweepOverview";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  inputTooltip: {
    whiteSpace: "pre-line"
  }
};

const mapState = state => ({
  tempVariables: AnalysisSelector.getTempSweptVariables(state)
});

const mapDispatch = dispatch => ({
  updateTempVariablesAction: variables =>
    dispatch(AnalysisAction.updateTempVariables(variables))
});

const columns = (error, inputTooltipClassName) => [
  { title: "Alias", field: "name" },
  makeParametersColumn(error, inputTooltipClassName)
];

export class AnalysisVariables extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      error: {}
    };
  }

  componentDidMount() {
    const { sweptVariables } = this.props;
    if (sweptVariables) {
      this.setData(this.props.sweptVariables);
    }
  }

  componentDidUpdate(oldProps) {
    const { sweptVariables } = this.props;
    if (!isEqual(oldProps.sweptVariables, sweptVariables)) {
      this.setData(sweptVariables);
    }
  }

  setData = sweptVariables => {
    this.props.updateTempVariablesAction(
      sweptVariables.map(({ name, parameters, values, sweepType }, index) => ({
        id: index,
        parameters,
        name,
        values,
        sweepType
      }))
    );
  };

  getAliasList = (variableToIgnoreId = null) => {
    const { tempVariables } = this.props;
    return tempVariables
      .filter(variable => variable.id !== variableToIgnoreId)
      .map(variable => variable.name);
  };

  generateVariableValues(params) {
    const paramsArray = params.split(/,|;|:/);
    return HelperUtils.arange(
      parseFloat(paramsArray[0]),
      parseFloat(paramsArray[1]),
      parseFloat(paramsArray[2])
    );
  }

  onRowAdd = newData =>
    new Promise((resolve, reject) => {
      const { tempVariables, updateTempVariablesAction } = this.props;
      validateVariableData(
        { ...newData, types: "Linear" },
        this.getAliasList(),
        {}
      )
        .then(validatedData => {
          delete validatedData.types;
          const variableToInsert = {
            ...validatedData,
            id: tempVariables.length + 1,
            values: this.generateVariableValues(validatedData.parameters),
            sweepType: "Linear"
          };
          updateTempVariablesAction(tempVariables.concat(variableToInsert));
          this.setState({ error: {} });
          resolve();
        })
        .catch(err => {
          reject();
          this.setState({ error: { [err.path]: err.message } });
        });
    });

  onRowUpdate = newData =>
    new Promise((resolve, reject) => {
      validateVariableData(
        { ...newData, types: "Linear" },
        this.getAliasList(newData.id),
        {}
      )
        .then(validatedData => {
          const { tempVariables, updateTempVariablesAction } = this.props;
          let newVariablesState = cloneDeep(tempVariables);
          const index = tempVariables.findIndex(
              variable => variable.id === newData.id
            ),
            updatedVariable = {
              ...validatedData,
              values: this.generateVariableValues(validatedData.parameters)
            };
          newVariablesState[index] = updatedVariable;
          updateTempVariablesAction(newVariablesState);
          resolve();
        })
        .catch(err => {
          reject();
          this.setState({ error: { [err.path]: err.message } });
        });
    });

  onRowDelete = oldData =>
    new Promise((resolve, reject) => {
      try {
        const { tempVariables, updateTempVariablesAction } = this.props,
          { id } = oldData;
        let newVariablesState = cloneDeep(tempVariables);
        newVariablesState = newVariablesState.filter(
          variable => variable.id !== id
        );
        updateTempVariablesAction(newVariablesState);
        resolve();
      } catch {
        reject();
      }
    });

  render = () => {
    const { classes, tempVariables } = this.props;
    return (
      <div className={classes.main}>
        <EnhancedMaterialTable
          slim
          options={{
            search: false,
            paging: false,
            sorting: false,
            draggable: false,
            maxBodyHeight: 350
          }}
          title="Sweep Variables"
          columns={columns(this.state.error, classes.inputTooltip)}
          data={tempVariables}
          editable={{
            onRowAdd: this.onRowAdd,
            onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete
          }}
          components={components(this.state.error)}
        />
      </div>
    );
  };
}

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(AnalysisVariables)));
