/**
 * Retrieves the auth tokens data which is changed by the
 * following actions: RETRIEVE_TOKENS, REMOVE_TOKENS
 * @param {Object} state - redux state
 * @return object { access: string, refresh: string }
 */
export const retrieveTokensData = state => state.auth.tokens;

/**
 * Retrieves the sign in data which is changed by the following
 * actions: SIGN_IN_STARTED, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
 * RESET_SIGN_IN
 * @param {Object} state - redux state
 * @return object { loading: boolean, error: boolean, data: object }
 */
export const retrieveSignInData = state => state.auth.signIn;

/**
 * Retrieves the register data which is changed by the following
 * actions: REGISTER_STARTED, REGISTER_SUCCESS, REGISTER_FAILURE,
 * RESET_REGISTER
 * @param {Object} state - redux state
 * @return object { loading: boolean, error: boolean, data: object }
 */
export const retrieveRegisterData = state => state.auth.register;

/**
 * Retrieves the auth interceptor data which is changed by the following
 * action: AUTH_INTERCEPTOR_LOADED
 * @param {Object} state - redux state
 * @return object { loaded: boolean }
 */
export const retrieveAuthInterceptorData = state => state.auth.authInterceptor;
