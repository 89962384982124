import React, { PureComponent } from "react";
import {
  TextField,
  Paper,
  Typography,
  Grid,
  withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import EditActions from "components/EditActions/EditActions";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: theme.spacing(23),
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: "100%"
  },
  text: {
    width: "100%",
    height: theme.spacing(18),
    overflowY: "auto",
    wordWrap: "break-word"
  },
  title: {
    float: "left"
  }
});

/**
 * A class component created to show a description.
 * @author Akira Kotsugai
 */
export class Description extends PureComponent {
  /**
   * calls the callback that changes the editing state in redux to reset it
   */
  resetEditing = () => {
    this.props.updateField(null);
  };

  render() {
    const {
      classes,
      subject,
      description,
      editingDescription,
      updateField,
      handleEdit,
      handleSave
    } = this.props;
    return (
      <div test-data="simulationDescription">
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h5" component="h3">
                Description
              </Typography>
              <div style={{ float: "right" }}>
                <EditActions
                  isEditing={editingDescription !== null ? true : false}
                  onEdit={handleEdit}
                  onCancel={this.resetEditing}
                  onSave={handleSave}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              {editingDescription !== null ? (
                <TextField
                  value={editingDescription}
                  className={classes.textField}
                  placeholder={`${subject} Description`}
                  multiline={true}
                  rows={1}
                  rowsMax={6}
                  onChange={e => updateField(e.target.value)}
                />
              ) : (
                <div className={classes.text}>
                  <Typography
                    onDoubleClick={handleEdit}
                    test-data="description"
                  >
                    {description}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

Description.propTypes = {
  /**
   * who is being described
   */
  subject: PropTypes.string.isRequired,
  /**
   * the real description from the entity
   */
  description: PropTypes.string.isRequired,
  /**
   * the editing value
   */
  editingDescription: PropTypes.string.isRequired,
  /**
   * the callback function for when the editing field changes
   */
  updateField: PropTypes.func.isRequired,
  /**
   * the callback that should start editing
   */
  handleEdit: PropTypes.func.isRequired,
  /**
   * the callback that should save the editing state
   */
  handleSave: PropTypes.func.isRequired
};

export default withStyles(styles)(Description);
