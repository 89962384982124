import React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import GenericListItem from 'components/GenericListItem/GenericListItem';

/**
 * a component created to be one of the contents of the AccountDialog. See {@link AccountDialogContent}.
 * It allows the management of user information details.
 * @author Akira Kotsugai
 */
export default function UserForm({ profile, onSaveEditing }) {

    return (
        <List>
            <GenericListItem test-data="title" field="title" label="Title" value={profile.title} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="firstName" field="firstName" label="First name" value={profile.firstName} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="lastName" field="lastName" label="Last name" value={profile.lastName} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="phone" field="phone" label="Phone" value={profile.phone} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="company" field="company" label="Company" value={profile.company} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="jobTitle" field="jobTitle" label="Job title" value={profile.jobTitle} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="country" field="country" label="Country" value={profile.country} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="zipCode" field="zipCode" label="Zip code" value={profile.zipCode} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="city" field="city" label="City" value={profile.city} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="streetName" field="streetName" label="Street name" value={profile.streetName} onEdit={() => { }} onSave={onSaveEditing} />
            <Divider />
            <GenericListItem test-data="streetNumber" field="streetNumber" label="Street number" value={profile.streetNumber} onEdit={() => { }} onSave={onSaveEditing} />
        </List>
    );

}