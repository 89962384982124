import Axios from "axios";
import GenericApi from "Api";

/**
 * A class created to prepare data and perform http operations
 * related to a meta component.
 * @author Akira Kotsugai
 */
export default class MetaComponentApi {
  /**
   * it generates an url to export a meta component
   * @param {Number} metaComponentId - the meta component id
   */
  static getExportUrl = metaComponentId =>
    `${GenericApi.getBaseUrl()}/metacomponents/${metaComponentId}/export`;

  /**
   * it makes a request to the export meta component endpoint
   * @param {Number} metaComponentId - the meta component id
   * @returns {Promise} the response
   */
  static export = metaComponentId =>
    GenericApi.runApiCall(
      Axios({
        url: this.getExportUrl(metaComponentId),
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/x-zip-compressed"
        }
      }).then(resp => resp.data),
      "Failed to export meta component file."
    );
}
