import Axios from "axios";
import GenericApi from "Api";

export default class WikiApi {
  static wikiUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/wiki/articles/${id}`;

  static fetchArticles = async (id = "") => {
    const url = WikiApi.wikiUrl(id);
    const response = await Axios.get(url);
    return response.data;
  };
}
