import domtoimage from "dom-to-image";
import { clone } from "lodash";
import HelperUtils from "MetaCell/helper/HelperUtils";
import Papa from "papaparse";

/**
 * Type of pdf report
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} ResultReportType
 * @property {String} PLOTS - the report containing all plots a simulation's result has
 * @property {String} CONFIGURATION - the report containing the configuration a simulation's result has
 * @property {String} FULL - the report containing the configuration and the pltos a simulation's result has
 * @global
 */
export const ResultReportType = Object.freeze({
  PLOTS: "Plots",
  CONFIGURATION: "Configuration",
  FULL: "Full Report"
});

/**
 * Type of simulation
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} SimulationType
 * @property {String} SIMULATION - the simulation of type meta cell simulation
 * @property {String} MODE_ANALYSIS - the simulation of type mode analysis simulation
 * @global
 */
export const SimulationType = Object.freeze({
  SIMULATION: "Simulation",
  MODE_ANALYSIS: "Mode Analysis"
});

/**
 * A helper class to help components that are related to simulation results.
 * @author Akira Kotsugai
 */
export default class SimulationResultHelper {
  /**
   * it returns a promise that returns the image data.
   * it is supposed to be passed to the pdf document.
   */
  static getImageData = element => {
    return domtoimage.toPng(element, { quality: 1 });
  };

  /**
   * it takes the element size in pixels and resize it without distortions to the largest
   * possible size respecting the given size constraints.
   * @param {Number} width - the plot width
   * @param {Number} height - the plot height
   * @param {Number} maxWidth - the max width the plot should have
   * @param {Number} maxHeight - the max height the plot should have
   */
  static getOptimumFittableSize = (width, height, maxWidth, maxHeight) => {
    if (maxWidth > 210 || maxHeight > 297)
      throw new Error("provide valid mm size for A4 page.");
    let newWidth;
    let newHeight;
    if (width > maxWidth) {
      newWidth = maxWidth;
      newHeight = this.getProportionalHeightForWidth(maxWidth, width, height);
    } else {
      newWidth = width;
      newHeight = height;
    }

    if (newHeight > maxHeight) {
      newWidth = this.getProportionalWidthForHeight(
        maxHeight,
        newWidth,
        newHeight
      );
      newHeight = maxHeight;
    }
    return {
      width: newWidth,
      height: newHeight
    };
  };

  /**
   * given the new height and the current size
   * it calculates the proportional width for the new height.
   * @param {Number} height - the new height
   * @param {Number} currentWidth - the current width
   * @param {Number} currentHeight - the current height
   * @returns {Number} the proportional width
   */
  static getProportionalWidthForHeight = (
    height,
    currentWidth,
    currentHeight
  ) => (currentWidth * height) / currentHeight;

  /**
   * given the new width and the current size
   * it calculates the proportional height for the new width
   * @param {Number} width - the new width
   * @param {Number} currentWidth - the current width
   * @param {Number} currentHeight - the current height
   * @returns {Number} the proportional height
   */
  static getProportionalHeightForWidth = (width, currentWidth, currentHeight) =>
    (currentHeight * width) / currentWidth;

  /**
   * we calculate the left margin for the given image width in a way that the image
   * is horizontally centered in the given area size
   * @param {Number} imageWidth - the image width
   * @param {Number} areaForImageWidth - the width of where the image will be placed
   * @returns {Number} the left margin for the plot
   */
  static getPlotLeftMargin = (imageWidth, areaForImageWidth) => {
    return (areaForImageWidth - imageWidth) / 2;
  };

  /**
   * we calculate the top margin for the given image height in a way that the image
   * is vertically centered in the given area size
   * @param {Number} imageHeight - the image height
   * @param {Number} areaForImageHeight - the height of where the image will be placed
   * @returns {Number} the top margin for the plot
   */
  static getPlotTopMargin = (imageHeight, areaForImageHeight) => {
    return (areaForImageHeight - imageHeight) / 2;
  };

  /**
   * it creates the content of a pdf page with two plots (or one if only one was passed)
   * @param {Object} doc - the pdf document
   * @param {HTMLElement} elementOne - the expected plot one
   * @param {HTMLElement} elementOne - the expected plot two
   * @param {Number} startY - where the images should start from the top of the page
   */
  static fillUpPage = async (doc, elementOne, elementTwo, startY) => {
    const pageHeight = 297;
    const pageWidth = 210;
    const pageBottomMargin = 5;
    const maxHeightForEachImage = (pageHeight - startY - pageBottomMargin) / 2;
    const maxWidthForEachImage = 210;
    const sizeOne = this.getOptimumFittableSize(
      elementOne.offsetWidth,
      elementOne.offsetHeight,
      maxWidthForEachImage,
      maxHeightForEachImage
    );
    const imageDataOne = await this.getImageData(elementOne);
    const leftMarginOne = this.getPlotLeftMargin(sizeOne.width, pageWidth);
    const topMarginOne = this.getPlotTopMargin(
      sizeOne.height,
      maxHeightForEachImage
    );
    doc.addImage(
      imageDataOne,
      "PNG",
      leftMarginOne,
      startY + topMarginOne,
      sizeOne.width,
      sizeOne.height
    );

    if (elementTwo !== undefined) {
      const sizeTwo = this.getOptimumFittableSize(
        elementTwo.offsetWidth,
        elementTwo.offsetHeight,
        maxWidthForEachImage,
        maxHeightForEachImage
      );
      const imageDataTwo = await this.getImageData(elementTwo);
      const leftMarginTwo = this.getPlotLeftMargin(sizeTwo.width, pageWidth);
      const topMarginTwo = this.getPlotTopMargin(
        sizeTwo.height,
        maxHeightForEachImage
      );
      const secondImageStartY = startY + maxHeightForEachImage + topMarginTwo;
      doc.addImage(
        imageDataTwo,
        "PNG",
        leftMarginTwo,
        secondImageStartY,
        sizeTwo.width,
        sizeTwo.height
      );
    }
  };

  /**
   * it inserts simulations results plots into the given document
   * @param {Object} doc - the pdf document
   * @param {Number} startY - the distance from the top of the page where the header should start
   */
  static insertResultPlotsInDocument = async (doc, startY) => {
    const plotContainers = document.getElementsByClassName("plotContainer");
    const numberOfPages = Math.ceil(plotContainers.length / 2);
    for (var i = 0; i < plotContainers.length; i = i + 2) {
      const currentPage = i / 2 + 1;
      const elementOne = plotContainers[i];
      const elementTwo = plotContainers[i + 1];
      await this.fillUpPage(doc, elementOne, elementTwo, startY);
      if (currentPage !== numberOfPages) this.addPage(doc);
    }
  };

  /**
   * it saves the given document with the given name
   * @param {Object} doc - the jspdf document
   * @param {String} documentName - the document name
   */
  static saveDocument = (doc, documentName) => {
    doc.save(documentName);
  };

  /**
   * it adds a page to the given document
   * @param {Object} doc - the jspdf document
   */
  static addPage = doc => {
    doc.addPage();
  };

  /**
   * it gets the csv headers from the plot data
   * @param {Object} plot - the plot data
   * @returns {String[]} the headers
   */
  static getCSVHeaders = plot => {
    let csvHeaders = [];
    if (plot.sliderData.length !== 0)
      plot.sliderName.split(",").forEach(sName => csvHeaders.push(sName));
    if (plot.plotType === "SC" && plot.scatterOptions.x !== "sweep")
      csvHeaders.push(plot.xName.replace(/,/g, "_"));
    else if (plot.plotType === "2D" || plot.plotType === "3D")
      csvHeaders.push(plot.xName.replace(/,/g, "_"));
    if (plot.plotType === "SC" && plot.scatterOptions.y !== "sweep")
      csvHeaders.push(plot.yName.replace(/,/g, "_"));
    else if (plot.plotType === "2D" || plot.plotType === "3D")
      csvHeaders.push(plot.yName.replace(/,/g, "_"));
    if (plot.plotType === "SC" && plot.scatterOptions.color === "sweep") {
      return csvHeaders;
    }
    if (plot.zData && plot.zData.length !== 0)
      csvHeaders.push(plot.zName.replace(/,/g, "_"));
    return csvHeaders;
  };

  static getModeAnalysisCSVHeaders = (plot, probeAlias) => {
    let csvHeaders = [];
    if (plot.sliderData.length !== 0)
      csvHeaders.push(probeAlias || "Probe Position");
    csvHeaders.push(plot.xName || "x");
    csvHeaders.push(plot.yName || "y");
    if (plot.zData.length !== 0) csvHeaders.push(plot.zName);
    return csvHeaders;
  };

  /**
   * generate comment for plot csv
   * @param {Object} plot - the plot
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the csv was generated
   * @returns {String} a text that is meant to be the first line of the csv
   */
  static getCSVComment = (
    plot,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    const plotType = plot.plotType === "3D" ? "Heat Map" : "Line";
    return [
      `## ${plotType} plot for ${projectName} > ${simulationName} ` +
        `${this.buildGenerationInfo(username, dateAndTime)}`
    ];
  };

  /**
   * generate comment for mode analysis plot csv
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the csv was generated
   * @returns {String} a text that is meant to be the first line of the csv
   */
  static getModeAnalysisCSVComment = (
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    return [
      `## Mode analysis plot for ${projectName} > ${simulationName} ` +
        `${this.buildGenerationInfo(username, dateAndTime)}`
    ];
  };

  static cartesian = (...args) => {
    var r = [],
      max = args.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i == max) r.push(a);
        else helper(a, i + 1);
      }
    }
    helper([], 0);
    return r;
  };

  /**
   * generate basic comment to be used in reports
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the document was generated
   * @returns {String} a structured information
   */
  static buildGenerationInfo = (username, dateAndTime) =>
    `generated by ${username} on ${dateAndTime}.`;

  /**
   * it builds the csv content from the given plot
   * @param {Object} plot - the given plot
   * @returns {String[]} the csv content
   */
  static getCSVContent = plot => {
    let csvContent = [];
    const hasSlider = plot.sliderData.length !== 0;
    if (plot.plotType === "2D") {
      let combinations =
        plot.sliderData.length > 0 ? this.cartesian(...plot.sliderData) : [];
      const flatYData = plot.yData.flat(plot.sliderData.length - 1);
      let c_index = 0;
      do {
        const combination =
          combinations.length > 0 ? combinations[c_index] : [];
        for (var xIndex = 0; xIndex < plot.xData.length; xIndex++) {
          const xValue = plot.xData[xIndex];
          const yValue = flatYData[c_index][xIndex];
          csvContent.push([...combination, xValue, yValue]);
        }
        c_index++;
      } while (c_index < combinations.length);
    }
    if (plot.plotType === "3D") {
      let combinations =
        plot.sliderData.length > 0 ? this.cartesian(...plot.sliderData) : [];
      const flatZData = plot.zData.flat(plot.sliderData.length - 1);
      let c_index = 0;
      do {
        const combination =
          combinations.length > 0 ? combinations[c_index] : [];
        for (var xIndex = 0; xIndex < plot.xData.length; xIndex++) {
          const xDimension = flatZData[c_index];
          const xValue = plot.xData[xIndex];
          for (var yIndex = 0; yIndex < plot.yData.length; yIndex++) {
            const yValue = plot.yData[yIndex];
            const zValue = xDimension[xIndex][yIndex];
            csvContent.push([...combination, xValue, yValue, zValue]);
          }
        }
        c_index++;
      } while (c_index < combinations.length);
    }
    if (plot.plotType === "SC") {
      for (let j = 0; j < plot.yData.length; j++) {
        let rowContent = [];
        rowContent.push(...Object.values(plot.scatterPoints[j]));
        if (plot.scatterOptions.x !== "sweep")
          rowContent.push(...[plot.xData[j]]);
        if (plot.scatterOptions.y !== "sweep")
          rowContent.push(...[plot.yData[j]]);
        if (plot.zData && plot.scatterOptions.color !== "sweep") {
          rowContent.push(plot.zData[j]);
        }
        csvContent.push(rowContent);
      }
    }
    return csvContent;
  };

  static getModeAnalysisCSVContent = plot => {
    let csvContent = [];
    const hasSlider = plot.sliderData.length !== 0;
    for (var sliderIndex = 0; sliderIndex < plot.zData.length; sliderIndex++) {
      const slider = plot.zData[sliderIndex];
      for (var yIndex = 0; yIndex < slider.length; yIndex++) {
        const yDimension = slider[yIndex];
        for (var xIndex = 0; xIndex < yDimension.length; xIndex++) {
          let csvRow = "";
          if (hasSlider) {
            if (Array.isArray(plot.sliderData[sliderIndex])) {
              csvRow += plot.sliderData[sliderIndex].join(";") + ";";
            } else {
              csvRow += plot.sliderData[sliderIndex] + ";";
            }
          }
          if (Array.isArray(plot.xData[xIndex])) {
            csvRow += plot.xData[xIndex].join(";") + ";";
          } else {
            csvRow += plot.xData[xIndex] + ";";
          }
          if (Array.isArray(plot.yData[yIndex])) {
            csvRow += plot.yData[yIndex].join(";") + ";";
          } else {
            csvRow += plot.yData[yIndex] + ";";
          }
          const zValue = yDimension[xIndex];
          csvRow += zValue;
          csvContent.push(csvRow.split(";"));
        }
      }
    }
    return csvContent;
  };

  /**
   * it gets the data within the plot and structure it in a way that can be passed
   * to the csv generator
   * @param {Object} plot - the plot object
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @returns {Object[]} the csv data
   */
  static getCSVData = (
    plot,
    projectName,
    simulationName,
    username,
    noComment = false
  ) => {
    let csvData = [];

    if (!noComment) {
      const csvComment = SimulationResultHelper.getCSVComment(
        plot,
        projectName,
        simulationName,
        username,
        new Date().toString()
      );
      csvData.push(csvComment);
    }
    let csvHeaders = [];
    if (
      plot?.plotType === "SC" &&
      plot?.scatterPoints &&
      plot?.scatterPoints?.length > 0
    )
      csvHeaders = Object.keys(plot.scatterPoints[0]);
    csvHeaders.push(...this.getCSVHeaders(plot));
    csvData.push(csvHeaders);

    const csvContent = this.getCSVContent(plot);
    const batchSize = 1000; // This value can be adjusted
    for (let i = 0; i < csvContent.length; i += batchSize) {
      csvData.push(...csvContent.slice(i, i + batchSize));
    }
    return csvData;
  };

  /**
   * it gets the data within the plot and structure it in a way that can be passed
   * to the csv generator
   * @param {Object} plot - the plot object
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @returns {Object[]} the csv data
   */
  static getModeAnalysisCSVData = (
    plot,
    projectName,
    simulationName,
    username,
    probeAlias
  ) => {
    let csvData = [];
    const csvComment = SimulationResultHelper.getModeAnalysisCSVComment(
      projectName,
      simulationName,
      username,
      new Date().toString()
    );
    csvData.push(csvComment);

    // const matrix = plot.zData[plot.rangeValue - 1];
    // csvData.push(...matrix);
    // return csvData;
    const csvHeaders = SimulationResultHelper.getModeAnalysisCSVHeaders(
      plot,
      probeAlias
    );
    csvData.push(csvHeaders);

    const csvContent = this.getModeAnalysisCSVContent(plot);
    for (let i = 0; i < csvContent.length; i++) {
      csvData.push(csvContent[i]);
    }
    return csvData;
  };

  static getAllPlotsCSVData = plots => {
    let allData = [];
    let allInputNames = [];
    let allOutputNames = [];
    plots.forEach(plot => {
      const csvData = this.getCSVData(plot, "", "", "", true);
      let csvHeaders = csvData.shift();
      let inputNames = [];
      let outputNames = [];
      if (plot.plotType == "2D" || plot.plotType == "3D") {
        inputNames = csvHeaders.slice(0, -1);
        outputNames = csvHeaders.slice(-1);
      } else if (plot.plotType == "SC") {
        let outputCounts = Object.values(plot.scatterOptions).filter(
          value => value === "output"
        ).length;
        inputNames = csvHeaders.slice(0, -outputCounts);
        outputNames = csvHeaders.slice(-outputCounts);
      } else {
      }
      allInputNames = allInputNames.concat(inputNames);
      allOutputNames = allOutputNames.concat(outputNames);
      let plotOutputData = csvData.map(row => {
        const values = [...row];
        const inputs = {};
        inputNames.forEach((name, index) => {
          inputs[name] = values[index];
        });
        if (plot.fixed) {
          plot.fixed.forEach(val => {
            const [name, value] = val.split("/");
            allInputNames.push(name);
            inputs[name] = Number(value);
          });
        }

        return values.slice(inputNames.length).reduce((obj, value, index) => {
          obj[outputNames[index].replace(/,/g, "_")] = value;
          return obj;
        }, inputs);
      });
      allData = allData.concat(plotOutputData);
    });
    allData.push({
      "selected (0/1)": null
    });
    const uniqueInputs = new Set();
    allData.forEach(row => {
      Object.keys(row).forEach(key => {
        uniqueInputs.add(key);
      });
    });

    const outputData = allData.map(row => {
      const outputRow = {};
      uniqueInputs.forEach(input => {
        outputRow[input] = row[input] ?? null;
      });
      return outputRow;
    });

    const mergedArray = HelperUtils.mergeArrayOfObjects(
      outputData,
      allInputNames
    );

    return mergedArray;
  };

  /**
   * it gets the names of all materials used by a layer and has a linebreak amongst them
   * @param {Object} layer
   * @returns {String} the materials' names
   */
  static getLayerUsedMaterialsNames = layer => {
    const { used_materials } = layer;
    let names = "";
    const lastIndex = used_materials.length - 1;
    for (var i = 0; i < used_materials.length; i++) {
      const used_material = used_materials[i];
      names += `${used_material.name + (i !== lastIndex ? "\n" : "")}`;
    }
    return names;
  };

  /**
   *
   * @param {*} usedMaterialPropertyValue - the used material property value inputted by the user
   * @param {*} material - the related material
   * @param {*} materialPropertyNewKeyname - the keyname of the property in the material set
   * @param {*} materialPropertyOldKeyname - the old keyname of the property in the material set
   * @param {*} length - how many values the inputted material value will be repeated
   * @returns {Number[]} the inputted material property value or the property value from the material set
   */
  static getMaterialProperty = (
    usedMaterialPropertyValue,
    material,
    materialPropertyNewKeyname,
    materialPropertyOldKeyname,
    length
  ) => {
    const thereIsADefinedValueForTheUsedMaterialProperty =
      usedMaterialPropertyValue !== undefined &&
      usedMaterialPropertyValue !== null;
    if (thereIsADefinedValueForTheUsedMaterialProperty) {
      if (
        !isNaN(usedMaterialPropertyValue) &&
        !Array.isArray(usedMaterialPropertyValue) &&
        length
      ) {
        let array = [];
        for (let i = 0; i < length; i++) {
          array[i] = usedMaterialPropertyValue;
        }
        return array;
      }
      return usedMaterialPropertyValue;
    }
    return material[materialPropertyNewKeyname]
      ? material[materialPropertyNewKeyname]
      : material[materialPropertyOldKeyname];
  };

  /**
   * it gets all used materials used in the calculation and also show which layer they belong to
   * if the used material does not have a value in one of its fields, then we take it from the material
   * @param {Object} - configuration
   * @returns {Object[]} - an array of used materials by layer sorted by the material name
   */
  static getUsedMaterialsByLayerFromConfiguration = configuration => {
    let allUsedMaterialsByLayer = [];
    const { structure, materials } = configuration;
    structure.forEach(layer => {
      const usedMaterialsByLayer = layer.used_materials.map(usedMaterial => {
        const material = materials[usedMaterial.name];
        const wavelength = this.getMaterialProperty(
          usedMaterial.wavelength,
          material,
          "wavelength",
          "wavelength"
        );
        const refractiveIndex = this.getMaterialProperty(
          usedMaterial.refractive_index,
          material,
          "refractive_index",
          "refractiveIndex",
          wavelength.length
        );
        const absorptionCoeff = this.getMaterialProperty(
          usedMaterial.absorption,
          material,
          "absorption",
          "absorptionCoeff",
          wavelength.length
        );
        return {
          layerName: layer.name,
          name: usedMaterial.name,
          wavelength,
          refractiveIndex,
          absorptionCoeff
        };
      });
      allUsedMaterialsByLayer.push(...usedMaterialsByLayer);
    });
    return allUsedMaterialsByLayer.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  /**
   * it gets used materials classified by layers from the result's configuration
   * and sort them by the layers names
   * @param {Object} configuration - the simulation result's configuration
   * @returns {Object[]} a list of materials each containing material properties and the name of all layers
   * that uses them.
   */
  static getUsedMaterialsByLayersFromConfiguration = configuration => {
    const usedMaterialsByLayer = this.getUsedMaterialsByLayerFromConfiguration(
      configuration
    );
    const unrepeatedUsedMaterialsByLayer = this.processRepeatedMaterials(
      usedMaterialsByLayer
    );
    return unrepeatedUsedMaterialsByLayer.sort((a, b) =>
      a.layersNames > b.layersNames ? 1 : -1
    );
  };

  /**
   * it takes a list of used materials and filters the used materials that are not plottable
   * @param {Object[]} usedMaterialsByLayer - a list of used materials
   * @returns only used materials that are plottable
   */
  static getPlottableMaterials = usedMaterialsByLayer => {
    let plottableMaterials = [];
    usedMaterialsByLayer.forEach(usedMaterial => {
      if (this.isUsedMaterialPlottable(usedMaterial)) {
        plottableMaterials.push(usedMaterial);
      }
    });
    return plottableMaterials;
  };

  /**
   * it takes a list of used materials per one layer, find repeated used materials and sort them
   * by multiple layers
   * @param {Object[]} materialsByLayer - used materials containing the layer name
   * @returns {Object[]} unrepeated used materials each containing multiple layer names
   */
  static getMaterialsPerLayers = materialsByLayer => {
    let unrepeatedMaterials = [];
    for (var i = 0; i < materialsByLayer.length; i++) {
      const currentMaterial = materialsByLayer[i];
      const {
        layerName,
        name,
        wavelength,
        refractiveIndex,
        absorptionCoeff
      } = currentMaterial;
      let layersNames = [];
      layersNames.push(layerName);
      let j = i + 1;
      while (j < materialsByLayer.length) {
        const materialToCompare = materialsByLayer[j];
        if (this.areUsedMaterialsEqual(currentMaterial, materialToCompare)) {
          layersNames.push(materialToCompare.layerName);
          materialsByLayer.splice(j, 1);
        } else {
          j++;
        }
      }
      const sortedLayersNames = layersNames.sort((a, b) => (a > b ? 1 : -1));
      unrepeatedMaterials.push({
        layersNames: HelperUtils.joinWords(layersNames),
        name,
        wavelength,
        refractiveIndex,
        absorptionCoeff
      });
    }
    return unrepeatedMaterials;
  };

  /**
   * it takes a list of used materials classified by layer, filter the ones that are not plottable
   * and classify them by multiple layers if layers have identical used materials. This is because
   * it is not interesting to repeat graphs in the result's configuration report.
   * @param {Object[]} usedMaterialsByLayer - a list of used materials containing the layer name
   * @returns {Object[]} a list of unrepeated used materials containing multiple layer names
   */
  static processRepeatedMaterials = usedMaterialsByLayer => {
    let plottableMaterials = this.getPlottableMaterials(usedMaterialsByLayer);
    return this.getMaterialsPerLayers([...plottableMaterials]);
  };

  /**
   * It checks if two used materials are equal by comparing their names, refractive indexes
   * and absorption coefficients
   * @param {Object} materialOne - the material
   * @param {Object} materialTwo - the other material
   * @returns {Boolean} whether they are equal
   */
  static areUsedMaterialsEqual = (materialOne, materialTwo) => {
    return (
      materialOne.name === materialTwo.name &&
      SimulationResultHelper.arePropertiesEqual(
        materialOne.refractiveIndex,
        materialTwo.refractiveIndex
      ) &&
      SimulationResultHelper.arePropertiesEqual(
        materialOne.absorptionCoeff,
        materialTwo.absorptionCoeff
      )
    );
  };

  /**
   * it checks if two property values are equal. we don't deep check arrays because we assume
   * that used material field values that are an array with more than one value than it is a value
   * inherited from material. it also compare an array of single value or a string (a swept variable)
   * @param {(Number[]|String)} propertyOne - the first property value
   * @param {(Number[]|String)} propertyTwo - a property value to be compared to propertyOne
   * @returns {Boolean} whether they are equal or not
   */
  static arePropertiesEqual = (propertyOne, propertyTwo) => {
    if (
      this.isPropertyValuePlottable(propertyOne) &&
      this.isPropertyValuePlottable(propertyTwo)
    ) {
      if (propertyOne[0] === propertyTwo[0]) {
        return true;
      }
    } else if (propertyOne === propertyTwo) {
      return true;
    }
    return false;
  };

  /**
   * it assumes that the value of a material is plottable if it is an array because
   * fields that were built from sweep variables should not be plotted
   * @param {(Number[]|String)} fieldValue - the material's property value
   * @returns {Boolean}
   */
  static isPropertyValuePlottable = fieldValue => fieldValue instanceof Array;

  /**
   * because we only insert plots of materials that do not have sweep variables for refractive index
   * and absorption coefficient this method checks whether a used material is plottable
   * @param {Object} usedMaterial - the used material
   * @returns {Boolean} whether it is plottable
   */
  static isUsedMaterialPlottable = usedMaterial =>
    this.isPropertyValuePlottable(usedMaterial.refractiveIndex) ||
    this.isPropertyValuePlottable(usedMaterial.absorptionCoeff);

  /**
   * it gets the sorted layers from the given simulation result's configuration
   * @param {Object} configuration
   * @returns {Object[]} the list of layers
   */
  static getLayersFromConfiguration = configuration => {
    const sortedLayers = configuration.structure.sort(
      (layerA, layerB) => layerA.correct_order - layerB.correct_order
    );
    return sortedLayers.map(layer => [
      layer.correct_order,
      layer.name,
      this.getLayerStructure(layer),
      layer.thickness,
      this.getLayerUsedMaterialsNames(layer),
      layer.parameters
    ]);
  };

  /**
   * @param {Object} layer
   * @returns {String} the structure type
   */
  static getLayerStructure(layer) {
    if (layer.structure) {
      return layer.structure;
    }
    return layer.discretized && layer.discretized.length
      ? "image"
      : "homogeneous";
  }

  /**
   * it gets the incident light values from the result's configuration
   * @param {Object} configuration - the result's configuration
   * @returns {String[][]} the incident light values
   */
  static getIncidentLightFromConfiguration = configuration => {
    const {
      zenit,
      azimut,
      wavelength,
      polarization,
      amplitude
    } = configuration.incident_light;
    return [[zenit, azimut, wavelength, polarization, amplitude]];
  };

  /**
   * it gets the diffraction orders from the result's configuration
   * @param {Object} configuration - the result's configuration
   * @returns {String[][]} - the diffraction orders
   */
  static getDiffractionOrdersFromConfiguration = configuration => {
    const { X, Y } = configuration.diffractionOrders;
    return [[X, Y]];
  };

  /**
   * in order to not show really long values in the sweep variables table for the configuration report
   * we show a limited text. Example: 20, 30, 40... 500, 520
   * @param {Number[]} - the values of a swept variable
   * @returns {String} the formatted text
   */
  static formatSweptVariableValues = values => {
    if (values) {
      if (values.length === 1) return `Selected value: ${values[0]}`;
      if (values.length <= 6) return values.join(", ");
      const firstThree = values.slice(0, 3).join(", ");
      const lastTwo = values.slice(-2).join(", ");
      return `${firstThree}... ${lastTwo}`;
    } else {
      return "-";
    }
  };

  /**
   * it gets the swept variables from the result's configuration
   * @param {Object} configuration - the result's configuration
   * @returns {String[][]} the swept variables
   */
  static getSweptVariablesFromConfiguration = configuration =>
    configuration.swept_variables.map(variable => [
      variable.name,
      variable.sweepType,
      variable.parameters ? variable.parameters : variable.formula,
      this.formatSweptVariableValues(variable.values)
    ]);

  static getProbeParametersFromConfiguration = configuration => {
    const { probe_name, probe_position } = configuration.probe_parameters;
    return [probe_name, probe_position];
  };

  /**
   * it gets the global parameters values from the given simulation result's configuration
   * @param {Object} configuration
   * @returns {String[][]} a list with only one row because global parameters are not multiple
   */
  static getGlobalParametersFromConfiguration = configuration => {
    const { global_parameters } = configuration;
    return [
      [
        global_parameters.cellWidth,
        global_parameters.cellHeight,
        global_parameters.unit
      ]
    ];
  };

  /**
   * it generates and inserts the header of a simulation result's configuration report
   * @param {Object} doc - the report document
   * @param {Number} startY - the distance from the top of the page where the header should start
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the report was generated
   * @param {String} simulationType - the type of simulation
   */
  static buildConfigurationReportHeader = (
    doc,
    startY,
    projectName,
    simulationName,
    username,
    dateAndTime,
    simulationType
  ) => {
    this.buildReportHeader(
      doc,
      startY,
      projectName,
      simulationName,
      username,
      dateAndTime,
      ResultReportType.CONFIGURATION,
      simulationType
    );
  };

  /**
   * it generates and inserts the header of a simulation result's full report
   * @param {Object} doc - the report document
   * @param {Number} startY - the distance from the top of the page where the header should start
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the report was generated
   * @param {String} simulationType - the type of simulation
   */
  static buildFullReportHeader = (
    doc,
    startY,
    projectName,
    simulationName,
    username,
    dateAndTime,
    simulationType
  ) => {
    this.buildReportHeader(
      doc,
      startY,
      projectName,
      simulationName,
      username,
      dateAndTime,
      ResultReportType.FULL,
      simulationType
    );
  };

  /**
   * it generates and inserts the header of a simulation result's plots
   * @param {Object} doc - the report document
   * @param {Number} startY - the distance from the top of the page where the header should start
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the report was generated
   * @param {String} simulationType - the type of simulation
   */
  static buildPlotsReportHeader = (
    doc,
    startY,
    projectName,
    simulationName,
    username,
    dateAndTime,
    simulationType
  ) => {
    this.buildReportHeader(
      doc,
      startY,
      projectName,
      simulationName,
      username,
      dateAndTime,
      ResultReportType.PLOTS,
      simulationType
    );
  };

  /**
   * it generates and inserts the header of a simulation result's
   * @param {Object} doc - the report document
   * @param {Number} startY - the distance from the top of the page where the header should start
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username- the name of the user
   * @param {String} dateAndTime - when the report was generated
   * @param {String} type - the type of report
   * @param {String} simulationType - the type of simulation
   */
  static buildReportHeader = (
    doc,
    startY,
    projectName,
    simulationName,
    username,
    dateAndTime,
    type,
    simulationType
  ) => {
    doc.text(
      10,
      startY,
      `${simulationName}: ${simulationType} Result's ${type}`
    );
    doc.setFontSize(10);
    const comment =
      `for project ${projectName} ` +
      `${this.buildGenerationInfo(username, dateAndTime)}`;
    doc.text(10, startY + 5, comment);
  };

  /**
   * it inserts used material graphs into a pdf document containing a label above each graph
   * if adds new pages if the graphs dont fit in
   * @param {Object} doc - the pdf document
   * @param {HTMLElement[]} graphs - a list of html elements
   * @returns {Number} the final Y value which is the end of the last inserted image
   */
  static insertUsedMaterialsGraphs = async (doc, graphs) => {
    const imageHeight = 100;
    const imageWidth = 200;
    const pageMaxWidth = 210;
    const imageMarginLeft = (pageMaxWidth - imageWidth) / 2;
    const pageMaxHeight = 290;
    let graphStartY = doc.previousAutoTable.finalY;

    for (var i = 0; i < graphs.length; i++) {
      const graph = graphs[i];
      if (graphStartY + imageHeight + 20 > pageMaxHeight) {
        this.addPage(doc);
        graphStartY = 10;
      }
      const title = `Material ${graph.getAttribute("usedmaterialname")}`;
      const subTitle = `used in layer(s): ${graph.getAttribute("layersnames")}`;

      doc.setFontSize(12);
      doc.text(10, graphStartY + 10, title);
      doc.setFontSize(8);
      doc.text(10, graphStartY + 15, subTitle);
      const graphImgData = await this.getImageData(graph);
      doc.addImage(
        graphImgData,
        "PNG",
        imageMarginLeft,
        graphStartY + 20,
        imageWidth,
        imageHeight
      );
      graphStartY += imageHeight + 20;
    }
    return graphStartY;
  };

  /**
   * it builds content of the configuration's pdf report which consists of graphs and data tables
   * @param {Object} doc - the pdf document
   * @param {Number} startY - where the content should start from the top of the page
   * @param {Object} configuration - the configuration
   */
  static buildConfigurationReportContent = async (
    doc,
    startY,
    configuration
  ) => {
    const usedMaterialsGraphs = document.getElementsByClassName(
      "usedMaterialGraph"
    );
    doc.setFontSize(12);
    doc.text(10, startY, "Layers");
    const layers = this.getLayersFromConfiguration(configuration);
    doc.autoTable({
      startY: startY + 5,
      head: [["Order", "Name", "Structure", "Thickness", "Materials"]],
      body: layers,
      styles: { overflow: "linebreak" }
    });

    const parameterStructuredLayers = this.getParameterStructuredLayers(layers);

    if (parameterStructuredLayers.length) {
      const unrepeatedParameters = this.getUnrepeatedLayersParameters(
        parameterStructuredLayers
      );
      const parameterizedStructures = this.getParameterizedStructures(
        parameterStructuredLayers,
        unrepeatedParameters
      );
      doc.text(
        10,
        doc.previousAutoTable.finalY + 10,
        "Parameterized Structures"
      );
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 15,
        head: [["Layer", "Type", ...unrepeatedParameters]],
        body: parameterizedStructures,
        styles: { overflow: "linebreak" }
      });
    }

    const lastImageY = await this.insertUsedMaterialsGraphs(
      doc,
      usedMaterialsGraphs
    );

    doc.setFontSize(12);
    doc.text(10, lastImageY + 10, "Global Parameters");
    doc.autoTable({
      startY: lastImageY + 15,
      head: [["Cell Width", "Cell Length", "Unit"]],
      body: this.getGlobalParametersFromConfiguration(configuration)
    });

    doc.text(10, doc.previousAutoTable.finalY + 10, "Incident Light");
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 15,
      head: [["Zenith", "Azimuth", "Wavelength", "Polarization", "Amplitude"]],
      body: this.getIncidentLightFromConfiguration(configuration)
    });

    doc.text(10, doc.previousAutoTable.finalY + 10, "Diffraction Orders");
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 15,
      head: [["X", "Y"]],
      body: this.getDiffractionOrdersFromConfiguration(configuration)
    });

    const sweptVariables = this.getSweptVariablesFromConfiguration(
      configuration
    );
    if (sweptVariables.length !== 0) {
      doc.text(10, doc.previousAutoTable.finalY + 10, "Swept Variables");
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 15,
        head: [["Name", "Type", "Parameters", "Values"]],
        body: sweptVariables,
        styles: { overflow: "linebreak" },
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
          3: { cellWidth: 60 }
        }
      });
    }
  };

  /**
   * it builds content of the configuration's pdf report of mode analysis
   * @param {Object} doc - the pdf document
   * @param {Number} startY - where the content should start from the top of the page
   * @param {Object} configuration - the configuration
   */
  static buildConfigurationModeAnalysisReportContent = async (
    doc,
    startY,
    configuration
  ) => {
    await this.buildConfigurationReportContent(doc, startY, configuration);
    const probeParameters = this.getProbeParametersFromConfiguration(
      configuration
    );
    doc.text(10, doc.previousAutoTable.finalY + 10, "Probe");
    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 15,
      head: [["Name", "Position"]],
      body: [probeParameters],
      styles: { overflow: "linebreak" }
    });
  };

  /**
   * @param {Object[][]} parameterStructuredLayers - list of list of layer values
   * @param {String[]} parametersNames - the structure parameters used by all layers
   * @returns {Object[][]} only the layer name, layer type and the value of each parameter
   *
   */
  static getParameterizedStructures(
    parameterStructuredLayers,
    parametersNames
  ) {
    const parameterizedStructures = parameterStructuredLayers.map(layer => {
      return [
        layer[0],
        layer[1],
        ...parametersNames.map(parameter =>
          layer[2][parameter] ? layer[2][parameter].value : ""
        )
      ];
    });
    return parameterizedStructures;
  }

  /**
   * @param {Object[][]} layers - list of list of layer values
   * @returns {Object[][]} only the layer name, layer type and layer parameters of the parameter structured layers
   */
  static getParameterStructuredLayers(layers) {
    return layers
      .filter(layer => layer[2] !== "homogeneous" && layer[2] !== "image")
      .map(layer => [layer[1], layer[2], layer[5]]);
  }

  /**
   * @param {Object[][]} parameterStructuredLayers - list of list of layer values
   * @returns {String[]} the structure parameters used by all layers
   */
  static getUnrepeatedLayersParameters(parameterStructuredLayers) {
    const layersParameters = parameterStructuredLayers.map(layer =>
      Object.keys(layer[2])
    );
    let allParameters = [];
    for (const layerParameters of layersParameters) {
      allParameters.push(...layerParameters);
    }
    const unrepeatedParameters = [...new Set(allParameters)];
    return unrepeatedParameters;
  }

  /**
   * it builds a pdf document to display the simulation result's plots and downloads it
   * @param {Object} doc - the pdf document
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username - the user who generated it
   * @param {String} dateAndTime - when the pdf was generated
   */
  static buildAndDownloadPlotsReport = async (
    doc,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    this.buildPlotsReportHeader(
      doc,
      15,
      projectName,
      simulationName,
      username,
      dateAndTime,
      SimulationType.SIMULATION
    );
    await this.insertResultPlotsInDocument(doc, 30);
    this.saveDocument(doc, `${simulationName}_result_plots.pdf`);
  };

  /**
   * it builds a pdf document to display the mode analysis result's plots and downloads it
   * @param {Object} doc - the pdf document
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username - the user who generated it
   * @param {String} dateAndTime - when the pdf was generated
   */
  static buildAndDownloadModeAnalysisPlotsReport = async (
    doc,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    this.buildPlotsReportHeader(
      doc,
      15,
      projectName,
      simulationName,
      username,
      dateAndTime,
      SimulationType.MODE_ANALYSIS
    );
    await this.insertResultPlotsInDocument(doc, 30);
    this.saveDocument(doc, `${simulationName}_mode_analysis_plots.pdf`);
  };

  /**
   * it builds a pdf document to display the simulation result's plots and downloads it
   * @param {Object} doc - the pdf document
   * @param {Object} configuration - the configuration
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username - the user who generated it
   * @param {String} dateAndTime - when the pdf was generated
   */
  static buildAndDownloadConfigurationReport = async (
    doc,
    configuration,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    this.buildConfigurationReportHeader(
      doc,
      15,
      projectName,
      simulationName,
      username,
      dateAndTime,
      SimulationType.SIMULATION
    );
    await this.buildConfigurationReportContent(doc, 40, configuration);
    this.saveDocument(doc, `${simulationName}_result_configuration.pdf`);
  };

  /**
   * it builds a pdf document to display the mode analysis' configuration and downloads it
   * @param {Object} doc - the pdf document
   * @param {Object} configuration - the configuration
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username - the user who generated it
   * @param {String} dateAndTime - when the pdf was generated
   */
  static buildAndDownloadModeAnalysisConfigurationReport = async (
    doc,
    configuration,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    this.buildConfigurationReportHeader(
      doc,
      15,
      projectName,
      simulationName,
      username,
      dateAndTime,
      SimulationType.MODE_ANALYSIS
    );
    await this.buildConfigurationModeAnalysisReportContent(
      doc,
      40,
      configuration
    );
    this.saveDocument(doc, `${simulationName}_mode_analysis_configuration.pdf`);
  };

  /**
   * it adds a text to a pdf document
   * @param {Object} doc - the pdf document
   * @param {Number} marginLeft - the left margin
   * @param {Number} marginTop - the top margin
   * @param {Number} fontSize - the size of the font
   * @param {String} text - the text to be added
   */
  static addTextToDocument = (doc, marginLeft, marginTop, fontSize, text) => {
    doc.setFontSize(fontSize);
    doc.text(marginLeft, marginTop, text);
  };

  /**
   * it builds a pdf document containing the simulation result's configuration and plots and downloads it
   * @param {Object} doc - the pdf document
   * @param {Object} configuration - the configuration
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username - the user who generated it
   * @param {String} dateAndTime - when the pdf was generated
   */
  static buildAndDownloadFullReport = async (
    doc,
    configuration,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    this.buildFullReportHeader(
      doc,
      15,
      projectName,
      simulationName,
      username,
      dateAndTime,
      SimulationType.SIMULATION
    );
    this.addTextToDocument(doc, 10, 40, 15, "Configuration");
    await this.buildConfigurationReportContent(doc, 50, configuration);
    this.addPage(doc);
    this.addTextToDocument(doc, 10, 15, 15, "Plots");
    await this.insertResultPlotsInDocument(doc, 20);
    this.saveDocument(doc, `${simulationName}_result_extended.pdf`);
  };

  /**
   * it builds a pdf document containing the mode analysis' configuration and plots and downloads it
   * @param {Object} doc - the pdf document
   * @param {Object} configuration - the configuration
   * @param {String} projectName - the name of the project
   * @param {String} simulationName - the name of the simulation
   * @param {String} username - the user who generated it
   * @param {String} dateAndTime - when the pdf was generated
   */
  static buildAndDownloadModeAnalysisFullReport = async (
    doc,
    configuration,
    projectName,
    simulationName,
    username,
    dateAndTime
  ) => {
    this.buildFullReportHeader(
      doc,
      15,
      projectName,
      simulationName,
      username,
      dateAndTime,
      SimulationType.MODE_ANALYSIS
    );
    this.addTextToDocument(doc, 10, 40, 15, "Configuration");
    await this.buildConfigurationModeAnalysisReportContent(
      doc,
      50,
      configuration
    );
    this.addPage(doc);
    this.addTextToDocument(doc, 10, 15, 15, "Plots");
    await this.insertResultPlotsInDocument(doc, 20);
    this.saveDocument(doc, `${simulationName}_mode_analysis_extended.pdf`);
  };

  /**
   * it formats the duration in seconds to a user friendly way.
   * @returns {Object} the metrics with the formatted duration
   */
  static formatJobMetrics = metrics => {
    const timeUnits = {
      DAY: {
        label: "day",
        seconds: 86400
      },
      HOUR: {
        label: "hour",
        seconds: 3600
      },
      MINUTE: {
        label: "minute",
        seconds: 60
      }
    };

    function getValueOfTimeUnitFromSeconds(
      seconds,
      timeUnit,
      formattedUnitValuesArray
    ) {
      const timeUnitSeconds = timeUnits[timeUnit].seconds;
      let unitValue = 0;
      while (seconds >= timeUnitSeconds) {
        unitValue += 1;
        seconds -= timeUnitSeconds;
      }
      if (unitValue) {
        const formattedUnitValue = `${unitValue} ${timeUnits[timeUnit].label}(s)`;
        formattedUnitValuesArray.push(formattedUnitValue);
      }
      return seconds;
    }

    let seconds = metrics.duration,
      formattedUnitsArray = [];
    for (let timeUnit of Object.keys(timeUnits)) {
      seconds = getValueOfTimeUnitFromSeconds(
        seconds,
        timeUnit,
        formattedUnitsArray
      );
    }
    formattedUnitsArray.push(`${seconds} second(s)`);
    const formattedDuration = HelperUtils.joinWords(formattedUnitsArray);
    return { ...metrics, duration: formattedDuration };
  };

  static readCsvSelectionFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (function(theFile) {
        return function(e) {
          try {
            const data = e.target.result;
            const fileName = file.name;
            let jsonObj;
            let selectedHeaderExists = false;
            if (fileName.endsWith(".csv")) {
              var parsed = Papa.parse(data, {
                header: true,
                transformHeader: function(header) {
                  if (header.startsWith("selected")) {
                    selectedHeaderExists = true;
                    return "selected";
                  }
                  return header;
                }
              });
              if (!selectedHeaderExists) {
                throw new Error("Selection header missing");
              }
              jsonObj = parsed.data;
              resolve(jsonObj);
            } else {
              throw new Error("Not a csv file");
            }
          } catch (exception) {
            reject(exception.message);
          }
        };
      })(file);
      reader.readAsText(file);
    });
  };
}
