import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import UserSelector from "BaseApp/selectors/User";
import UserAction from "BaseApp/actions/User";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * This component was created to allow the user to visualize documents within a dialog.
 * the component content can vary
 * @author Akira Kotsugai
 * @param {Object} props - props passed by parent component and redux
 */
export class DocumentDialog extends PureComponent {
  /**
   * it can be passed to child components and tells redux to close the dialog
   * @callback
   */
  handleClose = () => {
    this.props.closeDocumentDialogAction();
  };

  render() {
    return (
      <UnselfishDialog
        fullScreen
        open={this.props.documentDialogContent !== null}
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              test-data="closeBtn"
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DocumentViewer content={this.props.documentDialogContent} />
      </UnselfishDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    documentDialogContent: UserSelector.getDocumentDialogContent(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeDocumentDialogAction: () =>
      dispatch(UserAction.setDocumentDialogContent(null))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(DocumentDialog)));
