import { actionType } from "MetaCell/actions/Family";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testFamilies = {
  byId: {
    1: {
      id: 1,
      name: "dummy",
      description: "test description",
      cell_width: null,
      cell_height: null,
      unit: "mm"
    },
    2: {
      id: 2,
      name: "new family",
      description: "test family",
      cell_width: null,
      cell_height: null,
      unit: "μm"
    },
    3: {
      id: 3,
      name: "dummy family",
      description: "",
      cell_width: null,
      cell_height: null,
      unit: "mm"
    },
    4: {
      id: 4,
      name: "structure family",
      description: "",
      cell_width: null,
      cell_height: null,
      unit: "mm"
    },
    5: {
      id: 5,
      name: "structure family",
      description: "structure description",
      cell_width: null,
      cell_height: null,
      unit: "μm"
    },
    6: {
      id: 6,
      name: "structure family snapshot",
      description: "structure description",
      cell_width: null,
      cell_height: null,
      unit: "μm",
      is_snapshot: true
    },
    7: {
      id: 7,
      name: "pdk family",
      description: "https://random.wikilink.com",
      cell_width: null,
      cell_height: null,
      unit: "μm",
      for_pdk: true,
      is_snapshot: false,
      pdk_preferences: {
        instructions_link: "https://random.wikilink.com"
      }
    }
  },
  allIds: [1, 2, 3, 4, 5, 6, 7],
  loaded: true
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testFamilyMembers = {
  byId: {
    1: {
      id: 1,
      alias: "family one member one",
      number_of_layers: 3,
      diff_summary: null,
      family: 1,
      simulation_job: 120,
      cell_width: 20,
      cell_height: 25,
      unit: "μm"
    },
    2: {
      id: 2,
      alias: "family one member two",
      number_of_layers: 3,
      diff_summary: null,
      family: 1,
      simulation_job: 120
    },
    3: {
      id: 3,
      alias: "family two member one",
      number_of_layers: 7,
      diff_summary: null,
      family: 2,
      simulation_job: 210
    }
  },
  allIds: [1, 2, 3],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} FamilyDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    families: {
      byId: {},
      allIds: [],
      loaded: false
    },
    familyMembers: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    wizardDialog: {
      openStatus: false,
      familyId: null
    }
  }
};

/**
 * Reducer function to manipulate the state of families
 * @param {Object} [state=FamilyDefaultState] - family entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case actionType.UPSERT_FAMILIES: {
      let families = cloneDeep(state.entities.families);
      Utils.addOrUpdateEntities(payload, families);
      return {
        ...state,
        entities: { ...state.entities, families }
      };
    }

    case actionType.DELETE_FAMILY: {
      let families = cloneDeep(state.entities.families);
      Utils.deleteEntities(payload, families);
      return {
        ...state,
        entities: { ...state.entities, families }
      };
    }

    case actionType.UPDATE_FAMILY: {
      let families = cloneDeep(state.entities.families);
      families.byId[payload.id] = payload;
      return {
        ...state,
        entities: { ...state.entities, families }
      };
    }

    case actionType.SET_MEMBER_WIZARD_OPEN: {
      let wizardDialog = cloneDeep(state.ui.wizardDialog);
      wizardDialog.openStatus = payload.openStatus;
      wizardDialog.familyId = payload.familyId;
      return {
        ...state,
        ui: { ...state.ui, wizardDialog }
      };
    }

    case actionType.UPSERT_FAMILY_MEMBERS: {
      let familyMembers = cloneDeep(state.entities.familyMembers);
      Utils.addOrUpdateEntities(payload, familyMembers);
      return {
        ...state,
        entities: { ...state.entities, familyMembers }
      };
    }

    case actionType.DELETE_FAMILY_MEMBER: {
      let familyMembers = cloneDeep(state.entities.familyMembers);
      Utils.deleteEntities(payload, familyMembers);
      return {
        ...state,
        entities: { ...state.entities, familyMembers }
      };
    }

    default:
      return state;
  }
}
