import React, { PureComponent } from "react";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import PropTypes from "prop-types";
import DropZone from "components/DropZone/DropZone";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

class Import extends PureComponent {
  render() {
    const {
      open,
      loading,
      onClose,
      onDrop,
      message,
      error,
      accept,
      postImportComponent
    } = this.props;
    return (
      <UnselfishDialog
        name="ImportDialog"
        open={open}
        onClose={onClose}
        maxWidth={"lg"}
      >
        <div style={{ minWidth: 500 }}>
          {postImportComponent ? (
            postImportComponent
          ) : (
            <div style={{ padding: "100px 0" }}>
              <DropZone
                onDrop={onDrop}
                accept={accept}
                loading={loading}
                error={error}
                message={message}
                style={{
                  textAlign: "center"
                }}
              />
            </div>
          )}
        </div>
      </UnselfishDialog>
    );
  }
}

Import.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.string,
  accept: PropTypes.string.isRequired,
  postImportComponent: PropTypes.element
};

export default withErrorBoundary(Import);
