/**
 * Instructions to be sent in the action to the directory explorer's reducer
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} DirectoryExplorerActionType
 * @property {String} UPDATE_SELECTED_ITEM  tells the reducer to update the selected item in the tree
 * @property {String} UPDATE_FORM  tells the reducer to update the form to include itens in the tree
 * @property {String} DELETE_PROJECT tells the reducer to remove a project item from the tree
 * @property {String} DELETE_SIMULATION tells the reducer to remove a simulation item from the tree
 * @property {String} UPSERT_PROJECTS tells the reducer to upsert projects into the tree
 * @property {String} UPSERT_SIMULATIONS tells the reducer to upsert simulations into the tree
 * @property {String} OPEN_SIMULATION tells the reducer to open a simulation
 * @property {String} EDIT_ITEM tells the reducer to edit an item
 * @property {String} UPDATE_ITEM_EDITING_VALUE tells the reducer to update the name of the editing item
 * @property {String} APPLY_EDITED_PROJECT tells the reducer to update the saved project
 * @property {String} APPLY_EDITED_SIMULATION tells the reducer to update the saved simulation
 * @property {String} SET_DIRECTORY_EXPLORER tells the reducer to set the directory explorer values
 * @property {String} DELETE_PROJECTS_AND_SIMULATIONS tells the reducer to delete projects and simulations
 * @global
 */
export const actionType = Object.freeze({
  UPDATE_SELECTED_ITEM: "UPDATE_SELECTED_ITEM",
  UPDATE_FORM: "UPDATE_FORM",
  DELETE_PROJECT: "DELETE_PROJECT",
  DELETE_SIMULATION: "DELETE_SIMULATION",
  UPSERT_PROJECTS: "UPSERT_PROJECTS",
  UPSERT_SIMULATIONS: "UPSERT_SIMULATIONS",
  SET_DIRECTORY_EXPLORER: "SET_DIRECTORY_EXPLORER",
  OPEN_SIMULATION: "OPEN_SIMULATION",
  EDIT_ITEM: "EDIT_ITEM",
  UPDATE_ITEM_EDITING_VALUE: "UPDATE_ITEM_EDITING_VALUE",
  APPLY_EDITED_PROJECT: "APPLY_EDITED_PROJECT",
  APPLY_EDITED_SIMULATION: "APPLY_EDITED_SIMULATION",
  DELETE_PROJECTS_AND_SIMULATIONS: "DELETE_PROJECTS_AND_SIMULATIONS"
});

/**
 * Actions creator for the directory explorer's reducer. it does not need to be unit tested
 * because directory explorer reducer already tests it.
 * consist of an instruction {@link DirectoryExplorerActionType} and some data.
 */
export default class DirectoryExplorerAction {
  static updateSelectedItem = item => ({
    type: actionType.UPDATE_SELECTED_ITEM,
    payload: item
  });

  static openSimulation = id => ({
    type: actionType.OPEN_SIMULATION,
    payload: id
  });

  static updateForm = value => ({
    type: actionType.UPDATE_FORM,
    payload: value
  });

  static upsertProjects = projects => ({
    type: actionType.UPSERT_PROJECTS,
    payload: projects
  });

  static upsertSimulations = simulations => ({
    type: actionType.UPSERT_SIMULATIONS,
    payload: simulations
  });

  static deleteProjectsAndSimulations = items => ({
    type: actionType.DELETE_PROJECTS_AND_SIMULATIONS,
    payload: items
  });

  static deleteProject = id => ({
    type: actionType.DELETE_PROJECT,
    payload: id
  });

  static deleteSimulation = id => ({
    type: actionType.DELETE_SIMULATION,
    payload: id
  });

  static editItem = item => ({
    type: actionType.EDIT_ITEM,
    payload: item
  });

  static updateEditingValue = value => ({
    type: actionType.UPDATE_ITEM_EDITING_VALUE,
    payload: value
  });

  static applyEditedProject = project => ({
    type: actionType.APPLY_EDITED_PROJECT,
    payload: project
  });

  static applyEditedSimulation = simulation => ({
    type: actionType.APPLY_EDITED_SIMULATION,
    payload: simulation
  });

  static set = (projects, simulations, simulationOpenId) => ({
    type: actionType.SET_DIRECTORY_EXPLORER,
    payload: { projects, simulations, simulationOpenId }
  });
}
