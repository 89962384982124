import React, { Component } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import { Tooltip } from "@material-ui/core";

/**
 * A class component to use for complex dropdown select menus.
 * It's using react-material-ui's Popover, TextField & List components
 * @typedef {Component} DrilldownInput
 */
export default class DrilldownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      page: 0
    };
  }

  /**
   * onTextFieldClick ui event callback
   * @param {Object} event ui event object
   */
  onTextFieldClick = event => {
    event.preventDefault();
    const { currentTarget } = event;
    if (!this.props.disabled) {
      const selections = this.getNumOfSelections();
      this.setState({
        anchorEl: currentTarget,
        open: true,
        page: selections > 0 ? selections - 1 : 0
      });
    }
  };

  /**
   * onBackClick ui event callback
   */
  onBackClick = () => {
    const { options } = this.props,
      { page } = this.state;
    options[page].forEach(val => {
      val.isSelected = false;
    });
    this.setState({ page: page > 0 ? page - 1 : page });
  };

  /**
   * onSelect ui event callback
   * @param {Number} index selected item position index
   */
  onSelect = (index, closeAfterSelect = true) => {
    const { options, onSelect } = this.props,
      { page } = this.state;
    options[page].forEach((val, i) => {
      val.isSelected = i === index;
    });
    if (options[page][index].next) {
      this.setState({ page: page < options.length - 1 ? page + 1 : page });
    } else {
      closeAfterSelect && this.handleClose();
    }
    if (onSelect) onSelect(index);
  };

  /**
   * Popover's component onClose callback
   */
  handleClose = () => {
    const { onClose, options } = this.props;
    let tmp = [];
    let checkNext = true;
    options.forEach(arr => {
      if (arr && checkNext) {
        arr.forEach(({ text, isSelected, next }) => {
          if (isSelected) {
            tmp.push(text);
            checkNext = next;
          }
        });
      }
    });
    this.setState({ open: false });
    if (onClose) onClose(tmp.join("/"), options);
  };

  handleKeyDown = event => {
    const { key } = event;
    if (key === "ArrowUp" || key === "ArrowDown" || key === "Enter") {
      event.stopPropagation();
      this.onTextFieldClick(event);
    }
  };

  /**
   * Returns number of selected items
   * @return {Number} selections
   */
  getNumOfSelections = () => {
    const { options } = this.props;
    let numOfSelections = 0;
    options.forEach(arr => {
      if (arr) {
        arr.forEach(({ isSelected }) => {
          if (isSelected) {
            numOfSelections++;
          }
        });
      }
    });
    return numOfSelections;
  };

  render() {
    const {
        options,
        value,
        showCount,
        marginTop,
        name,
        float,
        disabled,
        onChange
      } = this.props,
      { anchorEl, open, page } = this.state,
      selections = this.getNumOfSelections(),
      maxSelections = options.length,
      list = options[page];
    return (
      <div
        style={{
          marginTop: marginTop !== undefined ? marginTop : 16,
          float: float !== undefined ? float : ""
        }}
        test-data="drilldownInput"
        name="OutputOption"
      >
        <TextField
          name={name}
          value={value}
          onClick={this.onTextFieldClick}
          onKeyDown={this.handleKeyDown}
          inputProps={{
            readOnly: true,
            style: { cursor: "pointer", padding: "6px 0 7px" }
          }}
          disabled={disabled}
          onChange={onChange}
        />
        <Popover
          open={open && list && list.length > 0}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          modifiers={{
            flip: {
              enabled: true
            }
          }}
          container={anchorEl && anchorEl.parentNode}
        >
          <div
            style={{
              minWidth: 200,
              maxHeight: 250,
              overflow: "auto"
            }}
          >
            <div style={{ padding: "10px 10px 0" }}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justify="space-between"
                style={{ height: 38 }}
              >
                <Grid item style={{ width: 30 }}>
                  {selections > 0 && options.length > 1 && (
                    <IconButton size="small" onClick={this.onBackClick}>
                      <ArrowBack />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  {showCount && (
                    <>
                      Selected {selections}/{maxSelections}
                    </>
                  )}
                  {!showCount && <>Select</>}
                </Grid>
                <Grid item style={{ width: 30 }} />
              </Grid>
            </div>

            <div>
              <List dense={true}>
                {list &&
                  list.map((item, i) => (
                    <Tooltip title={item.tooltip ? item.tooltip : ""} key={i}>
                      <ListItem
                        name="DrillDownOption"
                        key={i}
                        button
                        selected={item.isSelected}
                        disabled={item.disabled}
                        onClick={this.onSelect.bind(this, i)}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            event.stopPropagation();
                            this.onSelect.bind(this, i);
                          }
                          if (event.key === "Tab") {
                            // this.handleClose();
                          }
                        }}
                      >
                        <ListItemText primary={item.text} />
                        {item.icon}
                      </ListItem>
                    </Tooltip>
                  ))}
              </List>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

DrilldownInput.propTypes = {
  marginTop: PropTypes.number,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.any.isRequired,
        isSelected: PropTypes.bool.isRequired,
        disabled: PropTypes.bool
      })
    )
  ),
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  showCount: PropTypes.bool.isRequired
};
