import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import PropTypes from "prop-types";
import HelperUtils from "MetaCell/helper/HelperUtils";
import ReportStatus from "./components/ReportStatus/ReportStatus";

const styles = {
  root: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    border: "1px solid red"
  },
  content: {
    color: "red",
    display: "block",
    position: "relative",
    top: "40%"
  }
};

export const reloadFlag = "PlanOpSimReloadAttempts";

/**
 * class component created to wrap up other components and catch render errors. it can handle the error
 * and display something else instead.
 */
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      reportSent: null
    };
  }

  /**
   * it refreshes the page up to 3 times if an error is caught,
   * an only sets the error state, logs the error,runs the error handler and
   * ask the user if they want to report the bug if it still fails after the 3rd refresh attempt.
   * @param {Object} error - the error
   * @param {Object} info - the info
   */
  componentDidCatch(error, info) {
    let totalReloadAttempts = localStorage.getItem(reloadFlag);
    if (totalReloadAttempts === null) {
      localStorage.setItem(reloadFlag, 0);
    }

    totalReloadAttempts = parseInt(localStorage.getItem(reloadFlag));
    if (totalReloadAttempts < 3) {
      localStorage.setItem(reloadFlag, totalReloadAttempts + 1);
      window.location.reload();
    } else {
      const { onError } = this.props;
      this.setState({ error });
      console.log(error);
      onError && onError();
      const onReportSuccess = () => this.setState({ reportSent: true });
      const onReportFailure = () => this.setState({ reportSent: false });
      HelperUtils.offerUserToReportError(
        error,
        this.props.description,
        onReportSuccess,
        onReportFailure
      );
    }
  }

  render() {
    const totalReloadAttempts = localStorage.getItem(reloadFlag),
      { error, reportSent } = this.state,
      hasErrorAndRefreshingThePageDidNotWork =
        error && totalReloadAttempts == 3;
    return hasErrorAndRefreshingThePageDidNotWork ? (
      <div test-data="errorComponent" style={styles.root}>
        <div style={styles.content}>
          <ErrorIcon />
          <Typography>
            {" "}
            {this.props.title || "Something went wrong."}
          </Typography>
        </div>
        <ReportStatus sent={reportSent} />
      </div>
    ) : (
      this.props.children
    );
  }
}

ErrorBoundary.propTypes = {
  /**
   * optional error handler.
   */
  onError: PropTypes.func,
  /**
   * optional label to show with the error sign.
   */
  title: PropTypes.string,
  /**
   * optional message to show in the dialog.
   */
  description: PropTypes.string
};

/**
 * Higher order component
 * @param {Component} Component - the component to be enhanced
 * @returns {Component} enhanced component
 * @author Akira Kotsugai
 */
export const withErrorBoundary = Component => {
  /**
   * it defines the enhanced layer that is going to wrap the original component and returned instead.
   * @param {Object} props - the props passed from parent components
   * @return {Component} - the original component wrapped by the error catcher component.
   */
  const NewComponent = props => {
    return (
      <ErrorBoundary
        onError={props.onRenderError}
        title={props.errorTitle}
        description={props.errorDescription}
      >
        <Component {...props} />
      </ErrorBoundary>
    );
  };

  return NewComponent;
};
