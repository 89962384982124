import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import HelperUtils from "MetaCell/helper/HelperUtils";
import Helper from "MetaCell/helper/Plotbox";
import GenericHeatmap from "components/Heatmap/Heatmap";

class Simulation3DPlot extends PureComponent {
  getZData = rangeValue => {
    const { zData } = this.props;
    var finalZData = zData;
    if (rangeValue?.length > 0 && finalZData) {
      rangeValue.forEach(value => {
        finalZData = finalZData[value - 1];
      });
      return finalZData;
    }
    return finalZData ? finalZData[0] : [];
  };

  getAnnotations = (zData) => {
    const { selectedPoints } = this.props;
    var annotations = [];
    selectedPoints.forEach(point => {
      let pointXIndex = point.pointIndex[0];
      let pointYindex = point.pointIndex[1];
      if (zData[pointXIndex][pointYindex] && Number(zData[pointXIndex][pointYindex]) == point.z) {
        annotations.push({
          x: point.x,
          y: point.y,
          showarrow: true,
          arrowhead: 7,
          ax: 0,
          ay: -10
        })
      }
    })
    return annotations;
  };

  render() {
    const {
      width,
      xData,
      xName,
      xUnit,
      yData,
      yName,
      yUnit,
      zData,
      zName,
      zUnit,
      rangeValue,
      xAxisRange,
      yAxisRange,
      selectedPoints
      // zDataRange,
    } = this.props;
    const zDataRange = this.props.zDataRange
      ? this.props.zDataRange
      : HelperUtils.getMatrixRange(this.getZData(rangeValue));
    return (
      <div style={{ width }}>
        <div
          style={{
            marginLeft:
              (width - Helper.getHeatmapWidth((width / 6) * 5, xData, yData)) /
              2
          }}
        >
          <GenericHeatmap
            onHeatmapClick={this.props.handleResultSelection}
            onGetUnwrappedOutputData={this.props.onGetUnwrappedOutputData}
            title={HelperUtils.getAxisName(zName, zUnit)}
            width={Helper.getHeatmapWidth((width / 6) * 5, xData, yData)}
            height={Helper.getHeatmapHeight((width / 6) * 5, xData, yData)}
            x={xData}
            y={yData}
            z={HelperUtils.limitDecimals(
              Helper.rotateMatrixAntiCWandFlip(this.getZData(rangeValue)),
              3
            )}
            annotations={selectedPoints ? this.getAnnotations(HelperUtils.limitDecimals(
              Helper.rotateMatrixAntiCWandFlip(this.getZData(rangeValue)),
              3
            )) : []}
            xLabel={HelperUtils.getAxisName(xName, xUnit)}
            yLabel={HelperUtils.getAxisName(yName, yUnit)}
            disableSideView={this.props.disableSideView}
            enableCrossSection={this.props.enableCrossSection}
            handleCrossSection={this.props.handleCrossSection}
            crossSectionOrientation={this.props.crossSectionOrientation}
            crossSectionIndex={this.props.crossSectionIndex}
            crossSectionRowsCount={this.props.crossSectionRowsCount}
            crossSectionColumnsCount={this.props.crossSectionColumnsCount}
            crossSection3DUnit={this.props.crossSection3DUnit}
            crossSectionColorbarUnit={this.props.crossSectionColorbarUnit}
            toggleProbeSliderVisibility={this.props.toggleProbeSliderVisibility}
            xAxisRange={xAxisRange}
            yAxisRange={yAxisRange}
            zmin={zDataRange[0]}
            zmax={zDataRange[1]}
          />
        </div>
      </div>
    );
  }
}

Simulation3DPlot.propTypes = {
  width: PropTypes.number.isRequired,
  xData: PropTypes.arrayOf(PropTypes.number).isRequired,
  xName: PropTypes.string.isRequired,
  xUnit: PropTypes.string.isRequired,
  yData: PropTypes.arrayOf(PropTypes.number).isRequired,
  yName: PropTypes.string.isRequired,
  yUnit: PropTypes.string.isRequired,
  zData: PropTypes.arrayOf(PropTypes.number).isRequired,
  zName: PropTypes.string.isRequired,
  zUnit: PropTypes.string.isRequired,
  rangeValue: PropTypes.number.isRequired
};

export default Simulation3DPlot;
