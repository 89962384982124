/**
 * @constant
 * @typedef {Object} DesignTargetActionType
 * @property {String} DELETE_DESIGN_TARGET - tells the reducer to delete a design target
 * @property {String} UPSERT_DESIGN_TARGETS - tells the reducer to insert or update design targets
 * @property {String} SET_DESIGN_TARGET_NF_WAVEFRONT - tells the reducer to set the design target's wavefront
 * @global
 * it defines design target's type of actions.
 */
export const actionType = Object.freeze({
  SET_DESIGN_TARGETS: "SET_DESIGN_TARGETS",
  DELETE_DESIGN_TARGET: "DELETE_DESIGN_TARGET",
  UPSERT_DESIGN_TARGETS: "UPSERT_DESIGN_TARGETS",
  SET_DESIGN_TARGET_NF_WAVEFRONT: "SET_DESIGN_TARGET_NF_WAVEFRONT"
});

/**
 * A class created to create design target actions to be dispatched to redux. It is already tested with
 * Design Target reducer, so it does not need to be unit tested.
 */
export default class DesignTargetAction {
  static set = value => ({
    type: actionType.SET_DESIGN_TARGETS,
    payload: value
  });

  static delete = designTargetId => ({
    type: actionType.DELETE_DESIGN_TARGET,
    payload: designTargetId
  });

  static upsert = value => ({
    type: actionType.UPSERT_DESIGN_TARGETS,
    payload: value
  });

  static setNFWavefront = (id, wavefront) => ({
    type: actionType.SET_DESIGN_TARGET_NF_WAVEFRONT,
    payload: {
      id,
      wavefront
    }
  });
}
