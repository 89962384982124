import uuid from "uuid/v4";

export let workers = [];

export function createPool() {
  try {
    const maxWorkers = navigator.hardwareConcurrency || 4;
    for (let i = 0; i < maxWorkers; i++) {
      const instance = new Worker(`${process.env.PUBLIC_URL}/static/worker.js`);
      instance.onmessage = handleMessageFromWorker;
      workers.push({ id: uuid(), instance, callbacks: {} });
    }
    const pyodideInstance = new Worker(
      `${process.env.PUBLIC_URL}/static/pyodideWorker.js`
    );
    pyodideInstance.onmessage = handleMessageFromWorker;
    workers.push({ id: "PYODIDE", instance: pyodideInstance, callbacks: {} });
  } catch {}
}

export function destroyPool() {
  workers.forEach(({ instance }) => {
    instance.terminate();
  });
}

export function sendMessage({ action, params, workerId }, callback, opt) {
  const worker = getWorker(workerId),
    messageId = uuid();
  worker.callbacks[messageId] = callback;
  worker.instance.postMessage({ action, params, messageId }, opt);
  return { workerId: worker.id, messageId };
}

export async function handleMessageFromWorker({ data }) {
  const response = data.response.data;
  const { messageId } = data.response;
  const worker = workers.find(worker => worker.callbacks[messageId]);
  if (worker) {
    const callback = worker.callbacks[messageId];
    await callback(response);
    delete worker.callbacks[messageId];
  }
}

export function getWorker(workerId) {
  if (workerId) {
    return workers.find(({ id }) => id === workerId);
  }
  let value = Object.keys(workers[0].callbacks).length,
    worker = workers[0];
  for (let i = 1; i < workers.length; i++) {
    const callbacksLength = Object.keys(workers[i].callbacks).length;
    if (callbacksLength < value) {
      value = callbacksLength;
      worker = workers[i];
    }
  }
  return worker;
}
