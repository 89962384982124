import React, { PureComponent } from "react";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import EditActions from "components/EditActions/EditActions";
import SetPointForm from "./components/SetPointForm/SetPointForm";
import { isEqual } from "lodash";

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  title: {
    float: "left"
  }
});

/**
 * a component to show information about the selected set point
 * @author Akira Kotsugai
 */
export class SetPoint extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditingSetPoint: false
    };
  }

  componentDidMount() {
    this.initialize();
  }

  /**
   * it supposely takes the formik submitter and assign it to this component, so that
   * the formik submitter can be accessed from outside the formik component.
   * @param {*} submitFormFunction - the formik function that submits the form
   * @callback
   */
  bindSubmitForm = submitFormFunction => {
    this.formSubmitter = submitFormFunction;
  };

  /**
   * it supposely takes the formik resetter and assign it to this component, so that
   * the formik resetter can be accessed from outside the formik component.
   * @param {*} resetFormFunction - the formik function that submits the form
   * @callback
   */
  bindResetForm = resetFormFunction => {
    this.formResetter = resetFormFunction;
  };

  /**
   * every time the active set point changes, we restart the component state,
   * because a different set point is active.
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.setPoint, this.props.setPoint)) {
      this.initialize();
    }
  }

  /**
   * when initializing the component, we should make sure that it is not on edit mode
   * unless we are creating a brand new set point.
   */
  initialize() {
    const isCreatingNew = this.props.setPoint === null;
    if (!isCreatingNew) {
      this.cancelEditingSetPoint();
    }
  }

  /**
   * it saves the editing SetPoint and leaves the edit mode
   * @param {Object} formEditingValues - the form values.
   */
  save = formEditingValues => {
    this.props.onSave(formEditingValues);
    this.cancelEditingSetPoint();
  };

  /**
   * it changes the editing state to true
   */
  editSetPoint = () => {
    this.setState({
      isEditingSetPoint: true
    });
  };

  /**
   * it changes the editing state to false and calls the formik resetter
   */
  cancelEditingSetPoint = () => {
    this.setState({
      isEditingSetPoint: false
    });
    this.formResetter();
  };

  render() {
    const { classes, setPoint } = this.props,
      { isEditingSetPoint } = this.state,
      isCreatingNew = setPoint === null;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={3}>
            {isCreatingNew && (
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 100, display: "flex" }}>
                  <Typography
                    className={classes.title}
                    variant="h5"
                    component="h3"
                  >
                    {"Create New"}
                  </Typography>
                </div>
                <EditActions
                  isEditing={true}
                  isSaving={false}
                  onEdit={this.editSetPoint}
                  onSave={() => {
                    this.formSubmitter();
                  }}
                />
              </div>
            )}
            <div style={{ width: 400, margin: "auto" }}>
              <SetPointForm
                isEditing={isEditingSetPoint}
                setPoint={setPoint}
                bindSubmitForm={this.bindSubmitForm}
                bindResetForm={this.bindResetForm}
                onSubmit={this.save}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SetPoint);
