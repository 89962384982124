/**
 * @constant
 * @typedef {Object} DesignActionType
 * @property {String} UPSERT_ANALYSIS_JOBS - tells the reducer that to update the analysis tasks in the store
 * @property {String} SELECT_ANALYSIS_JOB - tells the reducer to select a analysis task in the store
 * @property {String} RESET_ANALYSIS_JOBS - tells the reducer to reset jobs
 * @property {String} UPDATE_TEMP_ANALYSIS_VARIABLES - tells the reducer to update the variables
 * @property {String} DELETE_ANALYSIS_JOBS - tells the reducer to delete analysis jobs
 * @property {String} UPDATE_OVERVIEW_FILTERS - tells the reducer to update the  analysis job overview filters
 * @property
 * @global
 * it defines analysis' type of actions.
 */
export const actionType = Object.freeze({
  UPSERT_ANALYSIS_JOBS: "UPSERT_ANALYSIS_JOBS",
  SELECT_ANALYSIS_JOB: "SELECT_ANALYSIS_JOB",
  RESET_ANALYSIS_JOBS: "RESET_ANALYSIS_JOBS",
  UPDATE_TEMP_ANALYSIS_VARIABLES: "UPDATE_TEMP_ANALYSIS_VARIABLES",
  SET_ANALYSIS_JOBS: "SET_ANALYSIS_JOBS",
  DELETE_ANALYSIS_JOBS: "DELETE_ANALYSIS_JOBS",
  UPDATE_OVERVIEW_FILTERS: "UPDATE_ANALYSIS_OVERVIEW_FILTERS"
});

/**
 * A class created to create analysis actions to be dispatched to redux. It is already tested with
 * analysis reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link DesignActionType} and some data.
 */
export default class DesignAction {
  static updateOverviewFilters = filters => ({
    type: actionType.UPDATE_OVERVIEW_FILTERS,
    payload: filters
  });

  static set = jobs => ({
    type: actionType.SET_ANALYSIS_JOBS,
    payload: jobs
  });

  static upsertJobs = jobs => ({
    type: actionType.UPSERT_ANALYSIS_JOBS,
    payload: jobs
  });
  static selectJob = id => ({
    type: actionType.SELECT_ANALYSIS_JOB,
    payload: id
  });
  static resetJobs = () => ({
    type: actionType.RESET_ANALYSIS_JOBS,
    payload: {}
  });
  static updateTempVariables = newVariables => ({
    type: actionType.UPDATE_TEMP_ANALYSIS_VARIABLES,
    payload: newVariables
  });
  static deleteJobs = jobIds => ({
    type: actionType.DELETE_ANALYSIS_JOBS,
    payload: jobIds
  });
}
