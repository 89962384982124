/**
 * A functional component created to be the root page content for Library.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components 
 */
function RootCanvas(props) {

    return null;
}

export default RootCanvas;