import { actionType } from "MetaComponent/actions/DesignTarget";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * @typedef {Object} DesignTargetDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    designTargets: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */

export const testDesignTargets = {
  byId: {
    1: {
      id: 1,
      name: "test target",
      description: "",
      NFWidth: 300,
      NFHeight: 350,
      order_out: [3, 3],
      polarization_out: "TE",
      direction_out: "Transmission",
      optimize_for: "amplitude",
      FFWFTarget: 1,
      set_point: 1,
      diffractive_order: {
        X: 1,
        Y: 1
      },
      unit: "mm",
      wave_front_design_script: 1
    },
    2: {
      id: 2,
      name: "AAAAA",
      description: "FDSAFDSA",
      NFWidth: 200.0,
      NFHeight: 100.0,
      order_out: [0.0, 0.0],
      polarization_out: "TE",
      direction_out: "Transmission",
      optimize_for: "amplitude",
      diffractive_order: {
        X: 1,
        Y: 1
      },
      FFWFTarget: null,
      set_point: null,
      unit: "μm",
      wave_front_design_script: 1
    },
    3: {
      id: 3,
      name: "BBBBBB",
      description: "bbbbb",
      NFWidth: 300000.0,
      NFHeight: 350000.0,
      NFWaveFront: [[[1]], [[1]]],
      order_out: [0.0, 0.0],
      polarization_out: "TE",
      direction_out: "Transmission",
      optimize_for: "amplitude",
      diffractive_order: {
        X: 1,
        Y: 1
      },
      FFWFTarget: 2,
      set_point: null,
      unit: "μm",
      wave_front_design_script: 1
    },
    4: {
      id: 4,
      name: "DESIGN TARGET 4",
      description: "bbbbbFDSA",
      NFWidth: 300000.0,
      NFHeight: 350000.0,
      NFWaveFront: [[[1]], [[1]]],
      order_out: [0.0, 0.0],
      polarization_out: "TE",
      direction_out: "Transmission",
      optimize_for: "amplitude",
      diffractive_order: {
        X: 1,
        Y: 1
      },
      FFWFTarget: 1,
      set_point: null,
      unit: "μm",
      wave_front_design_script: 1
    },
    5: {
      id: 5,
      name: "DESIGN TARGET 5",
      description: "bbbbbFDSA",
      NFWidth: 300000.0,
      NFHeight: 350000.0,
      NFWaveFront: [[[1]], [[1]]],
      order_out: [0.0, 0.0],
      polarization_out: "TE",
      direction_out: "Transmission",
      optimize_for: "amplitude",
      diffractive_order: {
        X: 1,
        Y: 1
      },
      FFWFTarget: null,
      set_point: null,
      unit: "μm",
      wave_front_design_script: 1
    }
  },
  allIds: ["1", "2", "3", "4", "5"],
  loaded: true
};

/**
 * Reducer function to manipulate the state of design target
 * @param {Object} [state=DesignTargetDefaultState] - design target entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_DESIGN_TARGETS: {
      let designTargets = cloneDeep(defaultState.entities.designTargets);
      Utils.addOrUpdateEntities(payload, designTargets, [
        "wave_front_design_script"
      ]);
      return {
        ...state,
        entities: { ...state.entities, designTargets }
      };
    }

    case actionType.UPSERT_DESIGN_TARGETS: {
      let designTargets = cloneDeep(state.entities.designTargets);
      Utils.addOrUpdateEntities(payload, designTargets, [
        "wave_front_design_script"
      ]);
      // design targets dont contain the NFWaveFront,
      // it is the wavefront component that fetches the wavefront
      // so we delete potentially old NFWaveFronts
      for (const designTarget of payload) {
        delete designTargets.byId[designTarget.id].NFWaveFront;
      }
      return {
        ...state,
        entities: { ...state.entities, designTargets }
      };
    }

    case actionType.DELETE_DESIGN_TARGET: {
      let designTargets = cloneDeep(state.entities.designTargets);
      Utils.deleteEntities([payload], designTargets);
      return {
        ...state,
        entities: { ...state.entities, designTargets }
      };
    }

    case actionType.SET_DESIGN_TARGET_NF_WAVEFRONT: {
      let designTargets = cloneDeep(state.entities.designTargets);
      designTargets.byId[payload.id] = {
        ...designTargets.byId[payload.id],
        NFWaveFront: payload.wavefront
      };
      return {
        ...state,
        entities: { ...state.entities, designTargets }
      };
    }

    default:
      return state;
  }
}
