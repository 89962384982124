import Axios from "axios";
import Action from "MetaCell/actions/SimulationSettings";
import { cloneDeep } from "lodash";
import GenericApi from "Api";

/**
 * A class created to perform http operations related to simulation settings' entities.
 * @author Akira Kotsugai
 * TODO: extract the similarities it has with GlobalParametersApi and IncidentLightApi
 */
export default class SimulationSettingsApi {
  /**
   * it generates a url to fetch maximum diffraction orders by a given simulation
   */
  static getMaxDiffractionOrdersBySimulationUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/diffractionorders/?simulation=${simulationId}`;

  /**
   * it generates the url to the maximum diffraction orders api to manipulate
   * the given maximum diffraction orders.
   *@param id - the maximum diffraction orders id
   */
  static getMaxDiffractionOrdersUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/diffractionorders/${id}`;

  /**
   * it can fetch the simulation's maximum diffraction orders. If it is a brand new simulation
   * it will create the record in the database.
   * @param {Number} simulationId - the open simulation's id
   * @return a function that receive a dispatcher to redux
   */
  static fetchMaxDiffractionOrders = simulationId => {
    return dispatch => {
      const bySimulationUrl = SimulationSettingsApi.getMaxDiffractionOrdersBySimulationUrl(
        simulationId
      );
      return Axios.get(bySimulationUrl)
        .then(res => res.data)
        .then(maxDiffractionOrders => {
          if (maxDiffractionOrders.length > 0)
            dispatch(Action.setMaxDiffractionOrders(maxDiffractionOrders));
          else {
            const url = SimulationSettingsApi.getMaxDiffractionOrdersUrl();
            const maxDiffractionOrders = {
              id: null,
              X: "4",
              Y: "4",
              simulation: simulationId
            };
            return Axios.post(url, maxDiffractionOrders)
              .then(res => res.data)
              .then(newEntity =>
                dispatch(Action.setMaxDiffractionOrders([newEntity]))
              );
          }
        })
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };

  /**
   * it can save the existing maximum diffraction orders. we don't always want to update the state
   * with the saved entity like when we are opening another simulation after the save,
   * that's why we dont dispatch any action.
   * @param {Object} maxDiffractionOrders - the maximum diffraction orders object
   * @return {Function} a function that is dispatchable to redux
   */
  static putMaxDiffractionOrders = maxDiffractionOrders => {
    return dispatch => {
      const url = SimulationSettingsApi.getMaxDiffractionOrdersUrl(
        maxDiffractionOrders.id
      );
      return Axios.put(url, maxDiffractionOrders)
        .then(({ data }) => {
          dispatch(Action.updateMaxDiffractionOrder(data));
        })
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };

  static getSweptVariables = (simulationId = "") => {
    return dispatch => {
      Axios.get(
        `${GenericApi.getBaseUrl()}/sweptvariables/?simulation=${simulationId}`
      )
        .then(({ data }) => {
          dispatch(Action.setSweptVariables(data));
        })
        .catch(err => {
          console.log(err);
        });
    };
  };

  static getSweepVariableUrl = obj => {
    var postUrl = null;
    if (obj.sweepType == "Formula")
      postUrl = `${GenericApi.getBaseUrl()}/dependentsweeps/`;
    else postUrl = `${GenericApi.getBaseUrl()}/sweptvariables/`;
    return postUrl;
  };

  static addSweptVariable = obj => {
    return dispatch => {
      const postUrl = this.getSweepVariableUrl(obj);
      return Axios.post(postUrl, obj).then(({ data }) =>
        dispatch(Action.addSweptVariable(data))
      );
    };
  };

  static removeSweptVariable = id => {
    return dispatch => {
      return Axios.delete(
        `${GenericApi.getBaseUrl()}/sweptvariables/${id}`
      ).then(() => {
        dispatch(Action.removeSweptVariable(id));
      });
    };
  };

  static updateSweptVariable = (id, obj) => {
    return dispatch => {
      const postUrl = this.getSweepVariableUrl(obj);
      return Axios.patch(`${postUrl}${id}`, obj).then(({ data }) =>
        dispatch(Action.updateSweptVariable(data))
      );
    };
  };

  static getProbes = (simulationId = "") => {
    const url =
      simulationId === ""
        ? `${GenericApi.getBaseUrl()}/probes/`
        : `${GenericApi.getBaseUrl()}/probes/?simulation=${simulationId}`;
    return dispatch => {
      return Axios.get(url).then(({ data }) => {
        const filteredProbes = data.filter(
          ({ simulation }) => simulation === simulationId
        );
        return dispatch(Action.setProbes(filteredProbes));
      });
    };
  };

  static postProbe = obj => {
    return dispatch => {
      Axios.post(`${GenericApi.getBaseUrl()}/probes/`, obj)
        .then(({ data }) => {
          dispatch(Action.addFieldProbe(data));
        })
        .catch(() => {});
    };
  };

  static patchProbe = (id, obj) => {
    return dispatch => {
      Axios.patch(`${GenericApi.getBaseUrl()}/probes/${id}`, obj).then(
        ({ data }) => {
          dispatch(Action.changeFieldProbe(id, data));
        }
      );
    };
  };

  static deleteProbe = id => {
    return dispatch => {
      dispatch(Action.deleteFieldProbe(id));
      Axios.delete(`${GenericApi.getBaseUrl()}/probes/${id}`);
    };
  };
}
