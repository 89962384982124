import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import PropTypes from "prop-types";
import SweepPoint from "components/SweepPoint/SweepPoint";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * This component was created to help users understand how variable assignments work
 * @author Akira Kotsugai
 */
export class VariableAssignmentExamplesDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sweepPoint: { radius: 150 }
    };
  }
  /**
   * it can be passed to child components and tells redux to close the dialog
   * @callback
   */
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const assignmentExamples = [
      "=radius",
      "=radius + 5",
      "=radius - 5",
      "=500 / radius",
      "=radius * 4",
      "=(3 - 2) * 5 + radius / 2",
      "=radius + 25%",
      "=radius - (2 ^ 3)",
      "=8 % 3 + radius",
      "=5! + radius",
      "=radius - sqrt(25)",
      "=radius + log(25)",
      "=radius - pi / 4",
      "=sin(0.78539816339) + radius",
      "=radius / sin(45 deg)",
      "=radius + cos(45 deg)",
      "=radius - tan(45 deg)"
    ];
    return (
      <UnselfishDialog
        open={this.props.open}
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
            <IconButton
              test-data="closeBtn"
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 50 }}>
          <Typography test-data={"matrixExplanation"} variant="caption">
            {`A simulation is run for each value of a swept variable.
                            Not only a variable can be assigned to multiple simulation parameters,
                            But multiple parameters can have variations based on the same swept variable.
                            Below you can find examples of assignments having variations based on the same variable.
                        `}
          </Typography>
          <SweepPoint
            sweptVariables={[{ name: "radius", values: [100, 150, 200] }]}
            sweptVariableValues={this.state.sweepPoint}
            setSelectedValue={sweepPoint => this.setState({ sweepPoint })}
          />
          <EnhancedMaterialTable
            slim
            disableSelectionHighlight
            title="Materials"
            options={{
              search: false,
              paging: false,
              sorting: false,
              draggable: false,
              toolbar: false
            }}
            columns={[
              { title: "Assignment example", field: "assignment" },
              { title: "Value used in the simulation", field: "finalValue" }
            ]}
            data={assignmentExamples.map(assignmentExample => ({
              assignment: assignmentExample,
              finalValue: HelperUtils.resolveMathExpression(
                assignmentExample.substring(1),
                this.state.sweepPoint
              )
            }))}
            localization={{
              header: {
                actions: ""
              }
            }}
          />
        </div>
      </UnselfishDialog>
    );
  }
}

export default withErrorBoundary(
  withStyles(styles)(VariableAssignmentExamplesDialog)
);

VariableAssignmentExamplesDialog.propTypes = {
  /**
   * expects a close handler
   */
  onClose: PropTypes.func.isRequired,
  /**
   * whether the dialog should be open
   */
  open: PropTypes.bool.isRequired
};
