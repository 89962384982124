import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress, Button } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";

export const styles = {
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    width: "100%"
  }
};

export class ModeAnalysisJobProgress extends PureComponent {
  render() {
    const { classes, selectedJob } = this.props;
    return (
      <div className={classes.progress}>
        {selectedJob && (
          <Typography>Mode Analysis status: {selectedJob.status}</Typography>
        )}
        <div className={classes.wrapper}>
          <Spinner name="Waiting" size={68} />
        </div>
        <div className={classes.wrapper}>
          {selectedJob && (
            <Typography>{`${selectedJob.progress} %`}</Typography>
          )}
        </div>
        <br />
        {selectedJob && (
          <>
            <LinearProgress
              name="SimulationProgress"
              variant="determinate"
              value={selectedJob.progress && selectedJob.progress}
            />
            {(selectedJob.status === "RUNNING" ||
              selectedJob.status === "QUEUED") && (
              <div
                style={{
                  textAlign: "center",
                  position: "relative",
                  marginTop: 20
                }}
              >
                <Button
                  test-data="stopBtn"
                  variant="contained"
                  onClick={() => this.props.stopJob(selectedJob.id)}
                >
                  Stop Mode Analysis
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ModeAnalysisJobProgress);
