import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { isEqual } from "lodash";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { GenericWavefront } from "components/GenericWavefront/GenericWavefront";
import OdaApi from "MetaComponent/api/ODA";
import Spinner from "components/Spinner/Spinner";
import SweepPoint from "components/SweepPoint/SweepPoint";
import HelperUtils from "MetaCell/helper/HelperUtils";

export const styles = {
  root: {
    marginBottom: 40,
    paddingRight: 260
  },
  title: {
    textAlign: "center"
  },
  centerElement: {
    display: "block",
    margin: "auto"
  }
};

export class OdaJobResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sweptVariableValues: {},
      oda_nfwf: null,
      lpa_nfwf: null,
      difference_wf: null,
      loadingResult: false
    };
  }

  componentDidMount() {
    this.setSelectedValues();
    this.getResult();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.sweptVariables, this.props.sweptVariables)) {
      this.setSelectedValues();
    }
  }

  isMultipleResult() {
    return this.props.sweptVariables.length > 0;
  }

  /**
   * sets in the state the first value of each swept variable
   */
  setSelectedValues() {
    let sweptVariableValues = {};
    for (const variable of this.props.sweptVariables) {
      sweptVariableValues[variable.name] = variable.values[0];
    }
    this.setState({ sweptVariableValues });
  }

  /**
   * it updates a variable's selected value in the state
   * @param {*} sweptVariableValues - the updated variable values
   */
  setSelectedValue = sweptVariableValues => {
    this.setState({ sweptVariableValues }, this.getResult);
  };

  /**
   * @param {String} params - the start, stop and step parameters divided by separators
   * @returns {Number[]} - an array containing all parameters
   */
  splitVariableParams = params => {
    const splittedParameters = params.split(/,|;|:/);
    return splittedParameters.map(parameter => parseFloat(parameter));
  };

  ifGeneralSweepUsedParameter = sv => {
    const { outgoingConditions, setPoint } = this.props;
    const order_out_x = outgoingConditions.order_out[0],
      order_out_y = outgoingConditions.order_out[1],
      diffractive_order_x = setPoint.diffractive_order["X"],
      diffractive_order_y = setPoint.diffractive_order["Y"],
      {
        amplitude,
        azimut,
        beam_divergence,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        incident_light_type,
        wavelength,
        zenit
      } = setPoint.incident_light,
      general_parameters = [
        zenit,
        azimut,
        wavelength,
        amplitude,
        diffractive_order_x,
        diffractive_order_y,
        order_out_x,
        order_out_y
      ],
      gb_parameters = [
        beam_divergence,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z
      ];
    if (incident_light_type == "PW") {
      return general_parameters.some(
        param =>
          param?.toString().includes("=") &&
          param.toString().split("=")[1] == sv.name
      );
    } else {
      // GB
      return (
        general_parameters.some(
          param =>
            param?.toString().includes("=") &&
            param.toString().split("=")[1] == sv.name
        ) ||
        gb_parameters.some(
          param =>
            param?.toString().includes("=") &&
            param.toString().split("=")[1] == sv.name
        )
      );
    }
  };

  /**
   * it shows a spinner, gets the result and hides the spinner again.
   * farfield wavefront has to be wrapped in a matrix because the wavefront component expects
   * 2 matrices.
   */
  getResult = async () => {
    this.setState({
      loadingResult: true
    });
    const result = await OdaApi.getOdaJobResult(
        this.props.jobId,
        this.state.sweptVariableValues
      ),
      { oda_nfwf, lpa_nfwf, difference_wf, rmse } = result.results[0];
    let newState = {
      loadingResult: false,
      oda_nfwf,
      rmse,
      lpa_nfwf,
      difference_wf
    };
    // newState.difference_wf = difference_wf ? [difference_wf[0], "fake difference phase"] : null;
    this.setState(newState);
  };

  /**
   * @returns {Boolean} whether variables were used in the analysis
   */
  isMultipleResult() {
    return this.props.sweptVariables.length > 0;
  }

  render() {
    const { classes, sweptVariables, width, height, unit } = this.props,
      { oda_nfwf, rmse, loadingResult, lpa_nfwf, difference_wf } = this.state,
      centerGridStyle = { margin: "auto" };
    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5" component="h3">
          {"Result"}
        </Typography>

        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={5}>
            <Grid container style={{ width: "60%", margin: "auto" }}>
              {this.isMultipleResult() && (
                <Grid item xs={12}>
                  <SweepPoint
                    sweptVariables={sweptVariables.filter(sv =>
                      this.ifGeneralSweepUsedParameter(sv)
                    )}
                    sweptVariableValues={this.state.sweptVariableValues}
                    setSelectedValue={this.setSelectedValue}
                    onChangeCommitted={() => {
                      this.getResult();
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                {loadingResult && (
                  <Spinner
                    size={25}
                    className={classes.centerElement}
                    timeout={30000}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {lpa_nfwf && (
                <Grid
                  item
                  xs={4}
                  style={centerGridStyle}
                  test-data={"lpa_nfwf"}
                >
                  <GenericWavefront
                    title={"LPA"}
                    wavefront={lpa_nfwf}
                    wfWidth={width * (1 - 1 / lpa_nfwf[0][0].length)} //width is full component, but visualized at center of component
                    wfHeight={height * (1 - 1 / lpa_nfwf[0].length)}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    unit={unit}
                    showLegend
                  />
                </Grid>
              )}
              {oda_nfwf && (
                <Grid
                  item
                  xs={4}
                  style={centerGridStyle}
                  test-data={"oda_nfwf"}
                >
                  <GenericWavefront
                    title={"ODA"}
                    wavefront={oda_nfwf}
                    wfWidth={width * (1 - 1 / oda_nfwf[0][0].length)} //width is full component, but visualized at center of component
                    wfHeight={height * (1 - 1 / oda_nfwf[0].length)}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    unit={unit}
                    showLegend
                  />
                </Grid>
              )}
              {difference_wf && (
                <Grid
                  item
                  xs={4}
                  style={centerGridStyle}
                  test-data={"difference_wf"}
                >
                  <GenericWavefront
                    title={"LPA - ODA"}
                    wavefront={difference_wf}
                    wfWidth={width * (1 - 1 / difference_wf[0][0].length)} //width is full component, but visualized at center of component
                    wfHeight={height * (1 - 1 / difference_wf[0].length)}
                    //                     rowsCount={100}
                    //                     columnsCount={100}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    unit={unit}
                    showLegend
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {rmse && (
              <Typography className={classes.title} component="p">
                {`RMSE: ${HelperUtils.limitDecimals(rmse, 3)}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(OdaJobResult));
