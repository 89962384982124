import React, { PureComponent } from "react";
import MaterialTable from "components/MaterialTable/src/index";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import FFWTTargetSelector from "MetaComponent/selectors/FFWTTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import FFWFTargetVisualisation from "../FFWFTargetVisualisation/FFWFTargetVisualisation";
import Script2DApi from "MetaComponent/api/Script2D";
import Script2DSelector from "MetaComponent/selectors/Script2D";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import HelperUtils from "MetaCell/helper/HelperUtils";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box"
  }
};

const columns = [
  {
    title: "Name",
    field: "name",
    customSort: (a, b) => HelperUtils.caseInsensitiveSort(a.name, b.name)
  },
  {
    title: "Description",
    field: "description"
  },
  {
    title: "Size",
    field: "size"
  },
  {
    title: "Center coord",
    field: "centerCoord"
  },
  {
    title: "Euler angles",
    field: "eulerAngles"
  }

  // to be enabled when implementing the far-field download functionality
  // {
  //   title: "",
  //   field: "download",
  //   editable: "never",
  //   render: rowData => (
  //     <Tooltip title="Download" placement="top">
  //       <IconButton
  //         test-data="exportBtn"
  //         name="TreeExportButton"
  //         onClick={() => {}}
  //       >
  //         <SaveAltIcon />
  //       </IconButton>
  //     </Tooltip>
  //   )
  // }
];

/**
 * A component to show all ffwftargets the user has permission to see.
 */
export class FFWFTargets extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tableRef = React.createRef();
    this.expandedTargets = [];
    this.editedTargetId = null;
  }

  /**
   * it fetches the ffwftargets as soon as the component mounts if they were not loaded already.
   */
  componentDidMount() {
    const {
      ffwftargets,
      fetchFFWFTargetsAction,
      fetchScript2DsAction
    } = this.props;
    this.expandedTargets = [];
    if (ffwftargets.loaded === false) fetchFFWFTargetsAction();
    fetchScript2DsAction();
  }

  /**
   *
   * it maps the targets data to table columns
   */
  mapDataToColumns(targets) {
    return targets.map(target => {
      return {
        name: target.name,
        description: target.description,
        size: `${target.WFWidth} ${target.unit} x ${target.WFHeight} ${target.unit}`,
        centerCoord: `(${target.center_coord.join("; ")})`,
        eulerAngles: `(${target.euler_Angles.join("; ")})`,
        // download: true,
        id: target.id,
        WFWidth: target.WFWidth,
        WFHeight: target.WFHeight
      };
    });
  }

  /**
   * @callback
   * @param {Object} ffwftarget - the ffwftarget being deleted
   * @returns {Promise} a promise that calls the delete ffwftarget action
   */
  onRowDelete = ffwftarget => {
    const { deleteFFWFTargetAction } = this.props;
    return new Promise((resolve, reject) => {
      deleteFFWFTargetAction(ffwftarget.id)
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  onRowUpdate = (newData, oldData) => {
    // TODO: implement update functionality
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  // keep the list of the expanded targets to handle the row expand/collapse state correctly
  onShowTargetVisualisation = targetId => {
    this.expandedTargets.push(targetId);
  };

  // keep the list of the expanded targets to handle the expand/collapse state correctly
  onHideTargetVisualisation = targetId => {
    this.expandedTargets.splice(this.expandedTargets.indexOf(targetId), 1);
  };

  /**
   * @param {Object} rowData - the row being deleted
   * @returns {String} a custom message when the far field is being used
   */
  deleteWarning = rowData => {
    const { designTargets } = this.props;
    const basicWarning = "Are you sure you want to delete this far-field?";
    const relatedDesignTargets = Object.values(designTargets.byId).filter(
      dt => dt.FFWFTarget === rowData.id
    );
    if (relatedDesignTargets.length) {
      const identifierLabels = relatedDesignTargets.map(dt => `${dt.name}`);
      return `This far-field is being used by the design target${
        relatedDesignTargets.length > 1 ? "s" : ""
      } ${HelperUtils.joinWords(identifierLabels)} ${basicWarning}`;
    }
    return basicWarning;
  };

  downloadFFWFTarget(ffwfTargetId) {
    const { ffwftargets } = this.props;
    return DesignTargetApi.exportFFWFTarget(ffwfTargetId).then(data => {
      HelperUtils.browserDownload(
        data,
        `${ffwftargets.byId[ffwfTargetId].name}.json`
      );
    });
  }

  render = () => {
    const { classes, ffwftargets, script2Ds } = this.props;
    return (
      <div className={classes.main}>
        <MaterialTable
          tableRef={this.tableRef}
          detailPanel={rowData => {
            return (
              <div
                style={
                  {
                    // width: "100%"
                    // height: "70vh",
                    // display: "flex",
                    // justifyContent: "center"
                  }
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FFWFTargetVisualisation
                      ffwfTargetId={rowData.id}
                      isEditing={this.editedTargetId === rowData.id}
                      showTargetVisualisation={this.onShowTargetVisualisation}
                      hideTargetVisualisation={this.onHideTargetVisualisation}
                      sampleScripts={Object.values(script2Ds.byId).filter(
                        script => script.type === "FFWF_EXAMPLE"
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          }}
          options={{
            addRowPosition: "first",
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 40, 50],
            detailPanelColumnAlignment: "right",
            actionsColumnIndex: -1
          }}
          title="Far-field Wavefront Targets"
          columns={columns}
          data={this.mapDataToColumns(Object.values(ffwftargets.byId))}
          editable={{
            isEditable: rowData => true,
            isDeletable: rowData => true,
            // onRowUpdate: this.onRowUpdate,
            onRowDelete: this.onRowDelete
          }}
          localization={{
            body: {
              editRow: {
                deleteText: this.deleteWarning
              }
            },
            pagination: {
              labelRowsSelect: "ffwftargets"
            },
            header: {
              actions: ""
            }
          }}
          actions={[
            {
              name: "download",
              icon: SaveAltIcon,
              onClick: (event, rowData) => this.downloadFFWFTarget(rowData.id)
            },
            {
              icon: "edit",
              tooltip: "Edit FFWFTarget",
              onClick: (event, rowData) => {
                this.editedTargetId = rowData.id;
                if (this.expandedTargets.indexOf(rowData.id) === -1) {
                  this.tableRef.current.onToggleDetailPanel(
                    [rowData.tableData.id],
                    this.tableRef.current.props.detailPanel
                  );
                }
              }
            }
          ]}
        />
      </div>
    );
  };
}

const mapState = state => ({
  ffwftargets: FFWTTargetSelector.getFFWTTargets(state),
  script2Ds: Script2DSelector.getScript2Ds(state),
  designTargets: DesignTargetSelector.getDesignTargets(state)
});

const mapDispatch = dispatch => ({
  fetchFFWFTargetsAction: id => dispatch(DesignTargetApi.fetchFFWFTargets(id)),
  deleteFFWFTargetAction: ffwfTargetId =>
    dispatch(DesignTargetApi.deleteFFWFTarget(ffwfTargetId)),
  fetchScript2DsAction: () => dispatch(Script2DApi.fetchScript2Ds())
});

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(FFWFTargets)));
