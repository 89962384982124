/**
 * A helper class for component components
 */
export default class ComponentHelper {
  /**
   * @param {Number} componentWidth - the width of the meta component
   * @param {Number} componentHeight - the height of the meta component
   * @param {Number} family_width - the width of the cell
   * @param {Number} family_height - the height of the cell
   * @returns {Number[]} the cell structure shape
   */
  static getComponentCellStructureShape = (
    componentWidth,
    componentHeight,
    family_width,
    family_height
  ) => {
    const numberOfCellsInYDirection = componentHeight / family_height,
      numberOfCellsInXDirection = componentWidth / family_width;
    return [
      Math.floor(numberOfCellsInXDirection),
      Math.floor(numberOfCellsInYDirection)
    ];
  };
}
