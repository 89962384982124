import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import Spinner from "components/Spinner/Spinner";

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: "3px 12px"
  }
}));

/**
 * a functional stateless component to be used in components that have edit mode
 */
const EditActions = ({ isEditing, onEdit, onSave, onCancel, isSaving }) => {
  const classes = useStyles();

  return isSaving ? (
    <Spinner size={25} timeout={30000} />
  ) : isEditing ? (
    <>
      {onCancel && (
        <IconButton
          test-data="cancelBtn"
          classes={{ root: classes.iconButton }}
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
      )}
      <IconButton
        test-data="saveBtn"
        classes={{ root: classes.iconButton }}
        onClick={onSave}
      >
        <CheckIcon />
      </IconButton>
    </>
  ) : (
    <IconButton
      test-data="editBtn"
      classes={{ root: classes.iconButton }}
      onClick={onEdit}
    >
      <EditIcon />
    </IconButton>
  );
};

EditActions.propTypes = {
  /**
   * it is used to decide whether to show the edit mode or not
   */
  isEditing: PropTypes.bool.isRequired,
  /**
   * the callback to be called when the user clicks to edit
   */
  onEdit: PropTypes.func.isRequired,
  /**
   * the callback to be called when the user clicks to cancel
   */
  onCancel: PropTypes.func.isRequired,
  /**
   * the callback to be called when the user clicks to save
   */
  onSave: PropTypes.func.isRequired,
  /**
   * it is used to decide whether or not show the spinner
   */
  isSaving: PropTypes.bool.isRequired
};

export default EditActions;
