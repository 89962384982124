import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { PlotboxDrilldownGroup } from "./PlotboxDrilldownGroup/PlotboxDrilldownGroup";

/**
 * A component to show drill downs for the plot form
 * @author Akira Kotsugai
 */
export const PlotboxFormDrilldowns = ({
  drilldowns,
  onDrilldownClose,
  disabled,
  plotType,
  scatterOptions,
  filterOutputDrilldownValues
}) => {
  return plotType !== "SC" ? (
    // <div style={{ display: "flex", flex: 1 }}>
    <Grid
      container
      style={{ width: "100%", wordBreak: "break-all" }}
      spacing={2}
    >
      <Grid item xs={6}>
        <PlotboxDrilldownGroup
          title="Output"
          drilldowns={filterOutputDrilldownValues(
            drilldowns.filter(drilldown => !drilldown.isSweep)
          )}
          onDrilldownClose={onDrilldownClose}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <PlotboxDrilldownGroup
          title="Sweep Variables"
          drilldowns={filterOutputDrilldownValues(
            drilldowns.filter(drilldown => drilldown.isSweep)
          )}
          onDrilldownClose={onDrilldownClose}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  ) : (
    // </div >
    <Grid
      container
      style={{ width: "90%", wordBreak: "break-word", marginLeft: 10 }}
      spacing={1}
    >
      {scatterOptions.x === "output" && (
        <Grid item xs={6}>
          <PlotboxDrilldownGroup
            title="X"
            test-data="output_x_dd_group"
            drilldowns={filterOutputDrilldownValues(
              drilldowns.filter(
                drilldown => !drilldown.isSweep && drilldown.name === "output_x"
              )
            )}
            onDrilldownClose={(value, data, i, isSweep) =>
              onDrilldownClose(value, data, i, isSweep, "output_x")
            }
            disabled={disabled}
          />
        </Grid>
      )}
      {scatterOptions.y === "output" && (
        <Grid item xs={6}>
          <PlotboxDrilldownGroup
            title="Y"
            test-data="output_y_dd_group"
            drilldowns={filterOutputDrilldownValues(
              drilldowns.filter(
                drilldown => !drilldown.isSweep && drilldown.name === "output_y"
              )
            )}
            onDrilldownClose={(value, data, i, isSweep) =>
              onDrilldownClose(value, data, i, isSweep, "output_y")
            }
            disabled={disabled}
          />
        </Grid>
      )}
      {scatterOptions.color === "output" && (
        <Grid item xs={6}>
          <PlotboxDrilldownGroup
            title="Color"
            test-data="output_color_dd_group"
            drilldowns={filterOutputDrilldownValues(
              drilldowns.filter(
                drilldown =>
                  !drilldown.isSweep && drilldown.name === "output_color"
              )
            )}
            onDrilldownClose={(value, data, i, isSweep) =>
              onDrilldownClose(value, data, i, isSweep, "output_color")
            }
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <PlotboxDrilldownGroup
          title="Sweep Variables"
          drilldowns={filterOutputDrilldownValues(
            drilldowns.filter(drilldown => drilldown.isSweep)
          )}
          onDrilldownClose={onDrilldownClose}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

PlotboxFormDrilldowns.propTypes = {
  /**
   * the drilldowns to be shown
   */
  drilldowns: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * function to handle drilldown close
   */
  onDrilldownClose: PropTypes.func.isRequired
};
