/**
 * A class to make value selection from the directoryExplorer in redux state more clear.
 * @author Akira Kotsugai
 */
export default class DirectoryExplorerSelector {
  /**
   * it selects projects from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the projects
   */
  static getProjects = state => state.directoryExplorer.entities.projects;

  /**
   * it selects simulations from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the simulations
   */
  static getSimulations = state => state.directoryExplorer.entities.simulations;

  /**
   * it selects the selected item from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the selected item
   */
  static getSelectedItem = state => state.directoryExplorer.ui.selectedItem;

  /**
   * it selects the form value from the redux state
   * @param {Object} state - the redux state
   * @return {String} the form value
   */
  static getForm = state => state.directoryExplorer.ui.form;

  /**
   * it selects the simulation open id from the redux state
   * @param {Object} state - the redux state
   * @return {Number} the simulation id
   */
  static getSimulationOpenId = state =>
    state.directoryExplorer.ui.simulationOpenId;

  /**
   * it selects the editing item from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the editing item
   */
  static getEditingItem = state => state.directoryExplorer.ui.editingItem;
}
