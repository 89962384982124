import Axios from "axios";
import Action from "MetaCell/actions/DirectoryExplorer";
import SimulationSettingsAction from "MetaCell/actions/SimulationSettings";
import GenericApi from "Api";

/**
 * A class created to perform http operations related to the tree explorer.
 * @author Akira Kotsugai
 */
export default class DirectoryExplorerApi {
  /**
   * it generates the url to the simulations' api to manipulate the given simulation if there is any.
   * if no id is passed then it generates the basic endpoint url.
   *@param id - the simulation's id
   */
  static getSimulationsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/simulations/${id}`;

  /**
   * it generates the url to the project's api to manipulate the given project if there is any.
   * if no id is passed then it generates the basic endpoint url.
   *@param id - the project's id
   */
  static getProjectsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/projects/${id}`;

  static getProjectsAndSimulationsUrl = () =>
    `${GenericApi.getBaseUrl()}/projectsimulation`;

  /**
   * @param {Number} projectId - the id of the project
   * @returns {String} the url to restore a simulation
   */
  static getRestoreSimulationUrl = projectId =>
    `${this.getProjectsUrl(projectId)}/restore_simulation`;

  /**
   * it can fetch the user's projects from the endpoint.
   * @return a function that receives a dispatcher to redux
   */
  static fetch = simulationOpenId => {
    return dispatch => {
      return Axios.get(DirectoryExplorerApi.getProjectsUrl())
        .then(projectsRes => projectsRes.data)
        .then(projects => {
          return Axios.get(DirectoryExplorerApi.getSimulationsUrl())
            .then(simulationsRes => simulationsRes.data)
            .then(simulations => {
              dispatch(Action.set(projects, simulations, simulationOpenId));
            });
        })
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  /**
   * it can add a project to the backend database
   * @return a function that receives a dispatcher to redux
   */
  static addProject = name => {
    return dispatch => {
      const project = { name };
      return Axios.post(DirectoryExplorerApi.getProjectsUrl(), project)
        .then(res => res.data)
        .then(savedProject => {
          dispatch(Action.upsertProjects([savedProject]));
          return savedProject;
        })
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  /**
   * it can add a simulation to the backend database
   * @return a function that receives a dispatcher to redux
   */
  static addSimulation = (name, project) => {
    return dispatch => {
      const simulation = { name, project };
      return Axios.post(DirectoryExplorerApi.getSimulationsUrl(), simulation)
        .then(res => res.data)
        .then(savedSimulation =>
          dispatch(Action.upsertSimulations([savedSimulation]))
        )
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  /**
   * it can delete a project from the backend database
   * @return a function that receives a dispatcher to redux
   */
  static deleteProject = id => {
    return dispatch => {
      return Axios.delete(DirectoryExplorerApi.getProjectsUrl(id))
        .then(res => dispatch(Action.deleteProject(id)))
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  static deleteProjectsAndSimulations = items => {
    return dispatch => {
      return Axios.delete(this.getProjectsAndSimulationsUrl(), {
        data: items
      }).then(res => dispatch(Action.deleteProjectsAndSimulations(items)));
    };
  };

  /**
   * it can delete a simulation from the backend database
   * @return a function that receives a dispatcher to redux
   */
  static deleteSimulation = id => {
    return dispatch => {
      return Axios.delete(DirectoryExplorerApi.getSimulationsUrl(id))
        .then(res => dispatch(Action.deleteSimulation(id)))
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  /**
   * it can change the value of a project's property in the backend database
   * @return a function that receives a dispatcher to redux
   */
  static updateProject = (id, properties) => {
    return dispatch => {
      return Axios.patch(DirectoryExplorerApi.getProjectsUrl(id), properties)
        .then(res => res.data)
        .then(project => dispatch(Action.applyEditedProject(project)))
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  /**
   * it can change the value of a simulation's property in the backend database
   * @return a function that receives a dispatcher to redux
   */
  static updateSimulation = (id, properties) => {
    return dispatch => {
      return Axios.patch(DirectoryExplorerApi.getSimulationsUrl(id), properties)
        .then(res => res.data)
        .then(simulation => {
          dispatch(Action.applyEditedSimulation(simulation));
          dispatch(
            SimulationSettingsAction.setEditingSimulationDescription(null)
          );
        })
        .catch(error =>
          console.log("error Directory Explorer API:", error.message)
        );
    };
  };

  /**
   * @param {Number} projectId - the project in which the simulation will be restored
   * @param {Number} simulationJobId - the simulation job from which the simulation will be restored
   * @returns {Function} a function that receives the redux dispatcher
   */
  static restoreSimulation = (projectId, simulationJobId) => {
    return dispatch => {
      return Axios.post(
        DirectoryExplorerApi.getRestoreSimulationUrl(projectId),
        { simulation_job_id: simulationJobId }
      )
        .then(res => res.data)
        .then(simulation => dispatch(Action.upsertSimulations([simulation])));
    };
  };
}
