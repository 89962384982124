import React, { PureComponent } from "react";
import GenericTextInput from "components/GenericTextInput/GenericTextInput";
import GenericSelect from "components/GenericSelect/GenericSelect";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { fieldTypeOptions } from "MetaCell/containers/SimulationSettingsCanvas/components/Probes/Probes";
import SweepInput from "components/SweepInput/SweepInput";

const styles = theme => ({
  selectContainer: {
    marginTop: theme.spacing(2),
    width: 200
  },
  container: {
    width: "100%",
    display: "flex",
    marginTop: theme.spacing.unit,
    flexWrap: "wrap"
  },
  textField: {
    marginTop: theme.spacing(0.1),
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 150
  },
  fab: {
    marginLeft: theme.spacing.unit,
    marginTop: theme.spacing(0.1)
  }
});

export const defaultState = {
  name: "",
  position: "",
  type: "normE"
};

class ProbeForm extends PureComponent {
  constructor() {
    super();
    this.state = defaultState;
  }

  handleUpdateField = (event, keyName) => {
    const newValue = {
      [keyName]: event.target.value
    };
    this.setState(newValue);
  };

  handleSubmitForm = () => {
    const { name, position, type } = this.state;
    const newFieldProbe = { name, position, type };
    this.props.onSubmit(newFieldProbe);
    this.setState(defaultState);
  };

  onPositionChange = position => {
    this.setState({ position });
  };

  render() {
    const { classes } = this.props;
    const { name, position, type } = this.state;
    return (
      <div className={classes.container} test-data="probeForm">
        <GenericTextInput
          keyName="name"
          label="Name"
          placeholder="Name"
          handleUpdateField={this.handleUpdateField}
          classes={classes}
          value={name}
          test-data="nameInput"
        />

        <SweepInput
          test-data="position"
          value={position}
          onChange={this.onPositionChange}
          label="Position"
        />

        <GenericSelect
          className={classes.selectContainer}
          selectedValue={type}
          options={fieldTypeOptions}
          handleChange={this.handleUpdateField}
          keyName="type"
          test-data="fieldTypeInput"
        />

        <Fab
          test-data="addButton"
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={this.handleSubmitForm}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(ProbeForm);
