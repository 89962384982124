import React, { PureComponent } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import GenericJobOverview from "./GenericJobOverview";
import DesignApi from "MetaComponent/api/Design";
import DesignAction from "MetaComponent/actions/Design";
import DesignSelector from "MetaComponent/selectors/Design";
import GenericApi from "Api";

export class MaskJobOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      data: null
    };
    this.handleFetchJobsComplete = this.handleFetchJobsComplete.bind(this);
    this.handleFetchJobsError = this.handleFetchJobsError.bind(this);
  }

  /**
   * when the component mounts it only gets the design jobs and mask jobs if the mc groups are loaded
   * else it fetches the groups and the meta componetns
   */
  componentDidMount() {
    const { fetchMCGroupsAndMetaComponentsAction, mcGroups } = this.props;
    if (mcGroups.loaded) {
      this.fetchJobs();
    } else {
      fetchMCGroupsAndMetaComponentsAction();
    }
  }

  /**
   * it fetches the jobs when the groups are loaded
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.mcGroups.loaded && this.props.mcGroups.loaded) {
      this.fetchJobs();
    }
  }

  /**
   * it makes a request to get all the design jobs and mask jobs
   */
  async fetchJobs() {
    try {
      const response = await Axios.get(
        `${GenericApi.getBaseUrl()}/maskjobs/`
      );
      if (response.status === 200) {
        this.handleFetchJobsComplete(response);
      } else {
        this.handleFetchJobsError();
      }
    } catch {
      this.handleFetchJobsError();
    }
  }

  /**
   * it sets the state that is used for the table data
   * @param {Object[]} designJobs
   * @param {String[]} maskJobs
   */
  handleFetchJobsComplete({ data }) {
    if (data && data.length > 0) {
      const tableData = data.map(job => {
        return {
          itemId: job.id,
          mcGroup: job.mcGroup,
          metaComponent: job.metaComponent,
          startDate: (job.creationDate && job.creationDate.slice(0, 10)) || "-",
          status: job.status,
          errors: job.errors
        };
      });
      this.setState({
        data: tableData
      });
    } else {
      this.setState({ data: [] });
    }
  }

  handleFetchJobsError() {
    this.setState({ error: true });
  }

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {Number} the id of the group to which the meta component belongs
   */
  getMCGroupId = metaComponentId => {
    const { metaComponents } = this.props;
    const metaComponent = metaComponents.byId[metaComponentId];
    return metaComponent ? metaComponent.mcGroup : null;
  };

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} the name of the project to which the component belongs
   */
  getMCGroupName = metaComponentId => {
    const { mcGroups, metaComponents } = this.props;
    let mcGroupName = "";
    const metaComponent = metaComponents.byId[metaComponentId];
    if (metaComponent) {
      const mcGroup = mcGroups.byId[metaComponent.mcGroup];
      if (mcGroup) {
        mcGroupName = mcGroup.name;
      }
    }
    return mcGroupName;
  };

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} - the name of the meta component
   */
  getMetaComponentName = metaComponentId => {
    const { metaComponents } = this.props;
    const metaComponent = metaComponents.byId[metaComponentId];
    return metaComponent ? metaComponent.name : "";
  };

  /**
   * @param {Number} jobId - the design job id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  stopMaskJob = jobId => {
    return DesignApi.stopMaskJob(jobId).then(() => Promise.resolve(true));
  };

  /**
   * @param {Number} jobId - the design job id
   * @returns {Object} errors and warnings extracted from the mask job
   */
  getMaskJobErrors = async jobId => {
    const job = this.state.data.find(job => job.itemId === jobId);
    const { errors } = job;
    return { errors };
  };

  /**
   * @callback
   * @param {Object} newData
   */
  updateData = newData => {
    this.setState({ data: newData });
  };

  /**
   * sends the new filters to the redux state
   * @param {Object} filter - the new filter
   */
  handleFilterChange = filter => {
    this.props.updateOverviewFilters(filter);
  };

  render = () => {
    const { data, error } = this.state;
    return (
      // data && (
      <>
        <GenericJobOverview
          data={data}
          updateData={this.updateData}
          error={error}
          getErrorsAndWarnings={this.getMaskJobErrors}
          getJobProgress={DesignApi.getDesignJobMaskProgress}
          stopJob={this.stopMaskJob}
          grouperColumns={[
            { title: "Group", field: "mcGroup", defaultGroupOrder: 0 },
            {
              title: "Meta Component",
              field: "metaComponent",
              defaultGroupOrder: 1
            }
          ]}
          handleFilterChange={this.handleFilterChange}
          filters={this.props.filters}
        />
      </>
      // )
    );
  };
}

const mapStateToProps = state => ({
  filters: DesignSelector.getOverviewFilters(state),
  mcGroups: DirectoryExplorerSelector.getMCGroups(state),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateOverviewFilters: filter =>
      dispatch(DesignAction.updateOverviewFilters(filter)),
    fetchMCGroupsAndMetaComponentsAction: () =>
      dispatch(DirectoryExplorerApi.fetch())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaskJobOverview);
