import React, { PureComponent } from "react";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";

/**
 * A component created to be the content for Meta Cell component's root.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 */
export default class RootCanvas extends PureComponent {
  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(MetaComponentPaths.ROOT);
  }

  render() {
    return null;
  }
}
