/**
 * @constant
 * @typedef {Object} GlobalParametersActionType
 * @property {String} SET_METACELL_GLOBAL_PARAMETERS - tells the reducer to set global parameters
 * @property {String} CHANGE_EDITING_FIELD - tells the reducer to change the field value of the editing state
 * @property {String} RESET_GLOBAL_PARAMETERS  - tells the reducer to set the default state
 * @property {String} EDIT_GLOBAL_PARAMETERS  - tells the reducer to copy the original gp to the edit state
 * @property {String} CANCEL_GLOBAL_PARAMETERS_EDITING - tells the reducer that there must be no editing value
 * @global
 * it defines meta cell global parameters' type of actions.
 */
export const actionType = Object.freeze({
  SET_METACELL_GLOBAL_PARAMETERS: "SET_METACELL_GLOBAL_PARAMETERS",
  CHANGE_GLOBAL_PARAMETERS_EDITING_FIELD:
    "CHANGE_GLOBAL_PARAMETERS_EDITING_FIELD",
  RESET_GLOBAL_PARAMETERS: "RESET_GLOBAL_PARAMETERS",
  UPDATE_METACELL_GLOBAL_PARAMETER: "UPDATE_METACELL_GLOBAL_PARAMETER",
  EDIT_GLOBAL_PARAMETERS: "EDIT_GLOBAL_PARAMETERS",
  CANCEL_GLOBAL_PARAMETERS_EDITING: "CANCEL_GLOBAL_PARAMETERS_EDITING"
});

/**
 * an action creator class for Meta Cell's global parameters to be dispatched to redux
 * It is already tested with global parameter reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link GlobalParametersActionType} and some data.
 * @author Akira Kotsugai
 */
export default class GlobalParametersAction {
  static set = value => ({
    type: actionType.SET_METACELL_GLOBAL_PARAMETERS,
    payload: value
  });

  static update = obj => ({
    type: actionType.UPDATE_METACELL_GLOBAL_PARAMETER,
    payload: obj
  });

  static changeEditingField = value => ({
    type: actionType.CHANGE_GLOBAL_PARAMETERS_EDITING_FIELD,
    payload: value
  });

  static reset = () => ({
    type: actionType.RESET_GLOBAL_PARAMETERS,
    payload: {}
  });

  static edit = () => ({
    type: actionType.EDIT_GLOBAL_PARAMETERS,
    payload: {}
  });

  static cancelEditing = () => ({
    type: actionType.CANCEL_GLOBAL_PARAMETERS_EDITING,
    payload: {}
  });
}
