import React, { PureComponent } from "react";
import {
  Typography,
  withStyles,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import IncidentLightForm from "components/IncidentLightForm/IncidentLightForm";
import IconTooltip from "components/IconTooltip/IconTooltip";
import SweepInput from "components/SweepInput/SweepInput";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import NumberInput from "components/NumberInput/NumberInput";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = theme => ({
  textField: {
    width: "100%",
    marginBottom: 5
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
});

/**
 * it changes the unit and scales the wavelength
 * @param {Number | String} wavelength - the current wavelength value
 * @param {String} oldUnit - old unit
 * @param {String} newUnit - new unit
 * @param {Function} fieldValueSetter - the formik function that sets the values
 */
export const changeUnit = (wavelength, oldUnit, newUnit, fieldValueSetter) => {
  fieldValueSetter("unit", newUnit);
  const wavelengthIsNumber = !isNaN(wavelength);
  if (wavelengthIsNumber) {
    const multiplier = HelperUtils.scaleUnit(oldUnit, newUnit);
    const newWavelength = wavelength * multiplier;
    fieldValueSetter("wavelength", newWavelength);
  }
};

const requiredFieldText = "required";

const setPointFormSchema = Yup.object().shape({
  X: Yup.string().required(requiredFieldText),
  Y: Yup.string().required(requiredFieldText),
  zenit: Yup.string().required(requiredFieldText),
  azimut: Yup.string().required(requiredFieldText),
  wavelength: Yup.string().required(requiredFieldText),
  amplitude: Yup.string().required(requiredFieldText),
  polarization: Yup.string().required(requiredFieldText)
});

export const unitOptions = Object.freeze(["μm", "nm"]);

export const xTooltip = `This setting defines the accuracy of the calculation. Increasing
“x” increases the accuracy but also increases the
calculation time. Set the number of diffraction orders considered in x direction for this simulation.`;

export const yTooltip = `This setting defines the accuracy of the calculation. Increasing
“y” increases the accuracy but also increases the
calculation time. Set the number of diffraction orders considered in y direction for this simulation.`;

export const unitTooltip = "Unit of the wavelength size.";

/**
 * a component to allow a set point to be edited or created
 * @author Akira Kotsugai
 */
export class SetPointForm extends PureComponent {
  /**
   * if there is an existing set point object, the initial
   * form values will consist of a copy of the set point object,
   * otherwise, it will be empty (create mode).
   * @returns {Object} the form values
   */
  getFormInitialValues() {
    const { setPoint, defaultValues } = this.props,
      isCreateMode = setPoint === null;
    if (isCreateMode) {
      if (defaultValues) {
        return defaultValues;
      }
      return {
        X: "",
        Y: "",
        zenit: "",
        azimut: "",
        wavelength: "",
        amplitude: "",
        polarization: "TE",
        incident_light_type: "PW",
        focal_spot_x: "",
        focal_spot_y: "",
        focal_spot_z: "",
        beam_divergence: "",
        unit: unitOptions[0]
      };
    }
    const { id, diffractive_order, incident_light, unit } = setPoint,
      { X, Y } = diffractive_order,
      {
        zenit,
        azimut,
        wavelength,
        amplitude,
        polarization,
        incident_light_type,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence
      } = incident_light;
    return {
      id,
      X,
      Y,
      zenit,
      azimut,
      wavelength,
      amplitude,
      polarization,
      unit,
      incident_light_type,
      focal_spot_x,
      focal_spot_y,
      focal_spot_z,
      beam_divergence
    };
  }

  /**
   * before calling the parent submitter, it prepares the data that matches the endpoint's
   * expected format
   * @param {Object} values - the form values to submit
   */
  handleSubmit = values => {
    const {
      id,
      X,
      Y,
      zenit,
      azimut,
      wavelength,
      amplitude,
      polarization,
      unit,
      incident_light_type,
      focal_spot_x,
      focal_spot_y,
      focal_spot_z,
      beam_divergence
    } = values;
    const preparedValues = {
      id,
      incident_light: {
        zenit,
        azimut,
        wavelength,
        amplitude,
        polarization,
        focal_spot_x,
        focal_spot_y,
        focal_spot_z,
        beam_divergence,
        incident_light_type
      },
      diffractive_order: {
        X,
        Y
      },
      unit
    };
    this.props.onSubmit(preparedValues);
  };

  /**
   * @callback - it is supposed to be used by the fields in the form to update the formik values
   * @param {Object} changedField - the field and the new value
   * @param {Function} setFieldValue - the formik field value setter
   */
  updateField = (changedField, setFieldValue) => {
    setFieldValue(Object.keys(changedField)[0], Object.values(changedField)[0]);
  };

  render() {
    const {
        classes,
        setPoint,
        isEditing,
        isSweep,
        hideAccuracy,
        disableAll
      } = this.props,
      isCreateMode = setPoint === null,
      disabled = disableAll || (!isEditing && !isCreateMode);
    return (
      <>
        <Formik
          initialValues={this.getFormInitialValues()}
          enableReinitialize
          validationSchema={setPointFormSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            submitForm,
            resetForm,
            setFieldValue
          }) => {
            // making it possible to call these formik functions from parent components
            this.props.bindSubmitForm(submitForm);
            this.props.bindResetForm(resetForm);
            const wavelengthUnitComponent = (
              <Grid item xs={4}>
                <FormControl style={{ width: "100%" }}>
                  <div>
                    <FormLabel
                      style={{ fontSize: 12 }}
                      disabled={disabled}
                      htmlFor="Y"
                    >
                      Unit
                    </FormLabel>
                    <IconTooltip text={unitTooltip} />
                  </div>
                  <UnselfishSelect
                    name="unit"
                    value={values.unit}
                    onChange={event =>
                      changeUnit(
                        values.wavelength,
                        values.unit,
                        event.target.value,
                        setFieldValue
                      )
                    }
                    disabled={disabled}
                  >
                    {unitOptions.map(unit => (
                      <MenuItem name="PolarizationOption" value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </UnselfishSelect>
                </FormControl>
              </Grid>
            );
            return (
              <Form>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                    {"Incident Light"}
                  </Typography>
                  <IncidentLightForm
                    incidentLight={{
                      incident_light_type: values.incident_light_type,
                      azimut: values.azimut,
                      zenit: values.zenit,
                      wavelength: values.wavelength,
                      polarization: values.polarization,
                      amplitude: values.amplitude,
                      focal_spot_x: values.focal_spot_x,
                      focal_spot_y: values.focal_spot_y,
                      focal_spot_z: values.focal_spot_z,
                      beam_divergence: values.beam_divergence
                    }}
                    errors={errors}
                    touched={touched}
                    updateFieldCallback={changedField =>
                      this.updateField(changedField, setFieldValue)
                    }
                    handleBlur={handleBlur}
                    disabled={disabled}
                    isSweep={isSweep}
                    simulationId={-1}
                    sweptVariables={this.props.sweptVariables}
                    wavelengthUnitComponent={
                      !this.props.hideUnit ? wavelengthUnitComponent : null
                    }
                  />
                </Grid>
                {!hideAccuracy && (
                  <Grid container spacing={1} style={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Typography variant="h6" component="h3">
                          {"Meta Cell Accuracy"}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div>
                              <FormLabel
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="Y"
                              >
                                Accuracy X
                              </FormLabel>
                              <IconTooltip text={xTooltip} />
                            </div>
                            {isSweep ? (
                              <SweepInput
                                test-data="xInput"
                                value={values.X}
                                onChange={value => setFieldValue("X", value)}
                                simulationId={-1}
                                sweptVariables={this.props.sweptVariables}
                              />
                            ) : (
                              <NumberInput
                                className={classes.textField}
                                disabled={disabled}
                                name="X"
                                error={touched.X && errors.X}
                                helperText={touched.X && errors.X}
                                value={values.X}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )}
                          </Grid>
                          <Grid item xs={6}>
                            <div>
                              <FormLabel
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="Y"
                              >
                                Accuracy Y
                              </FormLabel>
                              <IconTooltip text={yTooltip} />
                            </div>
                            {isSweep ? (
                              <SweepInput
                                test-data="yInput"
                                value={values.Y}
                                onChange={value => setFieldValue("Y", value)}
                                simulationId={-1}
                                sweptVariables={this.props.sweptVariables}
                              />
                            ) : (
                              <NumberInput
                                className={classes.textField}
                                disabled={disabled}
                                name="Y"
                                error={touched.Y && errors.Y}
                                helperText={touched.Y && errors.Y}
                                value={values.Y}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withStyles(styles)(SetPointForm);
