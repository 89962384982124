export const actionType = Object.freeze({
  UPSERT_RAYTRACING_JOBS: "UPSERT_RAYTRACING_JOBS",
  SELECT_RAYTRACING_JOB: "SELECT_RAYTRACING_JOB",
  RESET_RAYTRACING_JOBS: "RESET_RAYTRACING_JOBS",
  UPDATE_TEMP_RAYTRACING_VARIABLES: "UPDATE_TEMP_RAYTRACING_VARIABLES",
  SET_RAYTRACING_JOBS: "SET_RAYTRACING_JOBS",
  DELETE_RAYTRACING_JOBS: "DELETE_RAYTRACING_JOBS"
  // UPDATE_OVERVIEW_FILTERS: "UPDATE_ANALYSIS_OVERVIEW_FILTERS"
});

export default class RayTracingAction {
  // static updateOverviewFilters = filters => ({
  //   type: actionType.UPDATE_OVERVIEW_FILTERS,
  //   payload: filters
  // });

  static set = jobs => ({
    type: actionType.SET_RAYTRACING_JOBS,
    payload: jobs
  });

  static upsertJobs = jobs => ({
    type: actionType.UPSERT_RAYTRACING_JOBS,
    payload: jobs
  });
  static selectJob = id => ({
    type: actionType.SELECT_RAYTRACING_JOB,
    payload: id
  });
  static resetJobs = () => ({
    type: actionType.RESET_RAYTRACING_JOBS,
    payload: {}
  });
  static updateTempVariables = newVariables => ({
    type: actionType.UPDATE_TEMP_RAYTRACING_VARIABLES,
    payload: newVariables
  });
  static deleteJobs = jobIds => ({
    type: actionType.DELETE_RAYTRACING_JOBS,
    payload: jobIds
  });
}
