import FamilyApi from "MetaCell/api/Family";

/**
 * Helper class to help components deal with family data.
 */
export default class FamilyHelper {
  /**
   * it finds which required properties are missing
   * @param {Object} material - the material
   * @param {String[]} requiredFields - the required properties
   */
  static getMissingRequiredProperties = (family, requiredFields) => {
    return requiredFields.filter(
      property =>
        family[property] === undefined ||
        family[property] === "" ||
        family[property] === null
    );
  };

  static loadFamilyMemberLazyFields = async familyMembers => {
    let structuresInfos = [];
    for (const member of familyMembers) {
      const structureInfo = await FamilyApi.fetchFamilyMemberStructureInfo(
        member.id
      );
      structuresInfos.push(structureInfo);
    }
    const membersWithStructureInfo = familyMembers.map((member, index) => ({
      ...member,
      ...structuresInfos[index]
    }));
    return membersWithStructureInfo;
  };

  /**
   * it formats the api family data to match the families table structure
   * @param {Object[]} familiesData -the families array
   */
  static formatFamiliesTableData = familiesData => {
    return familiesData
      .filter(family => !family.is_snapshot)
      .map((family, index) => {
        const familyRow = Object.assign(
          {},
          {
            id: family.id,
            name: family.name,
            description: family.description,
            // cellSize: family.for_pdk
            //   ? "PDK"
            //   : `${family.cell_width || ""}${
            //       family.unit
            //     } x ${family.cell_height || ""}${family.unit}`,
            cellSize: `${family.cell_width || ""}${
              family.unit
            } x ${family.cell_height || ""}${family.unit}`,
            unit: family.unit,
            for_pdk: family.for_pdk,
            pdk_preferences: family.pdk_preferences
          }
        );
        return familyRow;
      });
  };

  static sortFamilies = familiesData => {
    const sortedData = familiesData.sort((a, b) => {
      // First, sort by 'pdk' property (true first)
      if (a.for_pdk && !b.for_pdk) {
        return -1; // 'a' comes first
      } else if (!a.for_pdk && b.for_pdk) {
        return 1; // 'b' comes first
      }

      // If 'pdk' values are the same, sort by 'id' in descending order
      return b.id - a.id;
    });
    return sortedData;
  };
}
