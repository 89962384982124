import React, { PureComponent } from "react";
import GenericPlot from "components/Plot/Plot";
import PropTypes from "prop-types";

/**
 * the top view of the heatmap
 * @author Akira Kotsugai
 */
export default class HeatmapSide extends PureComponent {
  render() {
    return (
      <GenericPlot
        data={this.props.getData("surface")}
        layout={this.props.layout}
        handleCustomRange={this.props.handleCustomRange}

      />
    );
  }
}

HeatmapSide.propTypes = {
  /**
   * the get data callback
   */
  getData: PropTypes.func.isRequired,
  /**
   * the layout object
   */
  layout: PropTypes.object.isRequired
};
