/**
 * A selector class for the family state
 */
export class FamilySelector {
  /**
   * it selects the families from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the families
   */
  static getFamilies = state => state.family.entities.families;

  /**
   * it selects the add family member dialog's open value
   * @param {Object} state - the redux state
   * @return {Object} - the dialog state
   */
  static getWizardDialog = state => state.family.ui.wizardDialog;

  /**
   * it selects the family members from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the familyMembers
   */
  static getFamilyMembers = state => state.family.entities.familyMembers;
}
