import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { metaCellPaths } from "MetaCell/MetaCell";
import { Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SimulationTargets from "./components/SimulationTargets/SimulationTargets";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaCell/api/DirectoryExplorer";
import SimulationTargetDetails from "./components/SimulationTargetDetails/SimulationTargetDetails";
import SimulationTargetSelector from "MetaCell/selectors/SimulationTarget";
import Spinner from "components/Spinner/Spinner";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import IncidentLightApi from "MetaCell/api/IncidentLight";
import IncidentLightSelector from "../../selectors/IncidentLight";
import SimulationTargetApi from "MetaCell/api/SimulationTarget";
import OutputSettingsApi from "MetaCell/api/OutputSetting";
import OutputSettingSelector from "MetaCell/selectors/OutputSetting";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  targetDetails: {
    marginTop: "10px"
  }
});

export class SimulationTargetCanvas extends PureComponent {
  constructor() {
    super();
    this.state = {
      focusedSimulationTargetId: 0,
      selectedTabIndex: 0,
      availableOptimizers: []
    };
  }

  componentDidMount() {
    this.props.setPage(metaCellPaths.SIMULATION_TARGET);
    this.getAvailableOptimizers().then(availableOptimizers => {
      this.setState({ availableOptimizers });
    });
    const {
      fetchIncidentLightsAction,
      fetchOutputSettingsAction,
      openSimulationId
    } = this.props;
    if (openSimulationId !== -1) {
      fetchIncidentLightsAction(openSimulationId);
      fetchOutputSettingsAction(openSimulationId);
    }
  }

  focusOnSimulationTarget = id => {
    this.setState({
      focusedSimulationTargetId: id
    });
  };

  getAvailableOptimizers = async () => {
    const availableOptimizers = await SimulationTargetApi.getAvailableOptimizers();
    return availableOptimizers;
  };

  setPolarization = (
    functionCall,
    newData,
    polarization,
    polarizationLabel
  ) => {
    const polarizationValues = polarization.split("/");
    if (polarizationValues.length >= 3) {
      polarizationValues.forEach((val, i) => {
        if (val === "") {
          polarizationValues[i] = "0";
        }
      });
    }
    return functionCall({
      ...newData,
      [polarizationLabel]: polarizationValues.join("/")
    });
  };

  createIncidentLight = newData => {
    const { createIncidentLightAction, openSimulationId } = this.props;
    const { polarization } = newData || {};
    newData.simulation = openSimulationId;
    if (polarization) {
      return this.setPolarization(
        createIncidentLightAction,
        newData,
        polarization,
        "polarization"
      );
    }
    return createIncidentLightAction(newData);
  };

  updateIncidentLight = newData => {
    const { updateIncidentLightAction } = this.props;
    const { polarization } = newData || {};
    if (polarization) {
      return this.setPolarization(
        updateIncidentLightAction,
        newData,
        polarization,
        "polarization"
      );
    }
    return updateIncidentLightAction(newData);
  };

  deleteIncidentLight = id => {
    const { deleteIncidentLightAction } = this.props;
    return deleteIncidentLightAction(id);
  };

  updateOutputSetting = newData => {
    const { updateOutputSettingAction } = this.props;
    const { polarization_in } = newData || {};
    if (polarization_in) {
      return this.setPolarization(
        updateOutputSettingAction,
        newData,
        polarization_in,
        "polarization_in"
      );
    }
    return updateOutputSettingAction(newData);
  };

  createOutputSetting = newData => {
    const { createOutputSettingAction, openSimulationId } = this.props;
    const { polarization_in } = newData || {};
    newData.simulation = openSimulationId;
    if (polarization_in) {
      return this.setPolarization(
        createOutputSettingAction,
        newData,
        polarization_in,
        "polarization_in"
      );
    }
    return createOutputSettingAction(newData);
  };

  deleteOutputSetting = id => {
    const { deleteOutputSettingAction } = this.props;
    return deleteOutputSettingAction(id);
  };

  getTargetIncidentLightSettings = incidentLights => {
    const { focusedSimulationTargetId } = this.state;
    if (focusedSimulationTargetId) {
      const targetIncidentLights = Object.values(incidentLights.byId).filter(
        il => il.simulation_target === focusedSimulationTargetId
      );
      return targetIncidentLights;
    }
    return [];
  };

  getTargetOptimizerSettings = () => {
    const { availableOptimizers, focusedSimulationTargetId } = this.state;
    if (focusedSimulationTargetId) {
      const selectedSimulationTarget = this.props.simulationTargets.byId[
        focusedSimulationTargetId
      ];
      const selectedOptimizer = availableOptimizers.find(
        optimizer => optimizer.id === selectedSimulationTarget.optimizer
      );
      if (selectedOptimizer) return selectedOptimizer.optimizer_attributes;
      else return [];
    }
  };

  onSimulationTargetUpdate = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      const {
        updateSimulationTargetAction,
        fetchSimulationTargetsAction
      } = this.props;
      updateSimulationTargetAction(newData)
        .then(() => {
          fetchSimulationTargetsAction(newData.simulation).then(() =>
            resolve()
          );
        })
        .catch(() => {
          reject();
        });
    });
  };

  render() {
    const {
      ready,
      classes,
      simulationTargets,
      incidentLights,
      fetchIncidentLightsAction,
      outputSettings
    } = this.props;
    const { focusedSimulationTargetId, availableOptimizers } = this.state;
    if (!ready) {
      return (
        <div className={classes.progress}>
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <SimulationTargets
                focusedSimulationTargetId={focusedSimulationTargetId}
                focusOnSimulationTarget={this.focusOnSimulationTarget}
                availableOptimizers={availableOptimizers}
                onSimulationTargetUpdate={this.onSimulationTargetUpdate}
              />
            </Paper>
          </Grid>
        </Grid>

        {focusedSimulationTargetId !== 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.targetDetails}>
                <SimulationTargetDetails
                  simulationIncidentLightSettings={this.getTargetIncidentLightSettings(
                    incidentLights
                  )}
                  selectedSimulationTarget={
                    simulationTargets.byId[focusedSimulationTargetId]
                  }
                  saveIncidentLight={this.updateIncidentLight}
                  createIncidentLight={this.createIncidentLight}
                  deleteIncidentLight={this.deleteIncidentLight}
                  fetchIncidentLightsAction={fetchIncidentLightsAction}
                  targetOptimizerSettings={this.getTargetOptimizerSettings()}
                  onSimulationTargetUpdate={this.onSimulationTargetUpdate}
                  fetchOutputSettingsAction={
                    this.props.fetchOutputSettingsAction
                  }
                  updateOutputSetting={this.updateOutputSetting}
                  deleteOutputSetting={this.deleteOutputSetting}
                  createOutputSetting={this.createOutputSetting}
                  outputSettings={outputSettings}
                />
              </Paper>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openSimulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
    incidentLights: IncidentLightSelector.get(state),
    simulationTargets: SimulationTargetSelector.getSimulationTargets(state),
    outputSettings: OutputSettingSelector.getOutputSettings(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetaCell: (id, newProperties) =>
      dispatch(DirectoryExplorerApi.updateMetaCell(id, newProperties)),
    fetchSimulationTargetsAction: simulation_id =>
      dispatch(SimulationTargetApi.fetchSimulationTargets(simulation_id)),
    updateSimulationTargetAction: simulationTarget =>
      dispatch(SimulationTargetApi.updateSimulationTarget(simulationTarget)),
    createIncidentLightAction: incidentLight =>
      dispatch(IncidentLightApi.createIncidentLight(incidentLight)),
    updateIncidentLightAction: incidentLight =>
      dispatch(IncidentLightApi.put(incidentLight)),
    deleteIncidentLightAction: incidentLightId =>
      dispatch(IncidentLightApi.deleteIncidentLight(incidentLightId)),
    fetchIncidentLightsAction: simulationId =>
      dispatch(IncidentLightApi.fetch(simulationId)),
    fetchOutputSettingsAction: simulationId =>
      dispatch(OutputSettingsApi.fetchOutputSettingsBySimulation(simulationId)),
    updateOutputSettingAction: outputSetting =>
      dispatch(OutputSettingsApi.updateOutputSetting(outputSetting)),
    deleteOutputSettingAction: outputSettingId =>
      dispatch(OutputSettingsApi.deleteOutputSetting(outputSettingId)),
    createOutputSettingAction: outputSetting =>
      dispatch(OutputSettingsApi.createOutputSetting(outputSetting))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(SimulationTargetCanvas)));
