/**
 * @constant
 * @typedef {Object} Script2DActionType
 * @property {String} SET_2D_SCRIPTS - tells the reducer to set the 2d scripts
 * @property {String} UPSERT_2D_SCRIPTS - tells the reducer to add or update 2d scripts
 * @global
 * it defines 2D script's type of actions.
 */
export const actionType = Object.freeze({
  SET_2D_SCRIPTS: "SET_2D_SCRIPTS",
  UPSERT_2D_SCRIPTS: "UPSERT_2D_SCRIPTS"
});

/**
 * A class created to create 2D script actions to be dispatched to redux. It is already tested with
 * 2D script reducer, so it does not need to be unit tested.
 */
export default class Script2DAction {
  static set = value => ({
    type: actionType.SET_2D_SCRIPTS,
    payload: value
  });

  static upsert = scripts => ({
    type: actionType.UPSERT_2D_SCRIPTS,
    payload: scripts
  });
}
