import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import TransferList from "components/GenericTransferList/GenericTransferList";

const styles = theme => ({
  drilldown: {
    float: "left"
  }
});

/**
 * A class component to connect to redux state and serve the form. See {@link MetaCellGlobalParametersForm}
 * @typedef {Component} MetaCellGlobalParameters
 */
export class TargetsSelection extends PureComponent {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   *
   * @returns {Object[]} an array of TransferList items created
   * from the design targets list
   */
  getDesignTargetsItems() {
    const { designTargets } = this.props;
    const targetsItems = Object.values(designTargets.byId).map(target => {
      const formattedNFDimensions = `(${target.NFWidth || ""} ${
        target.unit
      } x ${target.NFHeight || ""} ${target.unit})`;
      return {
        id: target.id,
        name: `${target.name} ${formattedNFDimensions}`
      };
    });
    return targetsItems;
  }

  render() {
    const { editingSelectedTargets, onSelectTarget } = this.props;
    return (
      <>
        <TransferList
          listTitle="Design Targets"
          items={this.getDesignTargetsItems()}
          selectedItemsIds={editingSelectedTargets}
          onChange={onSelectTarget}
        />
      </>
    );
  }
}

export default withStyles(styles)(TargetsSelection);
