/**
 * Instructions to be sent in the action to the confirm dialog's reducer
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} ConfirmDialogActionType
 * @property {String} SET_DIALOG_OPEN  tells the reducer to update the open value
 * @property {String} SHOW_DIALOG  tells the reducer to show the confirmation dialog
 * @global
 */
export const actionType = Object.freeze({
  SET_DIALOG_OPEN: "SET_DIALOG_OPEN",
  SHOW_DIALOG: "SHOW_DIALOG"
});

/**
 * Actions creator for the confirm dialog's reducer.
 * consist of an instruction {@link ConfirmDialogActionType} and some data.
 */
export default class ConfirmDialogAction {
  /**
   * creates an action to update the open value in the store
   * @param {Boolean} open - value
   * @return {Object} action
   */
  static setOpen = open => ({
    type: actionType.SET_DIALOG_OPEN,
    payload: open
  });

  /**
   * creates an action to show the confirm dialog
   * @param title - the title of the dialog
   * @param message - the content of the dialog
   * @param confirmAction - an action to be dispatched on confirmation
   * @param cancelAction - an action to be dispatched on cancelation
   * @param isReduxAction - whether the dialog triggers a redux action
   * @param postConfirm - what to do after confirmation
   * @param inputPlaceholders - the place holders for each input that the dialog should have
   * @param uploadFile - whether the dialog should show a file uploader
   * @return {Object} action
   */
  static show = (
    title,
    message,
    confirmAction,
    cancelAction,
    isReduxAction,
    postConfirm,
    inputPlaceholders,
    uploadFile,
    confirmLabels
  ) => ({
    type: actionType.SHOW_DIALOG,
    payload: {
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm,
      inputPlaceholders,
      uploadFile,
      confirmLabels
    }
  });
}
