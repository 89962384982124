import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import IconTooltip from "components/IconTooltip/IconTooltip";
import SimulationTargetSelector from "MetaCell/selectors/SimulationTarget";
import TargetsView from "./components/TargetsView/TargetsView";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 30
  },
  title: {
    float: "left"
  },
  tooltip: {
    float: "left"
  }
});

export class SimulationTargets extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingSelectedTargets: null
    };
  }

  getOpenSimulation = () => {
    const { openSimulationId, simulations } = this.props;
    return simulations.byId[openSimulationId];
  };

  getSimulationTargets = simulationTargetIds => {
    const { simulationTargets } = this.props;
    return simulationTargetIds.map(
      simulationTargetId => simulationTargets.byId[simulationTargetId]
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div test-data="titleWrapper">
              <Typography className={classes.title} variant="h6" component="h6">
                Simulation Targets
              </Typography>
              <div className={classes.tooltip}>
                <IconTooltip
                  text={
                    "Simulation Targets for the current meta cell configuration"
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TargetsView
              targetsList={this.getSimulationTargets(
                this.getOpenSimulation().simulation_targets
              )}
              focusedSimulationTargetId={this.props.focusedSimulationTargetId}
              focusOnSimulationTarget={this.props.focusOnSimulationTarget}
              updateSimulationTargetAction={this.props.updateSimulationTarget}
              onSimulationTargetUpdate={this.props.onSimulationTargetUpdate}
              availableOptimizers={this.props.availableOptimizers}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    simulations: DirectoryExplorerSelector.getSimulations(state),
    openSimulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
    simulationTargets: SimulationTargetSelector.getSimulationTargets(state)
  };
};

export default connect(mapStateToProps)(
  withErrorBoundary(withStyles(styles)(SimulationTargets))
);
