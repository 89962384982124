/**
 * A selector class for the global parameters state
 * @author Akira Kotsugai
 */
export default class GlobalParametersSelector {

    /**
     * it selects the global parameters from the redux state
     * @param {Object} state => the redux state
     * @return {Object} the global parameters
     */
    static get = state => state.metaCellGlobalParameters.entities.globalParameters;

    /**
     * it selects the editing global parameters from the redux state
     * @param {Object} state => the redux state
     * @return {Object} the editing global parameters
     */
    static getEditing = state => state.metaCellGlobalParameters.ui.editingGlobalParameters;

}