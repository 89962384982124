/**
 * A selector class for the material state
 * @author Akira Kotsugai
 */
export default class MaterialSelector {

    /**
     * it selects the materials from the redux state
     * @param {Object} state => the redux state
     * @return {Object} - the materials
     */
    static getMaterials = state => state.material.entities.materials;

}
