import { connect } from "react-redux";
import Selector from "BaseApp/selectors/ConfirmDialog";
import Action from "BaseApp/actions/ConfirmDialog";
import React from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from "@material-ui/core";
import DropZone from "components/DropZone/DropZone";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import Linkify from "react-linkify";
/**
 * A component to be used globally everytime a component wants
 * to perform an action that requires user confirmation
 * @author Akira Kotsugai
 * @param {Object} props - passed props
 * @return {Component} a Dialog component with title, content, confirm button and cancel button
 *
 *
 * @todo: make it a class component, because jest cant test functional components properly
 */
export const ConfirmDialog = ({
  isOpen,
  message,
  title,
  confirmAction,
  cancelAction,
  dispatchAction,
  isReduxAction,
  setOpen,
  postConfirm,
  placeholders,
  uploadFile,
  confirmLabels
}) => {
  let [selectedOptions, setSelectedOptions] = React.useState(null);
  let [formFields, setFormFields] = React.useState({});
  let [uploadedFile, setUploadedFile] = React.useState(null);

  // the selected options is only used when there are multiple sets of confirm actions
  // therefore we set it to null when the confirm action is just a single action
  React.useEffect(() => {
    const multipleQuestions = Array.isArray(confirmAction),
      selectedOptionsIsMultiple = Array.isArray(selectedOptions);
    if (multipleQuestions && !selectedOptionsIsMultiple) {
      // first option is by default the selected action for each question
      setSelectedOptions(confirmAction.map(() => 0));
    } else if (!multipleQuestions && selectedOptionsIsMultiple) {
      setSelectedOptions(null);
    }
  });

  /**
   * it sets in the state the value of the change event
   * @param {*} event - the radio change event
   * @param {Number} index - the index of the question (set of actions)
   */
  const handleOptionChange = (event, index) => {
    const newSelectedOptions = Object.assign([], selectedOptions, {
      [index]: parseInt(event.target.value)
    });
    setSelectedOptions(newSelectedOptions);
  };

  /**
   * it dispatches the cancel action if it was passed.
   */
  const close = () => {
    runAction(cancelAction);
  };

  /**
   * it dispatches the passed action to redux on confirmation and closes the dialog
   */
  const confirm = () => {
    runAction(confirmAction);
  };

  /**
   * it runs the selected confirm action for each question (each set of actions)
   * @param {*} actionSets
   */
  const runMultipleActions = actionSets => {
    Promise.all(
      actionSets.map((actionSet, index) => {
        const selectedOption = selectedOptions[index],
          actionArray = actionSet[selectedOption].actions;
        return Promise.all(actionArray.map(action => new Promise(action)));
      })
    ).then(data => postConfirm());
  };

  /**
   * it executes a confirm or cancel action if its not a redux action or dispatches the action if
   * it is a redux action
   */
  const runAction = action => {
    const multipleQuestions = Array.isArray(action);
    if (multipleQuestions) {
      runMultipleActions(confirmAction);
    } else if (action) {
      if (isReduxAction) dispatchAction(action);
      else {
        let actionArguments = [];
        if (placeholders && placeholders.length) {
          actionArguments.push(formFields);
        }
        if (uploadFile) {
          actionArguments.push(uploadedFile);
        }
        action(...actionArguments);
      }
    }
    setOpen(false);
  };

  /**
   * it sets the file in the component's state
   * @param { File[] } acceptedFiles - an array containing the dropped file
   */
  const handleFileDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setUploadedFile(file);
    }
  };
  return (
    <UnselfishDialog
      test-data="confirmDialog"
      name="confirmDialog"
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {Array.isArray(selectedOptions) && Array.isArray(message) ? (
          message.map((individualMsg, index) => {
            const setOfActions = confirmAction[index];
            return individualMsg ? (
              <>
                <DialogContentText id="alert-dialog-description">
                  <span
                    style={{ whiteSpace: "pre-wrap" }}
                    test-data="dialogMessage"
                  >
                    {individualMsg}
                  </span>
                </DialogContentText>
                {setOfActions?.length > 1 && (
                  <RadioGroup
                    aria-label="quiz"
                    name="quiz"
                    value={selectedOptions[index]}
                    onChange={event => handleOptionChange(event, index)}
                  >
                    {setOfActions.map((action, index) => (
                      <FormControlLabel
                        value={index}
                        control={<Radio />}
                        label={action.option}
                      />
                    ))}
                  </RadioGroup>
                )}
              </>
            ) : null;
          })
        ) : (
          <DialogContentText id="alert-dialog-description">
            {RegExp(
              "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
            ).test(message) ? (
              <span
                style={{ whiteSpace: "pre-wrap" }}
                test-data="dialogMessage"
              >
                <Linkify properties={{ target: "_blank" }}>{message}</Linkify>
              </span>
            ) : (
              <span
                style={{ whiteSpace: "pre-wrap" }}
                test-data="dialogMessage"
              >
                {message}
              </span>
            )}

            {placeholders &&
              placeholders.map(placeholder => (
                <TextField
                  value={formFields[placeholder] || ""}
                  onChange={e =>
                    setFormFields({
                      ...formFields,
                      [placeholder]: e.target.value
                    })
                  }
                  multiline={true}
                  rows={1}
                  rowsMax={10}
                  margin="dense"
                  placeholder={placeholder}
                  fullWidth
                />
              ))}
            {uploadFile && (
              <div style={{ width: "100%" }}>
                <div style={{ width: "80%", margin: "auto", paddingTop: 20 }}>
                  {uploadedFile && (
                    <div test-data="filename" style={{ textAlign: "center" }}>
                      {uploadedFile.name}
                    </div>
                  )}
                  <DropZone
                    onDrop={handleFileDrop}
                    accept={"application/json"}
                    loading={false}
                    message={"Upload file"}
                  />
                </div>
              </div>
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {confirmAction !== false ? (
          <Button
            test-data="confirmBtn"
            name="ConfirmButton"
            onClick={confirm}
            color="primary"
          >
            {confirmLabels ? confirmLabels[0] : "Confirm"}
          </Button>
        ) : (
          ""
        )}
        <Button
          test-data="cancelBtn"
          name="CancelButton"
          onClick={close}
          color="primary"
          autoFocus
        >
          {confirmLabels ? confirmLabels[1] : "Cancel"}
        </Button>
      </DialogActions>
    </UnselfishDialog>
  );
};

const mapStateToProps = state => ({
  isOpen: Selector.isOpen(state),
  title: Selector.getTitle(state),
  message: Selector.getMessage(state),
  confirmAction: Selector.getConfirmAction(state),
  cancelAction: Selector.getCancelAction(state),
  isReduxAction: Selector.isReduxAction(state),
  postConfirm: Selector.getPostConfirm(state),
  placeholders: Selector.getPlaceholders(state),
  uploadFile: Selector.getUploadFile(state),
  confirmLabels: Selector.getConfirmLabels(state)
});

const mapDispatchToProps = dispatch => {
  return {
    setOpen: open => dispatch(Action.setOpen(open)),
    dispatchAction: action => dispatch(action)
  };
};

ConfirmDialog.propTypes = {
  /**
   * Whether the dialog is open
   */
  isOpen: PropTypes.bool.isRequired,

  /**
   * The dialog's message
   */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

  /**
   * The dialog's title
   */
  title: PropTypes.string.isRequired,
  /**
   * The dialog's confirm action that can be either a redux action or a function
   */
  confirmAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array
  ]),
  /**
   * The dialog's cancel action that can be either a redux action or a function
   */
  cancelAction: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * The redux dispatcher to dispatch passed redux actions
   */
  dispatchAction: PropTypes.func.isRequired,
  /**
   * It tells whether the passed actions are redux or not
   */
  isReduxAction: PropTypes.bool.isRequired,
  /**
   * a function that dispatches whether the dialog is open or not
   */
  setOpen: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog);
