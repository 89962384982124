import React, { PureComponent } from "react";
import { MetaComponentPaths } from "MetaComponent/MetaComponent";
import DesignJobDropdown from "MetaComponent/containers/DesignCanvas/components/DesignJobDropdown/DesignJobDropdown";
import DesignSelector from "MetaComponent/selectors/Design";
import RayTracingSelector from "MetaComponent/selectors/RayTracing";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import {
  Grid,
  Paper,
  TextField,
  withStyles,
  Typography,
  LinearProgress,
  Button
} from "@material-ui/core";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import JobActions from "components/JobActions/JobActions";
import { dispatch } from "d3";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import JsonDialog from "components/JsonDialog/JsonDialog";
import RayTracingVariables from "./components/RayTracingVariables";
import RayTracingForm from "./components/RayTracingForm";
import DesignApi from "MetaComponent/api/Design";
import SetPointSelector from "MetaComponent/selectors/SetPoint";
import RayTracingApi from "MetaComponent/api/RayTracing";
import RayTracingAction from "MetaComponent/actions/RayTracing";
import RayTracingJobDropdown from "./components/RayTracingJobDropdown";
import RayTracingJobResult from "./components/RayTracingJobResult";
import DesignAction from "MetaComponent/actions/Design";
import debounce from "lodash.debounce";
import Spinner from "components/Spinner/Spinner";

export const styles = theme => ({
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  },
  left: {
    paddingRight: 260
  },
  nameInput: {
    marginLeft: 10
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    width: "100%"
  }
});

const RayTracingJobSubmissionMode = Object.freeze({
  CREATE: "create",
  UPDATE: "update"
});

export class RayTracingCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      jsonToShow: null,
      polling: true,
      rayTracingName: this.getDefaultRayTacingName(),
      exporting: false,
      exportingPsrt: false,
      message: null,
      valueSubmissionIsReady: false,
      defaultValues: null
    };
    this.rayTracingFormSubmitter = null;
  }

  bindrayTracingFormSubmitter = formSubmitter => {
    this.rayTracingFormSubmitter = formSubmitter;
  };

  componentDidMount() {
    this.props.setPage(MetaComponentPaths.RAY_TRACING_LINK);
    this.init();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedRayTracingJobId, selectedDesignJobId } = this.props;
    this.submitRayTracingJobIfReady(prevState);
    const rayTracingJobChanged =
        prevProps.selectedRayTracingJobId !== selectedRayTracingJobId,
      designJobChanged = prevProps.selectedDesignJobId !== selectedDesignJobId;
    if (rayTracingJobChanged || designJobChanged) {
      this.init();
    }
  }

  async init() {
    const { rayTracingJobs, selectedRayTracingJobId } = this.props;
    const defaultValues = await this.getdefaultValues();
    this.setState(
      {
        defaultValues,
        rayTracingName: selectedRayTracingJobId
          ? rayTracingJobs.byId[selectedRayTracingJobId].name
          : this.getDefaultRayTacingName(),
        polling: selectedRayTracingJobId !== null
      },
      () => this.getRayTracingJobProgress()
    );
  }

  async getRayTracingJobProgress() {
    const { polling } = this.state,
      {
        selectedRayTracingJobId,
        getRayTracingJobProgressAction,
        rayTracingJobs
      } = this.props;
    if (selectedRayTracingJobId) {
      try {
        const selectedRayTracingJob = selectedRayTracingJobId
          ? rayTracingJobs.byId[selectedRayTracingJobId]
          : null;
        const oldProgress = selectedRayTracingJob?.progress;
        const data = await getRayTracingJobProgressAction(
          selectedRayTracingJobId
        );
        if (oldProgress !== data.progress) {
          this.setState(
            {
              polling: false
            },
            () => {
              this.setState({
                polling: true
              });
            }
          );
        }
        if (
          data.status === "ERROR" ||
          data.status === "DONE" ||
          data.status === "STOPPED" ||
          data.status === "FAILED"
        ) {
          this.setState({ polling: false });
        } else if (polling) {
          this.getRayTracingJobProgressWithDelay();
        }
      } catch (exception) {
        console.log(exception);
        if (polling) {
          this.setState({ polling: false });
        }
      }
    } else {
      this.setState({ polling: false });
    }
  }

  getRayTracingJobProgressWithDelay = debounce(() => {
    this.getRayTracingJobProgress();
  }, 5000);

  async getdefaultValues() {
    const { selectedDesignJobId } = this.props;
    if (selectedDesignJobId) {
      const targets = await DesignApi.getDesignJobTargets(selectedDesignJobId);
      const nfwf = targets.nfwfs[0];
      const setPoint = targets.set_points[0];
      const {
          incident_light_type,
          incident_light,
          diffractive_order,
          unit
        } = setPoint,
        { azimut, zenit, wavelength, amplitude, polarization } = incident_light,
        { X, Y } = diffractive_order,
        { polarization_out } = nfwf;
      return {
        azimut,
        zenit,
        wavelength,
        amplitude,
        polarization_in: polarization,
        polarization_out,
        incident_light_type,
        X,
        Y,
        unit,
        order_x: 0,
        order_y: 0,
        polynomial: "ZER",
        max_nr_polynomial: 15,
        rmse_threshold: 0.44,
        direction_out: "Transmission"
      };
    }
    return null;
  }

  getDefaultRayTacingName() {
    const { selectedDesignJobId, rayTracingJobs } = this.props;
    const designRayTracingJobs = Object.values(rayTracingJobs.byId).filter(
      rayTracingJobs => rayTracingJobs.design_job === selectedDesignJobId
    );
    return `Ray Tracing Link ${designRayTracingJobs.length + 1}`;
  }

  getDesignJobsList = designJobs => {
    const { openMetaComponentId } = this.props;
    return Object.values(designJobs.byId).filter(
      job =>
        job.meta_component === openMetaComponentId &&
        (job.status === "ERROR" || job.status === "DONE")
    );
  };

  validateForm = submissionMode => {
    this.setState({ submissionMode });
    this.rayTracingFormSubmitter();
  };

  onShowNewJobDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "New Ray Tracing Link";
    const message = `Are you sure you want to create a new ray tracing link?`;
    const confirmAction = () =>
      this.validateForm(RayTracingJobSubmissionMode.CREATE);
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  onShowUpdateJobDialog = () => {
    const { showConfirmDialog } = this.props;
    const title = "Update Ray Tracing Link";
    const message = `Are you sure you want to rerun the ray tracing link with the actual parameters?`;
    const confirmAction = () =>
      this.validateForm(RayTracingJobSubmissionMode.UPDATE);
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  getRayTracingJobsList = rayTracingJobs => {
    const { selectedDesignJobId } = this.props;
    return Object.values(rayTracingJobs.byId).filter(
      job => job.design_job === selectedDesignJobId
    );
  };

  onShowResultsErrorsAndWarnings = errors => {
    this.setState({
      jsonToShow: {
        errors
      }
    });
  };

  hideJsonDialog = () => {
    this.setState({ jsonToShow: null });
  };

  onExportRayTracing = () => {
    this.setState({ exporting: true });
    const { selectedRayTracingJobId, rayTracingJobs } = this.props;
    const rayTracingJob = rayTracingJobs.byId[selectedRayTracingJobId];
    return RayTracingApi.exportRayTracing(selectedRayTracingJobId)
      .then(data => {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${rayTracingJob.name}.data.zip`);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error))
      .finally(() => this.setState({ exporting: false }));
  };

  onExportRayTracingPsrt = () => {
    this.setState({ exportingPsrt: true });
    const { selectedRayTracingJobId, rayTracingJobs } = this.props;
    const rayTracingJob = rayTracingJobs.byId[selectedRayTracingJobId];
    return RayTracingApi.exportRayTracingPsrt(selectedRayTracingJobId)
      .then(data => {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "application/octet-stream" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `raytracing-${rayTracingJob.id}.psrt`);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error))
      .finally(() => this.setState({ exportingPsrt: false }));
  };

  onDownloadDllEssentials = () => {
    const link = document.createElement("a");
    link.href =
      "https://pos-public-docs.s3.eu-west-1.amazonaws.com/POS_ZEMAX_DLLS.zip";
    link.click();
  };

  getSetPoint(rayTracingJob) {
    const {
      diffractive_order,
      incident_light,
      set_point_unit,
      polarization,
      order_out
    } = rayTracingJob;
    return {
      diffractive_order,
      incident_light,
      polarization,
      order_out,
      unit: set_point_unit
    };
  }

  getTempVariables() {
    return this.props.tempVariables.map(tempVariable => ({
      ...tempVariable,
      variableName: tempVariable.name,
      simulation: -1 // since sweep inputs requires a simulation id, -1 means that there is no simulationId
    }));
  }

  getOutgoingConditions(rayTracingJob) {
    const {
      order_out,
      polynomial,
      max_nr_polynomial,
      rmse_threshold,
      direction_out
    } = rayTracingJob;
    return {
      order_out,
      polynomial,
      max_nr_polynomial,
      rmse_threshold,
      direction_out
    };
  }

  setValuesReady = values => {
    this.setState({
      dataToSubmit: {
        ...this.state.dataToSubmit,
        ...values
      },
      valueSubmissionIsReady: true
    });
  };

  handlePermissionError(response) {
    if (response.status === 403) {
      this.setState({ polling: false, message: null }, () =>
        this.setState({ message: response.data.detail })
      );
    } else if (response.status === 503) {
      this.setState({ polling: false, message: null });
    }
  }

  getDataToSubmit() {
    const { selectedDesignJobId } = this.props,
      { rayTracingName, dataToSubmit } = this.state;
    return {
      ...dataToSubmit,
      set_point_unit: dataToSubmit.unit,
      swept_variables: this.getTempVariables(),
      design_job: selectedDesignJobId,
      name: rayTracingName ? rayTracingName : this.getDefaultRayTacingName()
    };
  }

  createDesignRayTracingLink = async () => {
    const { createRayTracingLink, selectRayTracingJob } = this.props;
    try {
      const id = await createRayTracingLink(this.getDataToSubmit());
      selectRayTracingJob(id);
    } catch (e) {
      this.handlePermissionError(e.response);
    }
    return;
  };

  submitRayTracingJobIfReady(prevState) {
    const { valueSubmissionIsReady, submissionMode } = this.state;
    if (prevState.valueSubmissionIsReady !== valueSubmissionIsReady) {
      if (valueSubmissionIsReady) {
        this.setState(
          {
            valueSubmissionIsReady: false,
            polling: true
          },
          () => {
            if (submissionMode === RayTracingJobSubmissionMode.CREATE) {
              this.createDesignRayTracingLink();
            } else if (submissionMode === RayTracingJobSubmissionMode.UPDATE) {
              this.updateRayTracingJob();
            }
          }
        );
      }
    }
  }

  updateRayTracingJob = async () => {
    const { updateRayTracingLink, selectedRayTracingJobId } = this.props;
    try {
      await updateRayTracingLink(
        selectedRayTracingJobId,
        this.getDataToSubmit()
      );
      this.getRayTracingJobProgress();
    } catch (e) {
      console.log("eeeeeee", e);
      this.handlePermissionError(e.response);
    }
    return;
  };

  getUsedVariables(job) {
    if (job) {
      const { swept_variables } = job;
      if (swept_variables.length) {
        const jobstring = JSON.stringify(job);
        return swept_variables.filter(variable =>
          jobstring.includes(`"=${variable.name}"`)
        );
      }
    }
    return [];
  }

  stopRayTracingJob = jobId => {
    return RayTracingApi.stopRayTracingJob(jobId).then(response => {
      this.getRayTracingJobProgress();
      return Promise.resolve();
    });
  };

  render() {
    const {
        classes,
        designJobs,
        rayTracingJobs,
        setPoints,
        selectedRayTracingJobId
      } = this.props,
      { polling, jsonToShow, message, defaultValues } = this.state,
      designJobsList = this.getDesignJobsList(designJobs),
      rayTracingJobsList = this.getRayTracingJobsList(rayTracingJobs),
      selectedRayTracingJob = selectedRayTracingJobId
        ? rayTracingJobs.byId[selectedRayTracingJobId]
        : null,
      setPoint = selectedRayTracingJobId
        ? this.getSetPoint(selectedRayTracingJob)
        : null,
      outgoingConditions = selectedRayTracingJob
        ? this.getOutgoingConditions(selectedRayTracingJob)
        : null,
      sweptVariables = selectedRayTracingJob
        ? selectedRayTracingJob.swept_variables
        : [];
    return (
      <div>
        <div className={classes.wrapper}>
          {polling && (
            <div className={classes.progress}>
              {selectedRayTracingJob && (
                <Typography>
                  Ray Tracing status: {selectedRayTracingJob.status}
                </Typography>
              )}
              <div className={classes.wrapper}>
                <Spinner name="Waiting" size={68} timeout={180000} />
              </div>
              <div className={classes.wrapper}>
                {selectedRayTracingJob && (
                  <Typography>{`${selectedRayTracingJob.progress} %`}</Typography>
                )}
              </div>
              <br />
              {selectedRayTracingJob && (
                <>
                  <LinearProgress
                    name="RayTracingProgress"
                    variant="determinate"
                    value={
                      selectedRayTracingJob.progress &&
                      selectedRayTracingJob.progress
                    }
                  />
                  {(selectedRayTracingJob.status === "RUNNING" ||
                    selectedRayTracingJob.status === "QUEUED") && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "relative",
                        marginTop: 20
                      }}
                    >
                      <Button
                        test-data="stopBtn"
                        name="StopRayTracingButton"
                        variant="contained"
                        onClick={() =>
                          this.stopRayTracingJob(selectedRayTracingJob.id)
                        }
                      >
                        Stop Ray Tracing
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {!polling && (
            <div>
              <div>
                <div>
                  {selectedRayTracingJob &&
                    selectedRayTracingJob.status === "DONE" && (
                      <RayTracingJobResult
                        jobId={selectedRayTracingJob.id}
                        sweptVariables={this.getUsedVariables(
                          selectedRayTracingJob
                        )}
                        setPoint={setPoint}
                        outgoingConditions={outgoingConditions}
                        width={selectedRayTracingJob.width}
                        height={selectedRayTracingJob.height}
                        rmseThreshold={selectedRayTracingJob.rmse_threshold}
                      />
                    )}
                </div>
                <div className={classes.left}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.nameInput}
                        name="name"
                        label="Name"
                        value={this.state.rayTracingName}
                        onChange={e =>
                          this.setState({ rayTracingName: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RayTracingVariables sweptVariables={sweptVariables} />
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paper} elevation={3}>
                        {defaultValues && (
                          <RayTracingForm
                            setPoint={setPoint}
                            isEditing={true}
                            bindResetForm={() => {}}
                            bindSubmitForm={this.bindrayTracingFormSubmitter}
                            isSweep
                            sweptVariables={this.getTempVariables()}
                            onSubmit={this.setValuesReady}
                            defaultValues={defaultValues}
                            disableAccuracy={true}
                            outgoingConditions={outgoingConditions}
                          />
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={classes.right}>
                <div>
                  <DesignJobDropdown designJobs={designJobsList} />
                </div>
                <div>
                  <RayTracingJobDropdown rayTracingJobs={rayTracingJobsList} />
                </div>
                <div>
                  <JobActions
                    jobType={"ray tracing link"}
                    onNewJob={this.onShowNewJobDialog}
                    showUpdateJobDialog={
                      selectedRayTracingJob && this.onShowUpdateJobDialog
                    }
                    exportAnalysis={
                      selectedRayTracingJob &&
                      selectedRayTracingJob.status !== "STOPPED" &&
                      this.onExportRayTracing
                    }
                    exporting={this.state.exporting}
                    showResultsErrorsAndWarnings={
                      selectedRayTracingJob &&
                      selectedRayTracingJob.errors &&
                      (() =>
                        this.onShowResultsErrorsAndWarnings(
                          selectedRayTracingJob.errors
                        ))
                    }
                    extraButtons={[
                      {
                        testData: "exportPsrtButton",
                        name: "Export PSRT file",
                        onClick: this.onExportRayTracingPsrt,
                        status: this.state.exportingPsrt,
                        disabled: !(
                          selectedRayTracingJob &&
                          selectedRayTracingJob.status === "DONE"
                        )
                      },
                      {
                        testData: "downloadDllEssentials",
                        name: "Export DLL essentials",
                        onClick: this.onDownloadDllEssentials,
                        status: false
                      }
                    ]}
                    errors={selectedRayTracingJob?.errors}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <JsonDialog
          open={jsonToShow !== null}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />
        {message && <DirectionSnackbar message={message} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    designJobs: DesignSelector.getDesignJobs(state),
    selectedDesignJobId: DesignSelector.getSelectedJobId(state),
    selectedRayTracingJobId: RayTracingSelector.getSelectedJobId(state),
    tempVariables: RayTracingSelector.getTempSweptVariables(state),
    rayTracingJobs: RayTracingSelector.getRayTracingJobs(state),
    setPoints: SetPointSelector.getSetPoints(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createRayTracingLink: designJobId =>
      dispatch(RayTracingApi.start(designJobId)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    selectRayTracingJob: jobId => dispatch(RayTracingAction.selectJob(jobId)),
    getRayTracingJobProgressAction: jobId =>
      dispatch(RayTracingApi.getRayTracingJobProgress(jobId)),
    updateRayTracingLink: (raytracingId, properties) =>
      dispatch(RayTracingApi.restart(raytracingId, properties)),
    resetDesignJobs: () => dispatch(DesignAction.resetJobs())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(RayTracingCanvas)));
