import React, { Component, forwardRef } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { register, resetRegister } from "./actions";
import { retrieveRegisterData } from "./selectors";
import {
  Input,
  Button,
  Select,
  withStyles,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Link,
  Grid
} from "@material-ui/core";
import * as Yup from "yup";
import AuthLayout from "./AuthLayout";
import Recaptcha from "react-recaptcha";
import DocumentTitle from "react-document-title";
import countries from "assets/countries.json";
import Autocomplete from "../components/Autocomplete";
import { Link as RouterLink } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import omit from "lodash.omit";
import { Redirect } from "react-router-dom";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import ConfirmDialog from "BaseApp/ConfirmDialog/ConfirmDialog";
import jsPDF from "jspdf";
import logo from "assets/planopsim_doc_logo.png";
import Spinner from "components/Spinner/Spinner";
import { findKey } from "lodash";

const requiredFieldText = "This field is required";

const registerSchema = Yup.object().shape({
  title: Yup.string().required(requiredFieldText),
  username: Yup.string()
    .required(requiredFieldText)
    .matches("^[A-Za-z][A-Za-z0-9]*$", "Invalid username"),
  password: Yup.string()
    .required(requiredFieldText)
    .min(8, "Must have at least 8 characters")
    .max(100, "Must have at most 100 characters"),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required(requiredFieldText),
  firstName: Yup.string().required(requiredFieldText),
  lastName: Yup.string().required(requiredFieldText),
  email: Yup.string()
    .email("Please enter a valid e-mail address")
    .required(requiredFieldText),
  emailRepeat: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails do not match")
    .required(requiredFieldText),
  company: Yup.string().required(requiredFieldText),
  jobTitle: Yup.string().required(requiredFieldText),
  streetName: Yup.string().required(requiredFieldText),
  streetNumber: Yup.string().required(requiredFieldText),
  zipCode: Yup.string().required(requiredFieldText),
  city: Yup.string().required(requiredFieldText),
  country: Yup.string().required(requiredFieldText),
  phone: Yup.string().required(requiredFieldText)
});

const mapState = state => ({
  registerData: retrieveRegisterData(state)
});

const mapDispatch = dispatch => ({
  register: formData => dispatch(register(formData)),
  resetRegister: () => dispatch(resetRegister()),
  showConfirmDialog: (
    title,
    message,
    confirmAction,
    cancelAction,
    isReduxAction,
    postConfirm,
    inputPlaceholders,
    uploadFile,
    confirmLabels
  ) =>
    dispatch(
      ConfirmDialogAction.show(
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction,
        postConfirm,
        inputPlaceholders,
        uploadFile,
        confirmLabels
      )
    )
});

const styles = {
  field: {
    marginTop: "5px",
    width: "100%"
  },
  validationMessage: {
    height: "25px",
    padding: "3px 0",
    color: "red"
  },
  recaptcha: {
    margin: "10px 0"
  },
  select: {
    width: "100%"
  },
  bottomText: {
    fontWeight: 500,
    marginTop: 5
  },
  bottom: {
    marginTop: 40,
    textAlign: "center"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
};

const AdapterLink = forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

/**
 * A component to allow the users to register. When the registration
 * is complete, the user should be redirected to RegisterComplete component
 */
export class Register extends Component {
  countrySuggestions = [];

  constructor(props) {
    super(props);

    this.state = {
      recaptchaVerified: false,
      recaptchaLoaded: false,
      recaptchaExpired: false,
      showPassword: false,
      showPasswordRepeat: false
    };
  }

  componentDidMount() {
    this.countrySuggestions = countries.map(val => ({
      label: val.country,
      value: val.country
    }));
  }

  componentWillUnmount = () => {
    const { resetRegister } = this.props;
    resetRegister();
  };

  recaptchaOnloadCallback = () => {
    this.setState({ recaptchaLoaded: true });
  };

  recaptchaVerifyCallback = () => {
    this.setState({ recaptchaVerified: true });
    this.setState({ recaptchaExpired: false });
  };

  recaptchaOnExpiredCallback = () => {
    this.setState({ recaptchaExpired: true });
  };

  togglePasswordVisibility = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  togglePasswordRepeatVisibility = () => {
    const { showPasswordRepeat } = this.state;
    this.setState({ showPasswordRepeat: !showPasswordRepeat });
  };

  donwloadPDF = pdfurl => {
    var link = document.createElement("a");
    link.download = `agreement.pdf`;
    link.href = pdfurl;
    link.click();
  };

  handleSubmit = data => {
    const { recaptchaLoaded, recaptchaVerified, recaptchaExpired } = this.state;
    const { register, registerData, showConfirmDialog } = this.props;
    if (registerData && registerData.loading) {
      return;
    } else if (
      process.env.REACT_APP_DEVELOPMENT === "true" ||
      (recaptchaLoaded && recaptchaVerified && !recaptchaExpired)
    ) {
      const agreementPDFDoc = this.createAgreementPDF(data);
      const agreementPDFfile = new File(
        [agreementPDFDoc.output("blob")],
        "agreement.pdf"
      );
      const agreementPDFBlobUrl = agreementPDFDoc.output("bloburl");
      const registerUser = receive_newsletter => {
        register(
          omit({ ...data, receive_newsletter, agreement: agreementPDFfile }, [
            "passwordRepeat",
            "emailRepeat"
          ])
        );
      };
      showConfirmDialog(
        "License Agreement",
        [
          <div>
            <iframe
              test-data="document_frame"
              src={agreementPDFBlobUrl}
              frameborder="1"
              scrolling="auto"
              height="100%"
              width="100%"
            ></iframe>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                variant="contained"
                color="primary"
                test-data="downloadAgreementButton"
                onClick={() => this.donwloadPDF(agreementPDFBlobUrl)}
              >
                Download Agreement
              </Button>
            </div>
          </div>
        ],
        [
          [
            {
              option: "I want to receive newsletter emails from PlanOpSim",
              actions: [
                (resolve, reject) => {
                  registerUser(true);
                  resolve();
                }
              ]
            },
            {
              option:
                "I don't want to receive newsletter emails from PlanOpSim",
              actions: [
                (resolve, reject) => {
                  registerUser(false);
                  resolve();
                }
              ]
            }
          ]
        ],
        () => {},
        false,
        () => {},
        false,
        false,
        ["agree", "disagree"]
      );
    }
  };

  getPDFTextHeight(doc, text) {
    return (
      (text.split("\n").length * doc.internal.getLineHeight()) /
      doc.internal.scaleFactor
    );
  }

  addPDFHeader(doc) {
    const logoImg = new Image();
    logoImg.src = logo;
    const marginTop = 15;
    const imageHeight = 14;
    doc.addImage(logoImg, "PNG", 115, marginTop, 70, imageHeight);
    const finalY = marginTop + imageHeight;
    return finalY;
  }

  addPDFParagraph(doc, text, startingY) {
    const finalText = doc.splitTextToSize("\n" + text, 170).join("\n");
    return this.checkIfFitsPDFAndAddText(
      doc,
      12,
      "normal",
      finalText,
      20,
      startingY
    );
  }

  addPDFText(doc, text, startingX, startingY, textHeight, options = {}) {
    doc.text(text, startingX, startingY, options);
    const finalY = startingY + textHeight;
    return finalY;
  }

  addPDFFooter(doc, fontSize) {
    doc.setFontSize(10);
    doc.setFontType("normal");
    doc.text("Confidential", 20, 282);
    if (fontSize) {
      doc.setFontSize(fontSize);
    }
  }

  checkIfFitsPDFAndAddText(
    doc,
    fontSize,
    fontType,
    text,
    startingX,
    startingY,
    options
  ) {
    doc.setFontType(fontType);
    doc.setFontSize(fontSize);
    const textHeight = this.getPDFTextHeight(doc, text);
    if (startingY + textHeight < 267) {
      return this.addPDFText(
        doc,
        text,
        startingX,
        startingY,
        textHeight,
        options
      );
    }
    this.addPDFFooter(doc, fontSize);
    doc.addPage();
    const newStartingY = this.addPDFHeader(doc);
    return this.addPDFText(
      doc,
      text,
      startingX,
      newStartingY,
      textHeight,
      options
    );
  }

  addPDFTitle(doc, text, startingY) {
    return this.checkIfFitsPDFAndAddText(
      doc,
      14,
      "bold",
      text,
      105,
      startingY,
      { align: "center" }
    );
  }

  addPDFSubtitle(doc, text, startingY) {
    return this.checkIfFitsPDFAndAddText(
      doc,
      12,
      "bold",
      "\n" + text,
      105,
      startingY,
      { align: "center" }
    );
  }

  createAgreementPDF(data) {
    var doc = new jsPDF();
    let finalY = this.addPDFHeader(doc);

    doc.setFontSize(12);
    const headerInfo =
      "Planopsim NV\nPrinses Clementinalaan 177\n9000 Gent, Belgium\nVAT: BE 0717 781 588\nT: +32 485 56 57 72";
    const headerInfoStartingY = finalY + 6;
    doc.text(190, headerInfoStartingY, headerInfo, { align: "right" });
    const headerInfoHeight = this.getPDFTextHeight(doc, headerInfo);
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    const emailInfoStartingY = headerInfoStartingY + headerInfoHeight;
    doc.text(190, emailInfoStartingY, "info@planopsim.com", { align: "right" });
    doc.setTextColor(0, 0, 0);

    const titleStartingY = emailInfoStartingY + 20;
    finalY = this.addPDFTitle(
      doc,
      "END USER LICENSE AGREEMENT ( EULA )",
      titleStartingY
    );

    finalY = this.addPDFParagraph(doc, "", finalY);

    finalY = this.addPDFParagraph(
      doc,
      `This agreement (or " EULA " ) is a legal agreement between ${data.company} located at ${data.streetName} ${data.streetNumber}, ${data.zipCode} ${data.city}, ${data.country} ( " the Client " ), represented by ${data.title} ${data.firstName} ${data.lastName} whose occupation is ${data.jobTitle}, that has licensed a software product ( " Product " ) and PlanOpSim NV, Boterbloemstraat 29, 9090 Melle, Belgium ( " Licensor " or " Application Provider " )`,
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      "By installing and/or using any Product provided by the Licensor the Client is confirming his acceptance of this agreement and is agreeing to become bound by the terms of it.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      "The EULA shall apply only to a Product supplied by the Licensor herewith.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      'The Product is licensed, not sold, to the Client for use only under the terms of this Licence. The Application Provider reserves all rights not expressly granted to the Client. The Product that is subject to this Licence is referred to in this License as the " Licensed Application ".',
      finalY
    );

    finalY = this.addPDFParagraph(doc, "a. Scope of License.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      "This license granted to the Client for the Licensed Application by the Application Provider is limited to a non-transferable license to use the Licensed Application.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      "The Client may not rent, lease, lend, sell, redistribute or sublicense the Licensed Application. The Client may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify or create derivative works of the Licensed Application, any updates or any part thereof. Any attempt to do so is a violation of the rights of the Application Provider and its licensors.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      "The terms of the License will govern any upgrades provided by the Application Provider that replace and/or supplement the original Product, unless such upgrade is accompanied by a separate license in which case the terms of that license will govern.",
      finalY
    );

    finalY = this.addPDFParagraph(doc, "b. Consent to use of data.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      "The Client agrees that Application Provider may collect and use technical data and related information, including but not limited to technical information about the Client's device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to the Client related to the Licensed Application. Application Provider may use this information to improve its products or to provide services or technologies to the Client.",
      finalY
    );

    finalY = this.addPDFParagraph(doc, "c. Termination.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      "Termination is regulated by the conditions mentioned in EXHIBIT I. The Client's rights under this license will terminate automatically without notice from the Application Provider if the Client fails to comply with any term of this license. Upon termination of the license the Client shall cease all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      "d. Services ; third party materials.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      `The Licensed Application may enable access to Application Provider's and third party services and websites ( collectively and individually " Services " ) Use of the Services may require Internet access (if chosen for Cloud Access) and the Client's acceptance of additional terms of service.`,
      finalY
    );

    finalY = this.addPDFParagraph(doc, "e. No warranty.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      "The Client expressly acknowledges and agrees that use of the Licensed Application and of the Services defined sub § d are at his sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with him.",
      finalY
    );

    finalY = this.addPDFParagraph(doc, "f. Limitation of Liability.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      "To the extent not prohibited by law, in no event shall Application Provider be liable for personal injury, or any incidental, special, indirect or consequential damages whatsoever, including, without limitation, damages for loss of profits, loss of data, business interruption or any other commercial damages or losses, arising out of or related to the Client's use or inability to use the Licensed Application, however caused, regardless of the theory of liability ( contract, tort or otherwise ) and even if Application Provider has been advised of the possibility of such damages.",
      finalY
    );

    finalY = this.addPDFParagraph(
      doc,
      "In no event shall Application Provider's total liability to the Client for all damages exceed the lesser of the licence price as stipulated in Exhibit I and 1 000 (one thousand) Euro.",
      finalY
    );

    finalY = this.addPDFParagraph(doc, "g. Applicable Law.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      "The laws of Belgium govern this license and the Client's use of the Licensed Application.",
      finalY
    );

    finalY = this.addPDFTitle(doc, "EXHIBIT I", finalY + 10);

    finalY = this.addPDFSubtitle(doc, "Specific terms of license.", finalY);

    finalY = this.addPDFParagraph(
      doc,
      `1. Licensed application: as specified in the Purchase Order.\n2. Licence price: as specified in the Purchase Order.\n3. Termination: the license will terminate at the end date of the license period specified in the Purchase Order.\n4. Miscellaneous: specific conditions (e.g. concerning technical support) are applicable when indicated by PlanOpSim in its offer as accepted by the Client in his Purchase Order. Technical support includes coaching regarding the use of the software and in the case of any software problem helping in finding a solution, correction or work-around. Technical support entails a commitment to provide a best effort in a professional manner to a reasonable extent, but not a result obligation. Further professional assistance e.g. in terms of photonic problem analysis or execution of the actual design can be performed through additional consulting agreements between PlanOpSim and ${data.company}`,
      finalY
    );

    this.addPDFFooter(doc);
    return doc;
  }

  render = () => {
    const { registerData, classes } = this.props;
    const {
      showPassword,
      showPasswordRepeat,
      recaptchaVerified,
      recaptchaExpired
    } = this.state;
    const { error } = registerData || {};
    const { data } = registerData || {};
    const usernameError = data && data.username;
    return registerData && registerData.success ? (
      <Redirect to="/register-complete" />
    ) : (
      <>
        <DocumentTitle title="Register">
          <AuthLayout title="Register">
            <div
              className={classes.validationMessage}
              style={{ textAlign: "center" }}
            >
              {error && usernameError}
              {error && !usernameError && "Something went wrong"}
            </div>

            <Formik
              initialValues={{
                username: "",
                password: "",
                passwordRepeat: "",
                title: "",
                firstName: "",
                lastName: "",
                email: "",
                emailRepeat: "",
                company: "",
                jobTitle: "",
                streetName: "",
                streetNumber: "",
                zipCode: "",
                city: "",
                country: "",
                phone: ""
              }}
              validationSchema={registerSchema}
              onSubmit={this.handleSubmit}
            >
              {({
                errors,
                values,
                handleChange,
                handleBlur,
                touched,
                setFieldValue
              }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        placeholder="Username *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.username && errors.username}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        placeholder="Password *"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.togglePasswordVisibility}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <div className={classes.validationMessage}>
                        {touched.password && errors.password}
                      </div>
                    </Grid>

                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                      <Input
                        className={classes.field}
                        type={showPasswordRepeat ? "text" : "password"}
                        name="passwordRepeat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.passwordRepeat}
                        placeholder="Repeat Password *"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password repeat visibility"
                              onClick={this.togglePasswordRepeatVisibility}
                            >
                              {showPasswordRepeat ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <div className={classes.validationMessage}>
                        {touched.passwordRepeat && errors.passwordRepeat}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        placeholder="FirstName *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.firstName && errors.firstName}
                      </div>
                    </Grid>

                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                      <Input
                        className={classes.field}
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        placeholder="Lastname *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.lastName && errors.lastName}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <FormControl
                        className={classes.field}
                        style={{ marginTop: -11 }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Title *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          test-data="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{
                            name: "title",
                            id: "title"
                          }}
                        >
                          <MenuItem value="Mr.">Mr.</MenuItem>
                          <MenuItem value="Ms.">Ms.</MenuItem>
                          <MenuItem value="Dr.">Dr.</MenuItem>
                          <MenuItem value="Prof.">Prof.</MenuItem>
                        </Select>
                      </FormControl>
                      <div className={classes.validationMessage}>
                        {touched.title && errors.title}
                      </div>
                    </Grid>

                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                      <Input
                        className={classes.field}
                        name="jobTitle"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.jobTitle}
                        placeholder="Job title"
                      />
                      <div className={classes.validationMessage}>
                        {touched.jobTitle && errors.jobTitle}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        placeholder="E-mail *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.email && errors.email}
                      </div>
                    </Grid>

                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                      <Input
                        className={classes.field}
                        name="emailRepeat"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailRepeat}
                        placeholder="Repeat E-mail *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.emailRepeat && errors.emailRepeat}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        placeholder="Phone number *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.phone && errors.phone}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="company"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                        placeholder="Company/Institution *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.company && errors.company}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="streetName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetName}
                        placeholder="Street name *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.streetName && errors.streetName}
                      </div>
                    </Grid>

                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                      <Input
                        className={classes.field}
                        name="streetNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.streetNumber}
                        placeholder="Street number *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.streetNumber && errors.streetNumber}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Input
                        className={classes.field}
                        name="zipCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zipCode}
                        placeholder="Zip code *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.zipCode && errors.zipCode}
                      </div>
                    </Grid>

                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                      <Input
                        className={classes.field}
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        placeholder="City *"
                      />
                      <div className={classes.validationMessage}>
                        {touched.city && errors.city}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                      <Autocomplete
                        id="country"
                        name="country"
                        placeholder="Country *"
                        options={this.countrySuggestions.map(({ label }) => ({
                          id: label,
                          name: label
                        }))}
                        value={values.country}
                        initialInputValue={""}
                        onChange={newValue =>
                          setFieldValue("country", newValue)
                        }
                        onBlur={handleBlur}
                      />
                      <div className={classes.validationMessage}>
                        {touched.country && errors.country}
                      </div>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    className={classes.recaptcha}
                  >
                    <Recaptcha
                      sitekey="6LddXp4kAAAAAPPY4heeQzI15h1_b1pSyzZAhk5e"
                      render="explicit"
                      verifyCallback={this.recaptchaVerifyCallback}
                      onloadCallback={this.recaptchaOnloadCallback}
                      expiredCallback={this.recaptchaOnExpiredCallback}
                    />
                  </div>
                  <div
                    className={classes.validationMessage}
                    style={{ textAlign: "center" }}
                  >
                    {!recaptchaVerified && "Please verify recaptcha"}
                    {recaptchaExpired &&
                      "Your recatpcha has expired, please verify again"}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      className={classes.field}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={registerData && registerData.loading}
                      test-data="submitRegistration"
                      onClick={() => {
                        const invalidInput = findKey(
                          errors,
                          key => key !== undefined
                        );
                        window.scrollTo(
                          0,
                          window.pageYOffset +
                            document.getElementsByName(invalidInput)[0]
                        );
                      }}
                    >
                      Submit
                    </Button>
                    {registerData && registerData.loading && (
                      <Spinner
                        name="Waiting"
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <div className={classes.bottom}>
                    <Typography>Have an account ?</Typography>
                    <Link component={AdapterLink} to="/login">
                      <Typography className={classes.bottomText}>
                        LOGIN
                      </Typography>
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </AuthLayout>
        </DocumentTitle>
        <ConfirmDialog />
      </>
    );
  };
}

export default connect(mapState, mapDispatch)(withStyles(styles)(Register));
