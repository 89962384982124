export const colorScaleOptions = [
  {
    id: 1,
    name: "Jet",
    value: "Jet",
    previewImageName: "jet.jpg"
  },
  {
    id: 2,
    name: "Greys",
    value: "Greys",
    previewImageName: "greys.jpg"
  },
  {
    id: 3,
    name: "Rd-Bu",
    value: "RdBu",
    previewImageName: "rdbu.jpg"
  },
  {
    id: 4,
    name: "Hot",
    value: "Hot",
    previewImageName: "hot.jpg"
  },
  {
    id: 5,
    name: "Blackbody",
    value: "Blackbody",
    previewImageName: "blackbody.jpg"
  },
  {
    id: 6,
    name: "Electric",
    value: "Electric",
    previewImageName: "electric.jpg"
  }
  // to add more colors, follow the similar structure above,
  // refer https://plotly.com/javascript/colorscales/ for colorscale options
  // take a snip of the preview and add it in assets/heatmap_color_preview directory
];
