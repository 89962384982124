import React, { useState } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import GenericListItem from "components/GenericListItem/GenericListItem";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { FormLabel, Link, Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import { Input, Button } from "@material-ui/core";
import * as Yup from "yup";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Axios from "axios";
import GenericApi from "Api";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import { dispatch } from "d3";
import { connect } from "react-redux";
import Spinner from "components/Spinner/Spinner";

const requiredFieldText = "This field is required";

const requestFabricationSchema = Yup.object().shape({
  nr_pieces: Yup.number().required(requiredFieldText),
  email: Yup.string().required(requiredFieldText),
  expected_date: Yup.string().required(requiredFieldText),
  comments: Yup.string()
});

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 24,
    marginLeft: 5
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "16px 0 16px 5px"
  },
  form: {
    padding: "32px 16px 32px"
  },
  field: {
    marginTop: "5px",
    width: "100%"
  },
  validationMessage: {
    height: "25px",
    padding: "3px 0"
  },
  circularProgress: {
    marginLeft: "10px"
  }
}));

function RequestFabricationForm(props) {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(
    false
  );
  const [fabricationFormErrors, setFabricationFormErrors] = useState({
    email: false,
    expected_date: false,
    comments: false
  });

  const onSubmit = (data, { setErrors }) => {
    setSubmitDisabled(true);
    setError(false);
    setErrorMsg("");
    props.resetSnackbar();
    Axios.post(`${GenericApi.getBaseUrl()}/pdk/`, data)
      .then(() => {
        // disableEditPassword();
        props.setSnackbar(
          "PDK quotation request submitted successfully. You will hear from us soon."
        );
        props.handleClose();
      })
      .catch(e => {
        console.log(e);
        // if (data === "Invalid password") {
        //   setError({ currentPassword: "Invalid password" });
        // } else {
        //   setError(true);
        //   setErrorMsg(data.detail || "Something went wrong");
        // }
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const onSubmitConfirmDialog = (data, setErrors, onSubmitCallback) => {
    const { showConfirmDialog } = props;
    const title = "Ask for a quotation";
    const message =
      "Please note that your design data will be shared with other third party vendors including the manufactuer of the selected PDK. Your personal data is always safe in the hands of Planopsim.";
    const onConfirm = () => {
      let submitData = data;
      submitData.design_job = props.design_job_id;
      onSubmitCallback(submitData, { setErrors });
    };
    showConfirmDialog(title, message, onConfirm, undefined, false);
  };

  return (
    <div className={classes.form}>
      <Formik
        test-data="request-fabrication-formik-form"
        initialValues={{
          nr_pieces: "",
          email: props.user.email,
          expected_date: "in 3 months",
          comments: ""
        }}
        validationSchema={requestFabricationSchema}
        onSubmit={(data, { setErrors }) => {
          onSubmitConfirmDialog(data, setErrors, onSubmit);
        }}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          setFieldValue
        }) => (
          <Form>
            <div>
              <FormLabel style={{ fontSize: 12 }} htmlFor="nr_pieces">
                #Pieces*
              </FormLabel>
            </div>
            <Input
              className={classes.field}
              name="nr_pieces"
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.nr_pieces}
              placeholder="Number of pieces"
            />
            <div className={classes.validationMessage}>
              {touched.nr_pieces && errors.nr_pieces}
            </div>

            <div>
              <FormLabel style={{ fontSize: 12 }} htmlFor="email">
                Email*
              </FormLabel>
            </div>
            <Input
              className={classes.field}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email address for correspondence"
            />
            <div className={classes.validationMessage}>
              {touched.email && errors.email}
            </div>

            <div>
              <FormLabel style={{ fontSize: 12 }} htmlFor="expected_date">
                Expected Delivery*
              </FormLabel>
            </div>
            <Input
              className={classes.field}
              name="expected_date"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.expected_date}
              placeholder="Expected date for delivery"
            />
            <div className={classes.validationMessage}>
              {touched.expected_date && errors.expected_date}
            </div>

            <div>
              <FormLabel style={{ fontSize: 12 }} htmlFor="comments">
                Comments
              </FormLabel>
            </div>
            <Input
              className={classes.field}
              name="comments"
              multiline
              rows={2}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comments}
              placeholder="Extra information or comments"
            />
            <div className={classes.validationMessage}>
              {touched.comments && errors.comments}
            </div>

            <Button
              className={classes.field}
              variant="contained"
              color="primary"
              type="submit"
              test-data="submitFormBtn"
              disabled={submitDisabled}
            >
              Ask for a quotation
              {submitDisabled && (
                <Spinner className={classes.circularProgress} size={20} />
              )}
            </Button>

            <Link target="_blank" href="https://planopsim.com/contact/">
              <Typography style={{ textAlign: "center", marginTop: 10 }}>
                Talk to an expert
              </Typography>
            </Link>

            {error && (
              <Typography
                test-data="errorMsg"
                style={{ textAlign: "center", marginTop: 10 }}
              >
                {errorMsg}
              </Typography>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      )
  };
};

export default connect(null, mapDispatchToProps)(RequestFabricationForm);
