import React from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";

const styles = {
  requiredTextInput: {
    display: "inline",
    float: "left",
    width: "90%"
  },
  requiredSignDiv: {
    display: "inline",
    float: "right",
    width: "10%"
  }
};

export const FamilySimpleFieldEditing = ({
  field,
  value,
  onChange,
  getRequiredStyle,
  isFieldInvalid
}) => {
  const isRequiredField = getRequiredStyle !== undefined;
  return (
    <div test-data="familySimpleFieldEditing">
      <div style={isRequiredField ? styles.requiredTextInput : {}}>
        <TextField
          value={value}
          onChange={event => onChange(field, event.target.value)}
          autoFocus
        />
      </div>
      {isRequiredField && (
        <div test-data="required">
          <div style={styles.requiredSignDiv}>
            <span test-data="requiredSign" style={getRequiredStyle(field)}>
              *
            </span>
          </div>
          {isFieldInvalid(field) && (
            <div>
              <span test-data="requiredText" style={{ color: "red" }}>
                required
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FamilySimpleFieldEditing.propTypes = {
  /**
   * the field name
   */
  field: PropTypes.string.isRequired,
  /**
   * the field value
   */
  value: PropTypes.string.isRequired,
  /**
   * callback fn to be called when field changes
   */
  onChange: PropTypes.func.isRequired
};

export const FamilyMultilineFieldEditing = ({ field, value, onChange }) => {
  return (
    <div>
      <TextField
        multiline={true}
        rowsMax={4}
        rows={1}
        value={value}
        onChange={event => onChange(field, event.target.value)}
      />
    </div>
  );
};

FamilyMultilineFieldEditing.propTypes = {
  /**
   * the field name
   */
  field: PropTypes.string.isRequired,
  /**
   * the field value
   */
  value: PropTypes.string.isRequired,
  /**
   * callback fn to be called when field changes
   */
  onChange: PropTypes.func.isRequired
};

export const FamilyUnitSelectField = ({ options, field, value, onChange }) => {
  return (
    <div test-data="familyUnitSelectField">
      <div>
        <Select
          name="FamilyUnit"
          value={value}
          onChange={event => onChange(field, event.target.value)}
          inputProps={{
            name: "unit",
            id: "unit"
          }}
        >
          {options.map(option => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

FamilyMultilineFieldEditing.propTypes = {
  /**
   * the field name
   */
  field: PropTypes.string.isRequired,
  /**
   * the field value
   */
  value: PropTypes.string.isRequired,
  /**
   * callback fn to be called when field changes
   */
  onChange: PropTypes.func.isRequired
};
