import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import logo from "assets/PlanOpSim_SOLO.png";
import PropTypes from "prop-types";

const styles = {
  main: {
    minHeight: "100%",
    boxSizing: "border-box",
    background: "#f8f8f8"
  },
  panel: {
    width: "50%",
    maxWidth: "500px",
    margin: "0 auto",
    paddingTop: "30px",
    textAlign: "center"
  },
  innerPanel: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: "50px 20px",
    margin: "30px 0",
    borderBottom: "2px solid rgba(0, 0, 0, 0.07)",
    textAlign: "left"
  },
  title: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "10px",
    fontWeight: 400
  },
  logo: {
    width: "70%"
  }
};

/**
 * A component to share the same layout for the auth
 * components (Register, Login, RegisterComplete)
 */
class AuthLayout extends Component {
  render = () => {
    const { children, classes, title } = this.props;
    return (
      <div className={classes.main}>
        <div className={classes.panel}>
          <img src={logo} alt="Logo" className={classes.logo} />
          <div className={classes.innerPanel}>
            {title && (
              <Typography variant="h1" className={classes.title}>
                {title}
              </Typography>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  };
}

AuthLayout.propTypes = {
  /**
   * Title text to show
   */
  title: PropTypes.string.isRequired,
  /**
   * Child component to wrap
   */
  children: PropTypes.any.isRequired
};

export default withStyles(styles)(AuthLayout);
