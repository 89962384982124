import React, { PureComponent } from "react";
import {
  withStyles,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  FormLabel,
  Typography
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Order from "components/Order/Order";
import Direction from "components/Direction/Direction";
import PolarizationOut from "components/PolarizationOut/PolarizationOut";

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textField: {
    marginBottom: 5
  }
});

const requiredFieldText = "required";

const outgoingConditionsFormSchema = Yup.object().shape({
  order_x: Yup.string().required(requiredFieldText),
  order_y: Yup.string().required(requiredFieldText)
});

/**
 * a component to allow the analysis details to be edited
 * @author Akira Kotsugai
 */
export class AnalysisDetails extends PureComponent {
  /**
   * @returns {Object} the initial form values
   */
  getFormInitialValues() {
    const { outgoingConditions } = this.props;
    const outgoingConditionsInexist = outgoingConditions === null;
    if (outgoingConditionsInexist) {
      return {
        order_x: "0",
        order_y: "0",
        direction_out: "Transmission",
        polarization_out: "TE"
      };
    }
    const { direction_out, polarization_out, order_out } = outgoingConditions;
    return {
      direction_out,
      polarization_out,
      order_x: order_out[0],
      order_y: order_out[1]
    };
  }

  /**
   * before calling the parent submitter, it prepares the data that matches the endpoint's
   * expected format
   * @param {Object} values - the form values to submit
   */
  handleSubmit = values => {
    const { direction_out, polarization_out, order_x, order_y } = values;
    const preparedValues = {
      direction_out,
      polarization_out,
      order_out: [order_x, order_y]
    };
    this.props.onSubmit(preparedValues);
  };

  render() {
    const { classes, disabled } = this.props;
    return (
      <Formik
        initialValues={this.getFormInitialValues()}
        enableReinitialize
        validationSchema={outgoingConditionsFormSchema}
        onSubmit={this.handleSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          submitForm,
          resetForm,
          setFieldValue
        }) => {
          // making it possible to call these formik functions from parent components
          this.props.bindSubmitForm(submitForm);
          this.props.bindResetForm(resetForm);
          return (
            <Paper className={classes.paper} elevation={3}>
              <Typography variant="h6" component="h3">
                {"Outgoing Conditions"}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Order
                          required
                          name="order_x"
                          error={touched.order_x && errors.order_x}
                          helperText={touched.order_x && errors.order_x}
                          value={values.order_x}
                          onChange={handleChange}
                          disabled={disabled}
                          onBlur={handleBlur}
                          orderType={"Order X"}
                          toolTipText={
                            "Diffraction order of the light exiting the component. The absolute value of order X should not be larger than the accuracy X of the meta cell"
                          }
                          allowNegative={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Order
                          required
                          name="order_y"
                          error={touched.order_y && errors.order_y}
                          helperText={touched.order_y && errors.order_y}
                          value={values.order_y}
                          disabled={disabled}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          orderType={"Order Y"}
                          toolTipText={
                            "Diffraction order of the light exiting the component. The absolute value of order Y should not be larger than the accuracy Y of the meta cell"
                          }
                          allowNegative={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Direction
                          disabled={disabled}
                          value={values.direction_out}
                          name={"direction_out"}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <PolarizationOut
                          disabled={disabled}
                          style={{ width: "100%" }}
                          value={values.polarization_out}
                          name={"polarization_out"}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          );
        }}
      </Formik>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(AnalysisDetails));
