/**
 * Instructions to be sent in the action to the user's reducer
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} UserActionType
 * @property {String} SET_USER  tells the reducer to set the given user in the store
 * @property {String} SET_ACCOUNT_DIALOG_OPEN  tells the reducer to update the account dialog's open value
 * @property {String} SET_DECIMAL_SEPARATOR tells the reducer to change the decimal separator with the payload
 * @property {String} SET_DOCUMENT_DIALOG_OPEN tells the document dialog to open or close
 * @global
 */
export const actionType = Object.freeze({
  SET_USER: "SET_USER",
  SET_ACCOUNT_DIALOG_OPEN: "SET_ACCOUNT_DIALOG_OPEN",
  SET_USER_SETTINGS: "SET_USER_SETTINGS",
  SET_DECIMAL_SEPARATOR: "SET_DECIMAL_SEPARATOR",
  SET_DOCUMENT_DIALOG_OPEN: "SET_DOCUMENT_DIALOG_OPEN",
  SET_VERSION: "SET_VERSION"
});

/**
 * Actions creator for the user's reducer.
 * consist of an instruction {@link UserActionType} and some data.
 */
export default class UserAction {
  static setUser = user => ({
    type: actionType.SET_USER,
    payload: user
  });

  static setAccountDialogOpen = open => ({
    type: actionType.SET_ACCOUNT_DIALOG_OPEN,
    payload: open
  });

  static setDocumentDialogContent = content => ({
    type: actionType.SET_DOCUMENT_DIALOG_OPEN,
    payload: content
  });

  static setDecimalSeparator = separator => ({
    type: actionType.SET_DECIMAL_SEPARATOR,
    payload: separator
  });

  static setUserSettings = userSettings => ({
    type: actionType.SET_USER_SETTINGS,
    payload: userSettings
  });

  static setVersion = version => ({
    type: actionType.SET_VERSION,
    payload: version
  });
}
