import React, { PureComponent } from "react";
import { Tooltip, Grid, Button } from "@material-ui/core";
import CategoryIcon from "@material-ui/icons/Category";
import PropTypes from "prop-types";
import ItemOptions from "../ItemOptions/ItemOptions";

/**
 * A component to merge the structure into the structure combination
 * @author Akira Kotsugai
 */
export class MergeItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showStructures: false,
      showOperators: false,
      selectedOperator: null
    };
  }

  showOperators = () => {
    this.setState({
      selectedOperator: null,
      showOperators: true
    });
  };

  selectStructure = event => {
    this.setState({
      showStructures: false
    });
    const selectedStructure = event.target.value;
    this.props.mergeStructure(this.state.selectedOperator, selectedStructure);
  };

  selectOperator = event => {
    this.setState({
      showOperators: false,
      selectedOperator: event.target.value,
      showStructures: true
    });
  };

  render = () => {
    return (
      <>
        {this.state.showStructures && (
          <Grid item xs={2}>
            <ItemOptions
              show={this.state.showStructures}
              handleSelect={this.selectStructure}
              options={this.props.structureOptions.map(option => ({
                name: option,
                label: option
              }))}
              handleClose={() => this.setState({ showStructures: false })}
            />
          </Grid>
        )}

        {this.state.showOperators && (
          <Grid item xs={1}>
            <ItemOptions
              show={this.state.showOperators}
              handleSelect={this.selectOperator}
              options={this.props.operatorOptions}
              handleClose={() => this.setState({ showOperators: false })}
            />
          </Grid>
        )}
        {!this.state.showStructures && !this.state.showOperators && (
          <Grid item xs={1}>
            <Grid container>
              <Grid item xs={12}>
                <Tooltip title={"merge structure"} placement="top">
                  <Button
                    style={{ width: "100%", padding: 5, marginTop: 34 }}
                    variant="outlined"
                    color="inherit"
                    onClick={this.showOperators}
                    disabled={this.props.disabled}
                  >
                    <CategoryIcon />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };
}

MergeItem.propTypes = {
  /**
   * callback to merge the selected structure into the structure combination
   */
  mergeStructure: PropTypes.func.isRequired,
  /**
   * possible operator options
   */
  operatorOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * possible parameterized structures
   */
  structureOptions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MergeItem;
