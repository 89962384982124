import React from "react";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const NavLinkWithRef = React.forwardRef((props, ref) => <NavLink {...props} />);

const useStyles = makeStyles({
  root: {
    margin: 5
  }
});

/**
 * A component to wrap up a button with mutual properties for the navigation menu
 * @author Akira Kotsugai
 */
const MainNavButton = ({ to, text, name }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        name={name}
        test-data="mainNavBtn"
        activeStyle={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
        isActive={() => to === window.location.pathname}
        variant="outlined"
        color="inherit"
        component={NavLinkWithRef}
        to={to}
      >
        <span test-data="btnText">{text}</span>
      </Button>
    </div>
  );
};

export default MainNavButton;
