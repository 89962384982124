import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";

const styles = theme => ({
  title: {
    float: "left"
  }
});

export class TargetsView extends PureComponent {
  constructor(props) {
    super(props);
  }

  /**
   * it checks if the focused design target exists
   */
  getSelectedEntityId() {
    const { targetsList, focusedDesignTargetId } = this.props;
    return targetsList.some(target => target.id === focusedDesignTargetId)
      ? focusedDesignTargetId
      : -1;
  }

  render() {
    const { targetsList, focusOnDesignTarget } = this.props;
    return (
      <EnhancedMaterialTable
        title=""
        selectedEntityId={this.getSelectedEntityId()}
        selectEntity={focusOnDesignTarget}
        disableDoubleClick
        columns={[
          {
            title: "Name",
            field: "name"
          },
          // {
          //   title: "Far-field wavefront target",
          //   field: "farfield_target"
          // },
          {
            title: "Description",
            field: "description"
          },
          {
            title: "Near-field wavefront dimensions",
            field: "nearfield_dimensions"
          }
        ]}
        data={targetsList}
        options={{
          search: false,
          paging: false,
          sorting: false,
          draggable: false,
          toolbar: false
        }}
      />
    );
  }
}

export default withStyles(styles)(TargetsView);
