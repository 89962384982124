import React, { PureComponent } from "react";
import ReportIcon from "@material-ui/icons/Report";
import PropTypes from "prop-types";
import MaterialTable, { MTableBody } from "components/MaterialTable/src/index";
import StopIcon from "@material-ui/icons/Stop";
import TuneIcon from "@material-ui/icons/Tune";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import WhereToVoteIcon from "@material-ui/icons/WhereToVote";
import CloseIcon from "@material-ui/icons/Close";

export class JobOverviewTable extends PureComponent {
  constructor() {
    super();
    this.tableRef = React.createRef();
  }

  getActions() {
    const {
      showErrorsAndWarnings,
      stopJob,
      showConfiguration,
      deleteJob,
      restore,
      confirmRestore,
      restoreMode,
      cancelRestore
    } = this.props;
    let actions = [];
    if (restoreMode) {
      actions.push({
        icon: WhereToVoteIcon,
        tooltip: "Restore here",
        onClick: confirmRestore
      });
      actions.push({
        icon: CloseIcon,
        tooltip: "cancel restoration",
        onClick: cancelRestore,
        isFreeAction: true
      });
    } else {
      if (stopJob) {
        actions.push({
          icon: StopIcon,
          tooltip: "Stop job",
          onClick: stopJob,
          disabled: rowsData => {
            const shouldEnable =
              rowsData.length === 1 && rowsData[0].status === "RUNNING";
            return !shouldEnable;
          }
        });
      }
      actions.push({
        icon: ReportIcon,
        tooltip: "Errors and Warnings",
        onClick: showErrorsAndWarnings,
        disabled: rowsData => {
          const shouldEnable =
            rowsData.length === 1 &&
            ["ERROR", "FAILED", "DONE"].includes(rowsData[0].status);
          return !shouldEnable;
        }
      });
      if (showConfiguration) {
        actions.push({
          icon: TuneIcon,
          tooltip: "Configuration",
          onClick: showConfiguration,
          disabled: rowsData => {
            const shouldEnable =
              rowsData.length === 1 &&
              !["FAILED", "QUEUED"].includes(rowsData[0].status);
            return !shouldEnable;
          }
        });
      }
      if (deleteJob) {
        actions.push({
          icon: DeleteIcon,
          tooltip: "Delete",
          onClick: deleteJob,
          disabled: rowsData => {
            const shouldEnable =
              rowsData.length > 0 &&
              rowsData.every(rowData => rowData.status !== "RUNNING");
            return !shouldEnable;
          }
        });
      }
      if (restore) {
        actions.push({
          icon: RestoreIcon,
          tooltip: "Restore origin of job",
          onClick: restore,
          disabled: rowsData => {
            const shouldEnable = rowsData.length === 1;
            return !shouldEnable;
          }
        });
      }
    }
    return actions;
  }

  /**
   * @returns {Object[]} all columns given by the parent if not on restore mode else
   * only the columns until the restoration recipient.
   */
  getColumns() {
    let columns = [];
    const { data, restoreMode, restorationRecipient, filters } = this.props;
    if (restoreMode) {
      for (const grouperColumn of this.props.grouperColumns) {
        columns.push({ ...grouperColumn });
        if (grouperColumn.field === restorationRecipient) {
          break;
        }
      }
    } else {
      columns = this.props.grouperColumns ? [...this.props.grouperColumns] : [];
      if (data && data.length && data[0].name) {
        columns.push({
          title: "Name",
          field: "name"
        });
      }
      if (data && data.length && data[0].startDate) {
        columns.push({
          title: "Start date",
          field: "startDate",
          type: "date",
          defaultSort: "desc"
        });
      }
      columns.push(
        ...[
          {
            title: "Progress",
            field: "progress"
          },
          {
            title: "Status",
            field: "status",
            lookup: {
              IDLE: "IDLE",
              RUNNING: "RUNNING",
              DONE: "DONE",
              ERROR: "ERROR",
              STOPPED: "STOPPED",
              FAILED: "FAILED",
              QUEUED: "QUEUED"
            }
          }
        ]
      );
      if (filters && Object.values(filters).length) {
        columns = columns.map(column => ({
          ...column,
          defaultFilter: filters[column.field]
        }));
      }
    }
    return columns;
  }

  /**
   * @returns {Number} - zero if restore mode is on, 3 when there is no name column and 4 when there
   * is name column.
   */
  getActionsColumnIndex() {
    const { data, restoreMode } = this.props;
    if (restoreMode) {
      return 0;
    }
    return data && data.length && data[0].name ? 4 : 3;
  }

  /**
   * @callback
   * it finds which column had a filter change and calls the parent callback
   * @param {Number} - the index of the column being filtered
   * @param {value} - the filter value
   */
  handleFilterChange = (columnIndex, value) => {
    const column = this.getColumns()[columnIndex];
    if (this.props.handleFilterChange) {
      this.props.handleFilterChange({ [column.field]: value });
    }
  };

  render = () => {
    const { data, restoreMode } = this.props;
    return (
      <div>
        <MaterialTable
          components={{
            Body: props => (
              <MTableBody
                {...props}
                onFilterChanged={(columnId, value) => {
                  props.onFilterChanged(columnId, value);
                  this.handleFilterChange(columnId, value);
                }}
              />
            )
          }}
          tableRef={this.tableRef}
          title={restoreMode ? "Select the recipient" : "All jobs"}
          columns={this.getColumns()}
          data={data}
          options={{
            selection: !restoreMode,
            search: false,
            grouping: true,
            filtering: true,
            actionsColumnIndex: this.getActionsColumnIndex(),
            pageSize: 20
          }}
          actions={this.getActions()}
        />
      </div>
    );
  };
}

JobOverviewTable.propTypes = {
  /**
   * Table data
   */
  data: PropTypes.array.isRequired,
  /**
   * Action click callback
   */
  showErrorsAndWarnings: PropTypes.func.isRequired,
  /**
   * callback to delete a job
   */
  stopJob: PropTypes.func,
  /**
   * callback to show configuration
   */
  showConfiguration: PropTypes.func,
  /**
   * calback to delete a job
   */
  restore: PropTypes.func
  /**
   * callback to restore a job into a different origin
   */
};
