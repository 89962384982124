/**
 * A class to make value selection from the meta component explorer in redux state more clear.
 * @author Akira Kotsugai
 */
export default class DirectoryExplorerSelector {
  /**
   * it selects meta component groups from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the meta component groups
   */
  static getMCGroups = state => state.mcExplorer.entities.mcGroups;

  /**
   * it selects meta components from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the meta components
   */
  static getMetaComponents = state => state.mcExplorer.entities.metaComponents;

  /**
   * it selects the selected item from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the selected item
   */
  static getSelectedItem = state => state.mcExplorer.ui.selectedItem;

  /**
   * it selects the form value from the redux state
   * @param {Object} state - the redux state
   * @return {String} the form value
   */
  static getForm = state => state.mcExplorer.ui.form;

  /**
   * it selects the meta component open id from the redux state
   * @param {Object} state - the redux state
   * @return {Number} the meta component id
   */
  static getMetaComponentOpenId = state =>
    state.mcExplorer.ui.metaComponentOpenId;

  /**
   * it selects the editing item from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the editing item
   */
  static getEditingItem = state => state.mcExplorer.ui.editingItem;
}
