import React, { PureComponent } from "react";
import Axios from "axios";
import GenericApi from "Api";

/**
 * a component that cleans selenium data when it is rendered
 */
export default class CleanSeleniumLeftovers extends PureComponent {
  constructor() {
    super();
    this.state = {
      cleaned: false
    };
  }

  componentDidMount() {
    return Axios.post(
      `${GenericApi.getBaseUrl()}/selenium/cleanleftovers`
    ).then(response => this.setState({ cleaned: true }));
  }

  render() {
    const { cleaned } = this.state;
    return (
      <>
        {cleaned && (
          <span test-data="cleaned">selenium cleaned successfully</span>
        )}
      </>
    );
  }
}
