import Axios from "axios";
import RayTracingAction from "MetaComponent/actions/RayTracing";
import GenericApi from "Api";
import { decode } from "@msgpack/msgpack";

export default class RayTracingApi {
  static getRayTracingJobUrl = (jobId = "") =>
    `${GenericApi.getBaseUrl()}/raytracingjobs/${jobId}`;

  static getRayTracingJobProgressUrl = rayTracingJobId =>
    `${this.getRayTracingJobUrl(rayTracingJobId)}/progress/`;

  static exportRayTracingUrl = id => `${this.getRayTracingJobUrl(id)}/data`;

  static exportRayTracingPsrtUrl = id =>
    `${this.getRayTracingJobUrl(id)}/export_psrt`;

  static getRayTracingJobResultUrl = (rayTracingJobId, variableValues) => {
    const resultsUrl = `${this.getRayTracingJobUrl(rayTracingJobId)}/results`;
    const args = Object.keys(variableValues).map(
      key => `${key}=${variableValues[key]}`
    );
    if (!args.length) {
      return resultsUrl;
    }
    return `${resultsUrl}?${args.join("&")}`;
  };

  static start = rayTracingJob => {
    return dispatch => {
      return GenericApi.runApiCall(
        Axios.post(RayTracingApi.getRayTracingJobUrl(), rayTracingJob),
        "Failed to start ray tracing job"
      )
        .then(response => response.data)
        .then(rayTracingJob => {
          dispatch(RayTracingAction.upsertJobs([rayTracingJob]));
          return rayTracingJob.id;
        });
    };
  };

  static restart = (rayTracingJobId, properties) => {
    return dispatch => {
      return GenericApi.runApiCall(
        Axios.put(
          RayTracingApi.getRayTracingJobUrl(rayTracingJobId) + "/",
          properties
        ),
        "Failed to update ray tracing job"
      )
        .then(response => response.data)
        .then(rayTracingJob =>
          dispatch(RayTracingAction.upsertJobs([rayTracingJob]))
        );
    };
  };

  static fetchRayTracingJobs = (jobId = "") => {
    return dispatch => {
      const url = RayTracingApi.getRayTracingJobUrl(jobId);
      return Axios.get(url)
        .then(response => response.data)
        .then(rayTracingJobs => {
          dispatch(RayTracingAction.set(rayTracingJobs));
        });
    };
  };

  static getRayTracingJobProgress = rayTracingJobId => dispatch => {
    const url = RayTracingApi.getRayTracingJobProgressUrl(rayTracingJobId);
    return GenericApi.runApiCall(
      Axios.get(url),
      "Ray Tracing Link Job does not progress."
    )
      .then(res => res.data)
      .then(jobProgress => {
        dispatch(RayTracingAction.upsertJobs([jobProgress]));
        return jobProgress;
      });
  };

  static getSimpleRayTracingJobProgress = rayTracingJobId => {
    const url = RayTracingApi.getRayTracingJobProgressUrl(rayTracingJobId);
    return GenericApi.runApiCall(
      Axios.get(url),
      "Ray Tracing Link Job does not progress."
    );
  };

  static getRayTracingJobResult = (rayTracingJobId, variableValues = {}) => {
    const url = RayTracingApi.getRayTracingJobResultUrl(
        rayTracingJobId,
        variableValues
      ),
      config = {
        responseType: "blob"
      };
    return Axios.get(url, config)
      .then(res => res.data.arrayBuffer())
      .then(arrayBuffer => decode(arrayBuffer))
      .then(wavefront => {
        return wavefront;
      });
  };

  static exportRayTracing = id =>
    GenericApi.runApiCall(
      Axios({
        url: this.exportRayTracingUrl(id),
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream"
        }
      }).then(res => res.data),
      "Failed to export ray tracing zip file."
    );

  static exportRayTracingPsrt = id =>
    GenericApi.runApiCall(
      Axios({
        url: this.exportRayTracingPsrtUrl(id),
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream"
        }
      }).then(res => res.data),
      "Failed to export ray tracing psrt file."
    );

  static deleteRayTracingJob = jobId => dispatch => {
    const url = this.getRayTracingJobUrl(jobId);
    return Axios.delete(url).then(() =>
      dispatch(RayTracingAction.deleteJobs([jobId]))
    );
  };

  static deleteRayTracingJobs = jobIds => dispatch => {
    const url = this.getRayTracingJobUrl("");
    return Axios.delete(url, { data: jobIds }).then(() =>
      dispatch(RayTracingAction.deleteJobs(jobIds))
    );
  };

  static stopRayTracingJob = jobId => {
    const url = `${this.getRayTracingJobUrl(jobId)}/stop`;
    return Axios.post(url);
  };
}
