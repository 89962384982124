import Axios from "axios";
import SimulationTargetAction from "MetaCell/actions/SimulationTargets";
import GenericApi from "Api";

export default class SimulationTargetApi {
  static simulationTargetsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/simulation_targets/${id}`;

  static simulationTargetUsedSimulationsUrl = id =>
    `${this.simulationTargetsUrl(id)}/used_simulations`;

  static getSimulationTargetsBySimulationIdUrl = simulationId => {
    return `${GenericApi.getBaseUrl()}/simulation_targets/?simulation=${simulationId}`;
  };

  static fetchSimulationTargets = (id = "") => {
    return dispatch => {
      return this.requestSimulationTargets(id).then(simulationTargets => {
        dispatch(SimulationTargetAction.set(simulationTargets));
        return simulationTargets;
      });
    };
  };

  static fetchSimulationTargetsBySimulationId = id => {
    return dispatch => {
      return this.requestSimulationTargetsBySimulationId(id).then(
        simulationTargets => {
          dispatch(SimulationTargetAction.set(simulationTargets));
          return simulationTargets;
        }
      );
    };
  };

  static requestSimulationTargetsBySimulationId = simulationId => {
    const url = SimulationTargetApi.getSimulationTargetsBySimulationIdUrl(
      simulationId
    );
    return Axios.get(url)
      .then(res => {
        const simulationTargets = res.data;
        return simulationTargets;
      })
      .catch(error => {
        console.log("Simulation targets error from API: ", error.message);
      });
  };

  static requestSimulationTargets = id => {
    const url = SimulationTargetApi.simulationTargetsUrl(id);
    return Axios.get(url)
      .then(res => {
        const simulationTargets = id ? [res.data] : res.data;
        return simulationTargets;
      })
      .catch(error => {
        console.log("Simulation targets error from API: ", error.message);
      });
  };

  static updateSimulationTarget = simulationTarget => {
    return dispatch => {
      return Axios.put(
        SimulationTargetApi.simulationTargetsUrl(simulationTarget.id),
        simulationTarget
      )
        .then(() => dispatch(SimulationTargetAction.upsert([simulationTarget])))
        .catch(error => {
          console.log(
            "Simulation target update error from API: ",
            error.message
          );
        });
    };
  };

  static createSimulationTarget = simulationTarget => dispatch =>
    Axios.post(SimulationTargetApi.simulationTargetsUrl(), simulationTarget)
      .then(res => res.data)
      .then(newSimulationTarget => {
        const targetAction = SimulationTargetAction.upsert([
          newSimulationTarget
        ]);
        dispatch(targetAction);
        return newSimulationTarget.id;
      });

  static deleteSimulationTarget = simulationTargetId => {
    return dispatch => {
      return Axios.delete(
        SimulationTargetApi.simulationTargetsUrl(simulationTargetId)
      ).then(() => dispatch(SimulationTargetAction.delete(simulationTargetId)));
    };
  };

  static getSimulationTargetUsedMCs = simulationTargetId => {
    return Axios.get(
      this.simulationTargetUsedSimulationsUrl(simulationTargetId)
    ).then(res => res.data);
  };

  static getAvailableOptimizers = () => {
    return Axios.get(`${GenericApi.getBaseUrl()}/optimizers`).then(
      res => res.data
    );
  };
}
