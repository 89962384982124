import React from "react";
import PropTypes from "prop-types";

/**
 * a functional stateless component to show the incident plane angle
 * @author Akira Kotsugai
 */
const IncidentPlaneLine = ({ width, height, incidentPlaneAngle }) => {
  return isNaN(incidentPlaneAngle) ? null : (
    <svg
      test-data="incidentPlaneLineSvg"
      width={width}
      height={height}
      style={{
        position: "absolute",
        zIndex: 200
      }}
    >
      <line
        test-data="incidentPlaneLine"
        strokeDasharray="5, 5"
        stroke="rgb(0, 0, 0)"
        strokeWidth={2}
        x1={width / 2}
        x2={width / 2}
        y1={0}
        y2={height}
        transform={`rotate(${parseFloat(incidentPlaneAngle) * -1}, ${width /
          2}, ${height / 2})`}
      ></line>
    </svg>
  );
};

IncidentPlaneLine.propTypes = {
  /**
   * the total width
   */
  width: PropTypes.number.isRequired,
  /**
   * the total height
   */
  height: PropTypes.number.isRequired,
  /**
   * the angle of the incident plane
   */
  incidentPlaneAngle: PropTypes.number.isRequired
};

export default IncidentPlaneLine;
