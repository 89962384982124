import { actionType } from "MetaCell/actions/Simulation";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testJobs = {
  byId: {
    1: {
      id: 1,
      simulation: 1,
      name: "Dichroic mirror",
      task: null,
      status: "DONE",
      creationDate: "2019-12-16T12:43:56.569382Z",
      startDate: null,
      endDate: null,
      metrics: null,
      progress: 0
    },
    2: {
      id: 2,
      simulation: 1,
      name: "Dichroic mirror",
      task: null,
      status: "FAILED",
      creationDate: "2019-12-16T12:43:56.569382Z",
      startDate: null,
      endDate: null,
      metrics: null,
      progress: 0
    },
    3: {
      id: 3,
      simulation: 1,
      name: "Dichroic mirror",
      task: null,
      status: "STOPPED",
      creationDate: "2019-12-16T12:43:56.569382Z",
      startDate: null,
      endDate: null,
      metrics: null,
      progress: 0,
      errors: {}
    },
    4: {
      id: 4,
      simulation: 1,
      name: "Dichroic mirror",
      task: null,
      status: "IDLE",
      creationDate: "2019-12-16T12:43:56.569382Z",
      startDate: null,
      endDate: null,
      metrics: null,
      progress: 0,
      warnings: {}
    }
  },
  allIds: [1, 2, 3, 4],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} SimulationDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    jobs: {
      byId: {},
      allIds: [],
      loaded: false
    },
    results: [],
    selectedPoints: [],
    modeAnalysisJobs: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    overviewFilters: {},
    plotOverviewFilters: {},
    selectedModeAnalysisJobId: null,
    selectedJobId: null
  }
};

/**
 * Reducer function to manipulate the state of simulations
 * @author Ibtihel
 * @param {Object} [state=SimulationDefaultState] - simulation entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case actionType.UPDATE_OVERVIEW_FILTERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          overviewFilters: { ...state.ui.overviewFilters, ...payload }
        }
      };
    }

    case actionType.UPDATE_PLOT_OVERVIEW_FILTERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          plotOverviewFilters: { ...state.ui.plotOverviewFilters, ...payload }
        }
      };
    }

    case actionType.DELETE_JOBS: {
      let jobs = cloneDeep(state.entities.jobs);
      Utils.deleteEntities(payload, jobs);
      return {
        ...state,
        entities: { ...state.entities, jobs }
      };
    }

    case actionType.UPSERT_JOBS: {
      let jobs = cloneDeep(state.entities.jobs);
      Utils.addOrUpdateEntities(payload, jobs);
      const lastJob = payload.length ? payload[payload.length - 1] : null;
      return {
        ...state,
        entities: { ...state.entities, jobs },
        ui: {
          ...state.ui,
          selectedJobId: lastJob ? lastJob.id : null
        }
      };
    }

    case actionType.UPSERT_MODE_ANALYSIS_JOBS: {
      let modeAnalysisJobs = cloneDeep(state.entities.modeAnalysisJobs);
      Utils.addOrUpdateEntities(payload, modeAnalysisJobs);
      const lastModeAnalysis = payload.length
        ? payload[payload.length - 1]
        : null;
      return {
        ...state,
        entities: { ...state.entities, modeAnalysisJobs },
        ui: {
          ...state.ui,
          selectedModeAnalysisJobId: lastModeAnalysis
            ? lastModeAnalysis.id
            : null
        }
      };
    }

    case actionType.SET_MODE_ANALYSIS_JOBS: {
      let modeAnalysisJobs = {
        byId: {},
        allIds: [],
        loaded: false
      };
      Utils.addOrUpdateEntities(payload, modeAnalysisJobs);
      const lastModeAnalysis = payload.length
        ? payload[payload.length - 1]
        : null;
      return {
        ...state,
        entities: { ...state.entities, modeAnalysisJobs },
        ui: {
          ...state.ui,
          selectedModeAnalysisJobId: lastModeAnalysis
            ? lastModeAnalysis.id
            : null
        }
      };
    }

    case actionType.SET_SIMULATION_JOBS: {
      let jobs = {
        byId: {},
        allIds: [],
        loaded: false
      };
      Utils.addOrUpdateEntities(payload, jobs);
      const lastJob = payload.length ? payload[payload.length - 1] : null;
      return {
        ...state,
        entities: { ...state.entities, jobs },
        ui: {
          ...state.ui,
          selectedJobId: lastJob ? lastJob.id : null
        }
      };
    }

    case actionType.SELECT_MODE_ANALYSIS_JOB: {
      return {
        ...state,
        ui: { ...state.ui, selectedModeAnalysisJobId: payload }
      };
    }

    case actionType.SELECT_SIMULATION_JOB: {
      return {
        ...state,
        ui: { ...state.ui, selectedJobId: payload }
      };
    }

    case actionType.RESET_JOBS: {
      return {
        ...state,
        entities: { ...state.entities, jobs: defaultState.entities.jobs }
      };
    }

    case actionType.SELECT_RESULTS: {
      let results = cloneDeep(state.entities.results);
      results = [...payload];
      return {
        ...state,
        entities: { ...state.entities, results }
      };
    }

    case actionType.SELECT_POINTS: {
      let selectedPoints = cloneDeep(state.entities.selectedPoints);
      selectedPoints = [...payload];
      return {
        ...state,
        entities: { ...state.entities, selectedPoints }
      };
    }

    case actionType.RESET_SELECTED_RESULTS: {
      let results = [];
      return {
        ...state,
        entities: { ...state.entities, results }
      };
    }

    default:
      return state;
  }
}
