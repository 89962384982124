import React, { PureComponent } from "react";
import { FormLabel, FormControl, MenuItem } from "@material-ui/core";
import IconTooltip from "components/IconTooltip/IconTooltip";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";

/**
 * a component for the user to input the polarization out
 * @author Akira Kotsugai
 */
export default class PolarizationOut extends PureComponent {
  render() {
    const { disabled, name, value, onChange, style } = this.props;
    return (
      <FormControl style={style}>
        <div>
          <FormLabel style={{ fontSize: 12 }} disabled={disabled}>
            Polarization
          </FormLabel>
          <IconTooltip text={"Polarization of the desired exit wavefront."} />
        </div>
        <UnselfishSelect
          disabled={disabled}
          style={{ marginBottom: 5 }}
          value={value}
          name={name}
          onChange={onChange}
        >
          <MenuItem value="TE">TE</MenuItem>
          <MenuItem value="TM">TM</MenuItem>
          <MenuItem value="RCP">RCP</MenuItem>
          <MenuItem value="LCP">LCP</MenuItem>
          <MenuItem value="co">Co</MenuItem>
          <MenuItem value="cross">Cross</MenuItem>
        </UnselfishSelect>
      </FormControl>
    );
  }
}
