import { actionType } from "MetaComponent/actions/RayTracing";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testRaytracingJobs = {
  byId: {
    1: {
      id: 1,
      name: "Raytracing 1",
      design_job: 1,
      status: "DONE",
      swept_variables: []
    },
    2: {
      id: 2,
      name: "Raytracing 2",
      design_job: 2,
      status: "RUNNING",
      swept_variables: []
    },
    3: {
      id: 3,
      name: "Raytracing 3",
      design_job: 3,
      status: "QUEUED",
      swept_variables: []
    },
    4: {
      id: 4,
      name: "Raytracing 4",
      design_job: 3,
      status: "DONE",
      swept_variables: []
    }
  },
  allIds: ["1", "2", "3", "4"],
  loaded: true
};

export const defaultState = {
  entities: {
    rayTracingJobs: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    selectedJobId: null,
    tempSweptVariables: []
    //   overviewFilters: {}
  }
};

export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    //   case actionType.UPDATE_OVERVIEW_FILTERS: {
    //     return {
    //       ...state,
    //       ui: {
    //         ...state.ui,
    //         overviewFilters: { ...state.ui.overviewFilters, ...payload }
    //       }
    //     };
    //   }

    case actionType.DELETE_RAYTRACING_JOBS: {
      let rayTracingJobs = cloneDeep(state.entities.rayTracingJobs);
      Utils.deleteEntities(payload, rayTracingJobs);
      return {
        ...state,
        entities: { ...state.entities, rayTracingJobs }
      };
    }
    case actionType.SET_RAYTRACING_JOBS: {
      let rayTracingJobs = cloneDeep(defaultState.entities.rayTracingJobs);
      Utils.addOrUpdateEntities(payload, rayTracingJobs);
      const lastJobId = payload.length
        ? Math.max.apply(
            Math,
            payload.map(job => job.id)
          )
        : null;
      const selectedJobId =
        state.ui.selectedJobId && rayTracingJobs.byId[state.ui.selectedJobId]
          ? state.ui.selectedJobId
          : lastJobId;
      return {
        ...state,
        entities: { ...state.entities, rayTracingJobs },
        ui: { ...state.ui, selectedJobId }
      };
    }
    case actionType.UPSERT_RAYTRACING_JOBS: {
      let rayTracingJobs = cloneDeep(state.entities.rayTracingJobs);
      Utils.addOrUpdateEntities(payload, rayTracingJobs);
      return {
        ...state,
        entities: { ...state.entities, rayTracingJobs }
      };
    }

    case actionType.SELECT_RAYTRACING_JOB: {
      return {
        ...state,
        ui: { ...state.ui, selectedJobId: payload }
      };
    }
    case actionType.RESET_RAYTRACING_JOBS: {
      return defaultState;
    }
    case actionType.UPDATE_TEMP_RAYTRACING_VARIABLES: {
      return {
        ...state,
        ui: { ...state.ui, tempSweptVariables: payload }
      };
    }
    default:
      return state;
  }
}
