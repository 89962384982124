import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { isEqual } from "lodash";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { GenericWavefront } from "components/GenericWavefront/GenericWavefront";
import RayTracingApi from "MetaComponent/api/RayTracing";
import Wavefront from "MetaComponent/containers/TargetCanvas/components/Wavefront/Wavefront";
import Spinner from "components/Spinner/Spinner";
import Efficiency from "MetaComponent/containers/AnalysisCanvas/components/AnalysisJobResult/components/Efficiency/Efficiency";
import SweepPoint from "components/SweepPoint/SweepPoint";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import HelperUtils from "MetaCell/helper/HelperUtils";
import IconTooltip from "components/IconTooltip/IconTooltip";

export const styles = {
  root: {
    marginBottom: 40,
    paddingRight: 260
  },
  title: {
    textAlign: "center"
  },
  centerElement: {
    display: "block",
    margin: "auto"
  }
};

export class RayTracingJobResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sweptVariableValues: {},
      nfwf: null,
      amplitude_wf: null,
      decomposition_wf: null,
      error_wf: null,
      loadingResult: false
    };
  }

  componentDidMount() {
    this.setSelectedValues();
    this.getResult();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.sweptVariables, this.props.sweptVariables)) {
      this.setSelectedValues();
    }
  }

  isMultipleResult() {
    return this.props.sweptVariables.length > 0;
  }

  /**
   * sets in the state the first value of each swept variable
   */
  setSelectedValues() {
    let sweptVariableValues = {};
    for (const variable of this.props.sweptVariables) {
      sweptVariableValues[variable.name] = variable.values[0];
    }
    this.setState({ sweptVariableValues });
  }

  /**
   * it updates a variable's selected value in the state
   * @param {*} sweptVariableValues - the updated variable values
   */
  setSelectedValue = sweptVariableValues => {
    this.setState({ sweptVariableValues }, this.getResult);
  };

  /**
   * @param {String} params - the start, stop and step parameters divided by separators
   * @returns {Number[]} - an array containing all parameters
   */
  splitVariableParams = params => {
    const splittedParameters = params.split(/,|;|:/);
    return splittedParameters.map(parameter => parseFloat(parameter));
  };

  ifGeneralSweepUsedParameter = sv => {
    const { outgoingConditions, setPoint } = this.props;
    const order_out_x = outgoingConditions.order_out[0],
      order_out_y = outgoingConditions.order_out[1],
      diffractive_order_x = setPoint.diffractive_order["X"],
      diffractive_order_y = setPoint.diffractive_order["Y"],
      {
        amplitude,
        azimut,
        incident_light_type,
        wavelength,
        zenit
      } = setPoint.incident_light,
      general_parameters = [
        zenit,
        azimut,
        wavelength,
        amplitude,
        diffractive_order_x,
        diffractive_order_y,
        order_out_x,
        order_out_y
      ];
    return general_parameters.some(
      param =>
        param?.toString().includes("=") &&
        param.toString().split("=")[1] == sv.name
    );
  };

  /**
   * it shows a spinner, gets the result and hides the spinner again.
   * farfield wavefront has to be wrapped in a matrix because the wavefront component expects
   * 2 matrices.
   */
  getResult = async () => {
    this.setState({
      loadingResult: true
    });
    const result = await RayTracingApi.getRayTracingJobResult(
        this.props.jobId,
        this.state.sweptVariableValues
      ),
      { nfwf, decomposition_wf, error_wf, rmse } = result.results[0];
    let newState = {
      loadingResult: false,
      nfwf,
      rmse,
      decomposition_wf,
      error_wf
    };
    newState.amplitude_wf = nfwf ? [nfwf[0], "fake phase"] : null;
    newState.nfwf = nfwf ? [nfwf[1], "fake phase"] : null;
    newState.decomposition_wf = decomposition_wf
      ? [decomposition_wf, "fake phase for decomp"]
      : null;
    newState.error_wf = error_wf ? [error_wf, "fake phase for error"] : null;
    this.setState(newState);
  };

  /**
   * @returns {Boolean} whether variables were used in the analysis
   */
  isMultipleResult() {
    return this.props.sweptVariables.length > 0;
  }

  render() {
    const {
        classes,
        sweptVariables,
        width,
        height,
        rmseThreshold
      } = this.props,
      {
        nfwf,
        rmse,
        loadingResult,
        amplitude_wf,
        decomposition_wf,
        error_wf
      } = this.state,
      centerGridStyle = { margin: "auto" };
    return (
      <div className={classes.root}>
        <Typography className={classes.title} variant="h5" component="h3">
          {"Result"}
        </Typography>

        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={5}>
            <Grid container style={{ width: "60%", margin: "auto" }}>
              {this.isMultipleResult() && (
                <Grid item xs={12}>
                  <SweepPoint
                    sweptVariables={sweptVariables.filter(sv =>
                      this.ifGeneralSweepUsedParameter(sv)
                    )}
                    sweptVariableValues={this.state.sweptVariableValues}
                    setSelectedValue={this.setSelectedValue}
                    onChangeCommitted={() => {
                      this.getResult();
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                {loadingResult && (
                  <Spinner
                    size={25}
                    className={classes.centerElement}
                    timeout={30000}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {amplitude_wf && (
                <Grid
                  item
                  xs={3}
                  style={centerGridStyle}
                  test-data={"amplitude_wf"}
                >
                  <GenericWavefront
                    title={"Amplitude"}
                    hidePhase
                    wavefront={amplitude_wf}
                    wfWidth={width * (1 - 1 / amplitude_wf[0][0].length)}
                    wfHeight={height * (1 - 1 / amplitude_wf[0].length)}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    // unit={nfwfUnit}
                    showLegend
                  />
                </Grid>
              )}
              {nfwf && (
                <Grid item xs={3} style={centerGridStyle} test-data={"nfwf"}>
                  <GenericWavefront
                    title={"Input wavefront"}
                    hidePhase
                    wavefront={nfwf}
                    wfWidth={width * (1 - 1 / nfwf[0][0].length)}
                    wfHeight={height * (1 - 1 / nfwf[0].length)}
                    rowsCount={100}
                    columnsCount={100}
                    colorBarUnit={"rad"}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    // unit={nfwfUnit}
                    showLegend
                    zmin={-3.14}
                    zmax={3.14}
                  />
                </Grid>
              )}
              {decomposition_wf && (
                <Grid
                  item
                  xs={3}
                  style={centerGridStyle}
                  test-data={"decomposition_wf"}
                >
                  <GenericWavefront
                    title={"Decomposition wavefront"}
                    hidePhase
                    wavefront={decomposition_wf}
                    wfWidth={width * (1 - 1 / decomposition_wf[0][0].length)}
                    wfHeight={height * (1 - 1 / decomposition_wf[0].length)}
                    rowsCount={100}
                    columnsCount={100}
                    colorBarUnit={"rad"}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    // unit={nfwfUnit}
                    showLegend
                  />
                </Grid>
              )}
              {error_wf && (
                <Grid
                  item
                  xs={3}
                  style={centerGridStyle}
                  test-data={"error_wf"}
                >
                  <GenericWavefront
                    title={"Error wavefront"}
                    hidePhase
                    wavefront={error_wf}
                    wfWidth={width * (1 - 1 / error_wf[0][0].length)}
                    wfHeight={height * (1 - 1 / error_wf[0].length)}
                    //                     rowsCount={100}
                    //                     columnsCount={100}
                    colorBarUnit={"rad"}
                    // onWaveFrontSelect={this.setNfwfCrossSectionType}
                    // unit={nfwfUnit}
                    showLegend
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {rmse && (
              <Typography
                className={classes.title}
                component="p"
                style={{
                  color: Number(rmse) > Number(rmseThreshold) ? "red" : null
                }}
              >
                {`RMSE: ${HelperUtils.limitDecimals(rmse, 3)}`}
                {Number(rmse) > Number(rmseThreshold) && (
                  <IconTooltip text="The obtained RMSE seems to exceed the given threshold. The decomposition might be incorrect." />
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
        {rmse != null && rmse > rmseThreshold && (
          <DirectionSnackbar
            message={"The obtained RMSE seems to exceed the given threshold."}
          />
        )}
      </div>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(RayTracingJobResult));
