import React, { PureComponent } from "react";
import {
  Paper,
  Grid,
  Typography,
  withStyles,
  TextField
} from "@material-ui/core";
import SweepInput from "components/SweepInput/SweepInput";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  container: {
    alignItems: "center",
    justifyContent: "center"
  }
});

/**
 * A class component to allow the user to input the simulation probe
 * @typedef {Component} MaxProbe
 * @author Akira
 */
export class Probe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      position: props.position
    };
  }

  handleBlur = field => {
    // we delay the save a little bit because the sweep input can reset its value
    setTimeout(() => {
      if (this.state[field] !== "") {
        this.saveField(field, this.state[field]);
        return;
      }
    }, 300);
  };

  handleBlurEvent = e => {
    this.handleBlur(e.target.name, e.target.value);
  };

  saveField = (name, value) => {
    this.props.updateFieldCallback({ [name]: value });
  };

  handleFieldChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h3" style={{ float: "left" }}>
              Probe
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="name"
                    test-data="probeName"
                    value={this.state.name}
                    onChange={e =>
                      this.handleFieldChange("name", e.target.value)
                    }
                    label="Name"
                    onBlur={this.handleBlurEvent}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SweepInput
                    name="position"
                    test-data="probePosition"
                    value={this.state.position}
                    onChange={value =>
                      this.handleFieldChange("position", value)
                    }
                    label="Position"
                    onBlur={this.handleBlur}
                    onSelect={this.handleBlur}
                    sweptVariables={this.props.sweptVariables}
                    restrictFormula={true}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(Probe));
