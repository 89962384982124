/**
 * A selector class for the selected design target state
 */
export default class SelectedDesignTargetSelector {
  /**
   * it selects the selected design targets from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the selected design targets
   */
  static getSelectedDesignTargets = state =>
    state.selectedDesignTarget.entities.selectedDesignTargets;
}
