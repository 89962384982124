import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import SimulationApi from "MetaCell/api/Simulation";
import { Button, Tooltip, Typography } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";
import Lozenge from "@atlaskit/lozenge";

const styles = {
  buttonWrapper: {
    position: "relative",
    marginTop: 10
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  tooltip: {
    fontSize: "16px !important"
  }
};

const statusAppearances = {
  IDLE: "default",
  RUNNING: "inprogress",
  DONE: "success",
  ERROR: "removed"
};

export class PlotMenu extends PureComponent {
  /**
   * it stops a plot by calling the endpoint
   */
  onStopButtonClick = () => {
    return SimulationApi.stopPlot(this.props.plotId).then(() =>
      Promise.resolve(true)
    );
  };

  render() {
    const {
      showErrorsAndWarnings,
      onUpdateButtonClick,
      isUpdateButtonDisabled,
      polling,
      tooltipError,
      status,
      progress,
      classes,
      disabled
    } = this.props;
    return (
      <div>
        <Button
          test-data="alerts"
          variant="contained"
          onClick={showErrorsAndWarnings}
          disabled={disabled}
          style={{ width: "100%", marginTop: 10 }}
        >
          Alerts
        </Button>
        <div className={classes.buttonWrapper}>
          {tooltipError ? (
            <Tooltip
              title={tooltipError}
              open={tooltipError !== ""}
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  test-data="updateBtn"
                  onClick={onUpdateButtonClick}
                  disabled={isUpdateButtonDisabled || polling}
                  style={{
                    width: "100%",
                    ...(tooltipError !== "" && {
                      backgroundColor: "#f44336"
                    })
                  }}
                >
                  Update
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              color="primary"
              test-data="updateBtn"
              onClick={onUpdateButtonClick}
              disabled={isUpdateButtonDisabled || polling}
              style={{
                width: "100%"
              }}
            >
              Update
            </Button>
          )}
          {polling && (
            <Spinner
              name="Waiting"
              size={24}
              className={classes.buttonProgress}
            />
          )}
        </div>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Lozenge appearance={statusAppearances[status]}>{status}</Lozenge>
        </div>
        {status === "RUNNING" && (
          <>
            <div>
              {progress ? (
                <Typography
                  variant="subtitle2"
                  test-data="progressStatus"
                  style={{ marginBottom: 10 }}
                >
                  {progress}%
                </Typography>
              ) : null}
            </div>
            <Button
              test-data="stopButton"
              variant="contained"
              onClick={this.onStopButtonClick}
              style={{
                width: "100%"
              }}
            >
              Stop
            </Button>
          </>
        )}
      </div>
    );
  }
}

PlotMenu.propTypes = {
  height: PropTypes.number.isRequired,
  showErrorsAndWarnings: PropTypes.func.isRequired,
  onUpdateButtonClick: PropTypes.func.isRequired,
  isUpdateButtonDisabled: PropTypes.bool.isRequired,
  polling: PropTypes.bool.isRequired,
  tooltipError: PropTypes.string,
  status: PropTypes.string.isRequired
};

export default withStyles(styles)(PlotMenu);
