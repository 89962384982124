import React from "react";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { CSVLink } from "react-csv";
import MaterialGraphs from "./MaterialGraphs";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

const buttonStyle = {
  marginBottom: 15,
  width: "100%"
};

/**
 * A component to visualize the material
 * @author Akira Kotsugai
 */
export const MaterialVisualization = ({
  data,
  id,
  exportImage,
  exportJson,
  csvData,
  name
}) => {
  return (
    <Grid container style={{ height: "100%" }} alignItems="center">
      <Grid item xs={10} style={{ height: "100%" }}>
        <MaterialGraphs data={data} id={id} />
      </Grid>
      <Grid item xs={2}>
        <div test-data="actions">
          <Button
            test-data="exportImageBtn"
            style={buttonStyle}
            onClick={e => exportImage(id)}
            variant="contained"
          >
            Export Image
          </Button>
          <CSVLink
            separator={";"}
            data={csvData}
            style={{ color: "inherit" }}
            filename={`${name}_material.csv`}
            enclosingCharacter={""}
          >
            <Button style={buttonStyle} variant="contained">
              Export CSV
            </Button>
          </CSVLink>
          <Button
            test-data="exportJsonBtn"
            style={buttonStyle}
            variant="contained"
            onClick={e => exportJson(id)}
          >
            Export Json
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default withErrorBoundary(MaterialVisualization);
