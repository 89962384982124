import { actionType } from "MetaCell/actions/Material";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testMaterials = {
  byId: {
    1: {
      id: 1,
      name: "dummy",
      wavelength: 66,
      refractiveIndex: 66,
      absorptionCoeff: 66,
      reference: "none",
      color: "#ff0000",
      owner: 1
    },
    2: {
      id: 2,
      name: "Air",
      wavelength: 550,
      refractiveIndex: 1,
      absorptionCoeff: 0,
      reference: "Basic example N2",
      color: "#34ebe5",
      owner: 1
    },
    3: {
      id: 3,
      name: "SiO2",
      wavelength: 440,
      refractiveIndex: 3.5,
      absorptionCoeff: 0.2,
      reference: "Basic example SiO2",
      color: "#00ff00",
      owner: 1
    },
    4: {
      id: 4,
      name: "Si",
      wavelength: 550,
      refractiveIndex: 2.5,
      absorptionCoeff: 2,
      reference: "Basic example Si",
      color: "#AA0000",
      owner: 3
    },
    5: {
      id: 5,
      name: "test",
      wavelength: -1,
      refractiveIndex: 0,
      absorptionCoeff: 0,
      reference: "none",
      color: "#ff0000",
      owner: 3
    }
  },
  allIds: [1, 2, 3, 4, 5],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} MaterialDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    materials: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

/**
 * Reducer function to manipulate the state of materials
 * @author Ibtihel
 * @param {Object} [state=MaterialDefaultState] - material entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case actionType.UPSERT_MATERIALS: {
      let materials = cloneDeep(state.entities.materials);
      Utils.addOrUpdateEntities(payload, materials);
      return {
        ...state,
        entities: { ...state.entities, materials }
      };
    }

    case actionType.DELETE_MATERIAL: {
      let materials = cloneDeep(state.entities.materials);
      Utils.deleteEntities([payload], materials);
      return {
        ...state,
        entities: { ...state.entities, materials }
      };
    }

    default:
      return state;
  }
}
