import {
  RETRIEVE_TOKENS,
  SIGN_IN_STARTED,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  REMOVE_TOKENS,
  RESET_SIGN_IN,
  REGISTER_STARTED,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_REGISTER,
  AUTH_INTERCEPTOR_LOADED
} from "./actions";

const defaultState = {
  tokens: null,
  signIn: {},
  register: {},
  authInterceptor: {}
};

export default (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case RETRIEVE_TOKENS: {
      return { ...state, tokens: { ...payload } };
    }
    case REMOVE_TOKENS: {
      return { ...state, tokens: { access: null, refresh: null } };
    }
    case SIGN_IN_STARTED: {
      return { ...state, signIn: { loading: true } };
    }
    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        signIn: { loading: false, success: true, data: { ...payload } }
      };
    }
    case SIGN_IN_FAILURE: {
      return {
        ...state,
        signIn: { loading: false, error: true, data: { ...payload } }
      };
    }
    case RESET_SIGN_IN: {
      return { ...state, signIn: {} };
    }
    case REGISTER_STARTED: {
      return { ...state, register: { loading: true } };
    }
    case REGISTER_SUCCESS: {
      return { ...state, register: { loading: false, success: true } };
    }
    case REGISTER_FAILURE: {
      return {
        ...state,
        register: { loading: false, error: true, data: { ...payload } }
      };
    }
    case RESET_REGISTER: {
      return { ...state, register: {} };
    }
    case AUTH_INTERCEPTOR_LOADED: {
      return { ...state, authInterceptor: { loaded: true } };
    }
    default: {
      return state;
    }
  }
};
