import { cloneDeep } from "lodash";

/**
 * Helper class to help components deal with family member data.
 */
export default class FamilyMemberHelper {
  /**
   * format the diff summary data if it is an array
   * @param {String[][] | String} diffSummary - the diff summary array
   * @returns {String} a text containing the diffs in a human readable way
   */
  static formatDiffSummary(diffSummary) {
    if (Array.isArray(diffSummary)) {
      let summary = "",
        mutableDiffSummary = cloneDeep(diffSummary);
      if (mutableDiffSummary) {
        mutableDiffSummary.forEach(diff => {
          const value = diff.pop();
          const subject = diff.pop();
          summary +=
            " the " +
            subject +
            " of " +
            diff.reverse().join(" of ") +
            " is " +
            value +
            ";";
        });
      }
      return summary;
    }
    return diffSummary;
  }

  /**
   * @param {Object[]} members - the family members data
   * @returns {Boolean} whether all members have the same thickness
   */
  static membersHaveSameThickness(members) {
    if (!members.length) {
      return true;
    }
    const thicknesses = members.map(member => member.total_thickness);
    return thicknesses.every(thickness => thickness === thicknesses[0]);
  }
}
