/**
 * A selector class for the simulation state
 * @author Akira Kotsugai
 */
export default class SimulationSelector {
  /**
   * it selects the simulation jobs from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the simulation jobs
   */
  static getJobs = state => state.simulation.entities.jobs;

  /**
   * it selects the selected simulation job id
   * @param {Object} state => the redux state
   * @returns {Number} - the simulation job id
   */
  static getSelectedJobId = state => state.simulation.ui.selectedJobId;

  /**
   * it selects the mode analysis jobs from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the mode analysis jobs
   */
  static getModeAnalysisJobs = state =>
    state.simulation.entities.modeAnalysisJobs;

  /**
   * it selects the selected mode analysis job id from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the mode analysis jobs
   */
  static getSelectedModeAnalysisJobId = state =>
    state.simulation.ui.selectedModeAnalysisJobId;

  /**
   * it selects the selected simulation results
   * @param {Object} state => the redux state
   * @return {Object} - the simulation results
   */
  static getSelectedResults = state => state.simulation.entities.results;

  static getSelectedPoints = state => state.simulation.entities.selectedPoints;

  /**
   * @param {*} state - the redux state
   * @return {Object} the filters for the simulation overview
   */
  static getOverviewFilters = state => state.simulation.ui.overviewFilters;

  /**
   * @param {*} state - the redux state
   * @return {Object} the filters for the plot overview
   */
  static getPlotOverviewFilters = state =>
    state.simulation.ui.plotOverviewFilters;
}
