/**
 * @constant
 * @typedef {Object} SelectedDesignTargetActionType
 * @property {String} DELETE_SELECTED_DESIGN_TARGET - tells the reducer to delete a selected design target
 * @property {String} UPSERT_SELECTED_DESIGN_TARGETS - tells the reducer to insert or update selected design targets
 * @global
 * it defines selected design target's type of actions.
 */
export const actionType = Object.freeze({
  SET_SELECTED_DESIGN_TARGETS: "SET_SELECTED_DESIGN_TARGETS",
  DELETE_SELECTED_DESIGN_TARGET: "DELETE_SELECTED_DESIGN_TARGET",
  UPSERT_SELECTED_DESIGN_TARGETS: "UPSERT_SELECTED_DESIGN_TARGETS"
});

/**
 * A class created to create design target actions to be dispatched to redux. It is already tested with
 * Selected Design Target reducer, so it does not need to be unit tested.
 */
export default class SelectedDesignTargetAction {
  static set = value => ({
    type: actionType.SET_SELECTED_DESIGN_TARGETS,
    payload: value
  });

  static delete = designTargetId => ({
    type: actionType.DELETE_SELECTED_DESIGN_TARGET,
    payload: designTargetId
  });

  static upsert = value => ({
    type: actionType.UPSERT_SELECTED_DESIGN_TARGETS,
    payload: value
  });
}
