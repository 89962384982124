import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "reducer/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(thunk)
)(createStore);

const store = process.env.JEST_WORKER_ID
  ? { dispatch: () => {}, getState: () => {}, subscribe: () => {} }
  : createStoreWithMiddleware(reducer);

export default store;
