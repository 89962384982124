import { actionType } from "MetaComponent/actions/DirectoryExplorer";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testMCGroups = {
  byId: {
    1: {
      id: 1,
      name: "mc group 1",
      metacomponent_set: [1, 2]
    },
    2: {
      id: 2,
      name: "mc group 2",
      metacomponent_set: [3, 4, 5]
    }
  },
  allIds: ["1", "2"],
  loaded: true
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testMetaComponents = {
  byId: {
    1: {
      id: 1,
      name: "meta component 1",
      mcGroup: 1,
      description: "created because I wanted",
      selectedFM: [1, 2],
      selected_design_targets: [1, 2],
      width: 300,
      height: 350,
      unit: "mm",
      family: 1
    },
    2: {
      id: 2,
      name: "meta component 2",
      mcGroup: 1,
      description: "created because I needed",
      selectedFM: [],
      selected_design_targets: [],
      family: 1
    },
    3: {
      id: 3,
      name: "meta component 1",
      mcGroup: 2,
      description: "created because I was told to",
      family: 2,
      selected_design_targets: [3]
    },
    4: {
      id: 4,
      name: "meta component 4",
      mcGroup: 2,
      description: "created for testing purposes",
      family: null,
      selectedFM: [],
      selected_design_targets: [4]
    },
    5: {
      id: 5,
      name: "meta component 5",
      mcGroup: 2,
      description: "created for testing purposes",
      family: null,
      selectedFM: [],
      selected_design_targets: [100]
    }
  },
  allIds: ["1", "2", "3", "4", "5"],
  loaded: true
};

const getLastCreatedMetaComponentId = metaComponents => {
  if (metaComponents.length === 0) return -1;
  return Math.max.apply(
    null,
    metaComponents.map(metaComponent => metaComponent.id)
  );
};

/**
 * @constant
 * @typedef {Object} DirectoryExplorerDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    mcGroups: {
      byId: {},
      allIds: [],
      loaded: false
    },
    metaComponents: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    selectedItem: null,
    form: "new group",
    metaComponentOpenId: -1,
    editingItem: null
  }
};

/**
 * Reducer function to manipulate the state of the directory explorer (the tree and the form)
 * @author Akira Kotsugai
 * @param {Object} [state=DirectoryExplorerDefaultState] - groups, meta components and ui management
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;

  const getFilteredMetaComponents = metaComponentsIdsToDelete => {
    let newMetaComponents = cloneDeep(state.entities.metaComponents);
    Utils.deleteEntities(metaComponentsIdsToDelete, newMetaComponents);
    return newMetaComponents;
  };

  const getFilteredMCGroups = mcGroupIdsToDelete => {
    let newMCGroups = cloneDeep(state.entities.mcGroups);
    Utils.deleteEntities(mcGroupIdsToDelete, newMCGroups);
    return newMCGroups;
  };

  switch (action.type) {
    case actionType.UPDATE_SELECTED_ITEM: {
      let form = "";
      if (!Array.isArray(payload)) {
        if (payload.type === itemType.PROJECT) form = "new meta component";
        else if (payload.type === itemType.USER) form = "new group";
      }
      return {
        ...state,
        ui: { ...state.ui, selectedItem: payload, form }
      };
    }

    case actionType.OPEN_META_COMPONENT: {
      return {
        ...state,
        ui: { ...state.ui, metaComponentOpenId: payload }
      };
    }

    case actionType.UPDATE_FORM: {
      return {
        ...state,
        ui: { ...state.ui, form: payload }
      };
    }

    case actionType.UPSERT_MC_GROUPS: {
      let mcGroups = cloneDeep(state.entities.mcGroups);
      Utils.addOrUpdateEntities(payload, mcGroups);
      return {
        ...state,
        entities: {
          ...state.entities,
          mcGroups
        }
      };
    }

    case actionType.UPSERT_META_COMPONENTS: {
      let entities = {
        ...state.entities
      };

      let newMetaComponents = cloneDeep(state.entities.metaComponents);
      Utils.addOrUpdateEntities(payload, newMetaComponents);
      entities["metaComponents"] = newMetaComponents;

      const mcGroupsToUpdate = [];
      payload.forEach(metaComponent => {
        const mcGroup = state.entities.mcGroups.byId[metaComponent.mcGroup];
        if (mcGroup !== undefined) {
          const inserting = !mcGroup.metacomponent_set.includes(
            metaComponent.id
          );
          if (inserting) {
            const metacomponent_set = mcGroup.metacomponent_set.concat(
              metaComponent.id
            );
            const updatedMCGroup = { ...mcGroup, metacomponent_set };
            mcGroupsToUpdate.push(updatedMCGroup);
          }
        }
      });

      if (mcGroupsToUpdate.length > 0) {
        let newMCGroups = cloneDeep(state.entities.mcGroups);
        Utils.addOrUpdateEntities(mcGroupsToUpdate, newMCGroups);
        entities["mcGroups"] = newMCGroups;
      }

      const currentMetaComponentIds = state.entities.metaComponents.allIds;
      const isSingleInsertion =
        payload.length === 1 &&
        !currentMetaComponentIds.includes("" + payload[0].id);
      const metaComponentOpenId = isSingleInsertion
        ? payload[0].id
        : state.ui.metaComponentOpenId;
      return {
        ...state,
        entities,
        ui: {
          ...state.ui,
          metaComponentOpenId
        }
      };
    }

    case actionType.SET_DIRECTORY_EXPLORER: {
      let mcGroups = cloneDeep(defaultState.entities.mcGroups);
      let metaComponents = cloneDeep(defaultState.entities.metaComponents);
      Utils.addOrUpdateEntities(payload.mcGroups, mcGroups);
      Utils.addOrUpdateEntities(payload.metaComponents, metaComponents);
      const metaComponentOpenId = getLastCreatedMetaComponentId(
        payload.metaComponents
      );
      return {
        ...state,
        entities: {
          mcGroups,
          metaComponents
        },
        ui: {
          ...state.ui,
          metaComponentOpenId
        }
      };
    }

    case actionType.DELETE_MC_GROUPS_AND_META_COMPONENTS: {
      const mcGroupIds = payload
        .filter(({ type }) => type === itemType.PROJECT)
        .map(({ id }) => id);
      let metaComponentIds = payload
        .filter(({ type }) => type === itemType.SIMULATION)
        .map(({ id }) => id);
      for (const mcGroupId of mcGroupIds) {
        const mcGroup = state.entities.mcGroups.byId[mcGroupId];
        metaComponentIds.push(...mcGroup.metacomponent_set);
      }
      metaComponentIds = [...new Set(metaComponentIds)]; // making them unique
      const newMCGroups = getFilteredMCGroups(mcGroupIds);
      const newMetaComponents = getFilteredMetaComponents(metaComponentIds);
      for (const metaComponentId of metaComponentIds) {
        const metaComponent =
          state.entities.metaComponents.byId[metaComponentId];
        const mcGroupId = metaComponent.mcGroup;
        const mcGroupWontBeDeleted = !mcGroupIds.includes(mcGroupId);
        if (mcGroupWontBeDeleted) {
          const newMetaComponentSet = newMCGroups.byId[
            mcGroupId
          ].metacomponent_set.filter(id => id !== metaComponentId);
          newMCGroups.byId[mcGroupId].metacomponent_set = newMetaComponentSet;
        }
      }
      const metaComponentOpenId = newMetaComponents.allIds.includes(
        "" + state.ui.metaComponentOpenId
      )
        ? state.ui.metaComponentOpenId
        : -1;
      return {
        ...state,
        entities: {
          ...state.entities,
          mcGroups: newMCGroups,
          metaComponents: newMetaComponents
        },
        ui: {
          ...state.ui,
          metaComponentOpenId,
          selectedItem: null
        }
      };
    }

    case actionType.DELETE_MC_GROUP: {
      const mcGroupId = payload;
      const mcGroup = state.entities.mcGroups.byId[mcGroupId];
      let newMetaComponents = cloneDeep(state.entities.metaComponents);
      Utils.deleteEntities(mcGroup.metacomponent_set, newMetaComponents);
      let newMCGroups = cloneDeep(state.entities.mcGroups);
      Utils.deleteEntities([mcGroupId], newMCGroups);
      const metaComponentOpenId = newMetaComponents.allIds.includes(
        "" + state.ui.metaComponentOpenId
      )
        ? state.ui.metaComponentOpenId
        : -1;
      return {
        ...state,
        entities: {
          ...state.entities,
          mcGroups: newMCGroups,
          metaComponents: newMetaComponents
        },
        ui: {
          ...state.ui,
          metaComponentOpenId,
          selectedItem: null
        }
      };
    }

    case actionType.DELETE_META_COMPONENT: {
      const metaComponentId = payload;
      const metaComponent = state.entities.metaComponents.byId[metaComponentId];
      const mcGroupId = metaComponent.mcGroup;
      const mcGroup = state.entities.mcGroups.byId[mcGroupId];
      const newProject = {
        ...mcGroup,
        metacomponent_set: mcGroup.metacomponent_set.filter(
          id => id != metaComponentId
        )
      };
      const newMetaComponents = cloneDeep(state.entities.metaComponents);
      Utils.deleteEntities([metaComponentId], newMetaComponents);
      const newMCGroups = cloneDeep(state.entities.mcGroups);
      newMCGroups.byId[mcGroupId] = newProject;
      const metaComponentOpenId = newMetaComponents.allIds.includes(
        "" + state.ui.metaComponentOpenId
      )
        ? state.ui.metaComponentOpenId
        : -1;
      return {
        ...state,
        entities: {
          ...state.entities,
          mcGroups: newMCGroups,
          metaComponents: newMetaComponents
        },
        ui: {
          ...state.ui,
          metaComponentOpenId,
          selectedItem: null
        }
      };
    }

    case actionType.EDIT_ITEM: {
      return {
        ...state,
        ui: { ...state.ui, editingItem: payload }
      };
    }

    case actionType.UPDATE_ITEM_EDITING_VALUE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingItem: { ...state.ui.editingItem, name: payload }
        }
      };
    }

    case actionType.APPLY_EDITED_MC_GROUP: {
      let mcGroups = cloneDeep(state.entities.mcGroups);
      Utils.addOrUpdateEntities([payload], mcGroups);
      return {
        ...state,
        entities: {
          ...state.entities,
          mcGroups
        },
        ui: { ...state.ui, editingItem: null }
      };
    }

    case actionType.APPLY_EDITED_META_COMPONENT: {
      let metaComponents = cloneDeep(state.entities.metaComponents);
      Utils.addOrUpdateEntities([payload], metaComponents);
      return {
        ...state,
        entities: {
          ...state.entities,
          metaComponents
        },
        ui: { ...state.ui, editingItem: null }
      };
    }

    case actionType.SELECT_AND_UNSELECT_DESIGN_TARGETS: {
      let metaComponents = cloneDeep(state.entities.metaComponents);
      const metaComponentToPatch = metaComponents.byId[payload.metaComponentId],
        { selected_design_targets } = metaComponentToPatch,
        newSelectedDesignTargets = [
          ...selected_design_targets,
          ...payload.selectedDesignTargets
        ].filter(sdt => !payload.unselectedDesignTargets.includes(sdt)),
        patchedMetaComponent = {
          ...metaComponentToPatch,
          selected_design_targets: newSelectedDesignTargets
        };
      Utils.addOrUpdateEntities([patchedMetaComponent], metaComponents);
      return {
        ...state,
        entities: {
          ...state.entities,
          metaComponents
        }
      };
    }

    case actionType.CLEAN_DESIGN_TARGET_RELATION: {
      let metaComponents = cloneDeep(state.entities.metaComponents);
      const metaComponentsLinkedToDT = Object.values(
          metaComponents.byId
        ).filter(({ selected_design_targets }) =>
          selected_design_targets.includes(payload)
        ),
        cleanedmetaComponents = metaComponentsLinkedToDT.map(metaComponent => ({
          ...metaComponent,
          selected_design_targets: metaComponent.selected_design_targets.filter(
            dtId => dtId !== payload
          )
        }));
      Utils.addOrUpdateEntities(cleanedmetaComponents, metaComponents);
      return {
        ...state,
        entities: {
          ...state.entities,
          metaComponents
        }
      };
    }

    default: {
      return state;
    }
  }
}
