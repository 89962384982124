export const actionType = Object.freeze({
  UPSERT_ODA_JOBS: "UPSERT_ODA_JOBS",
  SELECT_ODA_JOB: "SELECT_ODA_JOB",
  RESET_ODA_JOBS: "RESET_ODA_JOBS",
  UPDATE_TEMP_ODA_VARIABLES: "UPDATE_TEMP_ODA_VARIABLES",
  SET_ODA_JOBS: "SET_ODA_JOBS",
  DELETE_ODA_JOBS: "DELETE_ODA_JOBS",
  UPDATE_OVERVIEW_FILTERS: "UPDATE_ODA_OVERVIEW_FILTERS"
});

export default class ODAAction {
  static updateOverviewFilters = filters => ({
    type: actionType.UPDATE_OVERVIEW_FILTERS,
    payload: filters
  });

  static set = jobs => ({
    type: actionType.SET_ODA_JOBS,
    payload: jobs
  });

  static upsertJobs = jobs => ({
    type: actionType.UPSERT_ODA_JOBS,
    payload: jobs
  });
  static selectJob = id => ({
    type: actionType.SELECT_ODA_JOB,
    payload: id
  });
  static resetJobs = () => ({
    type: actionType.RESET_ODA_JOBS,
    payload: {}
  });
  static updateTempVariables = newVariables => ({
    type: actionType.UPDATE_TEMP_ODA_VARIABLES,
    payload: newVariables
  });
  static deleteJobs = jobIds => ({
    type: actionType.DELETE_ODA_JOBS,
    payload: jobIds
  });
}
