import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import LayerUsedMaterials from "./components/LayerUsedMaterials/LayerUsedMaterials";
import StructureSelector from "MetaCell/selectors/Structure";
import StructureAction from "MetaCell/actions/Structure";
import MaterialSelector from "MetaCell/selectors/Material";
import StructureApi from "MetaCell/api/Structure";
import StructureHelper from "MetaCell/helper/Structure";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import Grid from "@material-ui/core/Grid";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import FileCopy from "@material-ui/icons/FileCopy";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SweepInput from "components/SweepInput/SweepInput";
import LayerParameterizedStructure from "./components/LayerParameterizedStructure/LayerParameterizedStructure";
import TextField from "@material-ui/core/TextField";
import DrilldownInput from "components/DrilldownInput";
import { isEqual } from "lodash";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import LayersIcon from "@material-ui/icons/Layers";
import ParamStack from "./components/ParamStack/ParamStack";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import Sync from "@material-ui/icons/Sync";

const styles = theme => ({
  layerStack: {
    width: "100%",
    position: "relative"
    //minWidth: 610
  }
});

class CombinationOperator {
  constructor(name, label) {
    this._name = name;
    this._label = label;
  }

  get name() {
    return this._name;
  }

  get label() {
    return this._label;
  }
}

/**
 * static structure types
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} StaticStructureTypes
 * @property {String} HOMOGENEOUS - it represents the inexistence of a structure
 * @property {String} IMAGE - it represents a structure that is an image file
 * @global
 */
export const staticStructureTypes = Object.freeze({
  HOMOGENEOUS: "homogeneous",
  IMAGE: "image",
  COMBINATION: "combination"
});

export const staticStructureTooltips = Object.freeze({
  IMAGE: "Upload a top view of the layer from an image file (jpg/png/bmp/...)"
});

/**
 *  A component to represent the stack of layers
 * @author Ibtihel
 */
export class LayerStack extends PureComponent {
  constructor() {
    super();
    this.layerImageInputRef = React.createRef();
    this.usedMaterialsRef = React.createRef();
    this.tableRef = null;
    this.combinationOperators = [
      new CombinationOperator("+", "+"),
      new CombinationOperator("~", "-"),
      new CombinationOperator("*", "*")
    ];
    this.state = {
      paramStackDialogOpen: false,
      isAddingLayer: false,
      saving: false
    };
  }

  setTableRef = ref => {
    this.tableRef = ref;
  };

  /**
   * It is supposed to be passed to the {@link GenericActions} component to handle a layer deletion
   * by calling an action with the layer.
   * @callback
   */
  handleDeleteLayer = () => {
    const { selectedLayerId, deleteLayerAction, simulationOpenId } = this.props;
    if (selectedLayerId !== -1) {
      const operation = () => {
        const layerId = isNaN(selectedLayerId)
          ? selectedLayerId.split("#")[0]
          : selectedLayerId;
        return deleteLayerAction(layerId, simulationOpenId).then(() =>
          this.setState({ saving: false })
        );
      };
      return this.performLayerOperation(operation);
    }
  };

  /**
   * @param {Function} operation - a function that returns a promise
   */
  performLayerOperation(operation) {
    this.setState({ saving: true });
    const operationPromise = operation();
    return operationPromise.then(() => this.setState({ saving: false }));
  }

  /**
   * It is supposed to be passed to the {@link GenericActions} component to handle a layer copy
   * by calling an action with the layer to copy, the following layers to have their indexes
   * updated and the copied used materials
   * @callback
   */
  handleCopyLayer = () => {
    const { selectedLayerId, copyLayerAction, simulationOpenId } = this.props;
    if (selectedLayerId !== -1) {
      const operation = () =>
        copyLayerAction(selectedLayerId, simulationOpenId);
      return this.performLayerOperation(operation);
    }
  };

  /**
   * It is supposed to be passed to the {@link GenericActions} component to handle the intention to shift
   * a layer up if it is not the first layer already
   * @callback
   */
  handleShiftLayerUp = () => {
    const { selectedLayerId, layers } = this.props;
    const layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    const layerToShiftUp = layerObjs.find(
      layer => layer.id === selectedLayerId
    );
    if (layerToShiftUp) {
      const newIndex = layerToShiftUp.index - 1;
      const { id } = layerToShiftUp;
      const layerId = isNaN(id) && id.includes("#") ? id.split("#")[0] : id;
      return this.shiftLayer(layerId, newIndex);
    }
  };

  /**
   * It is supposed to be passed to the {@link GenericActions} component to handle the intention to shift
   * a layer down if it is not the last layer already
   * @callback
   */
  handleShiftLayerDown = () => {
    const { selectedLayerId, layers } = this.props;
    const layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    let layerToShiftDown = layerObjs.find(
      layer => layer.id === selectedLayerId
    );
    if (layerToShiftDown) {
      const newIndex = layerToShiftDown.index + 1;
      const { id } = layerToShiftDown;
      const layerId = isNaN(id) && id.includes("#") ? id.split("#")[0] : id;
      return this.shiftLayer(layerId, newIndex);
    }
  };

  handleInvertLayers = () => {
    const { invertLayersAction, simulationOpenId } = this.props;
    const operation = () => invertLayersAction(simulationOpenId);
    return this.performLayerOperation(operation);
  };

  /**
   * it finds which layer will change position with the given layer
   * and calls an action to shift the layer
   * @param {Number} layerToShiftId - the layer that will move up or down
   * @param {Number} newIndex - the new index that the layer will receive
   */
  shiftLayer = (layerToShiftId, newIndex) => {
    const { shiftLayerAction, simulationOpenId } = this.props;
    const operation = () =>
      shiftLayerAction(layerToShiftId, newIndex, simulationOpenId);
    return this.performLayerOperation(operation);
  };

  /**
   * It gets materials for the select options when a used material is being edited
   * it also includes the currently selected material as the first option
   * @callback
   * @param {Number} selectedMaterialId - the currently selected materials' id
   * @return {Object[]} a list of materials
   */
  getSelectOptionsForLayerUsedMaterials = selectedMaterialId => {
    const { selectedLayerId, materials, usedMaterials, layers } = this.props;
    const selectedLayer = layers.byId[selectedLayerId];
    const materialsValues = Object.values(materials.byId);
    let selectOptions = StructureHelper.getMaterialsNotBeingUsedByLayer(
      // StructureHelper.getLayerUsedMaterials(selectedLayer, usedMaterials),
      [],
      materialsValues
    );
    let optionSelected = "";
    if (selectedMaterialId) {
      optionSelected = this.props.materials.byId[selectedMaterialId];
      selectOptions = selectOptions.filter(item => item !== optionSelected);
    }
    if (selectedMaterialId) {
      selectOptions.unshift(optionSelected);
    }
    return selectOptions;
  };

  /**
   * it formats the original layers to make it displayable in the layer stack.
   * for each layer it creates a "materials" property and a "structure" property that is not exactly
   * the structure in the layer entity, but could also be the layer image name or "homogenous"
   * @returns {Object[]} the list of all presentable layers.
   */
  getLayersPresentableData = () => {
    const { layers, usedMaterials, materials } = this.props;
    const expandedLayers = StructureHelper.getExpandedStaircaseLayers(layers);
    return expandedLayers.map(layer => {
      const layerUsedMaterials = StructureHelper.getLayerUsedMaterials(
          layer,
          usedMaterials
        ),
        layerMaterials = StructureHelper.getMaterialsFromLayerUsedMaterials(
          layerUsedMaterials,
          materials
        );
      return {
        ...layer,
        materials: StructureHelper.formatLayerMaterialsNames(layerMaterials),
        tempStructure: this.getTempLayerStructure(layer)
      };
    });
  };

  /**
   * it generates the structure types to be passed to the layers
   * by gathering the static structure types and the parameterized structures
   * @returns {String[]} the structures
   */
  getStructureTypes = () => {
    const staticStructures = Object.values(staticStructureTypes),
      { parameterizedStructures } = this.props;
    return [...staticStructures, ...parameterizedStructures];
  };

  /**
   * it calls the action to upload new structure parameters to a layer
   * @param {Number} layerId - the id of the layer
   * @param {Object} parameters - the new layer structure parameters
   * @returns {Promise} the result of the called action
   * @callback
   */
  handleUpdateStructureParameters = (layerId, parameters) => {
    return this.props.uploadStructureParametersAction(layerId, parameters);
  };

  updateStructureCombination = (layerId, newStructure, newParameters) => {
    return this.props.uploadStructureCombinationAction(
      layerId,
      newStructure,
      newParameters
    );
  };

  /**
   * @param {Object} layer
   * @returns {Boolean} whether it is a combination
   */
  isStructureCombination = layer => {
    const structureParameters = layer ? layer.parameterList : null;
    if (structureParameters && Array.isArray(JSON.parse(structureParameters))) {
      return true;
    }
    return false;
  };

  /**
   * it tells what is the layer structure because although we have the structure field
   * internally we call the layer image a structure as well as the inexistence of a structure and
   * image a homogeneous structure.
   * @param {Object} layer - the layer
   * @returns {String} the layer structure
   */
  getTempLayerStructure = layer => {
    if (layer.fileName) {
      return layer.fileName;
    }
    if (layer.structure) {
      if (this.isStructureCombination(layer)) {
        return "combination";
      }
      return layer.structure;
    }
    return staticStructureTypes.HOMOGENEOUS;
  };

  /**
   * it handles the selection of a drilldown option by changing the row temporary values
   * when editing or adding a layer
   * @param {String} selectedStructureType - the selected structureType
   * @param {Object} rowData - the current layer row data when editing
   * @param {Function} onRowDataChange - the layer row change callback
   */
  onStructureTypeSelect = (selectedStructureType, rowData, onRowDataChange) => {
    let tempRowData = { ...rowData, parameterList: null };
    if (selectedStructureType === staticStructureTypes.HOMOGENEOUS) {
      tempRowData.tempStructure = staticStructureTypes.HOMOGENEOUS;
      tempRowData.image = null;
      tempRowData.structure = null;
      onRowDataChange(tempRowData);
    } else if (selectedStructureType === staticStructureTypes.IMAGE) {
      this.layerImageInputRef.current.click();
    } else if (selectedStructureType === staticStructureTypes.COMBINATION) {
      tempRowData.tempStructure = staticStructureTypes.COMBINATION;
      const currentParameterList = JSON.parse(rowData?.parameterList || "[]");
      const singleStructureCurrentlySelected =
        rowData.structure && !Array.isArray(currentParameterList);
      const combinationAlreadySelected =
        rowData.structure && Array.isArray(currentParameterList);
      if (!combinationAlreadySelected) {
        if (singleStructureCurrentlySelected) {
          tempRowData.parameterList = JSON.stringify([currentParameterList]);
        } else {
          const firstParamStructure = this.props.parameterizedStructures[0];
          tempRowData.structure = firstParamStructure;
          tempRowData.parameterList = JSON.stringify([null]);
        }
        onRowDataChange(tempRowData);
      }
    } else {
      tempRowData.tempStructure = selectedStructureType;
      tempRowData.structure = selectedStructureType;
      onRowDataChange(tempRowData);
    }
  };

  countPixels = data => {
    const colorCounts = {};
    let index = 0;
    while (Object.keys(colorCounts).length < 10 && index < data.length) {
      const rgba = `rgba(${data[index]}, ${data[index + 1]}, ${
        data[index + 2]
      }, ${data[index + 3] / 255})`;

      if (rgba in colorCounts) {
        colorCounts[rgba] += 1;
      } else {
        colorCounts[rgba] = 1;
      }
      index += 4;
    }
    return Object.keys(colorCounts).length;
  };

  readFileAsync = file => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  /**
   * it handles an image selection when editing or adding a layer
   * by setting the row temporary values
   * @param {Object} event - the change event
   * @param {Object} rowData - the current layer row data when editing
   * @param {Function} onRowDataChange - the layer row change callback
   */
  handleImageSelection = async (event, rowData, onRowDataChange) => {
    let tempRowData = { ...rowData };
    const { showConfirmDialog } = this.props;
    let { countPixels } = this;
    const file = event.target.files[0];
    const readFileSrc = await this.readFileAsync(file);
    var image = await this.addImageProcess(readFileSrc);
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const colorCountExceeds = countPixels(imageData.data) >= 10;
    if (colorCountExceeds) {
      showConfirmDialog(
        "Image warning",
        "The imported images contains over 10 different materials. Check if your image has no reduced quality edges if this is not intended.",
        () => {
          tempRowData.tempStructure = file.name;
          tempRowData.image = file;
          onRowDataChange(tempRowData);
        },
        () => {},
        true
      );
    } else {
      tempRowData.tempStructure = file.name;
      tempRowData.image = file;
      onRowDataChange(tempRowData);
    }
  };

  /**
   * @param {Object} layerChangesObj - layer fields
   */
  resetStructureFields = layerChangesObj => {
    if (layerChangesObj.structure !== undefined) {
      layerChangesObj.image = null;
      if (layerChangesObj.parameterList === undefined) {
        layerChangesObj.parameterList = null;
      }
    } else if (layerChangesObj.image !== undefined) {
      layerChangesObj.structure = null;
      layerChangesObj.parameterList = null;
    }
  };

  /**
   * it creates a promise that compares the editing value with the original value
   * and calls an action to update the layer only with the modified values
   * @param {Object} newData - the editing value
   * @param {Object} oldData - the original value
   * @returns {Promise} a promise that resolves if the saving was succesful
   */
  saveEditingLayer = (newData, oldData) => {
    if (!newData.thickness) newData.thickness = 0;
    return new Promise((resolve, reject) => {
      const layerPropertiesNames = Object.keys(newData);
      let objWithLayerChanges = {};
      layerPropertiesNames.forEach(property => {
        // temp structure is not a layer property, it is only used to display data,
        // hence it should not try to save it
        if (property !== "tempStructure") {
          if (!isEqual(newData[property], oldData[property])) {
            objWithLayerChanges[property] = newData[property];
          }
        }
      });
      this.resetStructureFields(objWithLayerChanges);
      this.props
        .applyLayerEditingAction(newData.id, objWithLayerChanges)
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  /**
   * it creates a promise that calls the service that adds a layerwith the new layer
   * to be added, if there is a selected layer it makes sure the new layer will be placed
   * between the outer layers, otherwise it takes the first place in the stack.
   * @param {Object} newData - the form value
   * @returns {Promise} a promise that resolves if the saving was succesful
   */
  addNewLayer = newData => {
    if (!newData.thickness) newData.thickness = 0;
    return new Promise((resolve, reject) => {
      const { addLayerAction, layers, simulationOpenId } = this.props;
      const expandedLayersIds = StructureHelper.getExpandedStaircaseLayers(
        layers
      ).map(layer => layer.id);
      let newLayer = { ...newData };
      // temp structure is not a layer property, it is only used to display data,
      // hence it should not try to save it
      delete newLayer.tempStructure;
      newLayer.simulation = simulationOpenId;
      const rowIndex = this.getAddRowPosition();
      const previousStaircaseByProductsIds = expandedLayersIds.filter(
        (id, i) => i < rowIndex && this.layerIsStaircaseByProduct(id)
      );
      newLayer.index = rowIndex + 1 - previousStaircaseByProductsIds.length;
      addLayerAction(newLayer, simulationOpenId)
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  /**
   * it is supposed to be used when there is a click event on the material edit component.
   * it hides and shows the used materials table gradually
   * @callback
   */
  blinkUsedMaterials = () => {
    this.usedMaterialsRef.current.style.opacity = "0";
    this.usedMaterialsRef.current.style.transition =
      "visibility 0s 0.25s, opacity 0.25s linear";
    this.usedMaterialsRef.current.style.visibility = "hidden";

    setTimeout(() => {
      this.usedMaterialsRef.current.style.opacity = "1";
      this.usedMaterialsRef.current.style.transition = "opacity 0.25s linear";
      this.usedMaterialsRef.current.style.visibility = "visible";
    }, 250);
  };

  /**
   * @returns {Boolean} whether the selected layer is the first layer in the stack
   */
  selectedLayerIsFirstInTheStack() {
    const { layers, selectedLayerId } = this.props;
    const layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    if (layerObjs.length) {
      return layerObjs[0].id === selectedLayerId;
    }
    return false;
  }

  /**
   * @returns {Boolean} whether the selected layer is the second layer in the stack
   */
  selectedLayerIsSecondInTheStack() {
    const { layers, selectedLayerId } = this.props;
    const layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    if (layerObjs.length > 1) {
      const secondLayer = layerObjs[1];
      return secondLayer.id === selectedLayerId;
    }
    return false;
  }

  /**
   * @returns {Boolean} whether the selected layer is the second to last layer in the stack
   */
  selectedLayerIsSecondToLastInTheStack() {
    const { layers, selectedLayerId } = this.props;
    const layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    if (layerObjs.length > 1) {
      const secondToLastLayer = layerObjs[layerObjs.length - 2];
      return secondToLastLayer.id === selectedLayerId;
    }
    return false;
  }

  /**
   * @returns {Boolean} whether the selected layer is the last layer in the stack
   */
  selectedLayerIsLastInTheStack() {
    const { layers, selectedLayerId } = this.props,
      layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    return (
      layerObjs.length && layerObjs[layerObjs.length - 1].id === selectedLayerId
    );
  }

  selectedLayerIsParameterStructured = () => {
    const { layers, selectedLayerId } = this.props;
    const layerObjs = StructureHelper.getExpandedStaircaseLayers(layers);
    const selectedLayer = layerObjs.find(layer => layer.id === selectedLayerId);
    if (selectedLayer) {
      return selectedLayer.structure && selectedLayer.parameterList;
    }
    return false;
  };

  /**
   * @returns {Boolean} whether the selected layer is not the last and not the first in the stack
   */
  selectedLayerIsInnerLayer = () => {
    return (
      !this.selectedLayerIsFirstInTheStack() &&
      !this.selectedLayerIsLastInTheStack()
    );
  };

  getFieldEditableOrAddRow = () => {
    return this.selectedLayerIsInnerLayer() || "addRow";
  };

  /**
   * layers should always be included between the outer layers. so we make sure
   * the form is always shown in between them.
   * @returns {Number} the index in the stack where the add layer form will be shown
   */
  getAddRowPosition() {
    const { layers, selectedLayerId } = this.props;
    const expandedLayers = StructureHelper.getExpandedStaircaseLayers(layers);
    const selectedLayer = expandedLayers.find(
      layer => layer.id === selectedLayerId
    );
    if (selectedLayer) {
      const orderedLayerIds = expandedLayers.map(layer => layer.id);
      const layerIndex = orderedLayerIds.indexOf(selectedLayerId);
      if (this.selectedLayerIsFirstInTheStack()) {
        return layerIndex + 1;
      }
      const staircaseByProductNotSelected =
        !isNaN(selectedLayer.id) ||
        (isNaN(selectedLayer.id) && selectedLayer.id.endsWith("#1"));
      if (staircaseByProductNotSelected) {
        return layerIndex;
      }
      let previousLayers = expandedLayers.slice(0, layerIndex);
      previousLayers.reverse();
      for (const layer of previousLayers) {
        const isStaircaseRoot = layer.id.endsWith("#1");
        if (isStaircaseRoot) {
          return orderedLayerIds.indexOf(layer.id);
        }
      }
    }
    return 0;
  }

  /**
   * @returns {Boolean} whether the selected layer is a staircase child
   */
  selectedLayerIsStaircaseByProduct = () => {
    const { selectedLayerId } = this.props;
    return this.layerIsStaircaseByProduct(selectedLayerId);
  };

  /**
   * @returns {Boolean} whether the selected layer belongs to a staircase
   */
  selectedLayerIsStaircase = () => {
    const { selectedLayerId } = this.props;
    return this.layerIsStaircase(selectedLayerId);
  };

  /**
   * @param {Number} - layerId
   * @returns {Boolean} whether the layer belongs to a staircase at all
   */
  layerIsStaircase = layerId => {
    return isNaN(layerId) && layerId.includes("#");
  };

  /**
   * @param {Number} - layerId
   * @returns {Boolean} whether the layer is a staircase child
   */
  layerIsStaircaseByProduct(layerId) {
    return this.layerIsStaircase(layerId) && !layerId.endsWith("#1");
  }

  /**
   * thickness and structure should only be editable if the table is on add mode
   * or the selected layer is not an outer layer.
   * @returns {Boolean} whether thickness and the structure are editable
   */
  getThicknessAndStructureEditable = () => {
    return (
      (this.tableRef && this.tableRef.current.state.showAddRow) ||
      this.selectedLayerIsInnerLayer()
    );
  };

  openDialog = () => {
    this.setState({ paramStackDialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ paramStackDialogOpen: false });
  };

  render() {
    const {
      classes,
      layers,
      selectedLayerId,
      usedMaterials,
      materials,
      selectLayerAction
    } = this.props;
    const { isAddingLayer, saving } = this.state;
    const expandedLayers = StructureHelper.getExpandedStaircaseLayers(layers);
    const selectedLayer = expandedLayers.find(
      layer => layer.id === selectedLayerId
    );
    const structureParameters = selectedLayer
      ? selectedLayer.parameterList
      : null;
    const structure = selectedLayer ? selectedLayer.structure : null;
    return (
      <>
        <Grid name="LayerStack" item className={classes.layerStack}>
          {saving && <LoadingOverlay />}
          <EnhancedMaterialTable
            setTableRef={this.setTableRef}
            style={{ height: "50vh" }}
            slim
            selectedEntityId={selectedLayerId}
            selectEntity={selectLayerAction}
            options={{
              paging: false,
              maxBodyHeight: "40vh",
              search: false,
              sorting: false,
              draggable: false,
              addRowPosition: this.getAddRowPosition(),
              actionsColumnIndex: 6
            }}
            title="Layer Stack"
            columns={[
              {
                title: "index",
                field: "index",
                sorting: true,
                defaultSort: "asc",
                editable: "never"
              },
              {
                title: "name",
                field: "name",
                render: rowData => {
                  return <span>{rowData.name}</span>;
                },
                editComponent: props => {
                  return (
                    <TextField
                      fullWidth
                      value={props.value}
                      test-data="nameEditColumn"
                      name="NewLayerName"
                      onChange={event => props.onChange(event.target.value)}
                      autoFocus
                    />
                  );
                }
              },
              {
                title: "Structure",
                field: "tempStructure",
                render: rowData => {
                  return <span>{rowData.tempStructure}</span>;
                },
                editable: this.getThicknessAndStructureEditable,
                editComponent: props => {
                  const structureTypes = this.getStructureTypes(),
                    drilldownOptions = [
                      structureTypes.map(structure => ({
                        text: structure,
                        isSelected: this.props.value === structure,
                        tooltip:
                          structure === staticStructureTypes.IMAGE
                            ? staticStructureTooltips.IMAGE
                            : ""
                      }))
                    ];
                  return (
                    <div
                      style={{ width: "100%" }}
                      test-data="structureEditColumn"
                    >
                      <DrilldownInput
                        name="NewLayerType"
                        marginTop={0}
                        value={props.value}
                        onSelect={index =>
                          this.onStructureTypeSelect(
                            structureTypes[index],
                            props.rowData,
                            props.onRowDataChange
                          )
                        }
                        options={drilldownOptions}
                        showCount={false}
                      />
                      <input
                        ref={this.layerImageInputRef}
                        name="NewLayerImage"
                        test-data="fileInput"
                        type="file"
                        style={{ display: "none" }}
                        onChange={event =>
                          this.handleImageSelection(
                            event,
                            props.rowData,
                            props.onRowDataChange
                          )
                        }
                      />
                    </div>
                  );
                }
              },
              {
                title: "Thickness",
                field: "thickness",
                render: rowData => {
                  return <span>{rowData.thickness}</span>;
                },
                editable: this.getThicknessAndStructureEditable,
                editComponent: props => {
                  return (
                    <div test-data="thicknessEditColumn">
                      <SweepInput
                        name="NewLayerThickness"
                        disabled={false}
                        value={props.value || ""}
                        onChange={newValue => props.onChange(newValue)}
                        handleSave={() => {
                          const oldData = { ...props.rowData, thickness: null };
                          this.tableRef.current.onEditingApproved(
                            props.rowData.id ? "update" : "add",
                            props.rowData,
                            oldData
                          );
                        }}
                      />
                    </div>
                  );
                }
              },
              {
                title: "Materials",
                field: "materials",
                editComponent: props => {
                  return (
                    <div
                      test-data="materialsEditColumn"
                      onClick={this.blinkUsedMaterials}
                    >
                      <span>{props.value}</span>
                    </div>
                  );
                }
              },
              {
                title: "Scale",
                field: "scale",
                editable: "never"
              }
            ]}
            data={this.getLayersPresentableData()}
            actions={[
              {
                name: "delete",
                icon: "delete",
                isFreeAction: true,
                onClick: event => this.handleDeleteLayer(),
                disabled:
                  !this.selectedLayerIsInnerLayer() ||
                  this.selectedLayerIsStaircaseByProduct()
              },
              {
                name: "copy",
                icon: FileCopy,
                isFreeAction: true,
                onClick: event => this.handleCopyLayer(),
                disabled:
                  !this.selectedLayerIsInnerLayer() ||
                  this.selectedLayerIsStaircase()
              },
              {
                name: "arrow_up",
                icon: ArrowUpward,
                isFreeAction: true,
                onClick: event => this.handleShiftLayerUp(),
                disabled:
                  !this.selectedLayerIsInnerLayer() ||
                  this.selectedLayerIsSecondInTheStack() ||
                  this.selectedLayerIsStaircaseByProduct()
              },
              {
                name: "arrow_down",
                icon: ArrowDownward,
                isFreeAction: true,
                onClick: event => this.handleShiftLayerDown(),
                disabled:
                  !this.selectedLayerIsInnerLayer() ||
                  this.selectedLayerIsSecondToLastInTheStack() ||
                  this.selectedLayerIsStaircaseByProduct()
              },
              {
                name: "parameterized_stack",
                icon: LayersIcon,
                isFreeAction: true,
                onClick: event => this.openDialog(),
                disabled:
                  !this.selectedLayerIsInnerLayer() ||
                  this.selectedLayerIsStaircaseByProduct() ||
                  !this.selectedLayerIsParameterStructured()
              },
              {
                name: "invert_layers",
                icon: Sync,
                tooltip: "Invert Layers",
                isFreeAction: true,
                onClick: event => this.handleInvertLayers()
              }
            ]}
            editable={{
              isEditable: rowData => !isNaN(rowData.id),
              isDeletable: rowData => true,
              onRowAdd: async newData => {
                if (!isAddingLayer) {
                  this.setState({ isAddingLayer: true });
                  await this.addNewLayer(newData);
                  this.setState({ isAddingLayer: false });
                }
              },
              onRowUpdate: (newData, oldData) =>
                this.saveEditingLayer(newData, oldData)
            }}
            localization={{
              body: {
                editRow: {
                  deleteText: "Are you sure you want to delete this layer?"
                }
              },
              header: {
                actions: ""
              }
            }}
          />
        </Grid>
        <Grid name="UsedMaterialList" item style={{ width: "100%" }}>
          <div ref={this.usedMaterialsRef}>
            <LayerUsedMaterials
              layer={selectedLayer}
              layerUsedMaterials={
                this.isStructureCombination(selectedLayer)
                  ? [
                      StructureHelper.getLayerUsedMaterials(
                        selectedLayer,
                        usedMaterials
                      )[0]
                    ]
                  : StructureHelper.getLayerUsedMaterials(
                      selectedLayer,
                      usedMaterials
                    )
              }
              layerUsedMaterialsMaterials={StructureHelper.getMaterialsFromLayerUsedMaterials(
                StructureHelper.getLayerUsedMaterials(
                  selectedLayer,
                  usedMaterials
                ),
                materials
              )}
              getSelectOptions={this.getSelectOptionsForLayerUsedMaterials}
            />
          </div>
        </Grid>
        {structure && structureParameters && (
          <Grid item style={{ width: "100%" }}>
            <LayerParameterizedStructure
              usedMaterialsProps={
                this.isStructureCombination(selectedLayer)
                  ? {
                      getSelectOptions: this
                        .getSelectOptionsForLayerUsedMaterials,
                      layer: selectedLayer,
                      layerUsedMaterials: this.isStructureCombination(
                        selectedLayer
                      )
                        ? StructureHelper.getLayerUsedMaterials(
                            selectedLayer,
                            usedMaterials
                          ).slice(1)
                        : [],
                      layerUsedMaterialsMaterials: StructureHelper.getMaterialsFromLayerUsedMaterials(
                        StructureHelper.getLayerUsedMaterials(
                          selectedLayer,
                          usedMaterials
                        ),
                        materials
                      )
                    }
                  : null
              }
              isCombination={this.isStructureCombination(selectedLayer)}
              combinationOperators={this.combinationOperators}
              structure={structure}
              parameters={structureParameters}
              layerId={selectedLayerId}
              onUpdateParameters={this.handleUpdateStructureParameters}
              onUpdateCombination={this.updateStructureCombination}
              parameterizedStructures={this.props.parameterizedStructures}
            />
          </Grid>
        )}

        <ParamStack
          open={this.state.paramStackDialogOpen}
          updateLayer={this.props.applyLayerEditingAction}
          handleClose={this.closeDialog}
          layer={selectedLayer}
          simulationId={this.props.simulationOpenId}
          buildStaircase={this.props.buildStaircaseAction}
        />
      </>
    );
  }
}

LayerStack.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    simulationOpenId: DirectoryExplorerSelector.getSimulationOpenId(state),
    layers: StructureSelector.getLayers(state),
    selectedLayerId: StructureSelector.getSelectedLayerId(state),
    usedMaterials: StructureSelector.getUsedMaterials(state),
    materials: MaterialSelector.getMaterials(state),
    parameterizedStructures: StructureSelector.getParameterizedStructures(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    applyLayerEditingAction: (layerId, layerChanges) =>
      dispatch(StructureApi.applyLayerEditing(layerId, layerChanges)),
    buildStaircaseAction: (simulationId, layerId, staircaseParams) =>
      dispatch(
        StructureApi.buildStaircase(simulationId, layerId, staircaseParams)
      ),
    addLayerAction: (newLayer, simulationId) =>
      dispatch(StructureApi.addLayer(newLayer, simulationId)),
    deleteLayerAction: (layerId, simulationId) =>
      dispatch(StructureApi.deleteLayer(layerId, simulationId)),
    shiftLayerAction: (layerToShiftId, newIndex, simulationId) =>
      dispatch(StructureApi.shiftLayer(layerToShiftId, newIndex, simulationId)),
    invertLayersAction: simulationId =>
      dispatch(StructureApi.invertLayers(simulationId)),
    selectLayerAction: layerId =>
      dispatch(StructureAction.selectLayer(layerId)),
    copyLayerAction: (layerId, simulationId) =>
      dispatch(StructureApi.copyLayer(layerId, simulationId)),
    uploadStructureParametersAction: (layerId, parameters) =>
      dispatch(StructureApi.updateStructureParameters(layerId, parameters)),
    uploadStructureCombinationAction: (layerId, newStructure, newParameters) =>
      dispatch(
        StructureApi.updateStructureCombination(
          layerId,
          newStructure,
          newParameters
        )
      ),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(LayerStack)));
