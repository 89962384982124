import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import { PureComponent } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import ErrorIcon from "@material-ui/icons/Error";
import { Typography } from "@material-ui/core";
import HelperUtils from "MetaCell/helper/HelperUtils";
import ReportStatus from "BaseApp/ErrorBoundary/components/ReportStatus/ReportStatus";
import { isEqual } from "lodash";

/**
 * A function created to receive a TreeItem component and inject props inside it (Higher order component).
 * it injects a style resolver and a focus handler for items of the ExplorerTree.
 * @author Akira Kotsugai
 * @param {Component} Component - a component
 * @return {Component} the enhanced component
 */
export function withTreeItemHandlers(Component, isTest) {
  const styles = theme => ({
    selected: {
      backgroundColor: "#bdbdbd"
    },
    open: {
      color: "blue",
      border: "1px solid blue"
    },
    openSelected: {
      backgroundColor: "#bdbdbd",
      color: "blue",
      border: "1px solid blue"
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
      color: "red"
    },
    errorIcon: {
      marginRight: theme.spacing(1)
    },
    errorText: {
      fontWeight: "inherit",
      flexGrow: 1
    }
  });

  /**
   * it defines the enhanced layer that is going to wrap the original component and returned instead.
   * it returns a text input if it is the item being edited.
   * @param {Object} props - the props passed from parent components and from redux
   * @return {Component} - the original component with extra props connected to redux.
   */
  class NewComponent extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        itemCorrupted: false,
        reportSent: null
      };
    }

    /**
     * it offers the user the option to report the bug
     * @param {Object} error - the exception object.
     */
    componentDidCatch(error) {
      const { item } = this.props;
      this.setState({ itemCorrupted: true });
      const onReportSuccess = () => this.setState({ reportSent: true });
      const onReportFailure = () => this.setState({ reportSent: false });
      error.message = `Tree item of type ${this.getItemType()} and id ${
        item.id
      } is corrupted.`;
      HelperUtils.offerUserToReportError(
        error,
        "An item in the directory explorer is corrupted.",
        onReportSuccess,
        onReportFailure
      );
    }

    getItem = event => {
      event.persist();
      event.stopPropagation();

      const wrappingElement = event.currentTarget.parentElement;
      const treeItem = {
        id: Number(wrappingElement.id),
        name: wrappingElement.getAttribute("text"),
        type: wrappingElement.type
      };

      return treeItem;
    };

    /**
     * it takes the currently selects item and toggles (add) the given item
     * @param {Object[] | Object} currentlySelectedItems
     * @param {Object} itemToToggle
     * @returns {Object | Object[]} the new selected items
     */
    getNewSelectedItems = (currentlySelectedItems, itemToToggle) => {
      const itemIsSelectedItem = isEqual(currentlySelectedItems, itemToToggle);
      const currentlyMultipleAreSelected = Array.isArray(
        currentlySelectedItems
      );
      const itemHasSelectedItem =
        currentlyMultipleAreSelected &&
        currentlySelectedItems.find(item => isEqual(item, itemToToggle));
      if (!itemIsSelectedItem && !itemHasSelectedItem) {
        return currentlyMultipleAreSelected
          ? [...currentlySelectedItems, itemToToggle]
          : [currentlySelectedItems, itemToToggle];
      }
      return currentlySelectedItems;
    };

    handleClick = event => {
      const { updateSelectedItem, selectedItem } = this.props;
      const treeItem = this.getItem(event);
      const newSelectedItems = event.shiftKey
        ? this.getNewSelectedItems(selectedItem, treeItem)
        : treeItem;
      updateSelectedItem(newSelectedItems);
    };

    /**
     * Even though the tree item component already styles the focused item, we have to force
     * the selected item to have a different background color because the focus style is lost on focus out.
     * It can also make the characters bold
     *
     * @callback
     * @param {Number} itemId - the item id
     * @param {String} itemType - the item type
     * @param {Boolean} [open=false] - if it should be highlighted
     * @return {Object} - the custom style
     */
    getItemContentStyle = (itemId, itemType, open) => {
      const { classes, selectedItem } = this.props;
      if (selectedItem != null) {
        const multipleAreSelected = Array.isArray(selectedItem);
        const multipleSelectionContainsItem =
          multipleAreSelected &&
          selectedItem.find(
            item => item.id === itemId && item.type === itemType
          );
        const singleSelectionIsItem =
          !multipleAreSelected &&
          selectedItem.id == itemId &&
          selectedItem.type == itemType;
        if (singleSelectionIsItem || multipleSelectionContainsItem) {
          if (open) {
            return classes.openSelected;
          }
          return classes.selected;
        } else if (open) {
          return classes.open;
        }
      }
      return "";
    };

    /**
     * it was created to get the item type based on the props passed to the item component
     * because item entities themselves don't have types.
     * @param {} props - the props passed to this higher order component.
     * @return {ExplorerTreeItemType} the item type.
     */
    getItemType = props => {
      if (this.props.children === undefined) return itemType.SIMULATION;
      else if (this.props.grandChildren === undefined) return itemType.PROJECT;
      else return itemType.USER;
    };

    /**
     * it handles changes on the item input when it is being edited and calls an action to redux state
     * @param {Object} event - the change event
     */
    updateEditingValue = event => {
      this.props.updateEditingValue(event.target.value);
    };

    /**
     * it stops event bubbling.
     * it was created to be used with the key down event for the editing input because the
     * tree item component uses this event to select items in the tree based on the key pressed
     * and that causes the input to either lose focus or change the text very laggily.
     * it can also call an action to save the editing.
     * @param {Object} event
     */
    handleKeyDown = event => {
      event.stopPropagation();
      if (event.key === "Enter") {
        const { editingItem } = this.props;
        this.props.save(editingItem.id, editingItem.name);
      }
    };

    render() {
      const { classes, item } = this.props,
        { itemCorrupted, reportSent } = this.state;
      if (itemCorrupted) {
        return (
          <TreeItem
            test-data="corruptedItem"
            label={
              <div className={classes.errorContainer}>
                <ErrorIcon className={classes.errorIcon} />
                <Typography className={classes.errorText}>
                  {`${item.name} (corrupted)`}
                </Typography>
                <ReportStatus sent={reportSent} />
              </div>
            }
          />
        );
      }
      if (
        this.props.editingItem !== null &&
        this.props.item !== undefined &&
        this.props.editingItem.id === this.props.item.id &&
        this.props.editingItem.type === this.getItemType(this.props)
      )
        return (
          <TextField
            name="EditedField"
            value={this.props.editingItem.name}
            autoFocus={true}
            onKeyDown={this.handleKeyDown}
            onChange={this.updateEditingValue}
          />
        );

      return (
        <Component
          {...this.props}
          handleClick={this.handleClick}
          getItemContentStyle={this.getItemContentStyle}
        />
      );
    }
  }
  return isTest ? NewComponent : withStyles(styles)(NewComponent);
}
