import React, { PureComponent } from "react";
import { Button, DialogActions, Tooltip, Typography } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";

export default class Actions extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      code: ""
    };
  }

  setCode = code => {
    this.setState({ code });
  };

  componentDidMount() {
    this.props.bindActionCodeUpdater(this.setCode);
  }

  render = () => {
    const {
      scriptRunning,
      error,
      wavefront,
      handleClose,
      handleConfirm,
      generate,
      generateAsync,
      disableSave,
      isLargerMetaCells
    } = this.props;
    return (
      <DialogActions>
        <Tooltip title={error} open={error !== null} placement="right">
          <div>
            <Button
              test-data="previewBtn"
              variant="contained"
              color="secondary"
              onClick={generate}
              disabled={isLargerMetaCells || scriptRunning}
            >
              Preview
            </Button>
            {isLargerMetaCells && (
              <>
                <br />
                <Typography test-data="asyncExplanation3" variant="caption">
                  Preview not available for metacomponents exceeding{" "}
                  <span style={{ color: "red" }}>4,000,000</span> metacells
                </Typography>
              </>
            )}
          </div>
        </Tooltip>
        {scriptRunning && <Spinner size={25} timeout={30000} />}
        <div style={{ flexGrow: 1 }} />
        <Button test-data="cancelBtn" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          test-data="generateAsyncBtn"
          variant="contained"
          color="primary"
          style={{ marginLeft: "10px" }}
          onClick={generateAsync ? generateAsync : handleConfirm}
          disabled={scriptRunning || (error ? disableSave : false)}
        >
          Generate
        </Button>
        {/* <Button
          test-data="confirmBtn"
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={error || scriptRunning || !wavefront}
        >
          Confirm
        </Button> */}
      </DialogActions>
    );
  };
}
