import React from "react";
import MaterialGraphs from "./MaterialGraphs";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

/**
 * A component to preview the material curve before deciding to import it
 * @author Akira Kotsugai
 */
export const ImportMaterialPreview = ({ data, onCancel, onConfirm }) => {

    return (
        <div
            test-data="importMaterialPreview"
            style={{ width: 1000, height: 500, margin: 'auto', padding: 10 }}
        >
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ flexGrow: 1 }} />
                <div style={{ margin: 10 }}>
                    <Button test-data="cancelButton" onClick={onCancel}>
                        <span>cancel</span>
                    </Button>
                    <Button test-data="confirmButton" onClick={onConfirm}>
                        <span>confirm</span>
                    </Button>
                </div>
            </div>
            <MaterialGraphs data={data} />
        </div >
    );
}

ImportMaterialPreview.propTypes = {
    /**
     * the graphs data
     */
    data: PropTypes.object.isRequired,
    /**
     * callback fn to be called on cancel
     */
    onCancel: PropTypes.func.isRequired,
    /**
     * callback fn to be called on confirm
     */
    onConfirm: PropTypes.func.isRequired,
};

export default withErrorBoundary(ImportMaterialPreview);