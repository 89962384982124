import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import SimulationItem from "../SimulationItem/SimulationItem";
import { withTreeItemHandlers } from "components/DirectoryExplorer/components/ExplorerTree/components/withTreeItemHandlers/withTreeItemHandlers";

/**
 * A component to display an item of type Project {@link ExplorerTreeItemType} in the tree explorer.
 * it render nested simulations if the project has at least one.
 * @author Akira Kotsugai
 * @param {Object} props - a project, its simulations, a style resolver, a focus handler, a function
 * that can open simulations and the id of the open simulation to be passed all the way down to simulations.
 * @return {Component} a tree item component
 */
export const ProjectItem = ({
  item,
  children,
  getItemContentStyle,
  simulationOpener,
  openSimulationId,
  openSimulationRef,
  projectRef,
  grouperType,
  updateSimulation,
  updateSelectedItem,
  updateEditingValue,
  editingItem,
  selectedItem,
  handleClick
}) => {
  return (
    <TreeItem
      ref={projectRef}
      test-data="project"
      name="TreeProjectItem"
      classes={{ content: getItemContentStyle(item.id, itemType.PROJECT) }}
      id={item.id}
      nodeId={"" + item.id}
      text={item.name}
      label={item.name}
      type={itemType.PROJECT}
      onClick={e => handleClick(e)}
    >
      {children.map(simulation => (
        <SimulationItem
          key={simulation.id}
          item={simulation}
          openCallback={simulationOpener}
          openSimulationId={openSimulationId}
          openSimulationRef={openSimulationRef}
          grouperType={grouperType}
          save={updateSimulation}
          updateSelectedItem={updateSelectedItem}
          updateEditingValue={updateEditingValue}
          editingItem={editingItem}
          selectedItem={selectedItem}
        />
      ))}
    </TreeItem>
  );
};

export default withTreeItemHandlers(ProjectItem);
