import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import ElectricFieldDirectionArrow from "../ElectricFieldDirectionArrow/ElectricFieldDirectionArrow";
import IncidentPlaneLine from "../IncidentPlaneLine/IncidentPlaneLine";

/**
 * a functional stateless component to describe what is being shown in the incident light illustration
 * @author Akira Kotsugai
 */
const IllustrationLegend = ({ polarization, incidentPlaneAngle }) => {
  return (
    <div>
      <div style={{ width: 150, height: 50 }}>
        <ElectricFieldDirectionArrow
          polarization={polarization}
          width={50}
          height={50}
          incidentPlaneAngle={incidentPlaneAngle}
        />
        <div
          style={{
            float: "right",
            width: 100,
            height: 50,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography variant="caption">
            direction of the electric field
          </Typography>
        </div>
      </div>
      <div style={{ width: 150, height: 50 }}>
        <IncidentPlaneLine
          width={50}
          height={50}
          incidentPlaneAngle={incidentPlaneAngle}
        />
        <div
          style={{
            float: "right",
            width: 100,
            height: 50,
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography variant="caption">incident plane</Typography>
        </div>
      </div>
    </div>
  );
};

IllustrationLegend.propTypes = {
  /**
   * the incident light polarization
   */
  polarization: PropTypes.string.isRequired,
  /**
   * the angle of the incident plane
   */
  incidentPlaneAngle: PropTypes.number.isRequired
};

export default IllustrationLegend;
