import React, { PureComponent } from "react";
import { connect } from "react-redux";
import HelperUtils from "MetaCell/helper/HelperUtils";
import UserSelector from "BaseApp/selectors/User";

/**
 * component just to show the swept variable parameters in a formatted way
 * using the user's decimal separator
 */
export class ParametersView extends PureComponent {
  render() {
    return (
      <div test-data="parameters">
        {HelperUtils.getDisplayableNumber(
          this.props.parameters,
          this.props.decimalSeparator
        )}
      </div>
    );
  }
}

const mapState = state => ({
  decimalSeparator: UserSelector.getDecimalSeparator(state)
});

export default connect(mapState, null)(ParametersView);
