import { actionType } from "MetaCell/actions/OutputSettings";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

export const defaultState = {
  entities: {
    outputSettings: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

export const testOutputSettings = {
  byId: {
    1: {
      id: 1,
      simulation: 1,
      incident_light: 1,
      diffraction_order_x: 0,
      diffraction_order_y: 0,
      direction: "T",
      quantity: "field_coeff",
      representation: "Phase",
      value: 0,
      weight: 0.5
    },
    2: {
      id: 2,
      simulation: 1,
      incident_light: 1,
      diffraction_order_x: 0,
      diffraction_order_y: 0,
      direction: "T",
      quantity: "power_coeff",
      representation: "Abs",
      value: 1,
      weight: 0.5
    }
  },
  allIds: ["1", "2"],
  loaded: true
};

export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_OUTPUT_SETTINGS: {
      let outputSettings = cloneDeep(defaultState.entities.outputSettings);
      Utils.addOrUpdateEntities(payload, outputSettings);
      return {
        ...state,
        entities: { ...state.entities, outputSettings }
      };
    }

    case actionType.UPSERT_OUTPUT_SETTINGS: {
      let outputSettings = cloneDeep(state.entities.outputSettings);
      Utils.addOrUpdateEntities(payload, outputSettings);
      return {
        ...state,
        entities: { ...state.entities, outputSettings }
      };
    }

    case actionType.DELETE_OUTPUT_SETTINGS: {
      let outputSettings = cloneDeep(state.entities.outputSettings);
      Utils.deleteEntities([payload], outputSettings);
      return {
        ...state,
        entities: { ...state.entities, outputSettings }
      };
    }

    default:
      return state;
  }
}
