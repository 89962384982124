import React, { PureComponent } from "react";
import { libraryPaths } from "Library/Library";
import Grid from "@material-ui/core/Grid";
import FFWFTargets from "./components/FFWFTargets/FFWFTargets";
import Spinner from "components/Spinner/Spinner";

/**
 * a class component to show FFWFTargets
 */
class FFWFTargetsCanvas extends PureComponent {
  /**
   * it calls the data fetcher for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(libraryPaths.FFWFTARGETS);
  }

  render() {
    if (!this.props.ready) {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            padding: 50
          }}
        >
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }
    return (
      <Grid container>
        <Grid container direction="column">
          <FFWFTargets />
        </Grid>
      </Grid>
    );
  }
}

export default FFWFTargetsCanvas;
