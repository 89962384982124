import React, { PureComponent } from "react";
import { FormLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DrilldownInput from "components/DrilldownInput";
import TransferList from "components/GenericTransferList/GenericTransferList";
import IconTooltip from "components/IconTooltip/IconTooltip";
import LockIcon from "@material-ui/icons/Lock";
import FamilyApi from "MetaCell/api/Family";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import { connect } from "react-redux";
import FamilyHelper from "MetaCell/helper/Family";

const styles = theme => ({
  drilldown: {
    float: "left"
  }
});

/**
 * A class component to connect to redux state and serve the form. See {@link MetaCellGlobalParametersForm}
 * @typedef {Component} MetaCellGlobalParameters
 * @author Akira Kotsugai
 */
export class MembersSelection extends PureComponent {
  /**
   * it initializes the selected family state.
   * when the meta component has editingSelectedFM, the selected family is the family associated with them.
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    const { editingSelectedFM, familyMembers } = props,
      selectedFamilyId =
        editingSelectedFM.length > 0
          ? familyMembers.byId[editingSelectedFM[0]].family
          : null;
    this.state = {
      selectedFamilyId
    };
  }

  /**
   * it creates drill down options from the families.
   */
  getDrilldownOptions() {
    return [
      FamilyHelper.sortFamilies(
        Object.values(this.props.families.byId).filter(
          family => !family.is_snapshot
        )
      ).map(family => ({
        text: family.name,
        isSelected: false,
        icon: family.for_pdk ? <LockIcon /> : null
      }))
    ];
  }

  /**
   * it sets the selected family id in the state
   * it is supposed to be passed as an "on change callback" to the drilldown component.
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onFamilySelect = async selectedOptionIndex => {
    const familyIds = FamilyHelper.sortFamilies(
      Object.values(this.props.families.byId).filter(
        family => !family.is_snapshot
      )
    ).map(family => family.id);
    // const selectedFamilyId = this.props.families.allIds.filter(family => !family.is_snapshot)[selectedOptionIndex];

    const selectedFamilyId = familyIds[selectedOptionIndex];
    if (selectedFamilyId) {
      const selectedFamily = this.props.families.byId[selectedFamilyId];
      if (selectedFamily.for_pdk) {
        // fetch pdk_preferences
        const response = await FamilyApi.getFamilyPdkPreferences(
          selectedFamilyId
        );
        const pdk_preferences = response.data;
        const { showConfirmDialog } = this.props;
        const message = `Do you confirm to have read the instructions for the given PDK at ${pdk_preferences.instructions_link}`;
        showConfirmDialog(
          "PDK Instructions",
          message,
          [],
          () => {},
          false,
          () => {
            this.setState({ selectedFamilyId });
          },
          false,
          false,
          ["Confirm", "Deny"]
        );
        //
      } else {
        this.setState({ selectedFamilyId });
      }
    }
  };

  /**
   *
   * @param {Number} familyId - the family id
   * @returns {Object[]} an array of TransferList items created
   * from the meta cells of the given meta cell group
   */
  getFamilyMembersItems(familyId) {
    const familyMembers = Object.values(this.props.familyMembers.byId).filter(
        member => member.family == familyId
      ),
      membersItems = familyMembers.map(member => ({
        id: member.id,
        name: member.alias
      }));
    return membersItems;
  }

  /**
   * @returns {Number[]} - the editing selected FM ids filtered by the selected family
   */
  getFamilySelectedFM() {
    const { editingSelectedFM, familyMembers } = this.props,
      { selectedFamilyId } = this.state;
    return editingSelectedFM.filter(
      fmId => familyMembers.byId[fmId].family == selectedFamilyId
    );
  }

  render() {
    const { families, onSelectFM, classes } = this.props,
      { selectedFamilyId } = this.state;
    return (
      <>
        <FormLabel style={{ fontSize: 12 }}>Meta cell group</FormLabel>
        <div test-data="drilldownWrapper">
          <DrilldownInput
            className={classes.drilldown}
            marginTop={0}
            float={"left"}
            value={
              selectedFamilyId ? families.byId[selectedFamilyId].name : null
            }
            options={this.getDrilldownOptions()}
            onSelect={this.onFamilySelect}
            showCount={false}
          />
          <IconTooltip
            text={
              "Selected meta cell group will be used to build this component"
            }
          />
        </div>
        {selectedFamilyId && (
          <TransferList
            listTitle={"Meta cells"}
            items={this.getFamilyMembersItems(selectedFamilyId)}
            selectedItemsIds={this.getFamilySelectedFM()}
            onChange={onSelectFM}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm,
      inputPlaceholders,
      uploadFile,
      confirmLabels
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm,
          inputPlaceholders,
          uploadFile,
          confirmLabels
        )
      )
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(MembersSelection));
