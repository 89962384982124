/**
 * A class to make value selection from the analysis canvas in redux state more clear.
 */
export default class AnalysisSelector {
  /**
   * it selects analysis jobs from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the analysis jobs
   */
  static getAnalysisJobs = state => state.analysis.entities.analysisJobs;

  /**
   * it gets the selected job from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the selected analysis job
   */
  static getSelectedJobId = state => state.analysis.ui.selectedJobId;

  /**
   * it gets the analysis swept variables from the redux state
   * @param {Object} state - the redux state
   * @return {Object[]} the analysis swept variables
   */
  static getTempSweptVariables = state => state.analysis.ui.tempSweptVariables;

  /**
   * @param {*} state - the redux state
   * @return {Object} the filters for the analysis overview
   */
  static getOverviewFilters = state => state.analysis.ui.overviewFilters;
}
