import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UserSelector from "BaseApp/selectors/User";
import { withErrorBoundary } from "../../../../../BaseApp/ErrorBoundary/ErrorBoundary";
import RequestFabricationForm from "./RequestFabricationForm";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function RequestFabiractionDialog(props) {
  const classes = useStyles();

  /**
   * it can be passed to child components and tells redux to close the dialog
   * @callback
   */
  const handleClose = () => {
    props.onClose();
  };

  return (
    <UnselfishDialog
      fullWidth
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="sm"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            test-data="closeBtn"
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography>Request Fabrication</Typography>
        </Toolbar>
      </AppBar>
      <RequestFabricationForm
        user={props.user}
        handleClose={handleClose}
        design_job_id={props.design_job?.id}
        setSnackbar={props.setSnackbar}
        resetSnackbar={props.resetSnackbar}
      />
    </UnselfishDialog>
  );
}
