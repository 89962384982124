import { actionType } from "BaseApp/actions/User";

/**
 * an object created to fake the user entity for testing purposes
 * @constant
 */
export const testUser = {
  id: 1,
  last_login: "2019-10-31T16:08:59.236523Z",
  email: "test@planopsim.com",
  username: "test",
  is_active: true,
  profile: {
    title: "Mrs",
    firstName: "test",
    lastName: "user",
    phone: "999999999",
    company: "PlanOpSim",
    jobTitle: "scientist",
    country: "Belgium",
    zipCode: "9000",
    city: "Gent",
    streetName: "Maria Hendrikaplein",
    streetNumber: "1000"
  }
};

export const DecimalSeparator = Object.freeze({
  COMMA: ",",
  PERIOD: "."
});

/**
 * @constant
 * @typedef {Object} UserDefaultState
 */
export const defaultState = {
  entities: {
    user: null,
    userSettings: null,
    version: null
  },
  ui: {
    accountDialogOpen: false,
    documentDialogContent: null,
    decimalSeparator: DecimalSeparator.COMMA
  }
};

/**
 * Reducer function to manipulate the state of the user
 * @author Akira Kotsugai
 * @param {Object} [state=UserDefaultState] - the state
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_USER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          user: payload
        }
      };
    }

    case actionType.SET_ACCOUNT_DIALOG_OPEN: {
      return {
        ...state,
        ui: {
          ...state.ui,
          accountDialogOpen: payload
        }
      };
    }

    case actionType.SET_DOCUMENT_DIALOG_OPEN: {
      return {
        ...state,
        ui: {
          ...state.ui,
          documentDialogContent: payload
        }
      };
    }

    case actionType.SET_DECIMAL_SEPARATOR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          decimalSeparator: payload
        }
      };
    }

    case actionType.SET_USER_SETTINGS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          userSettings: payload
        }
      };
    }

    case actionType.SET_VERSION: {
      return {
        ...state,
        entities: {
          ...state.entities,
          version: payload
        }
      };
    }

    default:
      return state;
  }
}
