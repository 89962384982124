import Axios from "axios";
import HelperUtils from "MetaCell/helper/HelperUtils";
import GenericApi from "Api";

export const RETRIEVE_TOKENS = "RETRIEVE_TOKENS";
export const REMOVE_TOKENS = "REMOVE_TOKENS";
export const SETUP_AUTH_INTERCEPTOR = "SETUP_AUTH_INTERCEPTOR";
export const AUTH_INTERCEPTOR_LOADED = "AUTH_INTERCEPTOR_LOADED";
export const SIGN_IN_STARTED = "SIGN_IN_STARTED";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const REGISTER_STARTED = "REGISTER_STARTED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const RESET_SIGN_IN = "RESET_SIGN_IN";
export const RESET_REGISTER = "RESET_REGISTER";

let isRefreshing = false;
let refreshSubscribers = [];

export const retrieveTokens = () => ({
  type: RETRIEVE_TOKENS,
  payload: {
    access: localStorage.getItem("access"),
    refresh: localStorage.getItem("refresh")
  }
});

export const signInSuccess = data => ({
  type: SIGN_IN_SUCCESS,
  payload: data
});

export const signInStarted = () => ({
  type: SIGN_IN_STARTED
});

export const signInFailure = data => ({
  type: SIGN_IN_FAILURE,
  payload: data
});

export const signIn = (username, password) => {
  return dispatch => {
    dispatch(signInStarted());
    Axios.post(`${GenericApi.getBaseUrl()}/token/`, {
      username,
      password
    })
      .then(resp => {
        delete resp.config.data;
        const {
          data: { refresh, access }
        } = resp;
        Axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        localStorage.setItem("access", access);
        localStorage.setItem("refresh", refresh);
        dispatch(retrieveTokens());
        dispatch(signInSuccess(resp));
      })
      .catch(err => {
        dispatch(signInFailure(err.response));
      });
  };
};

export const removeTokens = () => {
  Axios.defaults.headers.common["Authorization"] = "";
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  return {
    type: REMOVE_TOKENS,
    payload: {}
  };
};

export const resetSignIn = () => ({
  type: RESET_SIGN_IN,
  payload: {}
});

export const setupAuthInterceptor = () => {
  return dispatch => {
    Axios.interceptors.request.use(
      config => {
        const access = localStorage.getItem("access");
        if (access) {
          config.headers.Authorization = `Bearer ${access}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    Axios.interceptors.response.use(
      resp => resp,
      error => {
        const {
          config,
          response: { status, data }
        } = error;
        const originalRequest = config;
        if (status === 401) {
          const loginErrors = [
            "User does not exist",
            "Invalid password",
            "User is not active",
            "Attempts exceeded",
            "User license is expired"
          ];
          if (data && loginErrors.includes(data.detail.split(" - ")[0])) {
            return Promise.reject(error);
          } else if (data && data.detail === "User is inactive") {
            dispatch(removeTokens());
          } else if (data && data.detail === "Token is invalid or expired") {
            dispatch(removeTokens());
          } else {
            if (!isRefreshing) {
              isRefreshing = true;
              Axios.post(`${GenericApi.getBaseUrl()}/token/refresh/`, {
                refresh: localStorage.getItem("refresh")
              })
                .then(resp => {
                  const { access } = resp.data;
                  isRefreshing = false;
                  localStorage.setItem("access", access);
                  dispatch(retrieveTokens());
                  refreshSubscribers.map(cb => cb(access));
                  refreshSubscribers = [];
                })
                .catch(() => {
                  isRefreshing = false;
                  refreshSubscribers = [];
                });
            }
            return new Promise(
              resolve => {
                refreshSubscribers.push(token => {
                  originalRequest.headers["Authorization"] = "Bearer " + token;
                  resolve(Axios(originalRequest));
                });
              },
              reject => {
                debugger;
              }
            );
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
    dispatch(authInterceptorLoaded());
  };
};

export const registerSuccess = data => ({
  type: REGISTER_SUCCESS,
  payload: data
});

export const registerStarted = () => ({
  type: REGISTER_STARTED
});

export const registerFailure = data => ({
  type: REGISTER_FAILURE,
  payload: data
});

export const resetRegister = () => ({
  type: RESET_REGISTER,
  payload: {}
});

export const register = formData => {
  return dispatch => {
    dispatch(registerStarted());
    Axios.post(
      `${GenericApi.getBaseUrl()}/user/register`,
      HelperUtils.getFormData(formData)
    )
      .then(() => {
        dispatch(registerSuccess());
      })
      .catch(err => {
        dispatch(registerFailure(err.response.data));
      });
  };
};

export const authInterceptorLoaded = () => ({
  type: AUTH_INTERCEPTOR_LOADED,
  payload: {}
});
