import React, { PureComponent } from "react";
import { FormLabel } from "@material-ui/core";
import IconTooltip from "components/IconTooltip/IconTooltip";
import NumberInput from "components/NumberInput/NumberInput";

/**
 * a component for the user to input order values
 * @author Akira Kotsugai
 */
export default class Order extends PureComponent {
  render() {
    const {
      disabled,
      name,
      required,
      error,
      helperText,
      value,
      onChange,
      onBlur,
      className,
      orderType,
      toolTipText,
      allowNegative
    } = this.props;
    return (
      <>
        <div>
          <FormLabel style={{ fontSize: 12 }} disabled={disabled}>
            {orderType}
          </FormLabel>
          <IconTooltip text={toolTipText} />
        </div>
        <NumberInput
          required={required}
          name={name}
          className={className}
          disabled={disabled}
          error={error}
          helperText={helperText}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          allowNegative={allowNegative}
        />
      </>
    );
  }
}
