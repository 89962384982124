import React, { Component } from "react";
import AuthLayout from "./AuthLayout";
import DocumentTitle from "react-document-title";
import { Button, withStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const styles = {
  content: {
    textAlign: "center"
  },
  text: {
    padding: "20px 0"
  }
};

const AdapterLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

/**
 * A component to inform the user about the manual activation
 * of the account
 */
export class ResetPasswordComplete extends Component {
  render = () => {
    const { classes } = this.props;
    return (
      <DocumentTitle title="Reset Password" test-data="resetPasswordComplete">
        <AuthLayout title="Congratulations!">
          <div className={classes.content}>
            <div className={classes.text}>
              You've successfully changed your password
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                component={AdapterLink}
                to="/login"
              >
                Continue
              </Button>
            </div>
          </div>
        </AuthLayout>
      </DocumentTitle>
    );
  };
}

export default withStyles(styles)(ResetPasswordComplete);
