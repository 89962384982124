import React, { PureComponent } from "react";
import { Typography, Grid, Slider } from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

/**
 * a component to select a specific value for sweep variables
 * @author Akira Kotsugai
 */
export class SweepPoint extends PureComponent {
  setSelectedValue(variableName, rangeValue) {
    let sweptVariableValues = { ...this.props.sweptVariableValues };
    sweptVariableValues[variableName] = rangeValue;
    this.props.setSelectedValue(sweptVariableValues);
  }
  render() {
    const {
      sweptVariables,
      sweptVariableValues,
      onChangeCommitted
    } = this.props;
    return (
      <Grid container>
        {sweptVariables.map(({ name, values }) => {
          const min = values[0];
          const max = values[values.length - 1];
          const currentValue = sweptVariableValues[name];
          return (
            <Grid item xs={12} key={name}>
              <div test-data={"variable"}>
                <Typography variant="subtitle1" component="h4">
                  {`${name} = ${currentValue}`}
                </Typography>
                <Slider
                  defaultValue={currentValue}
                  marks={values.map(value => ({ value }))}
                  min={min}
                  max={max}
                  step={null}
                  onChange={(event, rangeValue) =>
                    this.setSelectedValue(name, rangeValue)
                  }
                  // onChangeCommitted={onChangeCommitted}
                />
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default withErrorBoundary(SweepPoint);
