/**
 * A helper class to help components deal with the directory explorer data.
 * @author Akira Kotsugai
 */
export default class DirectoryExplorerHelper {
  /**
   * it returns the given name if the name is not being used, otherwise it returns an incremented
   * name in the following format: name (2)
   * @param {String} name - the name
   * @param {Object[]} items - the existing items that have names
   * @returns {String} the name or the incremented name
   */
  static handleRepeatedItemName = (name, items) => {
    const regexIncremented = new RegExp(/ \(\d+\)$/),
      nameIsUsed = items.find(item => item.name === name) !== undefined,
      itemsNameIncremented = items.filter(item => {
        return (
          item.name.startsWith(name) &&
          regexIncremented.test(item.name.substring(name.length))
        );
      }),
      namesIncremented = itemsNameIncremented.map(item => item.name),
      nameIsUsedAndIncremented = namesIncremented.length > 0;
    if (!nameIsUsed) {
      return name;
    } else if (nameIsUsedAndIncremented) {
      const sortedIncremented = namesIncremented.sort((name1, name2) => {
          const matchingOne = regexIncremented.exec(name1)[0],
            matchingTwo = regexIncremented.exec(name2)[0],
            nameOneNumberInsideParantheses = parseInt(
              matchingOne.substring(2, matchingOne.length - 1)
            ),
            nameTwoNumberInsideParantheses = parseInt(
              matchingTwo.substring(2, matchingTwo.length - 1)
            );
          return nameOneNumberInsideParantheses > nameTwoNumberInsideParantheses
            ? 1
            : -1;
        }),
        lastIncrementedName = sortedIncremented[sortedIncremented.length - 1],
        matching = regexIncremented.exec(lastIncrementedName)[0],
        lastIncrementedNumber = parseInt(
          matching.substring(2, matching.length - 1)
        );
      return `${name} (${lastIncrementedNumber + 1})`;
    } else {
      return `${name} (2)`;
    }
  };
}
