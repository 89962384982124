import { actionType } from "MetaComponent/actions/SelectedDesignTarget";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testSelectedDesignTargets = {
  byId: {
    1: {
      id: 1,
      design_target: 1,
      meta_component: 1,
      set_point: 2,
      list_of_set_points: [2, 3],
      diffractive_order: {
        X: 1,
        Y: 1
      },
      weight: 1
    },
    2: {
      id: 2,
      design_target: 2,
      meta_component: 1,
      set_point: 1,
      list_of_set_points: [1],
      diffractive_order: {
        X: 1,
        Y: 1
      },
      weight: 1
    },
    3: {
      id: 3,
      design_target: 3,
      meta_component: 1,
      list_of_set_points: [2],
      diffractive_order: {
        X: 1,
        Y: 1
      },
      weight: 1
    },
    4: {
      id: 4,
      design_target: 1,
      meta_component: 1,
      list_of_set_points: [2],
      diffractive_order: {
        X: 1,
        Y: 1
      },
      weight: 1
    },
    5: {
      id: 5,
      design_target: 5,
      meta_component: 1,
      list_of_set_points: [],
      diffractive_order: {
        X: 1,
        Y: 1
      },
      set_point: 1,
      weight: 1
    }
  },
  allIds: ["1", "2", "3", "4", "5"],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} SelectedDesignTargetDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    selectedDesignTargets: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */

/**
 * Reducer function to manipulate the state of selected design targets
 * @param {Object} [state=SelectedDesignTargetDefaultState] - design target entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_SELECTED_DESIGN_TARGETS: {
      let selectedDesignTargets = cloneDeep(
        defaultState.entities.selectedDesignTargets
      );
      Utils.addOrUpdateEntities(payload, selectedDesignTargets);
      return {
        ...state,
        entities: { ...state.entities, selectedDesignTargets }
      };
    }

    case actionType.UPSERT_SELECTED_DESIGN_TARGETS: {
      let selectedDesignTargets = cloneDeep(
        state.entities.selectedDesignTargets
      );
      Utils.addOrUpdateEntities(payload, selectedDesignTargets);
      return {
        ...state,
        entities: { ...state.entities, selectedDesignTargets }
      };
    }

    case actionType.DELETE_SELECTED_DESIGN_TARGET: {
      let selectedDesignTargets = cloneDeep(
        state.entities.selectedDesignTargets
      );
      Utils.deleteEntities(payload, selectedDesignTargets);
      return {
        ...state,
        entities: { ...state.entities, selectedDesignTargets }
      };
    }

    default:
      return state;
  }
}
