import { isEmpty } from "ramda";

/**
 * A helper class for the structure components. unit tests are not necessary as all methods
 * are already tested inside structure components' tests
 * @author Akira Kotsugai
 */
export default class StructureHelper {
  /**
   * it filters the used materials belonging to a specific layer
   * @param {Object} layer - the layer
   * @param {Object} usedMaterialsMap - the map of usedMaterials
   * @return used materials belonging to the given layer
   */
  static getLayerUsedMaterials = (layer, usedMaterialsMap) => {
    if (layer == null || usedMaterialsMap.allIds.length === 0) return [];
    const layerUsedMaterials = layer.usedmaterial_set.map(id => ({
      ...usedMaterialsMap.byId[id]
    }));
    return layerUsedMaterials;
  };

  /**
   * @param {Object} layers - the layers in the redux shape
   * @returns {Object[]} - the layers with expanded staircases
   */
  static getExpandedStaircaseLayers(layers) {
    const allLayers = layers.allIds.map(sortedId => {
      return layers.byId[sortedId];
    });
    let expandedLayers = [];
    for (const layer of allLayers) {
      const isStaircase = Array.isArray(layer);
      if (isStaircase) {
        const staircase = layer
          .sort((a, b) =>
            a.sc_params.staircase_index > b.sc_params.staircase_index ? 1 : -1
          )
          .map(layer => ({
            ...layer,
            id: `${layer.id}#${layer.sc_params.staircase_index}`
          }));
        expandedLayers.push(...staircase);
        continue;
      }
      expandedLayers.push(layer);
    }
    return expandedLayers;
  }

  /**
   * it gets the material ids from used materials belonging to the given layer used materials
   * @param {Object[]} layerUsedMaterials - the given used materials
   * @return the material ids
   */
  static getMaterialsIdsFromLayerUsedMaterials = layerUsedMaterials => {
    const materialsIds = layerUsedMaterials.map(
      layerUsedMaterial => layerUsedMaterial.material
    );
    return materialsIds;
  };

  /**
   * it gets material objects from the given layer's used materials
   * @param {Object[]} layerUsedMaterials - the given used materials
   * @param {Object} materialsMap - the materials' map
   * @return {Object[]} a list of materials
   */
  static getMaterialsFromLayerUsedMaterials = (
    layerUsedMaterials,
    materialsMap
  ) => {
    if (materialsMap.allIds.length === 0) return [];
    const materialsIds = StructureHelper.getMaterialsIdsFromLayerUsedMaterials(
      layerUsedMaterials
    );
    const layerMaterials = materialsIds.map(
      materialId => materialsMap.byId[materialId]
    );
    return layerMaterials;
  };

  /**
   * it get materials objects that are not being used by the given layer
   * @param {Object[]} layerUsedMaterials - the given used materials
   * @param {Object} materialsMap - the materials' map
   * @return {Object[]} a list of materials
   */
  static getMaterialsNotBeingUsedByLayer = (layerUsedMaterials, materials) => {
    const materialsBeingUsedByLayerIds = StructureHelper.getMaterialsIdsFromLayerUsedMaterials(
      layerUsedMaterials
    );
    const materialsNotBeingUsedByLayer = materials.filter(
      material => !materialsBeingUsedByLayerIds.includes(material.id)
    );
    return materialsNotBeingUsedByLayer;
  };

  /**
   * creates a brand new used material from the given material
   * @param {Object} material - the material
   * @return {Object} the used material
   */
  static createUsedMaterialFromMaterial = material => {
    return material
      ? {
        id: null,
        MatId: 0,
        layer: null,
        material: material.id,
        refractiveIndex: "",
        absorptionCoeff: ""
      }
      : null;
  };

  /**
   * It concatenates the name of layer materials to make it displayable in the layer row
   * @param {Object[]} materials - the list of materials
   * @returns {String} A text containing the material names
   */
  static formatLayerMaterialsNames = materials => {
    if (isEmpty(materials)) {
      return "";
    }
    var str = "";
    materials.forEach(material => {
      str += (material?.name || "unknown") + ", ";
    });

    const materialsNames = str.substring(0, str.length - 2);
    const limitedMaterialsNames =
      materialsNames.length > 15
        ? materialsNames.substring(0, 15) + "..."
        : materialsNames;
    return limitedMaterialsNames;
  };

  /**
   *
   */
  static formatLayerFileName = layerFileName => {
    const limitValue = value =>
      value.length > 9 ? value.substring(0, 9) + "..." : value;

    return layerFileName ? limitValue(layerFileName) : "no";
  };
}
