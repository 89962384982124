export const actionType = Object.freeze({
  SET_OUTPUT_SETTINGS: "SET_OUTPUT_SETTINGS",
  DELETE_OUTPUT_SETTINGS: "DELETE_OUTPUT_SETTINGS",
  UPSERT_OUTPUT_SETTINGS: "UPSERT_OUTPUT_SETTINGS"
});

/**
 * A class created to create design target actions to be dispatched to redux. It is already tested with
 * Design Target reducer, so it does not need to be unit tested.
 */
export default class OutputSettingsAction {
  static set = value => ({
    type: actionType.SET_OUTPUT_SETTINGS,
    payload: value
  });

  static delete = designTargetId => ({
    type: actionType.DELETE_OUTPUT_SETTINGS,
    payload: designTargetId
  });

  static upsert = value => ({
    type: actionType.UPSERT_OUTPUT_SETTINGS,
    payload: value
  });
}
