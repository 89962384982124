import React from "react";
import LinePlot from "components/LinePlot/LinePlot";
import ContainerDimensions from "react-container-dimensions";

/**
 * A component to visualize the material graphs
 * @author Akira Kotsugai
 */
const MaterialGraphs = ({ data, id }) => {
  const refractiveIndexCurve = data[0];
  const refractiveIndex = refractiveIndexCurve.data.map(({ y }) => y);
  const absorptionCoeffCurve = data[1];
  const absorptionCoeff = absorptionCoeffCurve.data.map(({ y }) => y);
  const wavelength = absorptionCoeffCurve.data.map(({ x }) => x);

  /**
   * it gets the div dimensions from inside the curve data if its not undefined
   * @param {Object} curveData - the curve data
   * @returns {Object} the dimension percentages
   */
  const getGraphDivDimensions = curveData => {
    if (curveData !== undefined) {
      const { dimensionsPercentages } = curveData;
      return dimensionsPercentages;
    }
    return {};
  };

  const refractiveIndexDivDimensions = getGraphDivDimensions(
    refractiveIndexCurve
  );
  const absorptionCoeffDivDimensions = getGraphDivDimensions(
    absorptionCoeffCurve
  );

  return (
    <div
      test-data="materialGraphs"
      id={`material_plot_${id}`}
      style={{ height: "100%", backgroundColor: "white" }}
    >
      <div
        test-data="materialRefrIdxGraph"
        style={{ height: "100%", width: "50%", float: "left" }}
      >
        <div style={refractiveIndexDivDimensions}>
          <ContainerDimensions>
            <LinePlot
              y={refractiveIndex}
              x={wavelength}
              yLabel={"refractive index"}
              xLabel={"wavelength"}
              xUnit={"μm"}
            />
          </ContainerDimensions>
        </div>
      </div>
      <div
        test-data="materialAbsCoeffGraph"
        style={{ height: "100%", width: "50%", float: "right" }}
      >
        <div style={absorptionCoeffDivDimensions}>
          <ContainerDimensions>
            <LinePlot
              y={absorptionCoeff}
              x={wavelength}
              yLabel={"absorption coefficient"}
              yUnit={"-"}
              xLabel={"wavelength"}
              xUnit={"μm"}
            />
          </ContainerDimensions>
        </div>
      </div>
    </div>
  );
};

export default MaterialGraphs;
