/**
 * A selector class for the structure state
 * @author Akira Kotsugai
 */
export default class StructureSelector {
  /**
   * it selects the list of layers
   * @param {Object} state => the redux state
   */
  static getLayers = state => state.structure.entities.layers;

  /**
   * it selects the list of used materials
   * @param {Object} state => the redux state
   */
  static getUsedMaterials = state => state.structure.entities.usedMaterials;

  /**
   * it selects the selected layer id
   * @param {Object} state => the redux state
   */
  static getSelectedLayerId = state => state.structure.ui.selectedLayerId;

  /**
   * it selects the selected used material id
   * @param {Object} state => the redux state
   */
  static getSelectedUsedMaterialId = state =>
    state.structure.ui.selectedUsedMaterialId;

  /**
   * it selects the parameterized structures in the redux store
   * @param {Object} state => the redux state
   */
  static getParameterizedStructures = state =>
    state.structure.ui.parameterizedStructures;
}
