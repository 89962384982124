import HelperUtils from "MetaCell/helper/HelperUtils";
import { decode } from "@msgpack/msgpack";
import JSZip from "jszip";

/**
 * A helper class to help components deal with FFWFTarget data.
 * @author Akira Kotsugai
 */
export default class FFWFTargetHelper {
  /**
   * it reads the wavefront file and returns either the read file or an error message
   * @param {File} file - the file
   * @returns {Object} the wavefront object
   */
  static readWavefrontFile = file => {
    if (file.name.endsWith(".msgpack")) {
      return file
        .arrayBuffer()
        .then(arrayBuffer => decode(arrayBuffer))
        .then(wavefront => {
          return wavefront;
        });
    } else if (file.name.endsWith(".zip")) {
      return file
        .arrayBuffer()
        .then(arrayBuffer => JSZip.loadAsync(arrayBuffer))
        .then(zip => Object.values(zip.files)[0])
        .then(file => file.async("string"))
        .then(json => JSON.parse(json));
    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (function(theFile) {
        return function(e) {
          try {
            const data = e.target.result;
            const jsonObj = JSON.parse(data);
            resolve(jsonObj);
          } catch (exception) {
            console.log(exception);
            resolve("This is not a valid wavefront file.");
          }
        };
      })(file);
      reader.readAsText(file);
    });
  };

  /**
   * because the wavefront contains 2 matrices, we must select which one to show
   * based on the selected type.
   * @param {Number[][][]} - the wavefront data containing 2 matrices
   * @returns {Number[][]} one of the matrices read from bottom to top
   */
  static getWavefrontZData = (wavefrontData, wavefrontType) => {
    if (wavefrontData) {
      const selectedZData =
        wavefrontData[wavefrontType === "Amplitude" ? 0 : 1];
      return [...selectedZData];
    }
    return null;
  };

  /**
   * @param {Number[][]} zData - the z matrix
   * @param {Number} wfWidth - the wavefront width
   * @param {Number} centerValue - the value that will be in the center
   * @returns {Number[]} the wf width values considering that 0 is in the center if there is no center value
   */
  static getWavefrontXData = (zData, wfWidth, centerValue) => {
    let matrixMaxWidth = wfWidth / 2,
      matrixMinWidth = (wfWidth * -1) / 2;
    if (centerValue) {
      matrixMaxWidth += centerValue;
      matrixMinWidth += centerValue;
    }
    if (zData) {
      const matrixNrOfHorizontalElements = zData[0].length;
      if (matrixNrOfHorizontalElements === 1) {
        return [matrixMinWidth, matrixMaxWidth];
      }
      return HelperUtils.getRange(
        matrixMaxWidth,
        matrixMinWidth,
        matrixNrOfHorizontalElements
      );
    }
    return [matrixMinWidth, centerValue ? centerValue : 0, matrixMaxWidth];
  };

  /**
   * @param {Number[][]} zData - the z matrix
   * @param {Number} wfHeight - the wavefront height
   * @param {Number} centerValue - the value that will be in the center
   * @returns {Number[]} the wf height values considering that 0 is in the center
   */
  static getWavefrontYData = (zData, wfHeight, centerValue) => {
    let matrixMaxHeight = wfHeight / 2,
      matrixMinHeight = (wfHeight * -1) / 2;
    if (centerValue) {
      matrixMaxHeight += centerValue;
      matrixMinHeight += centerValue;
    }
    if (zData) {
      const matrixNrOfVerticalElements = zData.length;
      if (matrixNrOfVerticalElements === 1) {
        return [matrixMinHeight, matrixMaxHeight];
      }
      return HelperUtils.getRange(
        matrixMaxHeight,
        matrixMinHeight,
        matrixNrOfVerticalElements
      );
    }
    return [matrixMinHeight, centerValue ? centerValue : 0, matrixMaxHeight];
  };
}
