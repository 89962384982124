import React, { PureComponent } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import GenericJobOverview from "./GenericJobOverview";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import GenericApi from "Api";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";

export class NFWavefrontJobOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      data: null
    };
    this.handleFetchJobsComplete = this.handleFetchJobsComplete.bind(this);
    this.handleFetchJobsError = this.handleFetchJobsError.bind(this);
  }

  /**
   * when the component mounts it only gets the nf wavefront jobs if the design targets are loaded
   * else it fetches the design targets
   */
  componentDidMount() {
    const { fetchDesignTargetsAction, designTargets } = this.props;
    if (designTargets.loaded) {
      this.fetchJobs();
    } else {
      fetchDesignTargetsAction();
    }
  }

  /**
   * it fetches the nf wavefront jobs when the design targets are loaded
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.designTargets.loaded && this.props.designTargets.loaded) {
      this.fetchJobs();
    }
  }

  /**
   * it makes requests to get the nf wavefront jobs
   */
  async fetchJobs() {
    try {
      const response = await Axios.get(
        `${GenericApi.getBaseUrl()}/nfwavefrontjobs/`
      );
      if (response.status === 200) {
        this.handleFetchJobsComplete(response);
      } else {
        this.handleFetchJobsError();
      }
    } catch {
      this.handleFetchJobsError();
    }
  }

  /**
   * it sets the state that is used for the table data
   * @param {Object[]} designTargets
   * @param {String[]} nfwavefrontJobs
   */
  handleFetchJobsComplete({ data }) {
    if (data && data.length > 0) {
      const tableData = data.map(job => {
        return {
          itemId: job.id,
          name: job.name,
          status: job.status,
          errors: job.errors,
          progress: job.status === "DONE" ? 100 : 0
        };
      });
      this.setState({
        data: tableData
      });
    } else {
      this.setState({ data: [] });
    }
  }

  handleFetchJobsError() {
    this.setState({ error: true });
  }

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} the name of the project to which the component belongs
   */
  getMCGroupName = metaComponentId => {
    const { mcGroups, metaComponents } = this.props;
    let mcGroupName = "";
    const metaComponent = metaComponents.byId[metaComponentId];
    if (metaComponent) {
      const mcGroup = mcGroups.byId[metaComponent.mcGroup];
      if (mcGroup) {
        mcGroupName = mcGroup.name;
      }
    }
    return mcGroupName;
  };

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} - the name of the meta component
   */
  getMetaComponentName = metaComponentId => {
    const { metaComponents } = this.props;
    const metaComponent = metaComponents.byId[metaComponentId];
    return metaComponent ? metaComponent.name : "";
  };

  /**
   * @param {Number} designTargetId - the design target id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  stopNFWavefrontJob = designTargetId => {
    return DesignTargetApi.stopNFWFImport(designTargetId).then(() =>
      Promise.resolve(true)
    );
  };

  /**
   * @param {Number} jobId - the design target id
   * @returns {Object} errors and warnings extracted from the nf wavefront job
   */
  getNFWavefrontJobErrors = async designTargetId => {
    const job = this.state.data.find(job => job.itemId === designTargetId);
    const { errors } = job;
    return { errors };
  };

  /**
   * @callback
   * @param {Object} newData
   */
  updateData = newData => {
    this.setState({ data: newData });
  };

  render = () => {
    const { data, error } = this.state;
    return (
      // data && (
      <>
        <GenericJobOverview
          data={data}
          grouperColumns={[]}
          updateData={this.updateData}
          error={error}
          getErrorsAndWarnings={this.getNFWavefrontJobErrors}
          getJobProgress={DesignTargetApi.getNFWFImportStatus}
          stopJob={this.stopNFWavefrontJob}
        />
      </>
      // )
    );
  };
}

const mapStateToProps = state => ({
  designTargets: DesignTargetSelector.getDesignTargets(state)
});

const mapDispatchToProps = dispatch => {
  return {
    fetchDesignTargetsAction: () =>
      dispatch(DesignTargetApi.fetchDesignTargets())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NFWavefrontJobOverview);
