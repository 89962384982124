import { actionType } from "MetaComponent/actions/SetPoint";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * @typedef {Object} SetPointDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    setPoints: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testSetPoints = {
  byId: {
    1: {
      id: 1,
      incident_light: {
        azimut: 2,
        wavelength: 3,
        zenit: 4,
        amplitude: 0,
        polarization: "TM"
      },
      diffractive_order: { X: 4, Y: 5 },
      family: 1,
      unit: "nm"
    },
    2: {
      id: 2,
      incident_light: {},
      diffractive_order: {},
      family: 2
    }
  },
  allIds: ["1", "2"],
  loaded: true
};

/**
 * Reducer function to manipulate the state of set point
 * @param {Object} [state=SetPointDefaultState] - set point entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_SET_POINTS: {
      let setPoints = cloneDeep(defaultState.entities.setPoints);
      Utils.addOrUpdateEntities(payload, setPoints);
      return {
        ...state,
        entities: { ...state.entities, setPoints }
      };
    }

    case actionType.UPSERT_SET_POINTS: {
      let setPoints = cloneDeep(state.entities.setPoints);
      Utils.addOrUpdateEntities(payload, setPoints);
      return {
        ...state,
        entities: { ...state.entities, setPoints }
      };
    }

    case actionType.DELETE_SET_POINT: {
      let setPoints = cloneDeep(state.entities.setPoints);
      Utils.deleteEntities([payload], setPoints);
      return {
        ...state,
        entities: { ...state.entities, setPoints }
      };
    }

    default:
      return state;
  }
}
