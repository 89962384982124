import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Input, Button, withStyles, Typography, Link } from "@material-ui/core";
import * as Yup from "yup";
import AuthLayout from "./AuthLayout";
import DocumentTitle from "react-document-title";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import GenericApi from "Api";

const requiredFieldText = "This field is required";

const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid e-mail address")
    .required(requiredFieldText)
});

const styles = {
  field: {
    marginTop: 5,
    width: "100%"
  },
  validationMessage: {
    height: 25,
    padding: "3px 0"
  },
  bottom: {
    marginTop: 40,
    textAlign: "center"
  },
  bottomText: {
    fontWeight: 500,
    marginTop: 5
  }
};

export class ResetPasswordRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: "",
      submitButtonDisabled: false,
      redirect: false
    };
  }

  onSubmit = (form, { setErrors }) => {
    this.setState({
      submitButtonDisabled: true,
      error: false,
      errorMessage: ""
    });
    this.resetPassword(form, setErrors);
  };

  resetPassword = ({ email }, setErrors) => {
    const protocol = window.location.protocol,
      host = window.location.host;
    Axios.post(`${GenericApi.getBaseUrl()}/user/password_reset`, {
      email,
      location: `${protocol}//${host}/reset-password`
    })
      .then(this.resetPasswordSuccess)
      .catch(({ response }) => {
        if (response.data === "Invalid email") {
          this.setState({ error: false, submitButtonDisabled: false });
          setErrors({ email: "E-mail could not be found" });
        } else {
          this.resetPasswordFailure(response);
        }
      });
  };

  resetPasswordSuccess = () => {
    this.setState({ redirect: true, submitButtonDisabled: false });
  };

  resetPasswordFailure = ({ response: { data } }) => {
    this.setState({
      error: true,
      errorMessage: data.detail || "Something went wrong",
      submitButtonDisabled: false
    });
  };

  render = () => {
    const { classes } = this.props,
      { error, errorMessage, submitButtonDisabled, redirect } = this.state;
    if (redirect) {
      return <Redirect to="/reset-password-request-complete" />;
    }
    return (
      <DocumentTitle title="Reset password" test-data="resetPasswordRequest">
        <AuthLayout title="Reset password">
          <div
            className={classes.validationMessage}
            style={{ textAlign: "center" }}
          >
            {error && errorMessage}
          </div>

          <Formik
            initialValues={{ email: "" }}
            validationSchema={resetPasswordSchema}
            onSubmit={this.onSubmit}
          >
            {({ errors, values, handleChange, handleBlur, touched }) => (
              <Form>
                <Input
                  className={classes.field}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="E-mail *"
                />
                <div className={classes.validationMessage}>
                  {touched.email && errors.email}
                </div>

                <Button
                  className={classes.field}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitButtonDisabled}
                >
                  Submit
                </Button>

                <div className={classes.bottom}>
                  <Typography>Just remembered ?</Typography>
                  <Link component={RouterLink} to="/login">
                    <Typography className={classes.bottomText}>
                      LOGIN
                    </Typography>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </AuthLayout>
      </DocumentTitle>
    );
  };
}

export default withStyles(styles)(ResetPasswordRequest);
