import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import UserItem from "./components/UserItem/UserItem";

const useStyles = makeStyles({
  root: {
    height: "68%",
    overflow: "auto",
    WebkitUserSelect: "none",
    KhtmlUserSelect: "none",
    MozUserSelect: "none",
    UserSelect: "none"
  }
});

/**
 * @constant
 * @typedef {Object} ExplorerTreeItemType
 * @property {String} USER - user type
 * @property {String} PROJECT - project type
 * @property {String} SIMULATION - simulation type
 * @global
 */
export const itemType = Object.freeze({
  USER: "user",
  PROJECT: "project",
  SIMULATION: "simulation"
});

/**
 * A component to display a Tree of items where the user can organize projects and simulations.
 * the tree is expanded to show the open simulation when the component starts.
 * @author Akira Kotsugai
 * @param {Object} props - it expects an object with a user, all projects, all simulations, a function
 * that can open simulations and the id of the open simulation to be passed all the way down to simulations
 * @return {Component} Tree View Component
 * TODO: use the correct user id when we have the user connected to redux
 */
const ExplorerTree = ({
  user,
  projects,
  simulations,
  simulationOpener,
  openSimulationId,
  openSimulationRef,
  projectsRefs,
  finalItemType,
  grouperType,
  updateSimulation,
  updateProject,
  updateSelectedItem,
  updateEditingValue,
  editingItem,
  selectedItem
}) => {
  const classes = useStyles();
  let [expanded, setExpanded] = React.useState(["user"]);

  /**
   * this react hook works like the component did update method. What we try to reach here is
   * make sure that open simulation is always visible
   */
  React.useEffect(() => {
    const thereIsAnOpenSimulation = openSimulationId !== -1;
    let newExpanded = [...expanded];
    if (thereIsAnOpenSimulation) {
      const openSimulation = simulations.byId[openSimulationId],
        projectNodeId = "" + openSimulation[grouperType],
        simulationNodeId = projectNodeId + "_" + openSimulationId,
        nodes = [projectNodeId, simulationNodeId];
      let openSimulationAlreadyExpanded = true;
      nodes.forEach(node => {
        if (!newExpanded.includes(node)) {
          newExpanded.push(node);
          openSimulationAlreadyExpanded = false;
        }
      });
      if (!openSimulationAlreadyExpanded) {
        setExpanded(newExpanded);
      }
    }
  });

  /**
   * it is passed to the tree to handle item toggle.
   * it makes sure that the user node is always expanded
   * @param {*} event - the toggle event
   * @param {*} nodes - all the nodes that will be expanded
   * @callback
   */
  const handleItemToggle = (event, nodes) => {
    if (!nodes.includes("user")) nodes.push("user");
    setExpanded(nodes);
  };

  return (
    <TreeView
      name="ProjectTree"
      test-data="explorerTree"
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon name="CollapseButton" />}
      defaultExpandIcon={<ChevronRightIcon name="ExpandButton" />}
      expanded={expanded}
      onNodeToggle={handleItemToggle}
    >
      <UserItem
        item={user}
        children={projects}
        grandChildren={simulations}
        finalItemType={finalItemType}
        grouperType={grouperType}
        simulationOpener={simulationOpener}
        openSimulationId={openSimulationId}
        openSimulationRef={openSimulationRef}
        projectsRefs={projectsRefs}
        updateSimulation={updateSimulation}
        updateProject={updateProject}
        updateSelectedItem={updateSelectedItem}
        updateEditingValue={updateEditingValue}
        editingItem={editingItem}
        selectedItem={selectedItem}
      />
    </TreeView>
  );
};

export default ExplorerTree;
