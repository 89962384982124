import React, { PureComponent } from "react";
import debounce from "lodash.debounce";
import SimulationApi from "MetaCell/api/Simulation";
import "jspdf-autotable";
import { cloneDeep } from "lodash";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import { connect } from "react-redux";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import HelperUtils from "MetaCell/helper/HelperUtils";
import { decode } from "@msgpack/msgpack";

export class PlotboxManager extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      plotboxes: null,
      message: null,
      disablePlotBoxUpdate: false
    };
    this.pollers = {};
  }

  componentDidMount() {
    if (this.props.simulationJobId) {
      this.getPlots();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.simulationJobId !== this.props.simulationJobId) {
      this.getPlots();
    }
  }

  componentWillUnmount() {
    this.killPollers();
  }

  killPollers() {
    for (const poller of Object.values(this.pollers)) {
      poller.cancel();
    }
  }

  buildPoller(plotboxId) {
    this.pollers[plotboxId] = debounce(() => {
      this.getPlotPolling(plotboxId);
    }, 1000);
  }

  buildPollers(plotboxIds) {
    for (const id of plotboxIds) {
      this.buildPoller(id);
    }
  }

  handleDisablePlotBoxUpdate = value => {
    this.setState({ disablePlotBoxUpdate: value });
  };

  addPlotbox = async addJobPlotPromise => {
    const { simulationJobId } = this.props;
    await addJobPlotPromise(simulationJobId);
  };

  deletePlotbox = id => {
    const confirmAction = () => {
      const { plotboxes } = this.state;
      this.setState({ plotboxes: plotboxes.filter(val => val.id !== id) });
      return SimulationApi.deleteJobPlot(id);
    };
    const title = "Delete plot";
    const message = "Are you sure you want to delete this plot?";
    this.props.showConfirmDialog(
      title,
      message,
      confirmAction,
      undefined,
      false
    );
  };

  onPlotBoxChange = (plotboxId, data) => {
    let plotboxes = cloneDeep(this.state.plotboxes);
    const index = plotboxes.findIndex(({ id }) => id === plotboxId);
    plotboxes[index].polling = true;
    this.setState({ plotboxes, disablePlotBoxUpdate: true });
    SimulationApi.updateJobPlotbox(plotboxId, data)
      .then(plot => {
        plotboxes[index] = { ...plotboxes[index], ...plot };
        return this.setState({ plotboxes });
      })
      .catch(error => {
        const { response } = error;
        if (response.status === 403) {
          this.setState({ message: null }, () => {
            this.setState({ message: response.data.detail });
          });
        }
      })
      .finally(() => this.pollers[plotboxId]());
  };

  getPlotPolling = plotboxId => {
    const plotboxes = cloneDeep(this.state.plotboxes);
    let index = 0,
      plotbox = null;
    SimulationApi.getJobPlotProgress(plotboxId)
      .then(res => res.data)
      .then(jobStatus => {
        index = plotboxes.findIndex(val => val.id === plotboxId);
        plotbox = plotboxes[index];
        plotbox.status = jobStatus.status;
        plotbox.progress = jobStatus.progress;
        if (
          jobStatus.status === "ERROR" ||
          jobStatus.status === "DONE" ||
          jobStatus.status === "STOPPED"
        ) {
          this.updatePlot(plotbox).then(() => this.setState({ plotboxes }));
        } else if (jobStatus.status == "IDLE") {
          plotbox.polling = false;
          this.setState({ plotboxes });
        } else {
          this.setState({ plotboxes });
        }
        if (jobStatus.status === "RUNNING" || jobStatus.status === "QUEUED") {
          this.pollers[plotboxId]();
        }
      })
      .catch(() => {
        index = plotboxes?.findIndex(val => val.id === plotboxId);
        if (index) plotbox = plotboxes[index];
        if (plotbox) plotbox.polling = false;
        this.setState({ plotboxes, disablePlotBoxUpdate: false });
      });
  };

  // only for 2d and 3d plots
  getUnwrappedOutputData = (plotboxId, wrapped) => {
    const plotboxes = cloneDeep(this.state.plotboxes);
    let index = 0;
    index = plotboxes.findIndex(({ id }) => id === plotboxId);
    SimulationApi.getJobPlot(plotboxId, wrapped).then(async ({ data }) => {
      const decodedPlotData = decode(await data.arrayBuffer());
      plotboxes[index].yData = decodedPlotData.yData;
      plotboxes[index].zData = decodedPlotData.zData;
      plotboxes[index].yAxisRange = null;
      plotboxes[index].xAxisRange = null;
      plotboxes[index].zDataRange = null;
      this.setState({ plotboxes });
    });
  };

  /**
   * it gets the up to date plot from the backend and updates the given plotbox object
   * @param {Object} plotbox
   * @returns {Promise} the result of the update.
   */
  updatePlot(plotbox) {
    return SimulationApi.getJobPlot(plotbox.id).then(async ({ data }) => {
      const decodedPlotData = decode(await data.arrayBuffer());
      plotbox.rangeValue = decodedPlotData.sliderData?.map(slider => 1);
      plotbox.plotType = decodedPlotData.plotType;
      plotbox.lineData = this.getLineData(decodedPlotData);
      plotbox.xData = decodedPlotData.xData;
      plotbox.yData = decodedPlotData.yData;
      plotbox.zData = decodedPlotData.zData;
      plotbox.sliderData = decodedPlotData.sliderData;
      plotbox.polling = false;
      this.setState({ disablePlotBoxUpdate: false });
      plotbox.xName = decodedPlotData.xName;
      plotbox.yName = decodedPlotData.yName;
      plotbox.zName = decodedPlotData.zName;
      plotbox.sliderName = decodedPlotData.sliderName;
      plotbox.xUnit = decodedPlotData.xUnit;
      plotbox.yUnit = decodedPlotData.yUnit;
      // plotbox.zUnit = decodedPlotData.zName && decodedPlotData.zName.includes("Phase")
      //   ? (decodedPlotData.zUnit = "rad")
      //   : decodedPlotData.zUnit;
      plotbox.zUnit = decodedPlotData.zUnit;
      plotbox.sliderUnit = decodedPlotData.sliderUnit;
      plotbox.postProcesLog = decodedPlotData.postProcesLog;
      plotbox.progress = decodedPlotData.progress;
      plotbox.scatterOptions = decodedPlotData.scatterOptions;
      plotbox.scatterPoints = decodedPlotData.scatterPoints;
      return Promise.resolve();
    });
  }

  getPlots = () => {
    const { simulationJobId } = this.props;
    SimulationApi.getJobPlots(simulationJobId)
      .then(async ({ data }) => {
        const decodedPlotData = decode(await data.arrayBuffer());
        const plotboxes = decodedPlotData.map(plotbox => {
          // ydata is array in 3d and array of arrays in 3d
          let yAxisRange =
            plotbox.plotType == "2D"
              ? HelperUtils.getMatrixRange(
                  plotbox.sliderData?.length > 1
                    ? plotbox.yData.flat(plotbox.sliderData.length - 1)
                    : plotbox.yData
                )
              : HelperUtils.getMatrixRange([plotbox.yData]);
          let xAxisRange = HelperUtils.getMatrixRange([plotbox.xData]);
          let zDataRange =
            plotbox.plotType == "3D"
              ? HelperUtils.getZDataRange(
                  plotbox.sliderData?.length > 1
                    ? plotbox.zData.flat(plotbox.sliderData.length - 2)
                    : plotbox.zData
                )
              : null;
          return {
            ...plotbox,
            rangeValue: plotbox.sliderData?.map(slider => 1),
            lineData: this.getLineData(plotbox),
            yAxisRange: HelperUtils.normaliseGraphRange(
              yAxisRange[0],
              yAxisRange[1]
            ),
            xAxisRange: HelperUtils.normaliseGraphRange(
              xAxisRange[0],
              xAxisRange[1]
            ),
            zDataRange
          };
        });
        this.setState({ plotboxes });
        const ids = plotboxes.map(plotbox => plotbox.id);
        this.buildPollers(ids);
        for (const id of ids) {
          this.pollers[id]();
        }
      })
      .catch(() => {});
  };

  getLineData = plotbox => {
    const xData = plotbox.xData,
      yData = plotbox.yData,
      sLength = plotbox.sliderData.length > 0 ? plotbox.sliderData.length : 1;
    let lineData = [],
      yIndex = 0,
      sIndex = 0;
    if (plotbox.plotType === "2D" && xData.length > 0 && yData.length > 0) {
      for (sIndex = 0; sIndex < sLength; sIndex++) {
        const data = [];
        for (yIndex = 0; yIndex < yData[sIndex].length; yIndex++) {
          let y = yData[sIndex][yIndex];
          let x = xData[yIndex];
          if (isNaN(x)) {
            x = null;
          }
          if (isNaN(y)) {
            y = null;
          }
          data.push({ x, y });
        }
        lineData.push([
          {
            data,
            id: sIndex
          }
        ]);
      }
    }
    return lineData;
  };

  onRangeValueChange = (plotboxId, value, rangeIndex) => {
    const plotboxes = cloneDeep(this.state.plotboxes);
    let index = 0;
    index = plotboxes.findIndex(({ id }) => id === plotboxId);
    plotboxes[index].rangeValue[rangeIndex] = value;
    this.setState({ plotboxes });
  };

  updatePlotRange = (plotboxId, xAxisRange, yAxisRange, zDataRange) => {
    const plotboxes = cloneDeep(this.state.plotboxes);
    let index = 0;
    index = plotboxes.findIndex(({ id }) => id === plotboxId);
    plotboxes[index].xAxisRange = xAxisRange;
    plotboxes[index].yAxisRange = yAxisRange;
    if (plotboxes[index].plotType == "3D")
      plotboxes[index].zDataRange = zDataRange;
    this.setState({ plotboxes });
  };

  render = () => {
    const { plotboxes, message, disablePlotBoxUpdate } = this.state;
    const { children } = this.props;
    const childrenWithExtraProp = React.Children.map(children, child =>
      React.cloneElement(child, {
        plotboxes,
        onRangeValueChange: this.onRangeValueChange,
        onGetUnwrappedOutputData: this.getUnwrappedOutputData,
        onPlotBoxChange: this.onPlotBoxChange,
        deletePlotbox: this.deletePlotbox,
        addPlotbox: this.addPlotbox,
        updatePlotRange: this.updatePlotRange,
        disablePlotBoxUpdate,
        handleDisablePlotBoxUpdate: this.handleDisablePlotBoxUpdate
      })
    );
    return (
      <div>
        {childrenWithExtraProp}
        {message && <DirectionSnackbar message={message} />}
      </div>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm
        )
      )
  };
};

export default connect(null, mapDispatchToProps)(PlotboxManager);
