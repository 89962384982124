import React from "react";
import { SketchPicker } from "react-color";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const styles = {
  requiredTextInput: {
    display: "inline",
    float: "left",
    width: "100%"
  },
  requiredSignDiv: {
    display: "inline",
    float: "right",
    width: "10%"
  }
};

/**
 * A component to edit the material color
 * @author Akira Kotsugai
 */
export const MaterialColorEditing = ({
  color,
  onChange,
  getRequiredStyle,
  isFieldInvalid
}) => {
  return (
    <div test-data="materialColorEditing">
      <div style={styles.requiredTextInput}>
        <TextField value={color} />
      </div>
      <div style={styles.requiredSignDiv}>
        <span test-data="requiredSign" style={getRequiredStyle("color")}>
          *
        </span>
      </div>
      {isFieldInvalid("color") && (
        <div test-data="requiredText">
          <span style={{ color: "red" }}>required</span>
        </div>
      )}
      <SketchPicker
        name="color"
        color={color}
        onChangeComplete={color => {
          if (color !== undefined) onChange("color", color.hex);
        }}
      />
    </div>
  );
};

MaterialColorEditing.propTypes = {
  /**
   * the color value
   */
  color: PropTypes.string.isRequired,
  /**
   * callback fn to be called when field changes
   */
  onChange: PropTypes.func.isRequired,
  /**
   * callback fn to be called to get the required field style
   */
  getRequiredStyle: PropTypes.func.isRequired,
  /**
   * callback fn to be called to check if field is invalid
   */
  isFieldInvalid: PropTypes.func.isRequired
};

/**
 * A component to edit the material name
 * @author Akira Kotsugai
 */
export const MaterialSimpleFieldEditing = ({
  field,
  value,
  onChange,
  getRequiredStyle,
  isFieldInvalid,
  errorMessage = "required"
}) => {
  const isRequiredField = getRequiredStyle !== undefined;
  return (
    <div test-data="materialSimpleFieldEditing">
      <div style={isRequiredField ? styles.requiredTextInput : {}}>
        <TextField
          defaultValue={value}
          value={value}
          onChange={event => onChange(field, event.target.value)}
        />
      </div>
      {isRequiredField && (
        <div test-data="required">
          <div style={styles.requiredSignDiv}>
            <span test-data="requiredSign" style={getRequiredStyle(field)}>
              *
            </span>
          </div>
          {isFieldInvalid(field) && (
            <div>
              <span test-data="requiredText" style={{ color: "red" }}>
                {errorMessage}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MaterialSimpleFieldEditing.propTypes = {
  /**
   * the field name
   */
  field: PropTypes.string.isRequired,
  /**
   * the field value
   */
  value: PropTypes.string.isRequired,
  /**
   * callback fn to be called when field changes
   */
  onChange: PropTypes.func.isRequired,
  /**
   * not mandatory callback fn to be called to get the required field style
   */
  getRequiredStyle: PropTypes.func,
  /**
   * not mandatory callback fn to be called to check if field is invalid
   */
  isFieldInvalid: PropTypes.func
};

/**
 * a component to edit the material file
 * @author Akira Kotsugai
 */
export const MaterialFileEditing = ({
  classes,
  fileName,
  onClick,
  getRequiredStyle
}) => {
  return (
    <Button classes={classes} onClick={onClick} variant="outlined">
      <span test-data="materialUploadText">
        {fileName ? (
          fileName
        ) : (
          <>
            upload file<span style={getRequiredStyle("fileName")}> *</span>
          </>
        )}
      </span>
    </Button>
  );
};

MaterialFileEditing.propTypes = {
  /**
   * MUI classes for styling
   */
  classes: PropTypes.object.isRequired,
  /**
   * the field value
   */
  fileName: PropTypes.string.isRequired,
  /**
   * callback fn to be called when the component is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * mandatory callback fn to be called to get the required field style
   */
  getRequiredStyle: PropTypes.func.isRequired
};
