import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: "100%",
    height: "45vh",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

/**
 * @param {Number[]} a
 * @param {Number[]} b
 * @returns {Number[]} all A values that do not exist in B
 */
function divergence(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

/**
 * @param {Number[]} a
 * @param {Number[]} b
 * @returns all A values that exist in B.
 */
function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

/**
 * @param {Number[]} a
 * @param {Number[]} b
 * @returns
 */
function union(a, b) {
  return [...a, ...divergence(b, a)];
}

export default function TransferList({
  items,
  selectedItemsIds,
  onChange,
  listTitle
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  /**
   * it selects or unselects a single checkbox
   * @param {Number} itemId - the item to be selected or unselected
   */
  const handleToggle = itemId => () => {
    const indexOfToggledBox = checked.indexOf(itemId),
      isSelecting = indexOfToggledBox === -1;
    let newChecked = [...checked];

    if (isSelecting) {
      newChecked.push(itemId);
    } else {
      newChecked.splice(indexOfToggledBox, 1);
    }
    setChecked(newChecked);
  };

  /**
   * @param {Number[]} itemsIds
   * @return {Number} the count of how many of the given items are checked.
   */
  const numberOfChecked = itemsIds => intersection(checked, itemsIds).length;

  /**
   * because the "checked" state contains the checked values for all columns
   * this function selects or unselects all items of one column
   * @param {Object[]} items - the column items
   */
  const handleToggleAll = items => () => {
    const itemsIds = items.map(item => item.id),
      allItemsAreChecked = numberOfChecked(itemsIds) === items.length;
    if (allItemsAreChecked) {
      setChecked(divergence(checked, itemsIds));
    } else {
      setChecked(union(checked, itemsIds));
    }
  };

  /**
   * updates the selected items
   */
  const selectCheckedUnselectedItems = leftChecked => {
    const newRight = selectedItemsIds.concat(leftChecked);
    onChange(newRight);
  };

  /**
   * updates the selected items
   */
  const unselectCheckedSelectedItems = rightChecked => {
    const newRight = divergence(selectedItemsIds, rightChecked);
    onChange(newRight);
  };

  const customList = (title, items) => {
    const itemsIds = items.map(item => item.id);
    return (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(itemsIds) === items.length && items.length !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map(item => {
            const { id, name } = item,
              labelId = `transfer-list-all-item-${id}-label`,
              itemIsChecked = checked.indexOf(item.id) !== -1;

            return (
              <ListItem
                key={id}
                role="listitem"
                button
                onClick={handleToggle(id)}
                test-data="listItem"
              >
                <ListItemIcon>
                  <Checkbox
                    checked={itemIsChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };

  const selectedItems = items.filter(item =>
      selectedItemsIds.includes(item.id)
    ),
    unselectedItems = items.filter(item => !selectedItemsIds.includes(item.id)),
    unselectedItemsIds = unselectedItems.map(item => item.id),
    leftChecked = intersection(checked, unselectedItemsIds),
    rightChecked = intersection(checked, selectedItemsIds);
  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        {customList(`${listTitle} Library`, unselectedItems)}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => selectCheckedUnselectedItems(leftChecked)}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => unselectCheckedSelectedItems(rightChecked)}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(`Selected ${listTitle}`, selectedItems)}
      </Grid>
    </Grid>
  );
}
