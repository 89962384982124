import React from "react";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import EditIcon from "@material-ui/icons/Edit";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import GenericTextInput from "components/GenericTextInput/GenericTextInput";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

/**
 * A generic component to compose list components.
 * @typedef {Component} GenericListItem
 * @author Akira Kotsugai
 * @param {Object} props - the item icon, the item label, the item value, the item type and an edit callback
 */
const GenericListItem = ({
  Icon,
  field,
  label,
  value,
  type,
  onEdit,
  onSave,
  error,
  rules,
  setFormErrors
}) => {
  const [editValue, setEditValue] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [fieldError, setFieldError] = React.useState(error);

  React.useEffect(() => setFieldError(error));

  /**
   * it updates the edit value when the user changes the field.
   * @param {Object} event - the change event
   */
  const handleChange = event => {
    setEditValue(event.target.value);
    rules &&
      rules.map(rule => {
        const ruleError = rule(event.target.value);
        let formError = {};
        formError[field] = ruleError;
        setFormErrors(formError);
      });
  };

  /**
   * it activates the editing mode and fill out the edit input with the item value
   */
  const handleEdit = () => {
    onEdit();
    let newEditValue = type === "password" ? "" : value;
    setEditValue(newEditValue);
    setEdit(true);
  };

  /**
   * it calls the saver callback and leave the edit mode.
   */
  const handleSave = () => {
    onSave(field, editValue);
    setEdit(false);
  };

  return edit ? (
    <ListItem>
      <GenericTextInput
        type={type}
        classes={{}}
        key={field}
        InputLabelProps={{
          shrink: true
        }}
        label={label}
        value={editValue}
        handleUpdateField={handleChange}
        error={Boolean(fieldError)}
        helperText={fieldError}
      />
      <ListItemSecondaryAction>
        <IconButton
          test-data="closeEditing"
          edge="end"
          onClick={e => setEdit(false)}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          test-data="saveEditing"
          edge="end"
          disabled={fieldError}
          onClick={handleSave}
        >
          <SaveIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ) : (
    <ListItem>
      {Icon !== undefined ? (
        <ListItemIcon>
          <Icon color="primary" />
        </ListItemIcon>
      ) : null}
      <ListItemText primary={label} secondary={value} />
      {onEdit !== undefined ? (
        <ListItemSecondaryAction>
          <IconButton
            test-data="editBtn"
            edge="end"
            onClick={e => handleEdit()}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

export default GenericListItem;
