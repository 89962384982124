/**
 * A helper class for design canvas
 */
export default class DesignHelper {
  /**
   * it finds the most recent design job in a list of jobs
   * @param {Object[]} designJobs - the design jobs
   * @returns the most recent job
   */
  static getMostRecentJob = designJobs => {
    const mostRecentDate = new Date(
      Math.max.apply(
        null,
        designJobs.map(task => new Date(task.creationDate))
      )
    );
    const mostRecentTask = designJobs.find(
      task => new Date(task.creationDate).getTime() == mostRecentDate.getTime()
    );
    return mostRecentTask;
  };
}
