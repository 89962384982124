import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Heatmap from "../../SimulateCanvas/components/Heatmap";
import { withStyles } from "@material-ui/core";
import uuid from "uuid/v4";
import { sendMessage } from "workerPool";

const styles = {
  holder: {
    position: "absolute",
    width: "100%",
    height: 10,
    zIndex: 200
  },
  clip: {
    overflow: "hidden"
  },
  ellipse: {
    position: "absolute",
    background:
      "radial-gradient(ellipse, transparent, transparent 3.5px, black 3.5px, black 5px, transparent 5.5px)",
    backgroundSize: "18px 10px",
    width: "100%",
    height: 5,
    backgroundPosition: "-5px 0"
  },
  ellipse2: {
    top: 5,
    left: 9,
    backgroundPosition: "-5px -5px"
  },
  layer: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    width: "100%"
  }
};

export class SideViewItem extends React.PureComponent {
  worker = null;

  constructor(props) {
    super(props);
    this.state = {
      z: null,
      id: null
    };
  }

  componentDidMount() {
    setTimeout(() => this.calculate(), 100);
  }

  componentWillUnmount() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.isSelected &&
      this.props.isSelected === prevProps.isSelected &&
      this.state.id === prevState.id
    ) {
      this.calculate();
    } else if (this.props.isSelected && this.props.layer != prevProps.layer) {
      this.calculate();
    }
  }

  calculate = () => {
    if (!this.props.layer.discretized) {
      this.setState({
        z: [
          [
            {
              layerName: this.props.layer.name,
              color: this.props.colors[0]
            }
          ]
        ],
        id: uuid()
      });
    } else {
      this.callWorker();
    }
  };

  callWorker = () => {
    const { layer, colors } = this.props;
    sendMessage(
      {
        action: "getSideviewHeatmapData",
        params: {
          layer,
          colors
        }
      },
      this.handleWorkerMessage
    );
  };

  handleWorkerMessage = data => {
    this.setState({ z: data, id: uuid() });
  };

  getTooltip = rect => (
    <Fragment>
      <div>Layer: {rect.zVal.layerName}</div>
    </Fragment>
  );

  render = () => {
    const {
        width,
        isResized,
        height,
        classes,
        isLast,
        isSelected
      } = this.props,
      { z } = this.state,
      data = z;
    return (
      <div style={{ width: "100%", position: "relative" }}>
        {isResized && (
          <div
            className={`${classes.holder} ${classes.clip}`}
            style={{ top: height / 2 - 5 }}
          >
            <div className={`${classes.ellipse}`}></div>
            <div className={`${classes.ellipse} ${classes.ellipse2}`}></div>
          </div>
        )}
        <div
          test-data="heatmap"
          className={classes.layer}
          style={{
            ...(isLast && {
              borderBottom: "1px solid black"
            })
          }}
        >
          {data && (
            <Heatmap
              z={data}
              getTooltip={this.getTooltip}
              height={height > 0 ? height : 1}
              width={width}
              colorOptions={{
                strategy: "SideView",
                data: { isSelected }
              }}
            />
          )}
        </div>
      </div>
    );
  };
}
SideViewItem.propTypes = {
  layer: PropTypes.any.isRequired,
  width: PropTypes.number.isRequired,
  isResized: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  classes: PropTypes.any.isRequired
};

export default withStyles(styles)(SideViewItem);
