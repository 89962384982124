import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import {
  azimutTooltip,
  zenitTooltip
} from "components/IncidentLightForm/IncidentLightForm";
import { MenuItem } from "@material-ui/core";
import Polarization from "components/Polarization/Polarization";
import NumberInput from "components/NumberInput/NumberInput";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import {
  xTooltip,
  yTooltip,
  unitTooltip,
  unitOptions
} from "../SetPoint/components/SetPointForm/SetPointForm";
import HelperUtils from "MetaCell/helper/HelperUtils";
import * as yup from "yup";

/**
 * A component to display a list of set points
 * @author Akira
 */
export class SetPointTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: {}
    };
  }

  /**
   * it selects the set point that was just created
   */
  componentDidUpdate(oldProps) {
    const aSetPointWasCreated =
      oldProps.setPoints.length !== this.props.setPoints.length;
    if (aSetPointWasCreated) {
      const oldIds = oldProps.setPoints.map(setPoint => setPoint.id);
      const newIds = this.props.setPoints.map(setPoint => setPoint.id);
      const createdId = newIds.find(id => !oldIds.includes(id));
      this.props.selectSetPoint(createdId);
    }
  }

  getData() {
    const { setPoints } = this.props;
    const data = setPoints.map(setPoint => {
      const { id, diffractive_order, incident_light, unit } = setPoint,
        { X, Y } = diffractive_order,
        { zenit, azimut, wavelength, amplitude, polarization } = incident_light;
      return {
        id,
        X,
        Y,
        zenit,
        azimut,
        wavelength,
        amplitude,
        polarization,
        unit
      };
    });
    return data.sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  /**
   * before calling the parent submitter, it prepares the data that matches the endpoint's
   * expected format
   * @param {Object} values - the form values to submit
   */
  handleSubmit = async values => {
    const {
      id,
      X,
      Y,
      zenit,
      azimut,
      wavelength,
      amplitude,
      polarization,
      unit
    } = values;
    const preparedValues = {
      id,
      incident_light: {
        zenit,
        azimut,
        wavelength,
        amplitude,
        polarization
      },
      diffractive_order: {
        X,
        Y
      },
      unit
    };
    return this.props.onSave(preparedValues, true);
  };

  /**
   * @callback
   * @param {Object} newData - a json object containing the form fields and values
   */
  onRowAdd = newData => {
    return new Promise((resolve, reject) => {
      return this.validateFormData(newData)
        .then(() => {
          this.setState({ error: {} });
          return this.handleSubmit(newData).then(() => resolve());
        })
        .catch(err => {
          this.setState({ error: { [err.path]: err.message } });
          reject();
        });
    });
  };

  /**
   * @returns {Object} - a validation schema
   */
  getValidationSchema = () => {
    const requiredFieldText = "This field is required";
    return yup.object().shape({
      polarization: yup.string().required(requiredFieldText),
      unit: yup.string().required(requiredFieldText),
      wavelength: yup.number().required(requiredFieldText),
      amplitude: yup.number().required(requiredFieldText),
      zenit: yup.number().required(requiredFieldText),
      azimut: yup.number().required(requiredFieldText),
      Y: yup.number().required(requiredFieldText),
      X: yup.number().required(requiredFieldText)
    });
  };

  /**
   * @param {Object} data - json data to be validated
   * @returns {Promise} whether or not it passes in the validation
   */
  validateFormData = data => {
    const schema = this.getValidationSchema();
    return schema.validate(data);
  };

  /**
   * it sets the new unit but also scales the wavelength
   * @param {String} oldUnit - the previous unit value
   * @param {String} newUnit - the unit being selected
   * @param {Object} rowData - the current row data
   * @param {Function} onRowDataChange - the function that updates the entire row data
   */

  changeUnit = (oldUnit, newUnit, rowData, onRowDataChange) => {
    let tempData = { ...rowData, unit: newUnit };
    const { wavelength } = rowData;
    const wavelengthIsNumber = !isNaN(wavelength);
    if (oldUnit && wavelengthIsNumber) {
      const multiplier = HelperUtils.scaleUnit(oldUnit, newUnit);
      const newWavelength = wavelength * multiplier;
      tempData["wavelength"] = newWavelength;
    }
    onRowDataChange(tempData);
  };

  render() {
    const { selectSetPoint, selectedSetPointId } = this.props;
    return (
      <EnhancedMaterialTable
        selectedShouldBeVisible
        disabled={this.props.disabled}
        data={this.getData()}
        slim
        selectedEntityId={selectedSetPointId}
        selectEntity={selectSetPoint}
        options={{
          maxBodyHeight: 130,
          paging: false,
          search: false,
          sorting: false,
          draggable: false,
          addRowPosition: "first",
          actionsColumnIndex: 8
        }}
        editable={{ onRowAdd: newData => this.onRowAdd(newData) }}
        title={`Selected Set Point`}
        columns={[
          {
            title: "Accuracy X",
            field: "X",
            tooltip: xTooltip,
            editComponent: props => {
              return (
                <NumberInput
                  autoFocus
                  value={props.value || ""}
                  onChange={event => props.onChange(event.target.value)}
                  error={this.state.error["X"]}
                  helperText={this.state.error["X"]}
                />
              );
            }
          },
          {
            title: "Accuracy Y",
            field: "Y",
            tooltip: yTooltip,
            editComponent: props => {
              return (
                <NumberInput
                  value={props.value || ""}
                  onChange={event => props.onChange(event.target.value)}
                  error={this.state.error["Y"]}
                  helperText={this.state.error["Y"]}
                />
              );
            }
          },
          {
            title: "Azimuth",
            field: "azimut",
            tooltip: azimutTooltip,
            editComponent: props => {
              return (
                <NumberInput
                  value={props.value || ""}
                  onChange={event => props.onChange(event.target.value)}
                  error={this.state.error["azimut"]}
                  helperText={this.state.error["azimut"]}
                />
              );
            }
          },
          {
            title: "Zenith",
            field: "zenit",
            tooltip: zenitTooltip,
            editComponent: props => {
              return (
                <NumberInput
                  value={props.value || ""}
                  onChange={event => props.onChange(event.target.value)}
                  error={this.state.error["zenit"]}
                  helperText={this.state.error["zenit"]}
                />
              );
            }
          },
          {
            title: "Amplitude",
            field: "amplitude",
            editComponent: props => {
              return (
                <NumberInput
                  value={props.value || ""}
                  onChange={event => props.onChange(event.target.value)}
                  error={this.state.error["amplitude"]}
                  helperText={this.state.error["amplitude"]}
                />
              );
            }
          },
          {
            title: "Wavelength",
            field: "wavelength",
            editComponent: props => {
              return (
                <NumberInput
                  value={props.value || ""}
                  onChange={event => props.onChange(event.target.value)}
                  error={this.state.error["wavelength"]}
                  helperText={this.state.error["wavelength"]}
                />
              );
            }
          },
          {
            title: "Unit",
            field: "unit",
            tooltip: unitTooltip,
            editComponent: props => {
              const { rowData, onRowDataChange } = props;
              return (
                <UnselfishSelect
                  value={props.value}
                  onChange={event =>
                    this.changeUnit(
                      props.value,
                      event.target.value,
                      rowData,
                      onRowDataChange
                    )
                  }
                  error={this.state.error["unit"]}
                  helperText={this.state.error["unit"]}
                >
                  {unitOptions.map(unit => (
                    <MenuItem name="PolarizationOption" value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </UnselfishSelect>
              );
            }
          },
          {
            title: "Polarization",
            field: "polarization",
            editComponent: props => {
              return (
                <Polarization
                  hideLabel
                  updateFieldCallback={({ polarization }) =>
                    props.onChange(polarization)
                  }
                  polarization={props.value}
                  error={this.state.error["polarization"]}
                />
              );
            }
          }
        ]}
        localization={{
          header: {
            actions: ""
          }
        }}
      />
    );
  }
}

SetPointTable.propTypes = {
  /**
   * an array of set point objects to be listed
   */
  setPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * the id of the selected set point
   */
  selectedSetPointId: PropTypes.number.isRequired,
  /**
   * a function that selects a set point
   */
  selectSetPoint: PropTypes.func.isRequired
};

export default SetPointTable;
