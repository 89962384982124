/**
 * A selector class for the script2D state
 */
export default class Script2DSelector {
  /**
   * it selects the script2Ds from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the script2Ds
   */
  static getScript2Ds = state => state.script2D.entities.script2Ds;
}
