import Axios from "axios";
import Script2DAction from "MetaComponent/actions/Script2D";
import GenericApi from "Api";

export default class Script2DApi {
  /**
   * it generates the url to the 2dscript api
   * @param {Number} id - the 2dscript id if there is one
   * @return {String} - the generated 2dscript endpoint url
   */
  static script2DsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/2dscripts/${id}`;

  /**
   * it can fetch all the existing 2dscripts in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchScript2Ds = (id = "") => {
    return dispatch => {
      const url = this.script2DsUrl(id);
      return Axios.get(url).then(res => {
        const script2Ds = res.data;
        dispatch(Script2DAction.set(script2Ds));
        return script2Ds;
      });
    };
  };

  /**
   * it can fetch all the existing 2dscripts in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static updateScript2D = (id, code) => {
    return dispatch => {
      const url = this.script2DsUrl(id);
      return Axios.patch(url, { script: code }).then(res => {
        const script2D = res.data;
        dispatch(Script2DAction.upsert([script2D]));
        return script2D;
      });
    };
  };
}
