import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Typography,
  Tooltip
} from "@material-ui/core";
import DrilldownInput from "components/DrilldownInput";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import HelpIcon from "@material-ui/icons/Help";

const paramStyle = {
    marginTop: "7px !important",
    marginBottom: "20px !important"
  },
  sweepParamStyle = {
    ...paramStyle,
    width: "80%"
  },
  outputParamStyle = {
    ...paramStyle,
    width: "40%"
  },
  useStyles = makeStyles(() => ({
    sweepParamStyle,
    outputParamStyle,
    group: {
      padding: 10,
      textAlign: "left"
    },
    helpIcon: {
      fontSize: 16,
      marginLeft: 3,
      color: "#3f51b5"
    },
    helpMessage: {
      maxWidth: 500,
      whiteSpace: "pre-line"
    },
    label: {
      display: "flex",
      alignItems: "center",
      textAlign: "left"
    }
  }));

/**
 * A component to group drilldowns
 * @author Akira Kotsugai
 */
export const PlotboxDrilldownGroup = ({
  title,
  drilldowns,
  onDrilldownClose,
  disabled
}) => {
  const classes = useStyles(),
    sweepTooltip =
      "assign this swept variable to be either on one of the plot axis, the slider bar or at a fixed value.";

  return (
    <Paper className={classes.group} elevation={1}>
      <Typography variant="subtitle1">{title}</Typography>
      {drilldowns.map((drilldown, i) => {
        return (
          <FormControl test-data="drillDownForms" disabled={disabled} key={i}>
            <div className={classes.label}>
              <FormLabel style={{ fontSize: 11 }}>{drilldown.label}</FormLabel>
              <Tooltip
                title={drilldown.isSweep ? sweepTooltip : drilldown.description}
                placement="top"
                classes={{ tooltip: classes.helpMessage }}
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            </div>
            <DrilldownInput
              marginTop={0}
              value={drilldown.value}
              onClose={(value, data) => {
                onDrilldownClose(value, data, i, drilldown.isSweep);
              }}
              options={drilldown.data}
              showCount={false}
              disabled={drilldown.disabled || disabled}
            />
            {drilldown.error && (
              <FormHelperText error={true}>{drilldown.error}</FormHelperText>
            )}
          </FormControl>
        );
      })}
    </Paper>
  );
};

PlotboxDrilldownGroup.propTypes = {
  /**
   * the drilldown group name
   */
  title: PropTypes.string.isRequired,
  /**
   * the drilldowns to be shown
   */
  drilldowns: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * function to handle drilldown close
   */
  onDrilldownClose: PropTypes.func.isRequired
};
