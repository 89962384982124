import React, { PureComponent } from "react";
import { libraryPaths } from 'Library/Library';
import Grid from '@material-ui/core/Grid';
import Materials from './components/Materials/Materials';

/**
 * a class component to show materials
 * @author Akira Kotsugai
 */
class MaterialsCanvas extends PureComponent {

    /**
     * it calls the data fetcher for this component as soon as the component mounts
     */
    componentDidMount() {
        this.props.fetchData(libraryPaths.MATERIALS);
    }

    render() {
        return (
            <Grid container>
                <Grid container direction="column" xs={12}>
                    <Materials />
                </Grid>
            </Grid>
        );
    }
}

export default MaterialsCanvas;