import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import JsonDialog from "components/JsonDialog/JsonDialog";
import { JobOverviewTable } from "./JobOverviewTable";
import Spinner from "components/Spinner/Spinner";
import debounce from "lodash.debounce";
import { cloneDeep } from "lodash";
import { connect } from "react-redux";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import { isEqual } from "lodash";

const styles = {
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

export class GenericJobOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReportDialog: false,
      json: null,
      restoringJobId: null,
      groupColumnsFetched: false,
      grouperColumns: []
    };
  }

  /**
   * a component update should never be prevented, except when only the filters changed
   * because the filters are only important when the component mounts, not when it updates.
   * @param {*} nextProps
   */
  shouldComponentUpdate(nextProps) {
    const oldPropsWithoutFilters = { ...this.props, filters: undefined };
    const newPropsWithoutFilters = { ...nextProps, filters: undefined };
    const propsExceptFiltersChanged = !isEqual(
      oldPropsWithoutFilters,
      newPropsWithoutFilters
    );
    const filtersChanged = !isEqual(this.props.filters, nextProps.filters);
    const onlyFiltersChanged = !propsExceptFiltersChanged && filtersChanged;
    if (onlyFiltersChanged) {
      return false;
    }
    return true;
  }

  showErrorsAndWarnings = async (e, items) => {
    const { itemId } = items[0];
    const errorsAndWarnings = await this.props.getErrorsAndWarnings(itemId);
    this.setState({ showReportDialog: true, json: errorsAndWarnings });
  };

  showConfiguration = async (e, items) => {
    const { itemId } = items[0];
    const configuration = await this.props.getConfiguration(itemId);
    this.setState({ showReportDialog: true, json: configuration });
  };

  hideReportDialog = () => {
    this.setState({ showReportDialog: false, json: null });
  };

  componentDidMount() {
    // this.buildPollers();
    // this.poll();
  }

  componentWillUnmount() {
    // this.killPollers();
  }

  poll() {
    for (const item of this.props.data) {
      this.getJobProgress(item.itemId);
    }
  }

  killPollers() {
    for (const poller of Object.values(this.pollers)) {
      poller.cancel();
    }
  }

  buildPollers() {
    let pollers = {};

    for (const item of this.props.data) {
      pollers[item.itemId] = debounce(() => {
        this.getJobProgress(item.itemId);
      }, 5000);
    }

    this.pollers = pollers;
  }

  async getJobProgress(jobId) {
    try {
      const response = await this.props.getJobProgress(jobId);
      const { data } = response;
      if (
        data.status === "RUNNING" ||
        data.status === "QUEUED" ||
        data.status === "IDLE"
      ) {
        this.pollers[jobId]();
      }
      let updatedData = cloneDeep(this.props.data);
      const jobIndex = updatedData.findIndex(job => job.itemId === jobId);
      updatedData[jobIndex] = {
        ...updatedData[jobIndex],
        ...data,
        progress: data.progress
          ? data.progress
          : data.status === "DONE"
          ? 100
          : 0
      };
      this.props.updateData(updatedData);
    } catch (exception) {
      console.log(exception);
    }
    return;
  }

  stopJob = (event, rowsData) => {
    this.props.stopJob(rowsData[0].itemId);
  };

  /**
   * it deletes the specified job from the table
   * @param {Number[]} jobIds - the idS of the jobS
   */
  deleteJobFromTable = jobIds => {
    let updatedData = cloneDeep(this.props.data);
    for (const jobId of jobIds) {
      const jobIndex = updatedData.findIndex(job => job.itemId === jobId);
      updatedData.splice(jobIndex, 1);
    }
    this.props.updateData(updatedData);
  };

  deleteJob = (event, rowsData) => {
    const jobsIds = rowsData.map(({ itemId }) => itemId);
    this.props.deleteJob(jobsIds, this.deleteJobFromTable);
  };

  /**
   * it switches on the restore mode
   * @param {Object} event - the click event
   * @param {Object} rowData - the job data
   */
  restore = (event, rowsData) => {
    this.setState({ restoringJobId: rowsData[0].itemId });
  };

  /**
   * it switches off the restore mode
   */
  cancelRestore = () => {
    this.setState({ restoringJobId: null });
  };
  /**
   * asks the user to confirm the restoration
   * @param {*} event - the click event
   * @param {*} rowData - the recipient data
   */
  confirmRestore = (event, rowData) => {
    this.props.showConfirmDialog(
      "Restore",
      "are you sure you want to restore the origin of the job into the selected recipient?",
      () => {
        this.props.onRestoreConfirm(
          this.state.restoringJobId,
          rowData.restorationRecipientId
        );
        this.cancelRestore();
      },
      undefined,
      false
    );
  };

  /**
   * @returns {Object[]} returns all the parent grouper columns but deactivates some groupers upwards
   * if the restore mode is on
   */
  getGrouperColumns() {
    if (this.state.restoringJobId) {
      let grouperColumns = [];
      let grouperColumnShouldBeSimpleColumn = false;
      for (const grouperColumn of this.props.grouperColumns) {
        if (grouperColumn.field === this.props.restorationRecipient) {
          grouperColumnShouldBeSimpleColumn = true;
        }
        if (grouperColumnShouldBeSimpleColumn) {
          grouperColumns.push({
            ...grouperColumn,
            defaultGroupOrder: undefined,
            tableData: {
              ...grouperColumn.tableData,
              groupSort: undefined,
              groupOrder: undefined
            }
          });
        } else {
          grouperColumns.push(grouperColumn);
        }
      }
      this.setState({ groupColumnsFetched: true, grouperColumns });
      // return grouperColumns;
    }
    this.setState({
      groupColumnsFetched: true,
      grouperColumns: this.props.grouperColumns
    });
    // return this.props.grouperColumns;
  }

  /**
   * @returns {Object[]} all the data or only filtered fields if the restore mode is on
   */
  getData() {
    if (this.state.restoringJobId) {
      return this.props.recipientData;
    }
    return this.props.data;
  }

  render = () => {
    const { grouperColumns, groupColumnsFetched } = this.state;
    const { data, error, classes } = this.props;
    return (
      <>
        {data && (
          <JobOverviewTable
            data={this.getData()}
            showErrorsAndWarnings={this.showErrorsAndWarnings}
            showConfiguration={
              this.props.getConfiguration && this.showConfiguration
            }
            deleteJob={this.props.deleteJob && this.deleteJob}
            stopJob={this.props.stopJob && this.stopJob}
            grouperColumns={
              groupColumnsFetched ? grouperColumns : this.getGrouperColumns()
            }
            restore={this.props.onRestoreConfirm && this.restore}
            confirmRestore={this.confirmRestore}
            restoreMode={this.state.restoringJobId !== null}
            restorationRecipient={this.props.restorationRecipient}
            cancelRestore={this.cancelRestore}
            handleFilterChange={this.props.handleFilterChange}
            filters={this.props.filters}
          />
        )}
        <JsonDialog
          open={this.state.showReportDialog}
          data={this.state.json}
          onClose={this.hideReportDialog}
        />
        {!error && !data && (
          <div className={classes.progress}>
            <Spinner name="Waiting" size={68} timeout={30000} />
          </div>
        )}
      </>
    );
  };
}

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      )
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(GenericJobOverview));
