import Axios from "axios";
import Action from "MetaComponent/actions/DirectoryExplorer";
import GenericApi from "Api";

/**
 * A class created to perform http operations related to the tree explorer.
 * @author Akira Kotsugai
 */
export default class DirectoryExplorerApi {
  /**
   * it generates the url to the meta component's api to manipulate the given meta component if there is any.
   * if no id is passed then it generates the basic endpoint url.
   *@param id - the simulation's id
   */
  static getMetaComponentsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/metacomponents/${id}`;

  static getMCGroupsAndMetaComponentsUrl = () =>
    `${GenericApi.getBaseUrl()}/mcgroupmetacomponent`;

  /**
   * it generates the url to the project's api to manipulate the given group if there is any.
   * if no id is passed then it generates the basic endpoint url.
   *@param id - the group's id
   */
  static getMCGroupsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/mcgroups/${id}`;

  /**
   * @param {Number} mcGroupId - the id of the group
   * @returns {String} the url to restore a meta component
   */
  static getRestoreMetaComponentUrl = mcGroupId =>
    `${this.getMCGroupsUrl(mcGroupId)}/restore_metacomponent`;

  /**
   * it can fetch the user's meta component groups from the endpoint.
   * @return a function that receives a dispatcher to redux
   */
  static fetch = () => {
    return dispatch => {
      return Axios.get(DirectoryExplorerApi.getMCGroupsUrl())
        .then(mcGroupsRes => mcGroupsRes.data)
        .then(mcGroups => {
          return Axios.get(DirectoryExplorerApi.getMetaComponentsUrl())
            .then(metaComponentsRes => metaComponentsRes.data)
            .then(metaComponents => {
              dispatch(Action.set(mcGroups, metaComponents));
            });
        });
    };
  };

  /**
   * it can add a group to the backend database
   * @return a function that receives a dispatcher to redux
   */
  static addMCGroup = name => {
    return dispatch => {
      const mcGroup = { name };
      return Axios.post(DirectoryExplorerApi.getMCGroupsUrl(), mcGroup)
        .then(res => res.data)
        .then(savedMCGroup => {
          dispatch(Action.upsertMCGroups([savedMCGroup]));
          return savedMCGroup;
        });
    };
  };

  /**
   * it can add a meta component to the backend database
   * @return a function that receives a dispatcher to redux
   */
  static addMetaComponent = (name, mcGroup) => {
    return dispatch => {
      const metaComponent = { name, mcGroup };
      return Axios.post(
        DirectoryExplorerApi.getMetaComponentsUrl(),
        metaComponent
      )
        .then(res => res.data)
        .then(savedMetaComponent =>
          dispatch(Action.upsertMetaComponents([savedMetaComponent]))
        );
    };
  };

  /**
   * it can delete a meta component group from the backend database
   * @return a function that receives a dispatcher to redux
   */
  static deleteMCGroup = id => {
    return dispatch => {
      return Axios.delete(DirectoryExplorerApi.getMCGroupsUrl(id)).then(res =>
        dispatch(Action.deleteMCGroup(id))
      );
    };
  };

  static deleteMCGroupsAndMetaComponents = items => {
    return dispatch => {
      return Axios.delete(this.getMCGroupsAndMetaComponentsUrl(), {
        data: items
      }).then(res => dispatch(Action.deleteMCGroupsAndMetaComponents(items)));
    };
  };

  /**
   * it can delete a meta component from the backend database
   * @return a function that receives a dispatcher to redux
   */
  static deleteMetaComponent = id => {
    return dispatch => {
      return Axios.delete(
        DirectoryExplorerApi.getMetaComponentsUrl(id)
      ).then(res => dispatch(Action.deleteMetaComponent(id)));
    };
  };

  /**
   * it can change the value of a meta component group's property in the backend database
   * @return a function that receives a dispatcher to redux
   */
  static updateMCGroup = (id, properties) => {
    return dispatch => {
      return Axios.patch(DirectoryExplorerApi.getMCGroupsUrl(id), properties)
        .then(res => res.data)
        .then(mcGroup => dispatch(Action.applyEditedMCGroup(mcGroup)));
    };
  };

  /**
   * it can change the value of a meta component's property in the backend database
   * @return a function that receives a dispatcher to redux
   */
  static updateMetaComponent = (id, properties) => {
    return dispatch => {
      return Axios.patch(
        DirectoryExplorerApi.getMetaComponentsUrl(id),
        properties
      )
        .then(res => res.data)
        .then(metaComponent => {
          dispatch(Action.applyEditedMetaComponent(metaComponent));
        });
    };
  };

  /**
   * @param {Number} mcGroupId - the group into which the meta component will be restored
   * @param {Number} designJobId - the design job from which the meta component will be restored
   */
  static restoreMetaComponent = (mcGroupId, designJobId) => {
    return Axios.post(
      DirectoryExplorerApi.getRestoreMetaComponentUrl(mcGroupId),
      { design_job_id: designJobId }
    ).then(res => res.data);
  };
}
