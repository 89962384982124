import React, { useState, useEffect } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import AnalysisAction from "MetaComponent/actions/Analysis";
import AnalysisSelector from "MetaComponent/selectors/Analysis";
import GenericJobOverview from "./GenericJobOverview";
import GenericApi from "Api";

export function AnalysisExportJobOverview({ filters, updateOverviewFilters }) {
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  /**
   * it makes a request to get all the analysis result export jobs
   */
  const fetchJobs = async () =>
    Axios.get(`${GenericApi.getBaseUrl()}/analysisresultsjobs/`)
      .then(response => {
        setData(handleFetchJobsComplete(response.data));
      })
      .catch(() => {
        handleFetchJobsError();
        setData([]);
      });

  useEffect(() => {
    fetchJobs();
  }, []);

  /**
   * it sets the state that is used for the table data
   */
  const handleFetchJobsComplete = data => {
    if (data && data.length > 0) {
      const tableData = data.map(job => {
        return {
          itemId: job.id,
          name: job.analysis_job_name,
          mcGroup: job.mcGroup,
          metaComponent: job.metaComponent,
          startDate: (job.creationDate && job.creationDate.slice(0, 10)) || "-",
          status: job.status,
          errors: job.errors
        };
      });
      return tableData;
    } else {
      return [];
    }
  };

  const handleFetchJobsError = () => {
    setError(true);
  };

  /**
   * @param {Number} jobId - the analysis result export job id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  const stopAnalysisResultJob = async jobId => {
    return Axios.post(
      `${GenericApi.getBaseUrl()}/analysisresultjobs/${jobId}/stop/`,
      { pk: jobId }
    ).then(() => Promise.resolve(true));
  };

  /**
   * it makes a request to the job progress backend endpoint
   * @param {Number} jobId - the analysis result export job id
   * @returns {Promise} - the response
   */
  const getAnalysisResultProgress = async jobId => {
    return Axios.get(
      `${GenericApi.getBaseUrl()}/analysisresultjobs/${jobId}/export_progress`
    );
  };

  /**
   * @param {Number} jobId - the analysis result job id
   * @returns {Object} errors and warnings extracted from the analysis result job
   */
  const getAnalysisResultJobErrors = async jobId => {
    const job = data.find(job => job.itemId === jobId);
    const { errors } = job;
    return { errors };
  };

  /**
   * @callback
   * @param {Object} newData
   */
  const updateData = newData => {
    setData(newData);
  };

  /**
   * sends the new filters to the redux state
   * @param {Object} filter - the new filter
   */
  const handleFilterChange = filter => {
    updateOverviewFilters(filter);
  };

  return (
    <>
      <GenericJobOverview
        data={data}
        updateData={updateData}
        error={error}
        getErrorsAndWarnings={getAnalysisResultJobErrors}
        getJobProgress={getAnalysisResultProgress}
        stopJob={stopAnalysisResultJob}
        handleFilterChange={handleFilterChange}
        filters={filters}
        grouperColumns={[
          { title: "Group", field: "mcGroup", defaultGroupOrder: 0 },
          {
            title: "Meta Component",
            field: "metaComponent",
            defaultGroupOrder: 1
          }
        ]}
      />
    </>
  );
}

const mapStateToProps = state => ({
  filters: AnalysisSelector.getOverviewFilters(state),
  mcGroups: DirectoryExplorerSelector.getMCGroups(state),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateOverviewFilters: filter =>
      dispatch(AnalysisAction.updateOverviewFilters(filter)),
    fetchMCGroupsAndMetaComponentsAction: () =>
      dispatch(DirectoryExplorerApi.fetch())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalysisExportJobOverview);
