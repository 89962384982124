import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DrilldownInput from "components/DrilldownInput";
import { FormLabel } from "@material-ui/core";
import DesignSelector from "MetaComponent/selectors/Design";
import DesignAction from "MetaComponent/actions/Design";
import Helper from "MetaComponent/helper/Design";
import { isEqual } from "lodash";
import AnalysisAction from "MetaComponent/actions/Analysis";

export const styles = {
  drillDown: {
    marginBottom: 20,
    textAlign: "left"
  }
};

/**
 * @TODO TEST THE COMPONENT DID MOUNT
 */
export class DesignJobDropdown extends PureComponent {
  componentDidMount() {
    const { selectedJobId, designJobs, selectJob } = this.props;
    if (!selectedJobId) {
      const job = Helper.getMostRecentJob(designJobs);
      if (job) {
        selectJob(job.id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { designJobs, selectJob } = this.props;
    if (!isEqual(prevProps.designJobs, designJobs)) {
      const mostRecentJob = Helper.getMostRecentJob(designJobs);
      if (mostRecentJob) {
        selectJob(mostRecentJob.id);
      }
    }
  }

  getJobsList = jobs => {
    const { selectedJobId } = this.props;
    return jobs.map(job => {
      const label = this.getJobLabel(job);
      return {
        id: job.id,
        text: label,
        isSelected: job.id === selectedJobId
      };
    });
  };

  getJobLabel = job => {
    let label = "";
    if (job) {
      const dt = new Date(job.start);
      const formattedDate = `${dt
        .getDate()
        .toString()
        .padStart(2, "0")}/${(dt.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${dt
        .getFullYear()
        .toString()
        .padStart(4, "0")} ${dt
        .getHours()
        .toString()
        .padStart(2, "0")}:${dt
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${dt
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      label = `${formattedDate} - ${job.status}`;
    }
    return label;
  };

  /**
   * it sets the selected job id in the state
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onJobSelect = selectedOptionIndex => {
    const { selectJob } = this.props;
    const selectedJobId = parseInt(
      this.props.designJobs[selectedOptionIndex].id,
      10
    );
    selectJob(selectedJobId);
  };

  render() {
    const { designJobs, classes, selectedJobId } = this.props;
    return (
      <div className={classes.drillDown}>
        <FormLabel>Design Job</FormLabel>
        <DrilldownInput
          name="DesignJob"
          marginTop={10}
          onClose={(value, data) => {}}
          options={[this.getJobsList(designJobs)]}
          showCount={false}
          value={
            selectedJobId > 0 &&
            this.getJobLabel(
              designJobs.filter(job => job.id === selectedJobId)[0]
            )
          }
          onSelect={this.onJobSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedJobId: DesignSelector.getSelectedJobId(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectJob: jobId => {
      dispatch(DesignAction.selectJob(jobId));
      dispatch(AnalysisAction.selectJob(null));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DesignJobDropdown));
