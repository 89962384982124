import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaCell/api/DirectoryExplorer";
import GenericJobOverview from "./GenericJobOverview";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import SimulationApi from "MetaCell/api/Simulation";
import SimulationAction from "MetaCell/actions/Simulation";
import SimulationSelector from "MetaCell/selectors/Simulation";
import { decode } from "@msgpack/msgpack";

export class SimulationPlotOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      data: null
    };
    this.handleFetchJobsComplete = this.handleFetchJobsComplete.bind(this);
    this.handleFetchJobsError = this.handleFetchJobsError.bind(this);
  }

  /**
   * when the component mounts it only gets the simulation jobs and plots if the projects are loaded
   * else it fetches the projects and simulations
   */
  componentDidMount() {
    const { fetchProjectsAndSimulationsAction, projects } = this.props;
    if (projects.loaded) {
      this.fetchJobsAndPlots();
    } else {
      fetchProjectsAndSimulationsAction();
    }
  }

  /**
   * it fetches the simulation jobs when the projects are loaded
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.projects.loaded && this.props.projects.loaded) {
      this.fetchJobsAndPlots();
    }
  }

  /**
   * it makes a request to get running plots, if there are running plots
   * it makes a request to get the simulation jobs
   */
  async fetchJobsAndPlots() {
    try {
      const runningPlots = await SimulationApi.getLightWeightRunningPlots();
      if (runningPlots.length > 0) {
        const simulationJobs = await SimulationApi.getAllSimulationJobs();
        this.handleFetchJobsComplete(simulationJobs, runningPlots);
      } else {
        this.setState({ data: [] });
      }
    } catch {
      this.handleFetchJobsError();
    }
    return null;
  }

  /**
   * it sets the state that is used for the table data
   * @param {Object[]} simulationJobs - the simulation job objects
   * @param {Object[]} resultPlots - the result plot objects
   */
  handleFetchJobsComplete(simulationJobs, resultPlots) {
    const data = resultPlots.map(resultPlot => {
      const simulationJob = simulationJobs.find(
        job => job.id === resultPlot.simulationJob
      );
      try {
        const plotData = {
          ...resultPlot,
          itemId: resultPlot.id,
          name:
            resultPlot.plotType === "2D" ? resultPlot.yName : resultPlot.zName,
          project: this.getProjectName(simulationJob.simulation),
          simulation: this.getSimulationName(simulationJob.simulation),
          simulationJob: `${this.getSimulationName(simulationJob.simulation)} ${simulationJob.id
            } - ${(simulationJob.startDate &&
              simulationJob.startDate.slice(0, 10)) ||
            "-"}`,
          startDate:
            (resultPlot.startDate && resultPlot.startDate.slice(0, 10)) || "-"
        }
        return plotData
      }
      catch (e) {
        return {}
      }

    });
    this.setState({ data });
  }

  handleFetchJobsError() {
    this.setState({ error: true });
  }

  /**
   * @param {Number} simulationId - the id of the simulation
   * @returns {String} the name of the project to which the simulation
   */
  getProjectName = simulationId => {
    const { projects, simulations } = this.props;
    let projectName = "";
    const simulation = simulations.byId[simulationId];
    if (simulation) {
      const project = projects.byId[simulation.project];
      if (project) {
        projectName = project.name;
      }
    }
    return projectName;
  };

  /**
   * @param {Number} simulationId - the id of the simulation
   * @returns {String} - the name of the simulation
   */
  getSimulationName = simulationId => {
    const { simulations } = this.props;
    const simulation = simulations.byId[simulationId];
    return simulation ? simulation.name : "";
  };

  /**
   * @param {Number} plotId - the plot id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  stopPlot = plotId => {
    return SimulationApi.stopPlot(plotId).then(() => Promise.resolve(true));
  };

  /**
   * @param {Number} plotId - the plot id
   * @returns {Object} errors extracted from the plot
   */
  getPlotErrors = async plotId => {
    const plot = await SimulationApi.getJobPlot(plotId).then(async res => {
      const decodedPlotData = decode(await res.data.arrayBuffer())
      return decodedPlotData
    });
    return plot.postProcesLog;
  };

  /**
   * it deletes a plot
   * @param {Number[]} plotIds - the plot ids
   * @callback
   */
  deletePlot = (plotIds, onConfirmCallback) => {
    return this.props.showConfirmDialog(
      "Delete plot",
      "Are you sure you want to delete the selected plots?",
      () => {
        return SimulationApi.deleteJobPlots(plotIds).then(() =>
          onConfirmCallback(plotIds)
        );
      },
      undefined
    );
  };

  /**
   * @callback
   * @param {Object} newData
   */
  updateData = newData => {
    this.setState({ data: newData });
  };

  /**
   * sends the new filters to the redux state
   * @param {Object} filter - the new filter
   */
  handleFilterChange = filter => {
    this.props.updateOverviewFilters(filter);
  };

  render = () => {
    const { data, error } = this.state;
    return (
      // data && (
      <GenericJobOverview
        data={data}
        updateData={this.updateData}
        error={error}
        getErrorsAndWarnings={this.getPlotErrors}
        getJobProgress={SimulationApi.getJobPlotProgress}
        deleteJob={this.deletePlot}
        stopJob={this.stopPlot}
        grouperColumns={[
          { title: "Project", field: "project", defaultGroupOrder: 0 },
          {
            title: "Simulation",
            field: "simulation",
            defaultGroupOrder: 1
          },
          {
            title: "Simulation Job",
            field: "simulationJob",
            defaultGroupOrder: 2
          }
        ]}
        handleFilterChange={this.handleFilterChange}
        filters={this.props.filters}
      />
      // )
    );
  };
}

const mapStateToProps = state => ({
  filters: SimulationSelector.getPlotOverviewFilters(state),
  projects: DirectoryExplorerSelector.getProjects(state),
  simulations: DirectoryExplorerSelector.getSimulations(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateOverviewFilters: filter =>
      dispatch(SimulationAction.updatePlotOverviewFilters(filter)),
    fetchProjectsAndSimulationsAction: () =>
      dispatch(DirectoryExplorerApi.fetch()),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulationPlotOverview);
