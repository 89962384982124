import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import WikiApi from "Wiki/api/wiki";
import Collapse from "@material-ui/core/Collapse";
import FolderIcon from "@material-ui/icons/Folder";
import ArticleIcon from "@material-ui/icons/Description";
import PublicIcon from "@material-ui/icons/Public";
import "react-quill/dist/quill.snow.css";
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "80vh",
    paddingTop: 130,
    position: "fixed",
    width: "100%"
  },
  foldersList: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "15%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.2em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey"
    }
  },
  icon: {
    minWidth: "40px"
  },
  topicIcon: {
    minWidth: "40px",
    paddingLeft: "10px"
  },
  listText: {
    paddingLeft: "0px",
    paddingRight: "15px"
  },
  articleBody: {
    overflowY: "scroll",
    // maxHeight: "calc(80vh - 145px)",
    // maxWidth: "60%",
    minWidth: "85%",
    "&::-webkit-scrollbar": {
      width: "0.8em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey"
    }
  }
});

class WikiHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      articles: [],
      folders: [],
      folderMap: [],
      articleData: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params.id) {
      this.getWikiArticles(Number(match.params.id));
    } else {
      this.getWikiArticles().then(() => {});
    }
  }

  groupArticlesByFolder = articles => {
    const foldersList = [];
    const folderMap = new Map();
    articles.forEach(article => {
      const folder = article.folder.id;
      const folderArticles = folderMap.get(folder);
      if (!folderArticles) {
        folderMap.set(folder, [article]);
        foldersList.push({ ...article.folder, open: false });
      } else {
        folderArticles.push(article);
      }
    });
    return [foldersList, folderMap];
  };

  getWikiArticles = async (id = "") => {
    if (!this.state.folders.length) {
      const wikiArticles = await WikiApi.fetchArticles();
      var [foldersList, folderMap] = this.groupArticlesByFolder(wikiArticles);
      foldersList.sort((a, b) => a.order - b.order);
      this.setState({
        articles: wikiArticles,
        folders: foldersList,
        folderMap: folderMap
      });
    }
    if (id) {
      const { folders } = this.state;
      this.props.history.push(`/wiki/${id}`);
      const wikiArticle = await WikiApi.fetchArticles(id);
      this.setState({
        articleData: wikiArticle,
        value: id,
        folders: folders.map(f =>
          f.id == wikiArticle.folder.id ? { ...f, open: true } : f
        )
      });
    }
  };

  toggleFolder = folder => {
    const { folders } = this.state;

    this.setState({
      folders: folders.map(f =>
        f.id === folder.id ? { ...f, open: !f.open } : f
      )
    });
  };

  goToWelcome = () => {
    this.setState({
      value: 0
    });
    this.props.history.push(`/wiki`);
  };

  render() {
    const { classes } = this.props;
    const { value, articles, articleData, folders, folderMap } = this.state;

    return (
      <div className={classes.root}>
        <List component="nav" className={classes.foldersList}>
          <ListItem selected={value == 0} button onClick={this.goToWelcome}>
            <ListItemIcon className={classes.icon}>
              <PublicIcon />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              inset
              primary="Welcome"
            />
          </ListItem>
          {folders &&
            folders.map((folder, index) => (
              <>
                <ListItem
                  button
                  selected={articleData && articleData.folder.id == folder.id}
                  onClick={() => this.toggleFolder(folder)}
                >
                  <ListItemIcon className={classes.icon}>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listText}
                    inset
                    primary={folder.title}
                  />
                  {folder.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={folder && folder.open}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {folderMap.get(folder.id).map(article => (
                      <ListItem
                        selected={value == article.id}
                        onClick={() => this.getWikiArticles(article.id)}
                        button
                        className={classes.nested}
                      >
                        <ListItemIcon className={classes.topicIcon}>
                          <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listText}
                          inset
                          primary={article.title}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ))}
        </List>
        {value === 0 && (
          <ArticleBody value={value} index={0} classes={classes}>
            <Typography variant="h5" align="center">
              Welcome to PlanOpSim Wiki
            </Typography>
            <Typography paragraph align="center" style={{ paddingTop: 30 }}>
              <p>
                This is the official wiki for PlanOpSim. Here you you'll find
                detailed information and documentation about the various
                features and functionalities of PlanOpSim.
              </p>
              <p>
                We are continuously adding information here to cover the
                insights of the calculations, let us know at info@planopsim.com
                if you didn’t find what you were looking for.
              </p>
            </Typography>
          </ArticleBody>
        )}
        {value !== 0 && (
          <ArticleBody value={value} index={value} classes={classes}>
            <Typography
              align="center"
              variant="h5"
              style={{ paddingBottom: "10px" }}
            >
              {articleData.title}
            </Typography>
            <Divider />
            {articleData && (
              <div
                class="ql-editor"
                dangerouslySetInnerHTML={{ __html: articleData.description }}
              ></div>
            )}
          </ArticleBody>
        )}
      </div>
    );
  }
}

WikiHome.propTypes = {
  classes: PropTypes.object.isRequired
};

function ArticleBody(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role="article"
      hidden={value !== index}
      id={`vertical-article-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={classes.articleBody}
    >
      {value === index && <div style={{ margin: "40px" }}>{children}</div>}
    </div>
  );
}

ArticleBody.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WikiHome);
