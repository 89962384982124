import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import EditActions from "components/EditActions/EditActions";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import IconTooltip from "components/IconTooltip/IconTooltip";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import FFWTTargetSelector from "MetaComponent/selectors/FFWTTarget";
import TargetsView from "./components/TargetsView/TargetsView";
import TargetsSelection from "./components/TargetsSelection/TargetsSelection";
import AddBox from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import Helper from "MetaComponent/helper/DesignTarget";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import SelectedDesignTargetApi from "MetaComponent/api/SelectedDesignTarget";
import HelperUtils from "MetaCell/helper/HelperUtils";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 30
  },
  title: {
    float: "left"
  },
  tooltip: {
    float: "left"
  }
});

/**
 * A class component to connect to redux state and serve the form. See {@link MetaCellGlobalParametersForm}
 * @typedef {Component} MetaCellGlobalParameters
 */
export class ComponentTargets extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingSelectedTargets: null,
      warnUserAboutFamilyMembers: false
    };
  }

  /**
   * it checks whether the given design targets' dimensions are compatible.
   * it also converts to a comparable unit before the comparison.
   * @param {Number[]} dtIds - the design target ids
   * @param {Number[]} beingDeselectedTargetIds - unselected design target ids
   * @param {Number[]} beingNewlyAddedTargetIds - newly added design targert ids
   * @param {Number} width - the compatible width
   * @param {Number} height - the compatible height
   * @param {String} unit - the compatible unit
   */
  getIncompatibleDesignTargets(
    dtIds,
    beingDeselectedTargetIds,
    beingNewlyAddedTargetIds,
    width,
    height,
    unit
  ) {
    const { designTargets } = this.props,
      designTargetObjects = dtIds
        .filter(id => beingNewlyAddedTargetIds.includes(id))
        .map(id => {
          if (beingDeselectedTargetIds.includes(id)) return;
          return designTargets.byId[id];
        });
    return Helper.getIncompatibleDesignTargets(
      designTargetObjects,
      width,
      height,
      unit
    );
  }

  /**
   * it updates the editing selected design targets with new ones but if incompatible
   * dimensions are detected it opens a dialog to duplicate the incompatible ones with compatible
   * dimensions
   * @param {Number[]} newSelectedTargets - an array of design target Id's
   * @callback
   */
  updateSelectedTargets = newSelectedTargets => {
    const { editingSelectedTargets } = this.state;
    const beingDeselectedTargetIds = editingSelectedTargets.filter(
      id => !newSelectedTargets.includes(id)
    );
    const beingNewlyAddedTargetIds = newSelectedTargets.filter(
      id => !editingSelectedTargets.includes(id)
    );
    const { showConfirmDialog } = this.props,
      openMetaComponent = this.getOpenMetaComponent(),
      { width, height, unit } = openMetaComponent,
      incompatibleDesignTargets = this.getIncompatibleDesignTargets(
        newSelectedTargets,
        beingDeselectedTargetIds,
        beingNewlyAddedTargetIds,
        width,
        height,
        unit
      );
    if (
      beingDeselectedTargetIds.length > 0 ||
      incompatibleDesignTargets.length === 0
    ) {
      this.setState({
        editingSelectedTargets: newSelectedTargets
      });
    } else {
      const incompatibleDTNames = incompatibleDesignTargets.map(
        incompatibleDT => incompatibleDT.name
      );
      showConfirmDialog(
        "Incompatible Dimensions",
        `The near field dimensions of the design targets below are not compatible with the meta component dimensions:\n\n    ${incompatibleDTNames.join(
          "\n    "
        )}\n\nHow would you like to handle this?`,
        () => {
          // this.copyAndSelectDesignTargets(
          //   incompatibleDesignTargets.map(incompatibleDT => incompatibleDT.id)
          // )
          // commented since we are not resizing temporarily
          this.setState({
            editingSelectedTargets: newSelectedTargets
          });
        },
        () => {
          let icdt_ids = incompatibleDesignTargets.map(
            incompatibleDT => incompatibleDT.id
          );
          this.setState({
            editingSelectedTargets: newSelectedTargets.filter(
              nst => !icdt_ids.includes(nst)
            )
          });
        },
        false,
        () => {},
        false,
        false,
        ["Confirm Anyway", "Cancel"]
      );
    }
  };

  /**
   * it copies the given design targets with adapted compatible properties and selects them in the edit mode
   * @param {*} designTargetsIds - the ids of the design targets to be copied
   */
  copyAndSelectDesignTargets = designTargetsIds => {
    const { designTargets } = this.props,
      designTargetsToCreate = designTargetsIds.map(dtId => {
        let copy = {
          ...designTargets.byId[dtId]
        };
        delete copy.id;
        return copy;
      });
    Promise.all(
      designTargetsToCreate.map(designTarget =>
        this.adaptAndCreateDesignTarget(designTarget)
      )
    ).then(createdIds =>
      this.setState({
        editingSelectedTargets: this.state.editingSelectedTargets.concat(
          createdIds
        )
      })
    );
  };

  /**
   * it changes the editing state with the open meta component selected design targets
   */
  editSelectedTargets = () => {
    const openMetaComponent = this.getOpenMetaComponent(),
      { selected_design_targets } = openMetaComponent;
    if (openMetaComponent.selectedFM.length === 0) {
      this.showNoFamilyMemberWarning();
      return;
    }
    this.setState({
      editingSelectedTargets: selected_design_targets
        ? selected_design_targets.map(
            sdtId => this.props.selectedDesignTargets.byId[sdtId].design_target
          )
        : []
    });
  };

  /**
   * it changes the editing state to null
   */
  cancelEditingSelectedTargets = () => {
    this.setState({
      editingSelectedTargets: null
    });
  };

  /**
   * TEMPORARY IMPLEMENTATION FOR HANDLING THE SELECTION OF DESIGN TARGETS WITH INCOMPATIBLE SET POINTS
   * the idea was that design targets could be used across multiple simulations
   * but I lot of times the set point linked to the design target is not compatible with the simulation
   * so due to current limitations we are duplicating the selected design targets to assign a compatible set point to them
   * @param {Number[]} designTargetsIds - the design targets to be checked
   */
  async handleDesignTargetsWithIncompatibleSetPoints(designTargetsIds) {
    const {
        compatibleSetPointsIds,
        designTargets,
        createDesignTarget
      } = this.props,
      designTargetsToDuplicateIds = designTargetsIds.filter(
        dtId =>
          !compatibleSetPointsIds.includes(designTargets.byId[dtId].set_point)
      ),
      designTargetsToKeepIds = designTargetsIds.filter(
        dtId => !designTargetsToDuplicateIds.includes(dtId)
      ),
      designTargetsToCreate = designTargetsToDuplicateIds.map(dtId => {
        let copy = { ...designTargets.byId[dtId] };
        delete copy.id;
        return copy;
      });

    const createdIds = await Promise.all(
      designTargetsToCreate.map(designTarget =>
        createDesignTarget(this.adaptDesignTargetSetPoint(designTarget))
      )
    );
    return [...designTargetsToKeepIds, ...createdIds];
  }

  /**
   * it saves the selected design targets and leaves the editing mode,
   * in case the currently focused design target is no longer selected,
   * it removes the focus.
   */
  save = async () => {
    const {
        openMetaComponentId,
        focusedDesignTargetId,
        selectedDesignTargets,
        createAndDeleteSelectedDesignTargets
      } = this.props,
      { editingSelectedTargets } = this.state,
      focusedDesignTargetIsNoLongerSelected =
        focusedDesignTargetId &&
        !editingSelectedTargets.includes(focusedDesignTargetId),
      selectedDesignTargetObjs = Object.values(selectedDesignTargets.byId),
      designTargetsCurrentlySelectedIds = selectedDesignTargetObjs.map(
        sdt => sdt.design_target
      ),
      designTargetsToSelect = editingSelectedTargets.filter(
        id => !designTargetsCurrentlySelectedIds.includes(id)
      ),
      designTargetsToUnselect = designTargetsCurrentlySelectedIds.filter(
        id => !editingSelectedTargets.includes(id)
      ),
      selectedDesignTargetsToUnselect = selectedDesignTargetObjs.filter(sdt =>
        designTargetsToUnselect.includes(sdt.design_target)
      ),
      sdtToUnselectIds = selectedDesignTargetsToUnselect.map(sdt => sdt.id);

    createAndDeleteSelectedDesignTargets(
      designTargetsToSelect,
      openMetaComponentId,
      sdtToUnselectIds
    );

    if (focusedDesignTargetIsNoLongerSelected) {
      this.props.focusOnDesignTarget(0);
    }
    this.cancelEditingSelectedTargets();
  };

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  /**
   * it gets the ids of the meta component's selected design targets and builds an array
   * of design target objects from it
   * @returns {Object[]} an array of component design targets
   */
  getComponentTargets = selectedDesignTargetsIds => {
    const { designTargets, selectedDesignTargets, ffwvTargets } = this.props;
    const allTargetsAreValid =
      selectedDesignTargetsIds.length &&
      selectedDesignTargetsIds.every(id => selectedDesignTargets.byId[id]);
    if (!allTargetsAreValid) {
      return [];
    }
    const targets = selectedDesignTargetsIds.map(selectedTargetId => {
      const selectedDesignTarget = selectedDesignTargets.byId[selectedTargetId];
      const targetId = selectedDesignTarget.design_target;
      const designTarget = designTargets.byId[targetId];
      const ffId = designTarget && designTarget.FFWFTarget;
      return Object.assign({}, designTargets.byId[targetId], {
        farfield_target: ffId && ffwvTargets.byId[ffId].name,
        nearfield_dimensions: `${(designTarget && designTarget.NFWidth) ||
          ""} ${designTarget && designTarget.unit} x ${(designTarget &&
          designTarget.NFHeight) ||
          ""} ${designTarget && designTarget.unit}`
      });
    });
    return targets;
  };

  /**
   * it creates a design target and immediately associates it with the component
   * selected design targets
   * @callback
   */
  createComponentDesignTarget = async () => {
    const openMetaComponent = this.getOpenMetaComponent();
    if (openMetaComponent.selectedFM.length === 0) {
      this.showNoFamilyMemberWarning();
      return;
    }
    const { createSelectedDesignTarget, focusOnDesignTarget } = this.props,
      newDesignTargetId = await this.adaptAndCreateDesignTarget({
        name: "new design target"
      });
    await createSelectedDesignTarget(newDesignTargetId, openMetaComponent.id);
    focusOnDesignTarget(newDesignTargetId);
  };

  getDesignTargetUsedMCs = async toBeDeletedDesignTargetId => {
    return await DesignTargetApi.getDesignTargetUsedMCs(
      toBeDeletedDesignTargetId
    );
  };

  deleteComponentDesignTarget = async () => {
    const { selectedDesignTargets } = this.props;
    const openMetaComponent = this.getOpenMetaComponent();
    let toBeDeletedDesignTargetId = this.props.focusedDesignTargetId;
    const { showConfirmDialog, deleteDesignTargetAction } = this.props;
    var usedMCs = await this.getDesignTargetUsedMCs(toBeDeletedDesignTargetId);
    usedMCs = usedMCs?.filter(
      usedMcName => usedMcName !== openMetaComponent.name
    );
    showConfirmDialog(
      "Are you sure you want to delete this design target?",
      usedMCs?.length > 0
        ? [
            `This design target is also being used by ${HelperUtils.joinWords(
              usedMCs
            )} Please select one of the following options`
          ]
        : [`Please select one of the following options`],
      [
        [
          {
            option:
              usedMCs?.length > 0
                ? "Delete for all (permanently)"
                : "Delete permanently",
            actions: [
              // delete call
              (resolve, reject) => {
                deleteDesignTargetAction(toBeDeletedDesignTargetId).then(() => {
                  this.cancelEditingSelectedTargets();
                  this.props.focusOnDesignTarget(0);
                });
                resolve();
              }
            ]
          },
          {
            option:
              usedMCs?.length > 0
                ? "Delete for this target (but keep in library)"
                : "Delete but keep in library",
            actions: [
              // delete call
              async (resolve, reject) => {
                const openMcSelectedTargets = Object.values(
                  selectedDesignTargets?.byId
                ).map(({ design_target }) => design_target);
                const newlySelectedTargets = openMcSelectedTargets.filter(
                  id => id !== toBeDeletedDesignTargetId
                );
                await this.setState({
                  editingSelectedTargets: openMcSelectedTargets
                });
                this.updateSelectedTargets(newlySelectedTargets);
                await this.save();
                resolve();
              }
            ]
          }
        ]
      ],
      () => {},
      false,
      () => {},
      false,
      false,
      ["Delete", "Cancel"]
    );
  };

  /* creates a duplicate design target
   * @callback
   */
  duplicateComponentDesignTarget = async () => {
    const openMetaComponent = this.getOpenMetaComponent();
    // copy object
    let toBeDuplicatedDesignTargetId = this.props.focusedDesignTargetId;
    // const { createSelectedDesignTarget } = this.props,
    let newDuplicateDesignTargetId = await this.props.duplicateDesignTarget(
      toBeDuplicatedDesignTargetId
    );
    this.props.createSelectedDesignTarget(
      newDuplicateDesignTargetId,
      openMetaComponent.id
    );
  };

  /**
   * it overrides the dimensions with a compatible one
   * @param {Object} designTarget - the given design target
   * @returns {Object} the design target with adapted dimensions
   */
  adaptDesignTargetDimensions(designTarget) {
    const openMetaComponent = this.getOpenMetaComponent();
    return {
      ...designTarget,
      NFWidth: openMetaComponent.width,
      NFHeight: openMetaComponent.height,
      unit: openMetaComponent.unit
    };
  }

  /**
   * it keeps the set point if it is compatible otherwise replaces with a compatible one
   * @param {Object} designTarget - the given design target
   * @returns {Object} the design target with adapted set point
   */
  adaptDesignTargetSetPoint(designTarget) {
    const { compatibleSetPointsIds } = this.props,
      compatibleSetPoint =
        designTarget.set_point &&
        compatibleSetPointsIds.includes(designTarget.set_point)
          ? designTarget.set_point
          : compatibleSetPointsIds[0];
    return {
      ...designTarget,
      set_point: compatibleSetPoint
    };
  }

  /**
   * it adapts the dimensions and the set point to compatible ones before creating the design target
   * @param {Object} designTarget - the given design target
   * @returns {Promise} the creation promise
   */
  adaptAndCreateDesignTarget(designTarget) {
    let adaptedDesignTarget = this.adaptDesignTargetDimensions(designTarget);
    adaptedDesignTarget = this.adaptDesignTargetSetPoint(adaptedDesignTarget);
    return this.props.createDesignTarget(adaptedDesignTarget);
  }

  /**
   * it removes and shows the warning message.
   * it is necessary otherwise the message is only shown once
   */
  showNoFamilyMemberWarning() {
    this.setState({ warnUserAboutFamilyMembers: false }, () =>
      this.setState({ warnUserAboutFamilyMembers: true })
    );
  }

  render() {
    const { classes } = this.props,
      { editingSelectedTargets, warnUserAboutFamilyMembers } = this.state,
      isEditing = editingSelectedTargets !== null;
    const openMetaComponent = this.getOpenMetaComponent();
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div test-data="titleWrapper">
              <Typography className={classes.title} variant="h5" component="h3">
                Design Targets
              </Typography>
              <div className={classes.tooltip}>
                <IconTooltip
                  text={"Selected design targets to build this component"}
                />
              </div>
            </div>
            <div style={{ float: "right" }}>
              {!isEditing && this.props.focusedDesignTargetId != 0 && (
                <IconButton
                  test-data="duplicateDesignTargetBtn"
                  aria-label="duplicate"
                  onClick={this.duplicateComponentDesignTarget}
                >
                  <FileCopyIcon />
                </IconButton>
              )}
              {!isEditing && (
                <IconButton
                  test-data="addDesignTargetBtn"
                  aria-label="add"
                  onClick={this.createComponentDesignTarget}
                >
                  <AddBox />
                </IconButton>
              )}
              {!isEditing && this.props.focusedDesignTargetId != 0 && (
                <IconButton
                  test-data="deleteDesignTargetBtn"
                  aria-label="delete"
                  onClick={this.deleteComponentDesignTarget}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <EditActions
                isEditing={isEditing}
                isSaving={false}
                onEdit={this.editSelectedTargets}
                onCancel={this.cancelEditingSelectedTargets}
                onSave={this.save}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            {isEditing ? (
              <TargetsSelection
                editingSelectedTargets={editingSelectedTargets}
                designTargets={this.props.designTargets}
                onSelectTarget={this.updateSelectedTargets}
              />
            ) : (
              <TargetsView
                targetsList={this.getComponentTargets(
                  openMetaComponent.selected_design_targets
                ).filter(({ id }) => id !== undefined)}
                focusedDesignTargetId={this.props.focusedDesignTargetId}
                focusOnDesignTarget={this.props.focusOnDesignTarget}
              />
            )}
          </Grid>
        </Grid>
        {warnUserAboutFamilyMembers && (
          <DirectionSnackbar message="The meta component must have selected family members." />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
    designTargets: DesignTargetSelector.getDesignTargets(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    ),
    ffwvTargets: FFWTTargetSelector.getFFWTTargets(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetaComponent: (id, newProperties) =>
      dispatch(DirectoryExplorerApi.updateMetaComponent(id, newProperties)),
    createDesignTarget: designTarget =>
      dispatch(DesignTargetApi.createDesignTarget(designTarget)),
    duplicateDesignTarget: designTargetId =>
      dispatch(DesignTargetApi.duplicateDesignTarget(designTargetId)),
    deleteDesignTargetAction: id =>
      dispatch(DesignTargetApi.deleteDesignTarget(id)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm,
      inputPlaceholders,
      uploadFile,
      confirmLabels
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm,
          inputPlaceholders,
          uploadFile,
          confirmLabels
        )
      ),
    createSelectedDesignTarget: (designTargetId, metaComponentId) =>
      dispatch(
        SelectedDesignTargetApi.createSelectedDesignTarget(
          designTargetId,
          metaComponentId
        )
      ),
    createAndDeleteSelectedDesignTargets: (
      designTargetIds,
      metaComponentId,
      selectedDesignTargetIds
    ) =>
      dispatch(
        SelectedDesignTargetApi.createAndDeleteSelectedDesignTargets(
          designTargetIds,
          metaComponentId,
          selectedDesignTargetIds
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(ComponentTargets)));
