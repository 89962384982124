import React, { PureComponent } from "react";
import debounce from "lodash.debounce";
import SweepOverviewAddOnly from "./components/SweepOverviewAddOnly/SweepOverviewAddOnly";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";

/**
 * a component to create sweep variables
 * @author Akira Kotsugai
 */
export class AddVariable extends PureComponent {
  constructor(props) {
    super(props);
    this.tableRef = null;
    this.state = {
      visibility: "hidden"
    };
  }

  setTableRef = ref => {
    this.tableRef = ref;
  };

  /**
   * it's not a component method, but an object. it calls the component rendered check with a delay
   * when it is invoked.
   */
  checkRenderedWithDelay = debounce(() => {
    this.checkRendered();
  }, 100);

  /**
   * it keeps checking if the component is rendered, when it is rendered,
   * we show a limited version of the sweep overview where only the form to add a variable
   * is shown. there are some tricks to make it possible (dom manipulation)
   */
  checkRendered() {
    const componentWrapper = document.getElementById("sweepAddOnly");
    console.log(componentWrapper);
    if (componentWrapper) {
      if (this.tableRef) {
        this.tableRef.current.startAdd();
        this.setState({ visibility: "visible" });
        return;
      }
    }
    this.checkRenderedWithDelay();
  }

  componentDidMount() {
    this.checkRendered();
  }

  render() {
    const { restrictFormula, sweptVariables } = this.props;
    return (
      <UnselfishDialog
        onClose={this.props.onClose}
        open={true}
        style={{ visibility: this.state.visibility }}
      >
        <div id="sweepAddOnly">
          <SweepOverviewAddOnly
            setTableRef={this.setTableRef}
            hideEmptyMessage
            hideToolbar
            restrictFormula={restrictFormula}
            onEditingFinished={this.props.onClose}
            sweptVariables={sweptVariables}
          />
        </div>
      </UnselfishDialog>
    );
  }
}

export default AddVariable;
