import React, { PureComponent } from "react";
import { FormLabel, FormControl, MenuItem } from "@material-ui/core";
import IconTooltip from "components/IconTooltip/IconTooltip";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";

/**
 * a component for the user to input direction values
 * @author Akira Kotsugai
 */
export default class Direction extends PureComponent {
  render() {
    const { disabled, name, value, onChange, style } = this.props;
    return (
      <FormControl style={style}>
        <div>
          <FormLabel style={{ fontSize: 12 }} disabled={disabled}>
            Direction
          </FormLabel>
          <IconTooltip text={"Direction of the target wavefront."} />
        </div>
        <UnselfishSelect
          disabled={disabled}
          style={{ marginBottom: 5 }}
          value={value}
          name={name}
          onChange={onChange}
        >
          <MenuItem value="Transmission">Transmission</MenuItem>
          <MenuItem value="Reflection">Reflection</MenuItem>
        </UnselfishSelect>
      </FormControl>
    );
  }
}
