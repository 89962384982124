import React, { PureComponent } from "react";
import MaterialTable from "components/MaterialTable/src/index";
import { withStyles, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import FFWTTargetSelector from "MetaComponent/selectors/FFWTTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import FarFieldWaveFront from "MetaComponent/containers/TargetCanvas/components/FarFieldWavefront/FarFieldWavefront";
import NearFieldWaveFront from "MetaComponent/containers/TargetCanvas/components/NearFieldWavefront/NearFieldWavefront";
import { isEqual } from "lodash";
import DesignTargetDetails from "MetaComponent/containers/TargetCanvas/components/DesignTargetDetails/DesignTargetDetails";
import SetPointApi from "MetaComponent/api/SetPoint";
import SetPointSelector from "MetaComponent/selectors/SetPoint";
import Script2DApi from "MetaComponent/api/Script2D";
import HelperUtils from "MetaCell/helper/HelperUtils";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box"
  }
};

const columns = [
  {
    title: "Name",
    field: "name",
    customSort: (a, b) => HelperUtils.caseInsensitiveSort(a.name, b.name)
  },
  {
    title: "Description",
    field: "description"
  },
  {
    title: "FFWF Name",
    field: "ffwfName"
  }
  // to be enabled after implementing the download functionality
  // {
  //   title: "",
  //   field: "download",
  //   editable: "never",
  //   render: rowData => (
  //     <Tooltip title="Download" placement="top">
  //       <IconButton
  //         test-data="exportBtn"
  //         name="TreeExportButton"
  //         onClick={() => {}}
  //       >
  //         <SaveAltIcon />
  //       </IconButton>
  //     </Tooltip>
  //   )
  // }
];

/**
 * A component to show all design targets the user has permission to see.
 */
export class DesignTargets extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.expandedRowIdx = [];
    this.tableRef = React.createRef();
  }

  /**
   * it fetches the data as soon as the component mounts
   */
  componentDidMount() {
    const {
      designTargets,
      fetchDesignTargetsAction,
      ffwfTargets,
      fetchFFWFTargetsAction,
      setPoints,
      fetchSetPointsAction,
      fetchScript2DsAction
    } = this.props;
    if (designTargets.loaded === false) fetchDesignTargetsAction();
    if (ffwfTargets.loaded === false) fetchFFWFTargetsAction();
    if (setPoints.loaded === false) fetchSetPointsAction();
    fetchScript2DsAction();
  }

  /**
   *
   * it maps the targets data to table columns
   */
  mapDataToColumns(targets) {
    const { ffwfTargets } = this.props;
    return targets.map(target => {
      return {
        name: target.name,
        description: target.description,
        ffwfName: target.FFWFTarget && ffwfTargets.byId[target.FFWFTarget].name,
        id: target.id,
        download: true
      };
    });
  }

  /**
   * @callback
   * @param {Object} designTarget - the designTarget being deleted
   * @returns {Promise} a promise that calls the delete designTarget action
   */
  onRowDelete = designTarget => {
    const { deleteDesignTargetAction } = this.props;
    return new Promise((resolve, reject) => {
      deleteDesignTargetAction(designTarget.id)
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  onRowUpdate = (newData, oldData) => {
    // TODO: implement update functionality
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  duplicateDesignTarget = designTargetId => {
    const { duplicateDesignTargetAction } = this.props;
    return new Promise((resolve, reject) => {
      duplicateDesignTargetAction(designTargetId)
        .then(() => resolve())
        .catch(() => reject());
    });
  };

  showDetailPanel = rowData => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DesignTargetDetails
              focusedDesignTargetId={rowData.id}
              // openMetaComponent={this.getOpenMetaComponent()}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FarFieldWaveFront
              focusedDesignTargetId={rowData.id}
              designTargets={this.props.designTargets}
              ffwfTargets={this.props.ffwfTargets}
              useWavefrontInternally
            />
          </Grid>
          <Grid item xs={6}>
            <NearFieldWaveFront
              focusedDesignTargetId={rowData.id}
              useWavefrontInternally
              // openMetaComponent={this.getOpenMetaComponent()}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  /**
   * @param {Object} rowData - the row being deleted
   * @returns {String} a custom message when the design target is being used
   */
  deleteWarning = rowData => {
    const { metaComponents, mcGroups, selectedDesignTargets } = this.props;
    const basicWarning = "Are you sure you want to delete this design target?";
    const relatedSelectedDesignTargets = Object.values(
      selectedDesignTargets.byId
    )
      .filter(sdt => sdt.design_target === rowData.id)
      .map(sdt => sdt.id);
    if (relatedSelectedDesignTargets.length) {
      const relatedMetaComponents = Object.values(
        metaComponents.byId
      ).filter(mc =>
        mc.selected_design_targets.some(sdtId =>
          relatedSelectedDesignTargets.includes(sdtId)
        )
      );
      const identifierLabels = relatedMetaComponents.map(
        mc => `(${mcGroups.byId[mc.mcGroup].name}) - ${mc.name}`
      );
      return `This design target is being used by ${HelperUtils.joinWords(
        identifierLabels
      )} ${basicWarning}`;
    }
    return basicWarning;
  };

  render = () => {
    const { classes, designTargets, ffwfTargets } = this.props;
    return (
      <div className={classes.main}>
        {designTargets.loaded && ffwfTargets.loaded && (
          <MaterialTable
            tableRef={this.tableRef}
            detailPanel={this.showDetailPanel}
            // onRowClick={(event, rowData, togglePanel) => {
            //   togglePanel();
            // }}
            options={{
              addRowPosition: "first",
              search: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 40, 50],
              detailPanelColumnAlignment: "right",
              actionsColumnIndex: -1
            }}
            title="Design Targets"
            columns={columns}
            data={this.mapDataToColumns(Object.values(designTargets.byId))}
            actions={[
              {
                icon: FileCopyIcon,
                tooltip: "Duplicate",
                name: "duplicate-action",
                onClick: (event, rowData) => {
                  // Do save operation
                  this.duplicateDesignTarget(rowData.id);
                }
              }
            ]}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => true,
              onRowUpdate: this.onRowUpdate,
              onRowDelete: this.onRowDelete
            }}
            localization={{
              body: {
                editRow: {
                  deleteText: this.deleteWarning
                }
              },
              pagination: {
                labelRowsSelect: "designTargets"
              },
              header: {
                actions: ""
              }
            }}
          />
        )}
      </div>
    );
  };
}

const mapState = state => ({
  designTargets: DesignTargetSelector.getDesignTargets(state),
  ffwfTargets: FFWTTargetSelector.getFFWTTargets(state),
  setPoints: SetPointSelector.getSetPoints(state),
  selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
    state
  ),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
  mcGroups: DirectoryExplorerSelector.getMCGroups(state)
});

const mapDispatch = dispatch => ({
  fetchDesignTargetsAction: id =>
    dispatch(DesignTargetApi.fetchDesignTargets(id)),
  fetchFFWFTargetsAction: () => dispatch(DesignTargetApi.fetchFFWFTargets()),
  deleteDesignTargetAction: id =>
    dispatch(DesignTargetApi.deleteDesignTarget(id)),
  duplicateDesignTargetAction: id =>
    dispatch(DesignTargetApi.duplicateDesignTarget(id)),
  fetchSetPointsAction: () => dispatch(SetPointApi.fetchSetPoints()),
  fetchScript2DsAction: () => dispatch(Script2DApi.fetchScript2Ds())
});

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(DesignTargets)));
