import { actionTypes } from "BaseApp/actions/navigationHistory";
import { JobTypes } from "JobOverview/MainJobOverview";

export const defaultState = {
  metacell: "",
  metacellComponents: "",
  library: "",
  jobOverview: JobTypes.SIMULATION
};

export default function(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.SET_METACELL_PATH: {
      return {
        ...state,
        metacell: payload
      };
    }

    case actionTypes.SET_METACELL_COMPONENTS_PATH: {
      return {
        ...state,
        metacellComponents: payload
      };
    }

    case actionTypes.SET_LIBRARY_PATH: {
      return {
        ...state,
        library: payload
      };
    }

    case actionTypes.SET_JOB_OVERVIEW_PATH: {
      return {
        ...state,
        jobOverview: payload
      };
    }

    default:
      return state;
  }
}
