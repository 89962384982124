import React, { Component } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";

/**
 * A class component to provide a searchable select
 * @typedef {Component} AutoComplete
 * @author Akira
 */
export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textInputValue: this.props.initialInputValue
    };
  }

  /**
   * @param {Object} event
   * @param {String} newInputValue
   */
  onInputChange = (event, newInputValue) => {
    if (event && event.type === "change") {
      this.setState({ textInputValue: newInputValue });
    }
  };

  /**
   * @param {Object} event
   * @param {String} newValue
   */
  onSelect = (event, newValue) => {
    if (event) {
      let selectedOption = null;
      for (const option of this.props.options) {
        if (option.name === newValue) {
          selectedOption = option;
          break;
        }
      }
      if (selectedOption) {
        this.props.onChange(selectedOption.id);
        this.setState({ textInputValue: selectedOption.name });
      }
    }
  };

  render() {
    return (
      <div onKeyDown={event => event.stopPropagation()}>
        <Autocomplete
          id={this.props.id}
          name={this.props.name}
          disableClearable
          options={this.props.options.map(({ name }) => name)}
          value={this.props.value}
          inputValue={this.state.textInputValue}
          renderInput={params => (
            <TextField
              {...params}
              style={{ width: "100%" }}
              placeholder={this.props.placeholder}
            />
          )}
          onInputChange={this.onInputChange}
          onChange={this.onSelect}
          menuPortalTarget={document.querySelector("body")}
          onBlur={this.props.onBlur}
          PopperComponent={props => <Popper {...props} />}
        />
      </div>
    );
  }
}

AutoComplete.propTypes = {
  /**
   * on blur callback
   */
  onBlur: PropTypes.func,
  /**
   * message when input is empty
   */
  placeholder: PropTypes.string,
  /**
   * text value of the selected option
   */
  value: PropTypes.string.isRequired,
  /**
   * option objects
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * the component's id
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * the component's name
   */
  name: PropTypes.string,
  /**
   * first value to show in the input
   */
  initialInputValue: PropTypes.string.isRequired
};
