import { actionType } from "MetaComponent/actions/ODA";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testOdaJobs = {
  byId: {
    1: {
      id: 1,
      name: "ODA 1",
      design_job: 1,
      status: "DONE",
      swept_variables: []
    },
    2: {
      id: 2,
      name: "ODA 2",
      design_job: 2,
      status: "RUNNING",
      swept_variables: []
    },
    3: {
      id: 3,
      name: "ODA 3",
      design_job: 3,
      status: "QUEUED",
      swept_variables: []
    },
    4: {
      id: 4,
      name: "ODA 4",
      design_job: 3,
      status: "DONE",
      swept_variables: []
    }
  },
  allIds: ["1", "2", "3", "4"],
  loaded: true
};

export const defaultState = {
  entities: {
    odaJobs: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    selectedJobId: null,
    tempSweptVariables: [],
    overviewFilters: {}
  }
};

export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.UPDATE_OVERVIEW_FILTERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          overviewFilters: { ...state.ui.overviewFilters, ...payload }
        }
      };
    }

    case actionType.DELETE_ODA_JOBS: {
      let odaJobs = cloneDeep(state.entities.odaJobs);
      Utils.deleteEntities(payload, odaJobs);
      return {
        ...state,
        entities: { ...state.entities, odaJobs }
      };
    }
    case actionType.SET_ODA_JOBS: {
      let odaJobs = cloneDeep(defaultState.entities.odaJobs);
      Utils.addOrUpdateEntities(payload, odaJobs);
      const lastJobId = payload.length
        ? Math.max.apply(
            Math,
            payload.map(job => job.id)
          )
        : null;
      const selectedJobId =
        state.ui.selectedJobId && odaJobs.byId[state.ui.selectedJobId]
          ? state.ui.selectedJobId
          : lastJobId;
      return {
        ...state,
        entities: { ...state.entities, odaJobs },
        ui: { ...state.ui, selectedJobId }
      };
    }
    case actionType.UPSERT_ODA_JOBS: {
      let odaJobs = cloneDeep(state.entities.odaJobs);
      Utils.addOrUpdateEntities(payload, odaJobs);
      return {
        ...state,
        entities: { ...state.entities, odaJobs }
      };
    }

    case actionType.SELECT_ODA_JOB: {
      return {
        ...state,
        ui: { ...state.ui, selectedJobId: payload }
      };
    }
    case actionType.RESET_ODA_JOBS: {
      return defaultState;
    }
    case actionType.UPDATE_TEMP_ODA_VARIABLES: {
      return {
        ...state,
        ui: { ...state.ui, tempSweptVariables: payload }
      };
    }
    default:
      return state;
  }
}
