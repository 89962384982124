import React, { Component } from "react";
import { connect } from "react-redux";
import DiffractionOrdersForm from "./components/DiffractionOrdersForm/DiffractionOrdersForm";
import Probes from "./components/Probes/Probes";
import Action from "MetaCell/actions/SimulationSettings";
import Selector from "MetaCell/selectors/SimulationSettings";
import SweepOverview from "./components/SweepOverview";
import { metaCellPaths } from "MetaCell/MetaCell";
import { withStyles } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";
import Description from "components/Description/Description";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";

const styles = {
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

/**
 * A class component to be used as the main content of a page for maximum diffraction orders
 * it connects to redux state and contains a form. See {@link MaxDiffractionOrdersForm}
 * @typedef {Component} SimulationSettingsCanvas
 * @author Ibtihel
 * TODO: extract the similarities it has with other canvases
 */
export class SimulationSettingsCanvas extends Component {
  /**
   * if sets the open page to fill the state as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(metaCellPaths.SIMULATION_SETTINGS);
  }

  /**
   * it calls the editing saver as soon as the component unmounts
   */
  componentWillUnmount() {
    this.saveEditings();
  }

  /**
   * it calls the editing saver callback for this component as soon as the component unmounts
   */
  saveEditings = async () => {
    await this.props.saveEditings(metaCellPaths.SIMULATION_SETTINGS);
    this.setEditingSimulationDescription(null);
  };

  /**
   * it is supposed to be passed to the form to handle field changes
   * @callback
   * @param {Object} newValue - the new field value
   */
  updateMDOField = newValue => {
    const { updateEditingMDOAction } = this.props;
    updateEditingMDOAction(newValue);
  };

  /**
   * it is supposed to be passed to the description component to handle field changes
   * @callback
   * @param {String} newValue - the new field value
   */
  setEditingSimulationDescription = value => {
    const { setEditingSimulationDescriptionAction } = this.props;
    setEditingSimulationDescriptionAction(value);
  };

  /**
   * it sets the real entity description in the editing state in redux
   */
  editSimulationDescription = () => {
    const { openSimulationId, simulations } = this.props;
    const simulation = simulations.byId[openSimulationId];
    if (simulation)
      this.setEditingSimulationDescription(
        simulation.description ? simulation.description : ""
      );
  };

  render() {
    const {
        editingMaxDiffractionOrders,
        isFetching,
        classes,
        editingSimulationDescription,
        simulations,
        openSimulationId
      } = this.props,
      simulation = simulations.byId[openSimulationId];

    if (isFetching) {
      return (
        <div className={classes.progress}>
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }
    return (
      <div style={{ display: "flex", paddingBottom: 100 }}>
        <div className="Simulation settings">
          <Description
            subject={"Simulation"}
            description={
              simulation && simulation.description ? simulation.description : ""
            }
            updateField={this.setEditingSimulationDescription}
            editingDescription={editingSimulationDescription}
            handleEdit={this.editSimulationDescription}
            handleSave={this.saveEditings}
          />
          <DiffractionOrdersForm
            maxDiffractionOrders={editingMaxDiffractionOrders}
            updateFieldCallback={this.updateMDOField}
          />
          <Probes />
        </div>
        <div name="SweepVar" style={{ display: "flex", flex: 1 }}>
          <SweepOverview fetchAllData={this.props.fetchAllData} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    editingMaxDiffractionOrders: Selector.getEditingMaxDiffractionOrders(state),
    openSimulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
    simulations: DirectoryExplorerSelector.getSimulations(state),
    editingSimulationDescription: Selector.getEditingSimulationDescription(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateEditingMDOAction: maxDiffractionOrders =>
      dispatch(Action.changeEditingMDOField(maxDiffractionOrders)),
    setEditingSimulationDescriptionAction: description =>
      dispatch(Action.setEditingSimulationDescription(description))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SimulationSettingsCanvas));
