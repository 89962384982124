import React, { PureComponent } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import GenericJobOverview from "./GenericJobOverview";
import DesignApi from "MetaComponent/api/Design";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import DesignAction from "MetaComponent/actions/Design";
import DesignSelector from "MetaComponent/selectors/Design";
import GenericApi from "Api";
import HelperUtils from "MetaCell/helper/HelperUtils";

export class DesignJobOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      data: null,
      restorationFailed: false
    };
    this.handleFetchJobsComplete = this.handleFetchJobsComplete.bind(this);
    this.handleFetchJobsError = this.handleFetchJobsError.bind(this);
  }

  /**
   * when the component mounts it only gets the design jobs if the mc groups are loaded
   * else it fetches the groups and the meta componetns
   */
  componentDidMount() {
    const { fetchMCGroupsAndMetaComponentsAction, mcGroups } = this.props;
    if (mcGroups.loaded) {
      this.fetchJobs();
    } else {
      fetchMCGroupsAndMetaComponentsAction();
    }
  }

  /**
   * it fetches the jobs when the groups are loaded
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.mcGroups.loaded && this.props.mcGroups.loaded) {
      this.fetchJobs();
    }
  }

  /**
   * it makes a request to get all the design jobs
   */
  async fetchJobs() {
    try {
      const response = await Axios.get(
        `${GenericApi.getBaseUrl()}/designjobs/`
      );
      if (response.status === 200) {
        this.handleFetchJobsComplete(response);
      } else {
        this.handleFetchJobsError();
      }
    } catch {
      this.handleFetchJobsError();
    }
  }

  /**
   * it sets the state that is used for the table data
   * @param {Object} response - the http response object
   */
  handleFetchJobsComplete({ data }) {
    if (data && data.length > 0) {
      const tableData = data.map(job => {
        return {
          itemId: job.id,
          mcGroup: this.getMCGroupName(job.meta_component),
          metaComponent: this.getMetaComponentName(job.meta_component),
          startDate: (job.start && job.start.slice(0, 10)) || "-",
          status: job.status,
          errors: job.errors
        };
      });
      this.setState({
        data: tableData
      });
    } else {
      this.setState({ data: [] });
    }
  }

  getRecipientData() {
    const { mcGroups } = this.props;
    return Object.values(mcGroups.byId).map(mcGroup => ({
      restorationRecipientId: mcGroup.id,
      mcGroup: mcGroup.name
    }));
  }

  handleFetchJobsError() {
    this.setState({ error: true });
  }

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {Number} the id of the group to which the meta component belongs
   */
  getMCGroupId = metaComponentId => {
    const { metaComponents } = this.props;
    const metaComponent = metaComponents.byId[metaComponentId];
    return metaComponent ? metaComponent.mcGroup : null;
  };

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} the name of the project to which the component belongs
   */
  getMCGroupName = metaComponentId => {
    const { mcGroups, metaComponents } = this.props;
    let mcGroupName = "";
    const metaComponent = metaComponents.byId[metaComponentId];
    if (metaComponent) {
      const mcGroup = mcGroups.byId[metaComponent.mcGroup];
      if (mcGroup) {
        mcGroupName = mcGroup.name;
      }
    }
    return mcGroupName;
  };

  /**
   * @param {Number} metaComponentId - the id of the meta component
   * @returns {String} - the name of the meta component
   */
  getMetaComponentName = metaComponentId => {
    const { metaComponents } = this.props;
    const metaComponent = metaComponents.byId[metaComponentId];
    return metaComponent ? metaComponent.name : "";
  };

  /**
   * @param {Number} jobId - the design job id
   * @returns {Promise} - the promise that stops the job at the endpoint
   */
  stopDesignJob = jobId => {
    return DesignApi.stopDesignJob(jobId).then(() => Promise.resolve(true));
  };

  /**
   * @param {Number} jobId - the design job id
   * @returns {Object} errors and warnings extracted from the design job
   */
  getDesignJobErrors = async jobId => {
    const job = this.state.data.find(job => job.itemId === jobId);
    const { errors } = job;
    return { errors };
  };

  /**
   * it deletes a design job
   * @param {Number[]} jobIds - the job ids
   * @callback
   */
  deleteDesignJob = (jobIds, onConfirmCallback) => {
    return this.props.showConfirmDialog(
      "Delete design job",
      "Are you sure you want to delete the selected design jobs?",
      () => {
        this.props.deleteDesignJob(jobIds);
        onConfirmCallback(jobIds);
      },
      undefined
    );
  };

  /**
   * @param {Number} designJobId - the job id to restore the meta component from
   * @param {Number} mcGroupId - the group to restore the meta component into
   */
  restoreMetaComponent = (designJobId, mcGroupId) => {
    this.setState({ restorationFailed: false });
    return DirectoryExplorerApi.restoreMetaComponent(
      mcGroupId,
      designJobId
    ).catch(error => this.setState({ restorationFailed: true }));
  };

  /**
   * @callback
   * @param {Object} newData
   */
  updateData = newData => {
    this.setState({ data: newData });
  };

  /**
   * sends the new filters to the redux state
   * @param {Object} filter - the new filter
   */
  handleFilterChange = filter => {
    this.props.updateOverviewFilters(filter);
  };

  render = () => {
    const { data, error, restorationFailed } = this.state;
    return (
      // data && (
      <>
        <GenericJobOverview
          data={data}
          updateData={this.updateData}
          recipientData={this.getRecipientData()}
          error={error}
          getErrorsAndWarnings={this.getDesignJobErrors}
          getJobProgress={DesignApi.getDesignJobProgress}
          stopJob={this.stopDesignJob}
          grouperColumns={[
            {
              title: "Group",
              field: "mcGroup",
              defaultGroupOrder: 0,
              customSort: (a, b) => {
                if (typeof a == "object") a = a.mcGroup;
                if (typeof b == "object") b = b.mcGroup;
                return HelperUtils.caseInsensitiveSort(a, b);
              }
            },
            {
              title: "Meta Component",
              field: "metaComponent",
              defaultGroupOrder: 1,
              customSort: (a, b) => {
                if (typeof a == "object") a = a.metaComponent;
                if (typeof b == "object") b = b.metaComponent;
                return HelperUtils.caseInsensitiveSort(a, b);
              }
            }
          ]}
          deleteJob={this.deleteDesignJob}
          onRestoreConfirm={this.restoreMetaComponent}
          restorationRecipient={"mcGroup"}
          handleFilterChange={this.handleFilterChange}
          filters={this.props.filters}
        />
        {restorationFailed && (
          <DirectionSnackbar message="We cannot restore a meta component from this design job because this feature was unsupported when this design ran. Try again with newer jobs." />
        )}
      </>
      // )
    );
  };
}

const mapStateToProps = state => ({
  filters: DesignSelector.getOverviewFilters(state),
  mcGroups: DirectoryExplorerSelector.getMCGroups(state),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state)
});

const mapDispatchToProps = dispatch => {
  return {
    updateOverviewFilters: filter =>
      dispatch(DesignAction.updateOverviewFilters(filter)),
    fetchMCGroupsAndMetaComponentsAction: () =>
      dispatch(DirectoryExplorerApi.fetch()),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    deleteDesignJob: jobIds => dispatch(DesignApi.deleteDesignJobs(jobIds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignJobOverview);
