/**
 * @constant
 * @typedef {Object} FFWTTargetActionType
 * @property {String} SET_FFWT_TARGETS - tells the reducer to set targets
 * @property {String} DELETE_FFWT_TARGET - tells the reducer to delete a ffwt target
 * @property {String} UPSERT_FFWF_TARGETS - tells the reducer to add or update ffwt targets
 * @property {String} SET_FFWF_TARGET_WAVEFRONT - tells the reducer to set the wave_front field
 * of a ffwf target
 * @global
 * it defines ffwt target's type of actions.
 */
export const actionType = Object.freeze({
  SET_FFWT_TARGETS: "SET_FFWT_TARGETS",
  DELETE_FFWT_TARGET: "DELETE_FFWT_TARGET",
  UPSERT_FFWF_TARGETS: "UPSERT_FFWF_TARGETS",
  SET_FFWF_TARGET_WAVEFRONT: "SET_FFWF_TARGET_WAVEFRONT"
});

/**
 * A class created to create design target actions to be dispatched to redux. It is already tested with
 * FFWT Target reducer, so it does not need to be unit tested.
 */
export default class FFWTTargetAction {
  static set = value => ({
    type: actionType.SET_FFWT_TARGETS,
    payload: value
  });

  static delete = targetId => ({
    type: actionType.DELETE_FFWT_TARGET,
    payload: targetId
  });

  static upsert = value => ({
    type: actionType.UPSERT_FFWF_TARGETS,
    payload: value
  });

  static setFFWFTargetWavefront = (id, wavefront) => ({
    type: actionType.SET_FFWF_TARGET_WAVEFRONT,
    payload: {
      id,
      wavefront
    }
  });
}
