import React, { PureComponent } from "react";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Proptypes from "prop-types";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import {
  withStyles,
  DialogContent,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  DialogActions,
  Button,
  Tooltip,
  Grid,
  Typography,
  FormLabel,
  FormControl,
  MenuItem
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SweepInput from "components/SweepInput/SweepInput";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import Spinner from "components/Spinner/Spinner";
import IconTooltip from "components/IconTooltip/IconTooltip";

const styles = theme => ({
  dialogTitleBar: {
    padding: 0
  },
  appBar: {
    position: "relative"
  },
  dialogActionBar: {
    justifyContent: "flex-start"
  },
  fullWidth: {
    width: "100%"
  },
  confirmButtonWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

/**
 * A component to allow the user to generate a parameterized layer stack
 * @author Akira Kotsugai
 */
export class ParamStack extends PureComponent {
  constructor(props) {
    super(props);
    const { layer } = props;
    this.state = this.getDefaultState(layer);
  }

  /**
   * resets the form when the component closes
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const dialogHasBeenOpened = !prevProps.open && this.props.open;
    if (dialogHasBeenOpened) {
      this.setState(this.getDefaultState(this.props.layer));
    }
  }

  getDefaultState(layer) {
    const sc_params = layer ? layer.sc_params : undefined;
    const type = sc_params ? sc_params.type : "linear";
    const layer_count = sc_params ? sc_params.layer_count : 3;
    const param_1 = sc_params ? sc_params.param_1 : 10;
    const param_2 = sc_params && sc_params.param_2 ? sc_params.param_2 : 3;
    return {
      type,
      layer_count,
      param_1,
      param_2,
      processing: false
    };
  }

  handleFieldChange = (name, value) => {
    const parsedValue = value === "" || isNaN(value) ? value : parseInt(value);
    this.setState({ [name]: parsedValue });
  };

  buildStaircase = () => {
    this.setState({ processing: true });
    const { simulationId, handleClose, layer, buildStaircase } = this.props;
    let staircaseParams = { ...this.state };
    delete staircaseParams.processing;
    if (staircaseParams.type === "linear") {
      delete staircaseParams["param_2"];
    }
    return buildStaircase(simulationId, layer.id, staircaseParams)
      .then(() => handleClose())
      .catch(() => this.setState({ processing: false }));
  };

  render = () => {
    const { classes, handleClose, open, layer } = this.props;
    const { type, layer_count, param_1, param_2, processing } = this.state;
    return (
      <UnselfishDialog open={open} maxWidth={"xs"} disableEnforceFocus={true}>
        <DialogTitle onClose={handleClose} className={classes.dialogTitleBar}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                test-data="closeBtn"
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                disabled={processing}
              >
                <CloseIcon />
              </IconButton>
              Build a staircase
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <div style={{ overflow: "hidden" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div>
                  <Typography
                    variant="subtitle1"
                    component="h4"
                    style={{ float: "left" }}
                  >
                    {`Base layer: ${(layer && layer.name) || ""}`}
                  </Typography>
                  <IconTooltip text="layer from which the other layers shall be generated." />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <FormLabel style={{ fontSize: 12 }}>
                    Total amount of layers
                  </FormLabel>
                  <IconTooltip text="This variable is the total number of layers in the staircase including the base layer." />
                </div>
                <SweepInput
                  test-data="layerCount"
                  value={layer_count}
                  onChange={value => {
                    if (Number(value) < 1) value = "";
                    this.handleFieldChange("layer_count", value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <div>
                    <FormLabel style={{ fontSize: 12 }}>
                      Staircase type
                    </FormLabel>
                    <IconTooltip text="This type defines how subsequent layers are related in the cross-section." />
                  </div>
                  <UnselfishSelect
                    test-data={"staircaseType"}
                    value={type}
                    style={{ marginTop: 4 }}
                    onChange={event => {
                      const { param_1, param_2 } = this.getDefaultState(layer);
                      this.setState({ param_1, param_2 });
                      this.handleFieldChange("type", event.target.value);
                    }}
                  >
                    <MenuItem value="linear">Linear</MenuItem>
                    <MenuItem value="parabolic">Parabolic</MenuItem>
                  </UnselfishSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormLabel style={{ fontSize: 12 }}>
                  {type === "linear"
                    ? "% to scale from top to bottom"
                    : "layer location of the waist"}
                </FormLabel>
                <SweepInput
                  allowNegative
                  test-data="param1"
                  value={param_1}
                  onChange={value => this.handleFieldChange("param_1", value)}
                />
              </Grid>
              {type === "parabolic" && (
                <Grid item xs={12}>
                  <FormLabel style={{ fontSize: 12 }}>
                    waist minimal width
                  </FormLabel>
                  <SweepInput
                    test-data="param2"
                    value={param_2}
                    onChange={value => this.handleFieldChange("param_2", value)}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActionBar}>
          <div style={{ flexGrow: 1 }} />
          <Button
            test-data="cancelBtn"
            onClick={handleClose}
            color="primary"
            disabled={processing}
          >
            Cancel
          </Button>
          <div className={classes.confirmButtonWrapper}>
            <Button
              test-data="confirmBtn"
              variant="contained"
              color="primary"
              onClick={this.buildStaircase}
              disabled={processing}
            >
              Confirm
            </Button>
            {processing && (
              <Spinner
                name="Waiting"
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </DialogActions>
      </UnselfishDialog>
    );
  };
}

ParamStack.propTypes = {
  /**
   * which layer the parameterized structure belongs to
   */
  layerId: Proptypes.number.isRequired,
  /**
   * the layer parameters
   */
  parameters: Proptypes.string.isRequired,
  /**
   * the callback function that handles the update of a parameter
   */
  onUpdateParameters: Proptypes.func.isRequired
};

export default withErrorBoundary(withStyles(styles)(ParamStack));
