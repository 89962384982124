import Axios from "axios";
import StructureAction from "MetaCell/actions/Structure";
import GenericApi from "Api";

/**
 * A class created to encapsulate async http operations related to the structure screen
 * it can prepare the data before firing http calls
 * @author Akira Kotsugai
 */
export default class StructureApi {
  /**
   * it generates the url to the layer api to manipulate the given layer if there is any
   *@param id - the layer's id
   */
  static getLayersUrl = (id = "") => {
    return `${GenericApi.getBaseUrl()}/layers/${id}`;
  };

  /**
   * it generates the url to the image upload url
   *@param id - the layer's id that the image belongs to
   */
  static getImageUploadUrl = layerId => {
    return `${GenericApi.getBaseUrl()}/layers/${layerId}/discretized`;
  };

  /**
   * it generates a url to fetch layers by a given simulation
   */
  static getLayersBySimulationUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/layers/?simulation=${simulationId}`;

  /**
   * it generates a url to copy a layer
   * @param {Number} layerId - the id of the layer
   */
  static getCopyLayerUrl = layerId =>
    `${GenericApi.getBaseUrl()}/layers/${layerId}/copy`;

  /**
   * it generates the url to the used material api to manipulate the given used material
   * if there is any
   * @param id - the used material's id
   */
  static getUsedMaterialsUrl = (id = "") => {
    return `${GenericApi.getBaseUrl()}/usedmaterials/${id}`;
  };

  /**
   * it generates a url to fetch used materials by a given layer
   */
  static getUsedMaterialsByLayerUrl = layerId =>
    `${GenericApi.getBaseUrl()}/usedmaterials/?layer=${layerId}`;

  /**
   * it generates the url to the used material api to manipulate the given used material
   *@param id - the used material's id
   */
  static updateLayersUrl = id =>
    `${GenericApi.getBaseUrl()}/usedmaterials/${id}`;

  static invertLayersUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/simulations/${simulationId}/invert_layers`;

  /**
   * it generates the url to the parameterized structures endpoint
   */
  static getPossibleParameterizedStructuresUrl = () =>
    `${GenericApi.getBaseUrl()}/parameterizedstructures`;

  /**
   * it saves the given used material's properties
   * @param {Object} usedMaterial - the used material properties
   * @return {Function} a function that is dispatchable to redux
   */
  static applyUsedMaterialEditing = usedMaterial => {
    return dispatch => {
      return Axios.patch(
        StructureApi.getUsedMaterialsUrl(usedMaterial.id),
        usedMaterial
      )
        .then(patchResponse => patchResponse.data)
        .then(savedUsedMaterial =>
          dispatch(StructureAction.upsertUsedMaterials([savedUsedMaterial]))
        )
        .catch(err => {
          console.log(err);
        });
    };
  };

  /**
   * it deletes a layer and fetches all layers to be dispatched
   * @param {Object} layerToDeleteId - the layer to be deleted
   * @param {Number} simulationId - the simulation to which the layer belongs
   * @return {Function} a function that is dispatchable to redux
   */
  static deleteLayer = (layerToDeleteId, simulationId) => {
    return dispatch => {
      return Axios.delete(StructureApi.getLayersUrl(layerToDeleteId)).then(() =>
        this.fetchLayers(simulationId)(dispatch)
      );
    };
  };

  /**
   * it saves the given layer properties. we convert the properties object to a form data
   * if there is an image being uploaded otherwise the request fails.
   * @param {Number} layerId - the layer id
   * @param {Object} newProperties - the layer properties that will be changed
   * @return {Function} a function that is dispatchable to redux
   */
  static applyLayerEditing = (layerId, newProperties) => {
    return dispatch => {
      return this.patchLayer(
        layerId,
        newProperties.image ? this.getFormData(newProperties) : newProperties
      )
        .then(savedLayer => {
          dispatch(
            StructureAction.updateEditingLayerAndReplaceUsedMaterials(
              savedLayer
            )
          );
        })
        .catch(err => {
          console.log(err);
        });
    };
  };

  /**
   * it updates the passed layer properties in the database
   * @param {Number} layerId - the layer id
   * @param {Object} newProperties - the layer properties that will be changed
   * @returns {Object} the updated layer with nested used materials
   */
  static patchLayer = (layerId, newProperties) => {
    return Axios.patch(StructureApi.getLayersUrl(layerId), newProperties).then(
      patchResponse => patchResponse.data
    );
  };

  static postInvertLayers = simulationId => {
    return Axios.post(StructureApi.invertLayersUrl(simulationId)).then(
      response => response.data
    );
  };

  /**
   * it deletes the passed used materials
   * @param {Number[]} usedMaterialIds - the used material ids
   * @returns {Promise} a promise to make requests to delete all of them
   */
  static deleteUsedMaterial = (usedMaterial, simulationId) => {
    // return Promise.all(
    //   usedMaterialIds.map(id => Axios.delete(this.getUsedMaterialsUrl(id)))
    // );
    return dispatch => {
      return Axios.delete(
        StructureApi.getUsedMaterialsUrl(usedMaterial.id)
      ).then(() => {
        this.fetchLayers(simulationId, true)(dispatch)
      })
        .catch(err => {
          console.log(err);
        });
    };
  };

  /**
   * it changes the parameterList value of the given layer
   * @param {Number} layerId - the layer that will have the parameterList changed
   * @param {Object} parameters - the new parameter list
   * @returns {Function} a function that is dispatchable to redux
   */
  static updateStructureParameters = (layerId, parameters) => {
    return dispatch => {
      return this.patchLayer(layerId, {
        parameterList: JSON.stringify(parameters)
      }).then(updatedLayer =>
        dispatch(
          StructureAction.updateEditingLayerAndReplaceUsedMaterials(
            updatedLayer
          )
        )
      );
    };
  };

  static updateStructureCombination = (layerId, structure, parameters) => {
    return dispatch => {
      return this.patchLayer(layerId, {
        structure,
        parameterList: JSON.stringify(parameters)
      }).then(updatedLayer =>
        dispatch(
          StructureAction.updateEditingLayerAndReplaceUsedMaterials(
            updatedLayer
          )
        )
      );
    };
  };

  /**
   * it copies a layer and its used materials. it dispatches all the new data and the modified ones.
   * @param {Number} layerId - the id of the layer
   * @param {Number} simulationId - the simulation to which the layer belongs
   * @returns {Function} a function that is dispatchable to redux
   */
  static copyLayer = (layerId, simulationId) => {
    return dispatch => {
      return Axios.post(this.getCopyLayerUrl(layerId)).then(() =>
        this.fetchLayers(simulationId, true)(dispatch)
      );
    };
  };

  /**
   * it shifts a layer up or down, gets the other updated layer and dispatch them
   * @param {Number} layerToSwapId - the layer to move up or down
   * @param {Number} newIndex - the new index the layer will have
   * @param {Number} simulationId - the simulation to which the layer belongs
   * @return {Function} a function that is dispatchable to redux
   */
  static shiftLayer = (layerToSwapId, newIndex, simulationId) => {
    return dispatch => {
      return this.patchLayer(layerToSwapId, { index: newIndex })
        .then(() => {
          return this.fetchLayers(simulationId, true)(dispatch);
        })
        .catch(err => {
          console.log(err);
        });
    };
  };

  static invertLayers = simulationId => {
    return dispatch => {
      return this.postInvertLayers(simulationId)
        .then(() => {
          return this.fetchLayers(simulationId, true)(dispatch);
        })
        .catch(err => {
          console.log(err);
        });
    };
  };

  /**
   * returns a function that takes the expands all staircases of a layer stack
   * @param {Number} simulationId - the simulation to which the layers belong
   * @param {Number} layerId - the layer to build a staircase from
   * @param {Object} sc_params - the staircase params
   * @return {Function} a function that is dispatchable to redux
   */
  static buildStaircase = (simulationId, layerId, sc_params) => {
    return dispatch => {
      return this.patchLayer(layerId, { sc_params }).then(() => {
        return this.fetchLayers(simulationId, true)(dispatch);
      });
    };
  };

  /**
   * it generates a submittable form data with the given object
   * but undefined or null values are ignored
   * @param {Object} object - the object to be converted
   * @returns {FormData} the form data
   */
  static getFormData = object => {
    const formData = new FormData();
    for (var key in object) {
      const field = object[key];
      if (field !== null && field !== undefined)
        formData.append(key, object[key]);
    }
    return formData;
  };

  /**
   * it adds a new layer and dispatches the new layer, its new used materials, and all the other layers because their indices were updated.
   * @param {Object} newLayer - the new layer
   * @param {Object} simulationId - the simulation to which the layer belongs
   * @returns {Function} a function that is dispatchable to redux
   */
  static addLayer = (newLayer, simulationId) => {
    return dispatch => {
      const formData = StructureApi.getFormData(newLayer);
      return Axios.post(StructureApi.getLayersUrl(), formData)
        .then(postLayerResponse => postLayerResponse.data)
        .then(() => {
          return this.fetchLayers(simulationId, true)(dispatch);
        })
        .catch(err => {
          console.log(err);
        });
    };
  };

  /**
   * it fetches all the existing layers in the api's database as well as the used materials,
   * if it is not an already open simulation, we get rid of the old layers.
   * we always get rid of old used materials though, it might be that they no longer exist.
   * belonging to the found layers and stores them in the redux state
   * @param {Number} simulationId - which simulation to fetch layers of
   * @param {Boolean} alreadyOpenSimulation - whether or not it is fetching layers for a simulation that is already open
   * @return {Function} a function that is dispatchable to redux
   */
  static fetchLayers = (simulationId, alreadyOpenSimulation) => {
    return dispatch =>
      Axios.get(StructureApi.getLayersBySimulationUrl(simulationId))
        .then(res => res.data)
        .then(layers => {
          const allUsedMaterialsPerLayer = layers.map(
            layer => layer.usedmaterial_set
          );
          const allUsedMaterials = [].concat.apply(
            [],
            allUsedMaterialsPerLayer
          );
          const resetOldData = !alreadyOpenSimulation;
          if (resetOldData) dispatch(StructureAction.reset());
          dispatch(StructureAction.upsertUsedMaterials(allUsedMaterials, true));
          dispatch(StructureAction.upsertLayers(layers));
          return layers;
        })
        .catch(error => {
          console.log("Error to fetch layers: ", error.message);
        });
  };

  /**
   * it makes a request to get the possible parameterized structures
   * @return {Function} a function that is dispatchable to redux
   */
  static fetchPossibleParameterizedStructures = () => {
    return dispatch =>
      GenericApi.runApiCall(
        Axios.get(this.getPossibleParameterizedStructuresUrl())
      )
        .then(res => res.data)
        .then(parameterizedStructures =>
          dispatch(
            StructureAction.setParameterizedStructures(parameterizedStructures)
          )
        );
  };
}
