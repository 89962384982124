import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DrilldownInput from "components/DrilldownInput";
import { FormLabel } from "@material-ui/core";
import AnalysisSelector from "MetaComponent/selectors/Analysis";
import DesignSelector from "MetaComponent/selectors/Design";
import AnalysisAction from "MetaComponent/actions/Analysis";

export const styles = {
  drillDown: {
    marginBottom: 20,
    textAlign: "left"
  }
};

export class AnalysisJobDropdown extends PureComponent {
  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDesignJobId !== this.props.selectedDesignJobId) {
      this.init();
    }
  }

  getJobsList = jobs => {
    const { selectedJobId } = this.props;
    return jobs.map(job => {
      const label = this.getJobLabel(job);
      return {
        id: job.id,
        text: label,
        isSelected: job.id === selectedJobId
      };
    });
  };

  getJobLabel = job => {
    return job ? `${job.name} - ${job.status}` : "";
  };

  /**
   * it sets the selected job id in the state
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onJobSelect = selectedOptionIndex => {
    const { selectJob } = this.props;
    const selectedJobId = parseInt(
      this.props.analysisJobs[selectedOptionIndex].id,
      10
    );
    selectJob(selectedJobId);
  };

  /**
   * validates the currently selected analysis job id to make sure it is remembered
   * and it makes sense.
   */
  init() {
    const {
      analysisJobs,
      selectedJobId,
      selectedDesignJobId,
      selectJob
    } = this.props;

    const aDesignJobIsSelected = selectedDesignJobId !== null;
    const thereIsAnalysisJob = analysisJobs.length > 0;
    const thereIsAnalysisJobThatBelongsToSelectedDesignJob =
      aDesignJobIsSelected &&
      thereIsAnalysisJob &&
      analysisJobs.find(job => job.design_job === selectedDesignJobId);
    const thereIsNoSelectedAnalysisJob = selectedJobId === null;
    const selectedAnalysisJobExists =
      !thereIsNoSelectedAnalysisJob &&
      analysisJobs.find(job => job.id === selectedJobId);
    const selectedAnalysisJobBelongsToSelectedDesignJob =
      selectedAnalysisJobExists &&
      analysisJobs.find(job => job.id === selectedJobId).design_job ===
        selectedDesignJobId;

    if (
      aDesignJobIsSelected &&
      thereIsAnalysisJobThatBelongsToSelectedDesignJob &&
      (thereIsNoSelectedAnalysisJob ||
        !selectedAnalysisJobExists ||
        !selectedAnalysisJobBelongsToSelectedDesignJob)
    ) {
      const last = [...analysisJobs]
        .reverse()
        .find(job => job.design_job === selectedDesignJobId);
      selectJob(last.id);
    } else if (
      !aDesignJobIsSelected ||
      !thereIsAnalysisJobThatBelongsToSelectedDesignJob
    ) {
      selectJob(null);
    }
  }

  render() {
    const { analysisJobs, classes, selectedJobId } = this.props;
    return (
      <div className={classes.drillDown}>
        <FormLabel>Analysis Job</FormLabel>
        <DrilldownInput
          name="AnalysisJob"
          marginTop={10}
          onClose={(value, data) => {}}
          options={[this.getJobsList(analysisJobs)]}
          showCount={false}
          value={
            selectedJobId
              ? this.getJobLabel(
                  analysisJobs.filter(job => job.id === selectedJobId)[0]
                )
              : ""
          }
          onSelect={this.onJobSelect}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedJobId: AnalysisSelector.getSelectedJobId(state),
    selectedDesignJobId: DesignSelector.getSelectedJobId(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectJob: jobId => dispatch(AnalysisAction.selectJob(jobId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AnalysisJobDropdown));
