import React from "react";
import { IconButton, Button } from "@material-ui/core";
// import ExposureIcon from "@material-ui/icons/Exposure";
import TextField from "@material-ui/core/TextField";
import NumberInput from "components/NumberInput/NumberInput";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

export default class CustomizePlotRangeDialog extends React.Component {
  state = {
    xAxisRange: this.props.layout?.xaxis?.range?.map(n => Number(n).toFixed(2)),
    yAxisRange: this.props.layout?.yaxis?.range?.map(n => Number(n).toFixed(2)),
    zDataRange: this.props.zDataRange?.map(n => Number(n).toFixed(2)),
    zminError: null
  };

  changeValue(attribute, value) {
    let xAxisRange = [...this.state.xAxisRange];
    let yAxisRange = [...this.state.yAxisRange];
    let zDataRange = null;

    if (this.state.zDataRange) zDataRange = [...this.state.zDataRange];

    // eslint-disable-next-line default-case
    switch (attribute) {
      case "xmin":
        xAxisRange[0] = value;
        this.setState({ xAxisRange });
        break;
      case "xmax":
        xAxisRange[1] = value;
        this.setState({ xAxisRange });
        break;
      case "ymin":
        yAxisRange[0] = value;
        this.setState({ yAxisRange });
        break;
      case "ymax":
        yAxisRange[1] = value;
        this.setState({ yAxisRange });
        break;
      case "zmin":
        zDataRange[0] = value;
        this.setState({ zDataRange });
        break;
      case "zmax":
        zDataRange[1] = value;
        this.setState({ zDataRange });
        break;
    }
  }

  updateValue = () => {
    const [zmin, zmax] = this.state.zDataRange || [];

    if (zmin !== undefined && zmax !== undefined) {
      if (Number(zmin) > Number(zmax)) {
        this.setState({ zminError: "Z min cannot be greater than Z max" });
        return;
      } else {
        this.setState({ zminError: null, zmaxError: null });
      }
    }

    if (this.state.zDataRange) {
      this.props.handleCustomRange(
        this.state.xAxisRange,
        this.state.yAxisRange,
        this.state.zDataRange
      );
    } else {
      this.props.handleCustomRange(
        this.state.xAxisRange,
        this.state.yAxisRange
      );
    }

    this.props.handleClose();
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Custom Axes Range</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Input the range to customize the range of axes in the plot
            </DialogContentText>
            {this.state.xAxisRange && this.state.yAxisRange && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NumberInput
                    test-data="xyRangeFields"
                    value={this.state.xAxisRange[0]}
                    name="xmin"
                    label="X min"
                    allowNegative
                    onChange={e => this.changeValue("xmin", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    test-data="xyRangeFields"
                    value={this.state.xAxisRange[1]}
                    name="xmax"
                    label="X max"
                    allowNegative
                    onChange={e => this.changeValue("xmax", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    test-data="xyRangeFields"
                    value={this.state.yAxisRange[0]}
                    name="ymin"
                    label="Y min"
                    allowNegative
                    onChange={e => this.changeValue("ymin", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    test-data="xyRangeFields"
                    value={this.state.yAxisRange[1]}
                    name="ymax"
                    label="Y max"
                    allowNegative
                    onChange={e => this.changeValue("ymax", e.target.value)}
                  />
                </Grid>
              </Grid>
            )}

            {this.state.zDataRange && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <NumberInput
                    test-data="zRangeFields"
                    value={this.state.zDataRange[0]}
                    name="zmin"
                    label="Z min"
                    allowNegative
                    onChange={e => this.changeValue("zmin", e.target.value)}
                  />
                  {this.state.zminError && (
                    <p style={{ color: "red" }}>{this.state.zminError}</p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <NumberInput
                    test-data="zRangeFields"
                    value={this.state.zDataRange[1]}
                    name="zmax"
                    label="Z max"
                    allowNegative
                    onChange={e => this.changeValue("zmax", e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="red">
              Cancel
            </Button>
            <Button
              test-data="updateBtn"
              onClick={this.updateValue}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
