/**
 * @constant
 * @typedef {Object} FamilyActionType
 * @property {String} UPSERT_FAMILIES - tells the reducer to set a list of families
 * @property {String} DELETE_FAMILY - tells the reducer to delete a family
 * @property {String} UPDATE_FAMILY - tells the reducer to update a family
 * @property {String} SET_MEMBER_WIZARD_OPEN - tells the reducer to open the member wizard dialog
 * @property {String} UPSERT_FAMILY_MEMBERS - tells the reducer to insert or update family members
 * @property {String} DELETE_FAMILY_MEMBER - tells the reducer to delete a family member
 * @global
 * it defines family's type of actions.
 */
export const actionType = Object.freeze({
  UPSERT_FAMILIES: "UPSERT_FAMILIES",
  DELETE_FAMILY: "DELETE_FAMILY",
  UPDATE_FAMILY: "UPDATE_FAMILY",
  SET_MEMBER_WIZARD_OPEN: "SET_MEMBER_WIZARD_OPEN",
  UPSERT_FAMILY_MEMBERS: "UPSERT_FAMILY_MEMBERS",
  DELETE_FAMILY_MEMBER: "DELETE_FAMILY_MEMBER"
});

/**
 * A class created to create family actions to be dispatched to redux. It is already tested with
 * Family reducer, so it does not need to be unit tested.
 */
export default class FamilyAction {
  static upsertFamilies = value => ({
    type: actionType.UPSERT_FAMILIES,
    payload: value
  });

  static upsertFamilyMembers = familyMembers => ({
    type: actionType.UPSERT_FAMILY_MEMBERS,
    payload: familyMembers
  });

  static deleteFamily = familyId => ({
    type: actionType.DELETE_FAMILY,
    payload: familyId
  });

  static updateFamily = value => ({
    type: actionType.UPDATE_FAMILY,
    payload: value
  });

  static setMemberWizardOpen = data => ({
    type: actionType.SET_MEMBER_WIZARD_OPEN,
    payload: data
  });

  static deleteFamilyMember = memberId => ({
    type: actionType.DELETE_FAMILY_MEMBER,
    payload: memberId
  });
}
