import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class GenericSelect extends React.Component {
  render() {
    const {
      labelText,
      options,
      handleChange,
      selectedValue,
      keyName
    } = this.props;

    return (
      <div>
        <InputLabel>{labelText}</InputLabel>
        <Select
          value={selectedValue}
          onChange={event => handleChange(event, keyName)}
          input={<OutlinedInput name={keyName} />}
        >
          {options.map(({ value, label }) => (
            <MenuItem value={label} key={value}>{label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}

export default GenericSelect;
