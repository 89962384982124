/**
 * A selector class for the material state
 * @author Akira Kotsugai
 */
export default class SimulationSettingsSelector {
  /**
   * it selects the maximum diffraction orders from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the maximum diffraction orders
   */
  static getMaxDiffractionOrders = state =>
    state.simulationSettings.entities.maxDiffractionOrders;

  /**
   * it selects the editing maximum diffraction orders from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the editing maximum diffraction orders
   */
  static getEditingMaxDiffractionOrders = state =>
    state.simulationSettings.ui.editingMaxDiffractionOrders;

  /**
   * it selects the probes from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the probes
   */
  static getFieldProbes = state => state.simulationSettings.fieldProbes;

  /**
   * it selects the selected probe id from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the selected probe id
   */
  static getSelectedFieldProbeId = state =>
    state.simulationSettings.selectedFieldProbeId;

  /**
   * it selects the editing probe from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the editing probe
   */
  static getFieldProbeBeingEditedId = state =>
    state.simulationSettings.fieldProbeBeingEditedId;

  /**
   * it selects the editing simulation description from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the editing probe
   */
  static getEditingSimulationDescription = state =>
    state.simulationSettings.ui.editingSimulationDescription;

  static getSweptVariables = state => state.simulationSettings.sweptVariables;
}
