/**
 * A selector class for the setpoint state
 */
export default class SetPointSelector {
  /**
   * it selects the setpoints from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the setpoints
   */
  static getSetPoints = state => state.setpoint.entities.setPoints;
}
