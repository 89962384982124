/**
 * @constant
 * @typedef {Object} DesignActionType
 * @property {String} SET_DESIGN_JOBS - tells the reducer to set the design jobs
 * @property {String} UPSERT_DESIGN_JOBS - tells the reducer that to update the design tasks in the store
 * @property {String} SELECT_DESIGN_JOB - tells the reducer to select a design task in the store
 * @property {String} RESET_JOBS - tells the reducer to reset jobs
 * @property {String} DELETE_DESIGN_JOBS - tells the reducer to delete design jobs
 * @property {String} UPDATE_OVERVIEW_FILTERS - tells the reducer to update the design job overview filters
 * @global
 * it defines design's type of actions.
 */
export const actionType = Object.freeze({
  SET_DESIGN_JOBS: "SET_DESIGN_JOBS",
  UPSERT_DESIGN_JOBS: "UPSERT_DESIGN_JOBS",
  SELECT_DESIGN_JOB: "SELECT_DESIGN_JOB",
  RESET_JOBS: "RESET_JOBS",
  DELETE_DESIGN_JOBS: "DELETE_DESIGN_JOBS",
  UPDATE_OVERVIEW_FILTERS: "UPDATE_DESIGN_OVERVIEW_FILTERS"
});

/**
 * A class created to create design actions to be dispatched to redux. It is already tested with
 * design reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link DesignActionType} and some data.
 */
export default class DesignAction {
  static updateOverviewFilters = filters => ({
    type: actionType.UPDATE_OVERVIEW_FILTERS,
    payload: filters
  });

  static set = value => ({
    type: actionType.SET_DESIGN_JOBS,
    payload: value
  });
  static upsertJobs = jobs => ({
    type: actionType.UPSERT_DESIGN_JOBS,
    payload: jobs
  });
  static selectJob = id => ({
    type: actionType.SELECT_DESIGN_JOB,
    payload: id
  });
  static resetJobs = () => ({
    type: actionType.RESET_JOBS,
    payload: {}
  });
  static deleteJobs = jobIds => ({
    type: actionType.DELETE_DESIGN_JOBS,
    payload: jobIds
  });
}
