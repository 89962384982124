import React, { PureComponent } from "react";
import {
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import AssignmentIcon from "@material-ui/icons/Assignment";
import jsPDF from "jspdf";
import SimulationApi from "MetaCell/api/Simulation";
import UnselfishMenu from "components/UnselfishMenu/UnselfishMenu";

/**
 * a component to allow the user to export reports of a simulation.
 * @author Akira Kotsugai
 */
export default class ExportReportMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      simulationExportMenuAnchorEl: null
    };
  }

  exportData = () => {
    const { simulationName, simulationJobId } = this.props;
    SimulationApi.exportJobConfig(simulationJobId).then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${simulationName}.data.json`);
      link.click();
      window.URL.revokeObjectURL(url);
    });
  };

  /**
   * it generates a pdf document containing all plots the simulation has.
   */
  exportReport = async () => {
    const {
      simulationName,
      projectName,
      username,
      exportPlotReport
    } = this.props;
    var doc = new jsPDF();
    const dateAndTime = new Date().toDateString();
    await exportPlotReport(
      doc,
      projectName,
      simulationName,
      username,
      dateAndTime
    );
  };

  /**
   * it generates and downloads a pdf report containing information about the configuration
   * used in the simulation
   */
  exportConfigurationReport = async () => {
    const {
      simulationName,
      projectName,
      username,
      configuration,
      exportConfigurationReport
    } = this.props;
    var doc = new jsPDF();
    const dateAndTime = new Date().toDateString();
    await exportConfigurationReport(
      doc,
      configuration,
      projectName,
      simulationName,
      username,
      dateAndTime
    );
  };

  /**
   * it generates and downloads a pdf report containing information about the configuration and
   * the plots used in the simulation
   */
  exportFullReport = async () => {
    const {
      simulationName,
      projectName,
      username,
      configuration,
      exportFullReport
    } = this.props;
    var doc = new jsPDF();
    const dateAndTime = new Date().toDateString();
    const pageHeight = 297;
    const pageWidth = 210;
    await exportFullReport(
      doc,
      configuration,
      projectName,
      simulationName,
      username,
      dateAndTime
    );
  };

  render() {
    const { simulationExportMenuAnchorEl } = this.state;
    return (
      <>
        <Button
          name="ExportButton"
          test-data="mainExport"
          className={this.props.btnClassName}
          variant="contained"
          onClick={e =>
            this.setState({
              simulationExportMenuAnchorEl: e.currentTarget
            })
          }
          disabled={this.props.disabled}
        >
          Export
        </Button>
        <UnselfishMenu
          onClick={e => this.setState({ simulationExportMenuAnchorEl: null })}
          elevation={0}
          // anchorOrigin={{
          //   vertical: "bottom",
          //   horizontal: "center"
          // }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          anchorEl={simulationExportMenuAnchorEl}
          open={Boolean(simulationExportMenuAnchorEl)}
          PaperProps={{
            style: {
              border: "1px solid #d3d4d5"
            }
          }}
        >
          <MenuItem test-data="exportReportBtn" onClick={this.exportReport}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Plot Report" />
          </MenuItem>
          <MenuItem
            test-data="exportConfigReportBtn"
            onClick={this.exportConfigurationReport}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Configuration Report" />
          </MenuItem>
          <MenuItem
            test-data="exportFullReportBtn"
            onClick={this.exportFullReport}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Full Report" />
          </MenuItem>
          <MenuItem test-data="exportData" onClick={this.exportData}>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText primary="Configuration Data" />
          </MenuItem>
        </UnselfishMenu>
      </>
    );
  }
}
