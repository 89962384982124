/**
 * Instructions to be sent in the action to the directory explorer's reducer
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} MetaComponentExplorerActionType
 * @global
 */
export const actionType = Object.freeze({
  UPDATE_SELECTED_ITEM: "UPDATE_SELECTED_MC_ITEM",
  UPDATE_FORM: "UPDATE_MC_FORM",
  DELETE_MC_GROUP: "DELETE_MC_GROUP",
  DELETE_META_COMPONENT: "DELETE_META_COMPONENT",
  UPSERT_MC_GROUPS: "UPSERT_MC_GROUPS",
  UPSERT_META_COMPONENTS: "UPSERT_META_COMPONENTS",
  SET_DIRECTORY_EXPLORER: "SET_MC_DIRECTORY_EXPLORER",
  OPEN_META_COMPONENT: "OPEN_META_COMPONENT",
  EDIT_ITEM: "EDIT_MC_ITEM",
  UPDATE_ITEM_EDITING_VALUE: "UPDATE_MC_ITEM_EDITING_VALUE",
  APPLY_EDITED_MC_GROUP: "APPLY_EDITED_MC_GROUP",
  APPLY_EDITED_META_COMPONENT: "APPLY_EDITED_META_COMPONENT",
  CLEAN_DESIGN_TARGET_RELATION: "CLEAN_DESIGN_TARGET_RELATION",
  SELECT_AND_UNSELECT_DESIGN_TARGETS: "SELECT_AND_UNSELECT_DESIGN_TARGETS",
  DELETE_MC_GROUPS_AND_META_COMPONENTS: "DELETE_MC_GROUPS_AND_META_COMPONENTS"
});

/**
 * Actions creator for the directory explorer's reducer. it does not need to be unit tested
 * because directory explorer reducer already tests it.
 * consist of an instruction {@link MetaComponentExplorerActionType} and some data.
 */
export default class DirectoryExplorerAction {
  static selectAndUnselectDesignTargets = (
    metaComponentId,
    selectedDesignTargets,
    unselectedDesignTargets
  ) => ({
    type: actionType.SELECT_AND_UNSELECT_DESIGN_TARGETS,
    payload: {
      metaComponentId,
      selectedDesignTargets,
      unselectedDesignTargets
    }
  });

  static cleanDesignTargetRelations = designTargetId => ({
    type: actionType.CLEAN_DESIGN_TARGET_RELATION,
    payload: designTargetId
  });

  static updateSelectedItem = item => ({
    type: actionType.UPDATE_SELECTED_ITEM,
    payload: item
  });

  static openMetaComponent = id => ({
    type: actionType.OPEN_META_COMPONENT,
    payload: id
  });

  static updateForm = value => ({
    type: actionType.UPDATE_FORM,
    payload: value
  });

  static upsertMCGroups = mcGroups => ({
    type: actionType.UPSERT_MC_GROUPS,
    payload: mcGroups
  });

  static upsertMetaComponents = metaComponents => ({
    type: actionType.UPSERT_META_COMPONENTS,
    payload: metaComponents
  });

  static deleteMCGroupsAndMetaComponents = items => ({
    type: actionType.DELETE_MC_GROUPS_AND_META_COMPONENTS,
    payload: items
  });

  static deleteMCGroup = id => ({
    type: actionType.DELETE_MC_GROUP,
    payload: id
  });

  static deleteMetaComponent = id => ({
    type: actionType.DELETE_META_COMPONENT,
    payload: id
  });

  static editItem = item => ({
    type: actionType.EDIT_ITEM,
    payload: item
  });

  static updateEditingValue = value => ({
    type: actionType.UPDATE_ITEM_EDITING_VALUE,
    payload: value
  });

  static applyEditedMCGroup = project => ({
    type: actionType.APPLY_EDITED_MC_GROUP,
    payload: project
  });

  static applyEditedMetaComponent = metaComponent => ({
    type: actionType.APPLY_EDITED_META_COMPONENT,
    payload: metaComponent
  });

  static set = (mcGroups, metaComponents) => ({
    type: actionType.SET_DIRECTORY_EXPLORER,
    payload: { mcGroups, metaComponents }
  });
}
