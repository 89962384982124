import React, { PureComponent } from "react";
import { Dialog } from "@material-ui/core";

/**
 * a dialog component that does not block the rest of the screen and the main scroll bar
 * is still visible
 * @author Akira
 */
class UnselfishDialog extends PureComponent {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Dialog {...rest} disableScrollLock={true} disableBackdropClick>
        {children}
      </Dialog>
    );
  }
}

export default UnselfishDialog;
