import Axios from "axios";
import SetPointAction from "MetaComponent/actions/SetPoint";
import GenericApi from "Api";

export default class SetPointApi {
  /**
   * it generates the url to the setpoint api
   * @param {Number} id - the setpoint id if there is one
   * @return {String} - the generated setpoint endpoint url
   */
  static setPointsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/setpoints/${id}`;

  /**
   * it can fetch all the existing setpoints in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchSetPoints = (id = "") => {
    return dispatch => {
      const url = this.setPointsUrl(id);
      return Axios.get(url).then(res => {
        const setPoints = res.data;
        dispatch(SetPointAction.set(setPoints));
        return setPoints;
      });
    };
  };

  /**
   * it makes a request to change field values of an existing setpoint
   * @param {*} id - the setpoint
   * @param {Object} newProps - the new fields values
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static updateSetPoint = (id, newProps) => dispatch =>
    Axios.patch(this.setPointsUrl(id), newProps)
      .then(res => res.data)
      .then(updatedDT => dispatch(SetPointAction.upsert([updatedDT])));

  /**
   * it makes a request to create a setpoint in the api's database
   * @param {Object} setPoint - the object contaning the properties
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static createSetPoint = setPoint => dispatch =>
    Axios.post(this.setPointsUrl(), setPoint)
      .then(res => res.data)
      .then(newSetPoint => {
        dispatch(SetPointAction.upsert([newSetPoint]));
        return newSetPoint.id;
      });

  static deleteSetPoint = setPointId => dispatch =>
    Axios.delete(this.setPointsUrl(setPointId))
      .then(() => dispatch(SetPointAction.delete(setPointId)))
      .catch(error => console.log("error set point API:", error.message));
}
