import React, { PureComponent } from "react";
import { Grid, Button, IconButton, withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import ItemOptions from "../ItemOptions/ItemOptions";

const styles = theme => ({
  iconButton: {
    padding: 5
  }
});

/**
 * the structure item in the structure combination
 * @author Akira Kotsugai
 */
export class StructureItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false
    };
  }

  selectStructure = event => {
    this.setState({ editing: false });
    this.props.onEdit(this.props.itemIndex, event.target.value);
  };

  showParams = () => {
    this.props.showParams(this.props.itemIndex);
  };

  render = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={2}>
        {this.state.editing ? (
          <ItemOptions
            show={this.state.editing}
            handleSelect={this.selectStructure}
            options={this.props.options.map(option => ({
              name: option,
              label: option
            }))}
            handleClose={() => this.setState({ editing: false })}
          />
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }} />
              {this.props.onDelete && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.props.onDelete(this.props.itemIndex)}
                  test-data="deleteStructureBtn"
                  disabled={this.props.disabled}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {this.props.onEdit && (
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.setState({ editing: true })}
                  test-data="editStructureBtn"
                  disabled={this.props.disabled}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  style={{
                    width: "100%",
                    padding: 5,
                    backgroundColor: this.props.selected && "grey"
                  }}
                  variant="outlined"
                  color="inherit"
                  onClick={this.showParams}
                >
                  <span>{this.props.structure}</span>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
}

StructureItem.propTypes = {
  /**
   * structure name
   */
  structure: PropTypes.string.isRequired,
  /**
   * possible parameterized structures
   */
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * callback for deleting an item
   */
  onDelete: PropTypes.func,
  /**
   * callback for editing an item
   */
  onEdit: PropTypes.func.isRequired,
  /**
   * callback for showing the params of a structure
   */
  showParams: PropTypes.func.isRequired,
  /**
   * index of the item in the structure combination
   */
  itemIndex: PropTypes.number.isRequired
};

export default withStyles(styles)(StructureItem);
