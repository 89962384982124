import Axios from "axios";
import SelectedDesignTargetAction from "MetaComponent/actions/SelectedDesignTarget";
import DirectoryExplorerAction from "MetaComponent/actions/DirectoryExplorer";
import GenericApi from "Api";

export default class SelectedDesignTargetApi {
  /**
   * it generates the url to the selected design target api
   * @param {Number} id - the selected design target id if there is one
   * @return {String} - the generated selected design target endpoint url
   */
  static selectedDesignTargetsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/selecteddesigntargets/${id}`;

  /**
   * it generates the url to the selected design target api filtered by meta component
   * @param {Number} metaComponentId - the id of the meta component
   * @return {String} - the generated selected design target endpoint url
   */
  static selectedDesignTargetsByMetaComponentUrl = metaComponentId =>
    `${GenericApi.getBaseUrl()}/selecteddesigntargets/?meta_component=${metaComponentId}`;

  /**
   * it can fetch all the existing selected design targets in the api's database
   * or filtered by meta component
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchSelectedDesignTargets = metaComponentId => {
    return dispatch => {
      const url = metaComponentId
        ? SelectedDesignTargetApi.selectedDesignTargetsByMetaComponentUrl(
            metaComponentId
          )
        : SelectedDesignTargetApi.selectedDesignTargetsUrl();
      return Axios.get(url)
        .then(res => {
          const selectedDesignTargets = res.data;
          dispatch(SelectedDesignTargetAction.set(selectedDesignTargets));
          return selectedDesignTargets;
        })
        .catch(error => {
          console.log("Design targets error from API: ", error.message);
        });
    };
  };

  /**
   * it makes a request to change field values of an existing selected design target
   * @param {*} id - the selected design target
   * @param {Object} newProps - the new fields values
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static updateSelectedDesignTarget = (id, newProps) => dispatch =>
    Axios.patch(SelectedDesignTargetApi.selectedDesignTargetsUrl(id), newProps)
      .then(res => res.data)
      .then(updatedDT =>
        dispatch(SelectedDesignTargetAction.upsert([updatedDT]))
      );

  /**
   * it makes a request to create a selected design target in the api's database
   * @param {Number} designTargetId - the design target id
   * @param {Number} metaComponentId - the meta component id
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static createSelectedDesignTarget = (
    designTargetId,
    metaComponentId
  ) => dispatch =>
    Axios.post(SelectedDesignTargetApi.selectedDesignTargetsUrl(), {
      design_target: designTargetId,
      meta_component: metaComponentId
    })
      .then(res => res.data)
      .then(newSelectedDesignTarget => {
        dispatch(SelectedDesignTargetAction.upsert([newSelectedDesignTarget]));
        dispatch(
          DirectoryExplorerAction.selectAndUnselectDesignTargets(
            metaComponentId,
            [newSelectedDesignTarget.id],
            []
          )
        );
        return newSelectedDesignTarget.id;
      });

  /**
   * it makes requests to create and delete selected design targets in the api's database
   * @param {Number[]} designTargetIds - the design targets to create the sdt from
   * @param {Number} metaComponentId - the meta component to create the sdt from
   * @param {Number[]} sdtIdsToDelete - the sdt to be deleted
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static createAndDeleteSelectedDesignTargets = (
    designTargetIds,
    metaComponentId,
    sdtIdsToDelete
  ) => async dispatch => {
    for (const sdtId of sdtIdsToDelete) {
      await Axios.delete(
        SelectedDesignTargetApi.selectedDesignTargetsUrl(sdtId)
      );
    }
    let createdSDTs = [];
    for (const dtId of designTargetIds) {
      const sdt = await Axios.post(
        SelectedDesignTargetApi.selectedDesignTargetsUrl(),
        { design_target: dtId, meta_component: metaComponentId }
      ).then(res => res.data);
      createdSDTs.push(sdt);
    }
    dispatch(SelectedDesignTargetAction.upsert(createdSDTs));
    dispatch(
      DirectoryExplorerAction.selectAndUnselectDesignTargets(
        metaComponentId,
        createdSDTs.map(sdt => sdt.id),
        sdtIdsToDelete
      )
    );
    dispatch(SelectedDesignTargetAction.delete(sdtIdsToDelete));
  };
}
