import React, { PureComponent } from "react";
import DirectoryExplorer from "components/DirectoryExplorer/DirectoryExplorer";
import { connect } from "react-redux";
import Api from "MetaComponent/api/DirectoryExplorer";
import MetaComponentApi from "MetaComponent/api/MetaComponent";
import UserApi from "BaseApp/api/User";
import Selector from "MetaComponent/selectors/DirectoryExplorer";
import Action from "MetaComponent/actions/DirectoryExplorer";
import UserSelector from "BaseApp/selectors/User";
import GenericApi from "Api";

/**
 * a component to display meta component and meta component groups.
 * it connects to redux, and manages all the data that is passed
 * to the generic directory explorer
 * @author Akira Kotsugai
 */
export class Explorer extends PureComponent {
  /**
   * it is supposed to be passed all the way down to meta component to allow items
   * to open the corresponding meta component, it also saves the open simulation as the last viewed
   * @callback
   * @param {Number} id - the simulation id
   */
  openMetaComponent = id => {
    this.props.openMetaComponent(id);
  };

  /**
   * it fetches meta components and meta component groups.
   * it is supposed to be passed to the directory explorer
   * @callback
   */
  fetchData = () => {
    const { fetchMCGroupsAndMetaComponentsAction } = this.props;
    fetchMCGroupsAndMetaComponentsAction();
  };

  /**
   * it fetches groups and meta components.
   * it is supposed to be used as the import success callback in the directory explorer
   * @callback
   */
  onImportSuccess = () => {
    this.props.fetchMCGroupsAndMetaComponentsAction();
  };

  /**
   * it gets the project id from the given simulation id
   * @param {Number} simulationId - the simulation
   * @returns {Number} the project Id
   */
  getProjectId = simulationId => {
    const { mcGroups } = this.props;
    const project = Object.values(mcGroups.byId).find(({ simulation_set }) =>
      simulation_set.includes(simulationId)
    );
    return (project && project.id) || -1;
  };

  /**
   * it generates a url to import a meta component
   * @return {String} the url
   */
  getImportUrl = () => {
    return `${GenericApi.getBaseUrl()}/import_metacomponent`;
  };

  /**
   * it updates the meta component name
   * @param {Number} id - the meta component to be updated
   * @param {String} name - the new name
   * @callback
   */
  updateMetaComponent = (id, name) => {
    this.props.updateMetaComponent(id, { name });
  };

  /**
   * it updates the meta component group name
   * @param {Number} id - the group to be updated
   * @param {String} name - the new name
   * @callback
   */
  updateMCGroup = (id, name) => {
    this.props.updateMCGroup(id, { name });
  };

  render() {
    const {
      saveEditings,
      addMCGroupItem,
      addMetaComponentItem,
      form,
      selectedItem,
      mcGroups,
      metaComponents,
      editItem,
      updateForm,
      openMetaComponentId,
      updateSelectedItem,
      updateEditingValue,
      editingItem,
      deleteMCGroupsAndMetaComponents
    } = this.props;
    return (
      <DirectoryExplorer
        saveEditings={saveEditings}
        addProjectItem={addMCGroupItem}
        addSimulationItem={addMetaComponentItem}
        form={form}
        selectedItem={selectedItem}
        projects={mcGroups}
        simulations={metaComponents}
        grouperType={"mcGroup"}
        finalItemType={"metacomponent"}
        deleteProjectsAndSimulations={deleteMCGroupsAndMetaComponents}
        editItem={editItem}
        openSimulation={this.openMetaComponent}
        updateForm={updateForm}
        fetchData={this.fetchData}
        getImportUrl={this.getImportUrl}
        onImportSuccess={this.onImportSuccess}
        openSimulationId={openMetaComponentId}
        exportSimulation={MetaComponentApi.export}
        updateSimulation={this.updateMetaComponent}
        updateProject={this.updateMCGroup}
        updateSelectedItem={updateSelectedItem}
        updateEditingValue={updateEditingValue}
        editingItem={editingItem}
        // selectedItem={selectedItem}
      />
    );
  }
}

const mapStateToProps = state => ({
  mcGroups: Selector.getMCGroups(state),
  metaComponents: Selector.getMetaComponents(state),
  selectedItem: Selector.getSelectedItem(state),
  form: Selector.getForm(state),
  userSettings: UserSelector.getUserSettings(state),
  openMetaComponentId: Selector.getMetaComponentOpenId(state),
  // selectedItem: Selector.getSelectedItem(state),
  editingItem: Selector.getEditingItem(state)
});

const mapDispatchToProps = dispatch => {
  return {
    addMCGroupItem: (item, selectedItem) =>
      dispatch(Api.addMCGroup(item, selectedItem)),
    addMetaComponentItem: (item, selectedItem) =>
      dispatch(Api.addMetaComponent(item, selectedItem)),
    editItem: id => dispatch(Action.editItem(id)),
    openMetaComponent: simulationId =>
      dispatch(Action.openMetaComponent(simulationId)),
    postUserSettings: data => dispatch(UserApi.postUserSettings(data)),
    updateForm: value => dispatch(Action.updateForm(value)),
    fetchMCGroupsAndMetaComponentsAction: simulationOpenId =>
      dispatch(Api.fetch(simulationOpenId)),
    updateMetaComponent: (id, properties) =>
      dispatch(Api.updateMetaComponent(id, properties)),
    updateMCGroup: (id, properties) =>
      dispatch(Api.updateMCGroup(id, properties)),
    updateSelectedItem: itemType =>
      dispatch(Action.updateSelectedItem(itemType)),
    updateEditingValue: value => dispatch(Action.updateEditingValue(value)),
    deleteMCGroupsAndMetaComponents: items =>
      dispatch(Api.deleteMCGroupsAndMetaComponents(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Explorer);
