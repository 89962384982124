import React, { Component } from "react";
import AuthLayout from "./AuthLayout";
import DocumentTitle from "react-document-title";
import { Button, withStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const styles = {
  content: {
    textAlign: "center"
  },
  text: {
    padding: "20px 0"
  }
};

const AdapterLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

/**
 * A component to inform the user about the manual activation
 * of the account
 */
class RegisterComplete extends Component {
  render = () => {
    const { classes } = this.props;
    return (
      <DocumentTitle title="Register: complete">
        <AuthLayout title="Thank you for Registering!">
          <div className={classes.content}>
            <div className={classes.text}>
              Your registration was successful, but your account is not active
              yet. Our customer support team will review your application and
              follow up within 5 business days.
            </div>
            <div>
              <Button
                test-data="continue"
                variant="contained"
                color="primary"
                component={AdapterLink}
                to="/login"
              >
                Continue
              </Button>
            </div>
          </div>
        </AuthLayout>
      </DocumentTitle>
    );
  };
}

export default withStyles(styles)(RegisterComplete);
