import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import UnselfishDialog from "components/UnselfishDialog/UnselfishDialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AccountForm from "./components/AccountForm/AccountForm";
import UserForm from "./components/UserForm/UserForm";
import { connect } from "react-redux";
import UserSelector from "BaseApp/selectors/User";
import UserAction from "BaseApp/actions/User";
import UserApi from "BaseApp/api/User";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

/**
 * @constant
 * @typedef {Object} AccountDialogContent
 * @property {String} ACCOUNT - tells the dialog that account details should be displayed
 * @property {String} USER - tells the dialog that user details should be displayed
 * @global
 * it defines the content of the AccountDialog.
 */
export const contentType = Object.freeze({
  ACCOUNT: "ACCOUNT",
  USER: "USER"
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * This component was created to allow the user to manage information related to his/her account.
 * the component content varies. See {@link AccountDialogContent}.
 * @author Akira Kotsugai
 * @param {Object} props - props passed by parent component and redux
 */
export function AccountDialog(props) {
  const classes = useStyles();
  const [content, setContent] = React.useState(contentType.ACCOUNT);

  /**
   * it can be passed to child components and tells redux to close the dialog
   * @callback
   */
  const handleClose = () => {
    props.closeAccountDialogAction();
  };

  /**
   * It is used by the render method and decides on which content to show.
   * @return {Component} - the content
   */
  const getContent = () => {
    if (content === contentType.ACCOUNT) {
      return (
        <AccountForm
          account={{ ...props.user, profile: undefined }}
          onSaveEditing={handleSave}
        />
      );
    } else if (content === contentType.USER) {
      return (
        <UserForm profile={props.user.profile} onSaveEditing={handleSave} />
      );
    }
  };

  /**
   * it calls the dispatcher that updates the user profile passing the new field value
   * @param {String} field - the profile field
   * @param {String} value - the new field value
   */
  const handleSave = (field, value) => {
    props.updateUserProfileAction(field, value);
  };

  return (
    <UnselfishDialog
      fullWidth
      open={props.accountDialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="xs"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            test-data="closeBtn"
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Tabs value={content} indicatorColor="secondary" textColor="inherit">
            <Tab
              test-data="accountTab"
              label="Account"
              value={contentType.ACCOUNT}
              onClick={e => setContent(contentType.ACCOUNT)}
            />
            <Tab
              test-data="userTab"
              label="User"
              value={contentType.USER}
              onClick={e => setContent(contentType.USER)}
            />
          </Tabs>
        </Toolbar>
      </AppBar>
      {getContent()}
    </UnselfishDialog>
  );
}

const mapStateToProps = state => {
  return {
    accountDialogOpen: UserSelector.getAccountDialogOpen(state),
    user: UserSelector.getUser(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeAccountDialogAction: () =>
      dispatch(UserAction.setAccountDialogOpen(false)),
    updateUserProfileAction: (field, value) =>
      dispatch(UserApi.updateProfile(field, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(AccountDialog));
