import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "components/Spinner/Spinner";
import ErrorBadge from "components/ErrorBadge/ErrorBadge";

const useStyles = makeStyles(theme => ({
  right: {
    display: "flex",
    flexDirection: "column"
  },
  buttonMargin: {
    marginBottom: "15px"
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit
  },
  span: {
    display: "grid"
  }
}));

/**
 * A component for design canvas action buttons
 * @param {Object} props - the object properties
 * @return {Component}
 */

const DesignJobActions = props => {
  const classes = useStyles();
  const {
    disabledActions,
    loadingActions,
    allowRequestFabrication,
    selectedJobId,
    designJobs
  } = props;

  const designJobStatus = ["FAILED", "ERROR", "STOPPED"];
  const btnAction = designJobStatus.filter(status =>
    disabledActions.includes(status)
  );

  return (
    <div className={classes.right}>
      <Tooltip title={"Start a new design job"}>
        <Button
          test-data="restartButton"
          name="RestartDesign"
          className={classes.buttonMargin}
          variant="contained"
          color="primary"
          onClick={props.showNewJobDialog}
        >
          Restart design
        </Button>
      </Tooltip>
      <Button
        test-data="errorsButton"
        name="Errors/Warnings"
        className={classes.buttonMargin}
        variant="contained"
        onClick={props.showResultsErrorsAndWarnings}
      >
        <ErrorBadge
          errors={selectedJobId && designJobs?.byId[selectedJobId].errors}
          warnings={selectedJobId && designJobs?.byId[selectedJobId].warnings}
          buttonLabel="Errors/Warnings"
        />
      </Button>

      {btnAction.length > 0 ? (
        <Tooltip
          test-data="btnAction"
          title={`The design has been ${btnAction}. Please rerun the design.`}
        >
          <span className={classes.span}>
            <Button
              test-data="reportButton"
              name="Report"
              className={classes.buttonMargin}
              variant="contained"
              onClick={props.getReport}
              disabled={disabledActions.indexOf("report") !== -1}
            >
              Report
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          test-data="reportButton"
          name="Report"
          className={classes.buttonMargin}
          variant="contained"
          onClick={props.getReport}
          disabled={disabledActions.indexOf("report") !== -1}
        >
          {loadingActions.includes("report") && (
            <Spinner className={classes.circularProgress} size={20} />
          )}
          Report
        </Button>
      )}

      {btnAction.length > 0 ? (
        <Tooltip
          title={`The design has been ${btnAction}. Please rerun the design.`}
        >
          <span className={classes.span}>
            <Button
              test-data="maskButton"
              name="Mask"
              className={classes.buttonMargin}
              variant="contained"
              onClick={props.getMask}
              disabled={disabledActions.indexOf("mask") !== -1}
            >
              Mask - GDS
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          test-data="maskButton"
          name="Mask"
          className={classes.buttonMargin}
          variant="contained"
          onClick={props.getMask}
          disabled={disabledActions.indexOf("mask") !== -1}
        >
          {loadingActions.includes("mask") && (
            <Spinner className={classes.circularProgress} size={20} />
          )}
          Mask - GDS
        </Button>
      )}

      {btnAction.length > 0 ? (
        <Tooltip
          title={`The design has been ${btnAction}. Please rerun the design.`}
        >
          <span className={classes.span}>
            <Button
              test-data="memberMapButton"
              name="Member Map"
              className={classes.buttonMargin}
              variant="contained"
              onClick={props.getMemberMap}
              disabled={disabledActions.indexOf("memberMap") !== -1}
            >
              Mask - JSON
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          test-data="memberMapButton"
          name="Member Map"
          className={classes.buttonMargin}
          variant="contained"
          onClick={props.getMemberMap}
          disabled={disabledActions.indexOf("memberMap") !== -1}
        >
          {loadingActions.includes("memberMap") && (
            <Spinner className={classes.circularProgress} size={20} />
          )}
          Mask - JSON
        </Button>
      )}

      {btnAction.length > 0 && allowRequestFabrication ? (
        <Tooltip
          title={`The design has been ${btnAction}. Please rerun the design.`}
        >
          <span className={classes.span}>
            <Button
              test-data="requestFabiractionButton"
              name="Request Fabrication"
              className={classes.buttonMargin}
              variant="contained"
              onClick={props.onRequestFabricationDialog}
              disabled={disabledActions.indexOf("requestFabrication") !== -1}
            >
              Request Fabrication
            </Button>
          </span>
        </Tooltip>
      ) : (
        allowRequestFabrication && (
          <Button
            test-data="requestFabiractionButton"
            name="Request Fabrication"
            className={classes.buttonMargin}
            variant="contained"
            color="primary"
            onClick={props.onRequestFabricationDialog}
            disabled={disabledActions.indexOf("requestFabrication") !== -1}
          >
            {loadingActions.includes("requestFabrication") && (
              <Spinner className={classes.circularProgress} size={20} />
            )}
            Request Fabrication
          </Button>
        )
      )}
    </div>
  );
};

export default DesignJobActions;
