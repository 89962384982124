import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 16,
    marginLeft: 3,
    color: "#3f51b5"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  }
}));

export default function IconTooltip({ text, image, secondaryImage }) {
  const classes = useStyles();

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [imagesAreLoaded, setImagesAreLoaded] = React.useState(false);

  React.useEffect(() => {
    if (image) {
      let preloadedImage = new Image();
      preloadedImage.onload = () => {
        if (secondaryImage) {
          let preloadedSecondaryImage = new Image();
          preloadedSecondaryImage.onload = () => {
            setImagesAreLoaded(true);
          };
          preloadedSecondaryImage.src = secondaryImage;
        } else {
          setImagesAreLoaded(true);
        }
      };
      preloadedImage.src = image;
    }
  });

  const handlePopoverOpen = event => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const open = Boolean(popoverAnchorEl);

  return (!image && !secondaryImage) || imagesAreLoaded ? (
    <>
      {text ? (
        <Tooltip title={text} interactive={true}>
          <HelpIcon
            className={classes.root}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
        </Tooltip>
      ) : (
        <HelpIcon
          className={classes.root}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      )}
      {image && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={open}
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <img
            test-data="image"
            src={image}
            alt=""
            style={{ maxWidth: 400, maxHeight: 400 }}
          />
          {secondaryImage && (
            <img
              test-data="secondaryImage"
              src={secondaryImage}
              alt=""
              style={{ maxWidth: 400, maxHeight: 400 }}
            />
          )}
        </Popover>
      )}
    </>
  ) : null;
}

IconTooltip.propTypes = {
  /**
   * the tooltip text
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
