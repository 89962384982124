import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import { itemType } from "components/DirectoryExplorer/components/ExplorerTree/ExplorerTree";
import ProjectItem from "../ProjectItem/ProjectItem";
import { withTreeItemHandlers } from "components/DirectoryExplorer/components/ExplorerTree/components/withTreeItemHandlers/withTreeItemHandlers";

/**
 * A component to display an item of type User {@link ExplorerTreeItemType} in the tree explorer
 * @author Akira Kotsugai
 * @param {Object} props - the object is expected to have a user, all projects, all simulations,
 * a style resolver, a focus handler and a function that can open simulations and the id of
 * the open simulation to be passed all the way down to simulations.
 * @return a tree item component
 */
export const UserItem = ({
  item,
  children,
  grandChildren,
  finalItemType,
  grouperType,
  getItemContentStyle,
  simulationOpener,
  openSimulationId,
  openSimulationRef,
  projectsRefs,
  updateProject,
  updateSimulation,
  updateSelectedItem,
  updateEditingValue,
  editingItem,
  selectedItem,
  handleClick
}) => {
  /**
   * it selects simulations from the given project
   * @param {Object} project - the given project
   * @return {Object[]} - the list of simulations
   */
  const getProjectSimulations = project => {
    return project[`${finalItemType}_set`].map(id => grandChildren.byId[id]);
  };

  return (
    <TreeItem
      test-data="user"
      classes={{ content: getItemContentStyle(item && item.id, itemType.USER) }}
      nodeId="user"
      id={item ? item.id : ""}
      text={item ? item.username : ""}
      label={item ? item.username : ""}
      type={itemType.USER}
      onClick={e => handleClick(e)}
    >
      {children.allIds.map(id => {
        const project = children.byId[id];
        return (
          <ProjectItem
            projectRef={projectsRefs[id]}
            key={id}
            item={project}
            children={getProjectSimulations(project)}
            simulationOpener={simulationOpener}
            openSimulationId={openSimulationId}
            openSimulationRef={openSimulationRef}
            grouperType={grouperType}
            updateSimulation={updateSimulation}
            save={updateProject}
            updateSelectedItem={updateSelectedItem}
            updateEditingValue={updateEditingValue}
            editingItem={editingItem}
            selectedItem={selectedItem}
          />
        );
      })}
    </TreeItem>
  );
};

export default withTreeItemHandlers(UserItem);
