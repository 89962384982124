/**
 * A class to make value selection from the design canvas in redux state more clear.
 */
export default class DesignSelector {
  /**
   * it selects design jobs from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the design jobs
   */
  static getDesignJobs = state => state.design.entities.designJobs;

  /**
   * it gets the selected job from the redux state
   * @param {Object} state - the redux state
   * @return {Object} the selected design job
   */
  static getSelectedJobId = state => state.design.ui.selectedJobId;

  /**
   * @param {*} state - the redux state
   * @return {Object} the filters for the design overview
   */
  static getOverviewFilters = state => state.design.ui.overviewFilters;
}
