import React, { PureComponent } from "react";
import GenericPlot from "components/Plot/Plot";
import PropTypes from "prop-types";

/**
 * the top view of the heatmap
 * @author Akira Kotsugai
 */
export default class HeatmapTop extends PureComponent {
  render() {
    return (
      <GenericPlot
        onClick={this.props.onHeatmapClick}
        data={this.props.getData("heatmap")}
        layout={this.props.layout}
        handleCustomRange={this.props.handleCustomRange}
        morePlotlyModeBarButtonsToAdd={this.props.morePlotlyModeBarButtonsToAdd}
        onGetUnwrappedOutputData={this.props.onGetUnwrappedOutputData}
      />
    );
  }
}

HeatmapTop.propTypes = {
  /**
   * on click callback
   */
  onHeatmapClick: PropTypes.func.isRequired,
  /**
   * the get data callback
   */
  getData: PropTypes.func.isRequired,
  /**
   * the layout object
   */
  layout: PropTypes.object.isRequired
};
