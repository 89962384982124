import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import FFWFTarget from "MetaComponent/containers/TargetCanvas/components/FarFieldWavefront/components/FFWFTarget/FFWFTarget";
import { isEqual } from "lodash";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import FFWTTargetSelector from "MetaComponent/selectors/FFWTTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";

/**
 * a class component to draw the FFWFTargetVisualisation
 */
export class FFWFTargetVisualisation extends PureComponent {
  /**
   * it takes only the differences between the original
   * and the editing ffwf target and calls an action to update these properties.
   * @param {Object} editingFFWFTarget - the editing ffwf target values
   * @callback
   */
  saveFFWFTarget = editingFFWFTarget => {
    const { ffwftargets, updateFFWFTargetAction } = this.props,
      { id } = editingFFWFTarget;

    const originalFFWFTarget = ffwftargets.byId[id],
      ffwfTargetPropertyNames = Object.keys(editingFFWFTarget);
    let objWithFFWFTargetChanges = {};
    ffwfTargetPropertyNames.forEach(property => {
      if (!isEqual(editingFFWFTarget[property], originalFFWFTarget[property])) {
        objWithFFWFTargetChanges[property] = editingFFWFTarget[property];
      }
    });
    updateFFWFTargetAction(id, objWithFFWFTargetChanges);
  };

  render = () => {
    const { ffwfTargetId, ffwftargets, isEditing } = this.props;
    return (
      <FFWFTarget
        ffwfTarget={ffwftargets.byId[ffwfTargetId]}
        onSave={this.saveFFWFTarget}
        showConfirmDialog={this.props.showConfirmDialog}
        loadWavefront={() => {}}
        isEditing={isEditing}
        rowLayout={true}
        sampleScripts={this.props.sampleScripts}
        useWavefrontInternally
      />
    );
  };
}

const mapState = state => ({
  ffwftargets: FFWTTargetSelector.getFFWTTargets(state)
});

const mapDispatch = dispatch => ({
  updateFFWFTargetAction: (id, properties) =>
    dispatch(DesignTargetApi.updateFFWFTarget(id, properties)),
  showConfirmDialog: (
    title,
    message,
    confirmAction,
    cancelAction,
    isReduxAction
  ) =>
    dispatch(
      ConfirmDialogAction.show(
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction
      )
    )
});

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(FFWFTargetVisualisation));
