import { combineReducers } from "redux";
import authReducer from "Auth/reducer";
import structureReducer from "MetaCell/reducers/structure";
import materialReducer from "MetaCell/reducers/material";
import familyReducer from "MetaCell/reducers/family";
import metaCellGlobalParametersReducer from "MetaCell/reducers/globalParameters";
import incidentLightReducer from "MetaCell/reducers/incidentLight";
import simulationSettingsReducer from "MetaCell/reducers/simulationSettings";
import metaCellExplorerReducer from "MetaCell/reducers/directoryExplorer";
import metaComponentExplorerReducer from "MetaComponent/reducers/directoryExplorer";
import metaComponentDesignTarget from "MetaComponent/reducers/designTarget";
import metaComponentSelectedDesignTarget from "MetaComponent/reducers/selectedDesignTarget";
import metaComponentFFWTTarget from "MetaComponent/reducers/ffwtTarget";
import confirmDialogReducer from "BaseApp/reducers/confirmDialog";
import userReducer from "BaseApp/reducers/user";
import { REMOVE_TOKENS } from "Auth/actions";
import navigationHistoryReducer from "BaseApp/reducers/navigationHistory";
import simulationReducer from "MetaCell/reducers/simulation";
import designReducer from "MetaComponent/reducers/design";
import setpointReducer from "MetaComponent/reducers/setpoint";
import script2DReducer from "MetaComponent/reducers/script2D";
import analysisReducer from "MetaComponent/reducers/analysis";
import rayTracingReducer from "MetaComponent/reducers/raytracing";
import odaReducer from "MetaComponent/reducers/oda";
import simulationTargetReducer from "MetaCell/reducers/simulationTarget";
import outputSettingReducer from "MetaCell/reducers/outputSetting";

const appReducer = combineReducers({
  auth: authReducer,
  structure: structureReducer,
  material: materialReducer,
  metaCellGlobalParameters: metaCellGlobalParametersReducer,
  incidentLight: incidentLightReducer,
  simulationSettings: simulationSettingsReducer,
  directoryExplorer: metaCellExplorerReducer,
  designTarget: metaComponentDesignTarget,
  selectedDesignTarget: metaComponentSelectedDesignTarget,
  mcExplorer: metaComponentExplorerReducer,
  confirmDialog: confirmDialogReducer,
  user: userReducer,
  navigationHistory: navigationHistoryReducer,
  simulation: simulationReducer,
  family: familyReducer,
  ffwtTarget: metaComponentFFWTTarget,
  design: designReducer,
  setpoint: setpointReducer,
  script2D: script2DReducer,
  analysis: analysisReducer,
  rayTracing: rayTracingReducer,
  oda: odaReducer,
  simulationTargets: simulationTargetReducer,
  outputSettings: outputSettingReducer
});

export default (state, action) => {
  if (action.type === REMOVE_TOKENS) {
    state = undefined;
  }

  return appReducer(state, action);
};
