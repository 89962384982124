import React, { Component } from "react";
import { axisBottom, axisLeft, select } from "d3";
import { scaleLinear } from "d3-scale";
import MouseTooltip from "react-sticky-mouse-tooltip";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { sendMessage } from "workerPool";
import uuid from "uuid/v4";

const styles = {
  main: {
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden"
  },
  tooltip: {
    zIndex: "1000",
    backgroundColor: "#fff",
    padding: "5px 10px",
    borderRadius: "3px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    minWidth: "120px"
  },
  axes: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%"
  },
  canvasParent: {
    lineHeight: 0,
    position: "absolute",
    cursor: "pointer"
  }
};

class Heatmap extends Component {
  mainRef;
  xAxisRef;
  yAxisRef;
  cells = [];
  offscreenCanvas;
  offscreenCanvasId;
  workerId;

  constructor(props) {
    super(props);
    this.state = {
      tooltip: null
    };
    this.mainRef = React.createRef();
    this.xAxisRef = React.createRef();
    this.yAxisRef = React.createRef();
  }

  componentDidMount() {
    this.getHeatmapData();
  }

  componentWillUnmount() {
    if (this.workerId) {
      this.workerRemoveHeatmapCanvas();
    }
  }

  componentDidUpdate = prevProps => {
    const zChanged = this.props.z !== prevProps.z;
    const widthChanged = this.props.width !== prevProps.width;
    const colorOptionsChanged =
      this.props.colorOptions !== prevProps.colorOptions;
    if (zChanged || widthChanged || colorOptionsChanged) {
      this.getHeatmapData();
    }
  };

  getHeatmapData = () => {
    const { x, y, z, colorOptions } = this.props,
      dimensions = this.getDimensions(),
      params = {
        x,
        y,
        z,
        colorOptions,
        ...dimensions
      };
    sendMessage(
      {
        action: "getHeatmapData",
        params
      },
      data => {
        this.handleWorkerMessage({ x, y, ...dimensions }, data);
      }
    );
  };

  handleWorkerMessage = (params, data) => {
    const { current: mainEl } = this.mainRef || {},
      canvasEl = mainEl && mainEl.querySelector("canvas");
    this.cells = data.cells;
    if (canvasEl) {
      this.renderAxis(params);
      if (typeof OffscreenCanvas === "undefined") {
        this.renderHeatmapCanvas(params, data, canvasEl);
      } else if (!this.offscreenCanvas) {
        this.workerAddHeatmapCanvas(params, data, canvasEl);
      } else {
        this.workerRenderHeatmapCanvas(params, data);
      }
    }
  };

  renderAxis = params => {
    const { x, y, canvasWidth, canvasHeight } = params,
      { current: xAxisEl } = this.xAxisRef || {},
      { current: yAxisEl } = this.yAxisRef || {};
    if (x && y && xAxisEl && yAxisEl) {
      const xScale = scaleLinear()
          .domain([x[0], x[x.length - 1]])
          .range([0, canvasWidth]),
        yScale = scaleLinear()
          .domain([y[y.length - 1], y[0]])
          .range([0, canvasHeight]),
        xAxis = axisBottom(xScale).tickFormat(val => val),
        yAxis = axisLeft(yScale).tickFormat(val => val);
      xAxis(select(xAxisEl));
      yAxis(select(yAxisEl));
    }
  };

  workerAddHeatmapCanvas = (params, data, canvasEl) => {
    this.offscreenCanvas = canvasEl.transferControlToOffscreen();
    this.offscreenCanvasId = uuid();
    const { workerId } = sendMessage(
      {
        action: "addHeatmapCanvas",
        params: {
          id: this.offscreenCanvasId,
          [this.offscreenCanvasId]: this.offscreenCanvas
        }
      },
      () => {
        this.workerRenderHeatmapCanvas(params, data);
      },
      [this.offscreenCanvas]
    );
    this.workerId = workerId;
  };

  workerRemoveHeatmapCanvas = () => {
    sendMessage(
      {
        action: "removeHeatmapCanvas",
        params: {
          id: this.offscreenCanvasId
        },
        workerId: this.workerId
      },
      () => {}
    );
  };

  workerRenderHeatmapCanvas = (params, data) => {
    const { pixels } = data;
    sendMessage(
      {
        action: "renderHeatmapCanvas",
        params: {
          id: this.offscreenCanvasId,
          pixels,
          ...params
        },
        workerId: this.workerId
      },
      () => {}
    );
  };

  renderHeatmapCanvas = (params, data, canvasEl) => {
    try {
      const { imageWidth, imageHeight } = params,
        { pixels } = data,
        imageData = new ImageData(pixels, imageWidth, imageHeight),
        canvasCtx = canvasEl.getContext("2d");
      canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height);
      canvasEl.width = imageWidth;
      canvasEl.height = imageHeight;
      canvasCtx.putImageData(imageData, 0, 0);
    } catch {}
  };

  handleMouseMove = mouseEvent => {
    const { getTooltip } = this.props;
    if (getTooltip) {
      const { current: mainEl } = this.mainRef || {},
        canvasEl = mainEl && mainEl.querySelector("canvas");
      if (canvasEl) {
        const canvasPosition = canvasEl.getBoundingClientRect(),
          x = mouseEvent.clientX - canvasPosition.x,
          y = mouseEvent.clientY - canvasPosition.y;
        let i, xStart, yStart, xEnd, yEnd;
        for (i = 0; i < this.cells.length; i++) {
          xStart = this.cells[i].x;
          yStart = this.cells[i].y;
          xEnd = xStart + this.cells[i].w;
          yEnd = yStart + this.cells[i].h;
          if (x >= xStart && x <= xEnd && y >= yStart && y <= yEnd) {
            this.setState({ tooltip: getTooltip(this.cells[i]) });
            return;
          }
        }
        this.setState({ tooltip: null });
      }
    }
  };

  handleMouseLeave = () => {
    this.setState({ tooltip: null });
  };

  getDimensions = () => {
    const props = this.props,
      noPadding = !props.x || !props.y,
      paddingLeft = noPadding ? 0 : 100,
      paddingRight = noPadding ? 0 : 30,
      paddingTop = noPadding ? 0 : 40,
      paddingBottom = noPadding ? 0 : 60,
      yLength = props.z.length,
      xLength = props.z[0].length,
      canvasCellWidth = (props.width - paddingLeft - paddingRight) / xLength,
      imageCellWidth = Math.ceil(canvasCellWidth),
      imageWidth = xLength * imageCellWidth,
      canvasWidth = xLength * canvasCellWidth,
      canvasCellHeight = props.height
        ? (props.height - paddingTop - paddingBottom) / yLength < 0
          ? canvasCellWidth / 10
          : (props.height - paddingTop - paddingBottom) / yLength
        : canvasCellWidth,
      imageCellHeight = Math.ceil(canvasCellHeight),
      imageHeight = imageCellHeight * yLength,
      canvasHeight = canvasCellHeight * yLength,
      height = canvasHeight + paddingTop + paddingBottom,
      width = canvasWidth + paddingLeft + paddingRight;

    return {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      imageCellWidth,
      imageCellHeight,
      canvasCellWidth,
      canvasCellHeight,
      canvasWidth,
      canvasHeight,
      width,
      height,
      imageWidth,
      imageHeight
    };
  };

  render = () => {
    const { tooltip } = this.state,
      { getTooltip, classes } = this.props,
      {
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingBottom,
        canvasHeight,
        width,
        height,
        imageWidth,
        imageHeight
      } = this.getDimensions();

    return (
      <div
        ref={this.mainRef}
        className={classes.main}
        style={{
          height,
          paddingLeft,
          paddingRight,
          paddingTop,
          paddingBottom
        }}
      >
        {getTooltip && tooltip && (
          <MouseTooltip
            visible={Boolean(tooltip)}
            offsetX={20}
            offsetY={15}
            className={classes.tooltip}
          >
            <div>{tooltip}</div>
          </MouseTooltip>
        )}

        <svg height={height} width={width} className={classes.axes}>
          <g
            ref={this.yAxisRef}
            transform={`translate(${paddingLeft - 10}, ${paddingTop})`}
          />
          <g
            ref={this.xAxisRef}
            transform={`translate(${paddingLeft}, ${canvasHeight +
              paddingTop +
              10})`}
          />
        </svg>

        <div
          className={classes.canvasParent}
          style={{ left: paddingLeft, right: paddingRight }}
        >
          <canvas
            style={{
              width: "100%",
              ...(this.props.height && { height: canvasHeight })
            }}
            width={imageWidth}
            height={imageHeight}
            onMouseMove={this.handleMouseMove}
            onMouseLeave={this.handleMouseLeave}
          />
        </div>
      </div>
    );
  };
}

Heatmap.propTypes = {
  x: PropTypes.arrayOf(PropTypes.number),
  y: PropTypes.arrayOf(PropTypes.number),
  z: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  getTooltip: PropTypes.func,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  colorOptions: PropTypes.object.isRequired
};

export default withStyles(styles)(props => <Heatmap {...props} />);
