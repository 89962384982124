import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";
import NumberInput from "components/NumberInput/NumberInput";

export class GenericTextInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error || false,
      helperText: props.helperText || ""
    };
  }

  componentDidUpdate(prevProps) {
    const { error, helperText } = this.props;
    if (prevProps.error != error) {
      this.setState({ error });
    }
    if (prevProps.helperText != helperText) {
      this.setState({ helperText });
    }
  }

  onChange = event => {
    const { minValue, handleUpdateField, keyName } = this.props;

    if (minValue && minValue > event.target.value) {
      this.setState({
        error: true,
        helperText: `The minimum value should be ${minValue}`
      });
    } else {
      this.setState({ error: false, helperText: "" });
    }
    handleUpdateField(event, keyName);
  };

  render() {
    const {
      value,
      name,
      type,
      keyName,
      label,
      placeholder,
      classes,
      onBlur,
      disabled,
      required,
      onKeyDown
    } = this.props;
    const { error, helperText } = this.state;
    if (type === "number") {
      return (
        <NumberInput
          value={value}
          name={name}
          id={keyName}
          label={label}
          placeholder={placeholder}
          onChange={this.onChange}
          onKeyDown={onKeyDown}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          test-data="genericTextInput"
          error={error}
          helperText={helperText}
          onBlur={onBlur || (() => {})}
          disabled={disabled || false}
          required={required || false}
          // inputProps={{ min: "1" }}
        />
      );
    }
    return (
      <TextField
        type={type}
        value={value}
        name={name}
        id={keyName}
        label={label}
        placeholder={placeholder}
        onChange={this.onChange}
        onKeyDown={onKeyDown}
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
        test-data="genericTextInput"
        error={error}
        helperText={helperText}
        onBlur={onBlur || (() => {})}
        disabled={disabled || false}
        required={required || false}
        // inputProps={{ min: "1" }}
      />
    );
  }
}

export default GenericTextInput;
