import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import ImportIcon from "@material-ui/icons/CloudUpload";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Spinner from "components/Spinner/Spinner";

const styles = {
  dropzone: {
    textAlign: "center"
  }
};

class DropZone extends PureComponent {
  render() {
    const {
      loading,
      classes,
      onDrop,
      message,
      error,
      accept,
      style
    } = this.props;
    return (
      <Dropzone onDrop={onDrop} accept={accept}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.dropzone}>
            {loading && <Spinner name="Waiting" timeout={30000} />}
            {!loading && (
              <>
                <input {...getInputProps()} />
                <ImportIcon name="SelectFileButton" fontSize="large" />
                <p style={{ color: "red", margin: "10px" }}>{error}</p>
                <p style={{ margin: "10px" }}>{message}</p>
              </>
            )}
          </div>
        )}
      </Dropzone>
    );
  }
}

DropZone.propTypes = {
  loading: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.string,
  accept: PropTypes.string.isRequired
};

export default withErrorBoundary(withStyles(styles)(DropZone));
