import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import MaterialTable from "components/MaterialTable/src/index";
import Helper from "MetaCell/helper/FamilyMember";
import Typography from "@material-ui/core/Typography";
import FamilyHelper from "MetaCell/helper/Family";

const styles = theme => ({
  title: {
    width: "100%"
  }
});

/**
 * A class component to connect to redux state and serve the form. See {@link MetaCellGlobalParametersForm}
 * @typedef {Component} MetaCellGlobalParameters
 * @author Akira Kotsugai
 */
export class MembersView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      totalMembers: 0
    };
  }

  async componentDidMount() {
    const [members, totalMembers] = await this.getMembersPresentableData();
    this.setState({ members, totalMembers });
  }

  /**
   * @returns {Object[]} - the members data but containing human readable diff summaries.
   */
  getMembersPresentableData = async () => {
    var previewMembers = this.props.membersList.slice(0, 9)
    if (this.props.membersList.length > 10)
      previewMembers = previewMembers.concat(this.props.membersList.slice(-1))
    const members = previewMembers.map(member => ({
      ...member,
      diff_summary: Helper.formatDiffSummary(member.diff_summary)
    }));
    const loadedMembers = await FamilyHelper.loadFamilyMemberLazyFields(
      members
    );
    return [loadedMembers, this.props.membersList.length];
  };

  render() {
    const { classes, family } = this.props;
    return (
      <>
        {family && (
          <Typography
            className={classes.title}
            variant="subtitle1"
            component="h4"
          >
            {`Meta cell group: ${family.name} - ${this.state.totalMembers} metacells in the library`}
          </Typography>
        )}
        {this.state.members.length > 0 && (
          <MaterialTable
            title=""
            columns={[
              {
                title: "Alias",
                field: "alias"
              },
              {
                title: "Number of layers",
                field: "number_of_layers"
              },
              {
                title: "Diff summary",
                field: "diff_summary"
              }
            ]}
            data={[
              ... this.state.totalMembers > 10 ? [
                ...this.state.members.slice(0, 9),
                {
                  alias: '...'
                },
                this.state.members[9]
              ] : this.state.members,
            ]}
            options={{
              search: false,
              paging: false,
              sorting: false,
              draggable: false,
              toolbar: false
            }}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(MembersView);
