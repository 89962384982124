import React, { Component } from "react";
import { Route } from "react-router";
import Proptypes from "prop-types";
import BaseApp from "BaseApp/BaseApp";

export default class PrivateRoute extends Component {
  render = () => {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props => (
          <BaseApp>
            <Component {...props} />
          </BaseApp>
        )}
      />
    );
  };
}

PrivateRoute.propTypes = {
  component: Proptypes.any.isRequired
};
