/**
 * @constant
 * @typedef {Object} SimulationActionType
 * @property {String} START - tells the reducer that a simulation was successfully started
 * @property {String} UPSERT_JOBS - tells the reducer that to update the simulation tasks in the store
 * @property {String} UPSERT_MODE_ANALYSIS_JOBS - tells the reducer to update or insert the mode analysis in the store
 * @property {String} RESET_JOBS - tells the reducer to reset jobs
 * @property {String} SET_JOBS - tells the reducer to set jobs
 * @property {String} RESET_SELECTED_RESULTS - tells the reducer to reset the selected plot results
 * @property {String} DELETE_JOBS - tells the reducer to delete multiple jobs
 * @property {String} UPDATE_OVERVIEW_FILTERS - tells the reducer to update the simulation job overview filters
 * @property {String} UPDATE_PLOT_OVERVIEW_FILTERS - tells the reducer to update the plot overview filters
 * @property {String} SELECT_MODE_ANALYSIS_JOB - tells the reducer to select a mode analysis
 * @property {String} SELECT_SIMULATION_JOB - tells the reducer to select a simulation job
 * @property {String} SET_MODE_ANALYSIS_JOBS - tells the reducer to set the mode analysis jobs
 * @property {String} SET_SIMULATION_JOBS - tells the reducer to set the simulation jobs
 * @global
 * it defines simulation's type of actions.
 */
export const actionType = Object.freeze({
  START: "START",
  UPSERT_JOBS: "UPSERT_JOBS",
  RESET_JOBS: "RESET_JOBS",
  SET_JOBS: "SET_JOBS",
  SELECT_RESULTS: "SELECT_RESULTS",
  SELECT_POINTS: "SELECT_POINTS",
  RESET_SELECTED_RESULTS: "RESET_SELECTED_RESULTS",
  DELETE_JOBS: "DELETE_JOBS",
  UPDATE_OVERVIEW_FILTERS: "UPDATE_SIMULATION_OVERVIEW_FILTERS",
  UPDATE_PLOT_OVERVIEW_FILTERS: "UPDATE_PLOT_OVERVIEW_FILTERS",
  UPSERT_MODE_ANALYSIS_JOBS: "UPSERT_MODE_ANALYSIS_JOBS",
  SELECT_MODE_ANALYSIS_JOB: "SELECT_MODE_ANALYSIS_JOB",
  SELECT_SIMULATION_JOB: "SELECT_SIMULATION_JOB",
  SET_MODE_ANALYSIS_JOBS: "SET_MODE_ANALYSIS_JOBS",
  SET_SIMULATION_JOBS: "SET_SIMULATION_JOBS"
});

/**
 * A class created to create simulation actions to be dispatched to redux. It is already tested with
 * simulation reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link SimulationActionType} and some data.
 * @author Akira Kotsugai
 */
export default class SimulationAction {
  static selectJob = id => ({
    type: actionType.SELECT_SIMULATION_JOB,
    payload: id
  });

  static selectModeAnalysisJob = id => ({
    type: actionType.SELECT_MODE_ANALYSIS_JOB,
    payload: id
  });

  static upsertModeAnalysisJobs = jobs => ({
    type: actionType.UPSERT_MODE_ANALYSIS_JOBS,
    payload: jobs
  });

  static setModeAnalysisJobs = jobs => ({
    type: actionType.SET_MODE_ANALYSIS_JOBS,
    payload: jobs
  });

  static setJobs = jobs => ({
    type: actionType.SET_SIMULATION_JOBS,
    payload: jobs
  });

  static updateOverviewFilters = filters => ({
    type: actionType.UPDATE_OVERVIEW_FILTERS,
    payload: filters
  });

  static updatePlotOverviewFilters = filters => ({
    type: actionType.UPDATE_PLOT_OVERVIEW_FILTERS,
    payload: filters
  });

  static delete = jobIds => ({
    type: actionType.DELETE_JOBS,
    payload: jobIds
  });

  static start = () => ({
    type: actionType.START,
    payload: {}
  });

  static upsertJobs = jobs => ({
    type: actionType.UPSERT_JOBS,
    payload: jobs
  });

  static resetJobs = () => ({
    type: actionType.RESET_JOBS,
    payload: {}
  });

  static selectResults = results => ({
    type: actionType.SELECT_RESULTS,
    payload: results
  });

  static selectPoints = selectedPoints => ({
    type: actionType.SELECT_POINTS,
    payload: selectedPoints
  });

  static resetSelectedResults = () => ({
    type: actionType.RESET_SELECTED_RESULTS,
    payload: {}
  });
}
