/**
 * @constant
 * @typedef {Object} MaterialActionType
 * @property {String} UPSERT_MATERIALS - tells the reducer to set a list of materials
 * @property {String} DELETE_MATERIAL - tells the reducer to delete a material
 * @global
 * it defines material's type of actions.
 */
export const actionType = Object.freeze({
  UPSERT_MATERIALS: "UPSERT_MATERIALS",
  DELETE_MATERIAL: "DELETE_MATERIAL"
});

/**
 * A class created to create material actions to be dispatched to redux. It is already tested with
 * Material reducer, so it does not need to be unit tested.
 * It consists of a instruction {@link MaterialActionType} and some data.
 * @author Akira Kotsugai
 */
export default class MaterialAction {
  static upsertMaterials = value => ({
    type: actionType.UPSERT_MATERIALS,
    payload: value
  });

  static deleteMaterial = materialId => ({
    type: actionType.DELETE_MATERIAL,
    payload: materialId
  });
}
