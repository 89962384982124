import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import SweepInput, { disablingType } from "components/SweepInput/SweepInput";
import Grid from "@material-ui/core/Grid";
import IconTooltip from "components/IconTooltip/IconTooltip";
import NumberInput from "components/NumberInput/NumberInput";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 360,
    height: 200
  }
});

/**
 * A class component to allow the user to insert dimensions data
 * @typedef {Component} DimensionsForm
 * @author Ibtihel
 */
export class DimensionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { selectOpen: false };
  }
  /**
   * It handles a change in a field when it is being edited and calls an action
   * @param {Object} event - the change event
   * @param {String} field - the field being edited
   */
  handleUpdateField = (event, field) => {
    const newValue = {
      [field]: event.target.value
    };
    const { updateFieldCallback } = this.props;
    updateFieldCallback(newValue);
  };

  onWidthChange = width => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ width });
  };

  onHeightChange = height => {
    const { updateFieldCallback } = this.props;
    updateFieldCallback({ height });
  };

  /**
   * it only really opens the select if enter was not pressed.
   * we should not open the select on enter because enter is the save key
   * @param {Object} event - the open event
   * @callback
   */
  handleSelectOpen = event => {
    if (event.key !== "Enter") {
      this.setState({ selectOpen: true });
    }
  };

  /**
   * on top of closing the select,  since Enter is our save key it also stops the event propagation
   * if enter was pressed because the user only wanted to close the select not save
   * @param {Object} event - the close event
   * @callback
   */
  handleSelectClose = event => {
    if (event.key === "Enter") {
      event.stopPropagation();
    }
    this.setState({ selectOpen: false });
  };

  render() {
    const {
      classes,
      dimensions: { width, height, unit },
      isEditing,
      unitOptions,
      unitTooltip,
      isSweep,
      handleSave
    } = this.props;

    return (
      <div test-data="dimensionsForm">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {isSweep ? (
              <SweepInput
                name="MetaCellWidth"
                test-data="width"
                value={width}
                onChange={this.onWidthChange}
                label="Width"
                disabled={isEditing ? false : disablingType.DISABLE_INPUT}
                handleSave={() => handleSave()}
                autoFocus
              />
            ) : (
              <NumberInput
                inputProps={{ style: { textAlign: "left", minWidth: 100 } }}
                onChange={event => {
                  this.onWidthChange(event.target.value);
                }}
                value={width}
                label={"Width"}
                disabled={!isEditing}
                autoFocus
              />
            )}
          </Grid>

          <Grid item xs={6}>
            {isSweep ? (
              <SweepInput
                name="MetaCellHeight"
                test-data="height"
                value={height}
                onChange={this.onHeightChange}
                label="Length"
                disabled={isEditing ? false : disablingType.DISABLE_INPUT}
                handleSave={() => handleSave()}
              />
            ) : (
              <NumberInput
                inputProps={{ style: { textAlign: "left", minWidth: 100 } }}
                onChange={event => {
                  this.onHeightChange(event.target.value);
                }}
                value={height}
                label={"Length"}
                disabled={!isEditing}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <div test-data="unitWrapper">
              <InputLabel
                shrink={true}
                style={{ float: "left" }}
                disabled={isEditing ? false : disablingType.DISABLE_INPUT}
                htmlFor="unit"
              >
                Unit
              </InputLabel>
              <IconTooltip text={unitTooltip} />
            </div>
            <Select
              open={this.state.selectOpen}
              disabled={isEditing ? false : disablingType.DISABLE_INPUT}
              name="MetaCellUnit"
              test-data="unit"
              className={classes.selectField}
              value={unit}
              onChange={event => this.handleUpdateField(event, "unit")}
              inputProps={{
                name: "unit",
                id: "unit"
              }}
              onOpen={this.handleSelectOpen}
              onClose={this.handleSelectClose}
            >
              {unitOptions.map(value => (
                <MenuItem key={value} value={value}>
                  <span test-data="textValue">{value}</span>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(DimensionsForm);
