import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import GridOnIcon from "@material-ui/icons/GridOn";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import DeleteIcon from "@material-ui/icons/Delete";
import { CSVLink } from "react-csv";
import Helper from "MetaCell/helper/SimulationResult";
import domtoimage from "dom-to-image";
import UnselfishMenu from "components/UnselfishMenu/UnselfishMenu";

class PlotActions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      exportMenuAnchorEl: null
    };
  }

  export = event => {
    this.setState({ exportMenuAnchorEl: event.currentTarget });
  };

  handleExportMenuClose = () => {
    this.setState({ exportMenuAnchorEl: null });
  };

  /**
   * it generates the csv data for the csv exporter
   * @returns {String[][]} a matrix of values
   */
  getCSVData = () => {
    const { exportMenuAnchorEl } = this.state;
    const { simulationName, projectName, username, getCSV } = this.props;
    if (exportMenuAnchorEl !== null) {
      const plot = this.props.plotboxes.find(
        plot => plot.id === Number(exportMenuAnchorEl.id)
      );
      return getCSV(plot, projectName, simulationName, username);
    }
    return [];
  };

  /**
   * it downloads an image of the chosen plot
   * @callback
   */
  downloadPlot = () => {
    this.handleExportMenuClose();
    const id = this.state.exportMenuAnchorEl.id;
    const element = document.getElementById(`plotbox_${id}`);
    domtoimage.toPng(element, { quality: 1 }).then(dataUrl => {
      var link = document.createElement("a");
      link.download = `plot_${id}.png`;
      link.href = dataUrl;
      link.click();
    });
  };

  render() {
    const { exportMenuAnchorEl } = this.state;
    const { id, deletePlotbox } = this.props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "20px",
          zIndex: 100
        }}
        test-data="plotboxActions"
      >
        <IconButton id={id} aria-label="download" onClick={this.export}>
          <SaveIcon />
        </IconButton>
        <UnselfishMenu
          test-data="downloadPlotActions"
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          anchorEl={exportMenuAnchorEl}
          open={Boolean(exportMenuAnchorEl)}
          onClose={this.handleExportMenuClose}
          PaperProps={{
            style: {
              border: "1px solid #d3d4d5"
            }
          }}
        >
          {this.props.simulationName &&
            this.props.projectName &&
            this.props.username && (
              <CSVLink
                separator={";"}
                data={this.getCSVData()}
                style={{ color: "inherit" }}
                filename={`${this.props.simulationName}.csv`}
                enclosingCharacter={""}
              >
                <MenuItem>
                  <ListItemIcon>
                    <GridOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Export CSV" />
                </MenuItem>
              </CSVLink>
            )}
          <MenuItem>
            <ListItemIcon>
              <InsertPhotoIcon />
            </ListItemIcon>
            <ListItemText
              test-data="downloadPlotImage"
              primary="Export Image"
              onClick={this.downloadPlot}
            />
          </MenuItem>
        </UnselfishMenu>
        <IconButton
          test-data="deletePlotBtn"
          aria-label="delete"
          onClick={e => deletePlotbox(id)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }
}

PlotActions.propTypes = {
  height: PropTypes.number.isRequired,
  onUpdateButtonClick: PropTypes.func.isRequired,
  isUpdateButtonDisabled: PropTypes.bool.isRequired,
  simulationName: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  updatePlotRange: PropTypes.func
};

export default PlotActions;
