import React, { PureComponent } from "react";
import GenericPlot from "components/Plot/Plot";
import HelperUtils from "MetaCell/helper/HelperUtils";

/**
 * a generic heatmap component
 * @author Akira Kotsugai
 */
export default class LinePlot extends PureComponent {
  state = {
    customxAxisRange: null,
    customyAxisRange: null
  };

  handleCustomRange = (xAxisRange, yAxisRange) => {
    this.setState({
      customxAxisRange: xAxisRange,
      customyAxisRange: yAxisRange
    });
  };

  scaleAxisRange = axisRange => {
    let rangeStart = axisRange[0];
    let rangeEnd = axisRange[1];
    if (rangeStart > 0) rangeStart = rangeStart * 0.8;
    else rangeStart = rangeStart * 1.2;

    if (rangeEnd > 0) rangeEnd = rangeEnd * 1.2;
    else rangeEnd = rangeEnd * 0.8;

    return [rangeStart, rangeEnd];
  };

  getAxisRange = dataArray => {
    return [Math.min(...dataArray), Math.max(...dataArray)];
  };

  getColors = () => {
    const { x, y, selectedPoints } = this.props;
    var colors = Array(Math.max(x.length, y.length)).fill("#1f77b4");
    if (selectedPoints) {
      selectedPoints.forEach(point => {
        var pointIndex = null;
        for (var i = 0; i < x.length; i++) {
          if (x[i] == point.x && y[i] == point.y) {
            pointIndex = i;
            break;
          }
        }
        if (pointIndex !== null && pointIndex !== undefined)
          colors[pointIndex] = "black";
      });
    }
    return colors;
  };

  render() {
    return (
      <>
        <GenericPlot
          onClick={this.props.onClick}
          onGetUnwrappedOutputData={this.props.onGetUnwrappedOutputData}
          handleCustomRange={this.handleCustomRange}
          data={[
            {
              x: this.props.x,
              y: this.props.y,
              type: "scatter",
              mode: "lines+markers",
              marker: {
                color: this.getColors()
              }
            }
          ]}
          layout={{
            width: this.props.width,
            height: this.props.height,
            xaxis: {
              autorange: this.state.customxAxisRange ? false : true,
              title: {
                text: HelperUtils.getAxisName(
                  this.props.xLabel,
                  this.props.xUnit
                )
              },
              range: this.state.customxAxisRange
                ? this.state.customxAxisRange
                : this.props.xAxisRange
                ? this.props.xAxisRange
                : this.getAxisRange(this.props.x)
            },
            yaxis: {
              autorange: this.state.customyAxisRange ? false : true,
              title: {
                text: HelperUtils.getAxisName(
                  this.props.yLabel,
                  this.props.yUnit
                )
              },
              range: this.state.customyAxisRange
                ? this.state.customyAxisRange
                : this.props.yAxisRange
                ? this.scaleAxisRange(this.props.yAxisRange)
                : this.scaleAxisRange(this.getAxisRange(this.props.y)),
              automargin: "width"
            },
            margin: {
              l: 50,
              r: 50,
              b: 50,
              t: 50,
              pad: 4
            }
          }}
        />
      </>
    );
  }
}
