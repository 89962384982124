import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import GenericActions from "components/GenericActions/GenericActions";
import GenericEditableCell from "components/GenericEditableCell/GenericEditableCell";
import GenericEditableCellSelect from "components/GenericEditableCellSelect/GenericEditableCellSelect";
import { fieldTypeOptions } from "MetaCell/containers/SimulationSettingsCanvas/components/Probes/Probes";
import SweepInput, { disablingType } from "components/SweepInput/SweepInput";

const styles = theme => ({
  root: {
    width: 400,
    height: 600,
    marginTop: theme.spacing(1),
    overflowX: "auto",
    overflowY: "auto"
  },
  table: {
    maxWidth: 400
  },
  container: {
    width: "80%",
    display: "flex"
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200]
    }
  },
  tableRowHoverSelected: {
    backgroundColor: "#967cf9"
  },
  tableHead: {
    backgroundColor: "#c6c4c4",
    color: "white"
  }
});

class ProbesTable extends PureComponent {
  fieldTypeSelectOptions = fieldTypeOptions.map(({ label }) => ({
    id: label,
    name: label
  }));

  isProbeSelected = id => {
    const { selectedFieldProbeId } = this.props;
    return id === selectedFieldProbeId;
  };

  isEditingProbe = id => {
    const { fieldProbeBeingEditedId } = this.props;
    return id === fieldProbeBeingEditedId;
  };

  onChange = (e, fieldName) => {
    const { fieldProbeBeingEditedId, onFieldChange } = this.props;
    onFieldChange(fieldProbeBeingEditedId, { [fieldName]: e.target.value });
  };

  onPositionChange = value => {
    const { fieldProbeBeingEditedId, onFieldChange } = this.props;
    onFieldChange(fieldProbeBeingEditedId, { position: value });
  };

  render() {
    const {
      classes,
      probeFields,
      onDeleteProbe,
      onCopyProbe,
      onSelectProbe,
      onEditProbe
    } = this.props;
    return (
      <div test-data="probesTable">
        <Paper className={classes.root}>
          <Toolbar>
            <GenericActions onDelete={onDeleteProbe} onCopy={onCopyProbe} />
          </Toolbar>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Position</TableCell>
                <TableCell align="right">Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {probeFields.map(({ id, name, position, type }, i) => {
                const isSelected = this.isProbeSelected(id),
                  isEditing = this.isEditingProbe(id);
                return (
                  <TableRow
                    test-data-id={`row_${i}`}
                    test-data="probesTableRow"
                    key={id}
                    className={
                      isSelected
                        ? classes.tableRowHoverSelected
                        : classes.tableRowHover
                    }
                    onClick={() => onSelectProbe(id)}
                    onDoubleClick={() => onEditProbe(id)}
                  >
                    <GenericEditableCell
                      test-data="nameCell"
                      inputType="text"
                      listIndex={id}
                      field="name"
                      lable={name}
                      editValue={isEditing ? name : null}
                      onInput={this.onChange}
                    />

                    <TableCell align="center">
                      <SweepInput
                        test-data="position"
                        value={position}
                        onChange={this.onPositionChange}
                        label="Position"
                        disabled={isEditing ? false : disablingType.LABEL}
                      />
                    </TableCell>

                    <TableCell align="center">
                      <GenericEditableCellSelect
                        test-data="fieldTypeCell"
                        value={type}
                        listIndex={id}
                        field="type"
                        options={this.fieldTypeSelectOptions}
                        onInput={this.onChange}
                        editValue={isEditing ? type : null}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ProbesTable);
