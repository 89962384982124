/**
 * A class to make value selection from the confirmDialog in redux state more clear.
 * @author Akira Kotsugai
 */
export default class ConfirmDialogSelector {
  /**
   * it checks if the confirm dialog is open
   * @param {Object} state - the redux state
   * @return if the dialog is open
   */
  static isOpen = state => state.confirmDialog.open;

  /**
   * it selects the confirm dialog's message
   * @param {Object} state - the redux state
   * @return the message
   */
  static getMessage = state => state.confirmDialog.message;

  /**
   * it selects the confirm dialog's title
   * @param {Object} state - the redux state
   * @return the title
   */
  static getTitle = state => state.confirmDialog.title;

  /**
   * it selects the confirm dialog's action
   * @param {Object} state - the redux state
   * @return the confirm action
   */
  static getConfirmAction = state => state.confirmDialog.confirmAction;

  /**
   * it selects the confirm dialog's cancel action
   * @param {Object} state - the redux state
   * @return the cancel action
   */
  static getCancelAction = state => state.confirmDialog.cancelAction;

  /**
   * it selects the value that says whether the actions are redux actions
   * @param {Object} state - the redux state
   * @return whether it is a redux action
   */
  static isReduxAction = state => state.confirmDialog.isReduxAction;

  /**
   * it selects the function that will run after the confirmation action runs
   * @param {Object} state - the redux state
   * @return {Function} post confirm
   */
  static getPostConfirm = state => state.confirmDialog.postConfirm;

  /**
   * it selects form field placeholders
   * @param {Object} state - the redux state
   * @return {Function} post confirm
   */
  static getPlaceholders = state => state.confirmDialog.inputPlaceholders;

  /**
   * it selects the flag to upload a file
   * @param {Object} state - the redux state
   * @return {Function} post confirm
   */
  static getUploadFile = state => state.confirmDialog.uploadFile;

  /**
   * @param {Object} state - the redux state
   * @returns {String[]} the confirm button labels
   */
  static getConfirmLabels = state => state.confirmDialog.confirmLabels;
}
