import { actionType } from "BaseApp/actions/ConfirmDialog";

/**
 * @constant
 * @typedef {Object} ConfirmDialogDefaultState
 * the dialog must be hidden by default
 */
export const defaultState = {
  open: false,
  title: "",
  message: "",
  confirmAction: null,
  cancelAction: null,
  isReduxAction: false,
  postConfirm: null,
  inputPlaceholders: false,
  uploadFile: false,
  confirmLabels: null
};

/**
 * Reducer function to manipulate the state of the confirmation dialog
 * @author Akira Kotsugai
 * @param {Object} [state=ConfirmDialogDefaultState] - open, title, message, confirm action, cancel action and
 * is redux action.
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;

  switch (action.type) {
    case actionType.SET_DIALOG_OPEN: {
      if (payload) {
        return { ...state, open: payload };
      }
      return { ...defaultState };
    }

    case actionType.SHOW_DIALOG: {
      const {
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction,
        postConfirm,
        inputPlaceholders,
        uploadFile,
        confirmLabels
      } = payload;
      return {
        ...state,
        open: true,
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction,
        postConfirm,
        inputPlaceholders,
        uploadFile,
        confirmLabels
      };
    }

    default: {
      return state;
    }
  }
}
