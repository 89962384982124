import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Spinner from "components/Spinner/Spinner";

const styles = {
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

class RootCanvas extends Component {
  render = () => {
    const { classes, isLoading } = this.props;
    return isLoading ? (
      <div className={classes.progress}>
        <Spinner name="Waiting" size={68} timeout={30000} />
      </div>
    ) : null;
  };
}

export default withStyles(styles)(RootCanvas);
