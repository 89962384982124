import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Tooltip from "@material-ui/core/Tooltip";
import ImportIcon from "@material-ui/icons/CloudUpload";
import { itemType } from "../ExplorerTree/ExplorerTree";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%"
  },
  grow: {
    flexGrow: 1
  },
  iconButton: {
    padding: 5
  }
});

/**
 * A component that can host buttons to perform operations in the selected item from the Tree
 * @author Akira Kotsugai
 * @param {Object} props - object is expected to have callback functions for exporting, editing and deleting items
 * @return {Component} a delete button
 */
const ExplorerPanel = ({
  selectedItem,
  onExport,
  onEdit,
  onDelete,
  onImport,
  isLoading
}) => {
  const classes = useStyles();
  const handleImportClick = () => {
    onImport();
  };
  return (
    <div className={classes.root} test-data="explorerPanel">
      <div className={classes.grow} />
      {isLoading && <LoadingOverlay />}
      <Tooltip title="Import" placement="top">
        <IconButton
          test-data="importBtn"
          name="TreeImportButton"
          className={classes.iconButton}
          onClick={handleImportClick}
          disabled={Array.isArray(selectedItem)}
        >
          <ImportIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Export" placement="top">
        <div>
          <IconButton
            test-data="exportBtn"
            name="TreeExportButton"
            className={classes.iconButton}
            onClick={onExport}
            disabled={
              !selectedItem ||
              Array.isArray(selectedItem) ||
              selectedItem.type !== itemType.SIMULATION ||
              !selectedItem.id
            }
          >
            <SaveAltIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Rename" placement="top">
        <div>
          <IconButton
            name="TreeEditButton"
            test-data="editBtn"
            className={classes.iconButton}
            onClick={onEdit}
            disabled={
              !selectedItem ||
              !selectedItem.id ||
              Array.isArray(selectedItem) ||
              selectedItem.type === "user"
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Delete" placement="top">
        <div>
          <IconButton
            test-data="deleteBtn"
            name="TreeDeleteButton"
            className={classes.iconButton}
            onClick={onDelete}
            disabled={
              !selectedItem ||
              (!Array.isArray(selectedItem) && !selectedItem.id) ||
              selectedItem.type === "user"
            }
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default ExplorerPanel;
