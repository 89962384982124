import ComponentHelper from "MetaComponent/helper/Component";
import { isEqual } from "lodash";
import HelperUtils from "MetaCell/helper/HelperUtils";
import store from "store";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import SelectedDesignTargetApi from "MetaComponent/api/SelectedDesignTarget";
import FFWFTargetHelper from "MetaComponent/helper/FFWFTarget";

/**
 * A helper class for design target components
 */
export default class DesignTarget {
  /**
   * it checks whether the given design targets' dimensions are compatible.
   * it also converts to a comparable unit before the comparison.
   * @param {Object[]} designTargets - the design target objects
   * @param {Number} width - the compatible width
   * @param {Number} height - the compatible height
   * @param {String} unit - the compatible unit
   */
  static getIncompatibleDesignTargets = (
    designTargets,
    width,
    height,
    unit
  ) => {
    const incompatibleDesignTargets = designTargets.filter(designTarget => {
      const comparableWidth = unit == "mm" ? width * 1000 : width,
        comparableHeight = unit == "mm" ? height * 1000 : height,
        comparableNFWidth =
          designTarget.unit == "mm"
            ? designTarget.NFWidth * 1000
            : designTarget.NFWidth,
        comparableNFHeight =
          designTarget.unit == "mm"
            ? designTarget.NFHeight * 1000
            : designTarget.NFHeight,
        widthIsCompatible = comparableNFWidth === comparableWidth,
        heightIsCompatible = comparableNFHeight === comparableHeight;
      return !widthIsCompatible || !heightIsCompatible;
    });
    return incompatibleDesignTargets;
  };

  /**
   * @param {Object[]} designTargets - the design target objects
   */
  static getDesignTargetsThatDontHaveFFWF = designTargets => {
    return designTargets.filter(designTarget => !designTarget.FFWFTarget);
  };

  static getDesignTargetsBiggerThanComponent = (
    designTargets,
    componentWidth,
    componentHeight,
    componentUnit
  ) => {
    function comparator(nfWidth, width, nFHeight, height) {
      return (
        (nfWidth > width && nFHeight > height) ||
        (nfWidth === width && nFHeight > height) ||
        (nfWidth > width && nFHeight === height)
      );
    }
    return this.getDesignTargetsComparedWithComponentDimensions(
      designTargets,
      componentWidth,
      componentHeight,
      componentUnit,
      comparator
    );
  };

  static getDesignTargetsSmallerThanComponent = (
    designTargets,
    componentWidth,
    componentHeight,
    componentUnit
  ) => {
    function comparator(nfWidth, width, nFHeight, height) {
      return (
        (nfWidth < width && nFHeight < height) ||
        (nfWidth === width && nFHeight < height) ||
        (nfWidth < width && nFHeight === height)
      );
    }
    return this.getDesignTargetsComparedWithComponentDimensions(
      designTargets,
      componentWidth,
      componentHeight,
      componentUnit,
      comparator
    );
  };

  static getDesignTargetsSameSizeAsComponent = (
    designTargets,
    componentWidth,
    componentHeight,
    componentUnit
  ) => {
    function comparator(nfWidth, width, nFHeight, height) {
      return nfWidth === width && nFHeight === height;
    }
    return this.getDesignTargetsComparedWithComponentDimensions(
      designTargets,
      componentWidth,
      componentHeight,
      componentUnit,
      comparator
    );
  };

  static getComparableDimension = (dimension, unit) => {
    return unit == "mm" ? parseFloat(dimension) * 1000 : parseFloat(dimension);
  };

  static getComponentDimensionfromMetacellDim = (
    dimension,
    componentUnit,
    metacellUnit
  ) => {
    dimension = parseFloat(dimension);
    var correctionToMum = 1;
    if (metacellUnit === "nm") {
      correctionToMum = 0.001;
    } else if (metacellUnit === "mm") {
      correctionToMum = 1000;
    }
    var dimensionMum = dimension * correctionToMum;
    var correctionToCompDim = 1;
    if (componentUnit === "nm") {
      correctionToCompDim = 1000;
    } else if (componentUnit === "mm") {
      correctionToCompDim = 0.001;
    }
    var dimensionCompUnit = dimensionMum * correctionToCompDim;
    return dimensionCompUnit;
  };

  static getDesignTargetsComparedWithComponentDimensions = (
    designTargets,
    componentWidth,
    componentHeight,
    componentUnit,
    comparator
  ) => {
    const comparedDesignTargets = designTargets.filter(designTarget => {
      const comparableWidth = this.getComparableDimension(
          componentWidth,
          componentUnit
        ),
        comparableHeight = this.getComparableDimension(
          componentHeight,
          componentUnit
        ),
        comparableNFWidth = this.getComparableDimension(
          designTarget.NFWidth,
          designTarget.unit
        ),
        comparableNFHeight = this.getComparableDimension(
          designTarget.NFHeight,
          designTarget.unit
        );
      return comparator(
        comparableNFWidth,
        comparableWidth,
        comparableNFHeight,
        comparableHeight
      );
    });
    return comparedDesignTargets;
  };

  static async getDesignTargetsDifferentCellStructureShape(
    designTargets,
    componentWidth,
    componentHeight,
    componentUnit,
    familyWidth,
    familyHeight,
    familyUnit
  ) {
    const componentComparableWidth = this.getComparableDimension(
        componentWidth,
        componentUnit
      ),
      componentComparableHeight = this.getComparableDimension(
        componentHeight,
        componentUnit
      ),
      familyComparableWidth = this.getComparableDimension(
        familyWidth,
        familyUnit
      ),
      familyComparableHeight = this.getComparableDimension(
        familyHeight,
        familyUnit
      ),
      componentCellStructureShape = ComponentHelper.getComponentCellStructureShape(
        componentComparableWidth,
        componentComparableHeight,
        familyComparableWidth,
        familyComparableHeight
      );
    let nfwf_shapes = [];
    for (const designTarget of designTargets) {
      const nfwf_shape = await DesignTargetApi.requestNFWFTargetWavefrontShape(
        designTarget.id
      );
      nfwf_shapes.push(nfwf_shape?.reverse());
    }

    return designTargets.filter((designTarget, index) => {
      return (
        nfwf_shapes[index] &&
        !isEqual(nfwf_shapes[index], componentCellStructureShape)
      );
    });
  }

  static buildDiscrepantDesignTargetNFWFsMessages(
    biggerNFWFDesignTargets,
    smallerNFWFDesignTargets,
    differentCellStructShapeDesignTargets,
    sameCellStructShapeDesignTargets
  ) {
    let messages = [];
    if (biggerNFWFDesignTargets.length > 0) {
      messages.push(
        `The wavefront of the following design targets are bigger than the meta component:
        ${biggerNFWFDesignTargets.map(
          designTarget => `\n\t-${designTarget.name}`
        )}`
        // \n How you like to solve this?`
      );
    }
    if (smallerNFWFDesignTargets.length > 0) {
      messages.push(
        `The near-field wavefront of the following design targets are smaller than the meta component:
        ${smallerNFWFDesignTargets.map(
          designTarget => `\n\t-${designTarget.name}`
        )}
        \n Therefore, their near-field wavefront will be scaled up.`
      );
    }
    if (differentCellStructShapeDesignTargets.length > 0) {
      messages.push(
        `The near-field wavefront of the following design targets matches the meta component size, but the wavefront shape does not match the shape of the meta component's cell structure:
        ${differentCellStructShapeDesignTargets.map(
          designTarget => `\n\t-${designTarget.name}`
        )}
        \n Therefore, their near-field wavefront will be interpolated.`
      );
    }
    if (sameCellStructShapeDesignTargets.length > 0) {
      messages.push("");
    }

    return messages;
  }

  static generatePromiseFnThatUpdatesADesignTarget = (
    designTarget,
    nearFieldDiscrepancyHandling,
    selectedDesignTargetObjs
  ) => {
    return async (resolve, reject) => {
      const selectedDesignTarget = selectedDesignTargetObjs.find(
        sdt => sdt.design_target === designTarget.id
      );
      await SelectedDesignTargetApi.updateSelectedDesignTarget(
        selectedDesignTarget.id,
        {
          NF_discrepancy_handling: nearFieldDiscrepancyHandling
        }
      )(store.dispatch);
      await DesignTargetApi.updateDesignTarget(designTarget.id, {
        NFWidth: designTarget.NFWidth,
        NFHeight: designTarget.NFHeight
      })(store.dispatch);
      if (
        designTarget.NFWaveFront &&
        designTarget.NFWaveFront instanceof File
      ) {
        await DesignTargetApi.updateNFWFWavefront(designTarget.id, {
          wavefrontFile: designTarget.NFWaveFront
        })(store.dispatch);
      } else {
        if (designTarget.s3FileKey) {
          await DesignTargetApi.updateNFWFWavefront(designTarget.id, {
            s3FileKey: designTarget.s3FileKey
          })(store.dispatch);
        }
      }
      resolve();
    };
  };

  static async getDiscrepantDesignTargetNFWFsMessages(
    designTargetObjects,
    family,
    componentDimensions
  ) {
    const componentWidth = componentDimensions.width,
      componentHeight = componentDimensions.height,
      componentUnit = componentDimensions.unit,
      familyWidth = family.cell_width,
      familyHeight = family.cell_height,
      familyUnit = family.unit,
      designTargetsSameSizeAsMetaComponent = this.getDesignTargetsSameSizeAsComponent(
        designTargetObjects,
        componentWidth,
        componentHeight,
        componentUnit
      ),
      designTargetsSameSizeDifferentCellStructure = await this.getDesignTargetsDifferentCellStructureShape(
        designTargetsSameSizeAsMetaComponent,
        componentWidth,
        componentHeight,
        componentUnit,
        familyWidth,
        familyHeight,
        familyUnit
      ),
      designTargetsSameSizeAndSameCellStructure = designTargetsSameSizeAsMetaComponent.filter(
        designTarget =>
          !designTargetsSameSizeDifferentCellStructure
            .map(dt => dt.id)
            .includes(designTarget.id)
      ),
      messages = this.buildDiscrepantDesignTargetNFWFsMessages(
        this.getDesignTargetsBiggerThanComponent(
          designTargetObjects,
          componentWidth,
          componentHeight,
          componentUnit
        ),
        this.getDesignTargetsSmallerThanComponent(
          designTargetObjects,
          componentWidth,
          componentHeight,
          componentUnit
        ),
        designTargetsSameSizeDifferentCellStructure,
        designTargetsSameSizeAndSameCellStructure
      );
    return messages;
  }

  static async getDiscrepantDesignTargetNFWFsAnswers(
    designTargetObjects,
    family,
    componentDimensions,
    selectedDesignTargetObjs
  ) {
    const componentWidth = componentDimensions.width,
      componentHeight = componentDimensions.height,
      componentUnit = componentDimensions.unit,
      familyWidth = family.cell_width,
      familyHeight = family.cell_height,
      familyUnit = family.unit,
      designTargetsSameSizeAsMetaComponent = this.getDesignTargetsSameSizeAsComponent(
        designTargetObjects,
        componentWidth,
        componentHeight,
        componentUnit
      ),
      designTargetsSameSizeDifferentCellStructure = await this.getDesignTargetsDifferentCellStructureShape(
        designTargetsSameSizeAsMetaComponent,
        componentWidth,
        componentHeight,
        componentUnit,
        familyWidth,
        familyHeight,
        familyUnit
      ),
      designTargetsSameSizeAndSameCellStructure = designTargetsSameSizeAsMetaComponent.filter(
        designTarget =>
          !designTargetsSameSizeDifferentCellStructure
            .map(dt => dt.id)
            .includes(designTarget.id)
      ),
      confirmActions = this.buildDiscrepantDesignTargetNFWFsAnswers(
        this.getDesignTargetsBiggerThanComponent(
          designTargetObjects,
          componentWidth,
          componentHeight,
          componentUnit
        ),
        this.getDesignTargetsSmallerThanComponent(
          designTargetObjects,
          componentWidth,
          componentHeight,
          componentUnit
        ),
        designTargetsSameSizeDifferentCellStructure,
        designTargetsSameSizeAndSameCellStructure,
        selectedDesignTargetObjs
      );
    return confirmActions;
  }

  static buildDiscrepantDesignTargetNFWFAnswerOption(
    optionText,
    discrepantDesignTargets,
    discrepancyHandling,
    selectedDesignTargetObjs
  ) {
    return {
      option: optionText,
      actions: discrepantDesignTargets.map(designTarget =>
        this.generatePromiseFnThatUpdatesADesignTarget(
          designTarget,
          discrepancyHandling,
          selectedDesignTargetObjs
        )
      )
    };
  }

  static buildDiscrepantDesignTargetNFWFsAnswers(
    biggerNFWFDesignTargets,
    smallerNFWFDesignTargets,
    differentCellStructShapeDesignTargets,
    sameCellStructShapeDesignTargets,
    selectedDesignTargetObjs
  ) {
    let answers = [];
    if (biggerNFWFDesignTargets.length > 0) {
      answers.push([
        this.buildDiscrepantDesignTargetNFWFAnswerOption(
          "Crop near-field wavefront",
          biggerNFWFDesignTargets,
          "CROP",
          selectedDesignTargetObjs
        ),
        this.buildDiscrepantDesignTargetNFWFAnswerOption(
          "Scale near-field wavefront down",
          biggerNFWFDesignTargets,
          "SCALE",
          selectedDesignTargetObjs
        )
      ]);
    }
    if (smallerNFWFDesignTargets.length > 0) {
      answers.push([
        this.buildDiscrepantDesignTargetNFWFAnswerOption(
          undefined,
          smallerNFWFDesignTargets,
          "SCALE",
          selectedDesignTargetObjs
        )
      ]);
    }
    if (differentCellStructShapeDesignTargets.length > 0) {
      answers.push([
        this.buildDiscrepantDesignTargetNFWFAnswerOption(
          undefined,
          differentCellStructShapeDesignTargets,
          "INTERPOLATE",
          selectedDesignTargetObjs
        )
      ]);
    }
    if (sameCellStructShapeDesignTargets.length > 0) {
      answers.push([
        this.buildDiscrepantDesignTargetNFWFAnswerOption(
          undefined,
          sameCellStructShapeDesignTargets,
          null,
          selectedDesignTargetObjs
        )
      ]);
    }
    return answers;
  }

  /**
   * the confirmation dialog accepts multiple questions, therefore, we can pass
   * multiple messages and multiple confirm actions to it.
   * each "confirmation action" is actually a list of actions, because each action corresponds
   * to an option for a question in the dialog.
   * this function always run the actions of the first answer option for a question.
   * (the option is also expect to be a set of actions) check the unit test to see examples
   * @param {*} confirmActions
   * @returns {Promise} the resolved promise.
   */
  static runFirstActionOfEveryConfirmAction(
    confirmActions,
    postFunction = () => {}
  ) {
    return Promise.all(
      confirmActions.map(actionSet => {
        const actionArray = actionSet[0].actions;
        return Promise.all(actionArray.map(action => new Promise(action)));
      })
    ).then(() => postFunction());
  }
}
