import React, { Component } from "react";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Dimensions from "components/Dimensions/Dimensions";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import { FamilySelector } from "MetaCell/selectors/Family";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import DesignTargetHelper from "MetaComponent/helper/DesignTarget";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import DirectionSnackbar from "components/Snackbar/Snackbar";

export const unitOptions = ["μm", "mm"];

/**
 * A class component to connect to redux state and serve the dimensions form. See {@link DimensionsForm}
 * @typedef {Component} ComponentDimensions
 * @author Ibtihel
 */
export class ComponentDimensions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingDimensions: null,
      saving: false,
      snackbar: {
        visible: false,
        message: ""
      }
    };
  }

  /**
   * it updates the editing dimensions with the given new values
   * @param {Object} newParameters - the parameters that changed
   * @callback
   */
  updateEditingDimensions = newParameters => {
    this.setState({
      editingDimensions: { ...this.state.editingDimensions, ...newParameters }
    });
  };

  /**
   * it changes the editing state with the open meta component dimension properties
   */
  editDimensions = () => {
    const openMetaComponent = this.getOpenMetaComponent();
    this.setState({
      editingDimensions: {
        width: openMetaComponent.width,
        height: openMetaComponent.height,
        unit: openMetaComponent.unit
      }
    });
  };

  /**
   * it changes the editing state to null
   */
  cancelEditing = () => {
    this.setState({
      editingDimensions: null,
      saving: false
    });
  };

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  /**
   * it ensures that the wavefront data is loaded, gets the messages and actions
   * for discrepancies in the meta component size and the selected design target sizes
   * and shows the dialog for the user to confirm the save operation when there are discrepancies.
   * if there are no discrepancies, it also performs actions in the design targets (set the discrepancy to null)
   */
  save = async () => {
    const {
        openMetaComponentId,
        families,
        showConfirmDialog,
        selectedDesignTargets
      } = this.props,
      { editingDimensions } = this.state,
      openMetaComponent = this.getOpenMetaComponent(),
      family = families.byId[openMetaComponent.family],
      { selected_design_targets } = openMetaComponent,
      sdtObjs = selected_design_targets.map(
        dtId => selectedDesignTargets.byId[dtId]
      );

    const designTargetObjs = sdtObjs.map(
      sdt => this.props.designTargets.byId[sdt.design_target]
    );

    const designTargetsThatDontHaveFFWF = DesignTargetHelper.getDesignTargetsThatDontHaveFFWF(
      designTargetObjs
    );

    if (designTargetsThatDontHaveFFWF.length > 0) {
      // we get fresh design targets because their wavefronts may have changed
      let upToDateDesignTargets = [];
      for (const dt of designTargetsThatDontHaveFFWF) {
        const upToDateDT = await DesignTargetApi.requestDesignTargets(dt.id);
        upToDateDesignTargets.push(...upToDateDT);
      }
      const messages = await DesignTargetHelper.getDiscrepantDesignTargetNFWFsMessages(
          upToDateDesignTargets,
          family,
          editingDimensions
        ),
        confirmActions = await DesignTargetHelper.getDiscrepantDesignTargetNFWFsAnswers(
          upToDateDesignTargets,
          family,
          editingDimensions,
          sdtObjs
        ),
        thereAreDiscrepanciesToHandle = messages.some(
          message => message !== ""
        ),
        thereAreSimilaritiesToHandle = messages.some(message => message === "");
      if (thereAreDiscrepanciesToHandle) {
        // when there are discrepancies between the sizes, the non discrepant design targets are
        // already handled if the user confirms in the dialog.
        showConfirmDialog(
          "Meta component size and near-field wavefront size",
          messages,
          [],
          () => this.cancelEditing(),
          false,
          () => {
            this.saveMetaComponentAndLeaveEditMode(
              openMetaComponentId,
              editingDimensions
            );
          },
          false,
          false,
          ["Confirm Anyway", "Cancel"]
        );
      } else if (thereAreSimilaritiesToHandle) {
        // matching sizes and cell structure dont need user confirmation
        // but it still has actions to be executed (set the discrepancy handling to null)
        await DesignTargetHelper.runFirstActionOfEveryConfirmAction(
          confirmActions
        );
        this.saveMetaComponentAndLeaveEditMode(
          openMetaComponentId,
          editingDimensions
        );
      }
    } else {
      this.saveMetaComponentAndLeaveEditMode(
        openMetaComponentId,
        editingDimensions
      );
    }
  };

  saveMetaComponentAndLeaveEditMode = async (
    openMetaComponentId,
    editingDimensions
  ) => {
    const { updateMetaComponent } = this.props;
    this.setState({ saving: true, snackbar: { message: "", visible: false } });
    try {
      await updateMetaComponent(openMetaComponentId, editingDimensions);
    } catch (e) {
      const errorMessage = e?.response?.data[0];
      this.setState({
        snackbar: {
          message: errorMessage,
          visible: true
        }
      });
    }
    this.cancelEditing();
  };

  render() {
    const { editingDimensions } = this.state,
      openMetaComponent = this.getOpenMetaComponent(),
      dimensions = openMetaComponent
        ? {
            width: openMetaComponent.width,
            height: openMetaComponent.height,
            unit: openMetaComponent.unit
          }
        : null;
    return (
      <>
        <Dimensions
          title={"Component dimensions"}
          tooltip={
            "The dimensions of the component you would like to design. These values shall be rounded to an equal time the dimensions of the family member (meta atom)."
          }
          dimensions={dimensions}
          editingDimensions={editingDimensions}
          onSave={this.save}
          updateDimensions={this.updateEditingDimensions}
          editDimensions={this.editDimensions}
          cancelDimensionsEditing={this.cancelEditing}
          unitOptions={unitOptions}
          unitTooltip={
            "This unit shall be used throughout the Meta component designer, all values shall be in this unit"
          }
          saving={this.state.saving}
        />
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
    designTargets: DesignTargetSelector.getDesignTargets(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    ),
    families: FamilySelector.getFamilies(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDesignTarget: id => dispatch(DesignTargetApi.fetchDesignTargets(id)),
    updateMetaComponent: (id, newProperties) =>
      dispatch(DirectoryExplorerApi.updateMetaComponent(id, newProperties)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm,
      inputPlaceholders,
      uploadFile,
      confirmLabels
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm,
          inputPlaceholders,
          uploadFile,
          confirmLabels
        )
      ),
    getNFWFTargetWavefrontAction: id =>
      dispatch(DesignTargetApi.getNFWFTargetWavefront(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(ComponentDimensions));
