import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import debounce from "lodash.debounce";
import { Typography } from "@material-ui/core";

/**
 * A functional component to show a spinner and allow a timeout
 * @param {Object} props - props passed to the component
 */
function Spinner(props) {
  let timer;

  const {
    timeout = DEFAULT_TIMEOUT,
    showConfirmDialog,
    progress,
    ...rest
  } = props;

  React.useEffect(() => {
    if (timeout) {
      timer = debounce(() => {
        showConfirmDialog(
          "Stuck",
          "It seems that you have waited for too long. Would you like to refresh the page? Refreshing the page might interrupt your ongoing action/processes.",
          () => {
            window.location.reload();
          },
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          ["Refresh", "Cancel"]
        );
      }, timeout);
      timer();
    }
    return () => {
      if (timer) {
        timer.cancel();
      }
    };
  });

  return (
    <>
      <CircularProgress {...rest} />
      <div>
        {progress ? (
          <Typography
            variant="subtitle2"
            test-data="progressStatus"
            style={{ marginBottom: 10, marginTop: 10 }}
            align="center"
          >
            {progress}%
          </Typography>
        ) : null}
      </div>
    </>
  );
}

const DEFAULT_TIMEOUT = 180000;

Spinner.propTypes = {
  /**
   * The children to be rendered.
   */
  timeout: PropTypes.number
};

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm,
      inputPlaceholders,
      uploadFile,
      confirmLabels
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm,
          inputPlaceholders,
          uploadFile,
          confirmLabels
        )
      )
  };
};

export default connect(null, mapDispatchToProps)(Spinner);
