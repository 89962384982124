import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import Popper from "@material-ui/core/Popper";
import NumberInput from "components/NumberInput/NumberInput";
import HelperUtils from "MetaCell/helper/HelperUtils";
import AddVariable from "./components/AddVariable/AddVariable";
import { isEqual } from "lodash";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  item: {
    cursor: "pointer",
    padding: "16px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    },
    "&:focus": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },
  dropdown: {
    margin: "10px 0",
    background: "#fff",
    cursor: "pointer",
    borderRadius: "4px",
    maxHeight: "200px",
    overflowY: "auto",
    textAlign: "left",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
  },
  error: {
    textAlign: "left"
  }
};

const mapState = (reduxState, parentProps) => ({
  simulationId:
    parentProps.simulationId ||
    DirectoryExplorerSelector.getSimulationOpenId(reduxState),
  sweptVariables:
    parentProps.sweptVariables ||
    SimulationSettingsSelector.getSweptVariables(reduxState),
  allowOnTheFlyVariableCreation: !parentProps.simulationId
});

/**
 * @constant
 * @typedef {Object} SweepInputDisablingType
 * @property {String} LABEL - should show a label instead of the input when using this disabling type
 * @property {String} DISABLE_INPUT - should just disable the sweep input when using this disabling type
 * @global
 */
export const disablingType = Object.freeze({
  LABEL: "LABEL",
  DISABLE_INPUT: "DISABLE_INPUT"
});

/**
 * A class component to replace material ui TextField component for sweep variables,
 * with validation, suggestions and tooltip.
 * @typedef {Component} SweepInput
 */
export class SweepInput extends Component {
  resetToPreviousValue = false;
  previousValue;
  focusTextInput = false;
  focusTextInputValue = "";
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      showMenu: false,
      tooltip: "",
      anchorEl: null,
      suggestionVariables: [],
      suggestionsRefs: [],
      showAddVariable: false
    };
    this.textInput = React.createRef();
  }

  hideAddVariable = (newValue = null) => {
    this.setState({ showAddVariable: false });
    if (newValue) {
      if (typeof newValue === "string")
        this.onSweepVariableSelect(newValue, this.props.name);
      else this.onSweepVariableSelect("", this.props.name);
      if (this.props.handleSave != null) {
        this.props.handleSave();
      }
    }
  };

  reset = shouldReset => {
    const { onReset } = this.props;
    this.resetToPreviousValue = shouldReset;
    if (onReset) {
      onReset(shouldReset, this.previousValue);
    }
  };

  componentDidUpdate(oldProps) {
    if (
      this.focusTextInput &&
      this.textInput &&
      this.props.value === this.focusTextInputValue
    ) {
      this.textInput.current.focus();
      this.focusTextInput = false;
      this.focusTextInputValue = "";
    }
    const { sweptVariables, value, onChange, simulationId } = this.props;

    if (value && value.toString().startsWith("=")) {
      const oldSweepVariables = oldProps.sweptVariables?.filter(
        sv => sv.simulation === simulationId
      );
      const newSweepVariables = this.props.sweptVariables?.filter(
        sv => sv.simulation === simulationId
      );
      if (
        oldSweepVariables &&
        newSweepVariables &&
        !isEqual(
          oldSweepVariables.map(sv => sv.variableName),
          newSweepVariables.map(sv => sv.variableName)
        )
      ) {
        const sweepNames = sweptVariables.map(sv => sv.name);
        if (!sweepNames.includes(value.slice(1))) {
          const oldSweep = oldProps.sweptVariables.find(
            sv => sv.name === value.slice(1)
          );
          if (oldSweep && oldSweep.values.length > 0) {
            onChange(oldSweep.values[oldSweep.values.length - 1]);
          } else {
            onChange("0");
          }
        }
      }
    }
  }

  /**
   * Sets validation error, toggle menu and change tooltip
   * @param {String} value TextField value
   */
  handleTextChange = value => {
    const {
        sweptVariables,
        simulationId,
        allowEmpty,
        allowNegative,
        restrictFloat,
        maxValue
      } = this.props,
      valRegex = new RegExp(allowNegative ? /^-?\d*\.?\d+$/ : /^\d*\.?\d+$/),
      noFloatRegex = new RegExp(/^-?(0|[1-9]\d*)$/),
      varRegex = new RegExp(/^=(.*?)$/);
    let error = "",
      showMenu = false,
      valRegexMatches = false,
      varRegexMatches = false,
      floatRegexMatches = true,
      isExpoNotation = false,
      sweptVariableFound = null,
      suggestions = [];
    this.reset(false);

    if (value === "") {
      error = allowEmpty ? "" : "This field is required";
    } else {
      valRegexMatches = valRegex.test(value);
      if (valRegexMatches && restrictFloat) {
        floatRegexMatches = noFloatRegex.test(value);
      }
      varRegexMatches = varRegex.test(value);
      isExpoNotation = HelperUtils.isExpoNotation(value, allowNegative);
      if (varRegexMatches) {
        sweptVariableFound =
          sweptVariables &&
          sweptVariables.some(
            ({ simulation, variableName }) =>
              simulation === simulationId && variableName === value.substr(1)
          );
        const sweptVariableFoundInsideMathExpression =
          sweptVariables &&
          sweptVariables.some(
            ({ simulation, variableName }) =>
              simulation === simulationId &&
              value.substr(1).includes(variableName) &&
              HelperUtils.validateMathExpression(value.substr(1), [
                variableName
              ])
          );
        suggestions = sweptVariables.filter(
          ({ simulation, variableName }) =>
            simulation === simulationId &&
            variableName.toUpperCase() !== value.substr(1).toUpperCase() &&
            variableName.toUpperCase().includes(value.substr(1).toUpperCase())
        );
        if (this.props.allowOnTheFlyVariableCreation && !sweptVariableFound) {
          suggestions.push({
            id: "create",
            variableName: "create new"
          });
        }
        this.defineSuggestionsRefs(suggestions.length);
        showMenu = true;
        const validInput =
          sweptVariableFound || sweptVariableFoundInsideMathExpression;
        this.reset(!validInput);
      } else if (!valRegexMatches && !isExpoNotation) {
        this.reset(true);
        error = "Invalid input";
      }
      if (valRegexMatches && !floatRegexMatches) {
        this.reset(true);
        error = "Only integers allowed";
      }
      if (maxValue && value > maxValue) {
        this.reset(true);
        error = `Maximum value is ${maxValue}`;
      }
    }
    this.setState({
      error,
      showMenu,
      suggestionVariables: suggestions,
      tooltip: this.getTooltip(value && ("" + value).substr(1))
    });
  };

  /**
   * it dinamically creates references to swept variables suggestions and hang on the component state.
   * as we already have a state for the list of suggestions, we can identify the refs by
   * the suggestion index. Index here is more important than id because the only reason we create
   * these refs is to make the suggestion popper navigable with keyboard arrows.
   * @param {Number} suggestionsQuantity - how many suggestions we have
   */
  defineSuggestionsRefs = suggestionsQuantity => {
    let suggestionsRefs = [];
    for (let index = 0; index < suggestionsQuantity; index++) {
      suggestionsRefs.push(React.createRef());
    }
    this.setState({ suggestionsRefs });
  };

  /**
   * Returns tooltip text for a sweep variable
   * @param {String} varName sweep variable name
   * @return {String} tooltip text with sweep values
   */
  getTooltip = varName => {
    const { sweptVariables, simulationId } = this.props;
    const sweptVariable =
      sweptVariables &&
      sweptVariables
        .filter(({ simulation }) => simulation === simulationId)
        .find(({ variableName }) => variableName === varName);
    return (
      sweptVariable &&
      (sweptVariable.values instanceof Array
        ? `[${sweptVariable.values.join(", ")}]`
        : sweptVariable.values)
    );
  };

  /**
   * onChange ui event callback
   * @param {Object} event ui event object
   */
  onTextFieldChange = event => {
    const { value } = event.target;
    const { onChange } = this.props;
    this.handleTextChange(value);
    onChange(value);
  };

  resetValue = () => {
    const { onChange } = this.props,
      value = this.previousValue || "";
    this.setState({ showMenu: false });
    if (this.resetToPreviousValue) {
      this.handleTextChange(value);
      onChange(value);
    }
  };

  /**
   * It is supposed to be passed to the text input.
   * if checks whether the next focus is the suggestions or not. only if it is not the suggestions
   * we should call the reset handler.
   * @param {Object} event - the blur event
   * @callback
   */
  onTextFieldBlur = event => {
    const { suggestionsRefs, suggestionVariables } = this.state,
      thereAreSuggestions = suggestionVariables.length > 0;
    if (
      !thereAreSuggestions ||
      !suggestionsRefs.some(({ current }) => current === event.relatedTarget)
    ) {
      const { onReset, onBlur } = this.props;
      if (!onReset) {
        this.resetValue();
      }
      if (onBlur) {
        onBlur(event.target.name);
      }
    }
  };

  /**
   * it only sets the previous value and handles text change if the focus did not come from the suggestions
   * @param {Object} event - the focus event
   * @callback
   */
  onTextFieldFocus = event => {
    this.setState({ anchorEl: event.currentTarget });
    const { value } = this.props,
      { suggestionsRefs } = this.state,
      thereAreSuggestions =
        suggestionsRefs.length > 0 &&
        suggestionsRefs.every(({ current }) => current !== null);
    if (
      !thereAreSuggestions ||
      event.relatedTarget !== suggestionsRefs[0].current
    ) {
      this.previousValue = value;
      this.handleTextChange(value);
    }
  };

  /**
   * onClick ui event callback
   * @param {String} val sweep variable name
   */
  onSweepVariableSelect = (val, inputName) => {
    const { onChange, onSelect } = this.props,
      value = `=${val}`;
    if (val === "create new") {
      this.setState({
        showAddVariable: true,
        showMenu: false
      });
      return;
    }
    this.resetToPreviousValue = false;
    onChange(value);
    this.setState({
      text: value,
      error: "",
      showMenu: false,
      tooltip: this.getTooltip(val)
    });
    this.focusTextInput = true;
    this.focusTextInputValue = value;
    if (onSelect) {
      onSelect(inputName);
    }
  };

  /**
   * It prevents an event from bubbling. The reason this method was created is
   * because double clicks inside an input should not save editings
   * @param {Object} event - the triggered event
   */
  stopEventPropagation = event => {
    event.stopPropagation();
  };

  /**
   * in case of reset we do not propagate the key down event to the parent layer
   * because otherwise it will save the editing layer on enter.
   */
  onKeyDown = e => {
    const { onReset } = this.props;
    if (this.resetToPreviousValue) {
      this.stopEventPropagation(e);
    }
    if (!onReset && e.key === "Enter") {
      this.resetValue();
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      this.focusOnSuggestion(e.key);
    }
  };

  /**
   * it tells whether the popper with variable suggestions are below or above the sweep input
   */
  suggestionsAreBelowTheInput = () => {
    let suggestionsAreBelowTheInput = true;
    const { suggestionsRefs } = this.state;
    const inputYPosition = this.textInput.current.getBoundingClientRect().y;
    if (suggestionsRefs[0] && suggestionsRefs[0].current) {
      const aSuggestionYPosition = suggestionsRefs[0].current.getBoundingClientRect()
        .y;
      suggestionsAreBelowTheInput = aSuggestionYPosition > inputYPosition;
    }
    return suggestionsAreBelowTheInput;
  };

  /**
   * it focus on the variable suggestions when a vertical arrow is pressed on the sweep input
   * @param {String} key - the pressed key
   */
  focusOnSuggestion = key => {
    const { suggestionsRefs } = this.state;
    const numberOfSuggestions = suggestionsRefs.length;
    const thereAreSuggestions = numberOfSuggestions !== 0;
    if (thereAreSuggestions) {
      const suggestionsAreBelowTheInput = this.suggestionsAreBelowTheInput();
      const suggestionsAreAbove = !suggestionsAreBelowTheInput;
      if (
        (suggestionsAreBelowTheInput && key === "ArrowDown") ||
        (suggestionsAreAbove && key === "ArrowUp")
      ) {
        if (suggestionsRefs[0] && suggestionsRefs[0].current) {
          suggestionsRefs[0].current.focus();
        }
      }
    }
  };

  /**
   * it selects the focused variable or handles an attempt to navigate from a suggestion
   * if a vertical arrow is pressed. it also prevents the event from reaching outer
   * components and scrolling the bar.
   * @param {Object} e - the keyDown event
   * @param {Number} index - the index of the suggestion where the event was generated
   * @param {String} variableName - the name of the variable
   */
  handlePopperKeyDown = (e, index, variableName) => {
    this.stopEventPropagation(e);
    if (e.key === "Enter") {
      this.onSweepVariableSelect(variableName, this.props.name);
    }
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      this.navigateFromSuggestion(e.key, index);
    }
  };

  /**
   * it tries to navigate to a different element (another suggestion or the sweep input)
   * @param {String} arrow - the pressed vertical arrow
   * @param {String} index - the index of currently focused suggestion
   */
  navigateFromSuggestion = (arrow, index) => {
    const { suggestionsRefs } = this.state;
    const suggestionToFocusIndex = arrow === "ArrowUp" ? index - 1 : index + 1;
    const suggestionToFocus = suggestionsRefs[suggestionToFocusIndex];
    if (suggestionToFocus !== undefined) suggestionToFocus.current.focus();
    else {
      const suggestionsAreBelowTheInput = this.suggestionsAreBelowTheInput();
      const suggestionsAreAboveTheInput = !suggestionsAreBelowTheInput;
      if (
        (arrow === "ArrowUp" && suggestionsAreBelowTheInput) ||
        (arrow === "ArrowDown" && suggestionsAreAboveTheInput)
      )
        this.textInput.current.focus();
    }
  };

  render = () => {
    const {
        error,
        showMenu,
        tooltip,
        anchorEl,
        suggestionVariables,
        suggestionsRefs
      } = this.state,
      {
        name,
        classes,
        value,
        disabled,
        label,
        allowNegative,
        restrictFormula,
        sweptVariables,
        autoFocus
      } = this.props;
    return (
      <div test-data="sweepInput">
        <Tooltip
          title={(!showMenu && tooltip !== "" && tooltip) || ""}
          placement="bottom"
        >
          <div>
            {disabled && disabled === disablingType.LABEL ? (
              <span test-data="textLabel" style={{ wordBreak: "break-all" }}>
                {value}
              </span>
            ) : (
              <NumberInput
                allowNegative={allowNegative}
                isSweep
                autoComplete="off"
                name={name}
                inputRef={this.textInput}
                inputProps={{ style: { textAlign: "left" } }}
                classes={{ root: classes.textField }}
                test-data="textInput"
                onChange={this.onTextFieldChange}
                value={value}
                onFocus={this.onTextFieldFocus}
                onBlur={this.onTextFieldBlur}
                onDoubleClick={this.stopEventPropagation}
                onKeyDown={this.onKeyDown}
                label={label}
                disabled={disabled && disabled === disablingType.DISABLE_INPUT}
                autoFocus={autoFocus}
              />
            )}
          </div>
        </Tooltip>
        {!disabled && <div className={classes.error}>{error}</div>}
        {showMenu && (
          <Popper
            open={true}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: 1301 }}
          >
            <div className={classes.dropdown}>
              {suggestionVariables.map(({ id, variableName }, index) => (
                <div
                  test-data={`suggestion_${id}`}
                  tabIndex="0"
                  ref={suggestionsRefs[index]}
                  key={id}
                  className={classes.item}
                  onClick={e =>
                    this.onSweepVariableSelect(variableName, this.props.name)
                  }
                  onKeyDown={e =>
                    this.handlePopperKeyDown(e, index, variableName)
                  }
                >
                  <Typography
                    variant={variableName === "create new" ? "button" : "body2"}
                  >
                    {variableName}
                  </Typography>
                </div>
              ))}
            </div>
          </Popper>
        )}
        {this.state.showAddVariable && (
          <AddVariable
            onClose={this.hideAddVariable}
            restrictFormula={restrictFormula}
            sweptVariables={sweptVariables}
          />
        )}
      </div>
    );
  };
}

SweepInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.object,
  label: PropTypes.string,
  allowEmpty: PropTypes.bool,
  onReset: PropTypes.func,
  allowNegative: PropTypes.bool
  // restrictFormula: PropTypes.bool,
};

export const StyledSweepInput = withStyles(styles)(SweepInput);

export default connect(mapState, null)(StyledSweepInput);
