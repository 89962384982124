import React, { Component } from "react";
import { connect } from "react-redux";
import IncidentLightForm from "components/IncidentLightForm/IncidentLightForm";
import IncidentLightIllustration from "./components/IncidentLightIllustration";
import IncidentLightAction from "MetaCell/actions/IncidentLight";
import IncidentLightSelector from "MetaCell/selectors/IncidentLight";
import IncidentLightApi from "MetaCell/api/IncidentLight";
import { metaCellPaths } from "MetaCell/MetaCell";
import Spinner from "components/Spinner/Spinner";
import { withStyles, Grid } from "@material-ui/core";
import { default as image } from "./MetaStructures.txt";

const styles = {
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

/**
 * a class component to connect to redux store and serve incident light components
 * it consists of an illustration component and a form. See {@link IncidentLightForm}.
 * @author Ibtihel
 * TODO: extract the similarities it has with other canvases
 */
export class IncidentLightCanvas extends Component {
  /**
   * it sets the open page for this component as soon as the component mounts
   */
  componentDidMount() {
    this.props.setPage(metaCellPaths.INCIDENT_LIGHT);
  }

  /**
   * it calls the editing saver for this component as soon as the component unmounts
   */
  componentWillUnmount() {
    this.props.saveEditings(metaCellPaths.INCIDENT_LIGHT);
  }

  /**
   * it is supposed to be passed to the form to handle field changes
   * @callback
   * @param {Object} newValue - the new field value
   */
  updateField = newValue => {
    const { updateIncidentLightAction } = this.props;
    updateIncidentLightAction(newValue);
  };

  render() {
    const {
      editingIncidentLight,
      isFetching,
      classes,
      polarizationHistory,
      updatePolarizationHistory
    } = this.props;
    if (isFetching) {
      return (
        <div className={classes.progress}>
          <Spinner name="Waiting" size={68} timeout={30000} />
        </div>
      );
    }
    return (
      <div>
        <Grid container>
          <Grid item xs style={{ padding: 20 }}>
            <div style={{ margin: "auto", maxWidth: 250 }}>
              <img
                src={image}
                alt={"DefinitionOfAngles"}
                style={{ maxWidth: 250 }}
              />
            </div>
          </Grid>
          <Grid item xs style={{ padding: 20 }}>
            <IncidentLightIllustration incidentLight={editingIncidentLight} />
          </Grid>
          <Grid item xs style={{ padding: 20 }}>
            <div style={{ width: 400, margin: "auto" }}>
              <IncidentLightForm
                incidentLight={editingIncidentLight}
                updateFieldCallback={this.updateField}
                polarizationHistory={polarizationHistory}
                updatePolarizationHistory={updatePolarizationHistory}
                isSweep
                hideType
                title={"Incident Light Parameters"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    editingIncidentLight: IncidentLightSelector.getEditing(state),
    polarizationHistory: IncidentLightSelector.getPolarizationHistory(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateIncidentLightAction: parameter =>
      dispatch(IncidentLightAction.changeEditingField(parameter)),
    fetchIncidentLightAction: simulationId =>
      dispatch(IncidentLightApi.fetch(simulationId)),
    updatePolarizationHistory: obj =>
      dispatch(IncidentLightAction.updatePolarizationHistory(obj))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IncidentLightCanvas));
