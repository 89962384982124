import { actionType } from "MetaComponent/actions/Script2D";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testScript2Ds = {
  byId: {
    1: {
      id: 1,
      name: "3x3",
      script: "amplitude = []",
      type: "FFWF_EXAMPLE"
    },
    2: {
      id: 2,
      name: "Gaussian",
      script: "amplitude = []\nphase = []",
      type: "NFWF_EXAMPLE"
    }
  },
  allIds: ["1", "2"],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} Script2DDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    script2Ds: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

/**
 * Reducer function to manipulate the state of 2D script
 * @param {Object} [state=Script2DDefaultState] - 2D script entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_2D_SCRIPTS: {
      let script2Ds = cloneDeep(defaultState.entities.script2Ds);
      Utils.addOrUpdateEntities(payload, script2Ds);
      return {
        ...state,
        entities: { ...state.entities, script2Ds }
      };
    }

    case actionType.UPSERT_2D_SCRIPTS: {
      let script2Ds = cloneDeep(state.entities.script2Ds);
      Utils.addOrUpdateEntities(payload, script2Ds);
      return {
        ...state,
        entities: { ...state.entities, script2Ds }
      };
    }

    default:
      return state;
  }
}
