import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class NoDataPlot extends PureComponent {
  render() {
    const { height } = this.props;
    return (
      <div
        test-data="plotbox"
        style={{
          height,
          display: "flex",
          color: "#a1a8b0",
          backgroundColor: "#e5e7eb",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6
        }}
      >
        No data to show
      </div>
    );
  }
}

NoDataPlot.propTypes = {
  height: PropTypes.number.isRequired
};

export default NoDataPlot;
