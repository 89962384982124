/**
 * A selector class for the ffwt target state
 */
export default class FFWTTargetSelector {
  /**
   * it selects the ffwt targets from the redux state
   * @param {Object} state => the redux state
   * @return {Object} - the ffwt targets
   */
  static getFFWTTargets = state => state.ffwtTarget.entities.ffwtTargets;
}
