import { actionType } from "MetaComponent/actions/Analysis";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testAnalysisJobs = {
  byId: {
    1: {
      id: 1,
      name: "Analysis 1",
      design_job: 1,
      status: "DONE",
      swept_variables: [],
      center_coord: ["0", "1", "2"]
    },
    2: {
      id: 2,
      name: "Analysis 2",
      design_job: 2,
      status: "RUNNING",
      swept_variables: [],
      center_coord: ["0", "1", "2"]
    },
    3: {
      id: 3,
      name: "Analysis 3",
      design_job: 3,
      status: "QUEUED",
      swept_variables: [],
      center_coord: ["0", "1", "2"]
    },
    4: {
      id: 4,
      name: "Analysis 4",
      design_job: 3,
      status: "DONE",
      swept_variables: [],
      center_coord: ["=x", "=y", "2"],
      angular_center_angles: [0, 0],
      opening_angles: [180, 180],
      angular_unit: "degrees"
    }
  },
  allIds: ["1", "2", "3", "4"],
  loaded: true
};

/**
 * @constant
 * @typedef {Object} AnalysisDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    analysisJobs: {
      byId: {},
      allIds: [],
      loaded: false
    }
  },
  ui: {
    selectedJobId: null,
    tempSweptVariables: [],
    overviewFilters: {}
  }
};

/**
 * Reducer function to manipulate the state of analysis
 * @param {Object} [state=AnalysisDefaultState] - analysis entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.UPDATE_OVERVIEW_FILTERS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          overviewFilters: { ...state.ui.overviewFilters, ...payload }
        }
      };
    }

    case actionType.DELETE_ANALYSIS_JOBS: {
      let analysisJobs = cloneDeep(state.entities.analysisJobs);
      Utils.deleteEntities(payload, analysisJobs);
      return {
        ...state,
        entities: { ...state.entities, analysisJobs }
      };
    }
    case actionType.SET_ANALYSIS_JOBS: {
      let analysisJobs = cloneDeep(defaultState.entities.analysisJobs);
      Utils.addOrUpdateEntities(payload, analysisJobs);
      const lastJobId = payload.length
        ? Math.max.apply(
            Math,
            payload.map(job => job.id)
          )
        : null;
      const selectedJobId =
        state.ui.selectedJobId && analysisJobs.byId[state.ui.selectedJobId]
          ? state.ui.selectedJobId
          : lastJobId;
      return {
        ...state,
        entities: { ...state.entities, analysisJobs },
        ui: { ...state.ui, selectedJobId }
      };
    }
    case actionType.UPSERT_ANALYSIS_JOBS: {
      let analysisJobs = cloneDeep(state.entities.analysisJobs);
      Utils.addOrUpdateEntities(payload, analysisJobs);
      return {
        ...state,
        entities: { ...state.entities, analysisJobs }
      };
    }

    case actionType.SELECT_ANALYSIS_JOB: {
      return {
        ...state,
        ui: { ...state.ui, selectedJobId: payload }
      };
    }
    case actionType.RESET_ANALYSIS_JOBS: {
      return defaultState;
    }
    case actionType.UPDATE_TEMP_ANALYSIS_VARIABLES: {
      return {
        ...state,
        ui: { ...state.ui, tempSweptVariables: payload }
      };
    }
    default:
      return state;
  }
}
