import React, { PureComponent } from "react";
import GenericHeatmap from "components/Heatmap/Heatmap";
import ContainerDimensions from "react-container-dimensions";
import PlotboxHelper from "MetaCell/helper/Plotbox";
import DrilldownInput from "components/DrilldownInput";
import Helper from "MetaComponent/helper/FFWFTarget";

export const wavefrontTypes = Object.freeze({
  AMPLITUDE: "Amplitude",
  PHASE: "Phase"
});

/**
 * a component to show wavefront of an analysis
 * @author Akira Kotsugai
 */
export class GenericWavefront extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedWavefrontType: wavefrontTypes.AMPLITUDE
    };
  }

  /**
   * @returns {Number[][]} - the displayable wavefront data
   */
  getZData() {
    const { wavefront } = this.props,
      { selectedWavefrontType } = this.state;
    return Helper.getWavefrontZData(wavefront, selectedWavefrontType);
  }

  filterRange(arr, a, b) {
    // added brackets around the expression for better readability
    return arr.filter(item => a <= item && item <= b);
  }

  /**
   * @returns {Number[]} the wf displayable X data
   */
  getXData() {
    const { wfWidth, xCenter } = this.props;
    if (this.props.wavefrontXData) {
      const wavefrontXData = this.props.wavefrontXData;
      let matrixMaxWidth = wfWidth / 2,
        matrixMinWidth = (wfWidth * -1) / 2;
      if (xCenter) {
        matrixMaxWidth += xCenter;
        matrixMinWidth += xCenter;
      }
      return this.filterRange(wavefrontXData, matrixMinWidth, matrixMaxWidth);
    }
    const zData = this.getZData();
    return Helper.getWavefrontXData(zData, wfWidth, xCenter);
  }

  /**
   * @returns {Number[]} the wf displayable Y data
   */
  getYData() {
    const { wfHeight, yCenter } = this.props;
    if (this.props.wavefrontYData) {
      const wavefrontYData = this.props.wavefrontYData;
      let matrixMaxWidth = wfHeight / 2,
        matrixMinWidth = (wfHeight * -1) / 2;
      if (yCenter) {
        matrixMaxWidth += yCenter;
        matrixMinWidth += yCenter;
      }
      return this.filterRange(wavefrontYData, matrixMinWidth, matrixMaxWidth);
    }
    const zData = this.getZData();
    return Helper.getWavefrontYData(zData, wfHeight, yCenter);
  }

  /**
   * @returns {Object[][]} it makes drilldown objects out of the wavefront types
   */
  getDrilldownOptions() {
    return [
      Object.values(wavefrontTypes).map(wfType => ({
        text: wfType,
        isSelected: false
      }))
    ];
  }

  /**
   * it sets the selected option in the state
   * @param {Number} optionIndex
   * @callback
   */
  onSelectDrilldownOption = optionIndex => {
    const { onWaveFrontSelect } = this.props;
    this.setState({
      selectedWavefrontType: Object.values(wavefrontTypes)[optionIndex]
    });
    if (onWaveFrontSelect) {
      onWaveFrontSelect(Object.values(wavefrontTypes)[optionIndex]);
    }
  };

  getZLabel() {
    if (this.props.colorBarUnit) {
      return this.props.colorBarUnit;
    }
    if (this.props.hidePhase) {
      return "W/m2";
    }
    return this.state.selectedWavefrontType === wavefrontTypes.AMPLITUDE
      ? "V/m"
      : "rad";
  }

  render() {
    const {
      wavefront,
      hidePhase,
      rowsCount,
      columnsCount,
      crossSectionOrientation,
      crossSectionIndex,
      handleCrossSection,
      hideHeatMapCrossSectionOrientationLabel,
      xLabel,
      yLabel
    } = this.props;
    const { selectedWavefrontType } = this.state;
    return (
      <>
        <div style={{ width: "100%", display: "flex" }}>
          {wavefront && !hidePhase && (
            <DrilldownInput
              float={"left"}
              value={this.state.selectedWavefrontType}
              options={this.getDrilldownOptions()}
              onSelect={this.onSelectDrilldownOption}
              showCount={false}
            />
          )}
        </div>
        {this.props.wfHeight && this.props.wfWidth && wavefront && (
          <div style={{ width: "100%" }}>
            <ContainerDimensions>
              {({ width }) => {
                const xData = this.getXData(),
                  yData = this.getYData(),
                  zData = this.getZData(),
                  heatmapWidth = PlotboxHelper.getHeatmapWidth(
                    width,
                    xData,
                    yData
                  ),
                  heatmapHeight = PlotboxHelper.getHeatmapHeight(
                    width,
                    xData,
                    yData
                  );
                return (
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      marginLeft: (width - heatmapWidth) / 2
                    }}
                  >
                    <GenericHeatmap
                      title={this.props.title}
                      width={heatmapWidth}
                      height={heatmapHeight}
                      x={xData}
                      y={yData}
                      z={zData}
                      xLabel={
                        xLabel
                          ? xLabel
                          : this.props.unit && `width (${this.props.unit})`
                      }
                      yLabel={
                        yLabel
                          ? yLabel
                          : this.props.unit && `length (${this.props.unit})`
                      }
                      valueUnit={this.getZLabel()}
                      showLegend={this.props.showLegend}
                      enableCrossSection={this.props.enableCrossSection}
                      crossSectionRowsCount={
                        rowsCount ? rowsCount : xData.length
                      }
                      crossSectionColumnsCount={
                        columnsCount ? columnsCount : yData.length
                      }
                      crossSectionOrientation={crossSectionOrientation}
                      crossSection3DUnit={this.props.unit}
                      crossSectionColorbarUnit={
                        selectedWavefrontType === "Phase"
                          ? "Phase(rad)"
                          : selectedWavefrontType === "Amplitude"
                          ? "Amp(V/m)"
                          : "I(W/m2)"
                      }
                      crossSectionIndex={crossSectionIndex}
                      handleCrossSection={handleCrossSection}
                      hideHeatMapCrossSectionOrientationLabel={
                        hideHeatMapCrossSectionOrientationLabel
                      }
                      zmin={this.props.zmin}
                      zmax={this.props.zmax}
                    />
                  </div>
                );
              }}
            </ContainerDimensions>
          </div>
        )}
      </>
    );
  }
}

export default GenericWavefront;
