import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LayersIcon from "@material-ui/icons/Layers";
import LeakAdd from "@material-ui/icons/LeakAdd";
import Settings from "@material-ui/icons/Settings";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Divider from "@material-ui/core/Divider";
import { withRouter } from "react-router-dom";
import Explorer from "./components/Explorer/Explorer";
import { metaCellPaths } from "MetaCell/MetaCell";
import SideMenuItem from "components/SideMenuItem/SideMenuItem";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";

const styles = theme => ({
  gutter: {
    paddingTop: 0,
    paddingRight: 0
  }
});

/**
 * the content of meta cell's control menu. Clicks on the menu will take the user to different pages.
 * See {@link MetaCellPath}.
 * @author Akira Kotsugai
 */
export class MetaCellMenu extends Component {
  simulationOpened;

  componentDidUpdate(prevProps) {
    const { simulationOpenId, history } = this.props;
    if (simulationOpenId !== prevProps.simulationOpenId) {
      if (simulationOpenId === -1) {
        history.push(metaCellPaths.ROOT);
      } else if (this.simulationOpened || prevProps.simulationOpenId !== -1) {
        this.simulationOpened = true;
        history.push(metaCellPaths.STRUCTURE);
      }
    }
  }

  render() {
    const { classes, disabled, activePage, saveEditings } = this.props;
    return (
      <div test-data="metaCellMenu">
        <ListItem className={classes.gutter}>
          <Explorer saveEditings={saveEditings} />
        </ListItem>
        <Divider />

        <SideMenuItem
          test-data="structureItem"
          activePath={activePage}
          disabled={disabled}
          path={metaCellPaths.STRUCTURE}
        >
          <LayersIcon />
          <ListItemText primary="Structure" name="NavStructureButton" />
        </SideMenuItem>
        <Divider />

        {/* <SideMenuItem
          test-data="incidentLightItem"
          activePath={activePage}
          disabled={disabled}
          path={metaCellPaths.INCIDENT_LIGHT}
        >
          <LeakAdd />
          <ListItemText
            primary="Incident Light"
            name="NavIncidentLightButton"
          />
        </SideMenuItem>
        <Divider /> */}

        <SideMenuItem
          test-data="simulationTargetItem"
          activePath={activePage}
          disabled={disabled}
          path={metaCellPaths.SIMULATION_TARGET}
        >
          <LeakAdd />
          <ListItemText
            primary="Meta-cell Targets"
            name="NavIncidentLightButton"
          />
        </SideMenuItem>
        <Divider />

        <SideMenuItem
          test-data="simulationSettingsItem"
          activePath={activePage}
          disabled={disabled}
          path={metaCellPaths.SIMULATION_SETTINGS}
        >
          <Settings />
          <ListItemText
            primary="Simulation Settings"
            name="NavSimulationSettingsButton"
          />
        </SideMenuItem>
        <Divider />

        <SideMenuItem
          test-data="simulateItem"
          activePath={activePage}
          disabled={disabled}
          path={metaCellPaths.SIMULATE}
        >
          <PlayArrow />
          <ListItemText primary="Simulate" name="NavSimulateButton" />
        </SideMenuItem>
        <Divider />

        <SideMenuItem
          test-data="modeAnalysisItem"
          activePath={activePage}
          disabled={disabled}
          path={metaCellPaths.MODE_ANALYSIS}
        >
          <BubbleChartIcon />
          <ListItemText primary="Mode Analysis" name="NavModeAnalysisButton" />
        </SideMenuItem>
        <Divider />
      </div>
    );
  }
}

MetaCellMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  activePage: PropTypes.string.isRequired,
  saveEditings: PropTypes.func.isRequired,
  simulationOpenId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(MetaCellMenu));
