import { actionType } from "MetaCell/actions/GlobalParameters";
import Utils from "reducer/Utils";
import { cloneDeep } from "lodash";

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */
export const testGlobalParameters = {
  byId: {
    1: {
      id: 1,
      cellWidth: "200",
      cellHeight: "200",
      unit: "nm"
    }
  },
  allIds: [1]
};

/**
 * @constant
 * @typedef {Object} GlobalParametersDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    globalParameters: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    editingGlobalParameters: null
  }
};

/**
 * Reducer function to manipulate the state of global parameters
 * @author Ibtihel
 * @param {Object} [state=GlobalParametersDefaultState] - global parameters entities and ui management
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_METACELL_GLOBAL_PARAMETERS: {
      let globalParameters = cloneDeep(defaultState.entities.globalParameters);
      Utils.addOrUpdateEntities(payload, globalParameters);
      return {
        ...state,
        entities: { ...state.entities, globalParameters },
        ui: {
          ...state.ui,
          editingGlobalParameters: null
        }
      };
    }

    case actionType.CHANGE_GLOBAL_PARAMETERS_EDITING_FIELD: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingGlobalParameters: {
            ...state.ui.editingGlobalParameters,
            ...payload
          }
        }
      };
    }

    case actionType.RESET_GLOBAL_PARAMETERS: {
      return defaultState;
    }

    case actionType.EDIT_GLOBAL_PARAMETERS: {
      const originalGlobalParameters = Object.values(
        state.entities.globalParameters.byId
      )[0];
      return {
        ...state,
        ui: {
          ...state.ui,
          editingGlobalParameters: originalGlobalParameters
        }
      };
    }

    case actionType.CANCEL_GLOBAL_PARAMETERS_EDITING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          editingGlobalParameters: null
        }
      };
    }

    default:
      return state;
  }
}
