import { actionType } from "MetaComponent/actions/FFWTTarget";
import { cloneDeep } from "lodash";
import Utils from "reducer/Utils";

/**
 * @constant
 * @typedef {Object} FFWTTargetDefaultState
 * value to be used as a state when the app is first load and the data has not been fetched yet
 */
export const defaultState = {
  entities: {
    ffwtTargets: {
      byId: {},
      allIds: [],
      loaded: false
    }
  }
};

/**
 * @constant
 * dummy data in the same format as the actual state for testing purposes
 */

export const testFFWTTargets = {
  byId: {
    1: {
      WFHeight: 300,
      WFWidth: 300,
      center_coord: [2.0, 5.0, 6.0],
      angular_center_angles: [0, 0],
      opening_angles: [180, 180],
      description: "",
      euler_Angles: [0.0, 0.0, 0.0],
      angular_unit: "degrees",
      target_quantity: "cartesian",
      id: 1,
      iteration_Stop: 1,
      name: "Test FFW Target",
      tolerance: 1,
      unit: "mm",
      wave_front_design_script: 1
    },
    2: {
      id: 2,
      name: "ffwf target 2",
      description: "test description",
      center_coord: [0.0, 0.0, 10.0],
      euler_Angles: [0.0, 0.0, 0.0],
      WFWidth: 100.0,
      WFHeight: 100.0,
      tolerance: 0.0001,
      iteration_Stop: 10,
      unit: "μm",
      wave_front_design_script: 1
    },
    3: {
      id: 3,
      name: "ffwf target 3",
      description: "test description",
      center_coord: [0.0, 0.0, 10.0],
      euler_Angles: [0.0, 0.0, 0.0],
      WFWidth: 100.0,
      WFHeight: 100.0,
      tolerance: 0.0001,
      iteration_Stop: 10,
      unit: "μm",
      wave_front_design_script: 1
    }
  },
  allIds: ["1", "2", "3"],
  loaded: true
};

/**
 * Reducer function to manipulate the state of ffwt target
 * @param {Object} [state=FFWTTargetDefaultState] - ffwt target entities
 * @param {Object} action - contains a data and an instruction to tell the reducer what to do with the data
 * @return {Object} - new state after the action was processed.
 */
export default function(state = defaultState, action) {
  const { payload } = action;
  switch (action.type) {
    case actionType.SET_FFWT_TARGETS: {
      let ffwtTargets = cloneDeep(state.entities.ffwtTargets);
      Utils.addOrUpdateEntities(payload, ffwtTargets, [
        "wave_front_design_script"
      ]);
      return {
        ...state,
        entities: { ...state.entities, ffwtTargets }
      };
    }

    case actionType.UPSERT_FFWF_TARGETS: {
      let ffwtTargets = cloneDeep(state.entities.ffwtTargets);
      Utils.addOrUpdateEntities(payload, ffwtTargets, [
        "wave_front_design_script"
      ]);
      return {
        ...state,
        entities: { ...state.entities, ffwtTargets }
      };
    }

    case actionType.DELETE_FFWT_TARGET: {
      let ffwtTargets = cloneDeep(state.entities.ffwtTargets);
      Utils.deleteEntities([payload], ffwtTargets);
      return {
        ...state,
        entities: { ...state.entities, ffwtTargets }
      };
    }

    case actionType.SET_FFWF_TARGET_WAVEFRONT: {
      let ffwtTargets = cloneDeep(state.entities.ffwtTargets);
      ffwtTargets.byId[payload.id] = {
        ...ffwtTargets.byId[payload.id],
        wave_front: payload.wavefront
      };
      return {
        ...state,
        entities: { ...state.entities, ffwtTargets }
      };
    }

    default:
      return state;
  }
}
