/**
 * @constant
 * @typedef {Object} SetPointActionType
 * @property {String} DELETE_SET_POINT - tells the reducer to delete a setpoint
 * @property {String} UPSERT_SET_POINTS - tells the reducer to insert or update setpoints
 * @property {String} SET_SET_POINTS - tells the reducer to set the set_points
 * @global
 * it defines setpoint's type of actions.
 */
export const actionType = Object.freeze({
  SET_SET_POINTS: "SET_SET_POINTS",
  DELETE_SET_POINT: "DELETE_SET_POINT",
  UPSERT_SET_POINTS: "UPSERT_SET_POINTS"
});

/**
 * A class created to create setpoint actions to be dispatched to redux. It is already tested with
 * setpoint reducer, so it does not need to be unit tested.
 */
export default class SetPointAction {
  static set = value => ({
    type: actionType.SET_SET_POINTS,
    payload: value
  });

  static delete = setPointId => ({
    type: actionType.DELETE_SET_POINT,
    payload: setPointId
  });

  static upsert = value => ({
    type: actionType.UPSERT_SET_POINTS,
    payload: value
  });
}
