import React, { PureComponent } from "react";
import MaterialTable from "components/MaterialTable/src/index";
import { Typography, withStyles } from "@material-ui/core";
import {
  FamilySimpleFieldEditing,
  FamilyMultilineFieldEditing,
  FamilyUnitSelectField
} from "./components/FamilyEditComponents";
import { connect } from "react-redux";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import FamilyApi from "MetaCell/api/Family";
import { FamilySelector } from "MetaCell/selectors/Family";
import UserSelector from "BaseApp/selectors/User";
import FamilyHelper from "MetaCell/helper/Family";
import FamilyMembers from "./components/FamilyMembers";
import FamilyMemberWizard from "./components/FamilyMemberWizard";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import HelperUtils from "MetaCell/helper/HelperUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import LockIcon from "@material-ui/icons/Lock";
import ReactLinkify from "react-linkify";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box"
  },
  uploadButtonInvalid: {
    borderColor: "red"
  },
  uploadButtonValid: {
    borderColor: "black"
  }
};

export const unitOptions = ["mm", "μm"];

/**
 * initial value for the family form when creating a family
 */
export const defaultFamilyState = {
  name: "",
  description: "",
  width: "",
  height: "",
  unit: unitOptions[1]
};

/**
 * fields that must be filled in before submission
 */
export const requiredFields = ["name"];

export class Families extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      columns: [
        {
          title: "Name",
          field: "name",
          customSort: (a, b) => HelperUtils.caseInsensitiveSort(a.name, b.name),
          editComponent: props => {
            return (
              <FamilySimpleFieldEditing
                field={"name"}
                value={props.value || ""}
                getRequiredStyle={this.getRequiredStyle}
                isFieldInvalid={this.isFieldInvalid}
                onChange={(field, value) => {
                  props.onChange(value);
                }}
              />
            );
          }
        },
        {
          title: "Cell width x length",
          field: "cellSize",
          editable: "never",
          cellStyle: {
            color: "#bbb"
          }
        },
        {
          title: "Unit",
          field: "unit",
          editComponent: props => {
            return (
              <FamilyUnitSelectField
                options={unitOptions}
                field={"unit"}
                value={props.value || unitOptions[1]}
                onChange={(field, value) => {
                  props.onChange(value);
                }}
              />
            );
          }
        },
        {
          title: "Description",
          field: "description",
          editComponent: props => {
            return (
              <FamilyMultilineFieldEditing
                field={"description"}
                value={props.value || ""}
                onChange={(field, value) => {
                  props.onChange(value);
                }}
              />
            );
          },
          render: rowData => <ReactLinkify>{rowData.description}</ReactLinkify>
        }
      ],
      familyToUpsert: defaultFamilyState,
      importDialogOpen: false,
      importDialogError: "",
      importPreviewData: null,
      invalidFields: []
    };
    this.tableRef = React.createRef();
  }

  /**
   * it checks whether the given field exists in the state for invalid fields
   * @param {String} field - the family field
   * @returns {Boolean} whether it is invalid
   */
  isFieldInvalid = field => {
    const { invalidFields } = this.state;
    return invalidFields.indexOf(field) !== -1;
  };

  /**
   * it returns a style for the * sign in required fields
   * by checking their validation status
   * @param {String} field - the field to be checked
   */
  getRequiredStyle = field => {
    return {
      color: this.isFieldInvalid(field) ? "red" : "black"
    };
  };

  /**
   * @callback
   * it submits the form data and resets it if there are no missing required fields otherwise
   * it updates the invalid fields and does not submit anything.
   */
  onRowAdd = newData => {
    const { createFamilyAction } = this.props;
    return new Promise((resolve, reject) => {
      const missingRequiredFields = FamilyHelper.getMissingRequiredProperties(
        newData,
        requiredFields
      );
      if (missingRequiredFields.length === 0) {
        createFamilyAction(newData);
        this.setState({ invalidFields: [] });
        resolve();
      } else {
        this.setState({ invalidFields: missingRequiredFields });
        reject();
      }
    });
  };

  /**
   * @callback
   * update a family data
   */
  onRowUpdate = (newData, oldData) => {
    const { updateFamilyAction } = this.props;
    return new Promise((resolve, reject) => {
      const missingRequiredFields = FamilyHelper.getMissingRequiredProperties(
        newData,
        requiredFields
      );
      if (missingRequiredFields.length === 0) {
        this.setState({ invalidFields: [] });
        updateFamilyAction(oldData.id, newData)
          .then(() => resolve())
          .catch(() => reject());
      } else {
        this.setState({ invalidFields: missingRequiredFields });
        reject();
      }
    });
  };

  /**
   * @param {String} field - the family field being changed
   * @param {String} value - the new value
   */
  handleFieldChange = (field, value) => {
    this.updateInvalidFields(field, value);
    this.changeFamilyToUpsertField(field, value);
  };

  /**
   * @param {String} field - the family field being changed
   * @param {String} value - the new value
   */
  updateInvalidFields = (field, value) => {
    if (requiredFields.includes(field)) {
      const { invalidFields } = this.state;
      const newInvalidFields = value
        ? invalidFields.filter(invalidField => invalidField !== field)
        : invalidFields.concat(field);
      this.setState({ invalidFields: newInvalidFields });
    }
  };

  /**
   * it sets the new value for the family being upserted on the state.
   * @param {String} field - the family field to be changed
   * @param {String} value - the new value
   */
  changeFamilyToUpsertField = (field, value) => {
    let familyToUpsert = { ...this.state.familyToUpsert };
    familyToUpsert[field] = value;
    this.setState({ familyToUpsert });
  };

  /**
   * @callback
   * @param {Object} e - the click event
   * @param {Object} rowsData - the selected family items
   * @returns {Promise} a promise that calls the delete family action
   */
  onRowDelete = (e, rowsData) => {
    const familyIds = rowsData.map(({ id }) => id);
    const { deleteFamilyAction, showConfirmDialog } = this.props;
    showConfirmDialog(
      "Delete",
      this.deleteWarning(familyIds),
      () => {
        deleteFamilyAction(familyIds);
      },
      undefined,
      false
    );
  };

  onWizardClose = (familyId, refresh = true) => {
    if (this.tableRef.current && this.tableRef.current.dataManager.sortedData) {
      const rowDataIndex = this.tableRef.current.dataManager.sortedData.findIndex(
        item => item.id === familyId
      );

      // const rowData = this.tableRef.current.dataManager.sortedData[rowDataIndex]
      const renderComponent = rowData => {
        return rowData.for_pdk ? (
          <div style={{ backgroundColor: "#f7f7f7", margin: "10px" }}>
            <Typography align="center">
              More Information about this PDK can be found at{" "}
              <ReactLinkify>
                {rowData.pdk_preferences.instructions_link}
              </ReactLinkify>
              link
            </Typography>
          </div>
        ) : (
          <div>
            <FamilyMembers familyId={rowData.id} />
          </div>
        );
      };
      this.tableRef.current &&
        this.tableRef.current.onToggleDetailPanel(
          [rowDataIndex],
          renderComponent
        );
      if (refresh) {
        // this.onWizardClose(familyId, false);
        this.tableRef.current &&
          this.tableRef.current.onToggleDetailPanel(
            [rowDataIndex],
            renderComponent
          );
        this.tableRef.current &&
          this.tableRef.current.onToggleDetailPanel(
            [rowDataIndex],
            renderComponent
          );
      }
    }
  };

  /**
   * @param {Number[]} familyIds - the family ids to delete
   * @returns {String} a custom message when the family is being used
   */
  deleteWarning = familyIds => {
    const { metaComponents, mcGroups, families } = this.props;
    const basicWarning = "Are you sure you want to proceed?";
    let affectedMessages = [];

    for (const familyId of familyIds) {
      const relatedMetaComponents = Object.values(metaComponents.byId).filter(
        mc => mc.family === familyId
      );
      if (relatedMetaComponents.length) {
        const familyName = families.byId[familyId].name;
        const identifierLabels = relatedMetaComponents.map(
          mc => `(${mcGroups.byId[mc.mcGroup].name}) - ${mc.name}`
        );
        const message = `-${familyName} is being used by ${HelperUtils.joinWords(
          identifierLabels
        )}`;
        affectedMessages.push(message);
      }
    }
    if (affectedMessages.length) {
      return `${affectedMessages.join("\n")}\n${basicWarning}`;
    }
    return basicWarning;
  };

  render() {
    const { columns } = this.state,
      { classes, families } = this.props;
    if (!families.loaded) {
      return null;
    }
    return (
      <div className={classes.main}>
        <MaterialTable
          tableRef={this.tableRef}
          detailPanel={[
            rowData => ({
              // disabled: rowData.for_pdk
              icon: rowData.for_pdk
                ? () => (
                    <>
                      <KeyboardArrowRightIcon />
                      <LockIcon />
                    </>
                  )
                : null,
              openIcon: rowData.for_pdk
                ? () => (
                    <>
                      <KeyboardArrowDownIcon />
                      <LockIcon />
                    </>
                  )
                : null,
              render: () =>
                rowData.for_pdk ? (
                  <div style={{ backgroundColor: "#f7f7f7", margin: "10px" }}>
                    <Typography align="center">
                      <ReactLinkify>
                        <span test-data="pdk_description">
                          More Information about this PDK can be found at{" "}
                          {rowData.pdk_preferences.instructions_link} link
                        </span>
                      </ReactLinkify>
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <FamilyMembers familyId={rowData.id} />
                  </div>
                )
            })
          ]}
          options={{
            selection: true,
            addRowPosition: "first",
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 40, 50],
            detailPanelColumnAlignment: "right",
            actionsColumnIndex: -1,
            selectionProps: rowData => ({
              disabled: rowData.for_pdk
            })
          }}
          columns={columns}
          title="Meta cell groups"
          editable={{
            isEditable: rowsData => rowsData.length === 1,
            onRowAdd: this.onRowAdd,
            onRowUpdate: this.onRowUpdate
          }}
          data={FamilyHelper.sortFamilies(
            FamilyHelper.formatFamiliesTableData(
              Object.values(this.props.families.byId)
            )
          )}
          localization={{
            pagination: {
              labelRowsSelect: "families"
            },
            header: {
              actions: ""
            }
          }}
          actions={[
            rowData => ({
              icon: DeleteIcon,
              tooltip: "Delete",
              onClick: this.onRowDelete,
              disabled: rowData.for_pdk
            })
          ]}
        />
        <FamilyMemberWizard wizardClose={this.onWizardClose} />
      </div>
    );
  }
}

const mapState = state => ({
  families: FamilySelector.getFamilies(state),
  user: UserSelector.getUser(state),
  metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
  mcGroups: DirectoryExplorerSelector.getMCGroups(state)
});

const mapDispatch = dispatch => ({
  createFamilyAction: family => dispatch(FamilyApi.createFamily(family)),
  deleteFamilyAction: familyIds =>
    dispatch(FamilyApi.deleteFamilies(familyIds)),
  updateFamilyAction: (familyId, newData) =>
    dispatch(FamilyApi.updateFamily(familyId, newData)),
  showConfirmDialog: (
    title,
    message,
    confirmAction,
    cancelAction,
    isReduxAction
  ) =>
    dispatch(
      ConfirmDialogAction.show(
        title,
        message,
        confirmAction,
        cancelAction,
        isReduxAction
      )
    )
});

export default connect(
  mapState,
  mapDispatch
)(withErrorBoundary(withStyles(styles)(Families)));
