import SweepOverview from "components/SweepOverview/SweepOverview";
import SimulationSettingsApi from "MetaCell/api/SimulationSettings";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import { connect } from "react-redux";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";

const mapState = state => ({
  simulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
  sweptVariables: SimulationSettingsSelector.getSweptVariables(state)
});

const mapDispatch = dispatch => ({
  addSweptVariable: data =>
    dispatch(SimulationSettingsApi.addSweptVariable(data)),
  removeSweptVariable: id =>
    dispatch(SimulationSettingsApi.removeSweptVariable(id)),
  updateSweptVariable: (id, obj) =>
    dispatch(SimulationSettingsApi.updateSweptVariable(id, obj))
});

export default connect(mapState, mapDispatch)(SweepOverview);
