import React from "react";
import { Button, Typography, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "components/Spinner/Spinner";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  progress: {
    maxWidth: 500,
    margin: "auto"
  },
  buttonWrapper: {
    position: "relative",
    marginTop: 20
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

/**
 * A component to show the status and progress of jobs
 * @param {Object} props - the parent's properties
 * @return {Component}
 */

const JobStatusAndProgress = props => {
  const classes = useStyles();

  return (
    <>
      {props.showStatus && (
        <div className={classes.wrapper}>
          <Typography>
            {props.jobType} status: {props.jobStatus}
          </Typography>
        </div>
      )}

      {props.starting && (
        <div className={classes.wrapper}>
          <Typography>Starting {props.jobType} Job</Typography>
        </div>
      )}

      {props.showBigSpinner && (
        <div className={classes.wrapper}>
          <Spinner
            name="Waiting"
            size={68}
            timeout={
              props.jobStatus === "RUNNING" || props.jobStatus === "QUEUED"
                ? undefined
                : 180000
            }
          />
        </div>
      )}

      {props.jobStatus === "RUNNING" && (
        <>
          <div className={classes.wrapper} style={{ padding: 25 }}>
            <Typography>{props.progress}%</Typography>
          </div>
          <div className={classes.progress}>
            <LinearProgress
              name="SimulationProgress"
              variant="determinate"
              value={props.progress}
            />
          </div>
          <div
            className={classes.buttonWrapper}
            style={{ textAlign: "center" }}
          >
            {props.stop && (
              <Button
                test-data="stopBtn"
                name="StopJobButton"
                variant="contained"
                onClick={props.stop}
              >
                Stop {props.jobType}
              </Button>
            )}
          </div>
        </>
      )}

      {props.showStartButton && (
        <div className={classes.wrapper}>
          <div className={classes.buttonWrapper}>
            <Button
              name="startJob"
              variant="contained"
              color="primary"
              disabled={props.polling}
              onClick={props.run}
            >
              Launch {props.jobType}
            </Button>
            {props.polling && (
              <Spinner
                name="Waiting"
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default JobStatusAndProgress;
