import React, { PureComponent } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RootCanvas from "./containers/RootCanvas/RootCanvas";
import MaterialsCanvas from "./containers/MaterialsCanvas/MaterialsCanvas";
import FamiliesCanvas from "./containers/FamiliesCanvas/FamiliesCanvas";
import DesignTargetsCanvas from "./containers/DesignTargetsCanvas/DesignTargetsCanvas";
import FFWFTargetsCanvas from "./containers/FFWFTargetsCanvas/FFWFTargetsCanvas";
import ControlMenu from "components/ControlMenu/ControlMenu";
import LibraryMenu from "Library/containers/Menu/Menu";
import { setLibraryPath } from "BaseApp/actions/navigationHistory";
import { connect } from "react-redux";
import UserApi from "BaseApp/api/User";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import FamilyApi from "MetaCell/api/Family";
import SelectedDesignTargetApi from "MetaComponent/api/SelectedDesignTarget";
import DesignTargetApi from "MetaComponent/api/DesignTarget";

/**
 * internal library paths
 * @author Akira Kotsugai
 * @constant
 * @typedef {Object} LibraryPath
 * @property {String} ROOT - the path to the library
 * @property {String} MATERIAL - the path to the material canvas
 * @property {String} FAMILIES - the path to the families canvas
 * @property {String} FFWFTARGETS - the path to the ffwftargets canvas
 * @global
 */
export const libraryPaths = Object.freeze({
  ROOT: "/library",
  MATERIALS: "/library/materials",
  FAMILIES: "/library/families",
  DESIGNTARGETS: "/library/designtargets",
  FFWFTARGETS: "/library/ffwftargets"
});

export class Library extends PureComponent {
  constructor() {
    super();
    this.state = {
      currentPage: "",
      dataIsReady: false
    };
  }

  componentDidMount() {
    this.props.postUserSettings({ lastView: "/library" });
    this.fetchAllData();
  }

  /**
   * it updates the open page in the state
   * @param {String} page - the library pathname
   */
  fetchData = page => {
    const { setLibraryPath, postUserSettings } = this.props;
    this.setState({ currentPage: page });
    setLibraryPath(page);
    postUserSettings({ lastView: page });
  };

  /**
   * it fetches all data the library needs and in the meanwhile it changes the
   * fetching state.
   */
  fetchAllData = () => {
    this.setState({ dataIsReady: false });
    const {
        fetchFamiliesAction,
        fetchMCGroupsAndMetaComponentsAction,
        fetchSelectedDesignTargetsAction,
        fetchDesignTargetsAction
      } = this.props,
      requests = [];
    requests.push(
      fetchFamiliesAction(),
      fetchMCGroupsAndMetaComponentsAction(),
      fetchSelectedDesignTargetsAction(),
      fetchDesignTargetsAction()
    );
    return Promise.all(requests).then(() =>
      this.setState({ dataIsReady: true })
    );
  };

  render() {
    const content = <LibraryMenu activePage={this.state.currentPage} />;
    return (
      <Router>
        <ControlMenu content={content}>
          <Switch>
            <Route
              exact
              path={libraryPaths.ROOT}
              render={props => <RootCanvas {...props} />}
            />
            <Route
              path={libraryPaths.MATERIALS}
              render={props => (
                <MaterialsCanvas {...props} fetchData={this.fetchData} />
              )}
            />
            <Route
              path={libraryPaths.FAMILIES}
              render={props => (
                <FamiliesCanvas
                  {...props}
                  ready={this.state.dataIsReady}
                  setPage={this.fetchData}
                />
              )}
            />
            <Route
              path={libraryPaths.DESIGNTARGETS}
              render={props => (
                <DesignTargetsCanvas
                  {...props}
                  ready={this.state.dataIsReady}
                  setPage={this.fetchData}
                />
              )}
            />
            <Route
              path={libraryPaths.FFWFTARGETS}
              render={props => (
                <FFWFTargetsCanvas
                  {...props}
                  setPage={this.fetchData}
                  ready={this.state.dataIsReady}
                />
              )}
            />
          </Switch>
        </ControlMenu>
      </Router>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setLibraryPath: path => dispatch(setLibraryPath(path)),
  postUserSettings: data => dispatch(UserApi.postUserSettings(data)),
  fetchFamiliesAction: () => dispatch(FamilyApi.fetchFamilies()),
  fetchMCGroupsAndMetaComponentsAction: () =>
    dispatch(DirectoryExplorerApi.fetch()),
  fetchSelectedDesignTargetsAction: () =>
    dispatch(SelectedDesignTargetApi.fetchSelectedDesignTargets()),
  fetchDesignTargetsAction: id =>
    dispatch(DesignTargetApi.fetchDesignTargets(id))
});

export default connect(null, mapDispatchToProps)(Library);
