import React, { Component } from "react";
import PropTypes from "prop-types";
import DirectionSnackbar from "components/Snackbar/Snackbar";

/**
 * component to let the user know whether the bug report was successfully sent or not
 * @author Akira
 */
export default class ReportStatus extends Component {
  render() {
    const { sent } = this.props;
    if (sent !== null) {
      return (
        <DirectionSnackbar
          message={
            sent
              ? "Report successfully sent to helpdesk@planopsim.com"
              : "Failed to report the bug. Contact helpdesk@planopsim.com"
          }
        />
      );
    }
    return null;
  }
}

ReportStatus.propTypes = {
  /**
   * whether the report was sent successfully
   */
  sent: PropTypes.bool.isRequired
};
