import React, { PureComponent } from "react";
import { MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

/**
 * A component for the user to see and select options
 * @author Akira Kotsugai
 */
export class MergeItem extends PureComponent {
  render = () => {
    return (
      <ClickAwayListener onClickAway={this.props.handleClose}>
        <UnselfishSelect
          open={this.props.show}
          value={null}
          onChange={this.props.handleSelect}
        >
          {this.props.options.map(option => (
            <MenuItem key={option.name} value={option.name}>
              <span test-data="itemOption">{option.label}</span>
            </MenuItem>
          ))}
        </UnselfishSelect>
      </ClickAwayListener>
    );
  };
}

MergeItem.propTypes = {
  /**
   * whether the options should be shown
   */
  show: PropTypes.bool.isRequired,
  /**
   * possible options
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * callback to select an option
   */
  handleSelect: PropTypes.func.isRequired,
  /**
   * callback to stop showing the options
   */
  handleClose: PropTypes.func.isRequired
};

export default MergeItem;
